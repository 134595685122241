import {
    RoutePoint,
    UpdateRoutePointRequest,
    CreateRoutePointsResponse,
    CreateRoutePointsRequest,
    UpdateRoutePointsResponse,
    UpdateRoutePointsRequest,
} from './types';
import { clientPassword } from '../ClientPassword';

export const updateDateORUserRoutePoint = (request: UpdateRoutePointRequest) => {
    return clientPassword.post<RoutePoint, UpdateRoutePointRequest>(
        '/route_points/change_visit_date_or_user/',
        request
    );
};

export const createRoutePoints = (request: CreateRoutePointsRequest) =>
    clientPassword.post<CreateRoutePointsResponse, CreateRoutePointsRequest>(
        '/route_points/',
        request
    );

export const updateRoutePoints = (arg: UpdateRoutePointsRequest) =>
    clientPassword.patch<UpdateRoutePointsResponse, UpdateRoutePointsRequest>(
        `/route_points/${arg?.route_point_id}/`,
        arg
    );

export const routePointsList = (request: CreateRoutePointsRequest) =>
    clientPassword.post<CreateRoutePointsResponse, CreateRoutePointsRequest>(
        '/route_points/list/',
        request
    );

export const deleteRoutePoint = (route_point_id: string) => {
    return clientPassword.delete(`/route_points/${route_point_id}/`);
};
