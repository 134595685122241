import React, { useCallback, useEffect, useState } from 'react';
import styles from './AddressProgramData.module.scss';
import { TableGrid, Button, Dialog } from '@components';
import {
    fetchProgramsList,
    deleteAddressProgram,
    updateProgram,
    fetchFullPeriodList,
} from '@apiFeature/addressProgram';
import { ProgramsResponse, PeriodItem } from '@apiFeature/addressProgram/type';
import { useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import FiltersSelectors from '@redux/filters/selectors';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as EditIcon } from '@images/svg/edit.svg';
import { ReactComponent as DeleteIcon } from '@images/svg/remove.svg';
import { ReactComponent as SaveIcon } from '@images/svg/save.svg';
import { ReactComponent as CancelIcon } from '@images/svg/cancel.svg';
import { ReactComponent as Visibility } from '@images/svg/visibility.svg';
import {
    GridRowModesModel,
    GridRowModes,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowEditStopReasons,
} from '@mui/x-data-grid';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Book } from '@navigation/Book';

const limit = 10;

const AddressProgramData = ({ filters }) => {
    const paginationModelDefault = { page: 0, pageSize: limit };
    const { projectsList } = useSelector(ProjectsSelectors.projectsSelectors);
    const { periodList } = useSelector(FiltersSelectors.filtersState);
    const [isLoading, setLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState(paginationModelDefault);
    const [sortModel, setSortModel] = useState([]);
    const [data, setData] = useState<ProgramsResponse>({});
    const { items = [], total = 0, offset } = data || {};

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('translation');

    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [deleteIdAP, setDeleteIdAP] = useState<string>('');
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState<boolean>(false);
    const [fullPeriodList, setFullPeriodList] = useState<PeriodItem>([]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        handleOpenModal(id);
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = async (updatedRow: GridRowModel) => {
        const { id, period_id, name, deactivated_at } = updatedRow || {};
        const updatedItems = items.map((row) => (row.id === updatedRow.id ? updatedRow : row));
        setData({
            ...data,
            items: updatedItems,
        });
        updateProgram(id, {
            ...(period_id && { period_id }),
            ...(name && { name }),
            ...(deactivated_at && { deactivated_at: deactivated_at?.toISOString() }),
        })
            .then((res) => {
                if (res) {
                    getProgramsList(paginationModel?.page * limit, sortModel);
                }
            })
            .catch((error) => {
                enqueueSnackbar(error, { variant: 'error' });
            });

        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const navigate = useNavigate();
    const handleOpenProgram = (id, name) => {
        navigate(`${Book.address_program}/${id}`, { state: name });
    };

    const handleError = (e) => {
        enqueueSnackbar(e, { variant: 'error' });
    };

    const handleOpenModal = (id) => {
        setDeleteIdAP(id);
        setOpenDeleteConfirm(true);
    };
    const handleDeleteAP = (deleteIdAP) => {
        deleteAddressProgram(deleteIdAP)
            .then(() => {
                const newData = {
                    ...data,
                    items: items.filter((row) => row.id !== deleteIdAP),
                };
                setData(newData);
            })
            .catch((error) => {
                enqueueSnackbar(error, { variant: 'error' });
            });

        setOpenDeleteConfirm(false);
    };

    const handleCloseModal = () => {
        setOpenDeleteConfirm(false);
        setDeleteIdAP('');
    };

    const columns = [
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Действия',
            width: 120,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Visibility />}
                        label="View"
                        onClick={() => handleOpenProgram(id, '')}
                        color="inherit"
                    />,
                ];
            },
        },
        {
            field: 'period_name',
            headerName: 'Название периода',
            minWidth: 200,
            editable: true,
            type: 'singleSelect',
            valueOptions: fullPeriodList.map((period) => period.name),
            valueGetter: ({ value }) => {
                const { name } = fullPeriodList.find((period) => period.name === value) || {};
                return name ?? '';
            },
            valueSetter: (params) => {
                const { value } = params || {};
                const { id } = fullPeriodList.find((period) => period.name === value) || {};
                return { ...params.row, period_name: value, period_id: id };
            },
        },
        {
            field: 'start_time',
            headerName: 'Начало действия АП',
            minWidth: 180,
            valueGetter: ({ value }) => (value ? moment(value).format('DD.MM.yyyy') : ''),
        },
        {
            field: 'end_time',
            headerName: 'Окончание действия АП',
            minWidth: 180,
            valueGetter: ({ value }) => (value ? moment(value).format('DD.MM.yyyy') : value),
        },
        { field: 'project_name', headerName: 'Проект', minWidth: 200, sortable: false },
        { field: 'name', headerName: 'Название АП', minWidth: 250, flex: 1, editable: true },
        {
            field: 'deactivated_at',
            headerName: 'Дата деактивации АП',
            minWidth: 150,
            valueGetter: ({ value }) => (value ? new Date(value) : value),
            type: 'date',
            editable: true,
        },
    ];

    const getProgramsList = useCallback(
        (offset = 0, sortModel = []) => {
            setLoading(true);
            const [firstSort] = sortModel || [];
            const { field, sort } = firstSort || {};
            let sorting = {};
            if (field) sorting = { sorting: { [field]: sort } };

            fetchProgramsList({
                ...filters,
                ...sorting,
                pagination: {
                    limit,
                    offset,
                },
            }).then(({ programs }) => {
                setLoading(false);

                const items = programs?.items?.map((e) => ({
                    ...e,
                    project_name: projectsList.find((p) => p.id === e.project_id)?.full_name,
                }));

                setData({ ...programs, items });
            });
        },
        [projectsList, filters]
    );

    useEffect(() => {
        if (projectsList?.length > 0 || periodList?.length > 0) {
            getProgramsList(paginationModel?.page * limit, sortModel);
        }
    }, [sortModel, paginationModel, projectsList]);

    useEffect(() => {
        setPaginationModel(paginationModelDefault);
    }, [filters]);

    useEffect(() => {
        if (!fullPeriodList.length) {
            fetchFullPeriodList({
                sorting: {
                    start_time: 'desc',
                },
            }).then((res) => {
                if (res?.periods) {
                    setFullPeriodList(res?.periods?.items);
                }
            });
        }
    }, []);

    return (
        <>
            <TableGrid
                rows={items}
                columns={columns}
                rowCount={total}
                loading={isLoading}
                pageSizeOptions={[limit]}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                paginationModel={offset === 0 ? paginationModelDefault : paginationModel}
                sortingMode="server"
                onSortModelChange={setSortModel}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleError}
                slotProps={{
                    toolbar: { setData, setRowModesModel },
                }}
                hasSun
                className={styles.tableGrid}
            />
            <Dialog
                id="deleteApDialog"
                isOpenDialog={openDeleteConfirm}
                setCloseDialog={handleCloseModal}
                className={styles.dialog}
                title={t('address_program.deleteAP')}
                actions={
                    <>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleDeleteAP(deleteIdAP)}
                        >
                            {t('common.yes')}
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
                            {t('common.no')}
                        </Button>
                    </>
                }
            >
                {t('messages.deleteAPConfirm')}
            </Dialog>
        </>
    );
};

export default AddressProgramData;
