import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import { Lists } from '@components';
import { getCrowdFeedsList } from '@apiFeature/crowd';
import CrowdFeedList from './CrowdFeedList/CrowdFeedList';
import { FiltersThunks } from '@redux/filters/thunk';
import { useURLFilters } from '@tools/hooks/useURLFilters';
import { FilterValue } from '@apiFeature/types';

const defaultItemsPerPage = {
    label: '10',
    value: 10,
};

const CrowdFeedData = ({
    defaultValues,
    filters,
    clientPermission,
    isCheckers = false,
    nameFilters,
}) => {
    const dispatch = useDispatch();
    const [itemsPerPage, setItemsPerPage] = useState<FilterValue>(defaultItemsPerPage);
    const limit = Number(itemsPerPage.value);

    const { searchParams } = useURLFilters();

    let pageNumber;
    try {
        pageNumber = Number(atob(searchParams.get('page')));
    } catch {
        pageNumber = Number(searchParams.get('page'));
    }

    const offsetFromPageNumber = (pageNumber - 1) * limit;
    const paginationModelDefault = {
        offset: offsetFromPageNumber > 0 ? offsetFromPageNumber : 0,
        limit,
    };
    const { projectsList } = useSelector(ProjectsSelectors.projectsSelectors);
    const [isLoading, setLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState(paginationModelDefault);
    const [data, setData] = useState({ items: [], total: 0, offset: 0 });
    const { items = [], total = 0, offset } = data || {};

    const getCrowdFeedList = useCallback(
        (offset = 0, limit) => {
            setLoading(true);
            const [project] = projectsList || [];
            const { id } = project || {};
            const { project: defaultProjectId } = defaultValues || {};
            const project_id = projectsList.find((p) => p.id === defaultProjectId)?.id ?? id;
            const project_ids = [project_id];
            let filtersData = {
                project_ids,
                ...defaultValues,
                ...filters,
            };
            const { date_range: dr, project_id: prId, ...filtersRes } = filtersData;

            const resultFilters = {
                ...filtersRes,
                pagination: {
                    limit,
                    offset,
                },
            };
            getCrowdFeedsList(resultFilters).then((res) => {
                setLoading(false);

                setData(() => {
                    const resultItems = res?.feeds?.items || [];
                    const resultFeed = res?.feeds || {};
                    const data = { ...resultFeed, items: resultItems };
                    return data;
                });
            });

            if (Object.keys(filtersData)?.length > 0 && nameFilters) {
                dispatch(FiltersThunks.setFilters({ [nameFilters]: filtersData }));
            }
        },
        [projectsList, filters]
    );

    useEffect(() => {
        const { offset, limit } = paginationModel;
        if (projectsList?.length > 0) getCrowdFeedList(offset, limit);
    }, [paginationModel, projectsList]);

    useEffect(() => {
        if (Object.keys(filters)?.length > 0) setPaginationModel(paginationModelDefault);
    }, [filters]);

    useEffect(() => {
        setPaginationModel({
            ...paginationModel,
            limit: Number(itemsPerPage.value),
        });
    }, [itemsPerPage]);

    return (
        <Lists
            items={items}
            loading={isLoading}
            hasSun={!clientPermission}
            total={total}
            offset={offset}
            limit={limit}
            renderList={(props) => <CrowdFeedList {...props} isCheckers={isCheckers} />}
            onPaginationModelChange={setPaginationModel}
            pageNumber={pageNumber}
            setItemsPerPage={setItemsPerPage}
        />
    );
};

export default CrowdFeedData;
