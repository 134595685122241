import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '@components';
import SupportRequestsData from './SupportRequestsData/SupportRequestsData';
import SupportRequestsFilters from './SupportRequestsFilters/SupportRequestsFilters';
import { fixTimezoneOffset } from '@tools/utils/date.util';

export const SupportRequests = () => {
    const { t } = useTranslation('translation');

    const defaultValues = {};

    const getFilters = (values) => {
        let res = {};
        if (values.support_request_type_ids) {
            res = { support_request_type_ids: values?.support_request_type_ids }
        };
        if (values?.date_range) {
            res = {
                ...res,
                created_at_lower: fixTimezoneOffset(values?.date_range?.start, 'start').replace('Z', ''),
                created_at_upper: fixTimezoneOffset(values?.date_range?.end, 'end').replace('Z', ''),
            }
        }
        return res;
    };

    return (
        <Layout
            title={t('supportRequests.title')}
            renderData={({ filters }) => <SupportRequestsData filters={filters} />}
            renderFilters={SupportRequestsFilters}
            defaultValues={defaultValues}
            getFilters={getFilters}
        />
    );
};
