import { useRef, useEffect } from 'react';
import { isOutdated } from 'src/tools/utils/date.util';
import { JWTToken } from 'src/types/auth';
import { useAppSelector } from './redux';

const isValid = (token: JWTToken) => Boolean(token.token) && !isOutdated(token.expires_at);

export function useAuth(): { isAccessValid: boolean; isRefreshValid: boolean } {
    const { accessToken, refreshToken } = useAppSelector((state) => state.auth);
    const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

    const isAccessValid = isValid(accessToken);
    const isRefreshValid = isValid(refreshToken);

    useEffect(
        () => () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        },
        []
    );

    return { isAccessValid, isRefreshValid };
}
