import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorApi } from '../../apiFeature/types';
import { reduxStoreName } from './types';
import {
    fetchQuestionnairesList,
    uploadAssignment,
    fetchQuestionnaireDetailed,
    fetchUpdateQuestionnaire,
    fetchCreateQuestionnaire,
    fetchCreateQuestionnaireStep,
    fetchUpdateQuestionnaireStep,
    fetchCreateQuestionnaireQuestion,
    fetchUpdateQuestionnaireQuestion,
    fetchUpdateQuestionnaireQuestionMany,
    fetchUpdateQuestionnaires,
} from '../../apiFeature/questionnaires';
import {
    FilesUploadRequest,
    QuestionnairesDetailedRequest,
    QuestionnairesDetailedResponse,
    QuestionnairesListRequest,
    QuestionnairesResponse,
    UpdateQuestionnaireRequest,
    UpdateQuestionnaireResponse,
} from '../../apiFeature/questionnaires/type';

export module AssignmentThunks {
    export const upload = createAsyncThunk<any, FilesUploadRequest, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/upload`,
        async (arg, { rejectWithValue }) => {
            try {
                return await uploadAssignment(arg);
            } catch (e: any | unknown) {
                return rejectWithValue(e);
            }
        }
    );

    export const getQuestionnairesList = createAsyncThunk<
        QuestionnairesResponse,
        QuestionnairesListRequest,
        { rejectValue: ErrorApi }
    >(`${reduxStoreName}/getQuestionnairesList`, async (arg, { rejectWithValue }) => {
        try {
            return await fetchQuestionnairesList(arg ?? {});
        } catch (e: any | unknown) {
            return rejectWithValue(e);
        }
    });

    export const getQuestionnairesProjectsList = createAsyncThunk<
        QuestionnairesResponse,
        QuestionnairesListRequest,
        { rejectValue: ErrorApi }
    >(`${reduxStoreName}/getQuestionnairesProjectsList`, async (arg, { rejectWithValue }) => {
        try {
            return await fetchQuestionnairesList(arg ?? {});
        } catch (e: any | unknown) {
            return rejectWithValue(e);
        }
    });

    export const getQuestionnairesDetailedList = createAsyncThunk<
        QuestionnairesDetailedResponse,
        QuestionnairesDetailedRequest,
        { rejectValue: ErrorApi }
    >(`${reduxStoreName}/getQuestionnairesDetailedList`, async (arg, { rejectWithValue }) => {
        try {
            return fetchQuestionnaireDetailed(arg ?? {});
        } catch (e: any | unknown) {
            return rejectWithValue(e);
        }
    });

    export const updateQuestionnaire = createAsyncThunk<
        UpdateQuestionnaireResponse,
        UpdateQuestionnaireRequest,
        { rejectValue: ErrorApi }
    >(`${reduxStoreName}/updateQuestionnaire`, async (arg, { rejectWithValue }) => {
        try {
            return await fetchUpdateQuestionnaire(arg);
        } catch (e: any | unknown) {
            return rejectWithValue(e);
        }
    });

    export const createQuestionnaireStep = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/createQuestionnaireStep`,
        async (arg, { rejectWithValue }) => {
            try {
                return await fetchCreateQuestionnaireStep(arg ?? {});
            } catch (e: any | unknown) {
                return rejectWithValue(e);
            }
        }
    );

    export const updateQuestionnaireStep = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/updateQuestionnaireStep`,
        async (arg, { rejectWithValue }) => {
            try {
                return await fetchUpdateQuestionnaireStep(arg ?? {});
            } catch (e: any | unknown) {
                return rejectWithValue(e);
            }
        }
    );

    export const createQuestionnaireQuestion = createAsyncThunk<
        any,
        any,
        { rejectValue: ErrorApi }
    >(`${reduxStoreName}/createQuestionnaireQuestion`, async (arg, { rejectWithValue }) => {
        try {
            return await fetchCreateQuestionnaireQuestion(arg ?? {});
        } catch (e: any | unknown) {
            return rejectWithValue(e);
        }
    });

    export const updateQuestionnaireQuestion = createAsyncThunk<
        any,
        any,
        { rejectValue: ErrorApi }
    >(`${reduxStoreName}/updateQuestionnaireQuestion`, async (arg, { rejectWithValue }) => {
        try {
            return await fetchUpdateQuestionnaireQuestion(arg ?? {});
        } catch (e: any | unknown) {
            return rejectWithValue(e);
        }
    });

    export const updateQuestionnaireQuestionMany = createAsyncThunk<
        any,
        any,
        { rejectValue: ErrorApi }
    >(`${reduxStoreName}/updateQuestionnaireQuestionMany`, async (arg, { rejectWithValue }) => {
        try {
            return await fetchUpdateQuestionnaireQuestionMany(arg ?? {});
        } catch (e: any | unknown) {
            return rejectWithValue(e);
        }
    });

    export const updateQuestionnaires = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/updateQuestionnaires`,
        async (arg, { rejectWithValue }) => {
            try {
                return await fetchUpdateQuestionnaires(arg ?? {});
            } catch (e: any | unknown) {
                return rejectWithValue(e);
            }
        }
    );
}
