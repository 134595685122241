import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, PhotoSlider } from '@components';
import { useSnackbar } from 'notistack';
import { fetchFeedsPhotos } from '@api/visitFeed/visitFeed.api';
import { useAppSelector } from '@tools/hooks/redux';
import { permissionCheck } from '@tools/utils/permissionCheck';
import { getDateGMT } from '@tools/utils/date.util';
import SettingsSelectors from '@redux/settings/selectors';
import moment from 'moment';
import styles from './Photos.module.scss';
import { FileLink } from '@apiFeature/crowd/types';

interface IPhotos {
    visit_id?: string;
    resultFeed?: Record<string, unknown>;
    projectOutlet?: Record<string, unknown>;
    photos?: FileLink[];
    setPhotos: Dispatch<SetStateAction<FileLink[]>>;
    selectedPhotoIds?: string[];
    setSelectedPhotoIds: Dispatch<SetStateAction<string[]>>
}

const Photos: FC<IPhotos> = ({ visit_id, resultFeed, projectOutlet, photos, setPhotos, selectedPhotoIds, setSelectedPhotoIds }) => {
    const [isLoading, setLoading] = useState(false);

    const { t } = useTranslation('translation');
    const authRoles = useAppSelector((state) => state?.auth?.jwtPayloadOpen?.roles);
    const { settingsAll } = useAppSelector(SettingsSelectors.settingsState);
    const { settings } = settingsAll || {};
    const isHidePhotoVisitTime =
        settings?.find((s) => s.code === 'photo_visit_time')?.setting_values?.[0]?.val === 'false';
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!visit_id) return;
        getFeedsPhotos();
    }, [visit_id]);

    const getFeedsPhotos = async () => {
        setLoading(true);

        await fetchFeedsPhotos({ visit_id, is_preview: null })
            .then((images) => {
                setPhotos(images);
            })
            .catch((e) => enqueueSnackbar(e?.message, { variant: 'error' }));

        setLoading(false);
    };

    const { start_time, utc_offset } = resultFeed || {};
    const { address, name, longitude, latitude } = projectOutlet || {};
    const visitDateWithoutTime = moment(start_time).format('D MMMM YYYY');

    const photoDescription = {
        [t('feed.visitDate')]: {
            value: isHidePhotoVisitTime
                ? visitDateWithoutTime
                : getDateGMT(start_time, utc_offset, 'D MMMM YYYY, HH:mm:ss'),
            isVisible: true,
        },
        [t('feed.shopName')]: {
            value: name,
        },
        [t('feed.address')]: {
            value: address,
        },
        [t('feed.coordinates')]: {
            value: latitude && longitude && `${latitude}, ${longitude}`,
            isVisible: true,
        },
    };

    return (
        <div className={styles.photos}>
            {isLoading && <Loader />}
            {photos?.length > 0 && (
                <PhotoSlider
                    id={visit_id}
                    photos={photos}
                    type="list"
                    description={permissionCheck(authRoles, photoDescription)}
                    utc_offset={utc_offset}
                    isSelectable={true}
                    selectedPhotoIds={selectedPhotoIds}
                    setSelectedPhotoIds={setSelectedPhotoIds}
                />
            )}
        </div>
    );
};

export default Photos;
