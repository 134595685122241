import { createAction } from '@reduxjs/toolkit';
import { reduxStoreName } from './types';

module FeedsActions {
    export const resetAction = createAction(`${reduxStoreName}/resetAction`);
    export const editMode = createAction<boolean>(`${reduxStoreName}/editMode`);
    export const saveEditFields = createAction<any>(`${reduxStoreName}/saveEditFields`);
    export const cancelEdit = createAction(`${reduxStoreName}/cancelEdit`);
    export const savePhotos = createAction(`${reduxStoreName}/savePhotos`);
}
export default FeedsActions;
