import { clientFileServicePassword, clientPassword } from '../ClientPassword';
import {
    FileLinksRequest,
    FileLinksResponse,
    FileLinksStatusRequest,
    FileLinksStatusResponse,
    FilesUploadListRequestExtra,
    FilesUploadListResponse,
    FilesUploadRequest,
    FilesUploadResponse,
} from './types';

export const filesUpload = (request: FilesUploadRequest & { mime: string; name: string }) => {
    const fd = new FormData();
    const { object_id, file, visit_id, type, name, mime, user_id } = request;
    fd.append('file', file);
    fd.append('object_id', object_id);
    fd.append('type', type);
    fd.append('user_id', user_id);
    if (!!visit_id) {
        fd.append('visit_id', visit_id);
    }
    return clientFileServicePassword.post<FilesUploadResponse, FormData>(
        '/api/v1/files/upload/',
        fd,
        {
            timeout: 30000,
            headers: {
                'Content-Type': `multipart/form-data`,
                'Access-Control-Allow-Origin': 'http://localhost:3000/',
            },
        }
    );
};

export const filesUploadList = (request: FilesUploadListRequestExtra) => {
    const fd = new FormData();
    const { file, name, mime } = request;
    fd.append('file', file);
    fd.append('objects', JSON.stringify(request.objects));
    // "generic_type": "SFA_VISIT"
    return clientFileServicePassword.post<FilesUploadListResponse, FormData>(
        '/api/v1/files/upload/list/',
        fd,
        {
            timeout: 30000,
            headers: {
                'Content-Type': `multipart/form-data`,
            },
        }
    );
};

export const fileLinksStatus = (request: FileLinksStatusRequest) =>
    clientFileServicePassword.post<FileLinksStatusResponse, FileLinksStatusRequest>(
        '/api/v1/file_links/status/',
        request
    );

export const fileLinks = (request: FileLinksRequest) =>
    clientPassword.post<FileLinksResponse, FileLinksRequest>('/file_links/', request);
