import React, { ForwardedRef, forwardRef } from 'react';

import { TextareaAutosize } from '@mui/material';

import { TextAreaInputPropsType } from './TextAreaInput.type';
import { useTranslation } from 'react-i18next';

const TextAreaInputWithoutForward = (
    { value, label, placeholder, style, error, className, ...props }: TextAreaInputPropsType,
    ref: ForwardedRef<HTMLTextAreaElement>
) => {
    const { t } = useTranslation('translation');
    return (
        <>
            <label className="task-form__description-section task-form-section-textarea">
                {label}
                <TextareaAutosize
                    {...props}
                    ref={ref}
                    aria-label="maximum height"
                    className={`${className} task-form-section__input-textarea textarea`}
                    maxRows={5}
                    placeholder={placeholder}
                    style={style}
                    value={value}
                />
            </label>
            {error?.status && (
                <div className="input__error">{error.text || t('messages.fieldRequired')}</div>
            )}
        </>
    );
};

export const TextAreaInput = forwardRef<HTMLTextAreaElement, TextAreaInputPropsType>(
    TextAreaInputWithoutForward
);
