import React, { FC, FocusEventHandler } from 'react';
import classNames from 'classnames';
import BaseSelect, { MenuPosition, components as defaultComponents } from 'react-select';
import { InputOption } from 'src/types/common';
import { FormHelperText } from '@mui/material';
import Label from '@components/Label/Label';
import styles from './Select.module.scss';
import { useTranslation } from 'react-i18next';
import MenuNested from '../MenuNested/MenuNested';
export interface ISelect {
    name: string;
    label?: string;
    className?: string;
    placeholder?: string;
    value?: InputOption;
    options?: InputOption[];
    disabled?: boolean;
    menuPosition?: MenuPosition | undefined;
    isNested?: boolean;
    error?: {
        message: string;
    };
    onChange?: (option?: InputOption | null) => void;
    onBlur?: FocusEventHandler<HTMLInputElement>;
}

const Select: FC<ISelect> = ({
    name,
    label,
    className = '',
    value = '',
    placeholder,
    options,
    onChange,
    disabled,
    menuPosition = 'fixed',
    error,
    isNested = false,
    ...props
}) => {
    const { t } = useTranslation('translation');

    return (
        <div className={styles.selectWrapper}>
            {label && <Label htmlFor={name}>{label}</Label>}
            <BaseSelect
                {...props}
                id={''}
                inputId={name}
                name={name}
                onChange={onChange}
                options={options}
                value={value}
                className={classNames(styles.select, className, {
                    [styles.error]: error,
                })}
                classNamePrefix="r-select"
                isDisabled={disabled}
                placeholder={placeholder || t('common.pick')}
                menuPosition={menuPosition}
                components={{
                    ...defaultComponents,
                    ...(isNested && { MenuList: MenuNested }),
                }}
            />
            {error && <FormHelperText error>{error.message}</FormHelperText>}
        </div>
    );
};

export default Select;
