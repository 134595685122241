import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Tree,
    getBackendOptions,
    MultiBackend,
    TreeMethods,
    NodeModel,
} from '@minoru/react-dnd-treeview';
import { DndProvider } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import styles from './TeamData.module.scss';
import { Loader, Button } from '@components';
import { Book } from '@navigation/Book';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { fetchTeamList } from '@apiFeature/team';
import { getUsrProj, userAssignProject } from '@apiFeature/user';
import ProjectsSelectors from '@redux/projects/selectors';
import { getFullUserName } from '@tools/utils/users';
import { FiltersThunks } from '@redux/filters/thunk';
import { CustomNode } from './CustomNode/CustomNode';
import { Placeholder } from './Placeholder/Placeholder';

export const getManagersList = (res) =>
    res
        .map((i) => i?.manager)
        ?.filter((value, index, self) => {
            if (value) {
                return index === self.findIndex((t) => t?.id === value?.id);
            }
        });

const TeamData = ({ defaultValues, filters, form }) => {
    const { project_id: dpID } = defaultValues || {};
    const { project_id: pID } = filters || {};
    const projectId = pID || dpID;

    const { watch } = form;
    const currValues = watch();
    const { search = '', executor_role, manager_id, isActive } = currValues;

    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    const [allUsers, setAllUsers] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();
    const { projectsList } = useSelector(ProjectsSelectors.projectsSelectors);

    const getName = (e) => (
        <Link className={styles.link} to={`${Book.catalog.team}/${e.id}`}>
            <span className={styles.head}>
                <span className={styles.title}>{getFullUserName(e)}</span>
                <span
                    className={e.status === 'active' ? styles.activeStatus : styles.nonActiveStatus}
                >
                    {e.status === 'active' ? t('statuses.active') : t('statuses.inactive')}
                </span>
            </span>
            {e?.roles?.map((ee) => ee?.name).join(', ') || t('catalog.team.manager')}, {e?.phone}
        </Link>
    );

    function findManagers(item, allUsers) {
        let usersId = [];
        let currentManagerId = item?.manager?.id;

        while (currentManagerId) {
            const user = allUsers.find((u) => u?.user?.id === currentManagerId);
            if (user) {
                if (usersId.includes(user?.user?.id)) {
                    break;
                } else {
                    currentManagerId = user?.manager?.id;
                    usersId.push({ name: 'user', id: user?.user?.id });
                }
            } else {
                const manager = allUsers.find((u) => u?.manager?.id === currentManagerId);
                if (manager) {
                    usersId.push({ name: 'manager', id: manager?.manager?.id });
                }
                break;
            }
        }

        return usersId;
    }

    const getFiltered = (allUsers, name) => {
        const res = allUsers.filter((item) => {
            const {
                first_name: fNManager,
                last_name: lNManager,
                patronymic_name: pNManager,
                phone: pManager,
                status: sManager,
                roles: rManager,
                id: idManager,
            } = item?.manager || {};
            const {
                first_name: fNUser,
                last_name: lNUser,
                patronymic_name: pNUser,
                phone: pUser,
                status: sUser,
                roles: rUser,
            } = item?.user || {};

            const searchString =
                name === 'users'
                    ? `${fNUser ?? ''} ${lNUser ?? ''} ${pNUser ?? ''} ${pUser ?? ''}`
                    : `${fNManager ?? ''} ${lNManager ?? ''} ${pNManager ?? ''} ${pManager ?? ''}`;
            const status = name === 'users' ? sUser : sManager;
            const roles = name === 'users' ? rUser : rManager;

            const resSearch = search
                ? searchString.toUpperCase().indexOf(search.toUpperCase()) > -1
                : true;
            const resRoles = executor_role
                ? roles?.some((t) => t?.sys_name === executor_role)
                : true;
            const resManager = manager_id ? idManager === manager_id : true;
            const resStatus = isActive !== 'all'
                ? status === isActive
                : true;

            return resSearch && resRoles && resManager && resStatus;
        });

        const filteredUsers = allUsers.filter((e) => {
            if (name === 'users') {
                if (res.some((r) => r?.user?.id === e?.user?.id)) {
                    return { ...e };
                }
            } else {
                if (res.some((r) => r?.manager?.id === e?.manager?.id)) {
                    return { ...e };
                }
            }
        });

        return filteredUsers;
    };

    const getUsers = (users, managersList) => {
        const userWithoutManager = users.filter((e) => !e.manager && e.user).map((i) => i?.user);
        const topLvl = managersList.filter((z) => !users.find((v) => z?.id === v?.user?.id));
        const topRes = [...topLvl, ...userWithoutManager];

        const topData = topRes.map((e) => ({
            ...e,
            id: e.id,
            parent: 0,
            text: getName(e),
            droppable: true,
        }));

        const data = users.map((e) => ({
            ...e,
            id: e.user.id,
            parent: e?.manager?.id || 0,
            text: getName(e.user),
            droppable: true,
        }));

        const dataFil = data.flatMap((e) => findManagers(e, allUsers));

        const usersId = [...new Set(dataFil.filter((e) => e.name === 'user'))];
        const resUsersId = allUsers.filter((e) => usersId.some((u) => u.id === e?.user?.id));
        const dataUsers = resUsersId.map((e) => ({
            ...e,
            id: e.user.id,
            parent: e?.manager?.id || 0,
            text: getName(e.user),
            droppable: true,
        }));

        const managerId = [...new Set(dataFil.filter((e) => e.name === 'manager'))];
        const resManagerId = allUsers.filter((e) => managerId.some((u) => u.id === e?.manager?.id));
        const dataManagersList = getManagersList(resManagerId);
        const dataManagers = dataManagersList.map((e) => ({
            ...e,
            id: e.id,
            parent: 0,
            text: getName(e),
            droppable: true,
        }));

        const treeData = [...topData, ...dataManagers, ...data, ...dataUsers];

        const result = treeData
            .reduce((o, i) => {
                if (!o.find((v) => v.id == i.id)) {
                    o.push(i);
                }
                return o;
            }, [])
            .sort((a, b) => a.last_name > b.last_name);
        setTreeData(result);

        setTimeout(() => {
            if (expanded) ref.current?.openAll();
        }, 100);
    };

    const getData = useCallback(async () => {
        const [project] = projectsList || [];
        const { id } = project || {};

        const project_id = projectsList.find((p) => p.id === projectId)?.id ?? id;

        setLoading(true);
        fetchTeamList(project_id)
            ?.then((res) => {
                const { hierarchy: allUsers } = res || {};
                if (allUsers) {
                    const filteredUsers = getFiltered(allUsers, 'users');
                    const filteredManagers = getFiltered(allUsers, 'managers');
                    const managersList = getManagersList(filteredManagers);
                    getUsers(filteredUsers, managersList);
                    setAllUsers(allUsers);

                    dispatch(
                        FiltersThunks.getManagerProjectExecutors.fulfilled({
                            project: project_id,
                            allUsers: managersList,
                        })
                    );
                }
            })
            .catch((e) => enqueueSnackbar(e?.message, { variant: 'error' }))
            .finally(() => {
                setLoading(false);
            });
    }, [projectId, projectsList]);

    useEffect(() => {
        if (projectsList?.length > 0) {
            getData();
        }
    }, [projectId, projectsList]);

    useEffect(() => {
        if (allUsers?.length > 0) {
            const filteredUsers = getFiltered(allUsers, 'users');
            const filteredManagers = getFiltered(allUsers, 'managers');
            const managersList = getManagersList(filteredManagers);
            getUsers(filteredUsers, managersList);
        }
    }, [search, executor_role, manager_id, isActive]);

    const ref = useRef<TreeMethods>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [treeData, setTreeData] = useState<NodeModel[]>([]);

    const handleExpandClick = () => {
        !expanded ? ref.current?.openAll() : ref.current?.closeAll();
        setExpanded(!expanded);
    };

    const handleDrop = (newTreeData: NodeModel[], options) => {
        const { dragSourceId: user_id, dropTargetId: manager_id } = options || {};

        if (user_id && projectId) {
            setTreeData(newTreeData);

            setLoading(true);
            getUsrProj({
                user_id,
            })
                .then(({ user_projects }) => {
                    const other_user_projects = user_projects
                        .map(({ project_id, manager_id }) => ({
                            project_id,
                            manager_id,
                        }))
                        .filter(({ project_id }) => project_id !== projectId);

                    userAssignProject({
                        user_id,
                        request: [
                            ...other_user_projects,
                            {
                                project_id: projectId,
                                manager_id: manager_id || undefined,
                            },
                        ],
                    })
                        .then(() => {
                            enqueueSnackbar(t('messages.userManagerChanged'), {
                                variant: 'success',
                            });
                        })
                        .catch((e) => enqueueSnackbar(e?.message, { variant: 'error' }))
                        .finally(() => {
                            setLoading(false);
                        });
                })
                .catch((e) => enqueueSnackbar(e?.message, { variant: 'error' }));
        }
    };

    return (
        <div className={styles.team}>
            {loading ? <Loader /> : <div className={styles.empty}>{t('catalog.team.empty')}</div>}
            {treeData?.length > 0 && (
                <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                    <div className={styles.treeData}>
                        <Button
                            color="secondary"
                            className={styles.expandToggle}
                            onClick={handleExpandClick}
                        >
                            {!expanded ? t('buttons.expandAll') : t('buttons.сollapseAll')}
                        </Button>
                        <Tree
                            ref={ref}
                            tree={treeData}
                            rootId={0}
                            placeholderRender={(_, { depth }) => <Placeholder depth={depth} />}
                            render={(node, { depth, isOpen, onToggle, handleRef }) => (
                                <CustomNode
                                    node={node}
                                    depth={depth}
                                    isOpen={isOpen}
                                    onToggle={onToggle}
                                    handleRef={handleRef}
                                />
                            )}
                            enableAnimateExpand={true}
                            onDrop={handleDrop}
                            classes={{
                                root: styles.treeRoot,
                                draggingSource: styles.draggingSource,
                                dropTarget: styles.dropTarget,
                            }}
                        />
                    </div>
                </DndProvider>
            )}
        </div>
    );
};

export default TeamData;
