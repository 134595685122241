import React, { FC } from 'react';
import styles from './StepList.module.scss';
import { TQuestionnaireStep } from '@apiFeature/questionnaires/type';
import StepItem from './StepItem/StepItem';

interface IStepList {
    steps?: TQuestionnaireStep[];
    registerStr: string;
    expandedAll: boolean;
}

const StepList: FC<IStepList> = ({ steps, registerStr, expandedAll }) => {
    return (
        <div className={styles.stepList}>
            {steps?.map((step) => (
                <StepItem
                    key={step.id}
                    step={step}
                    registerStr={`${registerStr}.questionnaire_steps[${step.id}]`}
                    expandedAll={expandedAll}
                />
            ))}
        </div>
    );
};

export default StepList;
