import React, { FC, useEffect, useState } from 'react';
import styles from './TaskInfo.module.scss';
import { TaskReportType } from '@apiFeature/tasks/types';
import { fetchTasksListV3, updateTaskReport, taskCreate } from '@apiFeature/tasks/tasks';
import { useTranslation } from 'react-i18next';
import ListMapper from '@components/Lists/List/ListMapper/ListMapper';
import {
    Button,
    Checkbox,
    PhotoSlider,
    Dialog,
    Loader,
    Select,
    TextareaAutosize,
    DatePicker,
} from '@components';
import { useAppSelector } from '@tools/hooks/redux';
import { useRoles } from '@tools/hooks/useRoles';
import { permissionCheck } from '@tools/utils/permissionCheck';
import { useForm, Controller } from 'react-hook-form';
import { taskStatusOptions } from '@constants';
import { convertToOriginalDate } from '@tools/utils/utcOffsetConvert';
import { TaskItem } from '@apiFeature/crowd/types';
import { User } from '@apiFeature/types';
import { getUserById } from '@apiFeature/user';
import { useSnackbar } from 'notistack';
import { extractErrorMessage } from '@tools/utils/functions';
import { getFullName } from '@tools/utils/users';
import TaskCreateDialog from './TaskCreateDialog/TaskCreateDialog';
import { formatters, fixTimezoneOffset } from '@tools/utils/date.util';
import moment from 'moment';

interface ITaskInfo {
    visit_id?: string;
    task_report?: TaskReportType;
    isChecker?: boolean;
    photoDescription?: Record<string, unknown>;
    utc_offset?: number | null;
    setTaskReports: ({}) => void;
}

const TaskInfo: FC<ITaskInfo> = ({ task_report, visit_id, setTaskReports }) => {
    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();
    const {
        id: task_report_id,
        task_id,
        status,
        report,
        end_time: executedAt,
        utc_offset: reportOffset,
        user_id,
        file_links,
    } = task_report || {};
    const { comment } = report || {};
    const [taskItem, setTaskItem] = useState<TaskItem>(null);
    const [author, setAuthor] = useState<string | null>(null);
    const [executor, setExecutor] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const authRoles = useAppSelector((state) => state?.auth?.jwtPayloadOpen?.roles);
    const { isClient, clientPermission, isTaskReportUpdateAllowed } = useRoles();

    const {
        created_by,
        created_at,
        deadline,
        is_required,
        is_photo_required,
        description,
        utc_offset: taskOffset,
        project_outlet,
    } = taskItem || {};
    const statusValue = status
        ? taskStatusOptions.find((opt) => opt.value === status)?.label
        : null;
    const createdAtOffset = convertToOriginalDate(created_at, taskOffset);
    const deadlineOffset = convertToOriginalDate(deadline, taskOffset);
    const executedAtOffset = convertToOriginalDate(executedAt, reportOffset);
    const { id: project_outlet_id } = project_outlet || {};
    const form = useForm({
        mode: 'onChange',
        defaultValues: {
            isEdit: false,
            is_requiredEdit: is_required,
            is_photo_requiredEdit: is_photo_required,
            deadlineEdit: deadline,
            descriptionEdit: description,
            statusEdit: status,
            executedAtEdit: fixTimezoneOffset(executedAt),
            commentEdit: comment,
        },
    });
    const { reset, setValue, control, handleSubmit, watch } = form;
    const { isEdit, deadlineEdit, descriptionEdit, is_requiredEdit, is_photo_requiredEdit } =
        watch();

    const handleEdit = () => {
        if (isEdit) {
            reset();
        } else {
            setValue('isEdit', !isEdit);
        }
    };

    useEffect(() => {
        if (task_id) {
            fetchTasksListV3({
                ids: [task_id],
            })
                .then((result) => {
                    const { items } = result || {};
                    const [task] = items || [];
                    setTaskItem(task);
                    const { created_by } = task || {};
                    if (created_by) {
                        getUserById(created_by)
                            .then((result) => {
                                const { user } = result || {};
                                const userInfo = getFullName(user);
                                setAuthor(userInfo);
                            })
                            .catch((error) => {
                                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                            });
                    }
                })
                .catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                });
        }
    }, [task_id]);

    useEffect(() => {
        if (user_id) {
            getUserById(user_id)
                .then((result) => {
                    const { user } = result || {};
                    const userInfo = getFullName(user);
                    setExecutor(userInfo);
                })
                .catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                });
        }
    }, [created_by]);

    const handleCreateTask = () => {
        const dayplus = formatters.toDatePlusDay(deadlineEdit);
        const deadlineDate = moment(dayplus)
            .subtract(1, 'seconds')
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        setLoadingCreate(true);
        const taskPayload = {
            caused_by_visit_id: visit_id,
            project_outlet_id,
            description: descriptionEdit,
            deadline: deadlineDate,
            is_required: !!is_requiredEdit,
            is_photo_required: !!is_photo_requiredEdit,
            user_type: 'INTERNAL',
        };

        taskCreate(taskPayload)
            .then(() => {
                enqueueSnackbar(t('tasks.createSuccess'), { variant: 'success' });
                setModalOpen(false);
                reset();
            })
            .catch((error) => {
                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
            })
            .finally(() => {
                setLoadingCreate(false);
            });
    };

    const handleCancel = () => {
        setModalOpen(false);
        reset();
    };

    const onSubmit = ({ isEdit, ...data }) => {
        setLoading(true);
        setValue('isEdit', false);

        const { commentEdit, executedAtEdit, statusEdit } = data;
        updateTaskReport(task_report_id, {
            end_time: executedAtEdit,
            report: {
                ...report,
                comment: commentEdit,
            },
            status: statusEdit,
        })
            .then((result) => {
                enqueueSnackbar(t('tasks.reportUpdated'), { variant: 'success' });
                const { task_report } = result || {};
                setTaskReports((prevState) => {
                    return prevState.map((report) => {
                        if (report?.id === task_report?.id) {
                            return {
                                ...task_report,
                                file_links: report?.file_links,
                            };
                        } else return report;
                    });
                });
            })
            .catch((error) => {
                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const upperFields = {
        [t('tasks.authorTask')]: { value: author ?? '-' },
        [t('tasks.setDate')]: { value: createdAtOffset ?? '-' },
        [t('tasks.deadline')]: { value: deadlineOffset ?? '-' },
        [t('tasks.description')]: { value: description ?? '-' },
    };

    const lowerFields = {
        [t('feed.taskStatus')]: {
            value: isEdit ? (
                <Controller
                    name="statusEdit"
                    control={control}
                    render={({ field: { name, value, ref } }) => (
                        <div className={styles.formItem}>
                            <Select
                                id={name}
                                name={name}
                                inputRef={ref}
                                onChange={(e) => {
                                    setValue(name, e?.value || null);
                                }}
                                value={taskStatusOptions?.find((e) => e.value === value)}
                                options={taskStatusOptions}
                            />
                        </div>
                    )}
                />
            ) : (
                statusValue ?? '-'
            ),
        },
        [t('tasks.finishedTime')]: {
            value: isEdit ? (
                <Controller
                    name="executedAtEdit"
                    control={control}
                    render={({ field: { name, value, ref, onChange }, fieldState: { error } }) => (
                        <div className={styles.formItem}>
                            <DatePicker
                                id={name}
                                name={name}
                                inputRef={ref}
                                onChange={onChange}
                                value={value}
                                enable={[]}
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                timeIntervals={1}
                                timeCaption="Time"
                                showTimeSelect
                                timeFormat="HH:mm:ss"
                                error={error}
                            />
                        </div>
                    )}
                />
            ) : (
                executedAtOffset ?? '-'
            ),
        },
        [t('tasks.comment')]: {
            value: isEdit ? (
                <Controller
                    name="commentEdit"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                        <div className={styles.comment}>
                            <TextareaAutosize
                                onChange={onChange}
                                minRows={4}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                            />
                        </div>
                    )}
                />
            ) : (
                comment ?? '-'
            ),
        },
    };

    return (
        <div className={styles.taskWrapper}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {loading && <Loader />}
                <div className={styles.taskBlock}>
                    <div className={styles.header}>
                        <div className={styles.title}>{t('tasks.taskInVisit')}</div>
                        <div className={styles.btns}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    setModalOpen(true);
                                }}
                            >
                                {t('buttons.resume')}
                            </Button>
                        </div>
                    </div>
                    <div className={styles.items}>
                        <div>
                            <ListMapper
                                items={permissionCheck(authRoles, upperFields)}
                                className={styles.serializedFeedItem}
                            />
                        </div>
                        <Checkbox
                            value={!!is_required}
                            label={t('tasks.isRequiredTask')}
                            labelPlacement="start"
                            className={styles.isRequired}
                            disabled
                        />
                        <Checkbox
                            value={!!is_photo_required}
                            label={t('tasks.photoRequired')}
                            labelPlacement="start"
                            className={styles.isRequired}
                            disabled
                        />
                    </div>
                </div>
                <div className={styles.taskBlock}>
                    <div className={styles.header}>
                        <div className={styles.title}>{t('tasks.taskReport')}</div>
                        <div className={styles.btns}>
                            <>
                                {isEdit ? (
                                    <>
                                        <Button variant="contained" color="secondary" type="submit">
                                            {t('buttons.save')}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={handleEdit}
                                        >
                                            {t('buttons.cancel')}
                                        </Button>
                                    </>
                                ) : (
                                    !isClient &&
                                    isTaskReportUpdateAllowed && (
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={handleEdit}
                                        >
                                            {t('buttons.edit')}
                                        </Button>
                                    )
                                )}
                            </>
                        </div>
                    </div>
                    <div className={styles.items}>
                        <div>
                            <ListMapper
                                items={permissionCheck(authRoles, lowerFields)}
                                className={styles.serializedFeedItem}
                            />
                        </div>
                        {file_links?.length > 0 && (
                            <PhotoSlider
                                photos={file_links}
                                type="list"
                                utc_offset={reportOffset}
                                hasDeleteBtn={!clientPermission}
                                isSmall
                            />
                        )}
                    </div>
                </div>
                {isModalOpen && (
                    <Dialog
                        id="taskCreateModal"
                        isOpenDialog={isModalOpen}
                        setCloseDialog={handleCancel}
                        title={t('tasks.resumeTask')}
                        actions={
                            <>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handleCreateTask()}
                                    disabled={loadingCreate}
                                >
                                    {t('buttons.resume')}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleCancel}
                                    disabled={loadingCreate}
                                >
                                    {t('buttons.cancel').toLocaleUpperCase()}
                                </Button>
                            </>
                        }
                    >
                        <TaskCreateDialog
                            setValue={setValue}
                            deadlineEdit={deadlineEdit}
                            descriptionEdit={descriptionEdit}
                            is_requiredEdit={is_requiredEdit}
                            is_photo_requiredEdit={is_photo_requiredEdit}
                            loading={loadingCreate}
                        />
                    </Dialog>
                )}
            </form>
        </div>
    );
};

export default TaskInfo;
