import { createAction } from "@reduxjs/toolkit"
import { reduxStoreName } from "./types"
import { AuthResponse } from "@apiFeature/auth/types";

module AuthActions {
    export const resetAction = createAction(`${reduxStoreName}/resetAction`);

    export const logout = createAction(`${reduxStoreName}/logout`);

    export const saveAuth = createAction<AuthResponse>(`${reduxStoreName}/saveAuth`);

    export const resetError = createAction(`${reduxStoreName}/resetError`);
}
export default AuthActions;