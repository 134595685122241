import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { List, ListItemButton, Collapse } from '@mui/material';
import { IData } from '../Items';
import styles from './Children.module.scss';

const Children: FC<IData> = ({
    label,
    children,
    onClick = () => {},
    isOpen,
    isActive,
    ...props
}) => {
    const [open, setOpen] = useState(isOpen || false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItemButton
                className={classNames(styles.listItemButton, {
                    [styles.listItemChildren]: children,
                    [styles.listItemOpen]: children && open,
                })}
                {...props}
                onClick={children ? handleClick : onClick}
            >
                {label}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {Array.isArray(children) ? (
                        children?.map(({ id, isActive, label, ...item }, ii) => (
                            <ListItemButton
                                key={id || ii}
                                className={classNames(styles.listItemSubButton, {
                                    [styles.listItemSubActive]: isActive,
                                })}
                                {...item}
                            >
                                {label}
                            </ListItemButton>
                        ))
                    ) : (
                        <div className={styles.children}>{children}</div>
                    )}
                </List>
            </Collapse>
        </>
    );
};

export default Children;
