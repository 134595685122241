import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { Backdrop } from '@mui/material';
import styles from './Loader.module.scss';

interface ILoader {
    className?: string | undefined;
    isPortal?: boolean;
}

const LoaderContent: FC<ILoader> = ({ className = '', isPortal }) => {
    return (
        <Backdrop
            className={classNames(styles.backdrop, className, { [styles.isPortal]: isPortal })}
            open={true}
        >
            <div className={styles.preloader}>
                <svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                        opacity=".25"
                    />
                    <path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z">
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            dur="0.75s"
                            values="0 12 12;360 12 12"
                            repeatCount="indefinite"
                        />
                    </path>
                </svg>
            </div>
        </Backdrop>
    );
};

const Loader: FC<ILoader> = ({ className = '', isPortal = true }) => {
    return isPortal ? (
        createPortal(<LoaderContent className={className} />, document.body)
    ) : (
        <LoaderContent className={className} />
    );
};

export default Loader;
