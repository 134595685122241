import React from 'react';
import styles from './AddressProgram.module.scss';
import { useTranslation } from 'react-i18next';
import { Layout } from '@components';
import AddressProgramData from './AddressProgramData/AddressProgramData';
import AddressProgramFilters from './AddressProgramFilters/AddressProgramFilters';

export const AddressProgram = () => {
    const { t } = useTranslation('translation');
    const defaultValues = {
        projectsAP: '',
        period: '',
        ap: '',
        is_deactivated: false,
    };

    const getFilters = (values) => {
        let res = {};
        if (values.projectsAP) res = { ...res, project_ids: values?.projectsAP };
        if (values.period) res = { ...res, period_ids: values?.period };
        if (values.ap) res = { ...res, name: values?.ap };
        if (values.is_deactivated) res = { ...res, is_deactivated: !values?.is_deactivated };
        return res;
    };

    return (
        <Layout
            title={t('address_program.title')}
            renderData={(props) => <AddressProgramData {...props} />}
            renderFilters={AddressProgramFilters}
            defaultValues={defaultValues}
            getFilters={getFilters}
        />
    );
};
