import React, { FC, Fragment } from 'react';
import classNames from 'classnames';
import { List, ListItemButton } from '@mui/material';
import Children from './Children/Children';
import styles from './Items.module.scss';

export interface IData {
    label: string;
    onClick: () => void;
    id?: string;
    children?: IData[] | string;
    isActive?: boolean;
    isOpen?: boolean;
}

interface IItems {
    className?: string;
    data: IData[];
}

const Items: FC<IItems> = ({ className = '', data, ...props }) => {
    return (
        <List {...props} className={classNames(styles.list, className)} disablePadding>
            {data.map(({ id, label, children, isActive, ...e }, i) => (
                <Fragment key={id || i}>
                    {!children ? (
                        <ListItemButton
                            className={classNames(styles.listItemButton, {
                                [styles.listItemActive]: isActive,
                            })}
                            {...e}
                        >
                            {label}
                        </ListItemButton>
                    ) : (
                        <Children label={label} children={children} {...e} />
                    )}
                </Fragment>
            ))}
        </List>
    );
};

export default Items;
