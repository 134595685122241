import React, { useEffect, useState } from 'react';
import { Listing, ListingProps } from 'src/view/components/Listing/Listing';
import { useTranslation } from 'react-i18next';
import { getCrowdTaskComplexityList, getCrowdTaskList, getTaskTypeList } from '@apiFeature/crowd';
import { FieldTypes, RequestSelectField } from 'src/view/components/Fields/Fields.type';
import { getProjectOutletsList } from '@apiFeature/projectOutlets';
import { FormFieldType } from 'src/view/components/ComplexForm/ComplexForm.type';
import { Table } from 'src/view/components/Table';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import style from './style/CrowdPage.module.scss';
import { useNavigate } from 'react-router-dom';

interface CrowdItem {
    id: string;
    start_time: string;
    end_time: string;
    is_ended_in_place: boolean;
}

const arrayToHashmap = (array: Array<any>) => {
    const hashMap = {};
    array.forEach((item) => {
        hashMap[item.id] = item;
    });
    return hashMap;
};

export const CrowdPage = () => {
    const [crowdItems, setCrowdItems] = useState([]);
    const [taskComplexity, setTaskComplexity] = useState({});
    const [taskType, setTaskType] = useState({});
    const { t } = useTranslation('translation');
    const navigate = useNavigate();

    const formatTime = (time: string) => {
        return format(new Date(time), 'dd.MM.yy', { locale: ru });
    };

    useEffect(() => {
        getCrowdTaskComplexityList({}).then((task_complexities) => {
            setTaskComplexity(arrayToHashmap(task_complexities));
        });

        getTaskTypeList({}).then((task_types) => {
            setTaskType(arrayToHashmap(task_types));
        });
    }, []);

    const listingProps: ListingProps<CrowdItem> = {
        title: t('crowd.title'),
        showTotal: false,
        buttons: [
            {
                text: t('crowd.createTask'),
                onClick: () => navigate(`/crowd/create/details`),
                type: 'primary',
            },
            {
                text: t('importJournal.import'),
                onClick: () => {},
            },
        ],
        searchInFilter: '',
        sort: {
            list: [],
        },
        defaultFilter: {
            project_outlet_ids: null,
            date_range: {
                date_to: null,
                date_from: null,
            },
            task_type_ids: null,
            task_complexity_ids: null,
        },
        fields: [
            {
                key: 'project_outlet_ids',
                field: {
                    label: t('common.project_outlet'),
                    type: FieldTypes.RequestSelect,
                    name: 'Project',
                    placeholder: t('common.all'),
                    optionsLoaderConfig: {
                        mapper: (data) => ({
                            label: data?.name + ' ' + data.address,
                            value: data?.id,
                        }),
                        request: (request) => getProjectOutletsList(request),
                    },
                } as RequestSelectField<any>,
            },
            {
                key: 'date_range',
                field: {
                    label: t('common.date_range') || '',
                    placeholder: t('common.select'),
                    name: 'date_range',
                    type: FormFieldType.DateRange,
                },
            },
            {
                key: 'task_type_ids',
                field: {
                    label: t('crowd.task_type'),
                    type: FieldTypes.RequestSelect,
                    name: 'Project',
                    placeholder: t('common.all'),
                    optionsLoaderConfig: {
                        mapper: (data) => ({ label: data?.name, value: data?.id }),
                        request: (request) => getTaskTypeList(request),
                    },
                } as RequestSelectField<any>,
            },
            {
                key: 'task_complexity_ids',
                field: {
                    label: t('crowd.task_complexity'),
                    type: FieldTypes.RequestSelect,
                    name: 'Project',
                    placeholder: t('common.all'),
                    optionsLoaderConfig: {
                        mapper: (data) => ({ label: data?.name, value: data?.id }),
                        request: (request) => getCrowdTaskComplexityList(request),
                    },
                } as RequestSelectField<any>,
            },
        ],
        request(filter) {
            const filterToRequest = {};

            if (filter['project_outlet_ids']) {
                filterToRequest['project_outlet_ids'] = [filter['project_outlet_ids']];
            }
            if (filter['task_type_ids']) {
                filterToRequest['task_type_ids'] = [filter['task_type_ids']];
            }

            if (filter['task_complexity_ids']) {
                filterToRequest['task_complexity_ids'] = [filter['task_complexity_ids']];
            }
            if (filter['date_from']) {
                filterToRequest['start_date_lower'] =
                    new Date(filter['date_from']).getTime() - 60 * 60 * 24 * 1000;
            }
            if (filter['date_to']) {
                filterToRequest['start_date_upper'] =
                    new Date(filter['date_to']).getTime() + 60 * 60 * 24 * 1000;
            }

            return getCrowdTaskList(filterToRequest).then((crowd) => {
                setCrowdItems(crowd);
                return crowd;
            });
        },
    };

    const tableHead = [
        {
            render: (data: any) => (
                <p
                    onClick={() => navigate(`/crowd/edit/${data.id}/details`)}
                    className={style.crowdNameCell}
                >
                    {data.description}
                </p>
            ),
            headComponent: () => <td>{t('common.name')}</td>,
        },
        {
            render: (data: any) => data?.project_outlet?.name,
            headComponent: () => <td>{t('common.project_outlet')}</td>,
        },
        {
            render: (data: any) => (
                <p>
                    {formatTime(data.start_date)} - {formatTime(data.end_date)}
                </p>
            ),
            headComponent: () => <td>{t('crowd.actual_period')}</td>,
        },
        {
            render: (data: any) => taskType[data.task_type_id]?.name,
            headComponent: () => <td>{t('crowd.type')}</td>,
        },
        {
            render: (data: any) => taskComplexity[data.task_complexity_id]?.name,
            headComponent: () => <td>{t('crowd.complexity')}</td>,
        },
    ];

    return (
        <Listing {...listingProps}>
            <Table head={tableHead} data={crowdItems} />
        </Listing>
    );
};
