import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useNavigationType, useParams } from 'react-router-dom';
import { Button } from '@components';
import styles from './ActionsBtns.module.scss';
import { Book } from '@/navigation/Book';

interface IActionsBtns {
    setValue: (name: string, value: boolean) => void;
    isEdit: boolean;
    isUser?: boolean;
}

const ActionsBtns: FC<IActionsBtns> = ({ setValue, isEdit, isUser }) => {
    const { userId } = useParams();
    const { t } = useTranslation('translation');

    const navigate = useNavigate();
    const navigationType = useNavigationType();
    const handleCancel = () => {
        isEdit && userId
            ? setValue('isEdit', false)
            : navigate(navigationType === 'POP' ? Book.catalog[isUser ? 'users' : 'team'] : -1);
    };

    const handleEdit = (e) => {
        e.preventDefault();
        setValue('isEdit', true);
    };

    return (
        <div className={styles.btns}>
            {!isEdit && userId ? (
                <Button variant="contained" color="secondary" onClick={handleEdit}>
                    {t('buttons.edit')}
                </Button>
            ) : (
                <Button variant="contained" color="secondary" type="submit">
                    {t('buttons.save')}
                </Button>
            )}
            <Button variant="outlined" color="secondary" onClick={handleCancel}>
                {t('buttons.cancel')}
            </Button>
        </div>
    );
};

export default ActionsBtns;
