import React, { FC } from 'react';
import { Menu as MN, MenuItem } from '@mui/material';
import classNames from 'classnames';
import styles from './Menu.module.scss';

interface IMenuItem {
    id?: string;
    label: string;
    onClick?: () => void;
}

interface IMenu {
    id: string;
    open: boolean;
    anchorEl: HTMLButtonElement | null;
    className?: string | undefined;
    items: IMenuItem[];
}

const Menu: FC<IMenu> = ({
    className,
    id = 'menu',
    open = false,
    anchorEl = null,
    items,
    ...props
}) => {
    return (
        <MN
            {...props}
            className={classNames(styles.menu, className)}
            id={open ? id : undefined}
            open={open}
            anchorEl={anchorEl}
        >
            {items.map((item, i) => (
                <MenuItem key={item.id || i} onClick={item.onClick}>
                    {item.label}
                </MenuItem>
            ))}
        </MN>
    );
};

export default Menu;
