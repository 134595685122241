import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { permissionCheck } from '@tools/utils/permissionCheck';
import { Button } from '@components';
import ListMapper from '@components/Lists/List/ListMapper/ListMapper';
import { useAppSelector } from '@tools/hooks/redux';
import { ProjectOutlet } from '@types/ProjectOutlets';
import { Book } from '@navigation/Book';
import styles from './OutletsList.module.scss';

const OutletsList: FC<ProjectOutlet> = (props) => {
    const authRoles = useAppSelector((state) => state?.auth?.jwtPayloadOpen?.roles);
    const { t } = useTranslation('translation');
    const navigate = useNavigate();

    const {
        id,
        name,
        outlet_type,
        external_code,
        address,
        project_retail_name,
        branch_name,
        territory_name,
        city_name,
        visit_id,
    } = props;

    const serializedFeedItem = {
        [t('feed.type')]: {
            value: outlet_type ?? '-',
        },
        [t('common.outletCode')]: {
            value: external_code ?? '-',
        },
        [t('feed.address')]: {
            value: address ?? '-',
        },
        [t('common.retail')]: {
            value: project_retail_name ?? '-',
        },
        [t('feed.branch')]: {
            value: branch_name ?? '-',
        },
        [t('common.territory')]: {
            value: territory_name ?? '-',
        },
        [t('common.city')]: {
            value: city_name ?? '-',
        },
    };

    const openOutlet = () => {
        navigate(`${Book.catalog.outlets}/${id}`);
    };

    const openVisit = () => {
        navigate(`/visit_feed/${visit_id}`);
    };

    return (
        <>
            <div className={styles.header}>
                <div className={styles.item}>
                    <div className={styles.name}>{name}</div>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.item}>
                    <ListMapper
                        items={permissionCheck(authRoles, serializedFeedItem)}
                        className={styles.serializedFeedItem}
                    />
                </div>
            </div>
            <div className={styles.footer}>
                <Button color="secondary" variant="outlined" onClick={openOutlet}>
                    {t('buttons.view')}
                </Button>
                {visit_id && (
                    <Button color="secondary" variant="outlined" onClick={openVisit}>
                        {t('buttons.viewVisit')}
                    </Button>
                )}
            </div>
        </>
    );
};

export default OutletsList;
