import { clientPassword } from '../ClientPassword';
import {
    ProjectsListRequest,
    ProjectsListResponse,
    ProjectResponse,
    ProjectRequest,
} from './types';

export const fetchProjectsList = (request: ProjectsListRequest) => {
    const res = clientPassword.post<ProjectsListResponse, ProjectsListRequest>(
        '/projects/list/',
        request
    );
    return res;
};

export const fetchProjectsListV2 = (request: ProjectsListRequest) => {
    const res = clientPassword.post<ProjectsListResponse, ProjectsListRequest>(
        '/v2/projects/list/',
        request
    );
    return res;
};

export const getProjectById = (id: string) => {
    return clientPassword.get(`/projects/${id}/`);
};

export const fetchProject = (id: string) => {
    return clientPassword.get<ProjectResponse, ProjectRequest>(`/projects/${id}/`);
};

export const updateProject = async (id: string, data: any) => {
    return clientPassword.patch(`/projects/${id}/`, data);
};

export const createProject = async (data: any) => {
    return clientPassword.post('/projects/', data);
};
