import React from 'react';

function PersonIcon({ color = '#414152' }) {
    return (
        <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.78125 5.8125C6.11019 5.8125 7.1875 4.73519 7.1875 3.40625C7.1875 2.07731 6.11019 1 4.78125 1C3.45231 1 2.375 2.07731 2.375 3.40625C2.375 4.73519 3.45231 5.8125 4.78125 5.8125Z" stroke={color} stroke-width="2" stroke-miterlimit="10" stroke-linejoin="round" />
            <path d="M4.78125 8.53125C2.71875 8.53125 1 11.625 1 15.0625H3.40625V18.5H6.15625V15.0625H8.5625C8.5625 11.625 6.84375 8.53125 4.78125 8.53125Z" stroke={color} stroke-width="2" stroke-miterlimit="10" stroke-linejoin="round" />
        </svg>
    );
}

export default PersonIcon;