import { clientPassword } from '../ClientPassword';
import {
    TagsListRequestType,
    TagsListResponseType,
    TagCreateRequestType,
    TagCreateResponseType,
    TagsResponseType,
} from './types';

export const fetchTagsList = async (request: TagsListRequestType) => {
    const res = await clientPassword.post<TagsListResponseType, TagsListRequestType>(
        '/tags/list/',
        request
    );
    return res?.tags;
};

export const fetchTagCreate = (request: TagCreateRequestType) => {
    return clientPassword.post<TagCreateResponseType, TagCreateRequestType>('/taggables/', request);
};

export const fetchTagDelete = (taggable_id: string) => {
    if (taggable_id) {
        return clientPassword.delete<TagsResponseType, { taggable_id: string }>(
            `taggables/${taggable_id}/`
        );
    }
};

export const fetchTagsByEntityId = (taggable_id: string) => {
    if (taggable_id) {
        return clientPassword.get<TagsResponseType, { taggable_id: string }>(
            `taggables/tags_by_entity_id/${taggable_id}/`
        );
    }
};
