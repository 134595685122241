import React from 'react';
import style from './style/EditInstanceLayoutFooter.module.scss';
import { ActionButton } from '../../EditInstanceLayout';
import { Button } from 'src/view/components/ui/Button/Button';

interface Props {
    saveButton?: ActionButton;
    cancelButton?: ActionButton;
    deleteButton?: ActionButton;
}
export const EditInstanceLayoutFooter = ({ saveButton, cancelButton, deleteButton }: Props) => {
    return (
        <footer className={style.wrapper}>
            <div className={style.leftSideButtonsWrapper}>
                {saveButton?.title && (
                    <Button type="primary" onClick={saveButton.onClick}>
                        {saveButton.title}
                    </Button>
                )}
                {cancelButton?.title && (
                    <Button onClick={cancelButton.onClick}>{cancelButton.title}</Button>
                )}
            </div>
            <div className={style.rightSideButtonsWrapper}>
                {deleteButton?.title && (
                    <Button onClick={deleteButton.onClick}>{deleteButton.title}</Button>
                )}
            </div>
        </footer>
    );
};
