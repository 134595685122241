/* eslint-disable */
import React, { createContext, ReactNode, useEffect, useState } from 'react';

const initialValue = {
    lang: 'en',
    authToken: null,
    user: {},
    isLoading: true,
};

type providerType = {
    children: ReactNode;
};

interface AppStateInterface {
    lang: string;
    authToken: null | string;
    user: object;
    isLoading: boolean;
}

export const AppContext = createContext<AppStateInterface>(initialValue);

export const AppProvider = ({ children }: providerType) => {
    const [state, setState] = useState<AppStateInterface>(initialValue);

    useEffect(() => {
        const getToken = async () => {
            let token;
            try {
                token = localStorage.getItem('authToken');
                if (token !== null) {
                    setState({ ...state, authToken: token, isLoading: false });
                } else setState({ ...state, isLoading: false });
            } catch (e) {}
        };

        getToken();
        setState({ ...state, lang: 'en' });
    }, []);

    const login = async (user: {}, authToken: string) => {
        localStorage.setItem('authToken', authToken);
        localStorage.setItem('user', JSON.stringify(user));
        setState({ ...state, user, authToken });
    };

    const reset = () => {
        localStorage.removeItem('authToken');
        setState({ ...initialValue, isLoading: false });
    };

    const changeLang = async (language: string) => {
        await setState({ ...state, lang: language });
    };

    return (
        <AppContext.Provider value={{ state, login, reset, changeLang }}>
            {children}
        </AppContext.Provider>
    );
};
