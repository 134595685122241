import { clientPassword } from '../ClientPassword';

interface requestSku {
    ids: string[],
    project_id?: string,
    external_code?: string,
    name?: string,
    article?: string
}

export const getSku = (request: requestSku) => {
    return clientPassword.post( `/sku/list/`, request)
}
