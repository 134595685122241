import { formatters, getDaysList } from '@tools/utils/date.util';
import { t } from 'i18next';

export const visitTypeOptions = [
    {
        label: t('feed.visit'),
        value: 'visit',
    },
    {
        label: t('feed.audit'),
        value: 'audit',
    },
    {
        label: t('feed.auditManager'),
        value: 'audit_manager',
    },
];

export const getScheduleDates = (start: string, end: string) => {
    const days = getDaysList(new Date(start), new Date(end));
    return days.map((day) => ({
        monthDay: formatters.toMonthDay(day),
        weekDay: formatters.toWeekDay(day),
        date: formatters.toISODateString(day),
    }));
};

export const getFiltersHiddenActive = (settings = []) => {
    const filtersHidden = [
        'visiting_schedule_filter_project_id',
        'visiting_schedule_filter_date',
        'visiting_schedule_filter_user_ids',
        'visiting_schedule_filter_manager_ids',
        'visiting_schedule_filter_address',
        'visiting_schedule_filter_project_retail_ids',
        'visiting_schedule_filter_branch_ids',
        'visiting_schedule_filter_city_ids',
        'visiting_schedule_filter_external_code',
        'visiting_schedule_filter_name',
        'visiting_schedule_filter_ir_outlet_code',
        'visiting_schedule_filter_ir_type_visit',
    ];

    return settings
        ?.filter((s) => filtersHidden.includes(s.code) && s?.setting_value === 'FALSE')
        .map((s) => s.code?.replace('visiting_schedule_filter_', ''));
};

export const getColumnsHiddenActive = (settings = []) => {
    const columnsHidden = [
        'visiting_schedule_column_external_code',
        'visiting_schedule_column_address',
        'visiting_schedule_column_branch_name',
        'visiting_schedule_column_city_name',
        'visiting_schedule_column_project_retail_name',
        'visiting_schedule_column_user_name',
        'visiting_schedule_column_user_id',
        'visiting_schedule_column_user_phone',
        'visiting_schedule_column_manager_name',
        'visiting_schedule_column_manager_phone',
    ];

    return settings
        ?.filter((s) => columnsHidden.includes(s.code) && s?.setting_value === 'FALSE')
        .map((s) => s.code?.replace('visiting_schedule_column_', ''));
};

export const getActionsHiddenActive = (settings = []) => {
    const actionsHidden = [
        'visiting_schedule_action_set_status_new',
        'visiting_schedule_action_change_date',
        'visiting_schedule_action_change_user',
        'visiting_schedule_action_plan_controlled_visit',
        'visiting_schedule_action_delete',
        'visiting_schedule_action_questionnaires',
        'visiting_schedule_action_go_to_visit',
        'visiting_schedule_action_create_task',
    ];

    return settings
        ?.filter((s) => actionsHidden.includes(s.code) && s?.setting_value === 'FALSE')
        .map((s) => s.code?.replace('visiting_schedule_action_', ''));
};
