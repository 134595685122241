import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ButtonDelete.module.scss';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Button, Dialog, Loader } from '@components';
import { Book } from '@navigation/Book';
import { deleteFeed } from '@apiFeature/feeds';

interface IButtonDelete {
    id: string;
}

const ButtonDelete: FC<IButtonDelete> = ({ id }) => {
    const { t } = useTranslation('translation');

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);
    const [isOpenDialog, setOpenDialog] = useState(false);

    const handleErr = (e) => {
        setLoading(false);
        enqueueSnackbar(e?.message, { variant: 'error' });
    };

    const handleDeleteVisit = () => {
        setOpenDialog(false);

        if (id) {
            setLoading(true);

            deleteFeed(id)
                .then(() => {
                    setLoading(false);

                    enqueueSnackbar(t('messages.visitDeleteSuccess'), { variant: 'success' });

                    navigate(Book.visit_feed);
                })
                .catch((e) => handleErr(e));
        }
    };

    const handleCloseModal = () => {
        setOpenDialog(false);
    };

    return (
        <>
            {isLoading && <Loader />}
            <Button variant="outlined" color="secondary" onClick={() => setOpenDialog(true)}>
                {t('buttons.delete')}
            </Button>
            <Dialog
                id="deleteVisitDialog"
                isOpenDialog={isOpenDialog}
                setCloseDialog={handleCloseModal}
                className={styles.dialog}
                title={t('visits.deleteVisit')}
                actions={
                    <>
                        <Button variant="contained" color="secondary" onClick={handleDeleteVisit}>
                            {t('buttons.delete')}
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
                            {t('buttons.cancel')}
                        </Button>
                    </>
                }
            >
                {t('messages.visitDeleteConfirmation')}
            </Dialog>
        </>
    );
};

export default ButtonDelete;
