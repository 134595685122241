import React, { FC } from 'react';
import classNames from 'classnames';
import { ReactComponent as CrossIcon } from 'src/assets/images/svg/cross.svg';
import { getFile } from 'src/tools/utils/getFile';
import styles from './ImportFiles.module.scss';
import { useTranslation } from 'react-i18next';

interface ImportFilesProps {
    error?: boolean;
    onChange: (arg: File | null) => void;
    file: File | null;
}

export const ImportFiles: FC<ImportFilesProps> = ({ error, onChange, file }) => {
    const { t } = useTranslation('translation');
    return (
        <div className={classNames(styles.importFiles, { [styles.importFiles__error]: error })}>
            {file ? (
                <div className={styles.importFiles__file}>
                    <p>{file?.name}</p>
                    <CrossIcon onClick={() => onChange(null)} />
                </div>
            ) : (
                <div className={styles.importFiles__content}>
                    <p>{t('catalog.planograms.dragFile')}</p>
                    <p>{t('messages.allowedFormatsXls')}</p>
                    <button
                        type="button"
                        onClick={() =>
                            getFile({
                                callback: (e) => onChange(e.target.files[0]),
                                accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                            })
                        }
                    >
                        {t('buttons.selectFile')}
                    </button>
                </div>
            )}
        </div>
    );
};
