import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import AssignmentSelectors from '@redux/questionnaires/selectors';
import { AssignmentThunks } from '@redux/questionnaires/thunk';
import FiltersSelectors from '@redux/filters/selectors';
import { FiltersThunks } from '@redux/filters/thunk';
import { crowdTaskStatusNames } from '@apiFeature/crowd/types';
import { getOptions } from '@tools/utils/functions';
import { useRoles } from '@tools/hooks/useRoles';

const ReportsBaseFiltersData = (form) => {
    const { clientPermission } = useRoles();

    const dispatch = useDispatch();
    const { projectsList, loading: loadingProjects } = useSelector(
        ProjectsSelectors.projectsSelectors
    );
    const projectsListId = projectsList?.[0]?.id;
    const { questionnaires, listLoading: loadingQuestionnairesProjects } = useSelector(
        AssignmentSelectors.assignmentSelector
    );
    const {
        cities,
        citiesLoading,
        retails,
        retailsLoading,
        branches,
        branchesLoading,
        visitsFailResults,
        visitsFailResultsLoading,
    } = useSelector(FiltersSelectors.filtersState);

    const { t } = useTranslation('translation');

    const { watch, setValue } = form;
    const currValues = watch();
    const { project_id, branch_ids, project_retail_ids, city_id, questionnaire_name } = currValues;

    const getFiltersProjects = useCallback(() => {
        dispatch(
            AssignmentThunks.getQuestionnairesProjectsList({ project_id, is_active: true })
        ).then((result) => {
            if (
                questionnaire_name &&
                AssignmentThunks.getQuestionnairesProjectsList.fulfilled.match(result)
            ) {
                const hasValue = result?.payload?.questionnaires.some(
                    (e) => e.id === questionnaire_name
                );
                if (!hasValue) setValue('questionnaire_name', '');
            }
        });
        dispatch(FiltersThunks.getCitiesList({ project_id })).then((result) => {
            if (city_id && FiltersThunks.getCitiesList.fulfilled.match(result)) {
                const hasValue = result?.payload.some((e) => e.id === city_id);
                if (!hasValue) setValue('city_id', '');
            }
        });
        dispatch(FiltersThunks.getRetailsList({ project_id })).then((result) => {
            if (project_retail_ids && FiltersThunks.getRetailsList.fulfilled.match(result)) {
                const hasValue = result?.payload.some((e) => e.id === project_retail_ids);
                if (!hasValue) setValue('project_retail_ids', '');
            }
        });
        dispatch(FiltersThunks.getBranchesList({ project_id })).then((result) => {
            if (branch_ids && FiltersThunks.getBranchesList.fulfilled.match(result)) {
                const hasValue = result?.payload.some((e) => e.id === branch_ids);
                if (!hasValue) {
                    setValue('branch_ids', '');
                    setValue('project_outlet_branch_ids', '');
                }
            }
        });
        dispatch(FiltersThunks.getVisitsFailResultsOptions({}));
    }, [dispatch, project_id]);

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    useEffect(() => {
        getProjects();
    }, []);

    useEffect(() => {
        if (projectsList.length > 0 && !project_id) setValue('project_id', projectsListId);
    }, [projectsList]);

    useEffect(() => {
        if (project_id) getFiltersProjects();
    }, [project_id, getFiltersProjects]);

    const projectOptions = getOptions(projectsList, 'full_name');

    const questionnairesProjectsOptions = getOptions(
        questionnaires,
        ['name', 'external_code'],
        'external_code'
    );

    const citiesOptions = getOptions(cities);
    const retailsOptions = getOptions(retails);
    const branchesOptions = getOptions(branches);
    const visitsFailResultsOptions = getOptions(visitsFailResults);

    const taskResultStatusOptions = Object.keys(crowdTaskStatusNames)?.map((key) => ({
        label: crowdTaskStatusNames[key],
        value: key,
    }));

    const yesNoOptions = [
        {
            label: t('common.yes'),
            value: true,
        },
        {
            label: t('common.no'),
            value: false,
        },
    ];

    const visitStatusOptions = [
        {
            label: t('reports.reports.new'),
            value: 'problem_reported',
        },
        {
            label: t('statuses.inProgress'),
            value: 'in_progress',
        },
        {
            label: t('reports.reports.completed'),
            value: 'completed',
        },
        {
            label: t('reports.reports.unperformed'),
            value: 'unperformed',
        },
    ];

    let visitTypeOptions = [
        {
            label: t('feed.visit'),
            value: 'visit',
        },
        {
            label: t('feed.audit'),
            value: 'audit',
        },
    ];
    if (!clientPermission)
        visitTypeOptions = [
            ...visitTypeOptions,
            {
                label: t('feed.auditManager'),
                value: 'audit_manager',
            },
        ];

    const questionnaireKindOptions = [
        {
            label: t('questionnaires.questionnaireType'),
            value: 'questionnaire',
        },
        {
            label: t('feed.audit'),
            value: 'audit',
        },
    ];

    return {
        projectOptions,
        questionnairesProjectsOptions,
        citiesOptions,
        retailsOptions,
        branchesOptions,
        visitsFailResultsOptions,
        yesNoOptions,
        visitStatusOptions,
        taskResultStatusOptions,
        visitTypeOptions,
        questionnaireKindOptions,
        loadingProjects,
        loadingQuestionnairesProjects,
        citiesLoading,
        retailsLoading,
        branchesLoading,
        visitsFailResultsLoading,
        projectsListId,
    };
};

export default ReportsBaseFiltersData;
