import React, { useState, useEffect, FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { fetchQuestionnaireDetailed } from '@apiFeature/questionnaires';
import { Header, Title, BackLink, Loader } from '@components';
import { sortQuestionnaires } from '@tools/utils/sorting';
import { Book } from '@navigation/Book';
import { useTranslation } from 'react-i18next';
import QuestionnaireEditNav from './QuestionnaireEditNav/QuestionnaireEditNav';
import styles from './QuestionnaireEdit.module.scss';

export const QuestionnaireEdit: FC = () => {
    const { t } = useTranslation('translation');

    const [data, setData] = useState({});
    const { id, external_code, name, version } = data || {};

    const params = useParams();
    const { pollId } = params || {};
    useEffect(() => {
        if (pollId)
            fetchQuestionnaireDetailed({
                ids: [pollId || ''],
            }).then(({ questionnaires }) => {
                if (questionnaires && questionnaires?.length > 0) {
                    const sortedQuestionnaires = sortQuestionnaires(questionnaires);
                    setData(sortedQuestionnaires?.[0] || {});
                }
            });
    }, [pollId]);

    const title = pollId ? (
        <>
            {name || external_code} {version && `(v${version})`}
        </>
    ) : (
        t('questionnaires.create')
    );

    return (
        <div className={styles.questionnaireEdit}>
            {pollId && !id && <Loader />}
            <Header type="inner">
                <Title>
                    <BackLink link={Book.questionnaires.questionnaires} />
                    {title}
                </Title>
                <QuestionnaireEditNav pollId={pollId} />
            </Header>
            <Outlet context={{ detailed: data, title }} />
        </div>
    );
};
