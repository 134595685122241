import React from 'react';
import styles from './VisitScheduleHeader.module.scss';
import { ToggleButtonGroup } from '@components';
//import Upload from './Upload/Upload';
import Create from './Create/Create';
import { visitTypeOptions } from '../helpers/helpers';

const VisitScheduleHeader = ({ form, visitingScheduleWebCreate }) => {
    const { watch, setValue } = form;
    const currValues = watch();
    const { project_id, type } = currValues;

    const handleChange = (_, value: string) => {
        if (value !== null) setValue('type', value);
    };

    const elements = visitTypeOptions.filter((e) => e.value === 'visit');

    return (
        <div className={styles.renderHeader}>
            <ToggleButtonGroup
                color="secondary"
                value={type}
                exclusive
                onChange={handleChange}
                elements={elements}
            />
            <div className={styles.renderHeaderBtns}>
                {/*<Upload data={currValues} setValue={setValue} />*/}
                {visitingScheduleWebCreate && (
                    <Create project_id={project_id} type={type} setValue={setValue} />
                )}
            </div>
        </div>
    );
};

export default VisitScheduleHeader;
