import React, { useEffect, useState } from 'react';
import styles from './VisitCheck.module.scss';
import { useTranslation } from 'react-i18next';
import { Layout } from '@components';
import VisitFeedData from '../VisitFeed/VisitFeedData/VisitFeedData';
import VisitFeedFilters from '../VisitFeed/VisitFeedFilters/VisitFeedFilters';
import { defaultValuesData, getDefaultValues, getVisitFilters } from '../VisitFeed/utils/functions';
import { useRoles } from '@tools/hooks/useRoles';
import FiltersSelectors from '@redux/filters/selectors';
import { useSelector } from 'react-redux';
import { useURLFilters } from '@tools/hooks/useURLFilters';

export const VisitCheck = () => {
    const { clientPermission } = useRoles();
    const { setURLFiltersFromDefaultValues } = useURLFilters();

    const { t } = useTranslation('translation');

    const getFilters = (values) => {
        const res = getVisitFilters(values, clientPermission);
        return res;
    };

    const [defaultValues, setDefaultValues] = useState({});

    const { filters: { visitCheck: visitCheckFilters = {} } = {} } = useSelector(
        FiltersSelectors.filtersState
    );

    useEffect(() => {
        const defaultValues = getDefaultValues(visitCheckFilters, clientPermission);
        const resultFilterValues = setURLFiltersFromDefaultValues(defaultValues);
        setDefaultValues(resultFilterValues);
    }, []);

    return Object.keys(defaultValues)?.length > 0 ? (
        <Layout
            title={t('feed.checkerTitle')}
            className={styles.visitCheck}
            renderSearch={() => ({})}
            renderData={(props) => (
                <VisitFeedData
                    {...props}
                    defaultValues={defaultValues}
                    clientPermission={clientPermission}
                    isCheckers
                    nameFilters="visitCheck"
                />
            )}
            renderFilters={(props) =>
                VisitFeedFilters({ ...props, defaultValues: defaultValuesData })
            }
            defaultValues={defaultValues}
            getFilters={getFilters}
        />
    ) : (
        <></>
    );
};
