import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorApi } from '@apiFeature/types';
import { reduxStoreName } from './types';
import { fetchCitiesList } from '@apiFeature/cities';
import { fetchRetailsList } from '@apiFeature/retails';
import { fetchBranchList } from '@apiFeature/branch';
import {
    fetchUserProjects,
    fetchManagerProjects,
    fetchUserList,
    fetchUserProjectExecutors,
} from '@apiFeature/user';
import { fetchTagsList } from '@apiFeature/tags';
import { getVisitsFailResultsOptions as fetchVisitsFailResultsOptions } from '@apiFeature/visits';
import { fetchTerritoryList } from '@apiFeature/territory';
import { getProjectOutletsList } from '@apiFeature/projectOutlets';
import { fetchPeriods } from '@apiFeature/addressProgram';
import { getExecutorsForCrowdFeed, getOutletsForCrowdFeed } from '@apiFeature/crowd';
import { fetchSupportRequestTypes } from '@apiFeature/supportRequests';
import { fetchRoles } from '@apiFeature/roles';
import { clientsFetch } from '@apiFeature/clients';

const defaultLimit = 100;

export module FiltersThunks {
    export const setFilters = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/setFilters`,
        (filters) => {
            return filters ?? {};
        }
    );
    export const getCitiesList = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/getCitiesList`,
        async (arg, { rejectWithValue }) => {
            try {
                return await fetchCitiesList(arg ?? {});
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );
    export const getRetailsList = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/getRetailsList`,
        async (arg, { rejectWithValue }) => {
            try {
                return await fetchRetailsList(arg ?? {});
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );
    export const getBranchesList = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/getBranchesList`,
        async (arg, { rejectWithValue }) => {
            try {
                return await fetchBranchList(arg ?? {});
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );
    export const getTerritoryList = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/getTerritoryList`,
        async (arg, { rejectWithValue }) => {
            try {
                return await fetchTerritoryList(arg ?? {});
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );
    const createThunkForRequestIds = (
        thunkName: string,
        request: any,
        payload: any,
        defaultLimit: number
    ) => {
        return createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
            `${reduxStoreName}/${thunkName}`,
            async (_, { rejectWithValue }) => {
                try {
                    const initialData = await request({
                        ...(payload && { ...payload }),
                        pagination: { limit: defaultLimit, offset: 0 },
                    });
                    const total = initialData?.total;
                    let offset = defaultLimit;
                    let allIds = initialData.items;
                    while (offset < total) {
                        const response = await request({
                            ...(payload && { ...payload }),
                            pagination: {
                                limit: defaultLimit,
                                offset,
                            },
                        });
                        const ids = response?.items || [];

                        allIds = allIds.concat(ids);
                        offset += defaultLimit;
                    }

                    return { ids: allIds || [], total };
                } catch (e) {
                    return rejectWithValue(e);
                }
            }
        );
    };
    const getDataFromIds = async (ids: string, total: number, request: any, limit: number) => {
        let offset = 0;
        let allData = [];
        while (offset < total) {
            const chunkIds = ids.slice(offset, offset + limit);
            const usersResponse = await request({
                ids: chunkIds,
                pagination: { limit },
            });
            allData = allData.concat(usersResponse?.items);
            offset += limit;
        }

        return allData || [];
    };
    export const getExecutorsForCrowdFilter = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/getExecutorsForCrowdFilter`,
        async (arg, { dispatch, rejectWithValue }) => {
            try {
                const getExecutorIdsForCrowdThunk = createThunkForRequestIds(
                    'getExecutorIdsForCrowd',
                    getExecutorsForCrowdFeed,
                    arg,
                    defaultLimit
                );
                const executorsIds = await dispatch(getExecutorIdsForCrowdThunk(arg));
                const ids = executorsIds?.payload?.ids || [];
                const total = executorsIds?.payload?.total;
                const limit = defaultLimit;
                const allExecutors = await getDataFromIds(ids, total, fetchUserList, limit);

                return allExecutors;
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );
    const generateAsyncThunkForUsers = (thunkName, request) => {
        return createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
            `${reduxStoreName}/${thunkName}`,
            async (arg, { dispatch, rejectWithValue }) => {
                try {
                    const getUsersIdsListThunk = createThunkForRequestIds(
                        `${thunkName}IdsList`,
                        request,
                        arg,
                        defaultLimit
                    );
                    const userProjects = await dispatch(getUsersIdsListThunk(arg));
                    const ids = userProjects?.payload?.ids || [];
                    const total = userProjects?.payload?.total;
                    const limit = defaultLimit;
                    const allUsers = await getDataFromIds(ids, total, fetchUserList, limit);

                    return allUsers;
                } catch (e) {
                    return rejectWithValue(e);
                }
            }
        );
    };
    export const getUsersList = generateAsyncThunkForUsers('getUsersList', fetchUserProjects);
    export const getUsersManagersList = generateAsyncThunkForUsers(
        'getUsersManagersList',
        fetchUserProjects
    );
    export const getManagersList = generateAsyncThunkForUsers(
        'getManagersList',
        fetchManagerProjects
    );
    export const getOutletsListForCrowd = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/getOutletsListForCrowd`,
        async (arg, { dispatch, rejectWithValue }) => {
            try {
                const getOutletIdsForCrowdThunk = createThunkForRequestIds(
                    'getOutletIdsForCrowdThunk',
                    getOutletsForCrowdFeed,
                    arg,
                    defaultLimit
                );
                const executorsIds = await dispatch(getOutletIdsForCrowdThunk(arg));
                const ids = executorsIds?.payload?.ids || [];
                const total = executorsIds?.payload?.total;
                const limit = defaultLimit;
                const allOutlets = await getDataFromIds(ids, total, getProjectOutletsList, limit);

                return allOutlets;
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );
    export const getVisitsFailResultsOptions = createAsyncThunk<
        any,
        any,
        { rejectValue: ErrorApi }
    >(`${reduxStoreName}/getVisitsFailResultsOptions`, async (arg, { rejectWithValue }) => {
        try {
            return await fetchVisitsFailResultsOptions(arg ?? {});
        } catch (e) {
            return rejectWithValue(e);
        }
    });
    export const fetchProjectOutletsList = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/fetchProjectOutletsList`,
        async (arg, { rejectWithValue }) => {
            try {
                return await getProjectOutletsList(arg ?? {});
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );
    export const getPeriodList = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/getPeriodList`,
        async (arg, { rejectWithValue }) => {
            try {
                return await fetchPeriods(arg ?? {});
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );
    export const getSupportRequestTypes = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/getSupportRequestTypes`,
        async (arg, { rejectWithValue }) => {
            try {
                return await fetchSupportRequestTypes(arg);
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );
    export const getRolesThunk = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/getRolesThunk`,
        async (arg, { rejectWithValue }) => {
            try {
                return await fetchRoles(arg);
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );
    export const getClientsThunk = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/getClientsThunk`,
        async (arg, { rejectWithValue }) => {
            try {
                return await clientsFetch(arg);
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );
    const generateAsyncThunkForUserProjectExecutors = (thunkName) => {
        return createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
            `${reduxStoreName}/${thunkName}`,
            async (arg, { dispatch, rejectWithValue }) => {
                try {
                    const getUsersIdsListThunk = createThunkForRequestIds(
                        `${thunkName}IdsList`,
                        fetchUserProjectExecutors,
                        arg,
                        defaultLimit
                    );
                    const userProjects = await dispatch(getUsersIdsListThunk(arg));
                    const ids = userProjects?.payload?.ids?.map((item) => item.user_id) || [];
                    const total = userProjects?.payload?.total;
                    const limit = defaultLimit;
                    const allUsers = await getDataFromIds(ids, total, fetchUserList, limit);

                    return { allUsers, project: arg?.project_id };
                } catch (e) {
                    return rejectWithValue(e);
                }
            }
        );
    };
    export const getUserProjectExecutors =
        generateAsyncThunkForUserProjectExecutors('getUserProjectExecutors');
    export const getManagerProjectExecutors = generateAsyncThunkForUserProjectExecutors(
        'getManagerProjectExecutors'
    );
    const generateAsyncThunkForTagsProject = (thunkName) => {
        return createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
            `${reduxStoreName}/${thunkName}`,
            async (arg, { dispatch, rejectWithValue }) => {
                try {
                    const getTagsIdsListThunk = createThunkForRequestIds(
                        `${thunkName}IdsList`,
                        fetchTagsList,
                        arg,
                        defaultLimit
                    );
                    const tags = await dispatch(getTagsIdsListThunk(arg));

                    return {
                        tags: tags?.payload?.ids,
                        project: arg?.project_id,
                        type: arg?.entity_type,
                    };
                } catch (e) {
                    return rejectWithValue(e);
                }
            }
        );
    };
    export const getTagsProject = generateAsyncThunkForTagsProject('getTagsProject');
}
