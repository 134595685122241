import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import AssignmentSelectors from '@redux/questionnaires/selectors';
import { AssignmentThunks } from '@redux/questionnaires/thunk';
import FiltersSelectors from '@redux/filters/selectors';
import { FiltersThunks } from '@redux/filters/thunk';
import { getOptions } from '@tools/utils/functions';

const PhotoReportBaseFiltersData = (form) => {
    const dispatch = useDispatch();
    const { projectsList, loading: loadingProjects } = useSelector(
        ProjectsSelectors.projectsSelectors
    );
    const projectsListId = projectsList?.[0]?.id;
    const { questionnaires, listLoading: loadingQuestionnairesProjects } = useSelector(
        AssignmentSelectors.assignmentSelector
    );
    const { retails, retailsLoading, branches, branchesLoading } = useSelector(
        FiltersSelectors.filtersState
    );

    const { t } = useTranslation('translation');

    const { watch, setValue } = form;
    const currValues = watch();
    const { project_id, project_outlet_retails, project_outlet_branches, questionnaire_names } =
        currValues;

    const getFiltersProjects = useCallback(() => {
        dispatch(
            AssignmentThunks.getQuestionnairesProjectsList({ project_id, is_active: true })
        ).then((result) => {
            if (
                questionnaire_names &&
                AssignmentThunks.getQuestionnairesProjectsList.fulfilled.match(result)
            ) {
                const hasValue = result?.payload?.questionnaires.some(
                    (e) => e.id === questionnaire_names
                );
                if (!hasValue) setValue('questionnaire_names', '');
            }
        });
        dispatch(FiltersThunks.getRetailsList({ project_id })).then((result) => {
            if (project_outlet_retails && FiltersThunks.getRetailsList.fulfilled.match(result)) {
                const hasValue = result?.payload.some((e) => e.id === project_outlet_retails);
                if (!hasValue) setValue('project_outlet_retails', '');
            }
        });
        dispatch(FiltersThunks.getBranchesList({ project_id })).then((result) => {
            if (FiltersThunks.getBranchesList.fulfilled.match(result)) {
                const hasValue = result?.payload.some((e) => e.id === project_outlet_branches);
                if (!hasValue) setValue('project_outlet_branches', '');
            }
        });
    }, [dispatch, project_id]);

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    useEffect(() => {
        getProjects();
    }, []);

    useEffect(() => {
        if (projectsList.length > 0 && !project_id) setValue('project_id', projectsListId);
    }, [projectsList]);

    useEffect(() => {
        if (project_id) getFiltersProjects();
    }, [project_id, getFiltersProjects]);

    const projectOptions = getOptions(projectsList, 'full_name');

    const questionnairesProjectsOptions = getOptions(
        questionnaires,
        ['name', 'external_code'],
        'external_code'
    );

    const retailsOptions = getOptions(retails);
    const branchesOptions = getOptions(branches);

    const yesNoOptions = [
        {
            label: t('common.yes'),
            value: true,
        },
        {
            label: t('common.no'),
            value: false,
        },
    ];

    return {
        projectOptions,
        questionnairesProjectsOptions,
        retailsOptions,
        branchesOptions,
        yesNoOptions,
        loadingProjects,
        loadingQuestionnairesProjects,
        retailsLoading,
        branchesLoading,
        projectsListId,
    };
};

export default PhotoReportBaseFiltersData;
