import React, { FC } from 'react';
import { Checkbox as CB, FormControlLabel, SvgIcon } from '@mui/material';
import classNames from 'classnames';
import styles from './Checkbox.module.scss';

interface ICheckbox {
    className?: string | undefined;
    value: boolean;
    label?: string;
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom' | undefined;
}

const Checkbox: FC<ICheckbox> = ({ value, className, label, labelPlacement, ...props }) => {
    return (
        <FormControlLabel
            {...props}
            className={classNames(styles.checkbox, className)}
            checked={value}
            control={
                <CB
                    icon={
                        <SvgIcon>
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect x="0.5" y="0.5" width="15" height="15" stroke="#414152" />
                            </svg>
                        </SvgIcon>
                    }
                    checkedIcon={
                        <SvgIcon>
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect x="0.5" y="0.5" width="15" height="15" stroke="#414152" />
                                <path
                                    d="M4 8L6.5 10.5L12 5"
                                    stroke="#CC1C17"
                                    strokeWidth="2"
                                    strokeLinecap="square"
                                />
                            </svg>
                        </SvgIcon>
                    }
                />
            }
            label={label}
            labelPlacement={labelPlacement}
        />
    );
};

export default Checkbox;
