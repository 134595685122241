import React, { FC, useEffect, useState } from 'react';
import { useRoles } from '@tools/hooks/useRoles';
import { Loader, PhotoSlider } from '@components';
import { useSnackbar } from 'notistack';
import { fetchFeedsPhotos } from '@/api/visitFeed/visitFeed.api';
import { useAppSelector } from '@tools/hooks/redux';
import { permissionCheck } from '@tools/utils/permissionCheck';
import styles from './Images.module.scss';

interface IImages {
    visit_id?: string;
    photoDescription?: Record<string, unknown>;
    utc_offset?: number | null;
}

const Images: FC<IImages> = ({ visit_id, photoDescription, utc_offset }) => {
    const [isLoading, setLoading] = useState(false);
    const [photos, setPhotos] = useState([]);

    const authRoles = useAppSelector((state) => state?.auth?.jwtPayloadOpen?.roles);
    const { clientPermission } = useRoles();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!visit_id) return;
        getFeedsPhotos();
    }, [visit_id]);

    const getFeedsPhotos = async () => {
        setLoading(true);

        await fetchFeedsPhotos({ visit_id, is_preview: null })
            .then((images) => {
                setPhotos(images);
            })
            .catch((e) => enqueueSnackbar(e?.message, { variant: 'error' }));

        setLoading(false);
    };

    return (
        <div className={styles.images}>
            {isLoading && <Loader />}
            {photos?.length > 0 && (
                <PhotoSlider
                    id={visit_id}
                    photos={photos}
                    type="list"
                    description={permissionCheck(authRoles, photoDescription)}
                    utc_offset={utc_offset}
                    hasDeleteBtn={!clientPermission}
                    isViewSettings
                />
            )}
        </div>
    );
};

export default Images;
