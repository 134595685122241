import { createSlice } from "@reduxjs/toolkit"
import ImportProcessActions from "./actions";
import { ImportProcessesThunks } from "./thunk";
import { reduxStoreName } from "./types";

const initialState = {
    loadingProcesses: false,
    loadingImport: false,
    errorImport: false,
    errorImportProcessList: false,
    errorMessage: '',
    importList: [],
    status: null,
    kind: null,
    project_id: null,
    file: null,
    import_file_process: null,
    import_row_process: null,
    lineNumberFilter: '',
    total: 0,
    projectInModal: null,
    kindInModal: null,
    totalRows: 0,
}

const slice = createSlice({
    name: reduxStoreName,
    initialState: {
        ...initialState,
    },
    reducers: {
        reset() {
            return { ...initialState };
        },
    },
    extraReducers: builder => {
        builder
        .addCase(ImportProcessesThunks.fetchImportProcesses.pending, (state) => {
            state.loadingProcesses = true;
            state.errorImportProcessList = false;
        })
        .addCase(ImportProcessesThunks.fetchImportProcesses.rejected, (state, { payload }) => {
            state.loadingProcesses = false;
            state.errorImportProcessList = true;
            state.errorMessage = payload?.message ?? 'Неизвестная ошибка';
        })
        .addCase(ImportProcessesThunks.fetchImportProcesses.fulfilled, (state, { payload }) => {
            state.loadingProcesses = false;
            state.errorImportProcessList = false;
            state.importList = payload?.import_file_processes?.items;
            state.total =  payload?.import_file_processes?.total;
        })
        .addCase(ImportProcessesThunks.fetchImportRowProcesses.pending, (state) => {
            state.loadingProcesses = true;
            state.errorImportProcessList = false;
        })
        .addCase(ImportProcessesThunks.fetchImportRowProcesses.rejected, (state, { payload }) => {
            state.loadingProcesses = false;
            state.errorImportProcessList = true;
            state.errorMessage = payload?.message ?? 'Неизвестная ошибка';
        })
        .addCase(ImportProcessesThunks.fetchImportRowProcesses.fulfilled, (state, { payload }) => {
            state.loadingProcesses = false;
            state.errorImportProcessList = false;
            state.import_row_process = payload?.import_row_processes?.items;
            state.totalRows = payload?.import_row_processes?.total;
        })
        .addCase(ImportProcessesThunks.importFileThunk.pending, (state) => {
            state.loadingImport = true;
            state.errorImport = false;
        })
        .addCase(ImportProcessesThunks.importFileThunk.rejected, (state, { payload }) => {
            state.loadingImport = false;
            state.errorImport = true;
            state.errorMessage = payload?.message ?? 'Неизвестная ошибка';
        })
        .addCase(ImportProcessesThunks.importFileThunk.fulfilled, (state, { payload }) => {
            state.loadingImport = false;
            state.errorImport = false;
            state.import_file_process = payload?.import_file_process;
        })

        builder
        .addCase(ImportProcessActions.setProjectId, (state, { payload }) => {
            state.project_id = payload;
        })
        .addCase(ImportProcessActions.setKind, (state, { payload }) => {
            state.kind = payload;
        })
        .addCase(ImportProcessActions.setProjectinModal, (state, { payload }) => {
            state.projectInModal = payload;
        })
        .addCase(ImportProcessActions.setKindinModal, (state, { payload }) => {
            state.kindInModal = payload;
        })
        .addCase(ImportProcessActions.selectFile, (state, { payload }) => {
            state.file = payload;
        })
        .addCase(ImportProcessActions.setStatus, (state, { payload }) => {
            state.status = payload;
        })
        .addCase(ImportProcessActions.setLineNumber, (state, { payload }) => {
            state.lineNumberFilter = payload;
        })
        .addCase(ImportProcessActions.resetAction, (state) => {
            state.errorMessage = '';
            state.errorImport = false;
            state.import_file_process = null;
            state.file = null;
            state.import_row_process = [];
            state.importList = [];
            state.status = null;
            state.lineNumberFilter = '';
            state.total = 0;
            state.totalRows = 0;
            state.kindInModal = null;
            state.projectInModal = null;
        })
    },
});

export const reducer = slice.reducer;