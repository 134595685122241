import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import FiltersSelectors from '@redux/filters/selectors';
import { FiltersThunks } from '@redux/filters/thunk';
import { getOptions } from '@tools/utils/functions';
import { fixTimezoneOffset } from '@tools/utils/date.util';

const VisitScheduleBaseFiltersData = (form) => {
    const dispatch = useDispatch();
    const { projectsList, loading: loadingProjects } = useSelector(
        ProjectsSelectors.projectsSelectors
    );
    const projectsListId = projectsList?.[0]?.id;
    const {
        userProjects,
        userProjectsLoading: usersLoading,
        managers,
        managersLoading,
        retails,
        retailsLoading,
        branches,
        branchesLoading,
        cities,
        citiesLoading,
    } = useSelector(FiltersSelectors.filtersState);

    const { watch, setValue } = form;
    const currValues = watch();
    const {
        project_id,
        user_ids,
        manager_ids,
        branch_ids,
        city_ids,
        project_retail_ids,
        type: visit_type,
        date,
    } = currValues;

    const start_date = date?.start ? fixTimezoneOffset(date.start) : null;
    const end_date = date?.end ? fixTimezoneOffset(date.end) : null;

    const setActualValue = (curData, data, name) => {
        const value = data?.filter((e) => curData?.includes(e.id)).map((e) => e.id);
        if (curData && curData?.join() !== value?.join()) {
            setValue(name, value.length > 0 ? value : null);
        }
    };

    const getFiltersProjects = useCallback(() => {
        dispatch(FiltersThunks.getUserProjectExecutors({ project_id })).then((result) => {
            if (FiltersThunks.getUserProjectExecutors.fulfilled.match(result)) {
                const users = result?.payload?.[project_id] || [];
                if (user_ids?.length > 0) setActualValue(user_ids, users, 'user_ids');
            }
        });
        dispatch(
            FiltersThunks.getManagersList({
                project_id,
                visit_type,
                start_date,
                end_date,
            })
        ).then((result) => {
            if (FiltersThunks.getManagersList.fulfilled.match(result)) {
                const managers = result?.payload || [];
                if (manager_ids?.length > 0) setActualValue(manager_ids, managers, 'manager_ids');
            }
        });
        dispatch(FiltersThunks.getRetailsList({ project_id })).then((result) => {
            if (FiltersThunks.getRetailsList.fulfilled.match(result)) {
                const data = result?.payload || [];
                if (project_retail_ids?.length > 0)
                    setActualValue(project_retail_ids, data, 'project_retail_ids');
            }
        });
        dispatch(FiltersThunks.getBranchesList({ project_id })).then((result) => {
            if (FiltersThunks.getBranchesList.fulfilled.match(result)) {
                const data = result?.payload || [];
                if (branch_ids?.length > 0) setActualValue(branch_ids, data, 'branch_ids');
            }
        });
        dispatch(FiltersThunks.getCitiesList({ project_id })).then((result) => {
            if (FiltersThunks.getCitiesList.fulfilled.match(result)) {
                const data = result?.payload || [];
                if (city_ids?.length > 0) setActualValue(city_ids, data, 'city_ids');
            }
        });
    }, [dispatch, project_id]);

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    useEffect(() => {
        getProjects();
    }, []);

    useEffect(() => {
        if (projectsList.length > 0 && !project_id) setValue('project_id', projectsListId);
    }, [projectsList]);

    useEffect(() => {
        if (project_id) getFiltersProjects();
    }, [project_id, getFiltersProjects]);

    const projectOptions = getOptions(projectsList, 'full_name');
    const usersOptions = getOptions(userProjects?.[project_id], [
        'last_name',
        'first_name',
        'patronymic_name',
        'phone',
    ]);
    const managerOptions = getOptions(managers, [
        'last_name',
        'first_name',
        'patronymic_name',
        'phone',
    ]);
    const retailsOptions = getOptions(retails);
    const branchesOptions = getOptions(branches);
    const citiesOptions = getOptions(cities);

    return {
        projectOptions,
        usersOptions,
        managerOptions,
        retailsOptions,
        branchesOptions,
        citiesOptions,
        loadingProjects,
        usersLoading,
        managersLoading,
        retailsLoading,
        branchesLoading,
        citiesLoading,
        projectsListId,
    };
};

export default VisitScheduleBaseFiltersData;
