import { createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorApi } from "../../apiFeature/types";
import { reduxStoreName } from "./types";
import { getCrowdTaskComplexityList, getTaskById, getTaskResultsList, getTaskTypeList } from "../../apiFeature/crowd";

export module CrowdThunks {
    export const fetchTaskResultsList = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/fetchTaskResultsList`,
        async (arg, { rejectWithValue }) => {
            try {
                return await getTaskResultsList(arg);
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );

    export const fetchTaskById = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/fetchTaskById`,
        async (arg, { rejectWithValue }) => {
            try {
                return await getTaskById(arg);
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );

    export const fetchTaskComplexities = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/fetchTaskComplexities`,
        async (arg, { rejectWithValue }) => {
            try {
                return await getCrowdTaskComplexityList(arg);
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );

    export const fetchTaskTypes = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/fetchTaskTypes`,
        async (arg, { rejectWithValue }) => {
            try {
                return await getTaskTypeList(arg);
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );
}