import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '@components';
import TeamHeader from './TeamHeader/TeamHeader';
import TeamData from './TeamData/TeamData';
import TeamFilters from './TeamFilters/TeamFilters';
import { useURLFilters } from '@tools/hooks/useURLFilters';

export const Team = () => {
    const { t } = useTranslation('translation');
    const { parseURL } = useURLFilters();

    const project_id = sessionStorage.getItem('project_id');
    const [defaultValues, setDefaultValues] = useState({});

    const getFilters = (values) => {
        return values;
    };

    useEffect(() => {
        const filterValuesFromUrl = parseURL();
        setDefaultValues({
            project_id: project_id !== 'undefined' ? project_id : '',
            ...filterValuesFromUrl,
            isActive: filterValuesFromUrl?.isActive || 'active',
        });
    }, []);

    return Object.keys(defaultValues)?.length > 0 ? (
        <Layout
            title={t('catalog.team.title')}
            renderHeader={(props) => <TeamHeader {...props} />}
            renderSearch={() => ({ name: 'search', placeholder: t('catalog.team.searchUser') })}
            renderData={(props) => <TeamData {...props} defaultValues={defaultValues} />}
            renderFilters={TeamFilters}
            defaultValues={defaultValues}
            getFilters={getFilters}
        />
    ) : (
        <></>
    );
};
