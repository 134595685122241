import React, { FC, useState, MouseEvent } from 'react';
import styles from './VisitScheduleCell.module.scss';
import classnames from 'classnames';
import { VisitStatus } from '@/types/visits';
import { isOutdatedDays } from '@tools/utils/date.util';
import { Button } from '@components';
import { SettingsAllUserItem } from '@apiFeature/settings/types';
import MenuDialog from '../components/MenuDialog';

interface VisitScheduleCellProps {
    status?: VisitStatus;
    row: {
        project_id: string;
        project_outlet_id?: string;
        user_id?: string;
    };
    date?: string;
    setValue: (name: string, value: string) => void;
    settings: SettingsAllUserItem[];
}

const VisitScheduleCell: FC<VisitScheduleCellProps> = ({
    status,
    row,
    date,
    setValue,
    settings,
}) => {
    const { id, date: statusDate } = status || {};
    let { status: statusType } = status || {};
    if (status && !id && !statusType)
        statusType = isOutdatedDays(statusDate) ? VisitStatus.Unperformed : VisitStatus.New;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return date && statusType !== undefined ? (
        <div className={styles.cell}>
            <Button className={styles.btn} onClick={handleClick}>
                <i
                    className={classnames(styles.visit, {
                        [styles.actualVisit]:
                            statusType === VisitStatus.Completed ||
                            statusType === VisitStatus.ProblemReported,
                        [styles.inProgressVisit]: statusType === VisitStatus.InProgress,
                        [styles.expiredVisit]: statusType === VisitStatus.Unperformed,
                        [styles.newVisit]: statusType === VisitStatus.New,
                    })}
                />
            </Button>
            {!!anchorEl && (
                <MenuDialog
                    status={status}
                    row={row}
                    date={date}
                    statusType={statusType}
                    setValue={setValue}
                    handleClose={handleClose}
                    anchorEl={anchorEl}
                    settings={settings}
                />
            )}
        </div>
    ) : (
        <></>
    );
};

export default VisitScheduleCell;
