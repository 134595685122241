import { t } from "i18next";

export const PlanogramTypesConst = {
    book: 'book',
    fem_hygiene: 'fem_hygiene',
    fabric_care: 'fabric_care',
    child_hygiene: 'child_hygiene',
    body_care: 'body_care',
    teeth_care: 'teeth_care',
    shaving: 'shaving',
    cleaning: 'cleaning',
    hair_care: 'hair_care',
} as const;

export const PlanogramTypesName = {
    book: t('catalog.planograms.book'),
    fem_hygiene: t('catalog.planograms.femHygiene'),
    fabric_care: t('catalog.planograms.fabricCare'),
    child_hygiene: t('catalog.planograms.childHygiene'),
    body_care: t('catalog.planograms.bodyCare'),
    teeth_care: t('catalog.planograms.teethCare'),
    shaving: t('catalog.planograms.shaving'),
    cleaning: t('catalog.planograms.cleaning'),
    hair_care: t('catalog.planograms.hairCare'),
} as const;

export type PlanogramTypes = keyof typeof PlanogramTypesConst;