import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Title, Header, BackLink, Loader } from '@components';
import { FiltersThunks } from '@redux/filters/thunk';
import { getProjectOutlet, patchProjectOutlet } from '@apiFeature/projectOutlets';
import { getProjectById } from '@apiFeature/projects';
import { Book } from '@navigation/Book';
import styles from './OutletDetails.module.scss';
import { ProjectOutletType } from '@apiFeature/projectOutlets/types'; 
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import Info from './Info/Info';
import Questionnaires from './Questionnaires/Questionnaires';

export const OutletDetails: FC = () => {
    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { outletId } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [outlet, setOutlet] = useState<ProjectOutletType>({});
    const { project_id, name, address } = outlet || {};

    const handleErr = (e) => {
        setLoading(false);
        const errorMessage = e?.message || e?.data?.detail || t('messages.unknownError');
        enqueueSnackbar(errorMessage, { variant: 'error' });
    };

    useEffect(() => {
        if (outletId) {
            (async () => {
                setLoading(true);
                try {
                    const res = await getProjectOutlet(outletId);
                    const { project_outlet } = res || {};
                    const { project_id } = project_outlet || {};

                    const { project } = await getProjectById(project_id);

                    dispatch(FiltersThunks.getRetailsList({ project_id }));
                    dispatch(FiltersThunks.getBranchesList({ project_id }));
                    dispatch(FiltersThunks.getTerritoryList({ project_id }));
                    dispatch(FiltersThunks.getCitiesList({ project_id }));

                    setOutlet({
                        ...project_outlet,
                        project: project?.full_name,
                    });

                    setLoading(false);
                } catch (e) {
                    handleErr(e);
                }
            })();
        }
    }, [outletId]);

    const handleSaveInfo = (data) => {
        if (data?.id) {
            setLoading(true);
            patchProjectOutlet(data)
                .then((project_outlet) => {
                    enqueueSnackbar(t('messages.messageSuccess'), { variant: 'success' });
                    setOutlet({ ...outlet, ...project_outlet });
                })
                .catch((e) => handleErr(e))
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <div className="outletDetails">
            {isLoading && <Loader />}
            <Header type="inner">
                <Title>
                    <BackLink link={Book.catalog.outlets} />
                    {name} {address}
                </Title>
            </Header>
            {outlet && Object.keys(outlet)?.length > 0 && (
                <Info data={outlet} handleSaveInfo={handleSaveInfo} />
            )}
            {outletId && project_id && (
                <Questionnaires outletId={outletId} project_id={project_id} />
            )}
        </div>
    );
};
