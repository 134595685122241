import React from 'react';
import { VisitStatus } from '@/types/visits';
import { isOutdatedDays } from '@tools/utils/date.util';
import MenuDialog from '../components/MenuDialog';

const findMissingElements = (arr) =>
    Array.from({ length: arr[1] - arr[0] + 1 }, (_, i) => i + arr[0]);

const VisitScheduleGroupEditing = ({
    context,
    columns,
    rows,
    anchorEl,
    setValue,
    project_id,
    handleClose,
    settings,
}) => {
    const { selectedRegions } = context || {};
    const [selectedRegion] = selectedRegions || [];
    const { rows: selectedRows, cols: selectedCols } = selectedRegion || {};

    const isOnlyRows = !selectedCols;

    const selectedRowsAll = findMissingElements(selectedRows || []);
    let selectedColsAll = findMissingElements(selectedCols || []);
    if (isOnlyRows) selectedColsAll = columns?.map((_, i) => i);

    const selectedDates = columns.filter((_, i) => selectedColsAll.includes(i)).map((e) => e.date);
    const filteredRows = rows.filter((_, i) => selectedRowsAll.includes(i));

    let selectedRoutes: any = [];
    filteredRows.forEach((e) => {
        if (e?.statuses && e?.statuses?.length > 0) {
            const res = e.statuses
                .filter((ee) => selectedDates.includes(ee.date))
                .map((ee) => ({ ...ee, userId: e.user_id }));
            if (res && res?.length > 0) selectedRoutes = [...selectedRoutes, ...res];
        }
    });

    if (selectedRoutes && selectedRoutes?.length > 0) {
        selectedRoutes = selectedRoutes.map((e) => ({
            ...e,
            statusType:
                !e.id && !e.status
                    ? isOutdatedDays(e.date)
                        ? VisitStatus.Unperformed
                        : VisitStatus.New
                    : e.status,
        }));
    }

    return selectedRoutes && selectedRoutes?.length > 0 && !!anchorEl ? (
        <MenuDialog
            status={{}}
            row={{ project_id }}
            setValue={setValue}
            handleClose={handleClose}
            anchorEl={anchorEl}
            selectedRoutes={selectedRoutes}
            settings={settings}
            isOnlyRows={isOnlyRows}
        />
    ) : (
        <></>
    );
};
export default VisitScheduleGroupEditing;
