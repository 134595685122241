import { useLocation, useSearchParams, useNavigationType } from 'react-router-dom';
import { formatters } from '@tools/utils/date.util';
import moment from 'moment';
import { defaultValuesData } from '@pages/VisitFeed/utils/functions';

const filtersWithArray = [
    'project_ids',
    'user_ids',
    'project_retail_ids',
    'branch_ids',
    'territory_ids',
    'city_ids',
    'manager_ids',
    'statuses',
    'task_complexity_ids',
    'task_type_ids',
    'project_outlet_ids',
    'retail_ids',
];

export const useURLFilters = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const navigationType = useNavigationType();
    const isCrowd = location.pathname.includes('crowd');

    const formatDateForPayload = (date, startOrEnd) => {
        let result = moment(date).unix();
        if (startOrEnd === 'end') result = moment(date).add(1, 'days').unix();
        return result;
    };

    const setURLFilters = (e, name) => {
        const { target, value, start, end } = e || {};
        if (target) {
            const { value: targetValue, type, checked } = target || {};
            if (type === 'checkbox') {
                searchParams.set(name, btoa(checked));
            } else if (Array.isArray(targetValue)) {
                searchParams.set('price_lower', btoa(String(targetValue[0])));
                searchParams.set('price_upper', btoa(String(targetValue[1])));
            } else if (targetValue === '') {
                searchParams.delete(name);
            } else {
                try {
                    searchParams.set(name, btoa(targetValue));
                } catch {
                    searchParams.set(name, targetValue);
                }
            }
        }
        if (value) {
            try {
                searchParams.set(name, btoa(value));
            } catch {
                searchParams.set(name, value);
            }
        }
        if (Array.isArray(e)) {
            const arrayValuesInFilter = e.map((item) => item.value);
            const paramsArrayinUrlData = searchParams.getAll(name);
            const paramsArrayinUrl = paramsArrayinUrlData.map((e) => {
                let val;
                try {
                    val = atob(e);
                } catch {
                    val = e;
                }
                return val;
            });
            arrayValuesInFilter.forEach((val) => {
                if (!paramsArrayinUrl.includes(val)) {
                    searchParams.append(name, btoa(val));
                }
            });
            paramsArrayinUrl.forEach((val) => {
                if (!arrayValuesInFilter.includes(val)) {
                    searchParams.delete(name);
                }
            });
        }
        if (name === 'date_range' && start && end) {
            const date_from = formatters.toISODateFormatString(start, 'yyyy-MM-dd');
            const date_to = formatters.toISODateFormatString(end, 'yyyy-MM-dd');
            searchParams.set('date_from', btoa(date_from));
            searchParams.set('date_to', btoa(date_to));
        }
        if (e === null) {
            searchParams.delete(name);
        }
        setSearchParams(searchParams);
    };

    const resetURLFilters = () => {
        const { project_id, date_range } = defaultValuesData;
        const { start, end } = date_range || {};
        const newSearchParams = new URLSearchParams();
        if (project_id) newSearchParams.set('project_id', btoa(project_id));
        newSearchParams.set('date_from', btoa(start));
        newSearchParams.set('date_to', btoa(end));
        if (!isCrowd) {
            newSearchParams.append('statuses', btoa('completed'));
            newSearchParams.append('visit_type', btoa('visit'));
        } else {
            newSearchParams.set('price_lower', btoa('50'));
            newSearchParams.set('price_upper', btoa('1500'));
        }
        setSearchParams(newSearchParams);
    };

    const parseURL = () => {
        const now = moment().format('YYYY-MM-DD');
        const parsedFilterValues = {
            date_range: {
                start: now,
                end: now,
            },
        };
        searchParams.forEach((value, key) => {
            let val;
            try {
                val = atob(value);
            } catch {
                val = value;
            }

            if (key === 'date_from') {
                parsedFilterValues['date_range']['start'] = val;
                const startValue = isCrowd ? formatDateForPayload(val, 'start') : val;
                const keyName = isCrowd ? 'start_time_lower' : 'date_from';
                parsedFilterValues[keyName] = startValue;
            } else if (key === 'date_to') {
                const endValue = isCrowd ? formatDateForPayload(val, 'end') : val;
                parsedFilterValues['date_range']['end'] = val;
                const keyName = isCrowd ? 'start_time_upper' : 'date_to';
                parsedFilterValues[keyName] = endValue;
            } else if (filtersWithArray.includes(key)) {
                const currentValueData = searchParams.getAll(key);
                const currentValue = currentValueData.map((e) => {
                    let val;
                    try {
                        val = atob(e);
                    } catch {
                        val = e;
                    }
                    return val;
                });
                parsedFilterValues[key] = currentValue;
            } else {
                if (key !== 'start_time_lower' && key !== 'start_time_upper')
                    parsedFilterValues[key] = val;
            }
        });
        return parsedFilterValues;
    };

    const setURLFiltersFromValues = (values) => {
        Object.keys(values).forEach((key) => {
            const currentValue = values[key];
            const valueInUrl = searchParams.get(key);
            if (!valueInUrl) {
                if (key === 'date_range') {
                    const { start, end } = currentValue;
                    const date_from = formatters.toISODateFormatString(start, 'yyyy-MM-dd');
                    const date_to = formatters.toISODateFormatString(end, 'yyyy-MM-dd');
                    searchParams.set('date_from', btoa(date_from));
                    searchParams.set('date_to', btoa(date_to));
                } else if (Array.isArray(currentValue)) {
                    currentValue.forEach((val) => {
                        searchParams.append(key, btoa(val));
                    });
                } else if (currentValue) {
                    searchParams.set(key, btoa(currentValue));
                }
            }
        });
        searchParams.delete('project_ids');
        setSearchParams(searchParams);
    };

    const setURLFiltersFromDefaultValues = (defaultValues) => {
        let filterValuesFromUrl;
        if (navigationType === 'POP') {
            filterValuesFromUrl = parseURL();
            filterValuesFromUrl = {
                ...defaultValues,
                ...filterValuesFromUrl,
            };

            if (filterValuesFromUrl?.project_id || defaultValues?.project_id) {
                filterValuesFromUrl = {
                    ...filterValuesFromUrl,
                    project_ids: filterValuesFromUrl?.project_id
                        ? [filterValuesFromUrl?.project_id]
                        : [defaultValues?.project_id],
                };
            }

            setURLFiltersFromValues(filterValuesFromUrl);
        } else {
            setURLFiltersFromValues(defaultValues);
            filterValuesFromUrl = parseURL();
        }
        const resultFilterValues = { ...defaultValues, ...filterValuesFromUrl };

        return resultFilterValues;
    };

    return {
        searchParams,
        parseURL,
        setURLFilters,
        resetURLFilters,
        setSearchParams,
        setURLFiltersFromValues,
        setURLFiltersFromDefaultValues,
    };
};
