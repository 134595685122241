import React, { forwardRef, useEffect, useImperativeHandle } from 'react';

import { useForm, FieldValues, FormProvider } from 'react-hook-form';

import { FormField } from './ComplexForm.type';
import { ComplexFormField } from './ComplexFormField';
import { FieldWrapper } from './FieldWrapper';
import { useCompareValues } from './hook';
import styles from './ComplexFormStyle.module.scss';

interface ComplexFormProps {
    className?: string;
    fields: FormField[];
    defaultValues: FieldValues;
    gridColumns?: 4 | 8 | 16 | 24;
    onChange?: (values: FieldValues, name?: string) => void;
    onSubmit?: (values: FieldValues) => void;
    onDelete?: () => void;
    onReset?: () => void;
    loading?: boolean;
    disabled?: boolean;
}

const ComplexForm: React.FC<ComplexFormProps> = (
    {
        fields,
        defaultValues,
        onSubmit,
        onChange,
        loading = false,
        className = '',
        disabled = false,
    },
    ref
) => {
    const { isSame } = useCompareValues();

    const methods = useForm({
        defaultValues,
    });

    const { watch, reset } = methods;

    useImperativeHandle(ref, () => {
        return {
            resetForm(values?: FieldValues) {
                reset(values || defaultValues);
            },
        };
    });

    useEffect(() => {
        const subscription = watch((values, { name }) => {
            if (!isSame(values) || name === 'questionnaire_kind' || name === 'questionnaire_name') {
                if (onChange) {
                    onChange(values, name);
                }
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, onChange]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (onSubmit) {
            methods.handleSubmit(onSubmit)(e);
        }
    };
    return (
        <FormProvider {...methods}>
            <form className={`${styles.form} ${className}`} onSubmit={handleSubmit}>
                {fields.map((field) => (
                    <FieldWrapper key={field.name} field={field}>
                        <ComplexFormField
                            key={field.name}
                            field={field}
                            disabled={disabled || field.disabled || loading}
                        />
                    </FieldWrapper>
                ))}
            </form>
        </FormProvider>
    );
};

export default forwardRef(ComplexForm);
