import { getProjectOutlet } from '@apiFeature/projectOutlets';
import { fetchBranchList } from '@apiFeature/branch';
import { fetchCitiesList } from '@apiFeature/cities';

export const getOutletValues = (project_outlet_id) => {
    return new Promise((resolve, reject) => {
        if (project_outlet_id) {
            getProjectOutlet(project_outlet_id)
                .then(async (outlet) => {
                    const { project_outlet } = outlet || {};
                    const { external_code, branch_id, city_id, retails_format } =
                        project_outlet || {};

                    let branch_name;
                    if (branch_id) {
                        await fetchBranchList({
                            ids: [branch_id],
                        }).then((branchList) => {
                            if (branchList && branchList?.length) {
                                branch_name = branchList[0].name;
                            }
                        });
                    }

                    let city_name;
                    if (city_id) {
                        await fetchCitiesList({
                            ids: [city_id],
                        }).then((cityList) => {
                            if (cityList && cityList?.length) {
                                city_name = cityList[0].name;
                            }
                        });
                    }

                    resolve({ external_code, branch_name, city_name, retails_format });
                })
                .catch((error) => {
                    reject(error);
                });
        } else {
            reject(new Error('Invalid task or project outlet id'));
        }
    });
};

export const arrayToHashmap = (array: Array<any>) => {
    const hashMap = {};
    array.forEach((item) => {
        hashMap[item.id] = item;
    });
    return hashMap;
};
