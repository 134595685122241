import React, { useCallback, useEffect, useState } from 'react';
import styles from './VisitFeedFilters.module.scss';
import { useTranslation } from 'react-i18next';
import { Select, TextField, RangePicker } from '@components';
import { getOptions } from '@tools/utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import FiltersSelectors from '@redux/filters/selectors';
import { FiltersThunks } from '@redux/filters/thunk';
import { useRoles } from '@tools/hooks/useRoles';
import { fixTimezoneOffset, getMinDateBasedOnSettings } from '@tools/utils/date.util';
import SettingsSelectors from '@redux/settings/selectors';
import { getFiltersHiddenActive } from '../utils/functions';

const VisitFeedFilters = ({ defaultValues, form }) => {
    const { clientPermission } = useRoles();

    const dispatch = useDispatch();
    const { projectsList, loading: loadingProjects } = useSelector(
        ProjectsSelectors.projectsSelectors
    );
    const projectsListId = projectsList?.[0]?.id;

    const {
        users,
        usersLoading,
        managers,
        managersLoading,
        retails,
        retailsLoading,
        branches,
        branchesLoading,
        territory,
        territoryLoading,
        cities,
        citiesLoading,
    } = useSelector(FiltersSelectors.filtersState);

    const { settingsAll } = useSelector(SettingsSelectors.settingsState);
    const { settings } = settingsAll || {};
    const [minDate, setMinDate] = useState<Date | null>(null);
    const [filtersHiddenActive, setFiltersHiddenActive] = useState<string[]>([]);

    const { t } = useTranslation('translation');

    const { reset, watch, setValue } = form;
    const currValues = watch();
    const {
        project_id: pr_id,
        date_range,
        user_ids,
        project_retail_ids,
        branch_ids,
        territory_ids,
        city_ids,
        manager_ids,
        visit_type,
        statuses,
    } = currValues;
    const project_id = pr_id || projectsListId;
    const start_date = fixTimezoneOffset(date_range.start, 'start').replace('Z', '');
    const end_date = fixTimezoneOffset(date_range.end, 'end').replace('Z', '');

    const setActualValue = (curData, data, name) => {
        const value = data?.filter((e) => curData?.includes(e.id)).map((e) => e.id);
        if (curData && curData?.join() !== value?.join()) {
            setValue(name, value.length > 0 ? value : null);
        }
    };

    const getFiltersProjects = useCallback(() => {
        dispatch(FiltersThunks.getRetailsList({ project_id })).then((result) => {
            if (FiltersThunks.getRetailsList.fulfilled.match(result)) {
                const retails = result?.payload || [];
                if (retails?.length > 0)
                    setActualValue(project_retail_ids, retails, 'project_retail_ids');
            }
        });
        dispatch(FiltersThunks.getBranchesList({ project_id })).then((result) => {
            if (FiltersThunks.getBranchesList.fulfilled.match(result)) {
                const branches = result?.payload || [];
                if (branches?.length > 0) setActualValue(branch_ids, branches, 'branch_ids');
            }
        });
        dispatch(FiltersThunks.getTerritoryList({ project_id })).then((result) => {
            if (FiltersThunks.getTerritoryList.fulfilled.match(result)) {
                const territories = result?.payload || [];
                if (territories?.length > 0)
                    setActualValue(territory_ids, territories, 'territory_ids');
            }
        });
        dispatch(FiltersThunks.getCitiesList({ project_id })).then((result) => {
            if (FiltersThunks.getCitiesList.fulfilled.match(result)) {
                const cities = result?.payload || [];
                if (cities?.length > 0) setActualValue(city_ids, cities, 'city_ids');
            }
        });
    }, [dispatch, project_id]);

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    const getExecutors = useCallback(() => {
        if (!clientPermission) {
            dispatch(
                FiltersThunks.getUsersList({
                    project_id,
                    visit_type,
                    start_date,
                    end_date,
                    ...(manager_ids && manager_ids.length && { head_id: manager_ids[0] }),
                })
            ).then((result) => {
                if (FiltersThunks.getUsersList.fulfilled.match(result)) {
                    const users = result?.payload || [];
                    if (user_ids?.length > 0) setActualValue(user_ids, users, 'user_ids');
                }
            });
        }
    }, [manager_ids, project_id, start_date, end_date, visit_type]);

    const getManagers = useCallback(() => {
        if (!clientPermission) {
            dispatch(
                FiltersThunks.getManagersList({
                    project_id,
                    visit_type,
                    start_date,
                    end_date,
                })
            ).then((result) => {
                if (FiltersThunks.getManagersList.fulfilled.match(result)) {
                    const managers = result?.payload || [];
                    if (manager_ids?.length > 0)
                        setActualValue(manager_ids, managers, 'manager_ids');
                }
            });
        }
    }, [project_id, start_date, end_date, visit_type]);

    useEffect(() => {
        getProjects();
    }, []);

    useEffect(() => {
        if (projectsList.length > 0 && !project_id) setValue('project_id', projectsListId);
    }, [projectsList]);

    useEffect(() => {
        if (project_id) getFiltersProjects();
    }, [project_id, getFiltersProjects]);

    useEffect(() => {
        if (project_id) {
            getExecutors();
        }
    }, [manager_ids, project_id, start_date, end_date, visit_type]);

    useEffect(() => {
        if (project_id) {
            getManagers();
        }
    }, [start_date, end_date, project_id, visit_type]);

    useEffect(() => {
        const { settings } = settingsAll || {};
        if (settings) {
            const minDate = getMinDateBasedOnSettings(settings, project_id, clientPermission);
            if (minDate) setMinDate(minDate);

            let filtersHiddenActive = getFiltersHiddenActive(settings);
            filtersHiddenActive = filtersHiddenActive?.length > 0 ? filtersHiddenActive : ['none'];
            setFiltersHiddenActive(filtersHiddenActive);
        }
    }, [settingsAll]);

    const onChange = (name, e) => {
        let value = e?.value;
        if (e && Array.isArray(e)) {
            value = e.length > 0 ? e.map((item) => item.value) : null;
        }
        setValue(name, value);
    };

    const handleReset = () => {
        reset({
            ...defaultValues,
            project_id: projectsListId,
        });
    };

    const projectOptions = getOptions(projectsList, 'full_name');
    const retailsOptions = getOptions(retails);
    const branchesOptions = getOptions(branches);
    const territoryOptions = getOptions(territory);
    const citiesOptions = getOptions(cities);
    const usersOptions = getOptions(users, ['last_name', 'first_name', 'patronymic_name', 'phone']);
    const managerOptions = getOptions(managers, [
        'last_name',
        'first_name',
        'patronymic_name',
        'phone',
    ]);

    let visitTypeOptions = [
        {
            label: t('feed.visit'),
            value: 'visit',
        },
        {
            label: t('feed.audit'),
            value: 'audit',
        },
    ];
    if (!clientPermission)
        visitTypeOptions = [
            ...visitTypeOptions,
            {
                label: t('feed.auditManager'),
                value: 'audit_manager',
            },
        ];

    const visitStatusOptions = [
        {
            label: t('statuses.completed'),
            value: 'completed',
        },
        {
            label: t('statuses.inProgress'),
            value: 'in_progress',
        },
        {
            label: t('statuses.problemReported'),
            value: 'problem_reported',
        },
    ];

    const filtersData = [
        {
            type: Select,
            name: 'project_id',
            label: t('common.project'),
            onChange,
            value: project_id
                ? projectOptions?.find((e) => e.value === project_id)
                : projectOptions?.[0],
            options: projectOptions,
            isLoading: loadingProjects,
        },
        {
            type: RangePicker,
            name: 'date_range',
            label: t('common.date_range'),
            placeholder: t('common.select'),
            dateFormat: 'dd.MM.yyyy',
            valueStart: date_range?.start,
            valueEnd: date_range?.end,
            minDate,
            maxDate: clientPermission ? new Date() : null,
        },
        {
            type: Select,
            name: 'user_ids',
            label: t('common.user'),
            placeholder: t('common.all'),
            onChange,
            value: user_ids ? usersOptions?.filter((e) => user_ids.includes(e.value)) : null,
            options: usersOptions,
            isLoading: usersLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'project_retail_ids',
            label: t('common.retail'),
            placeholder: t('common.all'),
            onChange,
            value: project_retail_ids
                ? retailsOptions?.filter((e) => project_retail_ids.includes(e.value))
                : null,
            options: retailsOptions,
            isLoading: retailsLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'branch_ids',
            label: t('feed.branches'),
            placeholder: t('common.all'),
            onChange,
            value: branch_ids ? branchesOptions?.filter((e) => branch_ids.includes(e.value)) : null,
            options: branchesOptions,
            isLoading: branchesLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'territory_ids',
            label: t('common.territory'),
            placeholder: t('common.all'),
            onChange,
            value: territory_ids
                ? territoryOptions?.filter((e) => territory_ids.includes(e.value))
                : null,
            options: territoryOptions,
            isLoading: territoryLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'city_ids',
            label: t('common.city'),
            placeholder: t('common.all'),
            onChange,
            value: city_ids ? citiesOptions?.filter((e) => city_ids.includes(e.value)) : null,
            options: citiesOptions,
            isLoading: citiesLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: TextField,
            name: 'external_code',
            label: t('feed.id'),
            placeholder: t('feed.searchId'),
        },
        {
            type: TextField,
            name: 'number',
            label: t('feed.visitNumber'),
            placeholder: t('feed.typeVisitNumber'),
        },
        {
            type: Select,
            name: 'manager_ids',
            label: t('common.manager'),
            placeholder: t('common.all'),
            onChange,
            value: manager_ids
                ? managerOptions?.filter((e) => manager_ids.includes(e.value))
                : null,
            options: managerOptions,
            isLoading: managersLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'visit_type',
            label: t('feed.visitType'),
            placeholder: t('common.all'),
            onChange,
            value: visitTypeOptions?.find((e) => e.value === visit_type),
            options: visitTypeOptions,
        },
        {
            type: Select,
            name: 'statuses',
            label: t('feed.visitStatus'),
            placeholder: t('common.all'),
            onChange,
            value: statuses ? visitStatusOptions?.filter((e) => statuses.includes(e.value)) : null,
            options: visitStatusOptions,
            isClearable: true,
            isMulti: true,
        },
    ];

    let filters = clientPermission
        ? filtersData.filter((e) => e.name !== 'user_ids' && e.name !== 'manager_ids')
        : filtersData;

    filters = clientPermission
        ? filters.filter((e) => !filtersHiddenActive.includes(e.name))
        : filters;

    if ((clientPermission && settings && filtersHiddenActive?.length > 0) || !clientPermission) {
        return { handleReset, filters };
    }
};

export default VisitFeedFilters;
