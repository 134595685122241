import React, { useCallback, useEffect, useState } from "react";
import styles from './QuestionnairesData.module.scss';
import { t } from 'i18next';
import { TableGrid } from '@components';
import { ReactComponent as EditIcon } from '@images/svg/edit.svg';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import ProjectsSelectors from '@redux/projects/selectors';
import { QuestionnairesResponse } from "@apiFeature/questionnaires/type";
import { fetchQuestionnairesList } from "@apiFeature/questionnaires";
import moment from "moment";
import { kindOptionsForQuestionnaires } from '@constants';

const limit = 10;

const columns = [
    {
        field: 'name',
        headerName: t('common.name'),
        flex: 2,
        sortable: false,
    },
    {
        field: 'external_code',
        headerName: t('catalog.outlets.code'),
        flex: 2,
        sortable: false,
    },
    {
        field: 'kind',
        headerName: t('feed.type'),
        valueGetter: ({ value }) =>
            value ? kindOptionsForQuestionnaires.find((kind) => kind.value === value)?.label : '',
        flex: 2,
        sortable: false,
    },
    {
        field: 'is_active',
        headerName: t('common.status'),
        valueGetter: ({ value }) =>
            value ? t('statuses.active') : t('statuses.inactive'),
        flex: 2,
        sortable: false,
    },
    {
        field: 'created_at',
        headerName: t('questionnaires.createDate'),
        renderHeader: () => (
            <div className={styles.header}>
                {t('questionnaires.createDate')}
            </div>
        ),
        valueGetter: ({ value }) => (value ? moment(value).format('DD.MM.yyyy') : ''),
        flex: 2,
        sortable: false,
    },
    {
        field: 'version',
        headerName: t('questionnaires.version'),
        flex: 1,
        sortable: false,
    },
    {
        field: 'order',
        headerName: t('questionnaires.order'),
        width: 140,
        sortable: false,
    },
    {
        field: 'period_lower',
        headerName: t('questionnaires.periodLower'),
        renderHeader: () => (
            <div className={styles.header}>
                {t('questionnaires.periodLower')}
            </div>
        ),
        valueGetter: ({ value }) => (value ? moment(value).format('DD.MM.yyyy') : ''),
        flex: 2,
        sortable: true,
    },
    {
        field: 'period_upper',
        headerName: t('questionnaires.periodUpper'),
        renderHeader: () => (
            <div className={styles.header}>
                {t('questionnaires.periodUpper')}
            </div>
        ),
        valueGetter: ({ value }) => (value ? moment(value).format('DD.MM.yyyy') : ''),
        flex: 2,
        sortable: false,
    },
];

const QuestionnairesData = ({ defaultValues, filters }) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const paginationModelDefault = { page: 0, pageSize: limit };
    const { projectsList } = useSelector(ProjectsSelectors.projectsSelectors);
    const [paginationModel, setPaginationModel] = useState(paginationModelDefault);
    const [sortModel, setSortModel] = useState({ period_lower: 'desc' });
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<QuestionnairesResponse | null>(null);
    const { items = [], total = 0, offset } = data || {};
    const { project_id: dpID } = defaultValues || {};
    const { project_id: pID } = filters || {};
    const projectId = pID || dpID;

    const getQuestionniares = useCallback(async (offset = 0) => {
        setLoading(true);
        const [project] = projectsList || [];
        const { id } = project || {};

        const project_id = projectsList.find((p) => p.id === projectId)?.id ?? id;
        const { status } = defaultValues || {};
        fetchQuestionnairesList({
            project_id,
            is_active: status,
            ...filters,
            sorting: sortModel,
            pagination: {
                limit,
                offset,
            },
        }).then((res) => {
            setData(res);
        }).catch((error) => {
            const errorMessage =
                error?.message || error?.data?.detail || t('messages.unknownError');
            enqueueSnackbar(errorMessage, { variant: 'error' });
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        });
    }, [filters, projectsList]);

    useEffect(() => {
        if (!isLoading && projectsList?.length > 0) {
            getQuestionniares(paginationModel?.page * limit);
        }
    }, [filters, paginationModel, projectsList]);

    useEffect(() => {
        if (filters && Object.keys(filters)?.length > 0) {
            setPaginationModel(paginationModel);
        }
    }, [filters]);

    useEffect(() => {
        setPaginationModel(paginationModelDefault);
    }, [sortModel]);

    const onSortModelChange = () => {
        setSortModel((prevState) => ({
            ...prevState,
            period_lower: prevState?.period_lower === 'asc' ? 'desc' : 'asc',
        }));
    };

    const openQuestionniareEdit = (id) => {
        navigate(`/questionnaires/edit/${id}/${projectId}`);
    };

    const resultColumns = [
        {
            field: 'actions',
            type: 'actions',
            headerName: t('common.actions'),
            width: 90,
            cellClassName: 'actions',
            renderCell: (params) => (
                <div className={styles.editIcon} onClick={() => openQuestionniareEdit(params.row.id)}>
                    <EditIcon />
                </div>
            ),
        },
        ...columns,
    ];
    return (
        <>
            <TableGrid
                rows={items}
                columns={resultColumns}
                rowCount={total}
                loading={isLoading}
                pageSizeOptions={[limit]}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                paginationModel={offset === 0 ? paginationModelDefault : paginationModel}
                onSortModelChange={onSortModelChange}
                sort={sortModel?.period_lower}
                hasSun
                getRowHeight={() => 'auto'}
                className={styles.tableGrid}
            />
        </>
    )
};

export default QuestionnairesData;