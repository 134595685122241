import { ProjectType } from "../../apiFeature/projects/types";

export const reduxStoreName = 'feeds';

export interface FeedState {
    loading?: boolean;
    error?: boolean;
    errorMessage?: string;
    projectsList?: ProjectType[] | null;
    branchList?: [];
}
