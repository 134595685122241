import React, { FC, useEffect, useState } from 'react';
import styles from './QItem.module.scss';
import { TQuestion } from '@apiFeature/questionnaires/type';
import { useFormContext, Controller } from 'react-hook-form';
import { Checkbox, Select, TextField } from '@components';
import { Tooltip } from '@mui/material';
import { FilterValue } from '@apiFeature/types';
import { useTranslation } from 'react-i18next';
import { getIntegerValue } from '@tools/utils/string.utils';
import ImageUpload from '../../../../../../ImageUpload/ImageUpload';
import { removeDuplicatesForImages } from '../../../../../../utilsForRestoredVisit';

interface IQItem {
    question?: TQuestion;
    registerStr: string;
}

const QItem: FC<IQItem> = ({ question, registerStr }) => {
    const { name, kind, question_answers, id, is_photo_required, question_result } = question || {};
    const { id: question_result_id } = question_result || {};
    const { t } = useTranslation('translation');
    const { control, setValue, formState, watch } = useFormContext();
    const [photos, setPhotos] = useState<{ url: string | File; id: string | null }[]>([]);
    const photosInit = watch(`photosInit.${id}`);
    const photosCurr = watch(`photosCurr.${id}`);
    const photosDelete = watch('photosDelete') || [];
    let answers: FilterValue[] = [];
    if (question_answers?.length) {
        answers = question_answers?.map((qa) => {
            return {
                value: qa.id,
                label: qa.answer,
            };
        });
    }

    const { isSubmitting } = formState || {};

    useEffect(() => {
        const { question_result } = question || {};
        const { file_links } = question_result || {};
        if (!photosInit && file_links?.length > 0) {
            const arrayOfFiles = file_links
                .map((fl) => {
                    const { file, id } = fl || {};
                    const { name, url } = file || {};
                    if (name !== 'preview.webp') {
                        return { url, id };
                    }
                })
                .filter((i) => i !== undefined);

            const uniqueFiles = removeDuplicatesForImages(arrayOfFiles);
            const uniqueImages = uniqueFiles.map((e) => e.url);

            setPhotos(uniqueFiles);
            setValue(`photosQuestion.${id}`, {
                files: uniqueImages,
                question_result_id,
            });
            setValue(`photosInit.${id}`, true);
            setValue(`photosCurr.${id}`, uniqueFiles);
        }
    }, [question]);

    useEffect(() => {
        if (photosCurr?.length > 0) {
            setPhotos(photosCurr);
        }
    }, []);

    const handleChangeManyAnswers = (e, name, value, answerId) => {
        const { checked } = e?.target || {};
        const answersArray = value && value?.length > 0 ? value : [];
        if (checked) {
            answersArray.push(answerId);
            setValue(name, answersArray);
        } else {
            const resultAnswers = answersArray.filter((id) => id !== answerId);
            setValue(name, resultAnswers);
        }
    };

    const renderAnswerByKind = (kind: string) => {
        switch (kind) {
            case 'one_answer':
                return (
                    <Tooltip placement="top" title={t('questionnaires.oneAnswer')}>
                        <div className={styles.formItem}>
                            <Controller
                                name={`${registerStr}.${id}`}
                                control={control}
                                render={({ field: { name, value, ref } }) => (
                                    <Select
                                        id={name}
                                        name={name}
                                        inputRef={ref}
                                        onChange={(e) => {
                                            setValue(name, e?.value || null);
                                        }}
                                        value={answers?.find((e) => e.value === value)}
                                        disabled={isSubmitting}
                                        options={answers}
                                    />
                                )}
                            />
                        </div>
                    </Tooltip>
                );
            case 'many_answers':
                return (
                    <Controller
                        name={`${registerStr}.${id}`}
                        control={control}
                        render={({ field: { name, value } }) => (
                            <>
                                {answers.map((answer) => (
                                    <Checkbox
                                        key={answer?.value}
                                        label={answer?.label}
                                        value={value ? value.includes(answer?.value) : false}
                                        onChange={(e) => {
                                            handleChangeManyAnswers(e, name, value, answer?.value);
                                        }}
                                        disabled={isSubmitting}
                                        id={`${name}.${answer?.value}`}
                                    />
                                ))}
                            </>
                        )}
                    />
                );
            case 'your_answer':
                return (
                    <Tooltip placement="top" title={t('questionnaires.text')}>
                        <div className={styles.formItem}>
                            <Controller
                                name={`${registerStr}.${id}`}
                                control={control}
                                render={({
                                    field: { onChange, name, value },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value || ''}
                                        id={name}
                                        variant="outlined"
                                        error={error}
                                        disabled={isSubmitting}
                                    />
                                )}
                            />
                        </div>
                    </Tooltip>
                );
            case 'number_answer':
                return (
                    <Tooltip placement="top" title={t('questionnaires.number')}>
                        <div className={styles.formItem}>
                            <Controller
                                name={`${registerStr}.${id}`}
                                control={control}
                                render={({
                                    field: { onChange, name, value },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value || ''}
                                        id={name}
                                        variant="outlined"
                                        error={error}
                                        disabled={isSubmitting}
                                        inputProps={{ type: 'number' }}
                                    />
                                )}
                            />
                        </div>
                    </Tooltip>
                );
            case 'integer_answer':
                return (
                    <Tooltip placement="top" title={t('questionnaires.integerAnswer')}>
                        <div className={styles.formItem}>
                            <Controller
                                name={`${registerStr}.${id}`}
                                control={control}
                                render={({
                                    field: { onChange, name, value },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        onChange={(e) =>
                                            onChange({
                                                ...e,
                                                target: {
                                                    ...e.target,
                                                    value: getIntegerValue(e.target.value),
                                                },
                                            })
                                        }
                                        value={value || ''}
                                        id={name}
                                        variant="outlined"
                                        error={error}
                                        disabled={isSubmitting}
                                        inputProps={{ maxLength: 15 }}
                                    />
                                )}
                            />
                        </div>
                    </Tooltip>
                );
        }
    };

    return (
        <div className={styles.questionWrapper}>
            <div className={styles.questionText}>{name}</div>
            <div className={styles.answer}>{renderAnswerByKind(kind)}</div>
            <div className={styles.photos}>
                {(kind === 'photo_answer' || is_photo_required) && (
                    <div className={styles.imageWrapper}>
                        <Controller
                            name={`photosCurr.${id}`}
                            control={control}
                            render={({ field: { onChange } }) => (
                                <ImageUpload
                                    existingImages={photos}
                                    onImagesChange={(files) => {
                                        onChange(files);
                                        setValue(`photosQuestion.${id}`, {
                                            files: files.map((e) => e.url),
                                            question_result_id,
                                        });
                                    }}
                                    onImagesDelete={(deleteIds) => {
                                        const photosDeleteArr = [...photosDelete, ...deleteIds];
                                        setValue('photosDelete', photosDeleteArr);

                                        if (photosCurr?.length > 0) {
                                            const photosCurrArr = photosCurr?.filter(
                                                (e) => !photosDeleteArr.includes(e.id)
                                            );
                                            setValue(`photosCurr.${id}`, photosCurrArr);
                                        }
                                    }}
                                    multiple={true}
                                    disabled={isSubmitting}
                                />
                            )}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default QItem;
