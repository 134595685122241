import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useRoles } from '@tools/hooks/useRoles';
import { Loader } from '@components';
import { permissionCheck } from '@tools/utils/permissionCheck';
import { crowdTaskStatusNames } from '@apiFeature/crowd/types';
import ListMapper from '@components/Lists/List/ListMapper/ListMapper';
import { useAppSelector } from '@tools/hooks/redux';
import { getUserById } from '@apiFeature/user';
import { getTaskTypeList, getCrowdTaskComplexityList } from '@apiFeature/crowd';
import moment from 'moment';
import { getOutletValues, arrayToHashmap } from './utils/utils';
import styles from '../Info/Info.module.scss';

interface IInfoCrowd {
    data: { taskItem; taskResult };
    isChecker?: boolean;
}

const InfoCrowd: FC<IInfoCrowd> = ({ data: { taskItem, taskResult }, isChecker = false }) => {
    const [isLoading, setLoading] = useState(false);
    const [taskTypes, setTaskTypes] = useState({});
    const [taskComplexities, setTaskComplexities] = useState({});
    const [outletData, setOutletData] = useState({});
    const [user, setUser] = useState(null);

    const { t } = useTranslation('translation');

    const authRoles = useAppSelector((state) => state?.auth?.jwtPayloadOpen?.roles);
    const { isClientCrowd } = useRoles();

    const { start_time, end_time, status, user_id } = taskResult || {};
    const {
        project_outlet,
        start_date,
        end_date,
        task_type_id,
        price,
        timing,
        task_complexity_id,
    } = taskItem || {};
    const { id: project_outlet_id, name, address } = project_outlet || {};
    let duration;
    if (start_time && end_time) {
        const durationStart = moment(start_time);
        const durationEnd = moment(end_time);
        const diff = durationEnd.diff(durationStart);
        duration = moment.utc(diff).format('HH:mm:ss');
    } else {
        duration = '-';
    }

    const taskComplexity =
        taskComplexities?.length && task_complexity_id
            ? taskComplexities.find((tCom) => tCom.id === task_complexity_id)?.name
            : '-';

    const { external_code, branch_name, city_name, retails_format } = outletData;

    const fields = {
        [t('common.outletName')]: { value: name ?? '-' },
        [t('feed.address')]: { value: address ?? '-' },
        [t('common.status')]: {
            value: status ? crowdTaskStatusNames[status] : '-',
        },
        [t('crowd.price')]: { value: price ?? '-' },
        [t('crowd.startDate')]: {
            value: start_date ? moment(start_date).format('D MMMM, HH:mm:ss') : '-',
        },
        [t('crowd.endDate')]: {
            value: end_date ? moment(end_date).format('D MMMM, HH:mm:ss') : '-',
        },
        [t('crowd.task_type')]: { value: task_type_id ? taskTypes[task_type_id]?.name : '-' },
        [t('crowd.startTime')]: {
            value: !!start_time ? moment(start_time).format('D MMMM, HH:mm:ss') : '-',
        },
        [t('crowd.endTime')]: {
            value: !!end_time ? moment(end_time).format('D MMMM, HH:mm:ss') : '-',
        },
        [t('crowd.task_complexity')]: { value: taskComplexity },
        [t('feed.timeSpend')]: { value: duration },
        [t('crowd.plannedTime')]: { value: timing ?? '-' },
        [t('feed.branch')]: { value: branch_name ?? '-' },
        [t('common.city')]: { value: city_name ?? '-' },
        [t('common.outletCode')]: { value: external_code ?? '-' },
        [t('feed.retailsFormat')]: {
            value: retails_format ? retails_format : '-',
        },
    };

    useEffect(() => {
        (async () => {
            setLoading(true);

            await getTaskTypeList({}).then((res) => {
                setTaskTypes(arrayToHashmap(res));
            });
            await getCrowdTaskComplexityList({}).then((res) => {
                setTaskComplexities(res);
            });
            await getOutletValues(project_outlet_id).then((res) => {
                setOutletData(res);
            });

            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (user_id) {
            getUserById(user_id)?.then((res) => {
                setUser(res);
            });
        }
    }, [user_id]);

    const { user: userData, roles } = user || {};
    const { first_name, phone } = userData || {};

    return (
        <div
            className={classNames(styles.info, styles.infoCrowd, { [styles.isChecker]: isChecker })}
        >
            {isLoading && <Loader />}
            <div className={styles.data}>
                <div className={styles.header}>
                    <div className={styles.first_name}>{!isClientCrowd && first_name}</div>
                    <div className={styles.name_phone}>
                        {!isClientCrowd && `${roles?.[0]?.name}, ${phone}`}
                    </div>
                </div>
                <div className={styles.fields}>
                    <ListMapper
                        items={permissionCheck(authRoles, fields)}
                        className={styles.serializedFeedItem}
                    />
                </div>
            </div>
        </div>
    );
};

export default InfoCrowd;
