import React, { FC, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import BasePicker from 'react-datepicker';
import { FormHelperText } from '@mui/material';
import { Button } from 'src/view/components/ui/Button/Button';
import { DatePickerHeader } from 'src/view/components/ui/form/date/DatePickerHeader';
import { ReactComponent as ArrowIcon } from '@images/svg/chevron-down.svg';
import 'react-datepicker/dist/react-datepicker.min.css';
import styles from './RangePicker.module.scss';
import i18next from 'i18next';
import i18n from '../../i18n';
import Label from '../Label/Label';

interface IRangePicker {
    label?: string;
    id?: string;
    name?: string;
    valueStart?: string | null;
    valueEnd?: string | null;
    className?: string;
    dateFormat?: string;
    placeholder?: string;
    disabled?: boolean;
    error?: {
        message: string;
    };
    onChange: ({ start, end }) => void;
    onBlur?: () => void;
    minDate?: Date;
    maxDate?: Date;
}

const RangePicker: FC<IRangePicker> = ({
    label,
    id,
    name,
    valueStart,
    valueEnd,
    placeholder = '',
    className,
    dateFormat,
    disabled,
    onBlur,
    onChange,
    error,
    minDate,
    maxDate,
}) => {
    const [startString, setStartString] = useState<string | null>(valueStart || null);
    const [endString, setEndString] = useState<string | null>(valueEnd || null);

    useEffect(() => {
        if (valueStart && valueStart !== startString) {
            setStartString(valueStart);
        } else if (valueStart === null) {
            setStartString(valueStart);
        }
        if (valueEnd && valueEnd !== endString) {
            setEndString(valueEnd);
        } else if (valueEnd === null) {
            setEndString(valueEnd);
        }
    }, [valueStart, valueEnd]); // eslint-disable-line react-hooks/exhaustive-deps

    const [showYearPicker, setShowYearPicker] = useState(false);

    const [open, setOpen] = useState(false);

    const openCalendar = () => {
        setOpen(true);
    };
    const handleClickOutside = () => {
        setOpen(false);
        setStartString(valueStart ?? null);
        setEndString(valueEnd ?? null);
        if (valueEnd) {
            handleSubmit();
        }
    };

    const handleYearClick = () => {
        setShowYearPicker(true);
    };

    const handleChange = (dates: (Date | null)[]) => {
        if (showYearPicker) {
            setShowYearPicker(false);
        }
        const [start, end] = dates;
        setStartString(start?.toISOString() ?? null);
        setEndString(end?.toISOString() ?? null);
    };

    const handleSubmit = () => {
        onChange({ start: startString, end: endString });
        setOpen(false);
    };

    const { startDate, endDate } = useMemo(() => {
        const startDate = startString && new Date(startString);
        const endDate = endString && new Date(endString);
        const isDateCorrect = (date) => {
            if (!date || date.toString() === 'Invalid Date') return null;
            return date;
        };
        return {
            startDate: isDateCorrect(startDate),
            endDate: isDateCorrect(endDate),
        };
    }, [startString, endString]);

    return (
        <>
            {label && <Label htmlFor={name}>{label}</Label>}
            <div
                className={classNames(
                    styles.datePicker,
                    'date-picker-wrapper',
                    { 'date-picker--open': open, [styles.error]: error },
                    className
                )}
            >
                <BasePicker
                    id={id || name}
                    selectsRange
                    dateFormat={dateFormat}
                    disabled={disabled}
                    endDate={endDate}
                    locale={i18n.language === 'ru' ? ruLocale : i18n.language}
                    open={open}
                    placeholderText={placeholder}
                    renderCustomHeader={(props) => (
                        <DatePickerHeader
                            {...props}
                            yearPicker={showYearPicker}
                            onYearClick={handleYearClick}
                        />
                    )}
                    showYearPicker={showYearPicker}
                    startDate={startDate}
                    onBlur={onBlur}
                    onCalendarOpen={openCalendar}
                    onChange={handleChange}
                    onClickOutside={handleClickOutside}
                    popperProps={{ strategy: 'fixed' }}
                    minDate={minDate}
                    maxDate={maxDate}
                >
                    <div className="text-center">
                        <Button
                            disabled={!endDate || !startDate}
                            className="date-picker__submit"
                            type="default"
                            onClick={handleSubmit}
                        >
                            {i18next.t('common.submit')}
                        </Button>
                    </div>
                </BasePicker>
                <ArrowIcon
                    className={classNames('date-picker-icon transition-transform', {
                        'rotate-180': open,
                    })}
                />
                {error && <FormHelperText error>{error.message}</FormHelperText>}
            </div>
        </>
    );
};

export default RangePicker;
