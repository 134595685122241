import React, { FC } from "react";
import styles from './TaskCreateDialog.module.scss';
import { Checkbox, DatePicker, TextField, Loader } from '@components';
import { useTranslation } from "react-i18next";

interface ITaskCreate {
    setValue: (name: string, e: any) => void;
    deadlineEdit?: string;
    descriptionEdit?: string;
    is_requiredEdit?: boolean;
    is_photo_requiredEdit?: boolean;
    loading?: boolean;
}

const TaskCreateDialog: FC<ITaskCreate> = ({
    setValue,
    deadlineEdit,
    descriptionEdit,
    is_requiredEdit,
    is_photo_requiredEdit,
    loading,
}) => {
    const { t } = useTranslation('translation');

    return (
        <div className={styles.form}>
            {loading && <Loader />}
            <div className={styles.items}>
                <div className={styles.deadline}>
                    <DatePicker
                        name='deadlineEdit'
                        onChange={(e) => {
                            setValue('deadlineEdit', e);
                        }}
                        label={t('tasks.deadline')}
                        value={deadlineEdit}
                        dateFormat="dd.MM.yyyy"
                        disabled={loading}
                    />
                </div>
                <div>
                    <TextField
                        label={t('tasks.description')}
                        type="text"
                        value={descriptionEdit}
                        onChange={(e) => {
                            setValue('descriptionEdit', e?.target?.value);
                        }}
                        disabled={loading}
                    />
                </div>
                <div>
                    <Checkbox
                        value={!!is_requiredEdit}
                        onChange={() => {
                            setValue('is_requiredEdit', !is_requiredEdit);
                        }}
                        label={t('tasks.isRequiredTask')}
                        className={styles.isRequired}
                        disabled={loading}
                    />
                </div>
                <div>
                    <Checkbox
                        value={!!is_photo_requiredEdit}
                        onChange={() => {
                            setValue('is_photo_requiredEdit', !is_photo_requiredEdit);
                        }}
                        label={t('tasks.photoRequired')}
                        className={styles.isRequired}
                        disabled={loading}
                    />
                </div>
            </div>
        </div>
    )
};

export default TaskCreateDialog;