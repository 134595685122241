import React, { FC, useEffect, useState } from 'react';
import styles from './Settings.module.scss';
import { Layout } from '@components';
import { useTranslation } from 'react-i18next';
import { useURLFilters } from '@tools/hooks/useURLFilters';
import SettingsFilters from './SettingsFilters/SettingsFilters';
import { SettingsData } from './SettingsData/SettingsData';
import { SettingsHeader } from './SettingsHeader/SettingsHeader';
import { SettingsRolesData } from './SettingsRolesData/SettingsRolesData';

export const Settings: FC = () => {
    const { t } = useTranslation('translation');
    const { setURLFiltersFromDefaultValues } = useURLFilters();

    const project_id = sessionStorage.getItem('project_id');

    const [defaultValues, setDefaultValues] = useState({});
    useEffect(() => {
        let defaultValuesInit = {
            project_id: project_id !== 'undefined' ? project_id : '',
            switchType: 'ROLES',
        };

        const resultFilterValues = setURLFiltersFromDefaultValues(defaultValuesInit);

        setDefaultValues(resultFilterValues);
    }, []);

    const getFilters = (values) => {
        const { project_id, kind, role, roleMultiple, name } = values || {};
        let res = {};
        if (project_id) res = { ...res, project_id };
        if (kind) res = { ...res, kind };
        if (role) res = { ...res, role };
        if (roleMultiple) res = { ...res, roleMultiple };
        if (name) res = { ...res, name };
        return res;
    };

    return Object.keys(defaultValues)?.length > 0 ? (
        <Layout
            className={styles.layoutSettings}
            title={t('common.settings')}
            renderHeader={(props) => <SettingsHeader {...props} defaultValues={defaultValues} />}
            renderData={(props) => {
                const { switchType } = props?.form?.getValues() || {};
                if (switchType === 'USERS') {
                    return < SettingsData {...props} setDefaultValues={setDefaultValues} />
                } else return <SettingsRolesData {...props} setDefaultValues={setDefaultValues} />
            }}
            renderFilters={SettingsFilters}
            defaultValues={defaultValues}
            getFilters={getFilters}
        />
    ) : (
        <></>
    );
};