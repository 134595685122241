import React, { useEffect, useState } from 'react';
import styles from './BarChart.module.scss'
import { getImportProcessesProgress } from '@apiFeature/importProcesses';
import { ImportProgressResponse } from '@apiFeature/importProcesses/types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const BarChart: React.FC<{ importProcessId?: string }> = ({ importProcessId }) => {
    const [progress, setProgress] = useState<ImportProgressResponse | null>(null);
    const [tooltipContent, setTooltipContent] = useState<string | null>(null);
    const { t } = useTranslation('translation');
    useEffect(() => {
        if (importProcessId) {
            getImportProcessesProgress(importProcessId).then(res => {
                setProgress(res);
            })
        }
    }, [importProcessId]);

    if (!progress) {
        return '-'
    };
    const {
        total_row_count: total,
        successful_row_count,
        error_row_count,
        in_progress_row_count
    } = progress;

    const successfulPercent = (successful_row_count / total) * 100;
    const inProgressPercent = (in_progress_row_count / total) * 100;
    const errorPercent = (error_row_count / total) * 100;
    const shouldDisplayNumber = (width: number) => width > 30;

    const handleSectionHover = (sectionName: string, count: number) => {
        setTooltipContent(`${sectionName}: ${count}`);
    };

    const handleSectionLeave = () => {
        setTooltipContent(null);
    };

    const defineLeftSectionName = (success, inProgress, error) => {
        if (success) {
            return {
                label: t('statuses.done'),
                value: success
            }
        };
        if (!success && inProgress) {
            return {
                label: t('statuses.inProgress'),
                value: inProgress
            }
        };
        if (!success && !inProgress && error) {
            return {
                label: t('statuses.problemReported'),
                value: error
            }
        };
    };

    const defineRightSectionName = (success, inProgress, error) => {
        if (error) {
            return {
                label: t('statuses.problemReported'),
                value: error
            }
        };
        if (!error && inProgress) {
            return {
                label: t('statuses.inProgress'),
                value: inProgress
            }
        };
        if (!error && !inProgress && success) {
            return {
                label: t('statuses.done'),
                value: success
            }
        };
    };

    const nullSection = {
        label: '',
        value: ''
    }

    const leftSectionName = defineLeftSectionName(successful_row_count, in_progress_row_count, error_row_count) || nullSection;
    const rightSectionName = defineRightSectionName(successful_row_count, in_progress_row_count, error_row_count) || nullSection;

    return (
        <div className={styles.barWrapper}>
            <div className={styles.totalCount}>
                {Boolean(total) && total}
            </div>
            {tooltipContent && <div className={styles.tooltip}>{tooltipContent}</div>}
            <div className={styles.barChart}>
                <div className={classNames(styles.leftEnd, {
                    [styles.success]: successfulPercent,
                    [styles.inProgress]: !successfulPercent && inProgressPercent,
                    [styles.error]: !successfulPercent && !inProgressPercent && errorPercent,
                    [styles.hidden]: !successfulPercent && !inProgressPercent && !errorPercent,
                })}
                    onMouseEnter={() => handleSectionHover(leftSectionName.label, leftSectionName.value)}
                    onMouseLeave={handleSectionLeave}
                >

                </div>
                <div
                    className={classNames(styles.barSection, styles.success)}
                    style={{
                        width: `${successfulPercent}%`,
                    }}
                    onMouseEnter={() => handleSectionHover(t('statuses.done'), successful_row_count)}
                    onMouseLeave={handleSectionLeave}
                >
                    {shouldDisplayNumber(successfulPercent) && successful_row_count}
                </div>
                <div
                    className={classNames(styles.barSection, styles.inProgress)}
                    style={{
                        width: `${inProgressPercent}%`,
                    }}
                    onMouseEnter={() => handleSectionHover(t('statuses.inProgress'), in_progress_row_count)}
                    onMouseLeave={handleSectionLeave}
                >
                    {shouldDisplayNumber(inProgressPercent) && in_progress_row_count}

                </div>
                <div
                    className={classNames(styles.barSection, styles.error)}
                    style={{
                        width: `${errorPercent}%`,
                    }}
                    onMouseEnter={() => handleSectionHover(t('statuses.problemReported'), error_row_count)}
                    onMouseLeave={handleSectionLeave}
                >
                    {shouldDisplayNumber(errorPercent) && error_row_count}
                </div>
                <div className={classNames(styles.rightEnd, {
                    [styles.success]: successfulPercent && !inProgressPercent && !errorPercent,
                    [styles.inProgress]: !errorPercent && inProgressPercent,
                    [styles.error]: errorPercent,
                    [styles.hidden]: !successfulPercent && !inProgressPercent && !errorPercent,
                })}
                    onMouseEnter={() => handleSectionHover(rightSectionName.label, rightSectionName.value)}
                    onMouseLeave={handleSectionLeave}
                >

                </div>
            </div>
        </div>
    );
};

export default BarChart;
