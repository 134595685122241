export interface FileArguments {
    callback: (arg: any) => void;
    accept?: string;
    multiple?: string;
}

export const getFile = ({ callback, accept, multiple }: FileArguments): void => {
    const file: HTMLInputElement = document.createElement('input');
    file.setAttribute('type', 'file');

    if (accept) {
        file.setAttribute('accept', accept);
    }

    if (multiple) {
        file.setAttribute('multiple', multiple);
    }

    file.addEventListener('change', callback);

    file.click();
};
