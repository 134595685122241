import React from 'react';
import backArrowSvg from '@images/svg/arrow-back.svg';
import style from './style/EditInstanceLayoutHeader.module.scss';
import { NavLink } from 'react-router-dom';
import { TabsButton } from '../../EditInstanceLayout';

interface Props {
    title: string;
    tabsButtons?: Array<TabsButton>;
    onClickBackButton?: () => void;
}
export const EditInstanceLayoutHeader = ({ title, tabsButtons, onClickBackButton }: Props) => {
    return (
        <header className={style.wrapper}>
            <div className={style.titleWrapper}>
                <img
                    onClick={onClickBackButton}
                    src={backArrowSvg}
                    alt="back"
                    className={style.backButton}
                />
                <h1 className={style.title}>{title}</h1>
            </div>
            <div className={style.tabsWrapper}>
                {tabsButtons?.map((tab, index) => (
                    <NavLink
                        key={index}
                        to={tab.to}
                        end
                        className={`${style.navLink} ${tab.isActive ? style.activeNavLink : ''}`}
                    >
                        {tab.title}
                    </NavLink>
                ))}
            </div>
        </header>
    );
};
