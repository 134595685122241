import React, { FC, ReactNode } from 'react';
import styles from './List.module.scss';

interface IList {
    children: ReactNode;
}

const List: FC<IList> = ({ children }) => {
    return <div className={styles.list}>{children}</div>;
};

export default List;
