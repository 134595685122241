import React, { useCallback, useEffect } from 'react';
import styles from './TasksFilters.module.scss';
import { useTranslation } from 'react-i18next';
import { Select, TextField, RangePicker, Checkbox } from '@components';
import { getOptions } from '@tools/utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import FiltersSelectors from '@redux/filters/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import { FiltersThunks } from '@redux/filters/thunk';
import { taskStatusesOptions } from '@constants';
import { useRoles } from '@tools/hooks/useRoles';

const TasksFilters = ({ defaultValues, form, isTasksAuthorFilter }) => {
    const dispatch = useDispatch();

    const { projectsList, loading: loadingProjects } = useSelector(
        ProjectsSelectors.projectsSelectors
    );
    const projectsListId = projectsList?.[0]?.id;

    const {
        retails,
        retailsLoading,
        branches,
        branchesLoading,
        cities,
        citiesLoading,
        userProjects,
        userProjectsLoading: usersLoading,
    } = useSelector(FiltersSelectors.filtersState);

    const { isClient } = useRoles();

    const { t } = useTranslation('translation');

    const { reset, watch, setValue } = form;
    const currValues = watch();
    const {
        project_id: pr_id,
        project_retail_ids,
        branch_ids,
        city_ids,
        execution_status,
        deadline,
        created,
        fact_date,
        user_ids,
        author_ids,
    } = currValues;
    const project_id = pr_id || projectsListId;

    const setActualValue = (curData, data, name) => {
        const value = data?.filter((e) => curData?.includes(e.id)).map((e) => e.id);
        if (curData && curData?.join() !== value?.join()) {
            setValue(name, value.length > 0 ? value : null);
        }
    };

    const getFiltersProjects = useCallback(() => {
        dispatch(FiltersThunks.getRetailsList({ project_id })).then((result) => {
            if (FiltersThunks.getRetailsList.fulfilled.match(result)) {
                const retails = result?.payload || [];
                if (project_retail_ids?.length > 0)
                    setActualValue(project_retail_ids, retails, 'project_retail_ids');
            }
        });
        dispatch(FiltersThunks.getBranchesList({ project_id })).then((result) => {
            if (FiltersThunks.getBranchesList.fulfilled.match(result)) {
                const branches = result?.payload || [];
                if (branch_ids?.length > 0) setActualValue(branch_ids, branches, 'branch_ids');
            }
        });
        dispatch(FiltersThunks.getCitiesList({ project_id })).then((result) => {
            if (FiltersThunks.getCitiesList.fulfilled.match(result)) {
                const cities = result?.payload || [];
                if (city_ids?.length > 0) setActualValue(city_ids, cities, 'city_ids');
            }
        });
        if ((isClient && isTasksAuthorFilter) || !isClient) {
            dispatch(FiltersThunks.getUserProjectExecutors({ project_id })).then((result) => {
                if (FiltersThunks.getUserProjectExecutors.fulfilled.match(result)) {
                    const users = result?.payload?.[project_id] || [];
                    if (!isClient && user_ids?.length > 0)
                        setActualValue(user_ids, users, 'user_ids');
                    if (author_ids?.length > 0) setActualValue(author_ids, users, 'author_ids');
                }
            });
        }
    }, [dispatch, project_id]);

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    useEffect(() => {
        getProjects();
    }, []);

    useEffect(() => {
        if (projectsList.length > 0 && !project_id) {
            setValue('project_id', projectsListId);
        }
    }, [projectsList]);

    useEffect(() => {
        if (project_id) getFiltersProjects();
    }, [project_id, getFiltersProjects]);

    const onChange = (name, e) => {
        let value = e?.value;
        if (e && Array.isArray(e)) {
            value = e.length > 0 ? e.map((item) => item.value) : null;
        }
        setValue(name, value);
    };

    const handleReset = () => {
        reset({
            ...defaultValues,
            project_id: projectsListId,
        });
    };

    const projectOptions = getOptions(projectsList, 'full_name');
    const retailsOptions = getOptions(retails);
    const branchesOptions = getOptions(branches);
    const citiesOptions = getOptions(cities);
    const statusOptions = taskStatusesOptions;
    const usersOptions = getOptions(userProjects?.[project_id], [
        'last_name',
        'first_name',
        'patronymic_name',
        'phone',
    ]);

    let filters = [
        {
            type: Select,
            name: 'project_id',
            label: t('common.project'),
            onChange,
            value: project_id
                ? projectOptions?.find((e) => e.value === project_id)
                : projectOptions?.[0],
            options: projectOptions,
            isLoading: loadingProjects,
        },
        {
            type: Select,
            name: 'project_retail_ids',
            label: t('common.retail'),
            placeholder: t('common.all'),
            onChange,
            value: project_retail_ids
                ? retailsOptions?.filter((e) => project_retail_ids.includes(e.value))
                : null,
            options: retailsOptions,
            isLoading: retailsLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'branch_ids',
            label: t('feed.branch'),
            placeholder: t('common.all'),
            onChange,
            value: branch_ids ? branchesOptions?.filter((e) => branch_ids.includes(e.value)) : null,
            options: branchesOptions,
            isLoading: branchesLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'city_ids',
            label: t('common.city'),
            placeholder: t('common.all'),
            onChange,
            value: city_ids ? citiesOptions?.filter((e) => city_ids.includes(e.value)) : null,
            options: citiesOptions,
            isLoading: citiesLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: TextField,
            name: 'external_code',
            label: t('common.outletCode'),
            placeholder: t('common.searchOutletCode'),
        },
        {
            type: TextField,
            name: 'description',
            label: t('common.description'),
            placeholder: t('common.searchDescription'),
        },
        {
            type: Select,
            name: 'execution_status',
            label: t('common.status'),
            placeholder: t('common.all'),
            onChange,
            value: statusOptions?.find((e) => e.value === execution_status),
            options: statusOptions,
            isClearable: true,
        },
        {
            type: RangePicker,
            name: 'deadline',
            label: t('tasks.deadline'),
            placeholder: t('common.select'),
            dateFormat: 'dd.MM.yyyy',
            valueStart: deadline?.deadline_lower,
            valueEnd: deadline?.deadline_upper,
        },
        {
            type: RangePicker,
            name: 'created',
            label: t('tasks.setDate'),
            placeholder: t('common.select'),
            dateFormat: 'dd.MM.yyyy',
            valueStart: created?.created_lower,
            valueEnd: created?.created_upper,
        },
        {
            type: RangePicker,
            name: 'fact_date',
            label: t('tasks.factDate'),
            placeholder: t('common.select'),
            dateFormat: 'dd.MM.yyyy',
            valueStart: fact_date?.start_time,
            valueEnd: fact_date?.end_time,
        },
        {
            type: Select,
            name: 'user_ids',
            label: t('common.user'),
            placeholder: t('common.all'),
            onChange,
            value: user_ids ? usersOptions?.filter((e) => user_ids.includes(e.value)) : null,
            options: usersOptions,
            isLoading: usersLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'author_ids',
            label: t('tasks.author'),
            placeholder: t('common.all'),
            onChange,
            value: author_ids ? usersOptions?.filter((e) => author_ids.includes(e.value)) : null,
            options: usersOptions,
            isLoading: usersLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Checkbox,
            name: 'is_photo_required',
            label: t('tasks.isRequiredPhoto'),
            labelPlacement: 'end',
            className: styles.isRequiredPhoto,
        },
        {
            type: Checkbox,
            name: 'is_required',
            label: t('tasks.isRequiredTask'),
            labelPlacement: 'end',
        },
    ];

    if (isClient && !isTasksAuthorFilter) {
        filters = filters.filter((e) => e.name !== 'user_ids' && e.name !== 'author_ids');
    } else if (isClient && isTasksAuthorFilter) {
        filters = filters.filter((e) => e.name !== 'user_ids');
    }

    return { handleReset, filters };
};

export default TasksFilters;
