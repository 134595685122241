import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getProjectOutletsList } from '@apiFeature/projectOutlets';
import { debounce } from '@tools/utils/functions';
import { useSnackbar } from 'notistack';

const outletsLimit = 10;

export function useOutlets(project_id, mode = 1, prevProjectIdRef = null, address = null, searchFilter = 'external_code') {
    const { t } = useTranslation('translation');

    const { enqueueSnackbar } = useSnackbar();

    const [name, setName] = useState();
    const [outlets, setOutlets] = useState({});
    const searchOutletName = useRef('');
    const isInitialLoad = useRef(true);
    const [outletsLoading, setOutletsLoading] = useState(false);
    const {
        items: projectOutlets = [],
        total: outletsTotal,
        offset: outletsOffset,
    } = outlets || {};

    const moreOutlets = () => {
        const offset = outletsLimit + outletsOffset;
        if (offset < outletsTotal) {
            getProjectOutletsListData({
                project_id,
                [searchFilter]: name,
                ...(address && { address }),
                pagination: { limit: outletsLimit, offset },
            });
        }
    };

    const findOutlets = (name) => {
        getProjectOutletsListData({
            project_id,
            [searchFilter]: name,
            ...(address && { address }),
            pagination: { limit: 10, offset: 0 },
        });
    };

    const handleInputChangeOutlets = debounce((name, mode) => {
        const value = typeof name === 'string' ? name : undefined;
        const { action } = mode || {};

        if (value) {
            setName(value);
            findOutlets(value);
        } else if (action === 'clear' || action === 'input-change') {
            setName(undefined);
            findOutlets(undefined);
        }
    }, 1000);

    const onInputChangeOutlets = (name, mode) => {
        setOutletsLoading(true);
        handleInputChangeOutlets(name, mode);
    };

    const clearName = () => {
        setName(undefined);
        findOutlets(undefined);
        setOutletsLoading(false);
    };

    const getProjectOutletsListData = useCallback(
        async (data) => {
            setOutletsLoading(true);
            try {
                const project_outlets = (await getProjectOutletsList(data)) || {};
                const project_outlets_items = project_outlets?.items || [];

                if (project_outlets?.offset > 0) {
                    setOutlets({
                        ...project_outlets,
                        items: [...projectOutlets, ...project_outlets_items],
                    });
                } else {
                    setOutlets(project_outlets);
                }
            } catch (e) {
                enqueueSnackbar(`${t('messages.error')}: ${e?.message}`, {
                    variant: 'error',
                });
            }
            setOutletsLoading(false);
        },
        [project_id, projectOutlets]
    );

    const getFiltersProjects = useCallback(() => {
        getProjectOutletsListData({
            project_id,
            ...(address && { address }),
            pagination: { limit: outletsLimit, offset: 0 },
        });
    }, [project_id, getProjectOutletsListData, address]);

    useEffect(() => {
        if (mode === 2) {
            if (isInitialLoad.current) {
                isInitialLoad.current = false;
            } else if (project_id !== prevProjectIdRef?.current) {
                getFiltersProjects();
            }
        } else if (project_id) {
            getFiltersProjects();
        }
    }, [project_id, address]);

    return [projectOutlets, outletsLoading, onInputChangeOutlets, moreOutlets, searchOutletName, clearName];
}
