import React, { FC, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import ruLocale from 'date-fns/locale/ru';
import BasePicker from 'react-datepicker';
import { FormHelperText } from '@mui/material';
import { Button } from 'src/view/components/ui/Button/Button';
import { DatePickerHeader } from 'src/view/components/ui/form/date/DatePickerHeader';
import { ReactComponent as ArrowIcon } from '@images/svg/chevron-down.svg';
import { useTranslation } from 'react-i18next';
import 'moment/locale/ru';
import i18n from '../../i18n';
import Label from '../Label/Label';
import 'react-datepicker/dist/react-datepicker.min.css';
import styles from './DatePicker.module.scss';

export interface IDatePicker {
    label?: string;
    id?: string;
    name?: string;
    value?: string;
    className?: string;
    placeholder?: string;
    dateFormat?: string;
    disabled?: boolean;
    minDate?: Date;
    error?: {
        message: string;
    };
    onChange: (value?: string | null) => void;
    onBlur?: () => void;
    showTimeSelect?: boolean;
}

const DatePicker: FC<IDatePicker> = ({
    label,
    id,
    name,
    value,
    placeholder = '',
    className,
    dateFormat,
    disabled,
    onBlur,
    onChange,
    minDate,
    error,
    showTimeSelect,
    ...props
}) => {
    const { t } = useTranslation('translation');
    const currentLocale = i18n.language === 'ru' ? ruLocale : i18n.language;

    const [valueString, setValueString] = useState<string | undefined | null>(value);
    const { valueDate } = useMemo(
        () => ({
            valueDate: (valueString && new Date(valueString)) || null,
        }),
        [valueString]
    );

    useEffect(() => {
        if (value && value !== valueString) {
            setValueString(value);
        } else if (value === null) {
            setValueString(value);
        }
    }, [value]);

    const [showYearPicker, setShowYearPicker] = useState(false);
    const [open, setOpen] = useState(false);

    const openCalendar = () => {
        setOpen(true);
    };

    const handleClickOutside = () => {
        setOpen(false);
        if (value && value !== valueString) {
            setValueString(value);
        } else if (value === null) {
            setValueString(value);
        }
        handleSubmit();
    };

    const handleYearClick = () => {
        setShowYearPicker(true);
    };

    const fixTimezoneOffset = (date: Date): Date | string => {
        if (!date) return '';

        return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    };

    const handleChange = (date: Date | null) => {
        if (!open) {
            setOpen(true)
        }
        if (showYearPicker) {
            setShowYearPicker(false);
        }
        setValueString(date?.toISOString());
    };

    const handleSubmit = () => {
        onChange(valueString);
        setOpen(false);
    };

    return (
        <>
            {label && <Label htmlFor={name}>{label}</Label>}
            <div
                ref={(el) => {
                    const monthLabel = el?.querySelector('.react-datepicker__current-month');
                    if (monthLabel) {
                        monthLabel.addEventListener('click', () => {
                            setShowYearPicker(true);
                        });
                    }
                }}
                className={classNames(
                    styles.datePicker,
                    'date-picker-wrapper',
                    { 'date-picker--open': open, [styles.error]: error },
                    className
                )}
            >
                <BasePicker
                    id={id || name}
                    dateFormat={dateFormat}
                    disabled={disabled}
                    locale={currentLocale}
                    open={open}
                    placeholderText={placeholder}
                    renderCustomHeader={(props) => (
                        <DatePickerHeader
                            {...props}
                            yearPicker={showYearPicker}
                            onYearClick={handleYearClick}
                        />
                    )}
                    selected={
                        !showTimeSelect
                            ? valueDate === null
                                ? valueDate
                                : fixTimezoneOffset(valueDate)
                            : valueDate
                    }
                    showYearPicker={showYearPicker}
                    onBlur={onBlur}
                    onCalendarOpen={openCalendar}
                    onChange={handleChange}
                    onClickOutside={handleClickOutside}
                    minDate={minDate}
                    popperProps={{ strategy: 'fixed' }}
                    showTimeSelect={showTimeSelect}
                    {...props}
                >
                    <div className="text-center">
                        <Button
                            className="date-picker__submit"
                            type="default"
                            onClick={handleSubmit}
                        >
                            {t('common.submit')}
                        </Button>
                    </div>
                </BasePicker>
                <ArrowIcon
                    className={classNames('date-picker-icon transition-transform', {
                        'rotate-180': open,
                        'aligner': error,
                    })}
                />
                {error && <FormHelperText error>{error.message}</FormHelperText>}
            </div>
        </>
    );
};

export default DatePicker;
