import React, { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { PrivacyPolicy } from '../../pages/PrivacyPolicy/PrivacyPolicy';
import './PublicLayout.style.scss';

export const PublicLayout: React.FC<PropsWithChildren> = ({ children }) => {
    const location = useLocation();

    if (location.pathname === '/policies') {
        return <PrivacyPolicy />;
    }

    return (
        <main className='authorization-page'>
            <div className='authorization-page__form-column'>
                {children}
            </div>
        </main>
    );
};
