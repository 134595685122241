import { createAsyncThunk } from "@reduxjs/toolkit";
import { importFile, importFileProcessesList, importRowProcessesList } from "../../apiFeature/importProcesses";
import { ImportFileProcessesRequest, ImportFileRequest, ImportRowProcessesRequest } from "../../apiFeature/importProcesses/types";
import { ErrorApi } from "../../apiFeature/types";
import { reduxStoreName } from "./types";


export module ImportProcessesThunks {

    export const fetchImportProcesses = createAsyncThunk<any, ImportFileProcessesRequest, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/fetchImportProcesses`,
        async (arg, { rejectWithValue }) => {
            try {
                return await importFileProcessesList(arg)
            } catch (e) {
                return rejectWithValue(e)
            }
        },
    );

    export const importFileThunk = createAsyncThunk<any, ImportFileRequest, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/importFileThunk`,
        async (arg, { rejectWithValue }) => {
            try {
                return await importFile(arg)
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );

    export const fetchImportRowProcesses = createAsyncThunk<any, ImportRowProcessesRequest, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/fetchImportRowProcesses`,
        async (arg, { rejectWithValue }) => {
            try {
                return await importRowProcessesList(arg)
            } catch (e) {
                return rejectWithValue(e)
            }
        },
    );
}
