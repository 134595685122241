import React from 'react';

function ToothIcon({ color = '#414152' }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.3247 2.53816L16.3252 2.53881C17.4951 4.04099 16.9351 5.75079 16.2186 7.68558L16.2185 7.68592L16.2076 7.71544L16.2068 7.71749L16.2015 7.73169C16.0589 8.11464 15.9064 8.5242 15.7908 8.90801C15.6766 9.28704 15.5726 9.72121 15.5726 10.1399C15.5726 11.0381 15.5168 12.9 15.1349 14.5103C14.9425 15.3217 14.6863 15.9839 14.3752 16.4216C14.0819 16.8342 13.8085 16.9655 13.5164 16.9655C12.9321 16.9655 12.5963 16.716 12.3095 16.2312C11.9788 15.672 11.7904 14.9118 11.5882 14.0555L11.5877 14.0534C11.3687 13.1348 11.1497 12.333 10.8281 11.7615C10.6572 11.4577 10.4263 11.1598 10.0964 10.9424C9.75377 10.7166 9.37747 10.6258 9.00103 10.6258C7.98377 10.6258 7.36783 11.3018 7.00377 11.9597C6.65223 12.595 6.40176 13.4397 6.16844 14.2738L6.16785 14.2759C5.93314 15.1221 5.72508 15.8468 5.41526 16.372C5.13562 16.846 4.87396 17 4.519 17C4.3083 17 4.07889 16.9096 3.80721 16.5644C3.5203 16.1998 3.25796 15.6302 3.04297 14.8879C2.61571 13.4126 2.45809 11.5516 2.45809 10.1436C2.45809 9.71007 2.3518 9.26701 2.23256 8.87758C2.11269 8.48611 1.95461 8.07209 1.80732 7.68636L1.80079 7.66924C1.80076 7.66919 1.80074 7.66913 1.80072 7.66908C1.80071 7.66903 1.80069 7.66899 1.80067 7.66895C1.08346 5.78871 0.505403 4.10013 1.63983 2.52296L1.64069 2.52177C2.45948 1.37987 3.22177 1 4.08379 1C4.71178 1 5.37061 1.20031 6.18327 1.45835C6.2025 1.4645 6.22185 1.4707 6.24133 1.47695C7.00104 1.72038 7.94835 2.02392 8.94234 2.02392C9.93135 2.02392 10.8311 1.74167 11.6121 1.49664C11.6314 1.49059 11.6507 1.48456 11.6698 1.47856L11.6706 1.47832L11.696 1.47033L11.6969 1.47003C12.4917 1.21933 13.1397 1.02308 13.7796 1.02308C14.6496 1.02308 15.4443 1.40584 16.3247 2.53816Z" stroke={color} stroke-width="2" />
        </svg>

    );
}

export default ToothIcon;