import { clientPassword } from '../ClientPassword';

export const fetchTerritoriesList = async (request) => {
    const res = await clientPassword.post('/territories/list/', request);
    return res?.territories;
};

export const fetchTerritoryList = async (arg) => {
    const res = await clientPassword.get(`/territories/?project_id=${arg?.project_id}`);
    return res?.territories;
};
