import { ImportFileProcessesItem, ImportFileProcessesResponse, ImportFileKindType, ImportRowProcessesResponse } from '@apiFeature/importProcesses/types';
import { FilterValue } from "@apiFeature/types";

export const reduxStoreName = 'import';

export interface ImportProcessesState {
    loadingProcesses?: boolean;
    loadingImport?: boolean;
    errorImport?: boolean;
    errorImportProcessList?: boolean;
    errorMessage?: string;
    importList?: ImportFileProcessesItem[] | null;
    status?: FilterValue | null | undefined;
    kind?: ImportFileKindType | null | undefined;
    project_id?: string | null | undefined;
    file?: File | null | undefined;
    import_file_process?: ImportFileProcessesResponse | null;
    import_row_process?: ImportRowProcessesResponse | null;
    lineNumberFilter?: string;
    total?: number;
    projectInModal?: string | null | undefined;
    kindInModal?: string | null | undefined;
    totalRows?: number;
}
