import { createAction } from "@reduxjs/toolkit"
import { FilterValue } from "../../apiFeature/types";
import { reduxStoreName } from "./types"

module ImportProcessActions {
    export const resetAction = createAction(`${reduxStoreName}/resetAction`);

    export const setProjectId = createAction<FilterValue | null | undefined>(`${reduxStoreName}/setProjectId`);

    export const setKind = createAction<FilterValue | null>(`${reduxStoreName}/setKind`);

    export const selectFile = createAction<File | null>(`${reduxStoreName}/selectFile`);

    export const setStatus = createAction<FilterValue | null>(`${reduxStoreName}/setStatus`);

    export const setLineNumber = createAction<any>(`${reduxStoreName}/setLineNumber`);

    export const setProjectinModal = createAction<FilterValue | null | undefined>(`${reduxStoreName}/setProjectinModal`);

    export const setKindinModal = createAction<FilterValue | null>(`${reduxStoreName}/setKindinModal`);


}
export default ImportProcessActions