/* eslint-disable */
import React, {
    ReactChild,
    ReactElement,
    ReactFragment,
    ReactPortal,
    useEffect,
    useState,
} from 'react';

import { Sort, TitleSort } from './components/Sort/Sort';

import {
    CheckboxField,
    FieldsItem,
    FieldTypes,
    RequestSelectField,
    SelectField,
    TextInputField,
} from '../Fields/Fields.type';
import { ButtonFilter, Filter } from '../ui/Filter/Filter';
import { Loader } from '../ui/Loader/Loader';
import { Search } from '../ui/Search/Search';
import { useDebounce } from '../../../tools/hooks/useDebounce';
import moment from 'moment';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IconFilter from '../../../assets/images/svg/filter-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import AppSelector from '../../../redux/app/selector';
import AppAction from '../../../redux/app/action';
import { Button } from '@components';
import { declOfNum } from '@tools/utils/string.utils';
import './Listing.style.scss';

type ReactNode = ReactChild | ReactFragment | ReactPortal | boolean | null | undefined;

interface SearchProps {
    key: string;
    placeholder?: string;
}

interface List<T> {
    list: T[];
    loader: boolean;
    error: {
        text: string;
        status: boolean;
    };
}

export interface FieldFilter {
    key: string;
    field: FieldsItem;
}

export interface ListingProps<T> {
    title: string;
    children?: ReactNode;
    fields: FieldFilter[];
    searchData?: SearchProps;
    searchInFilter: any;
    setSearchInFilter: any;
    defaultFilter: any;
    buttons?: ButtonFilter[];
    filter_length?: number;
    setData?: (data: any) => void;
    request: (
        filter: any
    ) => Promise<T[] | { items: T[]; total: number; offset: number; limit: number }>;
    emptyText?: string;
    isFeed?: boolean;
    sort?: {
        list: TitleSort[];
        className?: string;
    };
    showTotal?: boolean;
    showFilter?: boolean;
    shouldLoad?: boolean;
    defaultTotal?: number;
    isTable?: boolean;
    reload?: boolean;
    isSortAvailable?: boolean;
    setProjectId?: (project_id: string) => void;
}

const limitDefault = 10;

export function Listing<T>({
    title,
    children,
    fields,
    searchData,
    defaultFilter,
    request,
    buttons = [],
    filter_length,
    sort,
    emptyText,
    defaultTotal,
    setData,
    isFeed = false,
    showTotal = true,
    showFilter = true,
    shouldLoad = true,
    isSortAvailable = false,
    isTable,
    reload = false,
    setProjectId,
}: ListingProps<T>): ReactElement {
    const [search, setSearch] = useState<string>('');
    const [urlSearch, setUrlSearch] = useSearchParams();
    const location = useLocation();
    const isFilter = useSelector(AppSelector.filterSelector);
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const [list, setList] = useState<List<T>>({
        list: [],
        loader: false,
        error: {
            text: '',
            status: false,
        },
    });
    const getInitialFilter = () => {
        const urlFilter = Object.assign({}, defaultFilter);

        const projectFromLs = {
            value: sessionStorage.getItem('project_id'),
            label: sessionStorage.getItem('project_label'),
        };

        if (urlFilter.hasOwnProperty('project_id') && projectFromLs?.value) {
            urlFilter['project_id'] = projectFromLs;
        } else if (urlFilter.hasOwnProperty('project_ids') && projectFromLs?.value) {
            urlFilter['project_ids'] = projectFromLs;
        }

        Object.keys(defaultFilter).forEach((key) => {
            const label = urlSearch.get(key + 'label');
            const value = urlSearch.get(key + 'value');
            const dateFrom = urlSearch.get('date_from');
            const dateTo = urlSearch.get('date_to');

            const external_code = urlSearch.get('external_codevalue');
            const description = urlSearch.get('descriptionvalue');
            const number = urlSearch.get('numbervalue');
            const is_photo_required = urlSearch.get('is_photo_requiredvalue');
            const is_required = urlSearch.get('is_requiredvalue');

            if (external_code) {
                urlFilter['external_code'] = external_code;
            }
            if (description) {
                urlFilter['description'] = description;
            }
            if (is_photo_required) {
                urlFilter['is_photo_required'] = is_photo_required;
            }
            if (is_required) {
                urlFilter['is_required'] = is_required;
            }
            if (number) {
                urlFilter['number'] = number;
            }
            if (dateFrom && dateTo) {
                urlFilter['date_range'] = {
                    date_from: dateFrom,
                    date_to: dateTo,
                };
            }
            if (key === 'is_active') {
                urlFilter[key] = { value: true, label: t('statuses.active') }
            }
            if (defaultFilter[key] === '' && value) {
                urlFilter[key] = value;
            }
            if (label && value) {
                urlFilter[key] = { label, value };
            }
        });

        return urlFilter;
    };

    const [filter, setFilter] = useState(getInitialFilter());
    const [total, setTotal] = useState(defaultTotal ?? 0);
    const [pagination, setPagination] = useState({ limit: limitDefault, offset: 0 });
    const [showPagination, setShowPagination] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isFirstLoadRef = React.useRef(true);
    const loadingRef = React.useRef(false);
    const [isSort, setIsSort] = useState<string | number>(sort?.list[0]?.id || '');

    const { offset } = pagination;

    useEffect(() => {
        loadingRef.current = isLoading;
    }, [isLoading]);
    useEffect(() => {
        setFilter(getInitialFilter());
    }, [location]);

    const [isTouched, setTouched] = useState(false);
    const debouncedSearch = useDebounce(search, 500);

    const debounceDescription = useDebounce(JSON.stringify(filter?.description), 500);
    const debounceFilter = useDebounce(JSON.stringify(filter), 500);
    const debounceRetail = useDebounce(JSON.stringify(filter?.retail), 500);

    const changeFilter = (key: string, data: any) => {
        setFilter({ ...filter, [key]: data });

        if (key === 'is_photo_required' || key === 'is_required') {
            if (data) {
                urlSearch.set(key + 'value', data.toString());
            } else {
                urlSearch.delete(key + 'value');
            }
        }
        if (key === 'project_ids' || key === 'project_id') {
            sessionStorage.setItem('project_id', data?.value);
            sessionStorage.setItem('project_label', data?.label);
            if (setProjectId && data?.value) {
                if (Array.isArray(data?.value)) {
                    setProjectId(data?.value?.[0]);
                } else {
                    setProjectId(data?.value);
                }
            }
        }
        if (typeof data === 'string') {
            data ? urlSearch.set(key + 'value', data.toString()) : urlSearch.delete(key + 'value');
        } else {
            if (key === 'date_range_tasks') {
                return;
            } else {
                if (key === 'date_range') {
                    urlSearch.set('date_from', data.date_from);
                    urlSearch.set('date_to', data.date_to);
                } else {
                    if (typeof data !== 'boolean') {
                        urlSearch.set(key + 'value', data.value);
                        urlSearch.set(key + 'label', data.label);
                    }
                }
            }
        }

        setUrlSearch(urlSearch.toString(), { replace: false });
        setPagination({ limit: limitDefault, offset: 0 });
    };

    const debouncedSearchInfo = useDebounce(search, 500);
    const sendRequest = async () => {
        if (total && total <= offset) {
            return;
        }
        setIsLoading(true);
        setList({ ...list, loader: true, error: { status: false, text: '' } });

        const newFilter = {
            ...filter.date_range_tasks,
            ...filter.date_range,
            ...filter,
            [searchData?.key || 'address']: debouncedSearchInfo,
            pagination: pagination,
            user_ids: filter?.user_ids?.value ? [filter?.user_ids?.value] : null,
            manager_ids: filter?.manager_ids?.value ? [filter?.manager_ids?.value] : null,
        };

        if (filter?.ids?.value) {
            newFilter.ids = [filter?.ids?.value];
        }
        if (isSort === 1) {
            //окончание по убыванию desc
            newFilter.sorting = { end_time: 'desc' };
        }
        if (isSort === 2) {
            //окончание по возрастанию asc
            newFilter.sorting = { end_time: 'asc' };
        }
        if (isSort === 3) {
            //начало по возрастанию asc
            newFilter.sorting = { start_time: 'asc' };
        }
        if (isSort === 4) {
            //начало по убыванию desc
            newFilter.sorting = { start_time: 'desc' };
        }
        const keys: string[] = Object.keys(newFilter);

        keys.forEach((key) => {
            if (typeof newFilter[key] === 'string') {
                newFilter[key] = newFilter[key].trim();
            }
            if (newFilter[key]?.value) {
                newFilter[key] = newFilter[key].value;
            }
            if (newFilter[key]?.label === t('common.all')) {
                delete newFilter[key];
            }
            if (key === 'date_range') {
                delete newFilter[key];
            }
            if (key === 'date_range_tasks' && newFilter?.deadline_lower) {
                delete newFilter[key];
            }
            if (key === 'name' && !search) {
                delete newFilter[key];
            }
        });

        setTouched(true);
        request(newFilter)
            .then((data) => {
                if (isFeed && !Array.isArray(data)) {
                    setTotal(data?.total);

                    const dataList =
                        offset === 0 ? [...data?.items] : [...list?.list, ...data?.items];
                    setList({ ...list, list: dataList, loader: false });
                    setData && setData(dataList);
                    setShowPagination(true);

                    return;
                }
                if (data && !data?.total) {
                    setTotal(data?.length);
                    setShowPagination(false);
                }

                setList({ ...list, list: [...data], loader: false });
            })
            .catch((err) => setList({ ...list, error: { status: true, text: err }, loader: false }))
            .finally(() => {
                setIsLoading(false);
                isFirstLoadRef.current = false;
            });
    };

    const resetFilter = () => {
        setFilter(defaultFilter);
        sessionStorage.removeItem('project_id');
        sessionStorage.setItem('project_label', t('common.all'));
        setTouched(false);
        setPagination({ limit: limitDefault, offset: 0 });
        setSearch('');
        setUrlSearch('');
    };

    const filterFields: FieldsItem[] = fields.map(({ field, key }) => {
        switch (field.type) {
            case FieldTypes.Select:
                return {
                    ...field,
                    value: filter[key],
                    onChange(value) {
                        changeFilter(key, value);
                    },
                } as SelectField;
            case FieldTypes.Checkbox:
                return {
                    ...field,
                    checked: filter[key],
                    onChange(checked) {
                        changeFilter(key, checked);
                    },
                } as CheckboxField;
            case FieldTypes.TextInput:
                return {
                    ...field,
                    value: filter[key],
                    onChange(e) {
                        changeFilter(key, e.target.value);
                    },
                } as TextInputField;
            case FieldTypes.RequestSelect:
                return {
                    ...field,
                    value: filter[key],
                    onChange(option) {
                        changeFilter(key, option);
                    },
                } as RequestSelectField<any>;

            case FieldTypes.DateRange:
                return {
                    ...field,
                    valueEnd: filter[key]?.date_to,
                    valueStart: filter[key]?.date_from,
                    dateFormat: 'dd.MM.yy',
                    onChange(start, end) {
                        changeFilter(key, {
                            date_from: moment(start).format('YYYY-MM-DD'),
                            date_to: moment(end).format('YYYY-MM-DD'),
                        });
                    },
                };
            default:
                return field;
        }
    });

    const getContent = () => {
        if (list.error.status) {
            return <p className="listing__error">{t('messages.error')}!</p>;
        }
        if (list.list.length === 0 && !list.loader) {
            return (
                <div className="listing__empty">
                    <h1>{emptyText || t('messages.notFound')}</h1>
                </div>
            );
        }

        return (
            <div id="listing-children">
                {children}
                {isLoading && (
                    <Loader
                        className={`listing__loader ${list?.list?.length !== total ? 'listing__loader-data' : ''
                            }`}
                    />
                )}
            </div>
        );
    };

    const handleToggleFilter = () => {
        dispatch(AppAction.filterToggle());
    };

    useEffect(() => {
        if (isLoading || !shouldLoad) return;
        sendRequest();
    }, [
        debouncedSearch,
        offset,
        debounceFilter,
        debounceRetail,
        shouldLoad,
        reload,
        debounceDescription,
        isSort,
    ]);

    const setMore = () => {
        setPagination({ limit: limitDefault, offset: offset + limitDefault });
    };

    const shown = offset + limitDefault;
    const balanceTotal = total - shown;
    const balance = balanceTotal > limitDefault ? limitDefault : balanceTotal;
    const shownTotal = shown > total ? total : shown;

    const balanceText = declOfNum(balance, [t('feed.item'), t('feed.itemsSome'), t('feed.items')]);

    return (
        <div className="listing">
            <div className="listing__wrapper">
                <div className={isFilter ? 'listing__content' : 'listing__content listing__full'}>
                    <div className="listing__title">
                        <div>
                            <h1>{title}</h1>
                            {showTotal && (
                                <div className="listing__sum">
                                    <span className="listing__sumItem">
                                        {t('common.total')}: <b>{total}</b>
                                    </span>
                                    <span className="listing__sumItem">
                                        {t('common.shown')}:{' '}
                                        <b>{!showPagination ? total : shownTotal}</b>
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="listing__sort sort_wrapper">
                            {sort && isSortAvailable && (
                                <Sort
                                    className="listing__sort"
                                    setValue={setIsSort}
                                    value={isSort}
                                    {...sort}
                                />
                            )}
                        </div>
                        <div className="filter_btns">
                            {buttons?.length > 0 &&
                                buttons?.map((btn, idx) => (
                                    <div
                                        className="filter-header-btn"
                                        key={idx}
                                        onClick={btn.onClick}
                                    >
                                        {btn.text}
                                    </div>
                                ))}
                            <div onClick={handleToggleFilter} className="toggleFilter">
                                <img src={IconFilter} alt="toggle filter btn" />
                            </div>
                        </div>
                    </div>
                    {searchData && (
                        <Search
                            placeholder={searchData.placeholder || t('common.searchName')}
                            value={search}
                            onChange={setSearch}
                            onClick={() => changeFilter(searchData.key, search)}
                            onEnter={() => changeFilter(searchData.key, search)}
                        />
                    )}
                    {getContent()}
                    {showPagination && total && total > shown ? (
                        <div className="listing-more">
                            <Button
                                onClick={setMore}
                                className="listing-more-btn"
                                variant="outlined"
                                color="secondary"
                            >
                                {`${t('buttons.showMore')} ${balance} ${balanceText}`}
                            </Button>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                {showFilter && (
                    <Filter
                        autoTouched
                        // buttons={buttons}
                        fields={filterFields}
                        isTouched={search.trim().length > 0 || isTouched}
                        values={filter && Object.values(filter)}
                        onReset={resetFilter}
                        isFilter={isFilter}
                        closeFilter={handleToggleFilter}
                    />
                )}
            </div>
        </div>
    );
}
