import { SettingsAllResponseType, SettingsAllUserItem, SettingsOption } from '@apiFeature/settings/types';

export const reduxStoreName = 'settings';

export interface SettingsState {
    loading?: boolean;
    errMessage?: string;
    settingsAll?: SettingsAllResponseType | null;
    settingsOptions?: { [settingId: string]: SettingsOption[] };
    settingsAllUser?: SettingsAllUserItem[] | null;
    settingsAllUserLoaded?: boolean;
    settingsAllLoaded?: boolean;
}
