import React from 'react';
import { Navigate } from 'react-router-dom';
import { Book } from '../../Book';

interface PrivateRouteProps {
    condition: boolean;
    children: React.ReactNode;
}

export const ProtectedRoute = ({ condition = false, children }: PrivateRouteProps): JSX.Element => {
    if (!condition) {
        return <Navigate to={Book.visit_feed} replace />;
    }
    return <>{children}</>;
};
