import { createAsyncThunk } from "@reduxjs/toolkit";
import { reduxStoreName } from "./types";
import { ErrorApi } from "@apiFeature/types";
import { authLogin, loginBySMSCode } from "@apiFeature/auth";
import { getUserById } from '@apiFeature/user';

export module AuthThunks {
    export const loginRequest = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/loginRequest`,
        async (arg, { rejectWithValue }) => {
            try {
                return await authLogin(arg);
            } catch (e) {
                return rejectWithValue(e)
            }
        },
    );

    export const loginBySMS = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/loginBySMS`,
        async (arg, { rejectWithValue }) => {
            try {
                return await loginBySMSCode(arg);
            } catch (e) {
                return rejectWithValue(e)
            }
        },
    );

    export const fetchUserInfo = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/fetchUserInfo`,
        async (arg, { rejectWithValue }) => {
            try {
                return await getUserById(arg);
            } catch (e) {
                return rejectWithValue(e)
            }
        },
    );
}