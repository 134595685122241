import React, { Fragment, ReactElement, useState } from 'react';
import style from './style/Table.module.scss';

type Data = Record<string, any>;

interface TableProps {
    head: {
        render: (data: Data, index: number) => ReactElement | null;
        headComponent: () => ReactElement | null;
        className?: string | undefined;
    }[];
    data: Data[];
    tableClassName?: string;
}

export const Table = ({ head, data, tableClassName = '' }: TableProps) => {
    const [selectedRow, setSelectedRow] = useState(null);
    const handleSelectRow = (id) => {
        setSelectedRow(id)
    }
    return (
        <div className={style.wrapper + ' ' + tableClassName}>
            <table>
                <thead>
                    <tr className="theadTr">
                        {head.map((headItem, headIndex) => (
                            <Fragment key={headIndex}>{headItem.headComponent()}</Fragment>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {data?.map((item: any, index: number) => (
                        <Fragment key={item.id || index}>
                            <tr className={selectedRow !== item.id ? item.className : item.className + ' ' + style.selected} onClick={() => handleSelectRow(item.id)}>
                                {head.map((headItem, headIndex) => (
                                    <td
                                        className={headItem.className}
                                        key={`${item.id || index}-${headIndex}`}
                                    >
                                        {headItem.render(item, index)}
                                    </td>
                                ))}
                            </tr>
                        </Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
