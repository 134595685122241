import { createSlice } from '@reduxjs/toolkit';
import { reduxStoreName as name, State } from './types';
import { adapter, detailedAdapter } from './adapter';
import { AssignmentThunks } from './thunk';
import AssignmentActions from './actions';

const initialState: State = {
    loading: false,
    init: false,
    error: false,
    errorMessage: '',
    importProcess: null,
    filterKind: undefined,
    questionnairesPaginatedData: {
        items: [],
        limit: 10,
        offset: 0,
        total: 0,
    },
};

const slice = createSlice({
    name,
    initialState: adapter.getInitialState({
        ...initialState,
        detailedList: detailedAdapter.getInitialState(),
    }),
    reducers: {
        reset() {
            return adapter.getInitialState({
                ...initialState,
                detailedList: detailedAdapter.getInitialState(),
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(AssignmentThunks.upload.pending, (state) => {
                state.loading = true;
            })
            .addCase(AssignmentThunks.upload.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.init = true;
                state.error = false;
                state.errorMessage = undefined;
                state.importProcess = payload?.import_process;
                if (Object.keys(state.importProcess?.error_data).length) {
                    if (Object.keys(state.importProcess?.error_data?.other).length) {
                        state.error = state.importProcess?.error_data?.other;
                    }
                    if (state.importProcess?.error_data?.common.length) {
                        state.errorMessage = state.importProcess?.error_data?.common[0];
                    }
                }
            })
            .addCase(AssignmentThunks.upload.rejected, (state, { payload }) => {
                state.error = false;
                state.errorMessage = payload?.message ?? 'Неизвестная ошибка';
            })
            .addCase(AssignmentActions.resetAction, (state) => {
                state.error = false;
                state.errorMessage = undefined;
                state.importProcess = null;
            });
        builder
            .addCase(AssignmentThunks.getQuestionnairesList.pending, (state) => {
                state.listLoading = true;
            })
            .addCase(AssignmentThunks.getQuestionnairesList.fulfilled, (state, { payload }) => {
                state.listLoading = false;
                state.listError = false;
                state.listErrMsg = undefined;
                state.questionnairesPaginatedData = {
                    items:
                        payload?.offset === 0
                            ? [...payload?.items]
                            : [...state.questionnairesPaginatedData.items, ...payload?.items],
                    total: payload?.total,
                    offset: payload?.offset,
                    limit: payload?.limit,
                };
                adapter.removeAll(state);
                adapter.addOne(state, payload);
                // adapter.addMany(state, payload?.questionnaires);
                //adapter.addMany(state, payload?.items); with pagination
                adapter.addMany(state, payload?.items);
            })
            .addCase(AssignmentThunks.getQuestionnairesList.rejected, (state, { payload }) => {
                state.listLoading = false;
                state.listError = true;
                state.listErrMsg = payload?.message ?? 'неизвестная ошибка';
            });
        builder
            .addCase(AssignmentThunks.getQuestionnairesProjectsList.pending, (state) => {
                state.listLoading = true;
            })
            .addCase(
                AssignmentThunks.getQuestionnairesProjectsList.fulfilled,
                (state, { payload }) => {
                    state.listLoading = false;
                    state.listError = false;
                    state.listErrMsg = undefined;
                    state.questionnaires = payload?.questionnaires;
                }
            )
            .addCase(
                AssignmentThunks.getQuestionnairesProjectsList.rejected,
                (state, { payload }) => {
                    state.listLoading = false;
                    state.listError = true;
                    state.listErrMsg = payload?.message ?? 'неизвестная ошибка';
                }
            );
        builder
            .addCase(AssignmentThunks.getQuestionnairesDetailedList.pending, (state) => {
                state.detailedLoading = true;
            })
            .addCase(
                AssignmentThunks.getQuestionnairesDetailedList.fulfilled,
                (state, { payload }) => {
                    state.detailedErr = false;
                    state.detailedLoading = false;
                    state.detailedErrMsg = undefined;
                    detailedAdapter.removeAll(state.detailedList);
                    detailedAdapter.addMany(state.detailedList, payload?.questionnaires);
                }
            )
            .addCase(
                AssignmentThunks.getQuestionnairesDetailedList.rejected,
                (state, { payload }) => {
                    state.detailedLoading = false;
                    state.detailedErr = true;
                    state.detailedErrMsg = payload?.message ?? 'неизвестная ошибка';
                }
            );
        builder.addCase(AssignmentActions.setSearch, (state, { payload }) => {
            state.search = payload;
        });
        builder.addCase(AssignmentActions.setProjectFilter, (state, { payload }) => {
            state.filterProj = payload ?? undefined;
        });
        builder.addCase(AssignmentActions.setKindFilter, (state, { payload }) => {
            state.filterKind = payload ?? undefined;
        });

        builder.addCase(AssignmentActions.setStatusFilter, (state, { payload }) => {
            state.filterStatus = payload ?? undefined;
        });
        builder.addCase(AssignmentActions.resetFilters, (state) => {
            state.search = '';
            state.filterStatus = undefined;
            state.filterKind = '';
            state.filterProj = '';
        });
        builder
            .addCase(AssignmentThunks.updateQuestionnaire.pending, (state) => {
                state.updateLoading = true;
                state.isUpdateFailed = false;
            })
            .addCase(AssignmentThunks.updateQuestionnaire.fulfilled, (state, { payload }) => {
                state.updateLoading = false;
                state.isUpdateFailed = false;
            })
            .addCase(AssignmentThunks.updateQuestionnaire.rejected, (state, { payload }) => {
                state.updateLoading = false;
                state.isUpdateFailed = true;
                state.errorUpdateMsg = payload?.message ?? 'неизвестная ошибка';
            });
        builder.addCase(AssignmentActions.onEditQuestionnaire, (state, { payload }) => {
            state.currentQuestionnaire = payload;
        });
        builder
            .addCase(AssignmentThunks.updateQuestionnaireStep.pending, (state) => {
                state.onUpdateQuestionnaire = true;
                state.isErrUpdateQuestionnaire = false;
            })
            .addCase(AssignmentThunks.updateQuestionnaireStep.fulfilled, (state) => {
                state.onUpdateQuestionnaire = false;
                state.isErrUpdateQuestionnaire = false;
            })
            .addCase(AssignmentThunks.updateQuestionnaireStep.rejected, (state, { payload }) => {
                state.onUpdateQuestionnaire = false;
                state.isErrUpdateQuestionnaire = true;
                state.errMessageQuestionnaire = payload?.message ?? 'неизвестная ошибка';
            });
        builder
            .addCase(AssignmentThunks.createQuestionnaireStep.pending, (state) => {
                state.onUpdateQuestionnaire = true;
                state.isErrUpdateQuestionnaire = false;
            })
            .addCase(AssignmentThunks.createQuestionnaireStep.fulfilled, (state) => {
                state.onUpdateQuestionnaire = false;
                state.isErrUpdateQuestionnaire = false;
            })
            .addCase(AssignmentThunks.createQuestionnaireStep.rejected, (state, { payload }) => {
                state.onUpdateQuestionnaire = false;
                state.isErrUpdateQuestionnaire = true;
                state.errMessageQuestionnaire = payload?.message ?? 'неизвестная ошибка';
            });
        builder
            .addCase(AssignmentThunks.createQuestionnaireQuestion.pending, (state) => {
                state.onUpdateQuestionnaire = true;
                state.isErrUpdateQuestionnaire = false;
            })
            .addCase(AssignmentThunks.createQuestionnaireQuestion.fulfilled, (state) => {
                state.onUpdateQuestionnaire = false;
                state.isErrUpdateQuestionnaire = false;
            })
            .addCase(
                AssignmentThunks.createQuestionnaireQuestion.rejected,
                (state, { payload }) => {
                    state.onUpdateQuestionnaire = false;
                    state.isErrUpdateQuestionnaire = true;
                    state.errMessageQuestionnaire = payload?.message ?? 'неизвестная ошибка';
                }
            );
        builder
            .addCase(AssignmentThunks.updateQuestionnaireQuestion.pending, (state) => {
                state.onUpdateQuestionnaire = true;
                state.isErrUpdateQuestionnaire = false;
            })
            .addCase(AssignmentThunks.updateQuestionnaireQuestion.fulfilled, (state) => {
                state.onUpdateQuestionnaire = false;
                state.isErrUpdateQuestionnaire = false;
            })
            .addCase(
                AssignmentThunks.updateQuestionnaireQuestion.rejected,
                (state, { payload }) => {
                    state.onUpdateQuestionnaire = false;
                    state.isErrUpdateQuestionnaire = true;
                    state.errMessageQuestionnaire = payload?.message ?? 'неизвестная ошибка';
                }
            );
        builder
            .addCase(AssignmentThunks.updateQuestionnaireQuestionMany.pending, (state) => {
                state.onUpdateQuestionnaire = true;
                state.isErrUpdateQuestionnaire = false;
            })
            .addCase(AssignmentThunks.updateQuestionnaireQuestionMany.fulfilled, (state) => {
                state.onUpdateQuestionnaire = false;
                state.isErrUpdateQuestionnaire = false;
            })
            .addCase(
                AssignmentThunks.updateQuestionnaireQuestionMany.rejected,
                (state, { payload }) => {
                    state.onUpdateQuestionnaire = false;
                    state.isErrUpdateQuestionnaire = true;
                    state.errMessageQuestionnaire = payload?.message ?? 'неизвестная ошибка';
                }
            );
        builder
            .addCase(AssignmentThunks.updateQuestionnaires.pending, (state) => {
                state.onUpdateQuestionnaire = true;
                state.isErrUpdateQuestionnaire = false;
            })
            .addCase(AssignmentThunks.updateQuestionnaires.fulfilled, (state) => {
                state.onUpdateQuestionnaire = false;
                state.isErrUpdateQuestionnaire = false;
            })
            .addCase(AssignmentThunks.updateQuestionnaires.rejected, (state, { payload }) => {
                state.onUpdateQuestionnaire = false;
                state.isErrUpdateQuestionnaire = true;
                state.errMessageQuestionnaire = payload?.message ?? 'неизвестная ошибка';
            });
    },
});

export const reducer = slice.reducer;
