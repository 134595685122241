import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { adapter } from './adapter';

module CheckerSelectors {
  export const checkerSelectors = (state: RootState) => state.checker;
  export const checkerAdapterSelector = adapter.getSelectors<RootState>(checkerSelectors);

  export const data = checkerAdapterSelector.selectAll;

  export const orderedData = (arr) =>
    createSelector(checkerSelectors, (state) => {
      const result = [];
      const indexMap = {};

      arr.forEach((item, index) => {
        if (item.parent_id) {
          const parentIndex = indexMap[item.parent_id];
          if (parentIndex !== undefined) {
            result.splice(parentIndex + 1, 0, item);
          } else {
            result.push(item);
          }
        } else {
          result.push(item);
        }
        indexMap[item.id] = index;
      });

      return result;
    });

  export const getLinkedChecker = (parentId) =>
    createSelector(checkerSelectors, (state) => {
      if (!!parentId?.length && !!state?.submitResponse?.length) {
        return state?.submitResponse?.filter((it) => it?.id === parentId);
      }
    });

  export const submitData = createSelector(checkerSelectors, (state) => {
    const res = state?.checkerList?.reduce((acc, it) => {
      const current =
        !!state?.submitResponse?.length &&
        state?.submitResponse?.filter((res) => res?.check_list_id === it?.id);
      const newObj = { ...it };
      newObj.check_list_params = current;
      acc.push(newObj);
      return acc;
    }, []);

    return res;
  });

  export const answerSelector = (parentId, questId) =>
    createSelector(checkerSelectors, (state) => {
      return state?.resultResponse
        ?.filter((item) => item?.check_list_id === parentId)?.[0]
        ?.data?.result?.filter((it) => it?.id === questId);
    });

  export const fieldResult = (id) =>
    createSelector(checkerSelectors, (state) => {
      return state?.resultResponse?.filter((it) => it?.check_list_id === id);
    });

  export const orderedQuestion = createSelector(checkerSelectors, (state) => state.ordredCheckers);
}

export default CheckerSelectors;
