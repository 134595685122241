import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { Button } from 'src/view/components/ui/Button/Button';
import { Checkbox } from 'src/view/components/ui/form/Checkbox/Checkbox';
import { DatePicker } from 'src/view/components/ui/form/date/DatePicker';
import { MonthSelect } from 'src/view/components/ui/form/date/MonthSelect';
import { RangePicker } from 'src/view/components/ui/form/date/RangePicker';
import { AsyncSelect } from 'src/view/components/ui/form/Select/AsyncSelect';
import { Select } from 'src/view/components/ui/form/Select/Select';
import { TextInput } from 'src/view/components/ui/form/TextInput/TextInput';
import { TextAreaInput } from 'src/view/components/ui/TextAreaInput/TextAreaInput';
import { FormField, FormFieldType } from './ComplexForm.type';

interface ComplexFormFieldProps {
    field: FormField;
    disabled?: boolean;
    className?: string;
}

const ControlledField: React.FC<ComplexFormFieldProps> = ({ field, disabled }) => {
    const { register, watch, setValue } = useFormContext();

    const value = watch(field.name);

    useEffect(() => {
        register(field.name, { disabled });
    }, [register, field.name, disabled]);

    switch (field.type) {
        case FormFieldType.TextInput:
            return (
                <TextInput
                    className={field.className}
                    label={field.label}
                    placeholder={field.placeholder ?? field.label}
                    onChange={(e) => setValue(field?.name, e?.target?.value)}
                    value={value}
                />
            );
        case FormFieldType.Checkbox:
            return (
                <Checkbox
                    className={field.className}
                    label={field.label}
                    placeholder={field.placeholder ?? field.label}
                    onChange={(option) => setValue(field.name, option)}
                    checked={value}
                />
            );

        case FormFieldType.Select:
            return (
                <Select
                    className={field.className}
                    disabled={disabled}
                    label={field.label}
                    name={field.name}
                    options={field.options}
                    placeholder={field.placeholder ?? field.label}
                    value={value}
                    onChange={(option) => setValue(field.name, option)}
                />
            );

        case FormFieldType.AsyncSelect:
            return (
                <AsyncSelect
                    className={field.className}
                    disabled={disabled}
                    label={field.label}
                    name={field.name}
                    projectId={field.project_id}
                    optionsLoaderConfig={{
                        mapper: field.mapper,
                        request: field.request,
                        prependPlaceholder: field.prependPlaceholder,
                    }}
                    isMulty={field.isMulty}
                    placeholder={field.placeholder ?? field.label}
                    value={value}
                    onChange={(option) => setValue(field.name, option)}
                    isSearchable={field.isSearchable}
                    searchField={field.searchField}
                    projectId={field.projectId}
                    questionnaireKind={field.questionnaireKind}
                />
            );
        case FormFieldType.MonthSelect:
            return (
                <MonthSelect
                    className={field.className}
                    disabled={disabled}
                    label={field.label}
                    name={field.name}
                    placeholder={field.placeholder ?? field.label}
                    value={value}
                    onChange={(option) => setValue(field.name, option)}
                />
            );

        case FormFieldType.Date:
            return (
                <DatePicker
                    className={field.className}
                    dateFormat="dd-MM-yyyy"
                    disabled={disabled}
                    label={field.label}
                    placeholder={field.placeholder ?? field.label}
                    value={value}
                    onChange={(date) => setValue(field.name, date)}
                />
            );

        case FormFieldType.DateRange:
            return (
                <RangePicker
                    className={field.className}
                    dateFormat="dd.MM.yy"
                    disabled={disabled}
                    label={field.label}
                    placeholder={field.placeholder ?? field.label}
                    valueEnd={value?.end}
                    valueStart={value?.start}
                    onChange={(start, end) => setValue(field.name, { start, end })}
                />
            );

        default:
            return null;
    }
};

const UncontrolledField: React.FC<ComplexFormFieldProps> = ({ field, disabled }) => {
    const { register, reset } = useFormContext();

    switch (field.type) {
        case FormFieldType.TextAreaInput:
            return (
                <TextAreaInput
                    {...register(field.name, { disabled })}
                    className={field.className}
                    label={field.label}
                    placeholder={field.placeholder ?? field.label}
                />
            );
        case FormFieldType.Reset:
            return (
                <Button className={field.className} type="flat" onClick={() => reset()}>
                    {field.label}
                </Button>
            );
        case FormFieldType.Submit:
            return (
                <Button
                    className={field.className}
                    disabled={disabled}
                    htmlType="submit"
                    type="primary"
                >
                    {field.label}
                </Button>
            );

        default:
            return null;
    }
};

export const ComplexFormField: React.FC<ComplexFormFieldProps> = ({ field, disabled }) => {
    switch (field.type) {
        case FormFieldType.Checkbox:
        case FormFieldType.Select:
        case FormFieldType.AsyncSelect:
        case FormFieldType.MonthSelect:
        case FormFieldType.Date:
        case FormFieldType.DateRange:
        case FormFieldType.TextInput:
            return <ControlledField disabled={disabled} field={field} />;

        default:
            return <UncontrolledField disabled={disabled} field={field} />;
    }
};
