import React, { useEffect, useState } from 'react';
import styles from './VisitFeed.module.scss';
import { useTranslation } from 'react-i18next';
import { Layout } from '@components';
import VisitFeedHeader from './VisitFeedHeader/VisitFeedHeader';
import VisitFeedData from './VisitFeedData/VisitFeedData';
import VisitFeedFilters from './VisitFeedFilters/VisitFeedFilters';
import {
    defaultValuesData,
    getDefaultValues,
    getFiltersHiddenActive,
    getVisitFilters,
} from '../VisitFeed/utils/functions';
import { useRoles } from '@tools/hooks/useRoles';
import SettingsSelectors from '@redux/settings/selectors';
import FiltersSelectors from '@redux/filters/selectors';
import { useSelector } from 'react-redux';
import { useURLFilters } from '@tools/hooks/useURLFilters';

export const VisitFeed = () => {
    const { clientPermission } = useRoles();
    const { setURLFiltersFromDefaultValues } = useURLFilters();

    const { t } = useTranslation('translation');

    const getFilters = (values) => {
        const res = getVisitFilters(values, clientPermission);
        return res;
    };

    const [defaultValues, setDefaultValues] = useState({});

    const { settingsAll } = useSelector(SettingsSelectors.settingsState);
    const { filters: { visitFeed: visitFeedFilters = {} } = {} } = useSelector(
        FiltersSelectors.filtersState
    );
    const { settings } = settingsAll || {};
    let filtersHiddenActive = getFiltersHiddenActive(settings);
    filtersHiddenActive = filtersHiddenActive?.length > 0 ? filtersHiddenActive : ['none'];
    const hideAddress = filtersHiddenActive.includes('address');
    const renderSearch = hideAddress && clientPermission ? '' : () => ({});

    useEffect(() => {
        const defaultValues = getDefaultValues(visitFeedFilters, clientPermission);
        const resultFilterValues = setURLFiltersFromDefaultValues(defaultValues);
        setDefaultValues(resultFilterValues);
    }, []);

    return Object.keys(defaultValues)?.length > 0 ? (
        <Layout
            title={t('feed.title')}
            className={styles.visitFeed}
            renderHeader={(props) => <VisitFeedHeader {...props} />}
            renderSearch={renderSearch}
            renderData={(props) => (
                <VisitFeedData
                    {...props}
                    defaultValues={defaultValues}
                    clientPermission={clientPermission}
                    nameFilters="visitFeed"
                />
            )}
            renderFilters={(props) =>
                VisitFeedFilters({ ...props, defaultValues: defaultValuesData })
            }
            defaultValues={defaultValues}
            getFilters={getFilters}
        />
    ) : (
        <></>
    );
};
