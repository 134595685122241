import React from 'react';
import ManyAnswer from './ManyAnswer';
import OneAnswer from './OneAnswer';
import PlainAnswer from './PlainAnswer';

export const checkFields = (item, checker_id) => {
    switch (item?.kind) {
        case 'one':
            return <OneAnswer key={item?.id} parentId={checker_id} item={item} />;
        case 'many':
            return <ManyAnswer key={item?.id} parentId={checker_id} item={item} />;
        case 'plain':
            return <PlainAnswer key={item?.id} parentId={checker_id} item={item} />;
        default:
            return null;
    }
};
