import { reduxStore } from '../../redux/store';
import { clientAuthPassword, clientPassword } from '../ClientPassword';
import {
    SettingsAllRequestType,
    SettingsAllResponseType,
    SettingsListRequestType,
    SettingsListResponseType,
    SettingsOptionsRequest,
    SettingsOptionsResponse,
    SettingValuesUpdateManyRequestType,
    SettingValuesUpdateManyResponseType,
    SettingsAllUserRequestType,
    SettingsAllUserResponseType,
    UserSettingsRequestType,
    UserSettingsResponseType,
    ProjectSettingsRequest,
    UserSettingItem
} from './types';

export const fetchAllUserSettings = (request: SettingsAllUserRequestType) => {
    const user = reduxStore.getState().auth?.user;
    return clientAuthPassword.post<SettingsAllUserResponseType, SettingsAllUserRequestType>(
        '/api/v1/user_setting/user/',
        {
            user_id: user?.id,
            pagination: {
                limit: 100,
                offset: 0,
            },
            ...request,
        }
    );
};

export const fetchAllSettings = (request: SettingsAllRequestType) => {
    return clientPassword.post<SettingsAllResponseType, SettingsAllRequestType>(
        '/settings/all/',
        request
    );
};

export const fetchSettingsList = (request: SettingsListRequestType) => {
    return clientPassword.post<SettingsListResponseType, SettingsListRequestType>(
        '/settings/list/',
        request
    );
};

export const settingValuesUpdateMany = (request: SettingValuesUpdateManyRequestType) => {
    return clientPassword.patch<
        SettingValuesUpdateManyResponseType,
        SettingValuesUpdateManyRequestType
    >('/setting_values/update_many/', request);
};

export const fetchUserSettings = (request: UserSettingsRequestType) => {
    return clientAuthPassword.post<UserSettingsResponseType, UserSettingsRequestType>('/api/v1/user_setting/user/', request)
};

export const fetchSettingsOptions = (request: SettingsOptionsRequest) => {
    return clientAuthPassword.post<SettingsOptionsResponse, SettingsOptionsRequest>('/api/v1/setting_option/list/', request)
};

export const fetchSettingsCategories = () => {
    return clientAuthPassword.post('/api/v1/user_setting/category/')
};

export const fetchProjectSettings = (request: ProjectSettingsRequest) => {
    return clientAuthPassword.post<UserSettingsResponseType, ProjectSettingsRequest>('/api/v1/user_setting/project_settings/', request)
};

export const updateSettings = (request: UserSettingItem[]) => {
    return clientAuthPassword.post<UserSettingItem[], UserSettingItem[]>('/api/v1/user_setting/update_many/', request);
}
