import { AuthResponse } from "../apiFeature/auth/types"; 

let refreshTokenRef: Promise<AuthResponse> | null = null;
export const setRefreshTokenRef = (r: Promise<AuthResponse> | null) => {
    refreshTokenRef = r;
};

export const getRefreshTokenRef = (): Promise<AuthResponse> | null => {
    return refreshTokenRef;
};
