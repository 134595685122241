import React, { useCallback, useEffect, useState } from 'react';
import styles from './SettingsRolesData.module.scss';
import { TableGrid, Loader } from '@components';
import { extractErrorMessage } from '@tools/utils/functions';
import { useSnackbar } from 'notistack';
import { fetchProjectSettings, updateSettings, fetchSettingsCategories } from '@apiFeature/settings';
import SettingsSelectors from '@redux/settings/selectors';
import FiltersSelectors from '@redux/filters/selectors';
import { SettingsThunks } from '@redux/settings/thunk';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ActionBtns from '../ActionBtns/ActionBtns';
import { DialogForSettings } from '../DialogForSettings/DialogForSettings';
import { renderCellInput } from '../RenderEditCell/RenderEditCell';
import ProjectsSelectors from '@redux/projects/selectors';

export const rolesList = [
    'client',
    'analyst',
    'director_merchandiser',
    'leader',
    'department_head',
    'supervisor',
    'merchandiser',
];

export const SettingsRolesData = ({ defaultValues, filters, form }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('translation');
    const [columns, setColumns] = useState<any>([]);
    const [columnsFilteredByRoles, setFilteredByRoles] = useState([]);
    const [rows, setRows] = useState([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isUpdateLoading, setUpdateLoading] = useState<boolean>(false);
    const [settingsData, setSettingsData] = useState<any | null>(null);
    const [categories, setCategories] = useState<any>([]);
    const [initFormValues, setInitFormValues] = useState(null);
    const [newData, setNewData] = useState(null);

    const { watch, setValue, handleSubmit } = form;
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isBtnsVisible, setIsButtonsVisible] = useState(false);

    const dispatch = useDispatch();
    const { roles, rolesLoading } = useSelector(FiltersSelectors.filtersState);
    const { settingsOptions } = useSelector(SettingsSelectors.settingsState);
    const { projectsList } = useSelector(ProjectsSelectors.projectsSelectors);

    const projectsListId = projectsList?.[0]?.id;
    const project_id = filters?.project_id || defaultValues?.project_id || projectsListId;


    const roleNames = {
        'client': t('settings.client'),
        'analyst': t('settings.analyst'),
        'director_merchandiser': t('settings.director'),
        'leader': t('settings.RM'),
        'department_head': t('settings.TM'),
        'supervisor': t('settings.supervisor'),
        'merchandiser': t('common.merchandiser'),
    };

    const getSettingsOptions = useCallback(async (setting_id) => {
        const currentSettingOptions = settingsOptions?.[setting_id];

        if (!currentSettingOptions && !currentSettingOptions?.length) {
            return dispatch(
                SettingsThunks.getSettingsOptions({
                    setting_id,
                })
            )
        }
    }, [rows]);

    const getSettingsByRoleId = useCallback(async (roles) => {
        if (roles?.length > 0 && project_id) {
            setLoading(true);
            const ids = roles.filter(r => rolesList.includes(r.sys_name)).map(r => r.id);
            const settingPromises = ids.map((id) => {
                return fetchProjectSettings({
                    role_id: id,
                    project_id,
                    pagination: {
                        limit: 50
                    }
                }).then((settingsResponse) => {
                    const { user_settings } = settingsResponse || {};
                    const { items } = user_settings || {};
                    if (items?.length > 0) {
                        return items;
                    }
                }).catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                }).finally(() => {
                    setLoading(false);
                });
            });
            if (settingPromises?.length) {
                try {
                    setLoading(true)
                    const fetchedSettings = await Promise.all(settingPromises);
                    if (fetchedSettings?.length) {
                        const settingsResults = fetchedSettings.flat().filter(s => s !== undefined);

                        return settingsResults;
                    }
                } catch (error) {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                };
                setLoading(false)

            }
        }
    }, [project_id, roles, newData])

    const getSettingsCategories = useCallback(() => {
        if (!categories?.length && project_id) {
            setLoading(true)
            fetchSettingsCategories().then((res) => {
                const { categories: catInit } = res || {};
                if (catInit?.length) {
                    const resultCategories = catInit.filter(c => c.setting_name !== null);
                    setCategories(resultCategories);
                }
            }).catch((error) => {
                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [project_id]);

    useEffect(() => {
        if (roles?.length > 0 && !rolesLoading) {
            getSettingsByRoleId(roles).then((res) => {
                setSettingsData(res);
            }).catch((error) => {
                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
            });
        }

    }, [roles, project_id, newData]);

    useEffect(() => {
        if (settingsData?.length > 0 && settingsOptions && Object.keys(settingsOptions)?.length) {
            const transformedData = settingsData.reduce((acc, currentValue) => {
                const { role_id, setting_id, setting_option_id, setting_value } = currentValue;
                if (!acc?.[role_id]) {
                    acc[role_id] = {};
                }
                acc[role_id][setting_id] = {
                    setting_value,
                    setting_option_id
                };

                return acc;
            }, {});
            setValue('roles', transformedData);
            setInitFormValues(transformedData);
        }
    }, [settingsData, settingsOptions])

    useEffect(() => {
        getSettingsCategories();
    }, [project_id]);

    useEffect(() => {
        if (categories?.length) {
            const resultRows = categories.map((c) => {
                const { setting_id, category_name } = c || {};
                let row = {
                    id: setting_id,
                    category_name,
                    ...c
                };
                if (settingsData?.length > 0) {
                    const settingsWithCurrentId = settingsData.filter(s => s.setting_id === setting_id);
                    settingsWithCurrentId?.forEach(s => {
                        row[s.role_id] = s
                    })
                };
                return row
            });
            setRows(resultRows);
        }
    }, [categories, settingsData]);


    useEffect(() => {
        if (roles?.length > 0) {
            const columnsRes = roles.filter(r => rolesList.includes(r.sys_name)).map((r) => {
                const { sys_name, id } = r || {};
                return {
                    field: id,
                    cellClassName: styles.cell,
                    headerName: roleNames[sys_name],
                    minWidth: 150,
                    renderCell: (params) => {
                        const { setting_id, setting_kind } = params.row;
                        const currentValue = watch(`roles[${id}][${setting_id}]`);
                        const argsForRenderCell = {
                            setting: params.row,
                            currentValue,
                            registerStrValue: `roles[${id}][${setting_id}].setting_value`,
                            registerStrOptionId: `roles[${id}][${setting_id}].setting_option_id`,
                            setValue,
                            settingsOptions,
                            setting_kind,
                            getSettingsOptions,
                            setIsButtonsVisible,
                        };
                        return renderCellInput(argsForRenderCell)
                    },
                    sortable: false,
                }
            });
            setColumns([
                {
                    field: 'category_name',
                    headerName: t('settings.category'),
                    width: 150,
                },
                {
                    field: 'setting_name',
                    headerName: t('settings.setting'),
                    minWidth: 220,
                    sortable: false,
                },
                ...columnsRes])
        }
    }, [roles, settingsOptions]);

    useEffect(() => {
        const { roleMultiple } = filters || {};
        if (roleMultiple && roleMultiple?.length > 0) {
            const newColumns = columns.filter((column) => {
                const { field, headerName } = column;
                if (field === 'setting_name' || field === 'category_name') {
                    return true
                } else {
                    const currentSysName = Object.keys(roleNames).find((key) => roleNames[key] === headerName);
                    return roleMultiple.includes(currentSysName)
                }

            });
            setFilteredByRoles(newColumns);
        } else {
            setFilteredByRoles([]);
        }
    }, [filters])

    useEffect(() => {
        if (rows?.length) {
            rows.forEach(((r) => {
                const { setting_kind, setting_id } = r || {};
                if (setting_kind === 'RIGHTS') {
                    getSettingsOptions(setting_id);
                };
            }))
        }
    }, [rows]);

    const getRowId = (row) => {
        return row?.setting_id
    };

    const handleCloseDialog = () => {
        setIsOpenDialog(false);
        setIsButtonsVisible(false);
    };

    const onSubmit = (data) => {
        const { roles } = data || {};
        let payload = [];
        if (roles) {
            const role_ids = Object.keys(roles);
            if (role_ids?.length > 0) {
                role_ids.forEach((rId) => {
                    const settings = Object.keys(data.roles[rId]);
                    if (settings?.length > 0) {
                        settings.forEach((sId) => {
                            const { setting_option_id } = data.roles[rId][sId] || {};
                            const { setting_option_id: initValue } = initFormValues?.[rId]?.[sId] || {};
                            if (setting_option_id && setting_option_id !== initValue) {
                                payload.push({
                                    setting_id: sId,
                                    role_id: rId,
                                    setting_option_id,
                                    project_id
                                })
                            }

                        })
                    }
                });
                if (payload?.length > 0) {
                    setUpdateLoading(true)
                    updateSettings(payload).then((res) => {
                        setNewData(res);
                        setInitFormValues(data.users)
                    }).catch((error) => {
                        enqueueSnackbar(extractErrorMessage(error), { variant: 'error' })
                    }).finally(() => {
                        setUpdateLoading(false);
                        setIsOpenDialog(false);
                        setIsButtonsVisible(false);
                    })
                }
            };
        }
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {isUpdateLoading && <Loader />}
            <TableGrid
                rows={rows}
                columns={columnsFilteredByRoles?.length > 0 ? columnsFilteredByRoles : columns}
                loading={isLoading}
                rowHeight={90}
                pageSizeOptions={[100]}
                getRowHeight={() => 'auto'}
                getRowId={getRowId}
                className={styles.tableGrid}
                disableRowSelectionOnClick
            />
            {isBtnsVisible &&
                <ActionBtns
                    handleCancel={handleCloseDialog}
                    setIsOpenDialog={setIsOpenDialog}
                />}
            <DialogForSettings
                isOpenDialog={isOpenDialog}
                handleCloseDialog={handleCloseDialog}
            />
        </form>
    )
}