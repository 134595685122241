import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './Reset.style.scss';

interface ResetProps {
    onclick: () => void;
    status?: boolean;
}

export const Reset: FC<ResetProps> = ({ onclick, status }) => {
    const { t } = useTranslation('translation');

    return (
        <button
            className={`${status ? 'reset' : 'reset active'}`}
            disabled={status}
            type="button"
            onClick={onclick}
        >
            {t('common.reset')}
        </button>
    );
};
