import { apServicePassword } from '../ClientPassword';
import { PeriodListRequest, PeriodListResponse, ProgramsListRequest, ProgramsResponse, UpdateProgramRequest, UpdateProgramResponse } from './type';

export const fetchPeriods = (request: PeriodListRequest) => {
    return apServicePassword.post<PeriodListResponse, PeriodListRequest>(
        `/v1/logic_periods/list_for_filter/`,
        request
    );
};

export const fetchProgramsList = (request: ProgramsListRequest) => {
    return apServicePassword.post<ProgramsResponse, ProgramsListRequest>(
        `/v1/programs/list/`,
        request
    );
};

export const fetchProgram = (program_id: string) => {
    return apServicePassword.get<ProgramsResponse, ProgramsListRequest>(
        `/v1/programs/${program_id}/`
    );
};

export const fetchProgramPramsList = (request: ProgramsListRequest) => {
    return apServicePassword.post<ProgramsResponse, ProgramsListRequest>(
        `/v1/program_prams/list/`,
        request
    );
};

export const updateProgram = (program_id: string, request: UpdateProgramRequest) => {
    return apServicePassword.patch<UpdateProgramResponse, UpdateProgramRequest>(
        `/v1/programs/${program_id}/`,
        request
        );
};

export const deleteAddressProgram = (program_id: string) => {
    return apServicePassword.delete( `/v1/programs/${program_id}/`);
};

export const fetchFullPeriodList = (request: PeriodListRequest) => {
    return apServicePassword.post<PeriodListResponse, PeriodListRequest>(
        `/v1/periods/list/`,
        request
    );
};
