import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from './context/appContext';
import { reduxStore } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { App } from './view/App';
import { SnackbarProvider } from './context/snackbarProvider';
import './i18n';
import './sentry';
import theme from './theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <ThemeProvider theme={theme}>
        <AppProvider>
            <ReduxProvider store={reduxStore}>
                <BrowserRouter>
                    <SnackbarProvider>
                        <App />
                    </SnackbarProvider>
                </BrowserRouter>
            </ReduxProvider>
        </AppProvider>
    </ThemeProvider>
);

reportWebVitals(); 
