import React, { FC } from 'react';

interface ButtonArrowProps {
    text: string;
    onClick?: () => void;
    className?: string;
}

export const ButtonArrow: FC<ButtonArrowProps> = ({ text, onClick, className }) => {
    return (
        <div className={`buttonArrow__wrap ${className}`}>
            <button
                className='buttonArrow'
                type='button'
                onClick={onClick}
            >
                {text}
            </button>
        </div>
    );
};
