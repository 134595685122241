import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './UserProjectItem.module.scss';
import { Select, Button, Dialog } from '@components';
import { Controller, useFormContext } from 'react-hook-form';
import { Project } from '@types/projects';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeamList } from '@apiFeature/team';
import { getOptions } from '@tools/utils/functions';
import { FiltersThunks } from '@redux/filters/thunk';
import FiltersSelectors from '@redux/filters/selectors';
import { getManagersList } from '../../TeamData/TeamData';
import DeleteIcon from '@images/svg/delete.svg';

interface IUserPojectItem {
    projectOptions: Project[];
    registerStr: string;
    removeProject: (index: number) => void;
    idx: number;
    userProjects: any[];
    id_item: string;
    isAdmin: boolean;
    isEdit: boolean;
}

export const UserProjectItem: FC<IUserPojectItem> = ({
    projectOptions,
    registerStr,
    removeProject,
    idx,
    userProjects,
    id_item,
    isAdmin,
    isEdit,
}) => {
    const [isOpenDialog, setOpenDialog] = useState(false);
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const formMethods = useFormContext();
    const { control, setValue, watch } = formMethods || {};
    const currentValues = watch();

    const userProjectDefaultValue = userProjects?.[idx];
    const {
        id_item: id_item_default,
        branches_default,
        territory_default,
        project_retails_default,
        questionnaires_default,
    } = userProjectDefaultValue || {};

    const userProjectValue = currentValues.userProjects[idx];
    const { project, manager, branches, territory, project_retails, questionnaires } =
        userProjectValue || {};

    const filtersState = useSelector(FiltersSelectors.filtersState);
    const { managerProjects, tagsProjects } = filtersState || {};

    const fetchOptionsList = useCallback(() => {
        if (project) {
            fetchTeamList(project)?.then((res) => {
                const { hierarchy: allUsers } = res || {};
                if (allUsers) {
                    const managersList = getManagersList(allUsers);
                    dispatch(
                        FiltersThunks.getManagerProjectExecutors.fulfilled({
                            project,
                            allUsers: managersList,
                        })
                    );
                }
            });
            dispatch(
                FiltersThunks.getUserProjectExecutors({
                    project_id: project,
                })
            );
        }
    }, [project]);

    useEffect(() => {
        if (project) {
            fetchOptionsList();
        }
    }, [project]);

    useEffect(() => {
        if (project && manager && Object.keys(managerProjects)?.length > 0) {
            const managers = managerProjects?.[project] || [];
            if (managers?.length > 0 && !managers.some((e) => e.id === manager))
                setValue(`${registerStr}.manager`, '');
        }
    }, [project, managerProjects, manager]);

    const setActualValue = (curData, data, name) => {
        const value = data?.filter((e) => curData?.includes(e.id)).map((e) => e.id);
        if (curData && curData?.join() !== value?.join()) {
            setValue(name, value.length > 0 ? value : null);
        }
    };

    useEffect(() => {
        if (project && tagsProjects?.[project]) {
            if (branches?.length > 0 && 'branches' in tagsProjects?.[project]) {
                const data = tagsProjects?.[project]?.branches || [];
                setActualValue(branches, data, `${registerStr}.branches`);
            }
            if (territory?.length > 0 && 'territory' in tagsProjects?.[project]) {
                const data = tagsProjects?.[project]?.territory || [];
                setActualValue(territory, data, `${registerStr}.territory`);
            }
            if (project_retails?.length > 0 && 'project_retails' in tagsProjects?.[project]) {
                const data = tagsProjects?.[project]?.project_retails || [];
                setActualValue(project_retails, data, `${registerStr}.project_retails`);
            }
            if (questionnaires?.length > 0 && 'questionnaires' in tagsProjects?.[project]) {
                const data = tagsProjects?.[project]?.questionnaires || [];
                setActualValue(questionnaires, data, `${registerStr}.questionnaires`);
            }
        }
    }, [project, tagsProjects?.[project]]);

    const deleteProject = (e) => {
        e.preventDefault();
        setOpenDialog(false);
        removeProject(idx);
    };

    const handleOpenModal = (e) => {
        e.preventDefault();
        setOpenDialog(true);
    };

    const handleCloseModal = () => {
        setOpenDialog(false);
    };

    const onChange = (name, e) => {
        let value = e?.value;
        if (e && Array.isArray(e)) {
            value = e.length > 0 ? e.map((item) => item.value) : null;
        }
        setValue(name, value, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    const projectOptionsData =
        isAdmin || !isEdit
            ? projectOptions
            : userProjects.map((e) => ({ label: e.project_name, value: e.project }));

    const managerOptions = getOptions(managerProjects[project], [
        'last_name',
        'first_name',
        'patronymic_name',
        'phone',
    ]);

    const branchesOptions =
        getOptions(tagsProjects[project]?.branches, ['tag_name']) ||
        getOptions(branches_default, ['tag_name'], 'tag_id');
    const territoryOptions =
        getOptions(tagsProjects[project]?.territory, ['tag_name']) ||
        getOptions(territory_default, ['tag_name'], 'tag_id');
    const retailsOptions =
        getOptions(tagsProjects[project]?.project_retails, ['tag_name']) ||
        getOptions(project_retails_default, ['tag_name'], 'tag_id');
    const questionnairesOptions =
        getOptions(tagsProjects[project]?.questionnaires, ['tag_name']) ||
        getOptions(questionnaires_default, ['tag_name'], 'tag_id');

    return (
        <div className={styles.listItem}>
            <div className={styles.btn}>
                <Button
                    type="secondary"
                    className={styles.deleteBtn}
                    onClick={id_item_default === id_item ? handleOpenModal : deleteProject}
                    title={t('buttons.deleteProject')}
                >
                    <img src={DeleteIcon} alt="" />
                </Button>
                <Dialog
                    id={'deleteUserProjectItemDialog' + idx}
                    isOpenDialog={isOpenDialog}
                    setCloseDialog={handleCloseModal}
                    className={styles.dialog}
                    title={t('buttons.deleteProject')}
                    actions={
                        <>
                            <Button variant="contained" color="secondary" onClick={deleteProject}>
                                {t('buttons.delete')}
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
                                {t('buttons.cancel')}
                            </Button>
                        </>
                    }
                >
                    {t('messages.userProjectDeleteConfirmation')}
                </Dialog>
            </div>
            <Controller
                name={`${registerStr}.project`}
                control={control}
                render={({ field: { name, value, ref }, fieldState: { error } }) => (
                    <Select
                        id={name}
                        name={name}
                        inputRef={ref}
                        label={idx === 0 ? t('common.project') : null}
                        onChange={(e) => {
                            setValue(name, e.value, {
                                shouldDirty: true,
                                shouldValidate: true,
                            });
                        }}
                        value={projectOptionsData?.find((e) => e.value === value)}
                        options={projectOptionsData}
                        error={error}
                        menuPlacement="top"
                    />
                )}
            />
            <Controller
                name={`${registerStr}.manager`}
                control={control}
                render={({ field: { name, value, ref }, fieldState: { error } }) => (
                    <Select
                        id={name}
                        name={name}
                        inputRef={ref}
                        label={idx === 0 ? t('common.manager') : null}
                        onChange={(e) => {
                            setValue(name, e?.value || null, {
                                shouldDirty: true,
                                shouldValidate: true,
                            });
                        }}
                        value={managerOptions?.find((e) => e.value === value)}
                        options={managerOptions}
                        error={error}
                        menuPlacement="top"
                        isClearable={true}
                    />
                )}
            />
            <Controller
                name={`${registerStr}.branches`}
                control={control}
                render={({ field: { name, value, ref }, fieldState: { error } }) => (
                    <Select
                        id={name}
                        name={name}
                        inputRef={ref}
                        label={idx === 0 ? t('feed.branch') : null}
                        onChange={(e) => onChange(name, e)}
                        onFocus={() =>
                            !tagsProjects[project]?.branches &&
                            dispatch(
                                FiltersThunks.getTagsProject({
                                    project_id: project,
                                    entity_type: 'branches',
                                })
                            )
                        }
                        value={
                            branches
                                ? branchesOptions?.filter((e) => branches.includes(e.value))
                                : null
                        }
                        options={branchesOptions}
                        error={error}
                        menuPlacement="top"
                        isClearable={true}
                        isMulti={true}
                    />
                )}
            />
            <Controller
                name={`${registerStr}.territory`}
                control={control}
                render={({ field: { name, value, ref }, fieldState: { error } }) => (
                    <Select
                        id={name}
                        name={name}
                        inputRef={ref}
                        label={idx === 0 ? t('common.territory') : null}
                        onChange={(e) => onChange(name, e)}
                        onFocus={() =>
                            !tagsProjects[project]?.territory &&
                            dispatch(
                                FiltersThunks.getTagsProject({
                                    project_id: project,
                                    entity_type: 'territory',
                                })
                            )
                        }
                        value={
                            territory
                                ? territoryOptions?.filter((e) => territory.includes(e.value))
                                : null
                        }
                        options={territoryOptions}
                        error={error}
                        menuPlacement="top"
                        isClearable={true}
                        isMulti={true}
                    />
                )}
            />
            <Controller
                name={`${registerStr}.project_retails`}
                control={control}
                render={({ field: { name, value, ref }, fieldState: { error } }) => (
                    <Select
                        id={name}
                        name={name}
                        inputRef={ref}
                        label={idx === 0 ? t('common.retail') : null}
                        onChange={(e) => onChange(name, e)}
                        onFocus={() =>
                            !tagsProjects[project]?.project_retails &&
                            dispatch(
                                FiltersThunks.getTagsProject({
                                    project_id: project,
                                    entity_type: 'project_retails',
                                })
                            )
                        }
                        value={
                            project_retails
                                ? retailsOptions?.filter((e) => project_retails.includes(e.value))
                                : null
                        }
                        options={retailsOptions}
                        error={error}
                        menuPlacement="top"
                        isClearable={true}
                        isMulti={true}
                    />
                )}
            />
            <Controller
                name={`${registerStr}.questionnaires`}
                control={control}
                render={({ field: { name, value, ref }, fieldState: { error } }) => (
                    <Select
                        id={name}
                        name={name}
                        inputRef={ref}
                        label={idx === 0 ? t('importJournal.questionnaire') : null}
                        onChange={(e) => onChange(name, e)}
                        onFocus={() =>
                            !tagsProjects[project]?.questionnaires &&
                            dispatch(
                                FiltersThunks.getTagsProject({
                                    project_id: project,
                                    entity_type: 'questionnaires',
                                })
                            )
                        }
                        value={
                            questionnaires
                                ? questionnairesOptions?.filter((e) =>
                                      questionnaires.includes(e.value)
                                  )
                                : null
                        }
                        options={questionnairesOptions}
                        error={error}
                        menuPlacement="top"
                        isClearable={true}
                        isMulti={true}
                    />
                )}
            />
        </div>
    );
};
