import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRoles } from '@tools/hooks/useRoles';
import moment from 'moment';
import { permissionCheck } from '@tools/utils/permissionCheck';
import FiltersSelectors from '@redux/filters/selectors';
import SettingsSelectors from '@redux/settings/selectors';
import { Button, PhotoSlider } from '@components';
import ListMapper from '@components/Lists/List/ListMapper/ListMapper';
import { fetchFeedsPhotos } from '@api/visitFeed/visitFeed.api';
import { useAppSelector } from '@tools/hooks/redux';
import styles from './CrowdFeedList.module.scss';
import { TaskItem } from '@apiFeature/crowd/types';
import { crowdTaskStatusNames } from '@apiFeature/crowd/types';
import CrowdSelectors from '@redux/crowd/selectors';
import { getDateGMT } from '@tools/utils/date.util';
import { useNavigate } from 'react-router-dom';
import { fetchCheckListResult } from '@apiFeature/checkers';

const channel = new BroadcastChannel('visitUpdateStatus');

const CrowdFeedList: FC<TaskItem> = (taskItem) => {
    const { t } = useTranslation('translation');
    const authRoles = useAppSelector((state) => state?.auth?.jwtPayloadOpen?.roles);
    const { clientPermission, isClientCrowd } = useRoles();
    const navigate = useNavigate();

    const { taskComplexities, taskTypes } = useAppSelector(CrowdSelectors.crowdSelectors);
    const { branches, cities } = useAppSelector(FiltersSelectors.filtersState);
    const { settingsAll } = useAppSelector(SettingsSelectors.settingsState);
    const photoSliderType = settingsAll?.settings?.find((s) => s.code === 'type_photo_display_feed')
        ?.setting_values?.[0]?.val;

    const [photos, setPhotos] = useState([]);

    const {
        project_outlet,
        utc_offset,
        start_date,
        end_date,
        task_type_id,
        price,
        timing,
        user,
        start_time,
        end_time,
        task_complexity_id,
        status,
        task_reserve_id,
        task_id,
        result,
        isCheckers = false,
    } = taskItem;
    const { last_name, first_name, roles, phone } = user || {};
    const {
        address,
        branch_id,
        city_id,
        external_code,
        name,
        longitude,
        latitude,
        retails_format,
    } = project_outlet || {};
    const roleData =
        roles && roles?.length > 1
            ? roles.map((i) => i?.name + ', ')
            : roles && roles[0]?.name
            ? roles[0]?.name + ', '
            : '';
    const role = isClientCrowd ? t('common.merchandiser') : roleData;

    const taskComplexity = taskComplexities?.length
        ? taskComplexities.find((tCom) => tCom.id === task_complexity_id)?.name
        : '-';
    const taskType = taskTypes?.length
        ? taskTypes.find((type) => type.id === task_type_id)?.name
        : '-';
    const branch = branches?.length
        ? branches.find((branch) => branch.id === branch_id)?.name
        : '-';
    const city = cities?.length ? cities.find((city) => city.id === city_id)?.name : '-';

    let duration;
    if (start_time && end_time) {
        const durationStart = moment(start_time);
        const durationEnd = moment(end_time);
        const diff = durationEnd.diff(durationStart);
        duration = moment.utc(diff).format('HH:mm:ss');
    } else {
        duration = '-';
    }

    const serializedFeedItem = {
        [t('common.outletName')]: {
            value: name,
        },
        [t('feed.address')]: {
            value: address ? address : '-',
        },
        [t('common.status')]: {
            value: status ? crowdTaskStatusNames[status] : '-',
        },
        [t('crowd.price')]: {
            value: price ? price : '-',
        },
        // waiting to add field in response from backend:
        // [t('crowd.startDate')]: {
        //     value: getDateGMT(start_date, utc_offset, 'D MMMM YYYY, HH:mm:ss'),
        // },
        // [t('crowd.endDate')]: {
        //     value: getDateGMT(end_date, utc_offset, 'D MMMM YYYY, HH:mm:ss'),
        // },
        [t('crowd.task_type')]: {
            value: taskType,
        },
        [t('crowd.task_complexity')]: {
            value: taskComplexity,
        },
        [t('crowd.plannedTime')]: {
            value: timing ? timing : '-',
        },
        //waiting to add field in response from backend:
        // [t('feed.comment')]: {
        //     value: comment ? comment : '-'
        // },
        [t('feed.branch')]: {
            value: branch ? branch : '-',
        },
        [t('common.city')]: {
            value: city ? city : '-',
        },
        [t('common.outletCode')]: {
            value: external_code ? external_code : '-',
        },
        [t('feed.retailsFormat')]: {
            value: retails_format ? retails_format : '-',
        },
    };

    const serializedFeedTimeItem = {
        [t('crowd.startTime')]: {
            value: getDateGMT(start_time, utc_offset, 'D MMMM YYYY, HH:mm:ss'),
        },
        [t('crowd.endTime')]: {
            value: getDateGMT(end_time, utc_offset, 'D MMMM YYYY, HH:mm:ss'),
        },
        [t('feed.timeSpend')]: {
            value: duration,
        },
    };

    const photoDescription = {
        [t('feed.visitDate')]: {
            value: getDateGMT(start_time, utc_offset, 'D MMMM YYYY, HH:mm:ss'),
            isVisible: true,
        },
        [t('feed.shopName')]: {
            value: name,
        },
        [t('feed.address')]: {
            value: address,
        },
        [t('feed.coordinates')]: {
            value: latitude && longitude && `${latitude}, ${longitude}`,
            isVisible: true,
        },
    };

    const [isVisitChecked, setIsVisitChecked] = useState({ [task_id]: false });
    const [checkResult, setCheckResult] = useState({ [task_id]: null });

    const getCheckListResult = () => {
        if (isCheckers) {
            fetchCheckListResult({
                object_id: task_id,
            }).then((res) => {
                const checkListResults = res?.check_list_results?.items || [];
                const [currentChecker] = checkListResults || [];
                if (currentChecker) {
                    setIsVisitChecked({ [task_id]: true });
                    setCheckResult({ [task_id]: currentChecker?.result });
                }
            });
        }
    };

    const visitUpdateStatus = ({ data }) => {
        const { visit } = data || {};
        const { id: visit_id, status } = visit || {};
        if ((status === 'completed' || status === 'DONE') && visit_id === task_id) {
            getCheckListResult();
        }
    };

    useEffect(() => {
        getCheckListResult();
    }, [task_id]);

    useEffect(() => {
        channel.addEventListener('message', visitUpdateStatus);
        return () => {
            channel.removeEventListener('message', visitUpdateStatus);
        };
    }, []);

    useEffect(() => {
        fetchFeedsPhotos({ visit_id: task_reserve_id, is_preview: null }).then((images) => {
            setPhotos(images);
        });
    }, [task_reserve_id]);

    const handleTaskNav = () => {
        navigate(`/crowd/create/`, { params: { isOpen: true, item: taskItem } });
    };

    const openPage = () => {
        window.open(isCheckers ? `/crowd-check/${task_id}` : `/crowd-feed/${task_id}`);
    };

    return (
        <>
            <div className={styles.header}>
                <div className={styles.item}>
                    {!isClientCrowd && (
                        <div className={styles.name}>
                            {last_name} {first_name}
                        </div>
                    )}
                    <div className={styles.role}>
                        {role} {!isClientCrowd ? phone : ''}
                    </div>
                    <div className={styles.number_info}>
                        {t('crowd.taskId')}: {task_id ?? '-'}
                    </div>
                </div>
                {isCheckers && (
                    <div className={styles.item}>
                        <div className={styles.checker_badge}>
                            <div
                                className={
                                    isVisitChecked[task_id]
                                        ? styles.activeChecker
                                        : styles.nonActiveChecker
                                }
                            >
                                {isVisitChecked[task_id]
                                    ? t('crowd.checked')
                                    : t('crowd.notChecked')}
                            </div>
                            {isVisitChecked[task_id] && (
                                <div className={styles.checkResult}>
                                    {`${t('feed.checkResult')}: ${checkResult[task_id]}`}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.content}>
                <div className={styles.item}>
                    <ListMapper
                        items={permissionCheck(authRoles, serializedFeedItem)}
                        className={styles.serializedFeedItem}
                    />
                </div>
                <div className={styles.item}>
                    <ListMapper
                        items={permissionCheck(authRoles, serializedFeedTimeItem)}
                        className={styles.serializedFeedTimeItem}
                    />
                </div>
            </div>
            {photos?.length > 0 && (
                <div className={styles.slider}>
                    <PhotoSlider
                        id={task_reserve_id}
                        photos={photos}
                        type={photoSliderType || 'carousel'}
                        description={permissionCheck(authRoles, photoDescription)}
                        utc_offset={utc_offset}
                        hasDeleteBtn={!clientPermission}
                    />
                </div>
            )}
            <div className={styles.footer}>
                <Button color="secondary" variant="outlined" target="_blank" onClick={openPage}>
                    {isCheckers
                        ? isVisitChecked[task_id]
                            ? t('buttons.checkResult')
                            : t('feed.checkerReport')
                        : t('feed.checkReport')
                    }
                </Button>
            </div>
        </>
    );
};

export default CrowdFeedList;
