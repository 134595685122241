import React, { FC, PropsWithChildren } from 'react';
import { PrivateRoutes } from '@navigation/routes/private';
import { MenuBlock } from '@components';

export const PrivateLayout: FC<PropsWithChildren> = () => {
    return (
        <div>
            <main>
                <div className="main__nav">
                    <MenuBlock />
                </div>
                <div className="main__content">
                    <PrivateRoutes />
                </div>
            </main>
        </div>
    );
};
