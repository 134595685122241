import React, { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import styles from './Title.module.scss';

interface ITitle {
    className?: string | undefined;
    type?: 'h1' | 'h2' | 'h3' | undefined;
    children: ReactNode;
}

const Title: FC<ITitle> = ({ children, className = '', type = 'h1', ...props }) => {
    return (
        <Box {...props} className={classNames(styles.title, styles[type], className)}>
            {children}
        </Box>
    );
};

export default Title;
