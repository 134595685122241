import moment from 'moment';

type TFuncConvert = (date: string) => string;
type TFuncConvertToOriginal = (date: string, utcOffset?: number, format?: string) => string;

export const convertLocalToUtc: TFuncConvert = (date) => moment.utc(date).format();

export const convertToOriginalDate: TFuncConvertToOriginal = (
    date,
    utcOffset,
    format = 'D MMMM, HH:mm:ss'
) =>
    date
        ? moment
              .utc(date || '')
              .utcOffset(utcOffset || 0)
              .format(format)
        : '';

export const utcOffsetInHours = (): number => moment().utcOffset() / 60;
