import React from 'react';
import { useSelector } from 'react-redux';
import styles from './VisitSchedule.module.scss';
import { useTranslation } from 'react-i18next';
import { Layout } from '@components';
import VisitScheduleBaseFilters from './VisitScheduleBaseFilters/VisitScheduleBaseFilters';
import VisitScheduleHeader from './VisitScheduleHeader/VisitScheduleHeader';
import VisitScheduleData from './VisitScheduleData/VisitScheduleData';
import SettingsSelectors from '@redux/settings/selectors';
import moment from 'moment';

export const VisitSchedule = () => {
    const { t } = useTranslation('translation');

    const project_id = sessionStorage.getItem('project_id');
    let defaultValues = {
        project_id: project_id !== 'undefined' ? project_id : '',
        type: 'visit',
        date: {
            start: moment(new Date()).format('YYYY-MM-DD'),
            end: moment(new Date()).add(6, 'days').format('YYYY-MM-DD'),
        },
    };

    const getFilters = (values) => {
        return values;
    };

    const { settingsAllUser: settings } = useSelector(SettingsSelectors.settingsState);
    const visitingScheduleWebCreate =
        settings?.find((s) => s.code === 'web_visiting_schedule_permission')?.setting_value ===
        'CREATE';

    return (
        <Layout
            className={styles.layoutVisitSchedule}
            title={t('visits.title')}
            renderHeader={(props) => (
                <VisitScheduleHeader
                    {...props}
                    visitingScheduleWebCreate={visitingScheduleWebCreate}
                />
            )}
            renderBaseFilters={(props) => (
                <VisitScheduleBaseFilters {...props} settings={settings} />
            )}
            renderData={(props) => <VisitScheduleData {...props} settings={settings} />}
            defaultValues={defaultValues}
            getFilters={getFilters}
            type={2}
        />
    );
};
