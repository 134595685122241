import React, { useCallback, useEffect, useState } from 'react';
import ProjectsSelectors from '@redux/projects/selectors';
import { getProjectOutletsListV2 } from '@apiFeature/projectOutlets';
import { Lists } from '@components';
import OutletsList from './OutletsList/OutletsList';
import { useSelector } from 'react-redux';
import { FilterValue } from '@apiFeature/types';
import { useURLFilters } from '@tools/hooks/useURLFilters';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const OutletsData = ({ defaultValues, filters }) => {
    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();
    const { searchParams, setSearchParams } = useURLFilters();

    let limitFromURL;
    let pageNumber;
    try {
        limitFromURL = Number(atob(searchParams.get('limit')));
        pageNumber = Number(atob(searchParams.get('page')));
    } catch {
        limitFromURL = Number(searchParams.get('limit'));
        pageNumber = Number(searchParams.get('page'));
    }

    const defaultItemsPerPage = {
        label: limitFromURL > 0 ? limitFromURL : '10',
        value: limitFromURL > 0 ? limitFromURL : 10,
    };
    const [itemsPerPage, setItemsPerPage] = useState<FilterValue>(defaultItemsPerPage);
    const limit = Number(itemsPerPage.value);
    const offsetFromPageNumber = (pageNumber - 1) * limit;
    const paginationModelDefault = {
        offset: offsetFromPageNumber > 0 ? offsetFromPageNumber : 0,
        limit,
    };
    const { projectsList } = useSelector(ProjectsSelectors.projectsSelectors);
    const [isLoading, setLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState(paginationModelDefault);
    const [data, setData] = useState({ items: [], total: 0, offset: 0 });
    const { items = [], total = 0, offset } = data || {};

    const getProjectOutlets = useCallback(
        (offset = 0, limit) => {
            setLoading(true);
            const [project] = projectsList || [];
            const { id } = project || {};
            const { project: defaultProjectId } = defaultValues || {};
            const project_id = projectsList.find((p) => p.id === defaultProjectId)?.id ?? id;

            const res = {
                project_id,
                ...defaultValues,
                ...filters,
                pagination: {
                    limit,
                    offset,
                },
            };

            getProjectOutletsListV2(res)
                .then((res) => {
                    setData(res);
                })
                .catch((e) => enqueueSnackbar(e?.message, { variant: 'error' }))
                .finally(() => {
                    setLoading(false);
                });
        },
        [projectsList, filters]
    );

    useEffect(() => {
        const { offset, limit } = paginationModel;
        if (projectsList?.length > 0) getProjectOutlets(offset, limit);
    }, [paginationModel, projectsList]);

    useEffect(() => {
        if (Object.keys(filters)?.length > 0) {
            searchParams.set('page', btoa('1'));
            searchParams.set('limit', btoa('10'));
            setSearchParams(searchParams);
            setItemsPerPage({
                label: '10',
                value: 10,
            });
            setPaginationModel({
                offset: 0,
                limit: 10,
            });
        }
    }, [filters]);

    return (
        <Lists
            items={items}
            loading={isLoading}
            hasSun={true}
            total={total}
            offset={offset}
            limit={limit}
            renderList={(props) => <OutletsList {...props} />}
            onPaginationModelChange={setPaginationModel}
            pageNumber={pageNumber}
            setItemsPerPage={setItemsPerPage}
            emptyText={t('catalog.outlets.empty')}
        />
    );
};

export default OutletsData;
