import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '@components';
import QuestionnaireEditOutletsHeader from './QuestionnaireEditOutletsHeader/QuestionnaireEditOutletsHeader';
import QuestionnaireEditOutletsData from './QuestionnaireEditOutletsData/QuestionnaireEditOutletsData';

export const QuestionnaireEditOutlets = () => {
    const { t } = useTranslation('translation');

    const defaultValues = {
        address: '',
    };

    const getFilters = ({ address }) => {
        return { address };
    };

    const [totalUnLink, setTotalUnLink] = useState(0);
    const handleTotalUnLink = (val) => setTotalUnLink(val);

    return (
        <Layout
            title={t('common.project_outlets')}
            renderHeader={(props) => (
                <QuestionnaireEditOutletsHeader {...props} totalUnLink={totalUnLink} />
            )}
            renderSearch={() => {}}
            renderData={(props) => (
                <QuestionnaireEditOutletsData {...props} setTotalUnLink={handleTotalUnLink} />
            )}
            defaultValues={defaultValues}
            getFilters={getFilters}
        />
    );
};
