import { clientPassword } from '../ClientPassword';
import {
    FilesUploadRequest,
    QuestionnairesDetailedRequest,
    QuestionnairesDetailedResponse,
    QuestionnairesListRequest,
    QuestionnairesResponse,
    UpdateQuestionnaireRequest,
    UpdateQuestionnaireResponse,
    UpdateQuestionnaireQuestionsRequest,
    UpdateQuestionnaireQuestionsResponse,
    UpdateQuestionnairesRequest,
    UpdateQuestionnairesResponse,
    UpdateQuestionnaireQuestionResultRequest,
    UpdateQuestionnaireQuestionResultResponse,
} from './type';

export const uploadAssignment = (request: FilesUploadRequest) => {
    const { project_id, file } = request;
    const formData = new FormData();
    formData.append('project_id', project_id);
    formData.append('file', file);
    return clientPassword.post(
        `/questionnaire_assignment_outlets/import/?project_id=urn:uuid:${project_id}`,
        formData,
        {
            headers: {
                'Content-Type': `multipart/form-data`,
            },
        }
    );
};

export const listQuestionnaire = (questionnaire_id: string) => {
    return clientPassword.post(`/questionnaire_assignment_outlets/list/`, {
        questionnaire_id,
    });
};

export const linkQuestionnaire = (questionnaire_id: string, outlet_id: string) => {
    return clientPassword.post(`/questionnaire_assignment_outlets/link/`, {
        questionnaire_id,
        project_outlet_ids: [outlet_id],
    });
};

export const unlinkQuestionnaire = (questionnaire_id: string, outlet_id: string) => {
    return clientPassword.post(`/questionnaire_assignment_outlets/unlink/`, {
        questionnaire_id,
        project_outlet_ids: [outlet_id],
    });
};

export const unlinkAllQuestionnaire = (questionnaire_id: string) => {
    return clientPassword.post(
        `/questionnaire_assignment_outlets/unlink_all_project_outlets/?questionnaire_id=${questionnaire_id}`
    );
};

export const fetchQuestionnairesList = (request: QuestionnairesListRequest) => {
    return clientPassword.post<QuestionnairesResponse, QuestionnairesListRequest>(
        `/questionnaires/list/`,
        request
    );
};

export const fetchQuestionnaireDetailed = (request: QuestionnairesDetailedRequest) => {
    return clientPassword.post<QuestionnairesDetailedResponse, QuestionnairesDetailedRequest>(
        `/questionnaires/detailed-list/`,
        request
    );
};

export const fetchUpdateQuestionnaire = (arg: UpdateQuestionnaireRequest) => {
    return clientPassword.patch<UpdateQuestionnaireResponse, UpdateQuestionnaireRequest>(
        `/questionnaires/${arg?.questionnaire_id}/`,
        arg?.request
    );
};

export const fetchGetQuestionnaire = (id: string) => {
    return clientPassword.get<UpdateQuestionnaireResponse, UpdateQuestionnaireRequest>(
        `/questionnaires/${id}/`
    );
};

export const fetchUpdateQuestionnaireStep = (arg: UpdateQuestionnaireRequest) => {
    return clientPassword.patch<UpdateQuestionnaireResponse, UpdateQuestionnaireRequest>(
        `/questionnaire_steps/${arg?.questionnaire_step_id}/`,
        arg?.request
    );
};

export const fetchCreateQuestionnaireStep = (arg: UpdateQuestionnaireRequest) => {
    return clientPassword.post<UpdateQuestionnaireResponse, UpdateQuestionnaireRequest>(
        `/questionnaire_steps/`,
        arg
    );
};

export const fetchCreateQuestionnaireQuestion = (arg: UpdateQuestionnaireRequest) => {
    return clientPassword.post<UpdateQuestionnaireResponse, UpdateQuestionnaireRequest>(
        `/questions/`,
        arg
    );
};

export const fetchUpdateQuestionnaireQuestion = (arg: UpdateQuestionnaireRequest) => {
    return clientPassword.patch<UpdateQuestionnaireResponse, UpdateQuestionnaireRequest>(
        `/questions/${arg?.question_id}/`,
        arg?.request
    );
};

export const fetchUpdateQuestionnaireQuestionMany = (arg: UpdateQuestionnaireQuestionsRequest) => {
    return clientPassword.post<
        UpdateQuestionnaireQuestionsResponse,
        UpdateQuestionnaireQuestionsRequest
    >(`/questions/many/`, arg?.request);
};

export const fetchCreateQuestionnaires = (arg: UpdateQuestionnairesRequest) => {
    return clientPassword.post<UpdateQuestionnairesResponse, UpdateQuestionnairesRequest>(
        `/questionnaires/create-filled/`,
        arg?.request
    );
};

export const fetchUpdateQuestionnaires = (arg: UpdateQuestionnairesRequest) => {
    return clientPassword.post<UpdateQuestionnairesResponse, UpdateQuestionnairesRequest>(
        `/questionnaires/update-filled/`,
        arg?.request
    );
};

export const fetchUpdateQuestionnaireQuestionResultMany = (
    arg: UpdateQuestionnaireQuestionResultRequest
) => {
    return clientPassword.patch<
        UpdateQuestionnaireQuestionResultResponse,
        UpdateQuestionnaireQuestionResultRequest
    >(`/question_results/many/`, arg);
};

export const fetchCreateQuestionnaireQuestionResultMany = (
    arg: UpdateQuestionnaireQuestionResultRequest
) => {
    return clientPassword.post<
        UpdateQuestionnaireQuestionResultResponse,
        UpdateQuestionnaireQuestionResultRequest
    >(`/question_results/many/`, arg);
};
