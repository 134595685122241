import React, { useCallback, useEffect, useState } from 'react';
import styles from './AddressProgramDetails.module.scss';
import { useTranslation } from 'react-i18next';
import { Title, Header, BackLink, TableGrid } from '@components';
import { fetchProgramPramsList, fetchProgram } from '@apiFeature/addressProgram';
import { getProjectOutletsList } from '@apiFeature/projectOutlets';
import { useLocation, useParams } from 'react-router-dom';
import { formatters } from '@tools/utils/date.util';
import { fetchRetailsList } from '@apiFeature/retails';
import { fetchTerritoriesList } from '@apiFeature/territory';
import { Book } from '@navigation/Book';

const limit = 10;

const columns = [
    { field: 'external_code', headerName: 'Внешний код ТТ', minWidth: 150, sortable: false },
    { field: 'name', headerName: 'Название', minWidth: 100, flex: 1, sortable: false },
    { field: 'address', headerName: 'Адрес', minWidth: 500, flex: 1, sortable: false },
    { field: 'project_retail_name', headerName: 'Сеть', minWidth: 200, sortable: false },
    { field: 'territory_name', headerName: 'Территория', minWidth: 200, sortable: false },
    { field: 'fte', headerName: 'FTE', minWidth: 120 },
    { field: 'visits_per_month', headerName: 'Визитов в месяц', minWidth: 120 },
    { field: 'timing', headerName: 'Время на один визит', minWidth: 120 },
    { field: 'cost_per_hour', headerName: 'Стоимость одного часа', minWidth: 120 },
];

export const AddressProgramDetails = () => {
    const params = useParams();
    const { id: program_id } = params || {};

    const { state: program_name } = useLocation();

    const { t } = useTranslation('translation');

    const [program, setProgram] = useState({});
    const { name, start_time, end_time } = program || {};

    const [data, setData] = useState({});
    const { items = [], total = 0 } = data || {};

    const [isLoading, setLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: limit });
    const [sortModel, setSortModel] = useState([]);

    const getProgram = useCallback(() => {
        fetchProgram(program_id).then(({ program }) => {
            setProgram(program);
        });
    }, [program_id]);

    useEffect(() => {
        getProgram();
    }, []);

    const getProgramPramsList = useCallback(
        async (offset = 0, sortModel = []) => {
            setLoading(true);

            const [firstSort] = sortModel || [];
            const { field, sort } = firstSort || {};
            let sorting = {};
            if (field) sorting = { sorting: { [field]: sort } };

            const res = await fetchProgramPramsList({
                program_ids: [program_id],
                ...sorting,
                pagination: {
                    limit,
                    offset,
                },
            });
            const { program_params } = res || {};
            const { items: program_params_items } = program_params || {};

            let ids = program_params_items
                ?.filter((e) => e.project_outlet_id)
                .map((e) => e.project_outlet_id);
            ids = [...new Set(ids)];
            const project_outlets_items =
                ids && ids?.length > 0 ? await getProjectOutletsListData(ids) : [];

            let territory_ids = project_outlets_items
                ?.filter((e) => e.territory_id)
                .map((e) => e.territory_id);
            territory_ids = [...new Set(territory_ids)];
            const territory_items =
                territory_ids && territory_ids?.length > 0
                    ? await getTerritoriesListData(territory_ids)
                    : [];

            let project_retail_ids = project_outlets_items
                ?.filter((e) => e.project_retail_id)
                .map((e) => e.project_retail_id);
            project_retail_ids = [...new Set(project_retail_ids)];
            const project_retail_items =
                project_retail_ids && project_retail_ids?.length > 0
                    ? await getRetailsListData(project_retail_ids)
                    : [];

            const items_detail = project_outlets_items.map((e) => ({
                ...e,
                territory_name: territory_items.find((p) => p.id === e.territory_id)?.name,
                project_retail_name: project_retail_items.find((p) => p.id === e.project_retail_id)
                    ?.name,
            }));

            const items = program_params_items?.map((e) => ({
                ...e,
                ...items_detail.find((p) => p.id === e.project_outlet_id),
            }));

            setLoading(false);
            setData({ ...program_params, items });
        },
        [program_id]
    );

    const getProjectOutletsListData = async (ids) => {
        const { items } = await getProjectOutletsList({ ids });
        return items;
    };

    const getTerritoriesListData = async (ids) => {
        const res = await fetchTerritoriesList({ ids });
        return res;
    };

    const getRetailsListData = async (ids) => {
        const res = await fetchRetailsList({ ids });
        return res;
    };

    useEffect(() => {
        getProgramPramsList(paginationModel?.page * limit, sortModel);
    }, [sortModel, paginationModel, getProgramPramsList]);

    return (
        <div className={styles.addressProgramDetails}>
            <Header type="inner">
                <Title>
                    <BackLink link={Book.address_program} />
                    {name || program_name}
                </Title>
                <div className={styles.date}>
                    {start_time && (
                        <span>
                            Начало действия АП:{' '}
                            <b>{formatters.toISODateFormatString(start_time)}</b>
                        </span>
                    )}
                    {end_time && (
                        <span>
                            Окончание действия АП:{' '}
                            <b>{formatters.toISODateFormatString(end_time)}</b>
                        </span>
                    )}
                </div>
            </Header>
            <TableGrid
                rows={items}
                columns={columns}
                rowCount={total}
                loading={isLoading}
                pageSizeOptions={[limit]}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                paginationModel={paginationModel}
                sortingMode="server"
                onSortModelChange={setSortModel}
                disableRowSelectionOnClick
                hasSun
            />
        </div>
    );
};
