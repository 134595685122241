import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Sum } from '@components';
import { Cell, Column, ColumnHeaderCell, Table2, TableLoadingOption } from '@blueprintjs/table';
import '@blueprintjs/table/lib/css/table.css';
import styles from './TableBlueprint.module.scss';

interface ITableBlueprint {
    className?: string | undefined;
    rows: [];
    columns: [];
    rowCount: number;
    isLoading?: boolean;
    hasSun?: boolean | undefined;
    renderBodyContextMenu?: ({ context, rows, columns }) => React.JSX.Element;
    clickContextMenu?: (data) => void;
}

const TableBlueprint: FC<ITableBlueprint> = ({
    rows,
    columns,
    className = '',
    rowCount,
    hasSun,
    isLoading,
    renderBodyContextMenu,
    clickContextMenu,
    ...props
}) => {
    const renderColumnHeader = (index: number) => {
        const { headerName, field } = columns?.[index] || {};
        return <ColumnHeaderCell index={index} name={headerName || field} />;
    };

    const handleContextMenu = (e) => {
        const selection = e.target.getBoundingClientRect();
        const getBoundingClientRect = () => selection;
        clickContextMenu && clickContextMenu({ getBoundingClientRect, nodeType: 1 });
    };

    const renderColumnCell = (rowIndex: number, columnIndex: number) => {
        const { field, renderCell } = columns?.[columnIndex] || {};
        const row = rows?.[rowIndex];
        const name = row?.[field];

        return (
            <Cell className={styles.cell} tooltip={name}>
                <div
                    onContextMenu={clickContextMenu && handleContextMenu}
                    className={styles.cellContent}
                >
                    {renderCell ? renderCell({ row }) : name}
                </div>
            </Cell>
        );
    };

    const [columnsState, setColumns] = useState([]);
    const [columnWidths, setColumnWidths] = useState<Array<number | null | undefined>>();

    useEffect(() => {
        if (columns?.length > 0) {
            setColumns(columns);
            setColumnWidths(columns.map((e) => e.minWidth));
        }
    }, [columns]);

    const onColumnWidthChanged = (index, size) => {
        if (columnWidths) {
            columnWidths[index] = size;
            setColumnWidths(columnWidths);
        }
    };

    return (
        <div className={classNames(styles.data, className)}>
            {hasSun && (
                <div className={styles.info}>
                    <Sum total={rowCount} shown={rows?.length} />
                </div>
            )}
            <Table2
                className={styles.table}
                numRows={rows?.length || 1}
                bodyContextMenuRenderer={(context) =>
                    renderBodyContextMenu && renderBodyContextMenu({ context, rows, columns })
                }
                loadingOptions={isLoading ? [TableLoadingOption.CELLS] : []}
                columnWidths={columnWidths}
                onColumnWidthChanged={onColumnWidthChanged}
                defaultRowHeight={40}
                {...props}
            >
                {columnsState.map((_, i) => (
                    <Column
                        key={i}
                        cellRenderer={renderColumnCell}
                        columnHeaderCellRenderer={renderColumnHeader}
                    />
                ))}
            </Table2>
        </div>
    );
};

export default TableBlueprint;
