import localforage from 'localforage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import app from '../redux/app/reducer';
import questionnaireAssignment from './questionnaires/reducer';
import fakeVisit from './fakeVisit/reducer';
import importProcess from './importProcess/reducer';
import projectsNew from './projects/reducer';
import crowd from './crowd/reducer';
import checker from './checker/reducer';
import feed from './feeds/reducer';
import filters from './filters/reducer';
import settings from './settings/reducer';
import auth from './auth/reducer';

const authPersistConfig = {
    storage: localforage,
    key: 'root',
    whitelist: ['auth', 'user'],
};
const rootReducer = combineReducers({
    app,
    auth,
    questionnaireAssignment,
    fakeVisit,
    importProcess,
    projectsNew,
    feed,
    crowd,
    checker,
    filters,
    settings,
});

export default persistReducer(authPersistConfig, rootReducer);
