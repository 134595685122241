import { createSlice } from '@reduxjs/toolkit';
import { reduxStoreName as name, FiltersState } from './types';
import { FiltersThunks } from './thunk';
import { isErrorApi } from '../../apiFeature/types';

const initialState: FiltersState = {
    filters: {},
    citiesLoading: false,
    citiesErrMessage: '',
    cities: [],
    retailsLoading: false,
    retailsErrMessage: '',
    retails: [],
    branchesLoading: false,
    branchesErrMessage: '',
    branches: [],
    territoryLoading: false,
    territoryErrMessage: '',
    territory: [],
    usersLoading: false,
    usersErrMessage: '',
    users: [],
    managers: [],
    managersLoading: false,
    managersErrMessage: '',
    visitsFailResultsLoading: false,
    visitsFailResultsErrMessage: '',
    visitsFailResults: [],
    outletsLoading: false,
    outletsErrMessage: '',
    projectOutlets: [],
    periodList: [],
    periodsLoading: false,
    periodsErrMessage: '',
    outletsCrowdLoading: false,
    outletsCrowdErrMessage: '',
    projectOutletsCrowd: [],
    supportRequestTypes: [],
    srTypesLoading: false,
    srTypesErrMessage: '',
    roles: [],
    rolesErrMessage: '',
    rolesLoading: false,
    clients: [],
    clientsErrMessage: '',
    clientsLoading: false,
    userProjects: {},
    userProjectsLoading: false,
    userProjectErr: '',
    managerProjects: {},
    managerProjectsLoading: false,
    managerProjectErr: '',
    tagsProjects: {},
    tagsProjectsLoading: false,
    tagsProjectErr: '',
};

const slice = createSlice({
    name,
    initialState: { ...initialState },
    reducers: {
        reset() {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(FiltersThunks.setFilters.fulfilled, (state, { payload }) => {
            state.filters = { ...state.filters, ...payload };
        });
        builder
            .addCase(FiltersThunks.getCitiesList.pending, (state) => {
                state.citiesLoading = true;
                state.cities = [];
            })
            .addCase(FiltersThunks.getCitiesList.fulfilled, (state, { payload }) => {
                state.citiesLoading = false;
                state.cities = payload || [];
            })
            .addCase(FiltersThunks.getCitiesList.rejected, (state, { payload }) => {
                state.citiesLoading = false;
                state.cities = [];
                state.citiesErrMessage =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.getRetailsList.pending, (state) => {
                state.retailsLoading = true;
                state.retails = [];
            })
            .addCase(FiltersThunks.getRetailsList.fulfilled, (state, { payload }) => {
                state.retailsLoading = false;
                state.retails = payload || [];
            })
            .addCase(FiltersThunks.getRetailsList.rejected, (state, { payload }) => {
                state.retailsLoading = false;
                state.retails = [];
                state.retailsErrMessage =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.getBranchesList.pending, (state) => {
                state.branchesLoading = true;
                state.branches = [];
            })
            .addCase(FiltersThunks.getBranchesList.fulfilled, (state, { payload }) => {
                state.branchesLoading = false;
                state.branches = payload || [];
            })
            .addCase(FiltersThunks.getBranchesList.rejected, (state, { payload }) => {
                state.branchesLoading = false;
                state.branches = [];
                state.branchesErrMessage =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.getTerritoryList.pending, (state) => {
                state.territoryLoading = true;
                state.territory = [];
            })
            .addCase(FiltersThunks.getTerritoryList.fulfilled, (state, { payload }) => {
                state.territoryLoading = false;
                state.territory = payload || [];
            })
            .addCase(FiltersThunks.getTerritoryList.rejected, (state, { payload }) => {
                state.territoryLoading = false;
                state.territory = [];
                state.territoryErrMessage =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.getUsersList.pending, (state) => {
                state.usersLoading = true;
                state.users = [];
            })
            .addCase(FiltersThunks.getUsersList.fulfilled, (state, { payload }) => {
                state.usersLoading = false;
                state.users = payload || [];
            })
            .addCase(FiltersThunks.getUsersList.rejected, (state, { payload }) => {
                state.usersLoading = false;
                state.users = [];
                state.usersErrMessage =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.getUsersManagersList.pending, (state) => {
                state.managersLoading = true;
                state.managers = [];
            })
            .addCase(FiltersThunks.getUsersManagersList.fulfilled, (state, { payload }) => {
                state.managersLoading = false;
                state.managers = payload || [];
            })
            .addCase(FiltersThunks.getUsersManagersList.rejected, (state, { payload }) => {
                state.managersLoading = false;
                state.managers = [];
                state.managersErrMessage =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.getManagersList.pending, (state) => {
                state.managersLoading = true;
                state.managers = [];
            })
            .addCase(FiltersThunks.getManagersList.fulfilled, (state, { payload }) => {
                state.managersLoading = false;
                state.managers = payload || [];
            })
            .addCase(FiltersThunks.getManagersList.rejected, (state, { payload }) => {
                state.managersLoading = false;
                state.managers = [];
                state.managersErrMessage =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.getVisitsFailResultsOptions.pending, (state) => {
                state.visitsFailResultsLoading = true;
                state.visitsFailResults = [];
            })
            .addCase(FiltersThunks.getVisitsFailResultsOptions.fulfilled, (state, { payload }) => {
                state.visitsFailResultsLoading = false;
                state.visitsFailResults = payload || [];
            })
            .addCase(FiltersThunks.getVisitsFailResultsOptions.rejected, (state, { payload }) => {
                state.visitsFailResultsLoading = false;
                state.visitsFailResults = [];
                state.visitsFailResultsErrMessage =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.fetchProjectOutletsList.pending, (state) => {
                state.outletsLoading = true;
                state.projectOutlets = [];
            })
            .addCase(FiltersThunks.fetchProjectOutletsList.fulfilled, (state, { payload }) => {
                state.outletsLoading = false;
                state.projectOutlets = payload?.items || [];
            })
            .addCase(FiltersThunks.fetchProjectOutletsList.rejected, (state, { payload }) => {
                state.outletsLoading = false;
                state.projectOutlets = [];
                state.outletsErrMessage =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.getPeriodList.pending, (state) => {
                state.periodsLoading = true;
                state.periodList = [];
            })
            .addCase(FiltersThunks.getPeriodList.fulfilled, (state, { payload }) => {
                state.periodsLoading = false;
                state.periodList = payload?.periods?.items || [];
            })
            .addCase(FiltersThunks.getPeriodList.rejected, (state, { payload }) => {
                state.periodsLoading = false;
                state.periodList = [];
                state.periodsErrMessage =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.getOutletsListForCrowd.pending, (state) => {
                state.outletsCrowdLoading = true;
                state.projectOutletsCrowd = [];
            })
            .addCase(FiltersThunks.getOutletsListForCrowd.fulfilled, (state, { payload }) => {
                state.outletsCrowdLoading = false;
                state.projectOutletsCrowd = payload || [];
            })
            .addCase(FiltersThunks.getOutletsListForCrowd.rejected, (state, { payload }) => {
                state.outletsCrowdLoading = false;
                state.projectOutletsCrowd = [];
                state.outletsCrowdErrMessage =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.getExecutorsForCrowdFilter.pending, (state) => {
                state.usersLoading = true;
                state.users = [];
            })
            .addCase(FiltersThunks.getExecutorsForCrowdFilter.fulfilled, (state, { payload }) => {
                state.usersLoading = false;
                state.users = payload || [];
            })
            .addCase(FiltersThunks.getExecutorsForCrowdFilter.rejected, (state, { payload }) => {
                state.usersLoading = false;
                state.users = [];
                state.usersErrMessage =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.getSupportRequestTypes.pending, (state) => {
                state.srTypesLoading = true;
                state.supportRequestTypes = [];
            })
            .addCase(FiltersThunks.getSupportRequestTypes.fulfilled, (state, { payload }) => {
                state.srTypesLoading = false;
                state.supportRequestTypes = payload?.support_request_types || [];
            })
            .addCase(FiltersThunks.getSupportRequestTypes.rejected, (state, { payload }) => {
                state.srTypesLoading = false;
                state.supportRequestTypes = [];
                state.srTypesErrMessage =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.getRolesThunk.pending, (state) => {
                state.rolesLoading = true;
                state.roles = [];
            })
            .addCase(FiltersThunks.getRolesThunk.fulfilled, (state, { payload }) => {
                state.rolesLoading = false;
                state.roles = payload?.roles || [];
            })
            .addCase(FiltersThunks.getRolesThunk.rejected, (state, { payload }) => {
                state.rolesLoading = false;
                state.roles = [];
                state.rolesErrMessage =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.getClientsThunk.pending, (state) => {
                state.clientsLoading = true;
                state.clients = [];
            })
            .addCase(FiltersThunks.getClientsThunk.fulfilled, (state, { payload }) => {
                state.clientsLoading = false;
                state.clients = payload || [];
            })
            .addCase(FiltersThunks.getClientsThunk.rejected, (state, { payload }) => {
                state.clientsLoading = false;
                state.clients = [];
                state.clientsErrMessage =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.getUserProjectExecutors.pending, (state) => {
                state.userProjectsLoading = true;
            })
            .addCase(FiltersThunks.getUserProjectExecutors.fulfilled, (state, { payload }) => {
                state.userProjectsLoading = false;
                const { allUsers, project } = payload;
                state.userProjects = {
                    ...state.userProjects,
                    [project]: allUsers,
                };
            })
            .addCase(FiltersThunks.getUserProjectExecutors.rejected, (state, { payload }) => {
                state.userProjectsLoading = false;
                state.userProjects = {};
                state.userProjectErr =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.getManagerProjectExecutors.pending, (state) => {
                state.managerProjectsLoading = true;
            })
            .addCase(FiltersThunks.getManagerProjectExecutors.fulfilled, (state, { payload }) => {
                state.managerProjectsLoading = false;
                const { allUsers, project } = payload;
                state.managerProjects = {
                    ...state.managerProjects,
                    [project]: allUsers,
                };
            })
            .addCase(FiltersThunks.getManagerProjectExecutors.rejected, (state, { payload }) => {
                state.managerProjectsLoading = false;
                state.managerProjects = {};
                state.managerProjectErr =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FiltersThunks.getTagsProject.pending, (state) => {
                state.tagsProjectsLoading = true;
            })
            .addCase(FiltersThunks.getTagsProject.fulfilled, (state, { payload }) => {
                state.tagsProjectsLoading = false;
                const { tags, project, type } = payload;
                state.tagsProjects = {
                    ...state.tagsProjects,
                    [project]: { ...state.tagsProjects?.[project], [type]: tags },
                };
            })
            .addCase(FiltersThunks.getTagsProject.rejected, (state, { payload }) => {
                state.tagsProjectsLoading = false;
                state.tagsProjects = {};
                state.tagsProjectErr =
                    (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
            });
    },
});

export const reducer = slice.reducer;
