import React from "react";
import styles from './RenderEditCell.module.scss';
import { Select, Switch } from '@components';
import { getOptions } from '@tools/utils/functions';
import { settingOptionsNames } from '@apiFeature/settings/types';

export const renderCellInput = (args) => {
    const {
        setting,
        currentValue,
        registerStrValue,
        registerStrOptionId,
        setValue,
        settingsOptions,
        setting_kind,
        getSettingsOptions,
        setIsButtonsVisible
    } = args;
    const { setting_id } = setting || {};
    const currentSettingOptions = settingsOptions?.[setting_id];
    let disabled = currentValue?.setting_option_id === null ? true : false;

    if (setting_kind === 'BOOLEN') {
        const checked = currentValue?.setting_value === 'TRUE' || false;
        const handleChangeSwitch = (e) => {
            setIsButtonsVisible(true);
            const resultValue = e.target.checked ? 'TRUE' : 'FALSE';
            if (!currentSettingOptions?.length) {
                getSettingsOptions(setting_id).then(res => {
                    const { setting_options } = res?.payload || {};
                    if (setting_options?.length > 0) {
                        const currentStrOption = setting_options.find(opt => opt.value === resultValue);
                        const { id: currentStrOptionId } = currentStrOption || {};
                        if (currentStrOptionId) {
                            setValue(registerStrOptionId, currentStrOptionId);
                        } else {
                            disabled = true;
                        }
                    }
                })
            } else {
                const currentStrOption = currentSettingOptions.find(opt => opt.value === resultValue);
                const { id: currentStrOptionId } = currentStrOption || {};
                if (currentStrOptionId) {
                    setValue(registerStrOptionId, currentStrOptionId);
                }
            };
            setValue(registerStrValue, resultValue);
        }
        return (
            <Switch
                checked={checked || false}
                className={styles.switch}
                disabled={disabled}
                onChange={(e) => { handleChangeSwitch(e) }}
            />
        )
    };
    if (setting_kind === 'RIGHTS') {
        let options = [];
        if (currentSettingOptions?.length > 0) {
            const optionsWithNames = currentSettingOptions?.map(opt => ({
                ...opt,
                label: settingOptionsNames?.[opt?.value]
            }))
            options = getOptions(optionsWithNames, 'label');
        };

        const hadleChangeSelect = (e) => {
            setIsButtonsVisible(true);
            setValue(registerStrOptionId, e.value);
            const currentStrOption = currentSettingOptions.find(opt => opt.id === e.value);
            const { value } = currentStrOption || {};
            if (value) {
                setValue(registerStrValue, value);
            }
        }
        return (
            <div className={styles.selectInput}>
                <Select
                    options={options}
                    value={options?.find((e) => e.value === currentValue?.setting_option_id)}
                    onChange={(e) => { hadleChangeSelect(e) }}
                    menuPosition={'absolute'}
                />
            </div>
        )
    }
}