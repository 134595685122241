import React, { useState } from 'react';
import styles from './ReportsHeader.module.scss';
import { useTranslation } from 'react-i18next';
import { ToggleButtonGroup, Button, Loader } from '@components';
import {
    exportQuestionnaireReport,
    exportVisitsReport,
    exportCrowdReport,
    exportCrowdQuestionnairesReport,
} from '@apiFeature/reports';
import { useSnackbar } from 'notistack';
import { fixTimezoneOffset } from '@tools/utils/date.util';
import { useRoles } from '@tools/hooks/useRoles';
import { useURLFilters } from '@tools/hooks/useURLFilters';

const ReportsHeader = ({ form, filters, defaultValues }) => {
    const [isLoading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { setValue, watch } = form;
    const currValues = watch();
    const { kind: curKind } = { ...defaultValues, ...filters };
    const kind = ['VISITS', 'VISITS_WITH_QUESTIONNAIRES', 'CROWD_TASKS', 'CROWD_QUESTIONNAIRES'].some(
        (ee) => ee === curKind
    )
        ? curKind
        : 'VISITS';

    const {
        project_id,
        visit_date: { start, end } = {},
        visit_status,
        project_outlet_additional_projects,
        project_outlet_outlet_types,
        project_retail_ids,
        branch_ids,
        ...otherValues
    } = currValues;

    const { t } = useTranslation('translation');
    const { setURLFilters } = useURLFilters();

    const handleChange = (_, value: string) => {
        if (value !== null) {
            setValue('kind', value);
            setURLFilters({ value }, 'kind');
        }
    };

    const { isClient, isAdmin, isQuestionnaireReport, isVisitReport, isCrowdReport, isCrowd, isVisitReportAndCrowd } =
        useRoles();

    const handleGetVisitExportFileRes = (res) => {
        if (res) {
            setLoading(false);
            enqueueSnackbar(t('messages.takenToProcess'), { variant: 'success' });
            setValue('export_process_id', res.export_process?.id);
        }
    };

    const handleGetVisitExportFileErr = (e) => enqueueSnackbar(e?.message, { variant: 'error' });

    const handleGetVisitExportFile = () => {
        if (!(project_id && start && end)) {
            enqueueSnackbar(t('messages.projectAndDateRequired'), { variant: 'error' });
            return;
        }

        setLoading(true);
        const isKindCrowd = ['CROWD_TASKS', 'CROWD_QUESTIONNAIRES'].some(((ee) => ee === kind));
        const data = {
            ...otherValues,
            project_id,
            [isKindCrowd ? 'task_result_date_start_lower' : 'visit_date_start_lower']:
                fixTimezoneOffset(start, 'start'),
            [isKindCrowd ? 'task_result_date_start_upper' : 'visit_date_start_upper']:
                fixTimezoneOffset(end, 'end'),
            project_outlet_outlet_types: project_outlet_outlet_types
                ? [project_outlet_outlet_types]
                : '',
            project_outlet_additional_projects: project_outlet_additional_projects
                ? [project_outlet_additional_projects]
                : '',
            project_retail_ids: project_retail_ids ? [project_retail_ids] : '',
            branch_ids: branch_ids ? [branch_ids] : '',
            project_outlet_branch_ids: branch_ids ? [branch_ids] : '',
        };
        if (kind === 'VISITS_WITH_QUESTIONNAIRES') {
            exportQuestionnaireReport(data)
                .then((res) => handleGetVisitExportFileRes(res))
                .catch((e) => handleGetVisitExportFileErr(e));
        } else if (kind === 'CROWD_TASKS') {
            exportCrowdReport(data)
                .then((res) => handleGetVisitExportFileRes(res))
                .catch((e) => handleGetVisitExportFileErr(e));
        } else if (kind === 'CROWD_QUESTIONNAIRES') {
            exportCrowdQuestionnairesReport(data)
                .then((res) => handleGetVisitExportFileRes(res))
                .catch((e) => handleGetVisitExportFileErr(e));
        } else {
            exportVisitsReport({
                ...data,
                visit_status: isClient && !visit_status ? 'completed' : visit_status,
            })
                .then((res) => handleGetVisitExportFileRes(res))
                .catch((e) => handleGetVisitExportFileErr(e));
        }
    };

    let elements = [
        { value: 'VISITS', label: t('reports.reports.visit') },
        { value: 'VISITS_WITH_QUESTIONNAIRES', label: t('reports.reports.questionnaire') },
        { value: 'CROWD_TASKS', label: t('reports.reports.crowd') },
        { value: 'CROWD_QUESTIONNAIRES', label: t('reports.reports.crowdQuestionnaires') },
    ];

    if (!isVisitReport && !isAdmin && isClient) {
        elements = elements.filter((e) => e.value !== 'VISITS');
    }
    if (!isQuestionnaireReport && !isAdmin && isClient) {
        elements = elements.filter((e) => e.value !== 'VISITS_WITH_QUESTIONNAIRES');
    }
    if ((!isCrowdReport && !isVisitReportAndCrowd) || (!isCrowd && !isAdmin)) {
        elements = elements.filter((e) => e.value !== 'CROWD_TASKS');
    }
    if (!(isAdmin || (isCrowdReport && isCrowd))) {
        elements = elements.filter((e) => e.value !== 'CROWD_QUESTIONNAIRES');
    }

    return (
        <div className={styles.renderHeader}>
            {isLoading && <Loader />}
            <ToggleButtonGroup
                color="secondary"
                value={kind}
                exclusive
                onChange={handleChange}
                elements={elements}
            />
            <Button color="secondary" variant="contained" onClick={handleGetVisitExportFile}>
                {t('buttons.exportReport')}
            </Button>
        </div>
    );
};

export default ReportsHeader;
