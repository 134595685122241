import React from 'react';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './QuestionnaireEditNav.module.scss';

const QuestionnaireEditNav: FC<{ pollId?: string }> = ({ pollId }) => {
    const { t } = useTranslation('translation');

    return (
        <div className={styles.links}>
            <NavLink to="" end className={styles.link}>
                {t('questionnaires.common')}
            </NavLink>
            {pollId && (
                <>
                    <NavLink to="outlets" className={styles.link}>
                        {t('questionnaires.outlets')}
                    </NavLink>
                </>
            )}
        </div>
    );
};

export default QuestionnaireEditNav;
