import React from 'react';

function DropIcon({ color = '#414152' }) {
    return (
        <svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5625 15C14.5625 18.5875 11.5875 21.5625 8 21.5625C4.4125 21.5625 1.4375 18.5875 1.4375 15C1.4375 11.4125 8 1.4375 8 1.4375C8 1.4375 14.5625 11.4125 14.5625 15Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export default DropIcon;