import React, { FC } from 'react';
import styles from './QuestionList.module.scss';
import { TQuestion } from '@apiFeature/questionnaires/type';
import QItem from './QuestionItem/QItem';

interface IQuestionList {
    questions?: TQuestion[];
    registerStr: string;
}

const QuestionList: FC<IQuestionList> = ({ questions, registerStr }) => {
    return (
        <div>
            {questions?.length &&
                questions?.map((question) => (
                    <QItem key={question?.id} question={question} registerStr={registerStr} />
                ))}
        </div>
    );
};

export default QuestionList;
