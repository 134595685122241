import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './Sum.module.scss';

interface ISum {
    total: number | undefined;
    shown?: number | undefined;
    secured?: number | undefined;
    className?: string | undefined;
}

const Sum: FC<ISum> = ({ total, shown, secured, className }) => {
    const { t } = useTranslation('translation');
    return (
        <div className={classNames(styles.sum, className)}>
            <span className={styles.sumItem}>
                {t('common.total')}: <b>{total}</b>
            </span>
            {shown && (
                <span className={styles.sumItem}>
                    {t('common.shown')}: <b>{shown}</b>
                </span>
            )}
            {secured ? (
                <span className={styles.sumItem}>
                    {t('common.secured')}: <b>{secured}</b>
                </span>
            ) : (
                ''
            )}
        </div>
    );
};

export default Sum;
