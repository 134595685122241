import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, TextField } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import { getOptions } from '@tools/utils/functions';
import { statusOptionsForQuestionnaires, kindOptionsForQuestionnaires } from '@constants';

const QuestionnairesFilters = ({ form }) => {
    const dispatch = useDispatch();
    const { projectsList, loading: loadingProjects } = useSelector(
        ProjectsSelectors.projectsSelectors
    );
    const { t } = useTranslation('translation');

    const projectsListId = projectsList?.[0]?.id;
    const { watch, setValue } = form;
    const projectId = watch('project_id');
    const kind = watch('kind');
    const status = watch('status');

    const projectValue = projectId || projectsListId;

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    useEffect(() => {
        getProjects();
    }, [getProjects]);

    const onChange = (name, e) => {
        setValue(name, e?.value);
    };

    const projectOptions = getOptions(projectsList, 'full_name');

    const filters = [
        {
            type: Select,
            name: 'project_id',
            label: t('common.project'),
            onChange,
            value: projectValue
                ? projectOptions?.find((e) => e.value === projectValue)
                : projectOptions?.[0],
            options: projectOptions,
            isLoading: loadingProjects,
        },
        {
            type: TextField,
            name: 'external_code',
            label: t('questionnaires.externalCode'),
        },
        {
            type: Select,
            name: 'kind',
            label: t('feed.type'),
            onChange,
            value: kindOptionsForQuestionnaires?.find((e) => e.value === kind),
            options: kindOptionsForQuestionnaires,
            isSearchable: false
        },
        {
            type: Select,
            name: 'status',
            label: t('common.status'),
            placeholder: t('common.all'),
            onChange,
            value: statusOptionsForQuestionnaires?.find((e) => e.value === status),
            options: statusOptionsForQuestionnaires,
            isClearable: true,
        },
    ];

    return { filters };
};

export default QuestionnairesFilters;