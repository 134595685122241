import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/tools/hooks/redux';
import { authRefreshToken } from '../../../apiFeature/login';
import { getRefreshTokenRef, setRefreshTokenRef } from '../../../api/globalRefresh';
import {
    ACCESS_EXPIRES,
    ACCESS_TOKEN,
    REFRESH_EXPIRES,
    REFRESH_TOKEN,
} from '../../../api/api.constant';
import moment from 'moment';
import AuthActions from '@redux/auth/actions';

const getMillisecondsUpdate = (expires_at) => {
    const currentDate = moment(new Date().toISOString().split('.')[0]);
    const accessTokenDate = moment(expires_at.split('.')[0]).subtract(30, 'second');
    const millisecondsUpdate = accessTokenDate.diff(currentDate);
    return millisecondsUpdate;
};

export const AutoUpdateRefreshToken: React.FC = () => {
    const { accessToken, refreshToken, user } = useAppSelector((state) => state.auth);
    const [millisecondsUpdate, setMillisecondsUpdate] = useState(
        getMillisecondsUpdate(accessToken?.expires_at)
    );
    const [accessExpiresAt, setAccessExpiresAt] = useState('');

    const dispatch = useAppDispatch();

    useEffect(() => {
        const timer = setTimeout(() => {
            let refreshTokenRef = getRefreshTokenRef();

            const refresh_token = refreshToken?.token;
            if (refreshTokenRef === null && refresh_token) {
                refreshTokenRef = authRefreshToken(refresh_token ?? '');
                setRefreshTokenRef(refreshTokenRef);
            }
            return refreshTokenRef
                ?.then((res) => {
                    dispatch(AuthActions.saveAuth({
                        tokens: res.tokens,
                        user
                    }));
                    localStorage.setItem(ACCESS_TOKEN, res.tokens.access.token);
                    localStorage.setItem(ACCESS_EXPIRES, res.tokens.access.expires_at);
                    localStorage.setItem(REFRESH_TOKEN, res.tokens.refresh.token);
                    localStorage.setItem(REFRESH_EXPIRES, res.tokens.refresh.expires_at);

                    setMillisecondsUpdate(getMillisecondsUpdate(res.tokens.access.expires_at));
                    setAccessExpiresAt(res.tokens.access.expires_at);
                })
                .catch((err) => {
                    if (err?.status === 401) {
                        dispatch(AuthActions.logout());
                    }
                    return Promise.reject(err);
                })
                .finally(() => {
                    setRefreshTokenRef(null);
                });
        }, millisecondsUpdate);
        return () => clearTimeout(timer);
    }, [accessExpiresAt]);

    return <></>;
};
