import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './ImportDialog.module.scss';
import { Select, Filters, Button } from '@components';
import { useTranslation } from 'react-i18next';
import { importFileKindNames } from '@apiFeature/importProcesses/types';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import deleteFileSvg from '@images/svg/close-card-button-inactive.svg';
import { downloadImportTemplate } from '@apiFeature/importProcesses';
import { findSubOption } from '../ImportsJournalFilters/ImportsJournalFilters';
import { importsKindOptions } from '@constants';

export const ImportDialog = ({
    selectedFile,
    setSelectedFile,
    projectForImport,
    kindForImport,
    setValue
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');

    const { projectsList, loading: loadingProjects } = useSelector(
        ProjectsSelectors.projectsSelectors
    );

    const filepicker = useRef<HTMLInputElement>(null);

    const onChange = (name, e) => {
        setValue(name, e?.value);
    };

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    useEffect(() => {
        getProjects();
    }, [getProjects]);

    useEffect(() => {
        if (projectsList.length > 0 && !projectForImport) {
            const projectsListId = projectsList?.[0]?.id;
            setValue('projectForImport', projectsListId)
        };
    }, [projectsList]);

    const projectOptions = projectsList?.map(({ full_name, id }) => ({
        label: full_name,
        value: id,
    }));

    const kindOptions = Object.keys(importFileKindNames).map((key) => {
        return {
            label: importFileKindNames[key],
            value: key,
        };
    });

    const downLoadTemplate = async () => {
        if (kindForImport) {
            const res = await downloadImportTemplate(kindForImport);
            if (res) {
                const href = URL.createObjectURL(new Blob([res], { type: 'application/xlsx' }));
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${importFileKindNames[kindForImport]}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }
        }
    };
    const handleSelectFile = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    const handleClickRef = () => {
        if (filepicker?.current) {
            filepicker.current.click();
        }
    };
    const handleDeleteFile = () => {
        setSelectedFile(null);
    };

    const handleKindChange = (e) => {
        setValue('kindForImport', e)
    }

    const filters = [
        {
            type: Select,
            name: 'projectForImport',
            label: t('common.project'),
            onChange,
            value: projectForImport
                ? projectOptions?.find((e) => e.value === projectForImport)
                : projectOptions?.[0],
            options: projectOptions,
            isLoading: loadingProjects,
        },
        {
            type: Select,
            name: 'kindForImport',
            label: t('importJournal.kind'),
            onChange: (_, e) => {handleKindChange(e)},
            value: findSubOption(kindForImport, importsKindOptions),
            options: importsKindOptions,
            isNested: true,
            isSearchable: false
        },
    ];

    return (
        <div className={styles.form}>
            <div className={styles.items}>
                <Filters
                    filters={filters}
                    className={styles.filters}
                />
                <div className={styles.selectFile}>
                    <Button
                        variant="outlined"
                        onClick={handleClickRef}
                        color="secondary"
                        disabled={!(projectForImport && kindForImport)}
                    >
                        {t('buttons.selectFile')}
                    </Button>
                    <input
                        type="file"
                        className={styles.hidden}
                        ref={filepicker}
                        onChange={handleSelectFile}
                        accept=".xls, .xlsx, .csv"
                    />
                    {!selectedFile && (
                        <p className={styles.allowedFormat}>
                            {t('importJournal.format')}
                        </p>
                    )}
                    {selectedFile && (
                        <div className={styles.selectedFile}>
                            {selectedFile?.name}
                            <div className={styles.deleteFile} onClick={handleDeleteFile}>
                                <img src={deleteFileSvg} alt="cancel" />
                            </div>
                        </div>
                    )}
                </div>

            </div>
            <div className={classNames(styles.items, styles.actionBtns)}>
                <Button
                    onClick={downLoadTemplate}
                    variant="outlined"
                    color="secondary"
                    disabled={!(projectForImport && kindForImport)}
                >
                    {t('buttons.downloadSample')}
                </Button>
                {!kindForImport && (
                    <p className={styles.allowedFormat}>
                        {t('messages.selectKindForTemplate')}
                    </p>
                )}
            </div>
        </div>
    )
}