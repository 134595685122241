import { t } from 'i18next';

export type FilesUploadRequest = {
    file: string | Blob;
    project_id: string | Blob;
};

export type BaseTypeEntity = {
    id: string;
    created_at: string;
    updated_at?: string | null;
};

export type QuestionnairesRequest = {
    date_in?: string;
    is_active?: boolean;
    outlet_id: string;
    project_id?: string;
    visit_ids?: string[];
};

export type TQuestionAnswer = BaseTypeEntity & { answer: string | null; is_default?: boolean };

export enum QuestionTypeKind {
    one_answer = 'one_answer',
    many_answers = 'many_answers',
    your_answer = 'your_answer',
    number_answer = 'number_answer',
    integer_answer = 'integer_answer',
    photo_answer = 'photo_answer',
}

export const questionnaireKindNames = {
    questionnaire: t('questionnaires.questionnaireType'),
    audit: t('feed.audit'),
} as const;

export type TFile = BaseTypeEntity & {
    bucket?: string | null;
    key?: string | null;
    name?: string | null;
    content_type?: string | null;
    checksum_md5?: string | null;
    url?: string | null;
    size_bytes?: number | null;
};

export type TFileLink = BaseTypeEntity & {
    file_id?: string | null;
    created_by?: string | null;
    generic_id?: string | null;
    generic_type?: string | null;
    tag?: string | null;
    is_primary: boolean;
    file?: TFile | null;
};

export type TQuestionResult = {
    question_id?: string | null;
    answer?: string[] | number | string | null | File;
};

export type TQuestion = BaseTypeEntity & {
    description: string | null;
    is_photo_required: boolean;
    is_required: boolean;
    kind: QuestionTypeKind;
    max_number: number | null;
    min_length: number | null;
    min_number: number | null;
    name: string | null;
    next_id: string | null;
    prev_id: string | null;
    sku_id: string | null;
    parent_criteria: string | null;
    parent_id: string | null;
    child_questions: string[];
    photo_quantity: number | null;
    question_answers: TQuestionAnswer[];
    question_result: TQuestionResult | null;
    barcode?: string | null;
    sku_photo?: string | null;
    questionnaire_step_id?: string | null;
    utc_offset?: number | null;
};

export type TQuestionnaire = BaseTypeEntity & {
    created_by?: string | null;
    updated_by?: string | null;
    is_active?: boolean;
    is_required?: boolean;
    description?: string | null;
    kind?: string;
    name?: string | null;
    external_code?: string;
    project_id?: string;
    period_lower?: string | null;
    period_upper?: string | null;
};

export type TQuestionnaireStep = BaseTypeEntity & {
    description: string | null;
    next_id: string | null;
    prev_id: string | null;
    title: string | null;
    questions: TQuestion[];
};

export type TQuestionnaireDetail = TQuestionnaire & {
    questionnaire_steps: TQuestionnaireStep[];
};

export type QuestionnairesResponse = {
    items: TQuestionnaire[];
    questionnaires: TQuestionnaire[];
    total: number;
    limit: number;
    offset: number;
};

export type QuestionnairesListRequest = {
    id?: string;
    project_id?: string;
    project_outlet_id?: string;
    name?: string;
    description?: string;
    external_code?: string;
    period_lower?: string;
    period_upper?: string;
    is_active?: boolean;
    date_in?: string;
    kind?: string;
    visit_id?: string;
    pagination?: { limit: number; offset: number };
};

export type QuestionnairesDetailedRequest = {
    project_id?: string;
    project_outlet_id?: string;
    date_in?: string;
    external_code?: string;
    is_active?: boolean;
    ids?: string[];
    visit_id?: string;
    pagination?: { limit: number; offset: number };
};

export type UpdateQuestionnaireRequest = {
    request: {
        external_code?: string;
        kind?: string;
        name?: string;
        description?: string;
        period_lower?: string;
        period_upper?: string;
        is_active?: boolean;
        is_required?: boolean;
        timing?: number;
        order?: number;
        utc_offset?: number;
    };
    questionnaire_id: string;
};

export type TQuestionnairesAnswer = {
    question_id?: string;
    visit_id?: string;
    answer?: string[] | string | undefined;
};

export type UpdateQuestionnaireResponse = { questionnaire: TQuestionnaire };

export type QuestionnairesDetailedResponse = { questionnaires: TQuestionnaireDetail[] };

export type QuestionnairesSubmitResponse = { question_results?: TQuestionResult[] };

export type UpdateQuestionnaireQuestionsRequest = { questions: TQuestion[] };

export type UpdateQuestionnaireQuestionResultRequest = { answers: TQuestionnairesAnswer[] };

export type UpdateQuestionnaireQuestionsResponse = UpdateQuestionnaireQuestionsRequest;

export type UpdateQuestionnaireQuestionResultResponse = QuestionnairesSubmitResponse;

export type UpdateQuestionnairesRequest = QuestionnairesListRequest & TQuestionnaireDetail;

export type UpdateQuestionnairesResponse = UpdateQuestionnairesRequest;
