import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { Title, Button } from '@components';
import { useFieldArray, useFormContext, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uniqid } from 'uuid';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import QuestionsItem from './QuestionsItem/QuestionsItem';
import styles from './Questions.module.scss';

const Questions: FC = () => {
    const [openedSteps, setOpenedSteps] = useState([]);
    const formMethods = useFormContext();
    const { control, formState } = formMethods || {};
    const { fields, remove, append, move } = useFieldArray({
        control,
        name: 'questionnaire_steps',
    });

    const { t } = useTranslation('translation');

    const toggleStep = (stepId) => {
        if (openedSteps.includes(stepId)) {
            setOpenedSteps(openedSteps.filter((step) => step !== stepId));
        } else {
            setOpenedSteps([...openedSteps, stepId]);
        }
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragEnd(event) {
        const { active, over } = event;
        if (active?.id !== over?.id) {
            const oldIndex = fields.findIndex((e) => e.id === active.id);
            const newIndex = fields.findIndex((e) => e.id === over.id);
            move(oldIndex, newIndex);
        }
    }

    return (
        <>
            <Title className={styles.title}>{t('common.questions')}</Title>
            <div className={styles.table}>
                <div className={styles.items}>
                    <div className={classNames(styles.item, styles.order)}>
                        {t('questionnaires.step')}
                    </div>
                    <div className={classNames(styles.item, styles.content)}>
                        {t('questionnaires.headerAndSubheading')}
                    </div>
                    <div className={classNames(styles.item, styles.questions)}>
                        {t('common.questions')}
                    </div>
                    <div className={classNames(styles.item, styles.actions)}>
                        {t('common.actions')}
                    </div>
                </div>
                <FormProvider {...formMethods}>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext items={fields} strategy={verticalListSortingStrategy}>
                            {fields?.length > 0 &&
                                fields?.map((step, index) => (
                                    <QuestionsItem
                                        key={step.id}
                                        stepId={step.id}
                                        toggle={toggleStep}
                                        removeStep={remove}
                                        openedSteps={openedSteps}
                                        registerStr={`questionnaire_steps[${index}]`}
                                        idx={index}
                                        isMoreOneField={fields?.length > 1}
                                        defaultValuesStep={
                                            formState.defaultValues?.questionnaire_steps?.[index] ||
                                            {}
                                        }
                                    />
                                ))}
                        </SortableContext>
                    </DndContext>
                </FormProvider>
                <div className={styles.btn}>
                    <Button
                        className={styles.textBtn}
                        onClick={() => append({ id: uniqid() })}
                        color="secondary"
                    >
                        {t('questionnaires.addStep').toLocaleUpperCase()}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default Questions;
