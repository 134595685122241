import React, { FC, useEffect, useState } from 'react';
import styles from './StepItem.module.scss';
import { TQuestionnaireStep } from '@apiFeature/questionnaires/type';
import { Collapse } from '@mui/material';
import { ReactComponent as ArrowIcon } from '@images/svg/chevron-down.svg';
import classNames from 'classnames';
import QuestionList from './QuestionList/QuestionList';

interface IStepItem {
    step?: TQuestionnaireStep;
    registerStr: string;
    expandedAll: boolean;
}

const StepItem: FC<IStepItem> = ({ step, registerStr, expandedAll }) => {
    const [expanded, setExpanded] = useState(expandedAll);
    const { questions, title, description } = step || {};

    useEffect(() => {
        setExpanded(expandedAll);
    }, [expandedAll]);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div className={styles.stepItem}>
            <div>
                <div className={styles.line} onClick={() => handleExpandClick()}>
                    <div
                        className={classNames(styles.expandIconWrapper, {
                            [styles.isOpen]: expanded,
                        })}
                    >
                        <ArrowIcon />
                    </div>
                    <div className={styles.titleWrapper}>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.description}>{description}</div>
                    </div>
                </div>
                <Collapse in={expanded} timeout={200}>
                    <QuestionList questions={questions} registerStr={registerStr} />
                </Collapse>
            </div>
        </div>
    );
};

export default StepItem;
