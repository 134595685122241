import React, { useEffect, useState } from 'react';
import styles from './Tasks.module.scss';
import { useTranslation } from 'react-i18next';
import { Layout } from '@components';
import { useSelector } from 'react-redux';
import SettingsSelectors from '@redux/settings/selectors';
import TasksHeader from './TasksHeader/TasksHeader';
import TasksData from './TasksData/TasksData';
import TasksFilters from './TasksFilters/TasksFilters';
import { useURLFilters } from '@tools/hooks/useURLFilters';

export const Tasks = () => {
    const { t } = useTranslation('translation');

    const { parseURL } = useURLFilters();

    const project_id = sessionStorage.getItem('project_id');
    const [defaultValues, setDefaultValues] = useState({});

    const { settingsAll, settingsAllUser: userSettings } = useSelector(SettingsSelectors.settingsState);
    const { settings } = settingsAll || {};
    const isEnableTaskWebClient =
        settings?.find((s) => s.code === 'is_enable_task_web_client')?.setting_values?.[0]?.val ===
        'true';
    const showMyTasksOnly =
        settings?.find((s) => s.code === 'show_my_tasks_only')?.setting_values?.[0]?.val === 'true';
    const isTasksAuthor =
        settings?.find((s) => s.code === 'client_tasks_author')?.setting_values?.[0]?.val ===
        'true';
    const isTasksAuthorFilter =
        settings?.find((s) => s.code === 'client_tasks_author_filter')?.setting_values?.[0]?.val ===
        'true';
    const isHideCreateTask = userSettings?.find(
        (s) => s.code === 'sfa_tasks_create_allowed_web_tasks'
    )?.setting_value === 'FALSE';
    const getFilters = (values) => {
        return values;
    };

    useEffect(() => {
        const filterValuesFromUrl = parseURL();
        setDefaultValues({
            project_id: project_id !== 'undefined' ? project_id : '',
            project_retail_ids: null,
            branch_ids: null,
            city_ids: null,
            external_code: '',
            description: '',
            execution_status: '',
            deadline: {
                deadline_lower: null,
                deadline_upper: null,
            },
            created: {
                created_lower: null,
                created_upper: null,
            },
            fact_date: {
                start_time: null,
                end_time: null,
            },
            user_ids: null,
            author_ids: null,
            is_photo_required: false,
            is_required: false,
            ...filterValuesFromUrl,
        });
    }, []);

    return Object.keys(defaultValues)?.length > 0 ? (
        <Layout
            title={t('tasks.title')}
            renderHeader={(props) => (
                <TasksHeader
                    {...props}
                    isEnableTaskWebClient={isEnableTaskWebClient}
                    isTaskCreateBlocked={isHideCreateTask}
                />
            )}
            renderSearch={() => ({})}
            renderData={(props) => (
                <TasksData
                    {...props}
                    isTaskCreateBlocked={isHideCreateTask}
                    showMyTasksOnly={showMyTasksOnly}
                    isTasksAuthor={isTasksAuthor}
                />
            )}
            renderFilters={(props) => TasksFilters({ ...props, isTasksAuthorFilter })}
            defaultValues={defaultValues}
            getFilters={getFilters}
        />
    ) : (
        <></>
    );
};
