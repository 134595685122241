import React from 'react';
import classNames from 'classnames';
import styles from './ListMapper.module.scss';

export interface iItems {
    [key: string]: {
        value: string;
        isVisible?: boolean;
    };
}

interface IListMapper {
    items: iItems;
    className?: string | undefined;
}

const ListMapper = ({ items, className }: IListMapper) => {
    return (
        <>
            {Object.keys(items).map((item) => {
                return (
                    items[item].isVisible && (
                        <div key={item} className={classNames(styles.item, className)}>
                            <div className={styles.key}>{item}:</div>
                            <div className={styles.value}>{items[item].value || '-'}</div>
                        </div>
                    )
                );
            })}
        </>
    );
};

export default ListMapper;
