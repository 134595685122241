import React, { FC } from 'react';
import { Pagination as Pages, PaginationItem } from '@mui/material';
import { ToggleButtonGroup } from '@components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useURLFilters } from '@tools/hooks/useURLFilters';
import { FilterValue } from '@apiFeature/types';
import styles from './Pagination.module.scss';

interface IPagination {
    className?: string | undefined;
    count?: number;
    hasNumbersPagination?: boolean;
    total?: number;
    offset?: number;
    limit: number;
    pageNumber?: number;
    isSearchParams?: boolean;
    onPaginationModelChange?: ({ offset, limit }) => void;
    setItemsPerPage?: (filter: FilterValue) => void;
}

const limitNumbers = [10, 20, 30];

const Pagination: FC<IPagination> = ({
    className,
    count,
    total = 0,
    offset = 0,
    limit = 10,
    pageNumber = 1,
    isSearchParams = true,
    hasNumbersPagination,
    onPaginationModelChange,
    setItemsPerPage,
    ...props
}) => {
    const { searchParams, setSearchParams } = useURLFilters();
    const { t } = useTranslation('translation');

    const totalPages = Math.ceil(total / limit);

    const handleChangePagination = (_, pageNumber) => {
        onPaginationModelChange &&
            onPaginationModelChange({
                offset: (pageNumber - 1) * limit,
                limit,
            });

        if (isSearchParams) {
            searchParams.set('page', btoa(pageNumber));
            setSearchParams(searchParams);
        }
    };

    const handleChangeItemsPerPage = (_, value) => {
        if (value) {
            const totalPages = Math.ceil(total / value);
            if (pageNumber && pageNumber > totalPages) {
                if (isSearchParams) searchParams.set('page', btoa(totalPages));
                onPaginationModelChange &&
                    onPaginationModelChange({
                        offset: (totalPages - 1) * limit,
                        limit: value,
                    });
            } else {
                const currentPageNumber = pageNumber || 1;
                onPaginationModelChange &&
                    onPaginationModelChange({
                        offset: (currentPageNumber - 1) * value,
                        limit: value,
                    });
            }
            setItemsPerPage &&
                setItemsPerPage({
                    label: value,
                    value,
                });

            if (isSearchParams) {
                searchParams.set('limit', btoa(value));
                setSearchParams(searchParams);
            }
        }
    };

    const optionsForLimit = limitNumbers
        .filter((num, index) => {
            if (index === 0) {
                return true;
            }
            return total + 10 > num;
        })
        .map((num) => ({
            label: num,
            value: num,
        }));

    return total !== 0 ? (
        <div className={classNames(styles.pagination, className)}>
            <div className={styles.limit}>
                <label htmlFor="limit">{t('feed.onPage')}:</label>
                <ToggleButtonGroup
                    id="limit"
                    color="secondary"
                    value={total < 10 ? 10 : limit}
                    exclusive
                    onChange={handleChangeItemsPerPage}
                    elements={optionsForLimit}
                />
            </div>
            <div className={styles.pages}>
                <Pages
                    count={totalPages}
                    page={Math.floor(offset / limit + 1 || 1)}
                    onChange={handleChangePagination}
                    shape="rounded"
                    variant="outlined"
                    color="secondary"
                    renderItem={(item) =>
                        (item?.type !== 'page' || hasNumbersPagination) && (
                            <PaginationItem {...item} />
                        )
                    }
                    {...props}
                />
            </div>
        </div>
    ) : (
        <></>
    );
};

export default Pagination;
