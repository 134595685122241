import React, { useEffect } from 'react';
import { EditInstanceLayout } from 'src/view/components/EditInstanceLayout';
import { useLocation, useNavigate, useNavigationType, useParams } from 'react-router-dom';
import ComplexForm from 'src/view/components/ComplexForm/ComplexForm';
import {
    AsyncSelectField,
    FormField,
    FormFieldType,
} from 'src/view/components/ComplexForm/ComplexForm.type';
import style from './style/EditCrowdPage.module.scss';
import { Checkbox } from 'src/view/components/ui/form/Checkbox/Checkbox';
import { TextAreaInput } from 'src/view/components/ui/TextAreaInput/TextAreaInput';
import { getProjectOutletsList } from '@apiFeature/projectOutlets';
import {
    createTask,
    deleteTask,
    getCrowdTaskComplexityList,
    getDetailedTaskList,
    getTaskTypeList,
    updateTask,
} from '@apiFeature/crowd';
import { fetchProjects } from '@api/projects/projects.api';
import { AsyncSelect } from 'src/view/components/ui/form/Select/AsyncSelect';
import { fetchQuestionnairesList } from '@apiFeature/questionnaires';
import { Select } from 'src/view/components/ui/form/Select/Select';
import { Book } from '@navigation/Book';
import { useTranslation } from 'react-i18next';

const requireFields = {
    task_type_id: true,
    task_complexity_id: true,
    project_outlet_id: true,
    questionnaire_id: true,
    start_date: true,
    end_date: true,
    description: true,
};
export const EditCrowdPage = () => {
    const location = useLocation();
    const isCreate = location.pathname.endsWith('create');
    const formRef = React.useRef(null);
    const navigate = useNavigate();
    const navigationType = useNavigationType();
    const { t } = useTranslation('translation');

    const [values, setValues] = React.useState<any>({
        is_photo_required: false,
    });
    const [errors, setErrors] = React.useState<any>({});

    const [questionnaires, setQuestionnaires] = React.useState<any[]>([]);
    const [projectOutlets, setProjectOutlets] = React.useState<any[]>([]);

    const { crowdId } = useParams<{ crowdId: string }>();
    const isWatchRoute = location.pathname.startsWith('/crowd/watch/');

    const handleBack = () => {
        if (navigationType === 'POP') {
            navigate(Book.crowd.crowd);
        } else {
            navigate(-1);
        }
    };
    const validate = () => {
        const newErrors = {};
        Object.keys(requireFields).forEach((key) => {
            if (requireFields[key] && !values[key]) {
                newErrors[key] = true;
            }
        });
        setErrors(newErrors);
        return newErrors;
    };
    const handleSubmit = () => {
        setErrors({});
        const dataToSend = {};
        Object.keys(values).forEach((key) => {
            if (values[key]?.value) {
                dataToSend[key] = values[key].value;
            } else {
                dataToSend[key] = values[key];
            }
        });
        const errors = validate();
        if (Object.keys(errors).length) {
            return;
        }
        if (crowdId) {
            updateTask(crowdId, dataToSend).then((data) => {});
        } else {
            createTask(dataToSend).then((data) => {});
        }
    };

    const handleDelete = () => {
        if (!crowdId) return;

        deleteTask(crowdId).then(() => {
            handleBack();
        });
    };
    useEffect(() => {
        if (!crowdId) return;
        getDetailedTaskList(crowdId).then((data) => {
            const { project_outlet, task_type, task_complexity } = data;
            const newData = {
                ...data,
                project_outlet_id: {
                    label: project_outlet?.name + ' ' + project_outlet?.address,
                    value: project_outlet?.id,
                },
                task_type_id: {
                    label: task_type?.name,
                    value: task_type?.id,
                },
                task_complexity_id: {
                    label: task_complexity?.name,
                    value: task_complexity?.id,
                },
            };
            setValues(newData);
            formRef.current?.resetForm(newData);
        });
    }, [crowdId]);

    useEffect(() => {
        if (!values.counterparty?.value) return;

        setValues({ ...values, questionnaire_id: null });

        fetchQuestionnairesList({ project_id: values.counterparty?.value }).then((data) => {
            setQuestionnaires(
                data.questionnaires.map((item: any) => ({ label: item.name, value: item.id }))
            );
        });

        setValues({ ...values, project_outlet_id: null });

        getProjectOutletsList({
            project_id: values.counterparty?.value,
        }).then((data) => {
            setProjectOutlets(
                data.items.map((item: any) => ({
                    label: item.name + ' ' + item.address,
                    value: item.id,
                }))
            );
        });
    }, [values.counterparty?.value]);

    const fields: FormField[] = [
        {
            name: 'task_type_id',
            label: t('crowd.task_type'),
            wrapperClassName: errors.task_type_id ? style.error : '',
            type: FormFieldType.AsyncSelect,
            mapper: (data: any) => ({ label: data?.name, value: data?.id }),
            request: (inputValue) => getTaskTypeList(inputValue),
        } as AsyncSelectField,

        {
            type: FormFieldType.TextInput,
            label: t('crowd.price'),
            name: 'price',
            wrapperClassName: style.priceField,
        },
        {
            type: FormFieldType.TextInput,
            label: t('questionnaires.duration'),
            name: 'timing',
        },
        {
            name: 'task_complexity_id',
            label: t('crowd.task_complexity'),
            wrapperClassName: errors.task_complexity_id ? style.error : '',
            type: FormFieldType.AsyncSelect,
            mapper(data: any) {
                return {
                    label: data?.name,
                    value: data?.id,
                };
            },
            request: (inputValue) => getCrowdTaskComplexityList(inputValue),
        } as AsyncSelectField,

        {
            type: FormFieldType.Date,
            label: t('crowd.startDate'),
            name: 'start_date',
            wrapperClassName: errors.start_date ? style.error : '',
        },
        {
            type: FormFieldType.Date,
            label: t('crowd.endDate'),
            name: 'end_date',
            wrapperClassName: errors.end_date ? style.error : '',
        },
        {
            type: FormFieldType.TextInput,
            label: t('crowd.sequenceNumber'),
            name: 'order',
        },
    ];

    return (
        <EditInstanceLayout
            title="Crowd"
            onClickBackButton={handleBack}
            tabsButtons={[
                {
                    title: t('questionnaires.details'),
                    to: 'details',
                    isActive: true,
                },
            ]}
            saveButton={{
                title: isWatchRoute ? '' : t('buttons.save'),
                onClick: handleSubmit,
            }}
            cancelButton={{
                title: t('buttons.cancel'),
                onClick: () => {},
            }}
            deleteButton={{
                title: isWatchRoute || isCreate ? '' : t('buttons.delete'),
                onClick: handleDelete,
            }}
        >
            <div className={style.content}>
                <AsyncSelect
                    name="counterparty"
                    label={t('common.project')}
                    onChange={(val) => setValues({ ...values, counterparty: val })}
                    optionsLoaderConfig={{
                        request: () => fetchProjects(),
                        mapper: (project: any) => ({ label: project.full_name, value: project.id }),
                    }}
                />
                <TextAreaInput
                    className={`${style.descriptionField} + ${
                        errors.description ? style.error : ''
                    }`}
                    label={t('common.description')}
                    name="description"
                    value={values.description || ''}
                    onChange={(event) => setValues({ ...values, description: event.target.value })}
                    disabled={isWatchRoute}
                />

                <Select
                    name="project_outlet_id"
                    className={errors.project_outlet_id ? style.error : ''}
                    label={t('common.project_outlet')}
                    options={projectOutlets}
                    onChange={(val) => setValues({ ...values, project_outlet_id: val })}
                    placeholder={t('common.project_outlet')}
                    value={values.project_outlet_id}
                    disabled={!values.counterparty?.value}
                />
                <div className={style.flexWrapper}>
                    <Select
                        label={t('importJournal.questionnaire')}
                        name="questionnaire_id"
                        placeholder={t('importJournal.questionnaire')}
                        value={values.questionnaire_id}
                        onChange={(val) => setValues({ ...values, questionnaire_id: val })}
                        options={questionnaires}
                        className={`${style.questionnaireField} ${
                            errors.questionnaire_id ? style.error : ''
                        }`}
                        disabled={!values.counterparty?.value}
                    />
                    <Checkbox
                        label={t('crowd.reportPhotoRequired')}
                        name="is_photo_required"
                        checked={values.is_photo_required || false}
                        onChange={(val) => setValues({ ...values, is_photo_required: val })}
                        disabled={isWatchRoute}
                    />
                </div>
                <ComplexForm
                    onSubmit={(e) => {}}
                    fields={fields}
                    defaultValues={values}
                    ref={formRef}
                    disabled={isWatchRoute}
                    onChange={(val) => {
                        setValues({ ...values, ...val });
                    }}
                />
            </div>
        </EditInstanceLayout>
    );
};
