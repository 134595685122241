import { QuestionnairesDetailed } from '../../types/questionnaires';

export const reduxStoreName = 'questionnaires/assignment';

export interface State {
    loading: boolean;
    init: boolean;
    error: boolean;
    errorMessage: string | undefined;
    importProcess: null | {};
    questionnairesPaginatedData: any;

    listLoading?: boolean;
    listError?: boolean;
    listErrMsg?: string | undefined;

    detailedLoading?: boolean;
    detailedErr?: boolean;
    detailedErrMsg?: string | undefined;

    search?: string | undefined;

    filterKind?: string | undefined;
    filterStatus?: string | undefined;
    filterProj?: string | undefined;

    updateLoading?: boolean;
    isUpdateFailed?: boolean;
    errorUpdateMsg?: string | undefined;

    currentQuestionnaire?: QuestionnairesDetailed[];

    onUpdateQuestionnaire?: boolean;
    isErrUpdateQuestionnaire?: boolean;
    errMessageQuestionnaire?: string | undefined;
}
