import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import { importFileStatusNames } from '@apiFeature/importProcesses/types';
import { getOptions } from '@tools/utils/functions';
import { importsKindOptions } from '@constants';

export const findSubOption = (currentValue, options) => {
    for (const item of options) {
        const subOption = item.subOptions.find(option => option.value === currentValue);
        if (subOption) {
          return subOption;
        }
      }
      return null;
};

const ImportsJournalFilters = ({ defaultValues, form }) => {
    const dispatch = useDispatch();
    const { projectsList, loading: loadingProjects } = useSelector(
        ProjectsSelectors.projectsSelectors
    );

    const { t } = useTranslation('translation');

    const { reset, watch, setValue } = form;
    const projectValue = watch('project');
    const kind = watch('kind');
    const status = watch('status');

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);


    useEffect(() => {
        getProjects();
    }, [getProjects]);

    const onChange = (name, e) => {
        setValue(name, e?.value);
    };

    const handleReset = () => {
        const [project] = projectsList || [];
        const { id } = project || {};
        reset({ ...defaultValues, project: id });
    };

    const projectOptions = getOptions(projectsList, 'full_name');

    const statusOptions = Object.keys(importFileStatusNames).map((key) => {
        return {
            label: importFileStatusNames[key],
            value: key,
        };
    });

    const handleKindChange = (e) => {
        setValue('kind', e)
    }

    const filters = [
        {
            type: Select,
            name: 'project',
            label: t('common.project'),
            onChange,
            value: projectValue
                ? projectOptions?.find((e) => e.value === projectValue)
                : projectOptions?.[0],
            options: projectOptions,
            isLoading: loadingProjects,
        },
        {
            type: Select,
            name: 'kind',
            label: t('importJournal.kind'),
            onChange: (_, e) => {handleKindChange(e)},
            value: findSubOption(kind, importsKindOptions),
            options: importsKindOptions,
            isNested: true,
            isSearchable: false
        },
        {
            type: Select,
            name: 'status',
            label: t('common.status'),
            placeholder: t('common.all'),
            onChange,
            value: statusOptions?.find((e) => e.value === status),
            options: statusOptions,
            isClearable: true,
        },
    ];

    return { handleReset, filters };
};

export default ImportsJournalFilters;
