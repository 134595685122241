import React from 'react';
import { Items } from '@components';
import styles from './PrivacyPolicy.module.scss';

export const PrivacyPolicy: React.FC = () => {
    const items = [
        {
            label: 'При установке приложение просит пользователя ввести следующую информацию:',
            isOpen: true,
            children:
                'Логин и пароль для доступа к приложению. Эти данные выдаются офисными сотрудниками. Личные данные не запрашиваются.',
        },
        {
            label: 'Безопасность',
            isOpen: true,
            children:
                'Мы предпринимаем необходимые технические и организационные меры информационной безопасности для защиты данных от несанкционированного доступа, изменения, раскрытия или уничтожения, путем внутренних проверок процессов сбора, хранения и обработки данных и мер безопасности, а также осуществления мер по обеспечению физической безопасности данных для предотвращения несанкционированного доступа к системам, в которых мы храним персональные данные.',
        },
        {
            label: 'Раскрытие информации третьим лицам',
            isOpen: true,
            children:
                'Мы не осуществляем передачу пользовательских данных третьим лицам, за исключением случаев, согласованных в рамках договорных обязательств. В исключительных случаях данные могут быть переданы компетентным органам по их запросу в соответствии с законодательством, либо для предотвращения противоправных действий.',
        },
        {
            label: 'Изменение настоящей Политики',
            isOpen: true,
            children:
                'Мы оставляем за собой право время от времени изменять настоящую Политику. Рекомендуем время от времени просматривать эту Политику для получения информации о любых изменениях.',
        },
    ];

    return (
        <div className={styles.privacyPolicy}>
            <p>
                «Сработаемся» — загружаемое мобильное приложение, доступное через электронные
                магазины третьих лиц от сторонних производителей. Используя наше приложение, вы
                автоматически соглашаетесь с данной политикой конфиденциальности. Мы очень серьезно
                относимся к защите конфиденциальности данных пользователей нашего приложения.
                Политика конфиденциальности регулирует и уточняет основные принципы
                конфиденциальности, применяемые нами в отношении данных, которые мы собираем с
                помощью приложения. Использование вами данного приложения означает подтверждение,
                что у вас есть или у вас была возможность ознакомиться с настоящими принципами
                политики конфиденциальности и описанными в них методами.
            </p>
            <h1>Доступы, необходимые «Сработаемся»</h1>
            <p>Зарегистрированные используемые сервисы:</p>
            <ul>
                <li>
                    Доступ к фотокамере необходим для выполнения заданий и создания фотоочётов
                    (прикрепления фотографий к визитам, задачам, опросникам), созданным в приложении
                </li>
                <li>
                    Доступ к геолокации необходим для отслеживания местоположения пользователя во
                    время работы (в том числе в фоновом режиме)
                </li>
            </ul>
            <Items data={items} className={styles.items} />
        </div>
    );
};
