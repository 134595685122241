import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './TasksDetails.module.scss';
import {
    Header,
    Title,
    BackLink,
    Select,
    Loader,
    DatePicker,
    TextField,
    Checkbox,
} from '@components';
import { Book } from '@/navigation/Book';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fetchGetTask, taskCreate } from '@apiFeature/tasks/tasks';
import { getProjectOutlet } from '@apiFeature/projectOutlets';
import { fetchFeed } from '@apiFeature/feeds';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import FiltersSelectors from '@redux/filters/selectors';
import { FiltersThunks } from '@redux/filters/thunk';
import { useAppDispatch, useAppSelector } from '@tools/hooks/redux';
import { extractErrorMessage, getOptions } from '@tools/utils/functions';
import { useOutlets } from '@tools/hooks/useOutlets';
import { fixTimezoneOffset } from '@tools/utils/date.util';
import Photos from './Photos/Photos';
import ActionsBtns from './ActionsBtns/ActionsBtns';
import CheckerSelectors from '@redux/checker/selector';
import { CheckerThunk } from '@redux/checker/thunk';
import moment from 'moment';
import { FileLink } from '@apiFeature/crowd/types';
import { fileLinks } from '@apiFeature/fileService';

export const TasksDetails: FC = () => {
    const session_project_id = sessionStorage.getItem('project_id');
    const { taskId, visitId } = useParams();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('project_id');
    const projectOutletId = searchParams.get('project_outlet_id');
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('translation');
    const dispatch = useAppDispatch();
    const { projectsList, loading: projectsLoading } = useAppSelector(
        ProjectsSelectors.projectsSelectors
    );
    const projectsListId = projectsList?.[0]?.id;
    const { orderedData } = useAppSelector(CheckerSelectors.checkerSelectors);
    const { retails, retailsLoading, branches, branchesLoading, cities, citiesLoading } =
        useAppSelector(FiltersSelectors.filtersState);
    const { state: checkState } = useLocation();
    const { check_result_id } = checkState || {};
    const [checkResult, setCheckResult] = useState(null);
    const [visitStartTime, setVisitStartTime] = useState(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [resultFeed, setResultFeed] = useState({});
    const [projectOutlet, setProjectOutlet] = useState({});

    const [selectedPhotoIds, setSelectedPhotoIds] = useState<string[]>([]);
    const [selectedFileLinks, setSelectedFileLinks] = useState<FileLink[]>([]);
    const [photos, setPhotos] = useState<FileLink[]>([]);

    const defaultErrorText = t('messages.fieldRequired');
    const validationSchema = object({
        project_id: string().required(defaultErrorText),
        project_outlet_id: string().required(defaultErrorText),
        deadline: string().required(defaultErrorText),
        description: string().required(defaultErrorText),
    });
    const form = useForm({ mode: 'onChange', resolver: yupResolver(validationSchema) });
    const { control, setValue, handleSubmit, reset, formState, watch } = form;
    const currentValues = watch();
    const {
        project_id,
        project_retail_ids,
        branch_ids,
        city_ids,
        project_outlet_id,
        outlet_search_filter,
    } = currentValues;

    const onChange = (name, e) => {
        let value = e?.value;
        if (e && Array.isArray(e)) {
            value = e.length > 0 ? e.map((item) => item.value) : null;
        }
        setValue(name, value);
    };

    const setActualValue = (curData, data, name) => {
        const value = data?.filter((e) => curData?.includes(e.id)).map((e) => e.id);
        if (curData && curData?.join() !== value?.join()) {
            setValue(name, value.length > 0 ? value : null);
        }
    };

    const formatCheckResultForDescription = (checkResult) => {
        let formattedText = '';
        if (checkResult) {
            const { result, comment, data } = checkResult || {};
            const { result: criteria } = data || {};
            const formattedDate = moment(visitStartTime).format('DD.MM.YYYY');
            formattedText += `Результат проверки визита от ${formattedDate}\n`;
            formattedText += `Итог проверки: ${result || '-'}\n`;
            formattedText += `Комментарий: ${comment || '-'}\n`;

            if (criteria?.length) {
                formattedText += `Критерии:\n`;
                criteria.forEach((c) => {
                    const { name, answers } = c || {};
                    if (name && answers) {
                        formattedText += `   ${c.name} - ${c.answers}\n`;
                    }
                });
            }
            formattedText += `\n`;
        }
        return formattedText;
    };

    const getFiltersProjects = useCallback(() => {
        dispatch(FiltersThunks.getRetailsList({ project_id })).then((result) => {
            if (FiltersThunks.getRetailsList.fulfilled.match(result)) {
                const retails = result?.payload || [];
                if (project_retail_ids?.length > 0)
                    setActualValue(project_retail_ids, retails, 'project_retail_ids');
            }
        });
        dispatch(FiltersThunks.getBranchesList({ project_id })).then((result) => {
            if (FiltersThunks.getBranchesList.fulfilled.match(result)) {
                const branches = result?.payload || [];
                if (branch_ids?.length > 0) setActualValue(branch_ids, branches, 'branch_ids');
            }
        });
        dispatch(FiltersThunks.getCitiesList({ project_id })).then((result) => {
            if (FiltersThunks.getCitiesList.fulfilled.match(result)) {
                const cities = result?.payload || [];
                if (city_ids?.length > 0) setActualValue(city_ids, cities, 'city_ids');
            }
        });
    }, [dispatch, project_id]);

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    const getCheckerResults = useCallback(() => {
        if (check_result_id) {
            dispatch(
                CheckerThunk.fetchResult({
                    check_list_id: check_result_id,
                    object_id: visitId,
                })
            ).then((result) => {
                if (CheckerThunk.fetchResult.fulfilled.match(result)) {
                    const { items } = result?.payload?.check_list_results || {};
                    const [checkResult] = items || [];
                    if (checkResult) {
                        setCheckResult(checkResult);
                    }
                }
            });
        }
    }, [check_result_id]);

    useEffect(() => {
        const resultDescription = formatCheckResultForDescription(checkResult);
        setValue('description', resultDescription);
    }, [checkResult, visitStartTime]);

    useEffect(() => {
        if (!outlet_search_filter) {
            setValue('outlet_search_filter', 'external_code');
        }
    }, [outlet_search_filter]);

    useEffect(() => {
        getCheckerResults();
    }, [orderedData, visitStartTime]);

    /*
    useEffect(() => {
        if (project_id) getFiltersProjects();
    }, [project_id, getFiltersProjects]);
    */

    useEffect(() => {
        if (projectsListId && !taskId && !visitId) {
            (async () => {
                if (projectOutletId) {
                    const { project_outlet } = (await getProjectOutlet(projectOutletId)) || {};
                    setProjectOutlet(project_outlet);
                }
                const defaultValues = {
                    project_id: projectId || session_project_id || projectsListId,
                    project_retail_ids: '',
                    branch_ids: '',
                    city_ids: '',
                    project_outlet_id: projectOutletId || '',
                    deadline: '',
                    description: '',
                    is_photo_required: false,
                    is_required: false,
                    outlet_search_filter: 'external_code',
                };
                reset(defaultValues);
            })();
        }
    }, [projectsListId]);

    useEffect(() => {
        getProjects();
    }, []);

    const getDataTask = () => {
        setLoading(true);
        fetchGetTask(taskId)
            ?.then(async (result) => {
                const {
                    project_outlet_id,
                    project_retail_ids,
                    branch_ids,
                    city_ids,
                    description,
                    is_photo_required,
                    is_required,
                } = result?.task || {};

                const { project_outlet } = (await getProjectOutlet(project_outlet_id)) || {};
                const { project_id } = project_outlet || {};

                const defaultValues = {
                    project_id,
                    project_retail_ids,
                    branch_ids,
                    city_ids,
                    project_outlet_id,
                    deadline: '',
                    description,
                    is_photo_required,
                    is_required,
                };

                reset(defaultValues);

                setProjectOutlet(project_outlet);
            })
            .catch((error) => {
                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getDataVisit = () => {
        setLoading(true);
        fetchFeed(visitId)
            ?.then(async (result) => {
                const { project_outlets, start_time } = result?.feed || {};
                setResultFeed(result?.feed || {});
                setVisitStartTime(start_time);

                const [{ id }] = project_outlets || [];

                if (id) {
                    const { project_outlet } = (await getProjectOutlet(id)) || {};

                    const {
                        project_id,
                        id: project_outlet_id,
                        project_retail_id,
                        branch_id,
                        city_id,
                    } = project_outlet || {};

                    const defaultValues = {
                        project_id,
                        ...(project_retail_id && { project_retail_ids: [project_retail_id] }),
                        ...(branch_id && { branch_ids: [branch_id] }),
                        ...(city_id && { city_ids: [city_id] }),
                        project_outlet_id,
                        deadline: '',
                        description: '',
                        is_photo_required: false,
                        is_required: false,
                    };
                    reset(defaultValues);

                    setProjectOutlet(project_outlet);
                }
            })
            .catch((error) => {
                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (taskId) getDataTask();
    }, [taskId]);

    useEffect(() => {
        if (visitId) getDataVisit();
    }, [visitId]);

    useEffect(() => {
        if (photos?.length > 0 && selectedPhotoIds?.length > 0) {
            const selectedPreviews = photos.filter((fl) => fl.is_preview && selectedPhotoIds.includes(fl.id));
            const selectedFilteredFileLinks = photos.filter((fl) => selectedPhotoIds.includes(fl.id));
            const originalsForSelectedPreviews = photos.filter((fl) => {
                const selectedPreview = selectedPreviews.find((selectedFl) => {
                    return selectedFl.extend_data.original_file_link_id === fl.id
                })
                return selectedPreview;
            });
            const resultFilteredFileLinks = [...selectedFilteredFileLinks, ...originalsForSelectedPreviews]
            setSelectedFileLinks(resultFilteredFileLinks)
        }
    }, [selectedPhotoIds, photos]);

    const [
        projectOutlets,
        outletsLoading,
        onInputChangeOutlets,
        moreOutlets,
        searchOutletName,
        clearName,
    ] = useOutlets(!taskId && !visitId ? project_id : null, 1, null, null, outlet_search_filter);

    useEffect(() => {
        if (
            !taskId &&
            !visitId &&
            !projectOutletId &&
            projectOutlets &&
            projectOutlets?.length > 0 &&
            project_outlet_id
        ) {
            const hasValue = projectOutlets?.some((e) => e.id === project_outlet_id);
            if (!hasValue) setValue('project_outlet_id', '');
        }
    }, [projectOutlets]);

    const onError = () => {
        if (!formState.isValid) {
            enqueueSnackbar(t('messages.invalidForm'), {
                variant: 'error',
            });
        }
    };

    const onSubmit = (data) => {
        const { isDirty, dirtyFields } = formState;
        if (isDirty || Object.keys(dirtyFields).length) {
            setLoading(true);

            const taskMessage = taskId ? t('tasks.resumeSuccess') : t('tasks.createSuccess');

            const deadline = data?.deadline?.length
                ? fixTimezoneOffset(new Date(data?.deadline).setHours(23, 59, 59))
                : '';

            taskCreate({
                ...data,
                deadline,
                user_type: 'INTERNAL',
                caused_by_visit_id: visitId,
            })
                .then((res) => {
                    const { task } = res || {};
                    const { id } = task || {};
                    const requestForFileLinks = selectedFileLinks
                        .filter(fl => !fl.is_preview)
                        .map((fl) => {
                            const { file_id } = fl || {};
                            return {
                                generic_type: 'tasks',
                                generic_id: id,
                                file_id,
                                is_preview: false,
                            }
                        });
                    if (requestForFileLinks?.length > 0) {
                        for (let fileLinkRequest of requestForFileLinks) {
                            fileLinks(fileLinkRequest).then((createdFileLink) => {
                                const { file_id } = fileLinkRequest || {};
                                const { id } = createdFileLink?.file_link || {};
                                const createPreviewRequest = {
                                    generic_type: 'tasks',
                                    generic_id: id,
                                    file_id,
                                    original_file_link_id: id,
                                    is_preview: true,
                                };
                                fileLinks(createPreviewRequest).catch((e) => {
                                    enqueueSnackbar(extractErrorMessage(e), {
                                        variant: 'error',
                                    });
                                });
                            })
                                .catch((e) => {
                                    enqueueSnackbar(extractErrorMessage(e), { variant: 'error', });
                                });
                        }
                    }
                    navigate(!visitId ? Book.tasks : Book.visit_feed);

                    enqueueSnackbar(taskMessage, { variant: 'success' });
                })
                .catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            enqueueSnackbar(t('messages.notChanged'), { variant: 'info' });
        }
    };

    const projectOptions = getOptions(projectsList, 'full_name');
    /*
    const retailsOptions = getOptions(retails);
    const branchesOptions = getOptions(branches);
    const citiesOptions = getOptions(cities);
    */
    const projectOutletsOptions = getOptions(
        !taskId && !visitId && !projectOutletId ? projectOutlets : [projectOutlet],
        ['external_code', 'name', 'address'],
        'id',
        false
    );

    const outletSearchOptions = [
        {
            label: t('tasks.searchByCode'),
            value: 'external_code',
        },
        {
            label: t('tasks.searchByAddress'),
            value: 'address',
        },
    ];

    return (
        <div className={styles.main}>
            {loading && <Loader />}
            <Header type="inner">
                <Title>
                    <BackLink link={!visitId ? Book.tasks : Book.visit_feed} />
                    {taskId ? t('tasks.resumeTask') : t('tasks.createNew')}
                </Title>
            </Header>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className={styles.formWrapper}>
                    <div className={styles.formItems}>
                        <div className={styles.formItem}>
                            <Controller
                                name="project_id"
                                control={control}
                                render={({
                                    field: { name, value, ref },
                                    fieldState: { error },
                                }) => (
                                    <Select
                                        id={name}
                                        name={name}
                                        inputRef={ref}
                                        label={t('common.project')}
                                        onChange={(e) => {
                                            setValue(name, e?.value, {
                                                shouldDirty: true,
                                                shouldValidate: true,
                                            });
                                        }}
                                        value={projectOptions?.find((e) => e.value === value)}
                                        options={projectOptions}
                                        isLoading={projectsLoading}
                                        isClearable={true}
                                        error={error}
                                        disabled={taskId || visitId || projectId}
                                    />
                                )}
                            />
                        </div>
                        {/*
                        <div className={styles.formItem}>
                            <Controller
                                name="project_retail_ids"
                                control={control}
                                render={({ field: { name, ref }, fieldState: { error } }) => (
                                    <Select
                                        id={name}
                                        name={name}
                                        inputRef={ref}
                                        label={t('common.retail')}
                                        onChange={(e) => onChange(name, e)}
                                        value={
                                            project_retail_ids
                                                ? retailsOptions?.filter((e) =>
                                                      project_retail_ids.includes(e.value)
                                                  )
                                                : null
                                        }
                                        options={retailsOptions}
                                        isLoading={retailsLoading}
                                        isClearable={true}
                                        isMulti={true}
                                        error={error}
                                        disabled={taskId || visitId}
                                    />
                                )}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <Controller
                                name="branch_ids"
                                control={control}
                                render={({ field: { name, ref }, fieldState: { error } }) => (
                                    <Select
                                        id={name}
                                        name={name}
                                        inputRef={ref}
                                        label={t('feed.branch')}
                                        onChange={(e) => onChange(name, e)}
                                        value={
                                            branch_ids
                                                ? branchesOptions?.filter((e) =>
                                                      branch_ids.includes(e.value)
                                                  )
                                                : null
                                        }
                                        options={branchesOptions}
                                        isLoading={branchesLoading}
                                        isClearable={true}
                                        isMulti={true}
                                        error={error}
                                        disabled={taskId || visitId}
                                    />
                                )}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <Controller
                                name="city_ids"
                                control={control}
                                render={({ field: { name, ref }, fieldState: { error } }) => (
                                    <Select
                                        id={name}
                                        name={name}
                                        inputRef={ref}
                                        label={t('common.city')}
                                        onChange={(e) => onChange(name, e)}
                                        value={
                                            city_ids
                                                ? citiesOptions?.filter((e) =>
                                                      city_ids.includes(e.value)
                                                  )
                                                : null
                                        }
                                        options={citiesOptions}
                                        isLoading={citiesLoading}
                                        isClearable={true}
                                        isMulti={true}
                                        error={error}
                                        disabled={taskId || visitId}
                                    />
                                )}
                            />
                        </div>
                        */}
                        <div className={styles.formItem}>
                            <Controller
                                name="outlet_search_filter"
                                control={control}
                                render={({
                                    field: { name, value, ref },
                                    fieldState: { error },
                                }) => (
                                    <Select
                                        id={name}
                                        name={name}
                                        inputRef={ref}
                                        label={t('common.searchOutletCriteria')}
                                        onChange={(e) => {
                                            setValue(name, e?.value, {
                                                shouldDirty: true,
                                            });
                                        }}
                                        value={outletSearchOptions?.find((e) => e.value === value)}
                                        options={outletSearchOptions}
                                        error={error}
                                        disabled={taskId || visitId}
                                    />
                                )}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <Controller
                                name="project_outlet_id"
                                control={control}
                                render={({
                                    field: { name, value, ref },
                                    fieldState: { error },
                                }) => (
                                    <Select
                                        id={name}
                                        name={name}
                                        inputRef={ref}
                                        label={t('common.project_outlet')}
                                        onChange={(e) => {
                                            setValue(name, e?.value, {
                                                shouldDirty: true,
                                                shouldValidate: true,
                                            });
                                            if (!e?.value)
                                                onInputChangeOutlets('', { action: 'clear' });
                                        }}
                                        value={projectOutletsOptions?.find(
                                            (e) => e.value === value
                                        )}
                                        options={projectOutletsOptions}
                                        isLoading={
                                            outletsLoading ||
                                            (searchOutletName?.current &&
                                                !projectOutletsOptions.length)
                                        }
                                        onInputChange={onInputChangeOutlets}
                                        onMenuScrollToBottom={moreOutlets}
                                        isClearable={true}
                                        error={error}
                                        disabled={taskId || visitId || projectOutletId}
                                        filterOption={false}
                                        onMenuClose={clearName}
                                    />
                                )}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <Controller
                                name="deadline"
                                control={control}
                                render={({
                                    field: { name, value, ref, onChange },
                                    fieldState: { error },
                                }) => (
                                    <DatePicker
                                        id={name}
                                        name={name}
                                        inputRef={ref}
                                        onChange={onChange}
                                        label={t('tasks.deadline')}
                                        placeholder={t('common.pick')}
                                        value={value}
                                        dateFormat="dd.MM.yyyy"
                                        error={error}
                                    />
                                )}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <Controller
                                name="description"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        label={t('common.description')}
                                        multiline
                                        onChange={onChange}
                                        value={value || ''}
                                        variant="outlined"
                                        error={error}
                                    />
                                )}
                            />
                        </div>
                        <div className={styles.formItem}>
                            <div className={styles.checkboxes}>
                                <Controller
                                    name="is_photo_required"
                                    control={control}
                                    render={({
                                        field: { name, onChange, value = false },
                                        fieldState: { error },
                                    }) => (
                                        <Checkbox
                                            value={value}
                                            onChange={onChange}
                                            label={t('tasks.isRequiredPhoto')}
                                            labelPlacement="end"
                                            id={name}
                                            error={error}
                                        />
                                    )}
                                />
                                <Controller
                                    name="is_required"
                                    control={control}
                                    render={({
                                        field: { name, onChange, value = false },
                                        fieldState: { error },
                                    }) => (
                                        <Checkbox
                                            value={value}
                                            onChange={onChange}
                                            label={t('tasks.isRequiredTask')}
                                            labelPlacement="end"
                                            id={name}
                                            error={error}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    {visitId && (
                        <>
                            <div className={styles.message}>{t('messages.pickPhotosForTask')}</div>
                            <div>{t('common.selected')}: {selectedPhotoIds?.length} {t('common.photo')}</div>
                            <Photos
                                visit_id={visitId}
                                resultFeed={resultFeed}
                                projectOutlet={projectOutlet}
                                photos={photos}
                                selectedPhotoIds={selectedPhotoIds}
                                setPhotos={setPhotos}
                                setSelectedPhotoIds={setSelectedPhotoIds}
                            />
                        </>
                    )}
                </div>
                <ActionsBtns />
            </form>
        </div>
    );
};
