import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchProjectsList } from "../../apiFeature/projects";
import { ProjectsListRequest } from "../../apiFeature/projects/types";
import { ErrorApi } from "../../apiFeature/types";
import { reduxStoreName } from "./types";


export module ProjectsThunks {

    export const fetchProjectsListThunk = createAsyncThunk<any, ProjectsListRequest, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/fetchProjectsListThunk`,
        async (arg, { rejectWithValue }) => {
            try {
                return await fetchProjectsList(arg)
            } catch (e) {
                return rejectWithValue(e)
            }
        },
    );

}