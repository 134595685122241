import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './PhotoPreloader.module.scss';
import CameraIcon from '@images/svg/camera-gray.svg';

interface IPhotoPreloader {
    className?: string | undefined;
}

const PhotoPreloader: FC<IPhotoPreloader> = ({ className, ...props }) => {
    return (
        <div {...props} className={classNames(styles.photoPreloader, className)}>
            <img width={50} height={30} src={CameraIcon} alt="" />
        </div>
    );
};

export default PhotoPreloader;
