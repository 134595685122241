import React, { useEffect, useState } from 'react';
import { FormControlLabel, Checkbox, FormGroup, FormLabel } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import CheckerAction from '@redux/checker/action';
import { useAppDispatch } from '@tools/hooks/redux';
import { useSelector } from 'react-redux';
import CheckerSelectors from '@redux/checker/selector';
import { checkFields } from './CheckFields';

const ManyAnswer = ({ item, parentId }) => {
    const resultSelect = useSelector(CheckerSelectors.answerSelector(parentId, item?.id));
    const { parent_criteria, parent_id } = item;
    const { setValue, control } = useFormContext();
    const [isShow, setIsShow] = useState(parent_criteria?.length > 0);
    const dispatch = useAppDispatch();
    const linkedItem = useSelector(CheckerSelectors.getLinkedChecker(parent_id));
    const [currState, setCurrState] = useState([]);
    const linkedVal = linkedItem?.[0]?.answers;

    useEffect(() => {
        if (linkedVal) {
            if (linkedVal === parent_criteria) {
                setIsShow(false);
            } else {
                setIsShow(true);
                dispatch(CheckerAction.deleteFormField(item));
            }
        }
    }, [parent_id, parent_criteria, linkedVal, dispatch, item]);

    const handleChange = (value, isChecked = true) => {
        if (isChecked) {
            setCurrState((prev) => [...prev, value]);
        } else {
            setCurrState((prev) => !!prev && prev.filter((it) => it !== value));
        }
    };
    useEffect(() => {
        if (currState?.length > 0) {
            setValue(item?.id, currState);
            dispatch(CheckerAction.updateFormField({ ...item, answers: currState }));
        } else {
            dispatch(CheckerAction.deleteFormField(item));
        }
    }, [currState]);

    useEffect(() => {
        if (!!resultSelect?.length) {
            setIsShow(false);
        }
    }, [resultSelect?.length]);

    if (isShow) {
        return null;
    }
    return (
        <div className={!!parent_id ? 'py-1.5 ml-5' : 'py-1.5'}>
            <FormLabel>{item?.name}</FormLabel>
            {item?.is_required && <span className="text-red">*</span>}
            <Controller
                name={item?.id}
                control={control}
                defaultValue={false}
                rules={{
                    required: item?.is_required && !resultSelect?.length && 'Поле обязательно !',
                }}
                render={({ field, fieldState }) => (
                    <>
                        <FormGroup row>
                            {item?.answers?.map((i) => (
                                <FormControlLabel
                                    {...field}
                                    key={i}
                                    onChange={(e) =>
                                        e.target.checked ? handleChange(i) : handleChange(i, false)
                                    }
                                    value={i}
                                    control={
                                        <Checkbox
                                            key={resultSelect?.[0]?.answers}
                                            checked={resultSelect?.[0]?.answers?.includes(i)}
                                            size="small"
                                        />
                                    }
                                    label={i}
                                    disabled={!!resultSelect?.[0]?.id?.length}
                                />
                            ))}
                        </FormGroup>
                        {item?.is_required && (
                            <div className="text-red">{fieldState?.error?.message}</div>
                        )}
                    </>
                )}
            />
            {!!item?.children?.length && (
                <div className="ml-3">
                    {item?.children?.map((item) => checkFields(item, item?.check_list_id))}
                </div>
            )}
        </div>
    );
};

export default ManyAnswer;
