import { createSlice } from "@reduxjs/toolkit"
import { ProjectsThunks } from "./thunk";
import { reduxStoreName } from "./types";

const initialState = {
    loading: false,
    error: false,
    errorMessage: '',
    projectsList: [],
}

const slice = createSlice({
    name: reduxStoreName,
    initialState: {
        ...initialState,
    },
    reducers: {
        reset() {
            return { ...initialState };
        },
    },
    extraReducers: builder => {
        builder
        .addCase(ProjectsThunks.fetchProjectsListThunk.pending, (state) => {
            state.loading = true;
            state.error = false;
            state.errorMessage = '';
        })
        .addCase(ProjectsThunks.fetchProjectsListThunk.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.errorMessage = payload?.message ?? 'Неизвестная ошибка';
        })
        .addCase(ProjectsThunks.fetchProjectsListThunk.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.errorMessage = '';
            state.projectsList = payload?.projects
        })
       
    },
});

export const reducer = slice.reducer;