import { clientExportServicePassword } from '../ClientPassword';
import { ExportPhotoRequest } from './types';

export const exportPhotoFiles = (arg: ExportPhotoRequest) => {
    return clientExportServicePassword.post('/v1/export_photo/export/', arg, {
        responseType: 'blob',
    });
};

export const exportPhotoCrowdTaskResult = async (data: any) => {
    const export_process = await clientExportServicePassword.post(
        '/v1/exports/photo_crowd_task_results/',
        data
    );

    return export_process;
};
