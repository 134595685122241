import { TaskComplexityItem, TaskItem, TaskResultItem } from "../../apiFeature/crowd/types";

export const reduxStoreName = 'crowd';

export interface CrowdState {
    loading?: boolean;
    erro?: boolean;
    errorMessage?: string;
    taskResultsList: TaskResultItem[];
    taskList?: TaskItem[];
    taskTypes?: any;
    currentTask?: TaskItem;
    currentUserId?: any;
    taskComplexities?: TaskComplexityItem[];
}