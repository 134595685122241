import { JwtPayload } from 'jwt-decode';

export type User = {
    id: string;
    created_at?: string | null;
    email?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    patronymic_name?: string | null;
    phone?: string | null;
    snils?: string | null;
    updated_at?: string | null;
};

export type Token = {
    expires_at: string;
    token: string;
};

export type Tokens = {
    access: Token;
    refresh: Token;
}

export type JwtPayloadOpen = JwtPayload & {
    phone: string;
    email?: string;
    session: string;
    roles: [];
};

export enum RolesOpenEnum {
    administrator = 'administrator',
    department_head = 'department_head',
    merchandiser = 'merchandiser',
    crisis_manager = 'crisis_manager'
}

export type RolesOpen =
    | RolesOpenEnum.administrator
    | RolesOpenEnum.department_head
    | RolesOpenEnum.merchandiser
    | RolesOpenEnum.crisis_manager;

export interface ErrorApi {
    error: number;
    message: string;
    error_description?: string;
    data?: any | null;
    status: number;
}

export const isErrorApi = (object?: any): object is ErrorApi =>
    object && (object as ErrorApi).message !== undefined && (object as ErrorApi).error !== undefined;

export type ResponsePageApi = {
    limit: number;
    offset: number;
    total: number;
};

export type BaseTypeEntity = {
    id: string;
    created_at: string;
    updated_at?: string | null;
};

export type TFile = BaseTypeEntity & {
    bucket?: string | null;
    key?: string | null;
    name?: string | null;
    content_type?: string | null;
    checksum_md5?: string | null;
    url?: string | null;
    size_bytes: number | null;
};

export type TFileLink = BaseTypeEntity & {
    file_id?: string | null;
    created_by?: string | null;
    generic_id?: string | null;
    generic_type?: string | null;
    tag?: string | null;
    is_primary: boolean;
    file?: TFile | null;
};

export const isTFileLink = (o: any): o is TFileLink =>
    o.id !== undefined && o.file_id !== undefined && o.file?.id !== undefined;

export type FilterValue = {
    label: string;
    value: string | number;
};

export type ResponseWithIds = ResponsePageApi & {
    items: string[];
}
