import { createSlice } from '@reduxjs/toolkit';
import { reduxStoreName } from './types';
import CheckerAction from './action';
import { CheckerThunk } from './thunk';
import { adapter } from './adapter';

const initialState = {
  loading: false,
  error: false,
  errorMessage: '',

  resultLoading: false,
  errorResult: false,
  errResultMessage: '',
  checkerList: [],
  resultResponse: [],
  submitResponse: [],
  ordredCheckers: [],
  orderedData: [],
  result: '',
  comment: '',
};

const extractAnswer = (arr, payload) => {
  const uniqueSet = new Set();
  const currItem = arr?.filter((it) => it?.id === payload?.id);
  const otherItems = arr?.filter((it) => it?.id !== payload?.id);
  const updateField =
    currItem?.length &&
    currItem?.reduce((acc, obj) => {
      const newObj = { ...obj };
      newObj.answers = payload?.answers;
      acc.push(newObj);
      return acc;
    }, []);

  const uniq =
    updateField &&
    updateField?.filter((it) => {
      const isUnique = !uniqueSet.has(it.id);
      if (isUnique) {
        uniqueSet.add(it.id);
      }
      return isUnique;
    });
  return !!currItem?.length ? [...otherItems, ...uniq] : [...arr, payload];
};
const extractChecker = (arr, payload, isComment = false) => {
  const curr = !!arr?.length && arr?.filter((it) => it?.id === payload?.checker_id);
  const other = !!arr?.length && arr?.filter((it) => it?.id !== payload?.checker_id);
  const update =
    !!curr?.length &&
    curr?.reduce((acc, obj) => {
      const copy = { ...obj };
      if (isComment) {
        copy.comment = payload?.value;
      } else {
        copy.results = [payload?.value];
      }
      acc.push(copy);
      return acc;
    }, []);
  return [...update, ...other];
};

function reorderArray(arr) {
  const result = [];
  const idMap = {};
  arr.forEach((item) => {
    idMap[item.id] = { ...item };
  });
  arr.forEach((item) => {
    if (item.parent_id) {
      const parent = idMap[item.parent_id];
      if (parent) {
        parent.children = parent.children || [];
        const existingChild = parent.children.find((child) => child.id === item.id);

        if (!existingChild) {
          parent.children.push(idMap[item.id]);
        }
      }
    } else {
      result.push(idMap[item.id]);
    }
  });

  return result;
}

const checkerOrder = (arr) => {
  const res = [...arr];
  const result = res?.map((it) => {
    return { ...it, check_list_params: reorderArray(it?.check_list_params) };
  });
  return result;
};

const slice = createSlice({
  name: reduxStoreName,
  initialState: adapter.getInitialState({
    ...initialState,
  }),
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CheckerAction.reset, (state) => {
        state.error = false;
        state.loading = false;
        state.errorMessage = '';
      })
      .addCase(CheckerThunk.fetchCheckerList.pending, (state) => {
        state.loading = true;
        state.errorMessage = '';
        state.error = false;
      })
      .addCase(CheckerThunk.fetchCheckerList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = false;
        state.errorMessage = '';
        adapter.removeAll(state);
        adapter.addMany(state, payload?.check_lists);
        state.checkerList = payload?.check_lists;
        state.orderedData = checkerOrder(payload?.check_lists);
      })
      .addCase(CheckerThunk.fetchCheckerList.rejected, (state, { payload }) => {
        state.error = true;
        state.loading = false;
        state.errorMessage = payload?.message ?? payload?.detail ?? 'Неизвестная ошибка';
      })
      .addCase(CheckerThunk.fetchResult.pending, (state) => {
        state.resultLoading = true;
        state.errResultMessage = '';
        state.errorResult = false;
      })
      .addCase(CheckerThunk.fetchResult.fulfilled, (state, { payload }) => {
        state.resultLoading = false;
        state.errorResult = false;
        state.errResultMessage = '';
        state.resultResponse.push(...payload?.check_list_results?.items);
      })
      .addCase(CheckerThunk.fetchResult.rejected, (state, { payload }) => {
        state.errorResult = true;
        state.resultLoading = false;
        state.errResultMessage = payload?.message ?? payload?.detail ?? 'Неизвестная ошибка';
      })
      .addCase(CheckerThunk.fetchCheckListSubmit.pending, (state) => {
        state.loading = true;
        state.errorMessage = '';
        state.error = false;
        state.submitResponse = [];
      })
      .addCase(CheckerThunk.fetchCheckListSubmit.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = false;
        state.errorMessage = '';
        state.submitResponse = payload?.check_list_result;
        state.resultResponse.push(payload?.check_list_result);
      })
      .addCase(CheckerThunk.fetchCheckListSubmit.rejected, (state, { payload }) => {
        state.error = true;
        state.loading = false;
        state.errorMessage = payload?.message ?? payload?.detail ?? 'Неизвестная ошибка';
      })
      .addCase(CheckerAction.updateFormField, (state, { payload }) => {
        state.submitResponse = !!state?.submitResponse?.length
          ? extractAnswer(state.submitResponse, payload)
          : [payload];
      })
      .addCase(CheckerAction.deleteFormField, (state, { payload }) => {
        state.submitResponse =
          !!state?.submitResponse?.length &&
          state?.submitResponse?.filter((item) => item?.id !== payload?.id);
      })
      .addCase(CheckerAction.finishChecker, (state, { payload }) => {
        state.checkerList = !!state.checkerList?.length
          ? extractChecker(state.checkerList, payload)
          : [...state.checkerList];
      })
      .addCase(CheckerAction.descriptionChecker, (state, { payload }) => {
        state.checkerList = !!state.checkerList?.length
          ? extractChecker(state.checkerList, payload, true)
          : [...state.checkerList];
      })
      .addCase(CheckerAction.reorderCheckersList, (state, { payload }) => {
        state.ordredCheckers = reorderArray(payload);
      });
  },
});

export const reducer = slice.reducer;
