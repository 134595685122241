import React from 'react';
import { SnackbarProvider as SP, MaterialDesignContent, closeSnackbar } from 'notistack';
import styled from '@emotion/styled';
import IconClose from '@images/svg/close.svg';
import { useTranslation } from 'react-i18next';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
        backgroundColor: '#d8ffd8',
        color: '#16ae14',
        boxShadow: '0px 6px 18px 0px rgba(0, 0, 0, 0.06)',
        fontSize: '14px',
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: '#f8d0cf',
        color: '#cc1c17',
        boxShadow: '0px 6px 18px 0px rgba(0, 0, 0, 0.06)',
        fontSize: '14px',
    },
    '&.notistack-MuiContent-info': {
        backgroundColor: '#ffd600',
        color: '#414152',
        boxShadow: '0px 6px 18px 0px rgba(0, 0, 0, 0.06)',
        fontSize: '14px',
    },
}));

const Button = styled.button`
    padding: 10px;
    opacity: 0.3;
`;

export const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {
    const { t } = useTranslation('translation');

    return (
        <SP
            autoHideDuration={3000}
            hideIconVariant
            dense
            maxSnack={3}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
                info: StyledMaterialDesignContent,
            }}
            action={(snackbarId) => (
                <Button onClick={() => closeSnackbar(snackbarId)}>
                    <img src={IconClose} alt={t('common.close') || ''} width="15" height="15" />
                </Button>
            )}
        >
            {children}
        </SP>
    );
};
