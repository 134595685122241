import { InputOption } from 'src/types/common';

export enum FormFieldType {
    TextInput,
    Checkbox,
    Submit,
    Delete,
    Select,
    AsyncSelect,
    MonthSelect,
    Date,
    DateRange,
    Reset,
    TextAreaInput,
}

interface BaseField {
    name: string;
    label: string;
    placeholder?: string;
    className?: string;
    columnStart?: number;
    columnEnd?: number;
    type: FormFieldType;
    wrapperClassName?: string;
    disabled?: boolean;
    onChange?: (value: unknown) => void;
}

export interface TextInputField extends BaseField {
    type: FormFieldType.TextInput;
}

export interface TextAreaInputField extends BaseField {
    type: FormFieldType.TextAreaInput;
}

export interface CheckboxField extends BaseField {
    type: FormFieldType.Checkbox;
}

export interface SubmitField extends BaseField {
    type: FormFieldType.Submit;
}

export interface DeleteField extends BaseField {
    type: FormFieldType.Delete;
}

export interface SelectField extends BaseField {
    type: FormFieldType.Select;
    options: InputOption[];
}

export interface ResetField extends BaseField {
    type: FormFieldType.Reset;
}

export interface MonthSelectField extends BaseField {
    type: FormFieldType.MonthSelect;
}

export interface AsyncSelectField<T = unknown> extends BaseField {
    type: FormFieldType.AsyncSelect;
    request: (inputValue: string) => Promise<T[]>;
    mapper: (data: T) => InputOption;
    prependPlaceholder?: boolean;
    isSearchable?: boolean;
    searchField?: string;
    isMulty?: boolean;
    projectId?: string;
    questionnaireKind?: string;
}

export interface DateField extends BaseField {
    type: FormFieldType.Date;
}

export interface DateRangeField extends BaseField {
    type: FormFieldType.DateRange;
}

export type FormField<T = unknown> =
    | TextInputField
    | CheckboxField
    | SubmitField
    | DeleteField
    | SelectField
    | AsyncSelectField<T>
    | MonthSelectField
    | DateField
    | DateRangeField
    | ResetField
    | TextAreaInputField;
