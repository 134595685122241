import React, { ReactNode, FC } from 'react';
import { Dialog as DL, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import classNames from 'classnames';
import exit from '@images/svg/exit-logo-for-popup-menu.svg';
import styles from './Dialog.module.scss';

interface IDialog {
    id: string;
    className?: string | undefined;
    isOpenDialog: boolean;
    setCloseDialog: () => void;
    children: ReactNode;
    title?: string;
    actions?: ReactNode;
    actionsAlignCenter?: boolean;
    type?: number;
}

const Dialog: FC<IDialog> = ({
    id,
    className = '',
    isOpenDialog,
    setCloseDialog,
    children,
    title,
    actions,
    actionsAlignCenter,
    type = 1,
    ...props
}) => {
    return (
        <DL
            {...props}
            onClose={setCloseDialog}
            aria-labelledby={id}
            open={isOpenDialog}
            className={classNames(styles.dialog, styles['type-' + type], className)}
        >
            {title && (
                <DialogTitle id={id} className={styles.title}>
                    {title}
                </DialogTitle>
            )}
            <IconButton aria-label="close" onClick={setCloseDialog} className={styles.close}>
                <img src={exit} alt="" />
            </IconButton>
            {children && (
                <DialogContent dividers className={styles.content}>
                    {children}
                </DialogContent>
            )}
            {actions && (
                <DialogActions
                    className={classNames(styles.actions, {
                        [styles.alignCenter]: actionsAlignCenter,
                        [styles.hasShadow]: children,
                    })}
                >
                    {actions}
                </DialogActions>
            )}
        </DL>
    );
};

export default Dialog;
