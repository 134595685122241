import React, { FC, ChangeEvent, KeyboardEvent } from 'react';
import searchIcon from 'src/assets/images/svg/search-icon.svg';
import './Search.style.scss';

interface SearchProps {
    placeholder?: string;
    value?: string;
    onChange?: (arg: string) => void;
    onClick?: () => void;
    onEnter?: () => void;
    className?: string;
    disable?: boolean;
}

export const Search: FC<SearchProps> = ({
    placeholder = 'placeholder',
    value = '',
    onChange = (val: string) => {},
    onClick = () => {},
    onEnter = () => {},
    className = '',
    disable = false,
}) => (
    <div className={`${className} search`}>
        <input
            placeholder={placeholder}
            value={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && onEnter()}
            disabled={disable}
        />
        <img alt="search/icon" src={searchIcon} onClick={onClick} />
    </div>
);
