import React, { FC, useState } from 'react';
import styles from './QuestionnaireStep.module.scss';
import QuestionnaireDetailedItem from './QuestionnaireDetailedItem/QuestionnaireDetailedItem';
import { TQuestionnaire } from '@apiFeature/questionnaires/type';
import { Loader, Button } from '@components';
import { useTranslation } from 'react-i18next';

interface IQuestionnaireStep {
    questionnaires: TQuestionnaire[];
    loading: boolean;
}

const QuestionnaireStep: FC<IQuestionnaireStep> = ({ questionnaires, loading }) => {
    const { t } = useTranslation('translation');

    const [expandedAll, setExpandedAll] = useState<boolean>(true);
    const handleExpandClick = () => {
        setExpandedAll(!expandedAll);
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : questionnaires?.length > 0 ? (
                <>
                    <div className={styles.header}>
                        <Button
                            color="secondary"
                            className={styles.expandToggle}
                            onClick={handleExpandClick}
                        >
                            {!expandedAll ? t('buttons.expandAll') : t('buttons.сollapseAll')}
                        </Button>
                    </div>
                    <div className={styles.listWrapper}>
                        {questionnaires.map((questionnaire) => (
                            <QuestionnaireDetailedItem
                                key={questionnaire.id}
                                questionnaire={questionnaire}
                                registerStr={`questionnaires[${questionnaire.id}]`}
                                expandedAll={expandedAll}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <div className={styles.emptyData}>{t('questionnaires.empty')}</div>
            )}
        </>
    );
};

export default QuestionnaireStep;
