import React, { FC } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import classNames from 'classnames';
import { Sum } from '@components';
import styles from './TableGrid.module.scss';

interface ITableGrid {
    className?: string | undefined;
    rows: [];
    columns: GridColDef[];
    rowCount: number;
    securedCount?: number;
    pageSizeOptions: number[];
    hasSun?: boolean | undefined;
}

const TableGrid: FC<ITableGrid> = ({
    rows,
    columns,
    rowCount,
    securedCount,
    pageSizeOptions = [10],
    className = '',
    hasSun,
    ...props
}) => {
    return (
        <div
            className={classNames(styles.data, className, {
                [styles.disableRowSelection]: props.disableRowSelectionOnClick,
            })}
        >
            {hasSun && (
                <div className={styles.info}>
                    <Sum total={rowCount} shown={rows?.length} secured={securedCount} />
                </div>
            )}
            <div className={styles.table}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    rowCount={rowCount}
                    pageSizeOptions={pageSizeOptions}
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    disableColumnFilter
                    {...props}
                />
            </div>
        </div>
    );
};

export default TableGrid;
