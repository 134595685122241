import { t } from "i18next";
import { visitStatusNames, visitTypeNames } from "@apiFeature/visits/types";
import { crowdTaskStatusNames } from "@apiFeature/crowd/types";
import { questionnaireKindNames } from "@apiFeature/questionnaires/type";

export const yesNoOptions = {
    'true': t('common.yes'),
    'false': t('common.no')
}

export const filterParamsList = [
    { 
        label: t('common.project'), 
        name: 'project_id', 
        type: 'text' 
      },
      { 
        label: t('visits.startDate'), 
        name: 'visit_date_start_lower', 
        type: 'date' 
      },
      { 
        label: t('visits.endDate'), 
        name: 'visit_date_start_upper', 
        type: 'date' 
      },
      { 
        label: t('crowd.startDate'), 
        name: 'task_result_start_time_lower', 
        type: 'date' 
      },
      { 
        label: t('crowd.endDate'), 
        name: 'task_result_start_time_upper', 
        type: 'date' 
      },
      { 
        label: t('visits.dateRange'), 
        name: 'date_range', 
        type: 'date' 
      },
      { 
        label: t('feed.branch'), 
        name: 'branch_ids', 
        type: 'id' 
      },
      { 
        label: t('common.city'), 
        name: 'city_id', 
        type: 'id' 
      },
      { 
        label: t('common.retail'), 
        name: 'project_retail_ids', 
        id: 'id' 
      },
      { 
        label: t('common.outletCode'), 
        name: 'project_outlet_external_code', 
        type: 'text' 
      },
      { 
        label: t('reports.reports.additionalCode'), 
        name: 'project_outlet_alert_magnit_code', 
        type: 'text' 
      },
      { 
        label: t('common.outletNameTT'), 
        name: 'project_outlet_name', 
        type: 'text' 
      },
      { 
        label: t('feed.address'), 
        name: 'project_outlet_address', 
        type: 'text' 
      },
      { 
        label: t('common.additionalProjectTT'), 
        name: 'project_outlet_additional_projects', 
        type: 'text' 
      },
      { 
        label: t('common.outletTypesTT'), 
        name: 'project_outlet_outlet_types', 
        type: 'text' 
      },
      { 
        label: t('reports.reports.additionalCodeIr'), 
        name: 'project_outlet_ir_outlet_code', 
        type: 'text' 
      },
      { 
        label: t('reports.reports.outletIrTypeVisitTT'), 
        name: 'project_outlet_ir_type_visit', 
        type: 'text' 
      },
      { 
        label: t('reports.reports.endDistance'), 
        name: 'visit_end_distance_lower', 
        type: 'text' 
      },
      { 
        label: t('reports.reports.fakeGps'), 
        name: 'visit_is_start_fake_gps', 
        type: 'yesNo' 
      },
      { 
        label: t('reports.reports.fakePhoto'), 
        name: 'visit_is_fake_photo', 
        type: 'yesNo' 
      },
      { 
        label: t('feed.visitStatus'), 
        name: 'visit_status', 
        type: 'select',
        options: visitStatusNames 
      },
      { 
        label: t('feed.taskStatus'), 
        name: 'task_result_status', 
        type: 'select',
        options: crowdTaskStatusNames 
      },
      { 
        label: t('feed.visitType'), 
        name: 'visit_type', 
        type: 'select',
        options: visitTypeNames
      },
      { 
        label: t('reports.reports.endedOut'), 
        name: 'is_visit_ended_out_outlet', 
        type: 'yesNo' 
      },
      { 
        label: t('filledVisit.problemInShop'), 
        name: 'visit_fail_result_name', 
        type: 'id' 
      },
      { 
        label: t('reports.reports.planVisit'), 
        name: 'route_point_is_scheduled', 
        type: 'yesNo' 
      },
      { 
        label: t('reports.reports.questionnaireName'), 
        name: 'questionnaire_name', 
        type: 'text' 
      },
      { 
        label: t('reports.reports.questionnaireType'), 
        name: 'questionnaire_kind', 
        type: 'select',
        options: questionnaireKindNames,
      },
      { 
        label: t('reports.reports.stepTitle'), 
        name: 'questionnaire_step_title', 
        type: 'text' 
      },
      { 
        label: t('reports.reports.stepDescription'), 
        name: 'questionnaire_step_description', 
        type: 'text' 
      },
      { 
        label: t('reports.reports.question'), 
        name: 'question_name', 
        type: 'text' 
      },
      { 
        label: t('common.answer'), 
        name: 'question_result_answer', 
        type: 'text' 
      },
      { 
        label: t('reports.reports.restoredVisit'), 
        name: 'visit_is_restored', 
        type: 'yesNo' 
      },
      { 
        label: t('visits.visitId'), 
        name: 'visit_ids', 
        type: 'text' 
      },
      { 
        label: t('common.retail'), 
        name: 'project_outlet_retails', 
        type: 'id' 
      },
      { 
        label: t('feed.branch'), 
        name: 'project_outlet_branches', 
        type: 'id' 
      },
      { 
        label: t('reports.reports.questionnaireName'), 
        name: 'questionnaire_names', 
        type: 'text' 
      },
      { 
        label: t('reports.reports.stepTitle'), 
        name: 'questionnaire_step_titles',
        type: 'text'
      },
      { 
        label: t('reports.reports.question'), 
        name: 'question_names', 
        type: 'text' 
      }
];

