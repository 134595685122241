import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VisitFeedItem } from '@/types/visitFeed';
import { useRoles } from '@tools/hooks/useRoles';
import { permissionCheck } from '@tools/utils/permissionCheck';
import { extractErrorMessage, getOptions } from '@tools/utils/functions';
import { Controller, useForm } from 'react-hook-form';
import { Button, Select, TextField } from '@components';
import ListMapper from '@components/Lists/List/ListMapper/ListMapper';
import { useAppSelector } from '@tools/hooks/redux';
import FiltersSelectors from '@redux/filters/selectors';
import { fetchAllSettings } from '@apiFeature/settings';
import { useSnackbar } from 'notistack';
import Map from './Map/Map';
import styles from './Info.module.scss';

interface IInfo {
    data: VisitFeedItem;
    handleSaveInfo: (VisitFeedItem) => void;
}

const Info: FC<IInfo> = ({ data, handleSaveInfo }) => {
    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();

    const { project_id } = data || {};

    const {
        retails,
        retailsLoading,
        branches,
        branchesLoading,
        territory,
        territoryLoading,
        cities,
        citiesLoading,
    } = useAppSelector(FiltersSelectors.filtersState);

    const authRoles = useAppSelector((state) => state?.auth?.jwtPayloadOpen?.roles);
    const { isSupervisor, isAdmin } = useRoles();

    const [settings, setSettings] = useState([]);
    const gpsChangeSetting = settings?.find((s) => s.code === 'is_gps_change_blocked')
        ?.setting_values?.[0]?.val;

    const isLockedEditCoordinates = !isAdmin && isSupervisor && !!gpsChangeSetting;

    const retailsOptions = getOptions(retails);
    const branchesOptions = getOptions(branches);
    const territoryOptions = getOptions(territory);
    const citiesOptions = getOptions(cities);

    const form = useForm({ mode: 'onChange' });
    const { control, reset, setValue, handleSubmit, watch } = form;
    const currentValues = watch();
    const {
        isEdit,
        name,
        project,
        external_code,
        outlet_type,
        address,
        branch_id,
        city_id,
        territory_id,
        project_retail_id,
        retails_format,
        additional_project,
        ir_outlet_code,
        ir_type_visit,
        square,
        agency_name,
        latitude,
        longitude,
    } = currentValues || {};

    const fields = {
        [t('common.project')]: { value: project ?? '-' },
        [t('common.outletCode')]: { value: external_code ?? '-' },
        [t('common.name')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                            />
                        )}
                    />
                </div>
            ) : (
                name ?? '-'
            ),
        },
        [t('feed.type')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="outlet_type"
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                            />
                        )}
                    />
                </div>
            ) : (
                outlet_type ?? '-'
            ),
        },
        [t('feed.address')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="address"
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                            />
                        )}
                    />
                </div>
            ) : (
                address ?? '-'
            ),
        },
        [t('feed.branch')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="branch_id"
                        control={control}
                        render={({ field: { name, value, ref } }) => (
                            <Select
                                id={name}
                                name={name}
                                inputRef={ref}
                                onChange={(e) => {
                                    setValue(name, e?.value || null);
                                }}
                                value={branchesOptions?.find((e) => e.value === value)}
                                options={branchesOptions}
                                isClearable={true}
                                isLoading={branchesLoading}
                            />
                        )}
                    />
                </div>
            ) : (
                branchesOptions?.find((e) => e.value === branch_id)?.label ?? '-'
            ),
        },
        [t('common.city')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="city_id"
                        control={control}
                        render={({ field: { name, value, ref } }) => (
                            <Select
                                id={name}
                                name={name}
                                inputRef={ref}
                                onChange={(e) => {
                                    setValue(name, e?.value || null);
                                }}
                                value={citiesOptions?.find((e) => e.value === value)}
                                options={citiesOptions}
                                isClearable={true}
                                isLoading={citiesLoading}
                            />
                        )}
                    />
                </div>
            ) : (
                citiesOptions?.find((e) => e.value === city_id)?.label ?? '-'
            ),
        },
        [t('common.territory')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="territory_id"
                        control={control}
                        render={({ field: { name, value, ref } }) => (
                            <Select
                                id={name}
                                name={name}
                                inputRef={ref}
                                onChange={(e) => {
                                    setValue(name, e?.value || null);
                                }}
                                value={territoryOptions?.find((e) => e.value === value)}
                                options={territoryOptions}
                                isClearable={true}
                                isLoading={territoryLoading}
                            />
                        )}
                    />
                </div>
            ) : (
                territoryOptions?.find((e) => e.value === territory_id)?.label ?? '-'
            ),
        },
        [t('common.retail')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="project_retail_id"
                        control={control}
                        render={({ field: { name, value, ref } }) => (
                            <Select
                                id={name}
                                name={name}
                                inputRef={ref}
                                onChange={(e) => {
                                    setValue(name, e?.value || null);
                                }}
                                value={retailsOptions?.find((e) => e.value === value)}
                                options={retailsOptions}
                                isClearable={true}
                                isLoading={retailsLoading}
                            />
                        )}
                    />
                </div>
            ) : (
                retailsOptions?.find((e) => e.value === project_retail_id)?.label ?? '-'
            ),
        },
        [t('common.format')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="retails_format"
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                            />
                        )}
                    />
                </div>
            ) : (
                retails_format ?? '-'
            ),
        },
        [t('common.additionalProject')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="additional_project"
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                            />
                        )}
                    />
                </div>
            ) : (
                additional_project ?? '-'
            ),
        },
        [t('common.latitude')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="latitude"
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                                disabled={isLockedEditCoordinates}
                            />
                        )}
                    />
                </div>
            ) : (
                latitude ?? '-'
            ),
        },
        [t('common.longitude')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="longitude"
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                                disabled={isLockedEditCoordinates}
                            />
                        )}
                    />
                </div>
            ) : (
                longitude ?? '-'
            ),
        },
        [t('feed.code')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="ir_outlet_code"
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                            />
                        )}
                    />
                </div>
            ) : (
                ir_outlet_code ?? '-'
            ),
        },
        [t('common.irTypeVisit')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="ir_type_visit"
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                            />
                        )}
                    />
                </div>
            ) : (
                ir_type_visit ?? '-'
            ),
        },
        [t('catalog.outlets.updated')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="square"
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                            />
                        )}
                    />
                </div>
            ) : (
                square ?? '-'
            ),
        },
        [t('catalog.outlets.updatedBy')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="agency_name"
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                            />
                        )}
                    />
                </div>
            ) : (
                agency_name ?? '-'
            ),
        },
    };

    useEffect(() => {
        reset({ ...data, isEdit: false });
    }, [data]);

    useEffect(() => {
        if (project_id) {
            getSettingsProjectData();
        }
    }, [project_id]);

    const getSettingsProjectData = useCallback(() => {
        fetchAllSettings({ project_ids: [project_id] })
            ?.then((settingsAll) => {
                const { settings } = settingsAll || {};
                setSettings(settings);
            })
            .catch((error) => {
                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
            });
    }, [project_id]);

    const onMapClick = (coords: number[]) => {
        setValue('latitude', coords[0]);
        setValue('longitude', coords[1]);
    };

    const handleEdit = (e) => {
        e.preventDefault();
        if (isEdit) {
            reset();
        } else {
            setValue('isEdit', !isEdit);
        }
    };

    const onSubmit = ({ isEdit, ...data }) => {
        setValue('isEdit', false);
        handleSaveInfo({ ...data, square: +data?.square || 0 });
    };

    return (
        <div className={styles.info}>
            <div className={styles.data}>
                <div className={styles.fields}>
                    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                        <ListMapper
                            items={permissionCheck(authRoles, fields)}
                            className={styles.serializedFeedItem}
                        />
                        <div className={styles.btns}>
                            {isEdit ? (
                                <>
                                    <Button variant="contained" color="secondary" type="submit">
                                        {t('buttons.save')}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleEdit}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                </>
                            ) : (
                                <Button variant="outlined" color="secondary" onClick={handleEdit}>
                                    {t('buttons.edit')}
                                </Button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
            <div className={styles.map}>
                <Map
                    isEdit={isEdit && !isLockedEditCoordinates}
                    latitude={latitude}
                    longitude={longitude}
                    getCoords={onMapClick}
                />
            </div>
        </div>
    );
};

export default Info;
