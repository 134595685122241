import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Map.module.scss';
import { YMaps, Map as YMap, Placemark } from '@pbe/react-yandex-maps';
import { getVisitById } from '@apiFeature/visits';
import { useSnackbar } from 'notistack';
import { apikey } from '@/constants';

interface IMap {
    visit_id: string;
    latitude: number;
    longitude: number;
}

const Map: FC<IMap> = ({ visit_id, latitude, longitude }) => {
    const [visit, setVisit] = useState({});
    const { start_point_lat, start_point_lon, end_point_lat, end_point_lon } = visit || {};

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('translation');

    useEffect(() => {
        if (!visit_id) return;

        getVisitById(visit_id)
            .then((res) => {
                const { visit } = res || {};
                setVisit(visit);
            })
            .catch((e) => enqueueSnackbar(e?.message, { variant: 'error' }));
    }, [visit_id]);

    return (
        <YMaps query={{ apikey, lang: 'ru_RU' }}>
            <YMap className={styles.map} defaultState={{ center: [latitude, longitude], zoom: 16 }}>
                {end_point_lat && end_point_lon && (
                    <Placemark
                        defaultGeometry={[end_point_lat, end_point_lon]}
                        options={{ preset: 'islands#blueGovernmentIcon' }}
                        properties={{
                            iconCaption: t('feed.visitEnd'),
                        }}
                    />
                )}
                {start_point_lat && start_point_lon && (
                    <Placemark
                        defaultGeometry={[start_point_lat, start_point_lon]}
                        options={{ preset: 'islands#blueRunIcon' }}
                        properties={{
                            iconCaption: t('feed.visitStart'),
                        }}
                    />
                )}
                <Placemark
                    defaultGeometry={[latitude, longitude]}
                    options={{ preset: 'islands#blueHomeCircleIcon' }}
                    properties={{
                        iconCaption: t('common.project_outlet'),
                    }}
                />
            </YMap>
        </YMaps>
    );
};

export default Map;
