import { clientAuth2Open } from "../BaseClient"
import { AccessAuthTypesRequest, AccessAuthTypesResponse, AuthRequest, AuthResponse, LoginBySMSRequest, SendSMSResponse } from "./types";

export const authLogin = (request: AuthRequest) => {
    return clientAuth2Open.post<AuthResponse, AuthRequest>(
        '/api/v1/auth/login/',
        request
    )
}

export const fetchAccessAuthTypes = (request: AccessAuthTypesRequest) => {
    return clientAuth2Open.post<AccessAuthTypesResponse, AccessAuthTypesRequest>(
        '/api/v1/auth/access_auth_types/',
        request
    )
};

export const sendSMS = (request: { phone: string }) => {
    return clientAuth2Open.post<SendSMSResponse, typeof request>(
        '/api/v1/sms_auth/send_otp/',
        request
    )
};

export const loginBySMSCode = (request: LoginBySMSRequest) => {
    return clientAuth2Open.post<AuthResponse, LoginBySMSRequest>(
        '/api/v1/sms_auth/login_by_otp_code/',
        request
    )
};