import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { InputLabel } from '@mui/material';
import styles from './Label.module.scss';

export interface ILabel {
    htmlFor?: string;
    className?: string;
    children: ReactNode;
}

const Label: FC<ILabel> = ({ htmlFor, className = '', children, ...props }) => {
    return (
        <InputLabel {...props} htmlFor={htmlFor} className={classNames(styles.label, className)}>
            {children}
        </InputLabel>
    );
};

export default Label;
