import React, { FC } from 'react';
import { useFieldArray, useFormContext, FormProvider, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { TextField, Button } from '@components';
import { v4 as uniqid } from 'uuid';
import sortIcon from '@images/svg/drag.svg';
import arrowSelect from '@images/svg/arrow-for-select-red.svg';
import arrowOpenSelect from '@images/svg/arrow-for-select-open-red.svg';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import QuestionsChildren from './QuestionsChildren/QuestionsChildren';
import styles from './QuestionsItem.module.scss';

const QuestionsItem: FC = ({
    toggle,
    openedSteps,
    removeStep,
    registerStr,
    idx,
    isMoreOneField,
    defaultValuesStep,
    stepId,
}) => {
    const { questions = [] } = defaultValuesStep;

    const formMethods = useFormContext();
    const { control } = formMethods || {};

    const { fields, remove, append, move } = useFieldArray({
        control,
        name: `${registerStr}.questions`,
    });

    const { t } = useTranslation('translation');

    const isOpenedSteps = openedSteps.includes(stepId);

    const { listeners, setNodeRef, transform, transition } = useSortable({ id: stepId });
    const style = {
        transform: CSS.Transform.toString(transform ? { ...transform, scaleY: 1 } : null),
        transition,
    };

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragEnd(event) {
        const { active, over } = event;
        if (active?.id !== over?.id) {
            const oldIndex = fields.findIndex((e) => e.id === active.id);
            const newIndex = fields.findIndex((e) => e.id === over.id);
            move(oldIndex, newIndex);
        }
    }

    return (
        <div className={styles.block} ref={setNodeRef} style={style}>
            <div className={classNames(styles.items, styles.itemBorder)}>
                <div
                    className={classNames(styles.item, styles.order, {
                        [styles.isSortable]: isMoreOneField,
                    })}
                >
                    <Button className={styles.sortBtn} {...listeners}>
                        <img src={sortIcon} alt="" />
                    </Button>
                    <Controller
                        name={`${registerStr}.order`}
                        control={control}
                        render={({ field: { name } }) => (
                            <TextField
                                value={idx + 1}
                                id={name}
                                variant="outlined"
                                disabled={true}
                            />
                        )}
                    />
                </div>
                <div className={classNames(styles.item, styles.content)}>
                    <Controller
                        name={`${registerStr}.title`}
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                                error={error}
                            />
                        )}
                    />
                    <Controller
                        name={`${registerStr}.description`}
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                                error={error}
                            />
                        )}
                    />
                </div>
                <div className={classNames(styles.item, styles.questions)}>
                    {fields?.length || '-'}
                </div>
                <div className={classNames(styles.item, styles.actions)}>
                    <Button
                        className={styles.textBtn}
                        onClick={() => toggle(stepId)}
                        color="secondary"
                        startIcon={
                            <img
                                className={styles.actionsImg}
                                src={!isOpenedSteps ? arrowSelect : arrowOpenSelect}
                                alt="dropdown"
                            />
                        }
                    >
                        {t(!isOpenedSteps ? 'buttons.expand' : 'common.hide').toLocaleUpperCase()}
                    </Button>
                    <Button
                        className={styles.textBtn}
                        onClick={() => removeStep(idx)}
                        color="secondary"
                    >
                        {t('buttons.delete').toLocaleUpperCase()}
                    </Button>
                </div>
            </div>
            {isOpenedSteps && (
                <div className={styles.table}>
                    <div className={styles.items}>
                        <div className={classNames(styles.item, styles.order)}>№</div>
                        <div className={classNames(styles.item, styles.content)}>
                            {t('common.question')}
                        </div>
                        <div className={classNames(styles.item, styles.questions)}>
                            {t('questionnaires.answerType')}
                        </div>
                        <div className={classNames(styles.item, styles.actions)}>
                            {t('common.actions')}
                        </div>
                    </div>
                    <FormProvider {...formMethods}>
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext items={fields} strategy={verticalListSortingStrategy}>
                                {fields?.length > 0 &&
                                    fields?.map((question, index) => (
                                        <QuestionsChildren
                                            key={question.id}
                                            questionId={question.id}
                                            remove={remove}
                                            registerStr={`${registerStr}.questions[${index}]`}
                                            questions={questions}
                                            idx={index}
                                            isMoreOneField={fields?.length > 1}
                                            defaultValuesStepQuestion={questions?.[index] || {}}
                                        />
                                    ))}
                            </SortableContext>
                        </DndContext>
                    </FormProvider>
                    <div className={styles.btn}>
                        <Button
                            className={styles.textBtn}
                            onClick={() => append({ id: uniqid() })}
                            color="secondary"
                        >
                            {t('questionnaires.addQuestion').toLocaleUpperCase()}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuestionsItem;
