import { clientChecker } from '../ClientPassword';

export const fetchCheckList = (arg) => clientChecker.post('/api/v1/check_list_logic/list/', arg);

export const fetchCheckListResult = async (arg) => {
  return clientChecker.post('/api/v1/check_list_result/list/', arg);
};

export const fetchSendCheckList = async (arg) => {
  return clientChecker.post('/api/v1/check_list_result/create/', arg);
};
