import React, { FC, useEffect, useState } from 'react';
import close from '@images/svg/close-card-button-active.svg';
import './PhotoPicker.style.scss';
import { useDispatch, useSelector } from 'react-redux';
import FakeVisitSelectors from '@redux/fakeVisit/selectors';
import FakeVisitAction from '@redux/fakeVisit/actions';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import SparkMD5 from 'spark-md5';
import { Question } from '@types/questionnaires';

interface IPhotoPicker {
    isPhotoAnswer?: boolean;
    isSmall?: boolean;
    multiple?: boolean;
    question?: Question;
    answersFromVisit?: {};
}

export const PhotoPicker: FC<IPhotoPicker> = ({
    question,
    isPhotoAnswer = false,
    isSmall = false,
    answersFromVisit,
    multiple = true,
}) => {
    const dispatch = useDispatch();
    const { photos } = useSelector(FakeVisitSelectors.fakeVisitSelectors);
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const { t } = useTranslation('translation');

    const generateHash = (photo) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
                const spark = new SparkMD5.ArrayBuffer();
                spark.append(event.target.result);
                const hash = spark.end();
                resolve(hash);
            };

            fileReader.onerror = (event) => {
                reject(event.target.error);
            };
            fileReader.readAsArrayBuffer(photo);
        });
    };

    const handlePickPhoto = async (files) => {
        if (Object.values(files)?.length) {
            const arrayOfFiles = Array.from(files);
            const arrayOfPhotos = await Promise.all(
                arrayOfFiles.map(async (file) => {
                    let photoObject;
                    await generateHash(file).then((hash) => {
                        if (
                            !selectedPhotos.find((photo) => photo.hash === hash) &&
                            !photos?.find((photo) => photo.hash === hash)
                        ) {
                            photoObject = {
                                uri: URL.createObjectURL(file),
                                file: file,
                                hash,
                            };
                            dispatch(
                                FakeVisitAction.addPhotos({
                                    question_id: question?.id,
                                    photo: file,
                                    isPhotoAnswer,
                                    blobUri: URL.createObjectURL(file),
                                    hash,
                                })
                            );
                        }
                    });
                    return photoObject;
                })
            );
            const filteredPhotos = arrayOfPhotos.filter((photo) => photo !== undefined);
            setSelectedPhotos((previousImages) => previousImages.concat(filteredPhotos));
            arrayOfFiles.map((file) => URL.revokeObjectURL(file));
        }
    };

    useEffect(() => {
        if (answersFromVisit && answersFromVisit?.file_links) {
            setSelectedPhotos(
                answersFromVisit?.file_links.map((fileLink) => {
                    return {
                        file: fileLink.file.url,
                        uri: fileLink.file.url,
                    };
                })
            );
            answersFromVisit?.file_links.map((fileLink) => {
                fetch(fileLink?.file?.url)
                    .then((res) => res.blob())
                    .then((blob) => {
                        const file = new File([blob], fileLink?.file?.name, { type: blob.type });
                        generateHash(file).then((hash) => {
                            if (!photos.find((photo) => photo.hash === hash)) {
                                dispatch(
                                    FakeVisitAction.addPhotos({
                                        question_id: question?.id,
                                        photo: file,
                                        isPhotoAnswer,
                                        blobUri: fileLink?.file?.url,
                                        isCopy: true,
                                        hash,
                                    })
                                );
                            }
                        });
                    });
            });
        }
    }, [answersFromVisit]);

    const handleDeletePhoto = (image) => {
        dispatch(FakeVisitAction.deletePhoto(image));
        setSelectedPhotos(selectedPhotos.filter((photo) => photo?.uri !== image));
    };

    useEffect(() => {
        if (photos?.length && !selectedPhotos.length) {
            const filesArray = photos?.filter((i) => i.question_id === question?.id);
            if (filesArray.length) {
                const photoArr = Promise.all(
                    filesArray?.map((o) => {
                        if (o?.file) {
                            generateHash(o?.file).then((hash) => {
                                return { uri: o?.blobUri, file: o?.file, hash };
                            });
                        }
                    })
                );
                if (photoArr?.length) {
                    setSelectedPhotos(photoArr);
                }
            }
        }
    }, [photos]);

    return (
        <div>
            <div className={isSmall ? 'small-preview-wrap' : 'picker-wrapper'}>
                {!!selectedPhotos.length &&
                    selectedPhotos.map((photo, index) => {
                        return (
                            <div
                                key={`${photo?.uri}-${index}`}
                                className={isSmall ? 'small-preview' : 'preview'}
                            >
                                <img src={photo?.uri} alt="preview" />
                                <div
                                    className="close-button"
                                    onClick={() => {
                                        handleDeletePhoto(photo?.uri);
                                    }}
                                >
                                    <img src={close} alt="close" />
                                </div>
                            </div>
                        );
                    })}
            </div>
            <FileUploader
                name="file"
                multiple={multiple}
                handleChange={handlePickPhoto}
                types={['JPG', 'PNG', 'JPEG', 'GIF', 'WEBP']}
                label={t('messages.dragOrSelect')}
            />
        </div>
    );
};
