import React, { InputHTMLAttributes } from 'react';

import cn from 'classnames';

import s from './Checkbox.module.scss';

export interface CheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    label: string;
    error?: string;
    onChange?: (checked: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
    label,
    error,
    className,
    onChange,
    ...props
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event.target.checked);
        }
    };
    return (
        <label className={cn(className, s.wrapper)}>
            <input
                {...props}
                checked={props?.checked}
                className={cn(s.input)}
                type="checkbox"
                onChange={handleChange}
            />
            <span className={s.label}>{label}</span>
        </label>
    );
};
