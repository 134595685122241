import React, { useEffect } from 'react';
import { PrivateRoutes } from 'src/navigation/routes/private';
import { PublicRoutes } from 'src/navigation/routes/public';
import { useAuth } from 'src/tools/hooks/useAuth';
import { PrivateLayout } from './layout/Private/PrivateLayout';
import { PublicLayout } from './layout/Public/PublicLayout';
import { useAppSelector } from '../tools/hooks/redux';
import { redirect } from 'react-router-dom';
import 'src/view/styles/index.scss';
import 'tailwindcss/tailwind.css';
import { BannedRoles } from './components/ui/bannedRole';
import { Book } from '@navigation/Book';
import { AutoUpdateRefreshToken } from './components/AutoUpdateRefreshToken/AutoUpdateRefreshToken';

export const App = () => {
    const { isAccessValid, isRefreshValid } = useAuth();
    const roles = useAppSelector((state) => state?.auth?.jwtPayloadOpen?.roles);
    const bannedRoles = [
        'stompy',
        'merchandiser',
        'crowd_merchandiser',
        'cunning_merchandiser',
        'trial_crowd',
    ];
    const isAvailable = roles?.some((i) => !bannedRoles.includes(i));

    useEffect(() => {
        document.title = 'Сработаемся';
        if (roles && !isAvailable) {
            redirect(Book.banned);
        }
    }, []);

    if (!isAccessValid && !isRefreshValid) {
        return (
            <PublicLayout>
                <PublicRoutes />
            </PublicLayout>
        );
    }

    if (isAvailable) {
        return (
            <>
                <AutoUpdateRefreshToken />
                <PrivateLayout>
                    <PrivateRoutes />
                </PrivateLayout>
            </>
        );
    } else if (roles && !isAvailable) {
        return <BannedRoles />;
    }
};
