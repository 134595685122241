import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Loader, Sum, Button, Pagination } from '@components';
import List from './List/List';
import arrowSelect from '@images/svg/arrow-for-select-red.svg';
import arrowOpenSelect from '@images/svg/arrow-for-select-open-red.svg';
import styles from './Lists.module.scss';
import { FilterValue } from '@apiFeature/types';

interface ILists {
    className?: string;
    items: [];
    loading?: boolean;
    hasSun?: boolean;
    hasNumbersPagination?: boolean;
    total?: number;
    offset?: number;
    limit: number;
    pageNumber?: number;
    sort?: string;
    sortName?: string;
    renderList?: (item) => ReactNode;
    onPaginationModelChange?: ({ offset, limit }) => void;
    onSortModelChange?: () => void;
    setItemsPerPage?: (filter: FilterValue) => void;
    emptyText?: string;
}

const Lists: FC<ILists> = ({
    items,
    loading,
    className = '',
    hasSun,
    hasNumbersPagination = true,
    total = 0,
    offset,
    limit,
    pageNumber,
    sort,
    sortName,
    renderList,
    onPaginationModelChange,
    onSortModelChange,
    setItemsPerPage,
    emptyText,
}) => {
    const { t } = useTranslation('translation');

    const setSort = () => {
        onSortModelChange && onSortModelChange();
    };

    const infoProps =
        hasSun || sort
            ? { className: classNames(styles.info, { [styles.infoWithoutSum]: !hasSun }) }
            : {};

    return (
        <>
            {loading && <Loader />}
            <div className={classNames(styles.data, className)}>
                <div {...infoProps}>
                    {hasSun && <Sum total={total} />}
                    {sort && (
                        <Button
                            className={styles.sortBtn}
                            onClick={setSort}
                            color="secondary"
                            endIcon={
                                <img
                                    src={sort === 'asc' ? arrowOpenSelect : arrowSelect}
                                    className={styles.sortImg}
                                    alt=""
                                />
                            }
                        >
                            {sortName}
                        </Button>
                    )}
                </div>
                <div className={styles.lists}>
                    {items && items?.length > 0 ? (
                        items.map((item) => (
                            <List key={item.id}>{renderList && renderList(item)}</List>
                        ))
                    ) : (
                        <div className={styles.empty}>{emptyText || t('feed.empty')}</div>
                    )}
                </div>
            </div>
            <Pagination
                total={total}
                limit={limit}
                offset={offset}
                pageNumber={pageNumber}
                hasNumbersPagination={hasNumbersPagination}
                onPaginationModelChange={onPaginationModelChange}
                setItemsPerPage={setItemsPerPage}
            />
        </>
    );
};

export default Lists;
