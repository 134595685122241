import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import { fetchProjects } from 'src/api/projects/projects.api';
import { InputOption } from 'src/types/common';
import { Planogram } from 'src/types/planogram';
import { AsyncSelect } from 'src/view/components/ui/form/Select/AsyncSelect';
import { TextInput } from 'src/view/components/ui/form/TextInput/TextInput';
import { Loader } from 'src/view/components/ui/Loader/Loader';
import { Modal } from 'src/view/components/ui/Modal/Modal';
import { ImageLink, ImagesPicker } from './ImagesPicker/ImagesPicker';
import { Button } from 'src/view/components/ui/Button/Button';
import { TextAreaInput } from 'src/view/components/ui/TextAreaInput/TextAreaInput';
import { useTranslation } from 'react-i18next';
import {
    createPlanogramRequest,
    editPlanogramRequest,
    uploadPlanogramImage,
} from '@apiFeature/planograms';
import { Select } from 'src/view/components/ui/form/Select/Select';
import { PlanogramTypesName } from '@apiFeature/planograms/types';
import DispenserIcon from '../IconComponents/DispenserIcon';
import DropIcon from '../IconComponents/DropIcon';
import PersonIcon from '../IconComponents/PersonIcon';
import PramIcon from '../IconComponents/PramIcon';
import RazorIcon from '../IconComponents/RazorIcon';
import ToothIcon from '../IconComponents/ToothIcon';
import TshirtIcon from '../IconComponents/TshirtIcon';
import ScissorsIcon from '../IconComponents/ScissorsIcon';
import BookIcon from '../IconComponents/BookIcon';
import { FileLink } from '@types/files';
import { deleteFeedPhoto } from '@apiFeature/feeds';
import './NewPlanogram.style.scss';

enum BodyKeys {
    ProjectId = 'project_id',
    Name = 'name',
    Text = 'text',
    Type = 'type',
}

interface Body {
    project_id: InputOption | null;
    name: string | null;
    text: string | null;
    type: InputOption | null;
}

const defaultBody: Body = {
    project_id: null,
    name: null,
    text: null,
    type: null,
};

const required: BodyKeys[] = Object.values(BodyKeys);

interface NewPlanogramProps {
    addPlanogram: (data: any) => void;
    onClose: () => void;
    planogram?: Planogram | null;
    setPlanograms: (data: any) => void;
    slider?: FileLink[] | null;
    setSlider?: (data: any) => void;
    setSelectedPhoto?: (data: any) => void;
}

export const NewPlanogram: FC<NewPlanogramProps> = ({
    onClose,
    addPlanogram,
    planogram,
    setPlanograms,
    slider,
    setSlider,
    setSelectedPhoto,
}) => {
    const [body, setBody] = useState<Body>(defaultBody);
    const [error, setError] = useState<BodyKeys[]>([]);
    const [createError, setCreateError] = useState<string>('');
    const [files, setFiles] = useState<ImageLink[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [images, setImages] = useState([]);
    const { t } = useTranslation('translation');

    const changeBody = (key: BodyKeys, data: any) => setBody({ ...body, [key]: data });

    const check = (key: BodyKeys): boolean => error.includes(key);

    const clearStr = (e: ChangeEvent<HTMLInputElement>): string | null => {
        const val = e.target.value;

        return val.length <= 0 ? null : val;
    };

    const sendImages = async (id: string | number) => {
        setCreateError('');

        for (const file of files) {
            try {
                if (file?.file) {
                    await uploadPlanogramImage({
                        file: file.file,
                        object_id: id,
                        type: 'sfa_planogram',
                    });
                }
            } catch (e) {
                setCreateError(e);
            } finally {
                setLoader(false);
            }
        }
    };

    const reset = () => {
        setBody(defaultBody);
        setFiles([]);
        onClose();
    };

    const createPlanogram = () => {
        setCreateError('');
        const valid: BodyKeys[] = [];

        required.forEach((key) => {
            if (body[key] === null) {
                valid.push(key);
            }
        });

        if (valid.length > 0) {
            setError(valid);

            return;
        }

        setLoader(true);

        createPlanogramRequest({
            ...body,
            type: body?.type?.value,
            project_id: body?.project_id?.value,
        })
            .then(async (res: any) => {
                const { id } = res.planogram;
                const file_links: any = [];
                for (const file of files) {
                    if (file?.file) {
                        file_links.push({
                            file_id: file?.id,
                            file: {
                                url: URL.createObjectURL(file?.file),
                            },
                        });
                    }
                }
                addPlanogram({
                    ...res.planogram,
                    project: {
                        full_name: body?.project_id?.label,
                    },
                    file_links,
                });

                if (files.length) {
                    await sendImages(id);
                }
                onClose();
            })
            .catch((e) => {
                const errorMessage = e?.message || e?.data?.detail || JSON.stringify(e);
                setCreateError(errorMessage);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    const editPlanogram = async (planogram) => {
        try {
            if (planogram) {
                await Promise.all(
                    planogram?.file_links
                        .filter((fl) => !fl?.file?.name?.includes('.webp'))
                        .map(async (fl) => {
                            if (!images.find((img) => img.id === fl.id)) {
                                await deleteFeedPhoto(fl.id);
                                const findPreview = planogram?.file_links.find(
                                    (img) => img.extend_data?.original_file_link_id === fl.id
                                );
                                if (findPreview) {
                                    await deleteFeedPhoto(findPreview.id);
                                }
                            }
                        })
                );
            }
            const editedPlanogram = await editPlanogramRequest(planogram.id, {
                ...body,
                type: body?.type?.value,
                project_id: body?.project_id?.value,
            });

            if (editedPlanogram && files.length) {
                sendImages(planogram?.id);
            }
            const file_links: any = [];
            for (const file of files) {
                if (file?.file) {
                    file_links.push({
                        file_id: file?.id,
                        file: {
                            url: URL.createObjectURL(file?.file),
                        },
                    });
                }
            }
            if (editedPlanogram.file_links?.length) {
                editedPlanogram.planogram.file_links = [
                    ...editedPlanogram.file_links,
                    ...file_links,
                    ...images,
                ];
            } else {
                editedPlanogram.planogram.file_links = [...file_links, ...images];
            }
            setPlanograms((prev) => {
                return prev.map((planogram) => {
                    if (planogram?.id === editedPlanogram?.planogram?.id) {
                        return editedPlanogram.planogram;
                    } else {
                        return planogram;
                    }
                });
            });
            onClose();
        } catch (e) {
            const errorMessage = e?.message || e?.data?.detail || JSON.stringify(e);
            setCreateError(errorMessage);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        setError([]);
        setCreateError('');
    }, [body]);

    useEffect(() => {
        if (planogram) {
            const { project, text, name, type } = planogram;

            setBody({
                project_id: { label: project?.full_name, value: project?.id },
                text,
                name,
                type: { label: PlanogramTypesName[type], value: type },
            });
        }

        return () => reset();
    }, []);

    useEffect(() => {
        const imagesWithoutPreview = planogram?.file_links?.filter(
            (fl) => !fl?.file?.name?.includes('.webp')
        );
        setImages(imagesWithoutPreview);
    }, [planogram]);

    return (
        <Modal className="planograms__modal" onClose={onClose}>
            <div className="planogramsСreate">
                {loader ? (
                    <div className="planogramsСreate__loader">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <h3>
                            {planogram
                                ? t('catalog.planograms.title')
                                : t('catalog.planograms.new')}
                        </h3>
                        <div className="planogramsСreate__fields">
                            <div className="planogramsСreate__field">
                                <TextInput
                                    error={
                                        check(BodyKeys.Name)
                                            ? t('messages.fieldRequired')
                                            : undefined
                                    }
                                    label={t('common.name')}
                                    placeholder={t('common.enterName')}
                                    value={body.name || ''}
                                    onChange={(e) => changeBody(BodyKeys.Name, clearStr(e))}
                                />
                            </div>
                            <div className="planogramsСreate__field">
                                <AsyncSelect
                                    label={t('common.project')}
                                    name="project"
                                    optionsLoaderConfig={{
                                        mapper: (data) => ({
                                            label: data.full_name,
                                            value: data.id,
                                        }),
                                        request: () => fetchProjects({}),
                                    }}
                                    value={body.project_id || undefined}
                                    onChange={(option) => {
                                        changeBody(BodyKeys.ProjectId, option);
                                    }}
                                    error={check(BodyKeys.ProjectId)}
                                    disabled={Boolean(planogram)}
                                />
                            </div>
                        </div>
                        <div className="planogramsСreate__types">
                            <div>{t('catalog.planograms.type')}</div>
                            <div className="planogramsСreate__icons">
                                <DispenserIcon
                                    color={body?.type?.value === 'fem_hygiene' ? 'red' : '#414152'}
                                />
                                <DropIcon
                                    color={body?.type?.value === 'cleaning' ? 'red' : '#414152'}
                                />
                                <PersonIcon
                                    color={body?.type?.value === 'body_care' ? 'red' : '#414152'}
                                />
                                <PramIcon
                                    color={
                                        body?.type?.value === 'child_hygiene' ? 'red' : '#414152'
                                    }
                                />
                                <RazorIcon
                                    color={body?.type?.value === 'shaving' ? 'red' : '#414152'}
                                />
                                <ToothIcon
                                    color={body?.type?.value === 'teeth_care' ? 'red' : '#414152'}
                                />
                                <TshirtIcon
                                    color={body?.type?.value === 'fabric_care' ? 'red' : '#414152'}
                                />
                                <ScissorsIcon
                                    color={body?.type?.value === 'hair_care' ? 'red' : '#414152'}
                                />
                                <BookIcon
                                    color={body?.type?.value === 'book' ? 'red' : '#414152'}
                                />
                            </div>
                            <div className="planogramsСreate__typesSelector">
                                <Select
                                    name="status"
                                    placeholder={t('common.all')}
                                    options={Object.keys(PlanogramTypesName).map((type) => {
                                        return {
                                            value: type,
                                            label: PlanogramTypesName[type],
                                        };
                                    })}
                                    value={body?.type || undefined}
                                    onChange={(option) => {
                                        changeBody(BodyKeys.Type, option);
                                    }}
                                    error={check(BodyKeys.Type)}
                                />
                            </div>
                        </div>
                        <ImagesPicker
                            files={files}
                            images={images}
                            onChange={setFiles}
                            slider={slider}
                            setSlider={setSlider}
                            setSelectedPhoto={setSelectedPhoto}
                            setImages={setImages}
                        />
                        <TextAreaInput
                            error={{ status: check(BodyKeys.Text) }}
                            label={t('common.description')}
                            placeholder={t('common.enterDescription')}
                            value={body.text || ''}
                            onChange={(e: any) => changeBody(BodyKeys.Text, clearStr(e))}
                        />
                        {createError && (
                            <div className="error">
                                {t('messages.error')}
                                {': '}
                                {JSON.stringify(createError)}
                            </div>
                        )}
                        <div className="planogramsСreate__wrapBtns">
                            <div className="planogramsСreate__btns">
                                {!Boolean(planogram) && (
                                    <Button onClick={createPlanogram}>{t('buttons.create')}</Button>
                                )}
                                {Boolean(planogram) && (
                                    <Button
                                        onClick={() => {
                                            editPlanogram(planogram);
                                        }}
                                    >
                                        {t('buttons.save')}
                                    </Button>
                                )}
                                <Button onClick={reset}>{t('buttons.cancel')}</Button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};
