import React, { FC, useEffect, useState } from 'react';
import styles from './VisitStep.module.scss';
import { Button, TextareaAutosize, DatePicker } from '@components';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { extractErrorMessage } from '@tools/utils/functions';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { getRestoredVisitAvailableDates } from '@apiFeature/visits/restoredVisit';
import ImageUpload from '../../ImageUpload/ImageUpload';

const VisitStep: FC = () => {
    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();
    const { control, watch, setValue, formState, trigger } = useFormContext() || {};
    const {
        date,
        user_id,
        project_outlet_id,
        signboardPhoto,
        signboardPhotoId,
        startTime,
        endTime,
        photosDelete = [],
    } = watch();
    const { isSubmitting } = formState || {};

    const [availableDates, setAvailableDates] = useState([]);

    const getRandomTime = (date) => {
        const min = 9;
        const max = 19;
        const baseHour = Math.floor(min + Math.random() * (max + 1 - min));
        const baseMinute = Math.floor(Math.random() * 60);

        const timeDifference = Math.floor(Math.random() * 11) + 20;

        const secondHour = (baseHour + Math.floor((baseMinute + timeDifference) / 60)) % 24;
        const secondMinute = (baseMinute + timeDifference) % 60;

        const startTime = new Date(date);
        startTime.setHours(baseHour);
        startTime.setMinutes(baseMinute);

        const endTime = new Date(date);
        endTime.setHours(secondHour);
        endTime.setMinutes(secondMinute);

        return [startTime, endTime];
    };

    const handleRandomTime = () => {
        if (date) {
            const [startTime, endTime] = getRandomTime(date);
            setValue('startTime', moment(startTime).toISOString());
            setValue('endTime', moment(endTime).toISOString());
            trigger('startTime');
            trigger('endTime');
        } else {
            enqueueSnackbar(t('common.selectDate'), { variant: 'error' });
        }
    };

    const handleChangeDate = (e, name) => {
        setValue(name, e);
        if (startTime) {
            handleChangeTime(startTime, 'startTime', e);
        }
        if (endTime) {
            handleChangeTime(endTime, 'endTime', e);
        }
        trigger('startTime');
        trigger('endTime');
        trigger('date');
    };

    const handleChangeTime = (e, name, dateInit = null) => {
        const dateValue = dateInit || date;
        if (dateValue) {
            const newDate = moment(dateValue)
                .hour(moment(e).hour())
                .minute(moment(e).minute())
                .second(0)
                .millisecond(0)
                .toISOString();
            setValue(name, newDate);
        } else {
            setValue(name, e);
        }
        trigger('startTime');
        trigger('endTime');
    };

    useEffect(() => {
        const today = new Date();
        const oneMonthBeforeToday = moment(today).subtract(1, 'month').format('YYYY-MM-DD');
        const twoMonthsFromToday = moment(today).add(2, 'month').format('YYYY-MM-DD');
        if (user_id && project_outlet_id) {
            getRestoredVisitAvailableDates({
                user_id,
                project_outlet_id,
                lower_date: oneMonthBeforeToday,
                upper_date: twoMonthsFromToday,
            })
                .then((res) => {
                    const { dates } = res || {};
                    setAvailableDates(dates);
                })
                .catch((e) => {
                    enqueueSnackbar(extractErrorMessage(e), { variant: 'error' });
                });
        }
    }, [user_id, project_outlet_id]);

    return (
        <div className={styles.formItems}>
            <div className={styles.dateTime}>
                <div>
                    <Controller
                        name="date"
                        control={control}
                        render={({ field: { name, value, ref }, fieldState: { error } }) => (
                            <DatePicker
                                id={name}
                                name={name}
                                inputRef={ref}
                                label={t('feed.visitDate')}
                                onChange={(e) => {
                                    handleChangeDate(e, name);
                                }}
                                value={value}
                                enable={availableDates}
                                dateFormat="yyyy-MM-dd"
                                disabled={isSubmitting}
                                error={error}
                            />
                        )}
                    />
                </div>
                <div>
                    <div className={styles.timeBlock}>
                        <div>
                            <Controller
                                name="startTime"
                                control={control}
                                render={({
                                    field: { name, value, ref },
                                    fieldState: { error },
                                }) => (
                                    <DatePicker
                                        className={styles.timePicker}
                                        id={name}
                                        name={name}
                                        inputRef={ref}
                                        label={t('filledVisit.startTime')}
                                        onChange={(e) => {
                                            handleChangeTime(e, name);
                                        }}
                                        value={value}
                                        enable={[]}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeFormat="HH:mm"
                                        dateFormat="HH:mm"
                                        disabled={isSubmitting}
                                        error={error}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                name="endTime"
                                control={control}
                                render={({
                                    field: { name, value, ref },
                                    fieldState: { error },
                                }) => (
                                    <DatePicker
                                        className={styles.timePicker}
                                        id={name}
                                        name={name}
                                        inputRef={ref}
                                        label={t('filledVisit.endTime')}
                                        onChange={(e) => {
                                            handleChangeTime(e, name);
                                        }}
                                        value={value}
                                        enable={[]}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeFormat="HH:mm"
                                        dateFormat="HH:mm"
                                        disabled={isSubmitting}
                                        error={error}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <Button
                            className={styles.textBtn}
                            onClick={() => {
                                handleRandomTime();
                            }}
                            color="secondary"
                            disabled={isSubmitting}
                        >
                            {t('filledVisit.randomDate')}
                        </Button>
                    </div>
                </div>
            </div>
            <div>
                <Controller
                    name="comment"
                    control={control}
                    render={({ field: { onChange, name, value }, fieldState: { error } }) => (
                        <TextareaAutosize
                            id={name}
                            minRows={4}
                            onChange={onChange}
                            value={value || ''}
                            label={t('filledVisit.outletComment')}
                            disabled={isSubmitting}
                            error={error}
                        />
                    )}
                />
            </div>
            <div className={styles.signBoardPhoto}>
                <label>{t('filledVisit.signBoardPhoto')}</label>
                <Controller
                    control={control}
                    name="signboardPhoto"
                    render={({ field: { onChange } }) => (
                        <ImageUpload
                            existingImages={[]}
                            onImagesChange={(files) => {
                                onChange(files.map((e) => e.url));
                                setValue('photosDelete', [...photosDelete, signboardPhotoId]);
                            }}
                            multiple={false}
                            signboardPhoto={signboardPhoto}
                            disabled={isSubmitting}
                        />
                    )}
                />
            </div>
        </div>
    );
};

export default VisitStep;
