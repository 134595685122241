import React, { FC, ReactNode } from 'react';
import { Button as BT } from '@mui/material';
import classNames from 'classnames';
import styles from './Button.module.scss';

interface IButton {
    className?: string | undefined;
    children: ReactNode;
    type?: string;
}

const Button: FC<IButton> = ({ children, className, ...props }) => {
    return (
        <BT {...props} className={classNames(styles.button, className)}>
            {children}
        </BT>
    );
};

export default Button;
