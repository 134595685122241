import React, { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import ruLocale from 'date-fns/locale/ru';
import BasePicker from 'react-datepicker';
import { Button } from 'src/view/components/ui/Button/Button';
import { DatePickerHeader } from 'src/view/components/ui/form/date/DatePickerHeader';
import { ReactComponent as ArrowIcon } from '@images/svg/chevron-down.svg';
import 'react-datepicker/dist/react-datepicker.min.css';
import './DatePicker.scss';
import { useTranslation } from 'react-i18next';
import 'moment/locale/ru';
import i18n from '../../../../../i18n';

export interface DatePickerProps {
    label?: string;
    value?: string;
    className?: string;
    placeholder?: string;
    dateFormat?: string;
    disabled?: boolean;
    onChange: (value?: string | null) => void;
    onBlur?: () => void;
}

export const DatePicker: React.FC<DatePickerProps> = ({
    label,
    value,
    placeholder = 'Любая',
    className,
    dateFormat,
    disabled,
    onBlur,
    onChange,
}) => {
    const { t } = useTranslation('translation');
    const currentLocale = i18n.language === 'ru' ? ruLocale : i18n.language;

    const [valueString, setValueString] = useState<string | undefined | null>(value);
    const { valueDate } = useMemo(
        () => ({
            valueDate: (valueString && new Date(valueString)) || null,
        }),
        [valueString]
    );

    useEffect(() => {
        if (value && value !== valueString) {
            setValueString(value);
        } else if (value === null) {
            setValueString(value);
        }
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    const [showYearPicker, setShowYearPicker] = useState(false);
    const [open, setOpen] = useState(false);

    const openCalendar = () => {
        setOpen(true);
    };

    const handleClickOutside = () => {
        setOpen(false);
        if (value && value !== valueString) {
            setValueString(value);
        } else if (value === null) {
            setValueString(value);
        }
        handleSubmit();
    };

    const handleYearClick = () => {
        setShowYearPicker(true);
    };

    const fixTimezoneOffset = (date: Date): Date | string => {
        if (!date) return '';

        return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    };

    const handleChange = (date: Date | null) => {
        if (showYearPicker) {
            setShowYearPicker(false);
        }
        setValueString(date?.toISOString());
    };

    const handleSubmit = () => {
        onChange(valueString);
        setOpen(false);
    };

    return (
        <div
            ref={(el) => {
                const monthLabel = el?.querySelector('.react-datepicker__current-month');
                if (monthLabel) {
                    monthLabel.addEventListener('click', () => {
                        setShowYearPicker(true);
                    });
                }
            }}
            className={cn('date-picker-wrapper', { 'date-picker--open': open }, className)}
        >
            {label}
            <BasePicker
                dateFormat={dateFormat}
                disabled={disabled}
                locale={currentLocale}
                open={open}
                placeholderText={placeholder}
                renderCustomHeader={(props) => (
                    <DatePickerHeader
                        {...props}
                        yearPicker={showYearPicker}
                        onYearClick={handleYearClick}
                    />
                )}
                selected={valueDate === null ? valueDate : fixTimezoneOffset(valueDate)}
                showYearPicker={showYearPicker}
                onBlur={onBlur}
                onCalendarOpen={openCalendar}
                onChange={handleChange}
                onClickOutside={handleClickOutside}
            >
                <div className="text-center pb-7">
                    <Button className="date-picker__submit" type="default" onClick={handleSubmit}>
                        {t('common.submit')}
                    </Button>
                </div>
            </BasePicker>
            <ArrowIcon
                className={cn('date-picker-icon transition-transform', { 'rotate-180': open })}
            />
        </div>
    );
};
