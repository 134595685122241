import React from 'react';
import { EditInstanceLayoutHeader } from './components/EditInstanceLayoutHeader';
import { EditInstanceLayoutFooter } from './components/EditInstanceLayoutFooter';
import style from './style/EditInstanceLayout.module.scss';

export interface TabsButton {
    title: string;
    to: string;
    isActive: boolean;
}

export interface ActionButton {
    title: string;
    onClick: () => void;
    role?: string;
}

interface EditInstanceLayoutProps {
    children: React.ReactNode;
    title: string;
    tabsButtons?: Array<TabsButton>;
    saveButton?: ActionButton;
    cancelButton?: ActionButton;
    deleteButton?: ActionButton;
    onClickBackButton?: () => void;
}

export const EditInstanceLayout = ({
    children,
    title,
    tabsButtons,
    saveButton,
    cancelButton,
    deleteButton,
    onClickBackButton,
}: EditInstanceLayoutProps) => {
    return (
        <>
            <EditInstanceLayoutHeader
                onClickBackButton={onClickBackButton}
                title={title}
                tabsButtons={tabsButtons}
            />
            <div className={style.children}>{children}</div>
            <EditInstanceLayoutFooter
                saveButton={saveButton}
                cancelButton={cancelButton}
                deleteButton={deleteButton}
            />
        </>
    );
};
