import React, { FC, useEffect, useState } from "react";
import { Layout } from '@components';
import { useTranslation } from "react-i18next";
import QuestionnairesFilters from "./QuestionniaresFilters/QuestionnairesFilters";
import QuestionniaresHeader from "./QuestionnairesHeader/QuestionnairesHeader";
import QuestionnairesData from "./QuestionnairesData/QuestionnairesData";
import { useURLFilters } from '@tools/hooks/useURLFilters';

export const Questionnaires: FC = () => {
    const { t } = useTranslation('translation');
    const { parseURL } = useURLFilters();
    const project_id = sessionStorage.getItem('project_id');
    const [defaultValues, setDefaultValues] = useState({});

    const getFilters = (values) => {
        let res = {};
        if (values.project_id) res = { ...res, project_id: values?.project_id };
        if (values.kind) res = { ...res, kind: values.kind };
        if ('status' in values) res = { ...res, is_active: values.status };
        if (values.external_code) res = { ...res, external_code: values.external_code };
        if (values.name) res = { ...res, name: values.name };
        return res;
    };

    useEffect(() => {
        const filterValuesFromUrl = parseURL();
        setDefaultValues({
            project_id: project_id !== 'undefined' ? project_id : '',
            ...filterValuesFromUrl,
            status: true,
        });
    }, []);

    return Object.keys(defaultValues)?.length > 0 ? (
        <Layout
            title={t('questionnaires.title')}
            renderSearch={() => ({ name: 'name', placeholder: t('common.searchName') })}
            renderHeader={(props) => (
                <QuestionniaresHeader  {...props} />
            )}
            renderData={(props) => <QuestionnairesData {...props} defaultValues={defaultValues} />}
            renderFilters={QuestionnairesFilters}
            defaultValues={defaultValues}
            getFilters={getFilters}
        />
    ): (
        <></>
    );
};