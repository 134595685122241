import { createAsyncThunk } from '@reduxjs/toolkit';
import { reduxStoreName } from './types';
import { ErrorApi } from '@apiFeature/types';
import { fetchAllSettings, fetchAllUserSettings, fetchSettingsOptions } from '@apiFeature/settings';

export module SettingsThunks {
    export const getAllSettings = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/getAllSettings`,
        async (arg, { rejectWithValue }) => {
            try {
                return await fetchAllSettings(arg);
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );

    export const getAllUserSettings = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/getAllUserSettings`,
        async (arg, { rejectWithValue }) => {
            try {
                return await fetchAllUserSettings(arg);
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );
    export const getSettingsOptions = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/getSettingsOptions`,
        async (arg, { rejectWithValue }) => {
            try {
                return await fetchSettingsOptions(arg);
            } catch (e) {
                return rejectWithValue(e)
            }
        },
    );
}
