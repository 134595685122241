import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './PhotoReportData.module.scss';
import { TableGrid, Button } from '@components';
import { useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import { useNavigate } from 'react-router-dom';
import { formatters } from '@tools/utils/date.util';
import { renderErrors } from '@tools/utils/functions';
import { exportProcessesList, exportProcessById } from '@apiFeature/reports';
import { exportProcessStatusNames } from '@apiFeature/reports/type';
import { t } from 'i18next';
import downloadFileIcon from '@images/svg/download-file.svg';

const limit = 10;

const columns = [
    {
        field: 'id',
        headerName: t('reports.reports.exportId'),
        minWidth: 320,
        valueGetter: ({ value }) => value || '-',
        sortable: false,
    },
    {
        field: 'created_at',
        headerName: t('questionnaires.createDate'),
        minWidth: 150,
        valueGetter: ({ value }) =>
            value ? formatters.toISODateFormatString(value, 'dd.MM.yyyy HH:mm') : value,
    },
    {
        field: 'status',
        headerName: t('common.status'),
        minWidth: 180,
        renderCell: ({ value }) =>
            value ? (
                <span
                    className={classNames(
                        styles.status,
                        styles[`status-${value.toLocaleLowerCase()}`]
                    )}
                >
                    {exportProcessStatusNames[value]}
                </span>
            ) : (
                '-'
            ),
    },
    {
        field: 'error_data',
        headerName: t('messages.error'),
        minWidth: 130,
        valueGetter: ({ value }) => renderErrors(value),
        sortable: false,
    },
    {
        field: 'user_name',
        headerName: t('common.user'),
        minWidth: 250,
        flex: 1,
        sortable: false,
    },
    {
        field: 'file_links',
        headerName: t('reports.reports.fileLinks'),
        minWidth: 170,
        sortable: false,
        renderCell: ({ value: file_links }) => (
            <div style={{ textAlign: 'center', width: '100%' }}>
                {file_links && file_links?.length > 0
                    ? file_links.map((item) => (
                          <div
                              key={item?.file?.id}
                              onClick={(e) => e.stopPropagation()}
                              title={item?.file?.name}
                          >
                              <Button href={item?.file?.url}>
                                  <img src={downloadFileIcon} alt="" />
                              </Button>
                          </div>
                      ))
                    : '-'}
            </div>
        ),
    },
];

const PhotoReportData = ({ defaultValues, filters }) => {
    const project_id = filters?.project_id || defaultValues?.project_id;
    const kind = filters?.kind || defaultValues?.kind;
    const status = filters?.status || defaultValues?.status;
    const export_process_id = filters?.export_process_id;

    const paginationModelDefault = { page: 0, pageSize: limit };
    const s = useSelector((s) => s?.auth);
    const { projectsList } = useSelector(ProjectsSelectors.projectsSelectors);
    const [isLoading, setLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState(paginationModelDefault);
    const [sortModel, setSortModel] = useState([]);
    const [data, setData] = useState({});
    const { items = [], total = 0, offset } = data || {};

    const getProcessesList = useCallback(
        (offset = 0, sortModel = []) => {
            setLoading(true);

            const [project] = projectsList || [];
            const { id } = project || {};
            const { project_id } = defaultValues || {};
            const project_id_res = projectsList.find((p) => p.id === project_id)?.id ?? id;

            const [firstSort] = sortModel || [];
            const { field, sort } = firstSort || {};
            let sorting = { sorting: { created_at: 'desc' } };
            if (field) sorting = { sorting: { [field]: sort } };

            let data = {
                project_id: project_id_res,
                ...defaultValues,
                ...filters,
                ...sorting,
                pagination: {
                    limit,
                    offset,
                },
            };

            exportProcessesList(data).then(async (res) => {
                setLoading(false);
                if (res?.export_processes) {
                    const data = res.export_processes;
                    const { last_name, first_name } = s?.user;

                    const items = await Promise.all(
                        data?.items?.map(async (e) => {
                            const { export_process: { file_links = [] } = {} } =
                                (await exportProcessById(e.id)) || {};
                            return {
                                ...e,
                                user_name: `${last_name} ${first_name}`,
                                file_links,
                            };
                        })
                    );

                    setData({ ...data, items });
                }
            });
        },
        [projectsList, filters]
    );

    useEffect(() => {
        if (projectsList?.length > 0 && project_id) {
            getProcessesList(paginationModel?.page * limit, sortModel);
        }
    }, [sortModel, paginationModel, projectsList]);

    useEffect(() => {
        setPaginationModel(paginationModelDefault);
    }, [project_id, kind, status, export_process_id]);

    const navigate = useNavigate();
    const handleClick = (id) => {
        navigate('/reports/' + id);
    };

    return (
        <TableGrid
            rows={items}
            columns={columns}
            rowCount={total}
            loading={isLoading}
            pageSizeOptions={[limit]}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            paginationModel={offset === 0 ? paginationModelDefault : paginationModel}
            sortingMode="server"
            onSortModelChange={setSortModel}
            isRowSelectable={({ id }) => handleClick(id)}
            hasSun
            className={styles.tableGrid}
        />
    );
};

export default PhotoReportData;
