import React, { FC } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import FakeVisitAction from '@/redux/fakeVisit/actions';
import { QuestionnairesDetailed } from '@types/questionnaires';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Button, TextField, Select, PhotoSlider } from '@components';
import { useAppSelector } from '@tools/hooks/redux';
import { permissionCheck } from '@tools/utils/permissionCheck';
import { getIntegerValue } from '@tools/utils/string.utils';
import { useRoles } from '@tools/hooks/useRoles';
import { getOptions } from '@tools/utils/functions';
import { QuestionTypeKind } from '@apiFeature/questionnaires/type';
import { Tooltip } from '@mui/material';
import styles from './Reports.module.scss';
import { PhotoPicker } from '../../legacy/PhotoPicker/PhotoPicker';

interface IReports {
    visit_id?: string;
    taskId?: string;
    question: QuestionnairesDetailed;
    handleSaveReports: (patch_res, post_res) => void;
    isChecker?: boolean;
    photoDescription?: Record<string, unknown>;
    utc_offset?: number | null;
    is_step_description_grouped?: boolean;
}

const Reports: FC<IReports> = ({
    visit_id,
    taskId,
    photoDescription,
    question,
    handleSaveReports,
    isChecker = false,
    utc_offset,
    is_step_description_grouped,
}) => {
    const dispatch = useDispatch();
    const authRoles = useAppSelector((state) => state?.auth?.jwtPayloadOpen?.roles);
    const { isAvailableDelete, clientPermission } = useRoles();

    const { t } = useTranslation('translation');
    const noAnswer = t('messages.noAnswer');

    const { questionnaire_steps, desc_group } = question || {};
    const descNames = desc_group?.length
        ? desc_group.map((descItem) => Object.keys(descItem)[0])
        : [];

    const form = useForm({
        mode: 'onChange',
        defaultValues: { isEdit: false },
    });
    const { control, reset, setValue, handleSubmit, watch } = form;
    const currentValues = watch();
    const { isEdit } = currentValues || {};

    const handleChange = (name, e, id) => {
        let val = e?.target?.value || (e?.value ? [e?.value] : null);
        if (e && Array.isArray(e)) {
            val = e.length > 0 ? e.map((item) => item.value) : null;
        }

        setValue(
            name,
            {
                question_id: id,
                visit_id: visit_id,
                generic_type: 'SFA_VISIT', // SFA_VISIT, CROWD_TASK_RESERVE
                answer: val,
            },
            { shouldTouch: true }
        );
    };

    const getAnswer = (data, i, ii) => {
        const { id, question_result, question_answers, kind } = data || {};
        const { answer } = question_result || {};

        const questionAnswersOptions = getOptions(question_answers, 'answer');

        const many_answers_res = Array.isArray(answer)
            ? answer
                  ?.map((answers) =>
                      question_answers
                          ?.filter((quest_answ) => quest_answ?.id === answers)
                          ?.map((e) => e.answer)
                  )
                  ?.join(', ') ?? noAnswer
            : noAnswer;

        const one_answer_res = Array.isArray(answer)
            ? answer?.map((answers) =>
                  question_answers?.filter((quest_answ) => quest_answ?.id === answers)
              )?.[0]?.[0]?.answer ?? noAnswer
            : noAnswer;

        const answer_num_res = !Array.isArray(answer) ? answer ?? noAnswer : noAnswer;

        const name = question_result !== null ? 'patch' : 'post';
        const nameItem = `${name}.${i}.${ii}`;

        if (data) {
            switch (kind) {
                case QuestionTypeKind.many_answers:
                    return !isEdit ? (
                        many_answers_res
                    ) : (
                        <Tooltip placement="top" title={t('questionnaires.manyAnswers')}>
                            <div className={styles.formItem}>
                                <Controller
                                    name={nameItem}
                                    control={control}
                                    render={({
                                        field: { name, value, ref },
                                        fieldState: { isTouched },
                                    }) => {
                                        const val = isTouched ? value?.answer : answer;
                                        return (
                                            <Select
                                                id={name}
                                                name={name}
                                                inputRef={ref}
                                                onChange={(e) => handleChange(name, e, id)}
                                                value={
                                                    val
                                                        ? questionAnswersOptions?.filter((e) =>
                                                              val.includes(e.value)
                                                          )
                                                        : null
                                                }
                                                options={questionAnswersOptions}
                                                isMulti={true}
                                                isClearable={true}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Tooltip>
                    );
                case QuestionTypeKind.one_answer:
                    return !isEdit ? (
                        one_answer_res
                    ) : (
                        <Tooltip placement="top" title={t('questionnaires.oneAnswer')}>
                            <div className={styles.formItem}>
                                <Controller
                                    name={nameItem}
                                    control={control}
                                    render={({
                                        field: { name, value, ref },
                                        fieldState: { isTouched },
                                    }) => {
                                        const val = isTouched ? value?.answer : answer;
                                        return (
                                            <Select
                                                id={name}
                                                name={name}
                                                inputRef={ref}
                                                onChange={(e) => handleChange(name, e, id)}
                                                value={
                                                    val
                                                        ? questionAnswersOptions?.filter((e) =>
                                                              val.includes(e.value)
                                                          )
                                                        : null
                                                }
                                                options={questionAnswersOptions}
                                                isClearable={true}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Tooltip>
                    );
                case QuestionTypeKind.number_answer:
                    return !isEdit ? (
                        answer_num_res
                    ) : (
                        <Tooltip placement="top" title={t('questionnaires.number')}>
                            <div className={styles.formItem}>
                                <Controller
                                    name={nameItem}
                                    control={control}
                                    render={({
                                        field: { name, value },
                                        fieldState: { isTouched },
                                    }) => {
                                        const val = isTouched ? value?.answer : answer;
                                        return (
                                            <TextField
                                                onChange={(e) => handleChange(name, e, id)}
                                                value={val}
                                                id={name}
                                                variant="outlined"
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Tooltip>
                    );
                case QuestionTypeKind.integer_answer:
                    return !isEdit ? (
                        answer_num_res
                    ) : (
                        <Tooltip placement="top" title={t('questionnaires.integerAnswer')}>
                            <div className={styles.formItem}>
                                <Controller
                                    name={nameItem}
                                    control={control}
                                    render={({
                                        field: { name, value },
                                        fieldState: { isTouched },
                                    }) => {
                                        const val = isTouched ? value?.answer : answer;
                                        return (
                                            <TextField
                                                onChange={(e) =>
                                                    handleChange(
                                                        name,
                                                        {
                                                            ...e,
                                                            target: {
                                                                ...e.target,
                                                                value: getIntegerValue(
                                                                    e.target.value
                                                                ),
                                                            },
                                                        },
                                                        id
                                                    )
                                                }
                                                inputProps={{ maxLength: 15 }}
                                                value={val || ''}
                                                id={name}
                                                variant="outlined"
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Tooltip>
                    );
                case QuestionTypeKind.your_answer:
                    return !isEdit ? (
                        answer_num_res
                    ) : (
                        <Tooltip placement="top" title={t('questionnaires.text')}>
                            <div className={styles.formItem}>
                                <Controller
                                    name={nameItem}
                                    control={control}
                                    render={({
                                        field: { name, value },
                                        fieldState: { isTouched },
                                    }) => {
                                        const val = isTouched ? value?.answer : answer;
                                        return (
                                            <TextField
                                                onChange={(e) => handleChange(name, e, id)}
                                                value={val}
                                                id={name}
                                                variant="outlined"
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </Tooltip>
                    );
                case QuestionTypeKind.photo_answer:
                    return null;
                default:
                    return noAnswer;
            }
        }
    };

    const handleEdit = () => {
        if (isEdit) {
            reset();
        } else {
            setValue('isEdit', !isEdit);
            dispatch(FakeVisitAction.cancelPhoto());
        }
    };

    const onSubmit = ({ isEdit, ...data }) => {
        reset();

        const { patch = [], post = [] } = data;
        const patch_res = patch.flat().filter((e) => e);
        const post_res = post.flat().filter((e) => e);
        handleSaveReports(patch_res, post_res);
    };

    const renderSteps = (steps) => {
        return steps.map(({ id, title, description, questions }, i) => (
            <div key={id} className={styles.report}>
                <div className={styles.title}>
                    {title} {description && !is_step_description_grouped ? '|' : ''}{' '}
                    {!is_step_description_grouped && description}
                </div>
                {questions?.length > 0 &&
                    questions?.map((question, ii) => {
                        const {
                            id,
                            name: questionName,
                            question_result,
                            is_photo_required,
                            kind,
                        } = question;

                        const photos = question_result?.file_links || [];
                        return (
                            <div key={id} className={styles.questions}>
                                <div>{questionName}</div>
                                <div>{getAnswer(question, i, ii)}</div>
                                <div>
                                    <div className={styles.photo}>
                                        {photos?.length > 0 && (
                                            <PhotoSlider
                                                id={id}
                                                photos={photos}
                                                type="list"
                                                description={permissionCheck(
                                                    authRoles,
                                                    photoDescription
                                                )}
                                                utc_offset={utc_offset}
                                                hasDeleteBtn={!clientPermission}
                                                isSmall
                                            />
                                        )}
                                    </div>
                                    {isEdit && (is_photo_required || kind === 'photo_answer') && (
                                        <div className={styles.photoPicker}>
                                            <PhotoPicker
                                                isPhotoAnswer={kind === 'photo_answer'}
                                                isSmall
                                                question={question}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </div>
        ));
    };

    return (
        <div className={classNames(styles.reports, { [styles.isChecker]: isChecker })}>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.btns}>
                    {!taskId && !isChecker && isAvailableDelete && (
                        <>
                            {isEdit ? (
                                <>
                                    <Button variant="contained" color="secondary" type="submit">
                                        {t('buttons.save')}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleEdit}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                </>
                            ) : (
                                <Button variant="outlined" color="secondary" onClick={handleEdit}>
                                    {t('buttons.edit')}
                                </Button>
                            )}
                        </>
                    )}
                </div>
                {is_step_description_grouped ? (
                    descNames?.length > 0 &&
                    desc_group.map((descItem, index) => {
                        const steps = descItem[descNames[index]];
                        return (
                            <div key={index}>
                                <div className={styles.descTitle}>{descNames[index]}</div>
                                <div>{renderSteps(steps)}</div>
                            </div>
                        );
                    })
                ) : (
                    <div>{renderSteps(questionnaire_steps)}</div>
                )}
            </form>
        </div>
    );
};

export default Reports;
