import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Map.module.scss';
import { YMaps, Map as YMap, Placemark, ZoomControl, SearchControl } from '@pbe/react-yandex-maps';
import { apikey } from '@/constants';

interface IMap {
    isEdit: boolean;
    latitude: number;
    longitude: number;
    getCoords: (e: Array<number>) => void;
}

const Map: FC<IMap> = ({ isEdit, latitude, longitude, getCoords }) => {
    const { t } = useTranslation('translation');

    return (
        <YMaps query={{ apikey, lang: 'ru_RU' }}>
            <YMap
                className={styles.map}
                defaultState={{ center: [latitude, longitude], zoom: 16 }}
                state={{ center: [latitude, longitude], zoom: 16 }}
                onClick={(e) => isEdit && getCoords(e._sourceEvent.originalEvent.coords)}
            >
                {isEdit && (
                    <>
                        <SearchControl
                            options={{
                                noPlacemark: true,
                            }}
                        />
                        <ZoomControl />
                    </>
                )}
                <Placemark
                    defaultGeometry={[latitude, longitude]}
                    geometry={[latitude, longitude]}
                    options={{ preset: 'islands#blueHomeCircleIcon' }}
                    properties={{
                        iconCaption: t('common.project_outlet'),
                    }}
                />
            </YMap>
        </YMaps>
    );
};

export default Map;
