import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { permissionCheck } from '@tools/utils/permissionCheck';
import { getFullName } from '@tools/utils/users';
import { Button, PhotoSlider, Select, DatePicker, TextField, Loader } from '@components';
import ListMapper from '@components/Lists/List/ListMapper/ListMapper';
import { Tooltip } from '@mui/material';
import { useAppSelector } from '@tools/hooks/redux';
import { useSelector } from 'react-redux';
import FiltersSelectors from '@redux/filters/selectors';
import { CheckCircleOutline, PublishedWithChanges, AccessTime } from '@mui/icons-material';
import { useRoles } from '@tools/hooks/useRoles';
import { useForm, Controller } from 'react-hook-form';
import { taskStatusOptions } from '@constants';
import { convertToOriginalDate } from '@tools/utils/utcOffsetConvert';
import { updateTaskReport } from '@apiFeature/tasks/tasks';
import { extractErrorMessage } from '@tools/utils/functions';
import { fixTimezoneOffset } from '@tools/utils/date.util';
import { useSnackbar } from 'notistack';
import styles from './TasksList.module.scss';
import { fetchFeedsPhotos } from '@api/visitFeed/visitFeed.api';

const TasksList = (props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [reportData, setReportData] = useState<any>({});
    const [photosFromTask, setPhotosFromTask] = useState([]);
    const authRoles = useAppSelector((state) => state?.auth?.jwtPayloadOpen?.roles);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const { branches, cities } = useSelector(FiltersSelectors.filtersState);
    const { isClient, clientPermission, isTaskReportUpdateAllowed } = useRoles();

    const {
        isTaskCreateBlocked,
        isTasksAuthor,
        id,
        project_outlet,
        project_short_name,
        created_by_name,
        created_at,
        deadline,
        task_report,
        caused_by_visit_id,
        description,
        is_photo_required,
        is_required,
        execution_status,
    } = props;
    const { name, external_code, address, city_id, branch_id } = project_outlet;
    const { id: task_report_id } = task_report || {};
    const {
        visit_id,
        status,
        user,
        report,
        file_links,
        utc_offset: reportOffset,
        end_time,
    } = reportData || {};
    const { comment } = report || {};
    const statusValue = status
        ? taskStatusOptions.find((opt) => opt.value === status)?.label
        : null;
    const createdAtOffset = convertToOriginalDate(created_at, reportOffset, 'DD.MM.YYYY, HH:mm:ss');
    const deadlineOffset = convertToOriginalDate(deadline, reportOffset, 'DD.MM.YYYY, HH:mm:ss');
    const executedAtOffset = convertToOriginalDate(end_time, reportOffset, 'DD.MM.YYYY, HH:mm:ss');

    let taskItem;
    if (execution_status === 'DONE') {
        taskItem = (
            <Tooltip title={t('tasks.completed')} placement="top" arrow>
                <CheckCircleOutline className={styles.green} />
            </Tooltip>
        );
    } else if (execution_status === 'PENDING') {
        taskItem = (
            <Tooltip title={t('tasks.pending')} placement="top" arrow>
                <PublishedWithChanges className={styles.yellow} />
            </Tooltip>
        );
    } else if (execution_status === 'OVERDUE') {
        taskItem = (
            <Tooltip title={t('tasks.overdue')} placement="top" arrow>
                <AccessTime className={styles.red} />
            </Tooltip>
        );
    }

    const form = useForm({ mode: 'onChange' });
    const { reset, setValue, control, handleSubmit, watch } = form;
    const { isEdit } = watch();

    useEffect(() => {
        if (task_report_id) {
            setReportData(task_report);

            const { status, report, end_time } = task_report || {};
            const { comment } = report || {};

            const defaultValues = {
                isEdit: false,
                status,
                end_time: fixTimezoneOffset(end_time),
                report: { comment },
            };

            reset(defaultValues);
        }
    }, [task_report_id]);

    useEffect(() => {
        if (id) {
            fetchFeedsPhotos({
                generic_id: id,
            }).then((res) => {
                setPhotosFromTask(res);
            }).catch((e) => enqueueSnackbar(e?.message, { variant: 'error' }));
        }
    }, [id]);

    const serializedFeedItem = {
        [t('tasks.authorTask')]: {
            value: (isClient && isTasksAuthor) || !isClient ? created_by_name : '-',
            isVisible: true,
        },
        [t('tasks.setDate')]: {
            value: createdAtOffset ?? '-',
            isVisible: true,
        },
        [t('tasks.deadline')]: {
            value: deadlineOffset ?? '-',
            isVisible: true,
        },
        [t('tasks.description')]: {
            value: description ?? '-',
            isVisible: true,
        },
        [t('tasks.isRequiredPhoto')]: {
            value: is_photo_required ? t('common.yes') : t('common.no'),
            isVisible: true,
        },
        [t('tasks.isRequiredTask')]: {
            value: is_required ? t('common.yes') : t('common.no'),
            isVisible: true,
        },
    };

    const serializedFeedReportItem = {
        [t('feed.taskStatus')]: {
            value: isEdit ? (
                <Controller
                    name="status"
                    control={control}
                    render={({ field: { name, value, ref } }) => (
                        <div className={styles.formItem}>
                            <Select
                                id={name}
                                name={name}
                                inputRef={ref}
                                onChange={(e) => {
                                    setValue(name, e?.value || null);
                                }}
                                value={taskStatusOptions?.find((e) => e.value === value)}
                                options={taskStatusOptions}
                            />
                        </div>
                    )}
                />
            ) : (
                statusValue ?? '-'
            ),
            isVisible: true,
        },
        [t('common.user')]: {
            value: !isClient && user ? getFullName(user) : '-',
            isVisible: true,
        },
        [t('tasks.finishedTime')]: {
            value: isEdit ? (
                <Controller
                    name="end_time"
                    control={control}
                    render={({ field: { name, value, ref, onChange }, fieldState: { error } }) => (
                        <div className={styles.formItem}>
                            <DatePicker
                                id={name}
                                name={name}
                                inputRef={ref}
                                onChange={onChange}
                                value={value}
                                enable={[]}
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                timeIntervals={1}
                                timeCaption="Time"
                                showTimeSelect
                                timeFormat="HH:mm:ss"
                                error={error}
                            />
                        </div>
                    )}
                />
            ) : (
                executedAtOffset ?? '-'
            ),
            isVisible: true,
        },
        [t('tasks.comment')]: {
            value: isEdit ? (
                <Controller
                    name="report.comment"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                        <div className={styles.formItem}>
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                            />
                        </div>
                    )}
                />
            ) : (
                comment ?? '-'
            ),
            isVisible: true,
        },
    };

    const openVisit = () => {
        window.open(`/visit_feed/${caused_by_visit_id}`);
    };

    const openCompletedVisit = () => {
        window.open(`/visit_feed/${visit_id}`);
    };

    const openTaskRestore = () => {
        navigate(`/task/restore/${id}`);
    };

    const handleEdit = (e) => {
        e.preventDefault();
        if (isEdit) {
            reset();
        } else {
            setValue('isEdit', !isEdit);
        }
    };

    const onSubmit = async ({ isEdit, ...data }) => {
        setLoading(true);

        const { status, report, end_time } = data;
        await updateTaskReport(task_report_id, {
            end_time,
            report: {
                ...report,
                comment: report?.comment,
            },
            status,
        })
            .then((res) => {
                enqueueSnackbar(t('tasks.reportUpdated'), { variant: 'success' });

                if (
                    task_report?.end_time &&
                    data.end_time !== fixTimezoneOffset(task_report?.end_time)
                )
                    enqueueSnackbar(t('tasks.reportUpdatedDate'), {
                        variant: 'info',
                        autoHideDuration: 10000,
                    });

                const { status, report, end_time } = res?.task_report || {};
                setReportData({
                    ...task_report,
                    status,
                    report,
                    end_time: fixTimezoneOffset(end_time),
                });
            })
            .catch((error) => {
                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });

        setValue('isEdit', false);
    };

    const cities_name = cities.find((p) => p.id === city_id)?.name;
    const branches_name = branches.find((p) => p.id === branch_id)?.name;

    return (
        <>
            <div className={styles.header}>
                <div className={styles.item}>
                    <div className={styles.name}>
                        {t('common.project_outlet')}:<span>{project_short_name}</span>
                        <span>{external_code}</span>
                        <span>{name}</span>
                        <span>{address}</span>
                        <span>{cities_name}</span>
                        <span>{branches_name}</span>
                    </div>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.item}>
                    <div className={styles.head}>
                        <div className={styles.title}>
                            {t('tasks.taskItem')} {taskItem}
                        </div>
                        {caused_by_visit_id && (
                            <Button color="secondary" variant="outlined" onClick={openVisit}>
                                {t('tasks.goVisit')}
                            </Button>
                        )}
                    </div>
                    <div className={styles.lists}>
                        <ListMapper
                            items={permissionCheck(authRoles, serializedFeedItem)}
                            className={styles.serializedFeedItem}
                        />
                    </div>
                    {photosFromTask?.length > 0 && (
                        <PhotoSlider
                            photos={photosFromTask}
                            className={styles.photos}
                            type="list"
                            utc_offset={reportOffset}
                            hasDeleteBtn={!clientPermission}
                            isSmall
                        />
                    )}
                    {!isTaskCreateBlocked && (
                        <Button color="secondary" variant="outlined" onClick={openTaskRestore}>
                            {t('buttons.resume')}
                        </Button>
                    )}
                </div>
                {task_report_id && (
                    <div className={styles.item}>
                        <div className={styles.head}>
                            <div className={styles.title}>{t('tasks.taskJob')}</div>
                            {visit_id && (
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    onClick={openCompletedVisit}
                                >
                                    {t('tasks.goCompletedVisit')}
                                </Button>
                            )}
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {loading && <Loader />}
                            <div className={styles.lists}>
                                <ListMapper
                                    items={permissionCheck(authRoles, serializedFeedReportItem)}
                                    className={styles.serializedFeedReportItem}
                                />
                                {file_links?.length > 0 && (
                                    <PhotoSlider
                                        photos={file_links}
                                        type="list"
                                        utc_offset={reportOffset}
                                        hasDeleteBtn={!clientPermission}
                                        isSmall
                                    />
                                )}
                            </div>
                            <div className={styles.btns}>
                                {isEdit ? (
                                    <>
                                        <Button variant="contained" color="secondary" type="submit">
                                            {t('buttons.save')}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={handleEdit}
                                        >
                                            {t('buttons.cancel')}
                                        </Button>
                                    </>
                                ) : (
                                    !isClient &&
                                    isTaskReportUpdateAllowed && (
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={handleEdit}
                                        >
                                            {t('buttons.edit')}
                                        </Button>
                                    )
                                )}
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </>
    );
};

export default TasksList;
