import { JwtPayloadOpen, Token, Tokens, User } from "@apiFeature/types";
import { DefaultTFuncReturn } from "i18next";

export const reduxStoreName = 'auth';

export interface AuthState {
    refreshToken: Token;
    accessToken: Token;
    user?: User;
    loading?: boolean;
    tokens?: Tokens;
    jwtPayloadOpen?: JwtPayloadOpen;
    errMessage?: string | DefaultTFuncReturn;
};