import { createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorApi } from "../../apiFeature/types";
import { reduxStoreName } from "./types";
import { fetchCheckList, fetchSendCheckList, fetchCheckListResult } from "../../apiFeature/checkers";

export module CheckerThunk {
    export const fetchCheckerList = createAsyncThunk<any, any, { rejectWithValue: ErrorApi }>(
        `${reduxStoreName}/fetchCheckerList`, async (arg, {rejectWithValue}) => {
            try{
                return await fetchCheckList(arg ?? {});
            } catch(e) {
                return rejectWithValue(e);
            }
        }
    );

    export const fetchResult = createAsyncThunk<any, any, { rejectWithValue: ErrorApi }>(
        `${reduxStoreName}/fetchCheckListResult`, async (arg, {rejectWithValue}) => {
            try{
                return await fetchCheckListResult(arg ?? {});
            } catch(e) {
                return rejectWithValue(e);
            }
        }
    );

    export const fetchCheckListSubmit = createAsyncThunk<any, any, { rejectWithValue: ErrorApi }>(
        `${reduxStoreName}/fetchCheckListSubmit`, async (arg, {rejectWithValue}) => {
            try{
                return await fetchSendCheckList(arg ?? {});
            } catch(e) {
                return rejectWithValue(e);
            }
        }
    );
}