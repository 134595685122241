import React, { useCallback, useEffect } from 'react';
import { Select, TextField } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import FiltersSelectors from '@redux/filters/selectors';
import { FiltersThunks } from '@redux/filters/thunk';
import { t } from 'i18next';
import { getOptions } from '@tools/utils/functions';
import { merchandisingTypeOptions, statusOptions } from '@constants';

const ProjectsFilters = ({ defaultValues, form }) => {
    const dispatch = useDispatch();
    const { clients, clientsLoading } = useSelector(FiltersSelectors.filtersState);

    const { reset, watch, setValue } = form;
    const client_id = watch('client_id');
    const merchandising_type = watch('merchandising_type');
    const status = watch('status');

    const getClients = useCallback(() => {
        dispatch(FiltersThunks.getClientsThunk({}));
    }, [dispatch]);

    useEffect(() => {
        getClients();
    }, []);

    const onChange = (name, e) => {
        let value = e?.value;
        if (e && Array.isArray(e)) {
            value = e.length > 0 ? e.map((item) => item.value) : null;
        }
        setValue(name, value);
    };

    const handleReset = () => {
        reset(defaultValues);
    };

    const clientsOptions = getOptions(clients, 'full_name');
    const filteredStatusOptions = statusOptions.filter((option) => option?.value !== 'banned');

    const filters = [
        {
            type: Select,
            name: 'client_id',
            label: t('catalog.projects.agency'),
            placeholder: t('common.all'),
            onChange,
            value: clientsOptions?.find((e) => e.value === client_id),
            options: clientsOptions,
            isLoading: clientsLoading,
            isClearable: true,
        },
        {
            type: Select,
            name: 'merchandising_type',
            label: t('catalog.team.merchType'),
            placeholder: t('common.all'),
            onChange,
            value: merchandisingTypeOptions?.find((e) => e.value === merchandising_type),
            options: merchandisingTypeOptions,
            isClearable: true,
        },
        {
            type: Select,
            name: 'status',
            label: t('common.status'),
            placeholder: t('common.all'),
            onChange,
            value: filteredStatusOptions?.find((e) => e.value === status),
            options: filteredStatusOptions,
            isClearable: true,
        },
        {
            type: TextField,
            name: 'external_code',
            label: t('catalog.projects.code'),
        },
    ];

    return { handleReset, filters };
};

export default ProjectsFilters;
