import { ErrorData, FileLinksItem } from '../importProcesses/types';
import { t } from 'i18next';

export const exportProcessStatus = {
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    ERROR: 'ERROR',
    CANCELED: 'CANCELED',
} as const;

export const exportProcessKind = {
    VISITS: 'VISITS',
    VISITS_WITH_QUESTIONNAIRES: 'VISITS_WITH_QUESTIONNAIRES',
    PLANOGRAMS: 'PLANOGRAMS',
    SFA_TASKS: 'SFA_TASKS',
    PHOTO_VISITS: 'PHOTO_VISITS',
    QUESTIONNAIRES_FOR_CLIENT: 'QUESTIONNAIRES_FOR_CLIENT',
    VISITS_FOR_CLIENT: 'VISITS_FOR_CLIENT',
    CROWD_QUESTIONNAIRES: 'CROWD_QUESTIONNAIRES',
} as const;

export const exportProcessKindNames = {
    VISITS: t('visits.visits'),
    VISITS_WITH_QUESTIONNAIRES: t('questionnaires.title'),
    PLANOGRAMS: t('catalog.planograms.planograms'),
    SFA_TASKS: t('tasks.title'),
    PHOTO_VISITS: t('common.photos'),
    CROWD_TASKS: t('crowd.crowd'),
    CROWD_QUESTIONNAIRES: t('crowd.questionnairesCrowd'),
} as const;

export const exportProcessKindNamesForTable = {
    VISITS: t('visits.visits'),
    VISITS_WITH_QUESTIONNAIRES: t('questionnaires.title'),
    PLANOGRAMS: t('catalog.planograms.planograms'),
    SFA_TASKS: t('tasks.title'),
    PHOTO_VISITS: t('common.photos'),
    QUESTIONNAIRES_FOR_CLIENT: t('questionnaires.title'),
    VISITS_FOR_CLIENT: t('visits.visits'),
    CROWD_TASKS: t('crowd.crowd'),
    CROWD_QUESTIONNAIRES: t('crowd.questionnairesCrowd'),
} as const;

export const exportProcessStatusNames = {
    PENDING: t('statuses.pending'),
    IN_PROGRESS: t('statuses.inProgress'),
    DONE: t('statuses.done'),
    ERROR: t('messages.error'),
    CANCELED: t('statuses.canceled'),
} as const;

type ExportProcessStatusType = keyof typeof exportProcessStatus;
type ExportProcessKindType = keyof typeof exportProcessKind;

export type ExportProcessesItem = {
    id?: string;
    created_at?: string;
    updated_at?: string;
    file_links?: FileLinksItem[];
    status?: ExportProcessStatusType;
    kind?: ExportProcessKindType;
    error_data?: ErrorData | null;
    extra_data?: {};
    filter_params_data: {};
    created_by?: string;
};

export type ExportProcessesResponse = {
    export_processes: {
        items: ExportProcessesItem[];
        total: number;
        limit: number;
        offset: number;
    };
};

export type ExportProcessesRequest = {
    status?: ExportProcessStatusType | null;
    kind?: ExportProcessKindType | null;
    project_id?: string | null;
    created_by?: string | null;
    pagination?: {
        limit?: number | null;
        offset?: number | null;
    };
    sorting?: {};
};
