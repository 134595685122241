import React, { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import BasePicker from 'react-datepicker';
import { Button } from 'src/view/components/ui/Button/Button';
import { DatePickerHeader } from 'src/view/components/ui/form/date/DatePickerHeader';
import { ReactComponent as ArrowIcon } from '@images/svg/chevron-down.svg';
import 'react-datepicker/dist/react-datepicker.min.css';
import './DatePicker.scss';
import i18next from 'i18next';
import i18n from '../../../../../i18n';

interface RangePickerProps {
    label?: string;
    valueStart?: string | null;
    valueEnd?: string | null;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    onChange: (startDate: string | null, endDate: string | null) => void;
    onBlur?: () => void;
}

export const RangePicker: React.FC<RangePickerProps> = ({
    label,
    valueStart,
    valueEnd,
    placeholder = 'Любая',
    className,
    dateFormat,
    disabled,
    onBlur,
    onChange,
}) => {
    const [startString, setStartString] = useState<string | null>(valueStart || null);
    const [endString, setEndString] = useState<string | null>(valueEnd || null);

    useEffect(() => {
        if (valueStart && valueStart !== startString) {
            setStartString(valueStart);
        } else if (valueStart === null) {
            setStartString(valueStart);
        }
        if (valueEnd && valueEnd !== endString) {
            setEndString(valueEnd);
        } else if (valueEnd === null) {
            setEndString(valueEnd);
        }
    }, [valueStart, valueEnd]); // eslint-disable-line react-hooks/exhaustive-deps

    const [showYearPicker, setShowYearPicker] = useState(false);

    const [open, setOpen] = useState(false);

    const openCalendar = () => {
        setOpen(true);
    };
    const handleClickOutside = () => {
        setOpen(false);
        setStartString(valueStart ?? null);
        setEndString(valueEnd ?? null);
        if (valueEnd) {
            handleSubmit();
        }
    };

    const handleYearClick = () => {
        setShowYearPicker(true);
    };

    const handleChange = (dates: (Date | null)[]) => {
        if (showYearPicker) {
            setShowYearPicker(false);
        }
        const [start, end] = dates;
        setStartString(start?.toISOString() ?? null);
        setEndString(end?.toISOString() ?? null);
    };

    const handleSubmit = () => {
        onChange(startString, endString);
        setOpen(false);
    };

    const { startDate, endDate } = useMemo(() => {
        const startDate = startString && new Date(startString);
        const endDate = endString && new Date(endString);
        const isDateCorrect = (date) => {
            if (!date || date.toString() === 'Invalid Date') return null;
            return date;
        };
        return {
            startDate: isDateCorrect(startDate),
            endDate: isDateCorrect(endDate),
        };
    }, [startString, endString]);

    return (
        <div className={cn('date-picker-wrapper', { 'date-picker--open': open }, className)}>
            {label}
            <BasePicker
                selectsRange
                dateFormat={dateFormat}
                disabled={disabled}
                endDate={endDate}
                locale={i18n.language === 'ru' ? ruLocale : i18n.language}
                open={open}
                placeholderText={placeholder}
                renderCustomHeader={(props) => (
                    <DatePickerHeader
                        {...props}
                        yearPicker={showYearPicker}
                        onYearClick={handleYearClick}
                    />
                )}
                showYearPicker={showYearPicker}
                startDate={startDate}
                onBlur={onBlur}
                onCalendarOpen={openCalendar}
                onChange={handleChange}
                onClickOutside={handleClickOutside}
            >
                <div className="text-center pb-7">
                    <Button
                        disabled={!endDate || !startDate}
                        className="date-picker__submit"
                        type="default"
                        onClick={handleSubmit}
                    >
                        {i18next.t('common.submit')}
                    </Button>
                </div>
            </BasePicker>
            <ArrowIcon
                className={cn('date-picker-icon transition-transform', { 'rotate-180': open })}
            />
        </div>
    );
};
