import React, { FC, useState } from 'react';
import styles from './MenuNested.module.scss';
import { List, ListItemButton, Collapse } from '@mui/material';
import classNames from 'classnames';

const MenuNested: FC<any> = ({ children, selectProps, ...props }) => {
    const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({});

    const handleToggle = (optionValue: string) => {
        setOpenItems((prev) => ({
            ...prev,
            [optionValue]: !prev[optionValue],
        }));
    };
    const { onChange, onMenuClose } = selectProps || {};

    const renderOption = (option, depth = 0) => (
        <div key={option.value} style={{ marginLeft: depth * 16 }}>
            <ListItemButton
                onClick={() => {
                    handleToggle(option.value);
                    if (!option?.subOptions) {
                        onChange(option.value)
                        onMenuClose()
                    }
                }}
                className={classNames(styles.listItemButton, {
                    [styles.listItemChildren]: option?.subOptions && children,
                    [styles.listItemOpen]: option?.subOptions && openItems[option.value],
                })}
            >
                {option.label}
            </ListItemButton>
            {option.subOptions && (
                <Collapse in={openItems[option.value]} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        {option.subOptions.map((subOption) => renderOption(subOption, depth + 1))}
                    </List>
                </Collapse>
            )}
        </div>
    );

    return (
        <div className={styles.listWrapper}>
            <List>
                {props.options.map((option) => renderOption(option))}
            </List>
        </div>
    );
};

export default MenuNested;
