import { urls } from 'src/api/api.constant';
import { clientPassword } from '@apiFeature/ClientPassword';
import { FeedsRequest } from '@types/visitFeed';
import { FileLinksListRequest } from '@types/files';

export const fetchVisitFeeds = async (filters: FeedsRequest = {}) => {
    const visitFeeds = await clientPassword.post(urls.feeds.list, filters);
    return visitFeeds.feed;
};

export const fetchFeedsPhotos = async (filters: FileLinksListRequest = {}) => {
    const photos = await clientPassword.post(urls.feeds.photos, filters);
    return photos.file_links;
};
