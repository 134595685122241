import { TQuestionnaireDetail, TQuestionResult } from '../../apiFeature/questionnaires/type';
import { FilterValue, User } from '../../apiFeature/types';
import { ProjectOutlet } from '../../types/projectOutlets';
import { QuestionnairesDetailed } from '../../types/questionnaires';

export const reduxStoreName = 'fakeVisit';

export type PhotoBase64 = {
    base64?: string;
    type?: string;
    fileName?: string;
    fileSize?: number;
    uri?: string;
};

export type QuestionnaireTaskPhoto = {
    photo: PhotoBase64;
    id: string;
    isQueue?: boolean;
};

export type TQuestionAnswerItem = {
    answers?: string[] | number | string;
    photos?: QuestionnaireTaskPhoto[];
    yourAnswer?: string;
};

export interface TStateQuestionAnswers {
    // id - questions.id (type TQuestion)
    [id: string]: TQuestionAnswerItem;
}

export interface FakeVisitState {
    loading: boolean;
    loadingDetailed: boolean;
    loadingOutlet: boolean;
    loadingCreate: boolean;
    loadingDates: boolean;
    init: boolean;
    error: boolean;
    errorMessage: string | undefined;
    errorNotFullData: boolean;
    errorDates: boolean;
    errorPolls: boolean;
    projectId?: FilterValue | null;
    projectOutletId?: FilterValue | null;
    projectOutlets?: ProjectOutlet[];
    userList?: User[];
    userId?: FilterValue | undefined | null;
    date?: string | undefined | null;
    availableDates?: string[];
    startTime?: string | undefined | null;
    endTime?: string | undefined | null;
    comment?: string | undefined | null;
    is_ended_in_place?: FilterValue | null;
    visit_fail_result?: FilterValue | null;
    questionnaires?: [];
    questionnaireDetailed?: TQuestionnaireDetail[];
    addedQuestionnaires?: [];
    openedQuestionnaires?: [];
    questionResults?: TQuestionResult[];
    start_point_lon?: string | undefined | null;
    start_point_lat?: string | undefined | null;
    end_point_lon?: string | undefined | null;
    end_point_lat?: string | undefined | null;
    photos?: [];
    photoShopSign?: File | null;
    isSucceed?: boolean;
    curQuestionnaireDetailed?: QuestionnairesDetailed | null;
    questionAnswers: TStateQuestionAnswers;
    fetchLoading?: boolean;
    showConfirmPopup?: boolean;
    disable?: boolean;
    isValidTime?: boolean;
}
