import { clientPassword } from '../ClientPassword';
import {
    VisitingScheduleStatusesResponse,
    VisitingScheduleWebRequest,
    VisitingScheduleWebResponse,
    VisitingMapResponse,
    VisitingScheduleOutletResponse,
} from './type';

export const fetchVisitingScheduleListWeb = (request: VisitingScheduleWebRequest) => {
    return clientPassword.post<VisitingScheduleWebResponse, VisitingScheduleWebRequest>(
        '/web/visiting_schedule/v2/list/',
        request
    );
};

export const fetchVisitingScheduleOutlet = (request: string[]) => {
    return clientPassword.post<VisitingScheduleOutletResponse, string[]>(
        '/web/visiting_schedule/project_outlet/info/',
        request
    );
};

export const fetchVisitingScheduleStatuses = (request: string[]) => {
    return clientPassword.post<VisitingScheduleStatusesResponse, string[]>(
        '/web/visiting_schedule/statuses/',
        request
    );
};

export const fetchVisitingMaps = (request: VisitingScheduleWebRequest) => {
    return clientPassword.post<VisitingMapResponse, VisitingScheduleWebRequest>(
        '/visiting_schedule/map_list/',
        request
    );
};
