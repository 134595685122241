import React, { useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Loader, Dialog } from '@components';
import { unlinkAllQuestionnaire } from '@apiFeature/questionnaires';
import { useSnackbar } from 'notistack';
import { declOfNum } from '@tools/utils/string.utils';
import styles from './UnlinkAll.module.scss';

const UnlinkAll = ({ setValue, totalUnLink }) => {
    const { title } = useOutletContext();

    const params = useParams();
    const { pollId } = params || {};

    const [isOpenDialog, setDialog] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const { t } = useTranslation('translation');

    const setCancelChange = () => {
        setDialog(false);
    };

    const handleUnlinkAllQuestionnaire = () => {
        setLoading(true);
        unlinkAllQuestionnaire(pollId)
            .then(() => {
                setCancelChange();
                setValue('isUpdate', true);
            })
            .catch((error) => {
                enqueueSnackbar(error, { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const totalUnLinkText = declOfNum(totalUnLink, [
        t('catalog.outlets.item'),
        t('catalog.outlets.items'),
        t('catalog.outlets.items'),
    ]);

    return (
        <>
            {isLoading && <Loader />}
            <Button color="secondary" variant="outlined" onClick={() => setDialog(true)}>
                {t('catalog.outlets.unlinkAll')}
            </Button>
            {isOpenDialog && (
                <Dialog
                    id="unlinkAll"
                    isOpenDialog={isOpenDialog}
                    setCloseDialog={setCancelChange}
                    title={
                        <>
                            Вы планируете открепить опросник {title} от {totalUnLink}{' '}
                            {totalUnLinkText} проекта
                        </>
                    }
                    className={styles.dialog}
                    actions={
                        <>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleUnlinkAllQuestionnaire}
                            >
                                {t('buttons.unlink')}
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={setCancelChange}>
                                {t('buttons.cancel')}
                            </Button>
                        </>
                    }
                ></Dialog>
            )}
        </>
    );
};

export default UnlinkAll;
