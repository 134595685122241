import React, { useCallback, useEffect } from 'react';
import styles from './CrowdFeedFilters.module.scss';
import { useTranslation } from 'react-i18next';
import { Select, RangePicker, RangeSlider } from '@components';
import { getOptions } from '@tools/utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import FiltersSelectors from '@redux/filters/selectors';
import { FiltersThunks } from '@redux/filters/thunk';
import { useRoles } from '@tools/hooks/useRoles';
import { CrowdThunks } from '@redux/crowd/thunk';
import CrowdSelectors from '@redux/crowd/selectors';
import { fixTimezoneOffset } from '@tools/utils/date.util';

const CrowdFeedFilters = ({ defaultValues, form }) => {
    const { t } = useTranslation('translation');
    const { isClientCrowd } = useRoles();
    const { projectsList, loading: loadingProjects } = useSelector(
        ProjectsSelectors.projectsSelectors
    );
    const {
        users,
        usersLoading,
        retails,
        retailsLoading,
        branches,
        branchesLoading,
        territory,
        territoryLoading,
        cities,
        citiesLoading,
        outletsCrowdLoading,
        projectOutletsCrowd,
    } = useSelector(FiltersSelectors.filtersState);
    const {
        taskComplexities,
        taskTypes,
        loading: loadingCrowdOptions,
    } = useSelector(CrowdSelectors.crowdSelectors);

    const projectsListId = projectsList?.[0]?.id;

    const dispatch = useDispatch();
    const { reset, watch, setValue } = form;
    const currValues = watch();
    const {
        project_id: pr_id,
        date_range,
        user_ids,
        retail_ids,
        branch_ids,
        territory_ids,
        city_ids,
        task_complexity_ids,
        task_type_ids,
        project_outlet_ids,
        price_lower,
        price_upper,
    } = currValues;
    const project_id = pr_id || projectsListId;
    const start_date = fixTimezoneOffset(date_range.start, 'start').replace('Z', '');
    const end_date = fixTimezoneOffset(date_range.end, 'end').replace('Z', '');

    const setActualValue = (curData, data, name) => {
        const value = data?.filter((e) => curData?.includes(e.id)).map((e) => e.id);
        if (curData && curData?.join() !== value?.join()) {
            setValue(name, value.length > 0 ? value : null);
        }
    };

    const getFilters = useCallback(() => {
        dispatch(FiltersThunks.getRetailsList({ project_id })).then((result) => {
            if (FiltersThunks.getRetailsList.fulfilled.match(result)) {
                const retails = result?.payload || [];
                if (retails?.length > 0) setActualValue(retail_ids, retails, 'retail_ids');
            }
        });
        dispatch(FiltersThunks.getBranchesList({ project_id })).then((result) => {
            if (FiltersThunks.getBranchesList.fulfilled.match(result)) {
                const branches = result?.payload || [];
                if (branches?.length > 0) setActualValue(branch_ids, branches, 'branch_ids');
            }
        });
        dispatch(FiltersThunks.getTerritoryList({ project_id })).then((result) => {
            if (FiltersThunks.getTerritoryList.fulfilled.match(result)) {
                const territories = result?.payload || [];
                if (territories?.length > 0)
                    setActualValue(territory_ids, territories, 'territory_ids');
            }
        });
        dispatch(FiltersThunks.getCitiesList({ project_id })).then((result) => {
            if (FiltersThunks.getCitiesList.fulfilled.match(result)) {
                const cities = result?.payload || [];
                if (cities?.length > 0) setActualValue(city_ids, cities, 'city_ids');
            }
        });
    }, [dispatch, project_id]);

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    useEffect(() => {
        getProjects();
        dispatch(CrowdThunks.fetchTaskComplexities({})).then((result) => {
            if (task_complexity_ids && CrowdThunks.fetchTaskComplexities.fulfilled.match(result)) {
                const taskComplexities = result?.payload || [];
                if (taskComplexities?.length > 0)
                    setActualValue(task_complexity_ids, taskComplexities, 'task_complexity_ids');
            }
        });
        dispatch(CrowdThunks.fetchTaskTypes({})).then((result) => {
            if (task_type_ids && CrowdThunks.fetchTaskTypes.fulfilled.match(result)) {
                const taskTypes = result?.payload || [];
                if (taskTypes?.length > 0)
                    setActualValue(task_type_ids, taskTypes, 'task_type_ids');
            }
        });
    }, []);

    const getExecutors = useCallback(() => {
        if (!isClientCrowd) {
            dispatch(
                FiltersThunks.getExecutorsForCrowdFilter({ project_id, start_date, end_date })
            ).then((result) => {
                if (FiltersThunks.getExecutorsForCrowdFilter.fulfilled.match(result)) {
                    const users = result?.payload || [];
                    if (users?.length > 0) setActualValue(user_ids, users, 'user_ids');
                }
            });
        }
    }, [start_date, end_date, project_id]);

    const getOutletsCrowd = useCallback(() => {
        dispatch(FiltersThunks.getOutletsListForCrowd({ project_id, start_date, end_date })).then(
            (result) => {
                if (FiltersThunks.getOutletsListForCrowd.fulfilled.match(result)) {
                    const projectOutletsCrowd = result?.payload || [];
                    if (projectOutletsCrowd?.length > 0)
                        setActualValue(
                            project_outlet_ids,
                            projectOutletsCrowd,
                            'project_outlet_ids'
                        );
                }
            }
        );
    }, [start_date, end_date, project_id]);

    useEffect(() => {
        if (projectsList.length > 0 && !project_id) setValue('project_id', projectsListId);
    }, [projectsList]);

    useEffect(() => {
        if (project_id) getFilters();
    }, [project_id, getFilters]);

    useEffect(() => {
        if (project_id) {
            getExecutors();
            getOutletsCrowd();
        }
    }, [start_date, end_date, project_id]);

    const onChange = (name, e) => {
        let value = e?.value;
        if (e && Array.isArray(e)) {
            value = e.length > 0 ? e.map((item) => item.value) : null;
        }
        setValue(name, value);
    };

    const handleReset = () => {
        reset({
            ...defaultValues,
            project_id: projectsListId,
        });
    };

    const projectOptions = getOptions(projectsList, 'full_name');
    const retailsOptions = getOptions(retails);
    const branchesOptions = getOptions(branches);
    const territoryOptions = getOptions(territory);
    const citiesOptions = getOptions(cities);
    const taskComplexitiesOptions = getOptions(taskComplexities);
    const taskTypesOptions = getOptions(taskTypes);
    const usersOptions = getOptions(users, ['last_name', 'first_name', 'patronymic_name', 'phone']);
    const outetsCrowdOptions = getOptions(projectOutletsCrowd, [
        'name',
        'address',
        'external_code',
    ]);

    const handleRangeChange = (_, e) => {
        const [price_lower, price_upper] = e.target.value;
        setValue('price_lower', price_lower);
        setValue('price_upper', price_upper);
    };

    const filtersData = [
        {
            type: Select,
            name: 'project_id',
            label: t('common.project'),
            onChange,
            value: project_id
                ? projectOptions?.find((e) => e.value === project_id)
                : projectOptions?.[0],
            options: projectOptions,
            isLoading: loadingProjects,
        },
        {
            type: RangePicker,
            name: 'date_range',
            label: t('common.date_range'),
            placeholder: t('common.select'),
            dateFormat: 'dd.MM.yyyy',
            valueStart: date_range?.start,
            valueEnd: date_range?.end,
        },
        {
            type: Select,
            name: 'user_ids',
            label: t('common.user'),
            placeholder: t('common.all'),
            onChange,
            value: user_ids ? usersOptions?.filter((e) => user_ids.includes(e.value)) : null,
            options: usersOptions,
            isLoading: usersLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'task_complexity_ids',
            label: t('crowd.task_complexity'),
            placeholder: t('common.all'),
            onChange,
            value: task_complexity_ids
                ? taskComplexitiesOptions?.filter((e) => task_complexity_ids.includes(e.value))
                : null,
            options: taskComplexitiesOptions,
            isLoading: loadingCrowdOptions,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'task_type_ids',
            label: t('crowd.task_type'),
            placeholder: t('common.all'),
            onChange,
            value: task_type_ids
                ? taskTypesOptions?.filter((e) => task_type_ids.includes(e.value))
                : null,
            options: taskTypesOptions,
            isLoading: loadingCrowdOptions,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'project_outlet_ids',
            label: t('common.project_outlet'),
            placeholder: t('common.all'),
            onChange,
            value: project_outlet_ids
                ? outetsCrowdOptions?.filter((e) => project_outlet_ids.includes(e.value))
                : null,
            options: outetsCrowdOptions,
            isLoading: outletsCrowdLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'retail_ids',
            label: t('common.retail'),
            placeholder: t('common.all'),
            onChange,
            value: retail_ids ? retailsOptions?.filter((e) => retail_ids.includes(e.value)) : null,
            options: retailsOptions,
            isLoading: retailsLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'branch_ids',
            label: t('feed.branches'),
            placeholder: t('common.all'),
            onChange,
            value: branch_ids ? branchesOptions?.filter((e) => branch_ids.includes(e.value)) : null,
            options: branchesOptions,
            isLoading: branchesLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'territory_ids',
            label: t('common.territory'),
            placeholder: t('common.all'),
            onChange,
            value: territory_ids
                ? territoryOptions?.filter((e) => territory_ids.includes(e.value))
                : null,
            options: territoryOptions,
            isLoading: territoryLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'city_ids',
            label: t('common.city'),
            placeholder: t('common.all'),
            onChange,
            value: city_ids ? citiesOptions?.filter((e) => city_ids.includes(e.value)) : null,
            options: citiesOptions,
            isLoading: citiesLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: RangeSlider,
            name: 'price',
            label: t('crowd.priceFilter'),
            onChange: handleRangeChange,
            value: [price_lower, price_upper],
            valueLabelDisplay: 'on',
            step: 50,
            min: 50,
            max: 1500,
            marks: [
                {
                    value: 50,
                    label: '50',
                },
                {
                    value: 1500,
                    label: '1500',
                },
            ],
        },
    ];

    const filters = isClientCrowd
        ? filtersData.filter((e) => e.name !== 'user_ids' && e.name !== 'price')
        : filtersData;

    return { handleReset, filters };
};

export default CrowdFeedFilters;
