import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styles from './GeneralStep.module.scss';
import { useFormContext, Controller } from 'react-hook-form';
import { Select, TextField } from '@components';
import { useAppDispatch, useAppSelector } from '@tools/hooks/redux';
import ProjectsSelectors from '@redux/projects/selectors';
import FiltersSelectors from '@redux/filters/selectors';
import { FiltersThunks } from '@redux/filters/thunk';
import { ProjectsThunks } from '@redux/projects/thunk';
import { extractErrorMessage, getOptions } from '@tools/utils/functions';
import { useTranslation } from 'react-i18next';
import { getProjectOutlet } from '@apiFeature/projectOutlets';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { getUserById } from '@apiFeature/user';
import { ProjectOutlet } from '@types/projectOutlets';
import { debounce } from '@tools/utils/functions';
import VisitStep from './VisitStep/VisitStep';

interface IGeneralStep {
    projectOutlets?: ProjectOutlet[];
    onInputChangeOutlets?: (arg?: string, obj?: { action: string }) => void;
    outletsLoading?: boolean;
    moreOutlets?: () => void;
}

const GeneralStep: FC<IGeneralStep> = ({
    projectOutlets,
    onInputChangeOutlets,
    outletsLoading,
    moreOutlets,
}) => {
    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();
    const { control, watch, setValue, trigger, formState } = useFormContext() || {};
    const dispatch = useAppDispatch();
    const { visitId } = useParams();
    const isCopy = !!visitId;
    const { project_id, address, user_id, project_outlet_id } = watch();
    const { projectsList, loading: projectsLoading } = useAppSelector(
        ProjectsSelectors.projectsSelectors
    );
    const { userProjects, userProjectsLoading: usersLoading } = useAppSelector(
        FiltersSelectors.filtersState
    );

    const { isSubmitting } = formState || {};
    const isInitialLoad = useRef(true);
    const prevProjectIdForUsers = useRef(project_id);
    const prevProjectIdForEmptyingOutlet = useRef(null);

    const [addressToShow, setAddressToShow] = useState('');
    const [missingOutlet, setMissingOutlet] = useState(null);
    const [missingUser, setMissingUser] = useState(null);
    const debounceSetAddress = debounce((address) => {
        setValue('address', address?.target?.value);
    }, 1000);

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    const getUsers = useCallback(() => {
        if (project_id && project_id !== prevProjectIdForUsers.current) {
            prevProjectIdForUsers.current = project_id;
            setValue('user_id', '');
            dispatch(FiltersThunks.getUserProjectExecutors({ project_id })).then((result) => {
                if (FiltersThunks.getUserProjectExecutors.fulfilled.match(result)) {
                    const users = result?.payload?.allUsers || [];
                    if (users?.length > 0) {
                        setValue('user_id', user_id);
                        trigger('user_id');
                    }
                }
            });
        }
    }, [project_id]);

    const setCoordFromOutlet = (projectOutlet) => {
        if (projectOutlet) {
            const { longitude, latitude } = projectOutlet || {};
            if (longitude && latitude) {
                setValue('start_point_lon', longitude);
                setValue('start_point_lat', latitude);
                setValue('end_point_lon', longitude);
                setValue('end_point_lat', latitude);
            }
        }
    };

    const projectOptions = getOptions(projectsList, 'full_name');
    const [usersOptions, setUsersOptions] = useState([]);
    const [outletOptions, setOutletOptions] = useState([]);

    useEffect(() => {
        getProjects();
    }, []);

    useEffect(() => {
        if (!project_id && !isCopy && projectOptions?.length > 0) {
            const projectIdFromList = projectOptions[0].value;
            setValue('project_id', projectIdFromList);
        }
    }, [project_id, projectsList]);

    const fetchMissingUserInOptions = async (user_id) => {
        const usersOptions = getOptions(userProjects?.[project_id], [
            'last_name',
            'first_name',
            'patronymic_name',
            'phone',
        ]);
        const userInOptions = usersOptions?.find((u) => u?.value === user_id);
        if (!userInOptions && isCopy) {
            try {
                const result = await getUserById(user_id);
                const { user } = result || {};
                if (user) {
                    const currentUserOption = getOptions(
                        [user],
                        ['last_name', 'first_name', 'patronymic_name', 'phone']
                    );
                    const newUsersOptions = [...(usersOptions || []), ...currentUserOption];
                    setUsersOptions(newUsersOptions);
                    setMissingUser(user);
                }
            } catch (e) {
                enqueueSnackbar(extractErrorMessage(e), { variant: 'error' });
            }
        }
    };

    const fetchMissingOutletInOptions = async () => {
        const outletInOptions = outletOptions?.find((o) => o?.value === project_outlet_id);
        if (!outletInOptions && isCopy && project_outlet_id && projectOutlets?.length > 0) {
            try {
                const res = await getProjectOutlet(project_outlet_id);
                const { project_outlet } = res || {};
                if (project_outlet) {
                    const newOutletOptions = getOptions(
                        [...projectOutlets, project_outlet],
                        ['external_code', 'name', 'address'],
                        'id',
                        false
                    );
                    setOutletOptions(newOutletOptions);
                    setMissingOutlet(project_outlet);
                }
            } catch (e) {
                enqueueSnackbar(extractErrorMessage(e), { variant: 'error' });
            }
        }
    };

    const addMissingUserToOptions = () => {
        const userInOptions = usersOptions?.find((u) => u?.value === missingUser?.id);
        if (missingUser && !userInOptions) {
            const currentUserOption = getOptions(
                [missingUser],
                ['last_name', 'first_name', 'patronymic_name', 'phone']
            );
            const newUsersOptions = [...(usersOptions || []), ...currentUserOption];
            setUsersOptions(newUsersOptions);
        }
    };

    const addMissingOutletToOption = (missingOutlet) => {
        const outletInOptions = outletOptions?.find((o) => o?.value === missingOutlet?.id);
        if (missingOutlet && !outletInOptions && !address) {
            projectOutlets?.push(missingOutlet);
            const newOutletOptions = getOptions(
                projectOutlets,
                ['external_code', 'name', 'address'],
                'id',
                false
            );
            setOutletOptions(newOutletOptions);
        }
    };

    useEffect(() => {
        if (!missingUser) {
            if (userProjects?.[project_id]?.length) fetchMissingUserInOptions(user_id);
        } else {
            addMissingUserToOptions();
        }
    }, [user_id, userProjects, usersOptions]);

    useEffect(() => {
        if (!missingOutlet) {
            fetchMissingOutletInOptions();
        } else {
            addMissingOutletToOption(missingOutlet);
        }
    }, [project_outlet_id, projectOutlets]);

    useEffect(() => {
        if (projectOutlets?.length > 0 && address) {
            const outletIdFromList = outletOptions?.[0]?.value;
            setValue('project_outlet_id', outletIdFromList);
            trigger('project_outlet_id');
            const projectOutlet = projectOutlets?.find((o) => o?.id === outletIdFromList);
            setCoordFromOutlet(projectOutlet);
        }
    }, [projectOutlets, address, outletOptions]);

    useEffect(() => {
        if (isInitialLoad.current) {
            isInitialLoad.current = false;
        } else {
            getUsers();
            if (prevProjectIdForEmptyingOutlet?.current) {
                setValue('project_outlet_id', '');
                trigger('project_outlet_id');
            } else {
                prevProjectIdForEmptyingOutlet.current = project_id;
            }
        }
    }, [project_id]);

    useEffect(() => {
        const newUsersOptions = getOptions(userProjects?.[project_id], [
            'last_name',
            'first_name',
            'patronymic_name',
            'phone',
        ]);
        setUsersOptions(newUsersOptions);
    }, [userProjects]);

    useEffect(() => {
        const newOutletOptions = getOptions(
            projectOutlets,
            ['external_code', 'name', 'address'],
            'id',
            false
        );
        setOutletOptions(newOutletOptions);
    }, [projectOutlets]);

    const handleChangeOutlet = (e, name) => {
        const { value } = e || {};
        setValue(name, value || null);
        if (!value && onInputChangeOutlets) onInputChangeOutlets('', { action: 'clear' });
        trigger();
        const projectOutlet = projectOutlets?.find((o) => o?.id === value);
        setCoordFromOutlet(projectOutlet);
    };

    const handleChangeAddress = (e) => {
        setValue('project_outlet_id', '');
        setAddressToShow(e?.target?.value);
        debounceSetAddress(e);
    };

    return (
        <div className={styles.formItems}>
            <div className={styles.formItem}>
                <Controller
                    name="project_id"
                    control={control}
                    render={({ field: { name, value, ref }, fieldState: { error } }) => (
                        <Select
                            id={name}
                            name={name}
                            inputRef={ref}
                            label={t('common.project')}
                            onChange={(e) => {
                                setValue(name, e?.value || null);
                                trigger();
                            }}
                            value={projectOptions?.find((e) => e.value === value)}
                            options={projectOptions}
                            isLoading={projectsLoading}
                            disabled={isSubmitting}
                            error={error}
                        />
                    )}
                />
            </div>
            <div className={styles.outletFilters}>
                <div>
                    <Controller
                        name="project_outlet_id"
                        control={control}
                        render={({ field: { name, value, ref }, fieldState: { error } }) => (
                            <Select
                                id={name}
                                name={name}
                                inputRef={ref}
                                label={t('common.project_outlet')}
                                placeholder={t('filledVisit.typeCode')}
                                onChange={(e) => {
                                    handleChangeOutlet(e, name);
                                }}
                                onFocus={() => {
                                    trigger();
                                }}
                                value={outletOptions?.find((e) => e.value === value)}
                                options={outletOptions}
                                onInputChange={onInputChangeOutlets}
                                onMenuScrollToBottom={moreOutlets}
                                isLoading={outletsLoading}
                                disabled={isSubmitting}
                                error={error}
                                className={styles.focusHide}
                            />
                        )}
                    />
                </div>
                <div className={styles.address}>
                    <Controller
                        name={`address`}
                        control={control}
                        render={({ field: { onChange, name, value }, fieldState: { error } }) => (
                            <TextField
                                label={t('filledVisit.addressFilterForOutlet')}
                                placeholder={t('filledVisit.addressFilterForOutlet')}
                                onChange={(e) => {
                                    handleChangeAddress(e);
                                }}
                                value={addressToShow || ''}
                                id={name}
                                variant="outlined"
                                disabled={isSubmitting}
                                error={error}
                            />
                        )}
                    />
                </div>
            </div>
            <div className={styles.formItem}>
                <Controller
                    name="user_id"
                    control={control}
                    render={({ field: { name, value, ref }, fieldState: { error } }) => (
                        <Select
                            id={name}
                            name={name}
                            inputRef={ref}
                            label={t('common.user')}
                            onChange={(e) => {
                                setValue(name, e?.value || null);
                                trigger();
                            }}
                            onFocus={() => {
                                trigger();
                            }}
                            value={usersOptions?.find((e) => e.value === value)}
                            options={usersOptions}
                            isLoading={usersLoading}
                            disabled={isSubmitting}
                            error={error}
                            className={styles.focusHide}
                        />
                    )}
                />
            </div>
            <VisitStep />
        </div>
    );
};

export default GeneralStep;
