import React, { useCallback, useEffect } from 'react';
import { Select } from '@components';
import { getOptions } from '@tools/utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import FiltersSelectors from '@redux/filters/selectors';
import { FiltersThunks } from '@redux/filters/thunk';
import { t } from 'i18next';

const TeamFilters = ({ defaultValues, form }) => {
    const dispatch = useDispatch();
    const { projectsList, loading: loadingProjects } = useSelector(
        ProjectsSelectors.projectsSelectors
    );
    const { roles, rolesLoading, managerProjects } = useSelector(FiltersSelectors.filtersState);

    const projectsListId = projectsList?.[0]?.id;

    const { reset, watch, setValue } = form;
    const currValues = watch();
    const { project_id: pr_id, executor_role, manager_id, isActive } = currValues;
    const project_id = pr_id || projectsListId;

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    const getRoles = useCallback(() => {
        dispatch(FiltersThunks.getRolesThunk({}));
    }, [dispatch]);

    useEffect(() => {
        getProjects();
        getRoles();
    }, []);

    useEffect(() => {
        if (project_id && manager_id && Object.keys(managerProjects)?.length > 0) {
            const managers = managerProjects?.[project_id] || [];
            if (!managers.some((e) => e.id === manager_id)) setValue('manager_id', '');
        }
    }, [project_id, managerProjects, manager_id]);

    const onChange = (name, e) => {
        let value = e?.value;
        if (e && Array.isArray(e)) {
            value = e.length > 0 ? e.map((item) => item.value) : null;
        }
        setValue(name, value);
    };

    const handleReset = () => {
        reset({ ...defaultValues, project_id: projectsListId });
    };

    const projectOptions = getOptions(projectsList, 'full_name');
    const rolesOptions = getOptions(roles, 'name', 'sys_name');
    const managerOptions = getOptions(managerProjects[project_id], [
        'last_name',
        'first_name',
        'patronymic_name',
        'phone',
    ]);
    const isActiveOptions = [
        {
            label: t('statuses.active'),
            value: 'active',
        },
        {
            label: t('statuses.inactive'),
            value: 'inactive',
        },
        {
            label: t('common.all'),
            value: 'all',
        },
    ];

    const filters = [
        {
            type: Select,
            name: 'project_id',
            label: t('common.project'),
            onChange,
            value: project_id
                ? projectOptions?.find((e) => e.value === project_id)
                : projectOptions?.[0],
            options: projectOptions,
            isLoading: loadingProjects,
        },
        {
            type: Select,
            name: 'executor_role',
            label: t('catalog.team.roles'),
            onChange,
            value: rolesOptions?.find((e) => e.value === executor_role),
            options: rolesOptions,
            isLoading: rolesLoading,
            isClearable: true,
        },
        {
            type: Select,
            name: 'manager_id',
            label: t('common.manager'),
            placeholder: t('common.all'),
            onChange,
            value: managerOptions?.find((e) => e.value === manager_id),
            options: managerOptions,
            isClearable: true,
        },
        {
            type: Select,
            name: 'isActive',
            label: t('common.status'),
            placeholder: t('common.all'),
            onChange,
            value: isActiveOptions?.find((e) => e.value === isActive),
            options: isActiveOptions,
        },
    ];

    return { handleReset, filters };
};

export default TeamFilters;
