import { createAction } from '@reduxjs/toolkit';
import { TQuestionResult } from '../../apiFeature/questionnaires/type';
import { reduxStoreName, TStateQuestionAnswers } from './types';
import { FilterValue } from '../../apiFeature/types';
import { ProjectOutlet } from '../../types/projectOutlets';

module FakeVisitAction {
    export const resetAction = createAction(`${reduxStoreName}/resetAction`);

    export const clearForm = createAction(`${reduxStoreName}/clearForm`);

    export const setProjectId = createAction<FilterValue | undefined>(
        `${reduxStoreName}/setProjectId`
    );

    export const setUserId = createAction<FilterValue | undefined | null>(
        `${reduxStoreName}/setUserId`
    );

    export const setOutletId = createAction<FilterValue | undefined | null>(
        `${reduxStoreName}/setOutletId`
    );

    export const setDate = createAction<string | undefined>(`${reduxStoreName}/setDate`);

    export const setStartTime = createAction<string | undefined>(`${reduxStoreName}/setStartTime`);

    export const setEndTime = createAction<string | undefined>(`${reduxStoreName}/setEndTime`);

    export const isValidTime = createAction<boolean | undefined>(`${reduxStoreName}/isValidTime`);

    export const setComment = createAction<string | undefined>(`${reduxStoreName}/setComment`);

    export const setEndedInPlace = createAction<FilterValue | undefined>(
        `${reduxStoreName}/setEndedInPlace`
    );

    export const setFailResult = createAction<FilterValue | undefined>(
        `${reduxStoreName}/setFailResult`
    );

    export const addQuestionnaireInVisit = createAction(
        `${reduxStoreName}/addQuestionnaireInVisit`
    );

    export const openQuestionnaire = createAction(`${reduxStoreName}/openQuestionnaire`);

    export const setQuestionResults = createAction<TQuestionResult | undefined>(
        `${reduxStoreName}/setQuestionResults`
    );

    export const setErrorNotFullData = createAction(`${reduxStoreName}/setErrorNotFullData`);

    export const addPhotos = createAction<any | undefined>(`${reduxStoreName}/addPhotos`);

    export const deletePhoto = createAction<any | undefined>(`${reduxStoreName}/deletePhoto`);

    export const cancelPhoto = createAction<any | undefined>(`${reduxStoreName}/cancelPhoto`);

    export const setProjectOutlets = createAction<any | undefined>(
        `${reduxStoreName}/setProjectOutlets`
    );

    export const setUserList = createAction<any | undefined>(`${reduxStoreName}/setUserList`);

    export const setPhotoShopSign = createAction<any | undefined>(
        `${reduxStoreName}/setPhotoShopSign`
    );

    export const setQuestionAnswer = createAction<TStateQuestionAnswers | undefined>(
        `${reduxStoreName}/setQuestionAnswer`
    );

    export const setLongitudeLatitude = createAction<ProjectOutlet>(
        `${reduxStoreName}/setLongitudeLatitude`
    );

    export const resetError = createAction(`${reduxStoreName}/resetError`);
    export const loadingStatus = createAction<boolean>(`${reduxStoreName}/loadingStatus`);
    export const showConfirmPopup = createAction<boolean>(`${reduxStoreName}/showConfirmPopup`);
}
export default FakeVisitAction;
