import { createAction } from '@reduxjs/toolkit';
import { reduxStoreName } from './types';

module AssignmentActions {
    export const resetAction = createAction(`${reduxStoreName}/resetAction`);

    export const setSearch = createAction<string | undefined>(`${reduxStoreName}/setSearch`);

    export const setKindFilter = createAction<string | undefined>(`${reduxStoreName}/setKindFilter`);

    export const setProjectFilter = createAction<string | undefined>(`${reduxStoreName}/setProjectFilter`);

    export const setStatusFilter = createAction<string| undefined>(`${reduxStoreName}/setStatusFilter`);

    export const resetFilters = createAction(`${reduxStoreName}/resetFilters`);

    export const onEditQuestionnaire = createAction<any>(`${reduxStoreName}/onEditQuestionnaire`);
}
export default AssignmentActions;
