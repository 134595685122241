import React, { useCallback, useEffect } from 'react';
import styles from './AddressProgramFilters.module.scss';
import { useTranslation } from 'react-i18next';
import { Select, TextField, Checkbox } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import FiltersSelectors from '@redux/filters/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import { FiltersThunks } from '@redux/filters/thunk';
import { getOptions } from '@tools/utils/functions';

const AddressProgramFilters = ({ defaultValues, form }) => {
    const dispatch = useDispatch();
    const { projectsList, loading: loadingProjects } = useSelector(
        ProjectsSelectors.projectsSelectors
    );
    const { periodList, periodsLoading } = useSelector(FiltersSelectors.filtersState);

    const { t } = useTranslation('translation');

    const { reset, watch, setValue } = form;
    const projectValue = watch('projectsAP');
    const periodValue = watch('period');

    const setActualValue = (curData, data, name) => {
        const value = data?.filter((e) => curData?.includes(e.id)).map((e) => e.id);
        if (curData && curData?.join() !== value?.join()) {
            setValue(name, value.length > 0 ? value : null);
        }
    };

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    const getFiltersProjects = useCallback(() => {
        dispatch(
            FiltersThunks.getPeriodList({
                ...(projectValue && { project_ids: projectValue }),
            })
        ).then((result) => {
            if (FiltersThunks.getPeriodList.fulfilled.match(result)) {
                const periods = result?.payload || [];
                if (periods?.length > 0) setActualValue(periodValue, periods, 'periodValue');
            }
        });
    }, [dispatch, projectValue]);

    useEffect(() => {
        getProjects();
    }, [getProjects]);

    useEffect(() => {
        getFiltersProjects();
    }, [projectValue, getFiltersProjects]);

    const onChange = (name, e) => {
        let value = e?.value;
        if (e && Array.isArray(e)) {
            value = e.length > 0 ? e.map((item) => item.value) : null;
        }
        setValue(name, value);
    };

    const handleReset = () => {
        const [period] = periodList || [];
        const { id: periodId } = period || {};
        reset({ ...defaultValues, period: [periodId] });
    };

    const projectOptions = getOptions(projectsList, 'full_name');

    const periodOptions = periodList?.map(({ name, id }) => ({
        label: name,
        value: id,
    }));

    const filters = [
        {
            type: Select,
            name: 'projectsAP',
            label: t('common.project'),
            placeholder: t('common.all'),
            onChange,
            value: projectValue
                ? projectOptions?.filter((e) => projectValue.includes(e.value))
                : null,
            options: projectOptions,
            isLoading: loadingProjects,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'period',
            label: t('common.period'),
            placeholder: t('common.all'),
            onChange,
            value: periodValue ? periodOptions.filter((e) => periodValue.includes(e.value)) : null,
            options: periodOptions,
            isLoading: periodsLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: TextField,
            name: 'ap',
            label: t('common.ap'),
        },
        {
            type: Checkbox,
            name: 'is_deactivated',
            label: t('statuses.active'),
            labelPlacement: 'end',
        },
    ];

    return { handleReset, filters };
};

export default AddressProgramFilters;
