import React from "react";
import styles from './DialogForSettings.module.scss'
import { Button, Dialog } from '@components';
import { useTranslation } from "react-i18next";

export const DialogForSettings = ({ isOpenDialog, handleCloseDialog }) => {
    const { t } = useTranslation('translation');
    return (
        <Dialog
            id="saveChanges"
            isOpenDialog={isOpenDialog}
            setCloseDialog={handleCloseDialog}
            className={styles.dialog}
            title={t('messages.saveChanges')}
            actions={
                <form className={styles.formBtns}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        type="submit"
                    >
                        {t('common.yes')}
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleCloseDialog}>
                        {t('common.no')}
                    </Button>
                </form>
            }
        >
        </Dialog>
    )
}