import { clientExportServicePassword } from '../ClientPassword';
import { ExportProcessesItem, ExportProcessesRequest, ExportProcessesResponse } from './type';

export const exportVisitsReport = (request) => {
    return clientExportServicePassword.post('/v1/exports/visits/', request);
};

export const exportQuestionnaireReport = (request) => {
    return clientExportServicePassword.post('/v1/exports/visits_with_questionnaires/', request);
};

export const exportCrowdReport = (request) => {
    return clientExportServicePassword.post('/v1/exports/crowd_tasks/', request);
};

export const exportProcessesList = (request: ExportProcessesRequest) => {
    const userId = localStorage.getItem('userId');
    return clientExportServicePassword.post<ExportProcessesResponse, ExportProcessesRequest>(
        '/v1/export_processes/list/',
        {
            ...request,
            created_by: userId,
        }
    );
};

export const exportProcessById = (id: string) => {
    return clientExportServicePassword.get<ExportProcessesItem, String>(
        `/v1/export_processes/${id}/`
    );
};

export const exportPhotosReportNew = (request) => {
    return clientExportServicePassword.post('/v1/exports/photo_visits/', request);
};

export const exportTimesheet = (request) => {
    return clientExportServicePassword.post('/v1/exports/timesheet/', request);
};

export const exportCrowdQuestionnairesReport = (request) => {
    return clientExportServicePassword.post('/api/v1/exports/crowd_questionnaire_lists/', request);
};
