import React from 'react';

function BookIcon({ color = '#414152' }) {
    return (
        // <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path d="M12 21V7C12 5.89543 12.8954 5 14 5H21.4C21.7314 5 22 5.26863 22 5.6V18.7143" stroke={color} stroke-linecap="round" />
        //     <path d="M12 21V7C12 5.89543 11.1046 5 10 5H2.6C2.26863 5 2 5.26863 2 5.6V18.7143" stroke={color} stroke-linecap="round" />
        //     <path d="M14 19L22 19" stroke={color} stroke-linecap="round" /> <path d="M10 19L2 19" stroke={color} stroke-linecap="round" />
        //     <path d="M12 21C12 19.8954 12.8954 19 14 19" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        //     <path d="M12 21C12 19.8954 11.1046 19 10 19" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
        // </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" stroke-width="1.5">
            <path fill="none" d="M0 0h24v24H0z" />
            <path fill="none" stroke={color} d="M21 18H6a1 1 0 0 0 0 2h15v2H6a3 3 0 0 1-3-3V4a2 2 0 0 1 2-2h16v16zM5 16.05c.162-.033.329-.05.5-.05H19V4H5v12.05zM16 9H8V7h8v2z" />
        </svg>

    );
}

export default BookIcon;
