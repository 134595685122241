import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorApi } from '../../apiFeature/types';
import { reduxStoreName } from './types';
import { deleteFeed, fetchFeed, updateFeed } from '../../apiFeature/feeds';
import { RootState } from '../store';
import { fileLinksStatus, filesUploadList } from '../../apiFeature/fileService';
import FeedsActions from './actions';
import { fetchBranchListByProjectId } from '../../apiFeature/branch';

const channel = new BroadcastChannel('visitUpdateStatus');

export module FeedsThunks {
    export const fetchCurrentFeed = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/fetchCurrentFeed`,
        async (arg, { rejectWithValue }) => {
            try {
                return await fetchFeed(arg);
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );

    export const fetchDeleteFeed = createAsyncThunk<any, string, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/fetchDeleteFeed`,
        async (arg, { rejectWithValue }) => {
            try {
                return await deleteFeed(arg);
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );

    export const fetchUpdateFeed = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/fetchUpdateFeed`,
        async (arg, { rejectWithValue, getState, dispatch }) => {
            try {
                const update = await updateFeed(arg);

                if (arg?.visitUpdateStatus) channel.postMessage(update);

                const state = getState() as RootState;
                const { photos } = state.fakeVisit;
                const ids: string[] = [];
                if (photos?.length > 0) {
                    for (let file of photos) {
                        const { photo } = file;
                        if (photo && file?.question_id?.length > 0) {
                            const requestForUploadFiles = file.isPhotoAnswer
                                ? {
                                      file: photo,
                                      objects: [
                                          {
                                              generic_type: 'SFA_VISIT',
                                              object_id: file?.question_id,
                                              type: 'sfa_question_result',
                                              visit_id: arg.visit_id,
                                          },
                                      ],
                                  }
                                : {
                                      file: photo,
                                      objects: [
                                          {
                                              generic_type: 'SFA_VISIT',
                                              object_id: file?.question_id,
                                              type: 'sfa_question_result',
                                              visit_id: arg.visit_id,
                                          },
                                      ],
                                  };
                            const responseUploadFile = await filesUploadList(requestForUploadFiles);
                            if (responseUploadFile[0]?.file_link_id.length > 0) {
                                ids.push(responseUploadFile[0]?.file_link_id);
                            }
                        }
                    }
                    if (ids?.length > 0) {
                        await fileLinksStatus({ ids });
                        setTimeout(async () => {
                            await fileLinksStatus({ ids });
                        }, 0);
                        setTimeout(async () => {
                            await fileLinksStatus({ ids });
                        }, 1000);
                        setTimeout(async () => {
                            await fileLinksStatus({ ids });
                        }, 2000);
                        setTimeout(async () => {
                            await fileLinksStatus({ ids });
                        }, 3500);
                    }
                }

                return update;
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );

    export const fetchBranches = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/fetchBranchesList`,
        async (arg, { rejectWithValue }) => {
            try {
                return await fetchBranchListByProjectId(arg);
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );
}
