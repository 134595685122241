import { BaseUserData } from '@types/user';

export function getFullName(user: BaseUserData): string {
    const fullName = [user?.last_name, user?.first_name, user?.patronymic_name, user?.phone]
        .filter(Boolean)
        .join(' ');
    return fullName;
}

export function getFullUserName(user: BaseUserData): string {
    const fullName = [user?.last_name, user?.first_name, user?.patronymic_name]
        .filter(Boolean)
        .join(' ');
    return fullName;
}
