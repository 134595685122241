import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Reset } from 'src/view/components/ui/Reset/Reset';

import styles from './Filter.module.scss';

import { Fields } from '../../Fields/Fields';
import { FieldsItem } from '../../Fields/Fields.type';
import { Button } from '../Button/Button';
import { ButtonArrow } from '../Button_Arrow/ButtonArrow';
import { useTranslation } from 'react-i18next';
import Close from '../../../../assets/images/svg/close.svg';

type Value = string | number | null | boolean;

export interface ButtonFilter {
    text: string | ReactNode;
    onClick: () => void;
    icon?: ReactNode;
    type?: 'primary' | 'default' | 'flat';
}

interface FilterProps {
    onReset?: () => void;
    isTouched?: boolean;
    fields: FieldsItem[];
    autoTouched?: boolean;
    onChange?: () => void;
    values?: Value[];
    filter_length?: number;
    buttons?: ButtonFilter[];
    isFilter?: boolean;
    closeFilter?: () => void;
}

export const Filter: FC<FilterProps> = ({
    onReset = () => {},
    isTouched = false,
    onChange,
    fields,
    values = [],
    autoTouched = false,
    buttons = [],
    filter_length,
    isFilter = false,
    closeFilter,
}) => {
    const [dropdown, setDropdown] = useState<boolean>(false);
    const { t } = useTranslation('translation');

    const getTouched = (): boolean => {
        if (!autoTouched) {
            return isTouched;
        }
        const valid: Value[] = values.filter(Boolean);

        return valid.length > 0 || isTouched;
    };

    useEffect(() => {
        if (onChange) {
            onChange();
        }
    }, [values]);

    return (
        <div className={isFilter ? `${styles.filter} main-listing__filter` : 'hiddenFilter'}>
            <div className={styles.filter__sticky_wrap}>
                <div onClick={closeFilter} className={isFilter ? 'close-filter-btn' : 'hidden'}>
                    <img src={Close} alt="close btn" />
                </div>
                <div className={styles.filter__content}>
                    <div className={styles.filter__header}>
                        <h2>{t('common.filters')}</h2>
                        <Reset onclick={onReset} status={!getTouched()} />
                    </div>
                    <div className={styles.filter__fields}>
                        <div className={styles.filter__allFields}>
                            <Fields
                                fields={
                                    filter_length && !dropdown
                                        ? fields.slice(0, filter_length)
                                        : fields
                                }
                            />
                        </div>
                        {filter_length && (
                            <div className={styles.filter__allFilters}>
                                <ButtonArrow
                                    className={dropdown ? 'close' : ''}
                                    text={!dropdown ? t('common.allFilters') : t('common.hide')}
                                    onClick={() => setDropdown(!dropdown)}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {buttons.length > 0 && (
                    <div className={styles.filter__buttons}>
                        {buttons.map((btn, idx) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Button type={btn.type} key={idx} onClick={btn.onClick}>
                                {btn.text}
                            </Button>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
