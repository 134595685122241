import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, RangePicker } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import FiltersSelectors from '@redux/filters/selectors';
import { FiltersThunks } from '@redux/filters/thunk';
import { getOptions } from '@tools/utils/functions';

const SupportRequestsFilters = ({ defaultValues, form }) => {
    const dispatch = useDispatch();
    const { supportRequestTypes } = useSelector(FiltersSelectors.filtersState);

    const { t } = useTranslation('translation');

    const { reset, watch, setValue } = form;
    const typeIds = watch('support_request_type_ids');

    const setActualValue = (curData, data, name) => {
        const value = data?.filter((e) => curData?.includes(e.id)).map((e) => e.id);
        if (curData && curData?.join() !== value?.join()) {
            setValue(name, value.length > 0 ? value : null);
        }
    };

    const getSupportRequestTypes = useCallback(() => {
        dispatch(FiltersThunks.getSupportRequestTypes({})).then((result) => {
            if (FiltersThunks.getSupportRequestTypes.fulfilled.match(result)) {
                const types = result?.payload || [];
                if (types?.length > 0) setActualValue(typeIds, types, 'support_request_type_ids');
            }
        });
    }, [dispatch, typeIds]);

    const start_date = sessionStorage.getItem('start_date');
    const end_date = sessionStorage.getItem('end_date');

    const date_range = useMemo(() => {
        return {
            start: start_date,
            end: end_date,
        };
    }, [start_date, end_date]);

    useEffect(() => {
        setValue('date_range', date_range);
    }, [setValue, date_range]);

    useEffect(() => {
        getSupportRequestTypes();
    }, [getSupportRequestTypes]);

    const onChange = (name, e) => {
        let value = e?.value;
        if (e && Array.isArray(e)) {
            value = e.length > 0 ? e.map((item) => item.value) : null;
        }
        setValue(name, value);
    };

    const handleReset = () => {
        reset(defaultValues);
    };

    const typeOptions = getOptions(supportRequestTypes);

    const filters = [
        {
            type: RangePicker,
            name: 'date_range',
            label: t('common.date_range'),
            placeholder: t('common.select'),
            dateFormat: 'dd.MM.yyyy',
            valueStart: date_range?.start,
            valueEnd: date_range?.end,
        },
        {
            type: Select,
            name: 'support_request_type_ids',
            label: t('feed.type'),
            onChange,
            value: typeIds ? typeOptions?.filter((e) => typeIds.includes(e.value)) : null,
            options: typeOptions,
            isMulti: true,
        },
    ];

    return { handleReset, filters };
};

export default SupportRequestsFilters;
