import React, { useCallback, useEffect } from "react";
import styles from './SettingsFilters.module.scss';
import { useTranslation } from 'react-i18next';
import { Select, TextField } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import FiltersSelectors from '@redux/filters/selectors';
import { FiltersThunks } from '@redux/filters/thunk';
import { getOptions } from '@tools/utils/functions';
import { rolesList } from "../SettingsRolesData/SettingsRolesData";

const SettingsFilters = ({ defaultValues, form }) => {
    const dispatch = useDispatch();
    const { projectsList, loading: loadingProjects } = useSelector(
        ProjectsSelectors.projectsSelectors
    );
    const { roles, rolesLoading } = useSelector(FiltersSelectors.filtersState);

    const { t } = useTranslation('translation');

    const projectsListId = projectsList?.[0]?.id;

    const { watch, setValue } = form;
    const projectId = watch('project_id');
    const role = watch('role');
    const roleMultiple = watch('roleMultiple');
    const switchType = watch('switchType');
    const projectValue = projectId || projectsListId;

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    const setActualValue = (curData, data, name) => {
        const value = data?.filter((e) => curData?.includes(e.id)).map((e) => e.id);
        if (curData && curData?.join() !== value?.join()) {
            setValue(name, value.length > 0 ? value : null);
        }
    };

    const getRoles = useCallback(() => {
        dispatch(FiltersThunks.getRolesThunk({})).then((result) => {
            if (FiltersThunks.getRolesThunk.fulfilled.match(result) && switchType === "ROLES") {
                const roles = result?.payload || [];
                if (roles?.length > 0) setActualValue(roleMultiple, roles, 'roleMultiple');
            }
        });
    }, [dispatch]);


    useEffect(() => {
        getProjects();
        if (!roles?.length && !rolesLoading) {
            getRoles();
        }
    }, []);

    const onChange = (name, e) => {
        let value = e?.value;
        if (e && Array.isArray(e)) {
            value = e.length > 0 ? e.map((item) => item.value) : null;
        }
        setValue(name, value);
    };

    const handleReset = () => {
        const [project] = projectsList || [];
        const { id } = project || {};
        setValue('project_id', id)
        setValue('role', '')
        setValue('roleMultiple', []);
        setValue('name', '')
    };

    const filteresRoles = switchType === "ROLES" ? roles.filter((r) => rolesList.includes(r.sys_name)) : roles;
    const projectOptions = getOptions(projectsList, 'full_name');
    const valueForRoleOptions = switchType === "ROLES" ? 'sys_name': 'id';
    const rolesOptions = getOptions(filteresRoles, 'name', valueForRoleOptions);

    let filters: any = [
        {
            type: Select,
            name: 'project_id',
            label: t('common.project'),
            placeholder: t('common.all'),
            onChange,
            value: projectValue
                ? projectOptions?.filter((e) => projectValue.includes(e.value))
                : projectOptions?.[0],
            options: projectOptions,
            isLoading: loadingProjects,
        },
        {
            type: Select,
            name: switchType === "ROLES" ? 'roleMultiple' : 'role',
            label: t('catalog.team.roles'),
            onChange,
            value: !(switchType === "ROLES")
                ? rolesOptions?.find((e) => e.value === role)
                : roleMultiple
                    ? rolesOptions?.filter((e) => roleMultiple.includes(e.value))
                    : null,
            options: rolesOptions,
            isLoading: rolesLoading,
            isClearable: true,
            isMulti: switchType === "ROLES" ? true : false,
        },
    ];

    if (switchType === 'USERS') {
        filters = [
            ...filters,
            {
                type: TextField,
                name: 'name',
                label: t('catalog.users.fullname'),
                placeholder: t('catalog.users.searchFullname'),
            },
        ]
    }

    return { handleReset, filters };
};

export default SettingsFilters;