import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components';
import styles from './QuestionEditDialogActions.module.scss';

const QuestionEditDialogActions: FC = ({ setDialog, setCancelChange, errors }) => {
    const { t } = useTranslation('translation');

    return (
        <div className={styles.btns}>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => setDialog(false)}
                disabled={errors && Object.keys(errors)?.length > 0}
            >
                {t('buttons.save').toLocaleUpperCase()}
            </Button>
            <Button variant="outlined" color="secondary" onClick={setCancelChange}>
                {t('buttons.cancel').toLocaleUpperCase()}
            </Button>
        </div>
    );
};

export default QuestionEditDialogActions;
