import React from 'react';
import styles from './Outlets.module.scss';
import { useTranslation } from 'react-i18next';
import { Layout } from '@components';
import OutletsData from './OutletsData/OutletsData';
import OutletsFilters from './OutletsFilters/OutletsFilters';

export const Outlets = () => {
    const { t } = useTranslation('translation');

    const project_id = sessionStorage.getItem('project_id');
    const defaultValues = {
        project_id: project_id !== 'undefined' ? project_id : '',
        project_retail_ids: null,
        branch_ids: null,
        territory_ids: null,
        city_ids: null,
        external_code: '',
    };

    const getFilters = (values) => {
        return values;
    };

    return (
        <Layout
            title={t('catalog.outlets.title')}
            renderSearch={() => ({})}
            renderData={(props) => <OutletsData {...props} />}
            renderFilters={OutletsFilters}
            defaultValues={defaultValues}
            getFilters={getFilters}
        />
    );
};
