import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Header.module.scss';

interface IHeader {
    className?: string | undefined;
    children: ReactNode;
    type?: 'inner' | undefined;
}

const Header: FC<IHeader> = ({ children, type, className = '' }) => {
    return (
        <header
            className={classNames(styles.header, className, {
                [styles.typeInner]: type === 'inner',
            })}
        >
            <div className={styles.headerContent}>{children}</div>
        </header>
    );
};

export default Header;
