import React, { FC } from 'react';
import { ToggleButtonGroup as TBG, ToggleButton } from '@mui/material';
import classNames from 'classnames';
import styles from './ToggleButtonGroup.module.scss';

interface IToggleButton {
    value: string;
    label: string;
}

interface IToggleButtonGroup {
    className?: string | undefined;
    value: string;
    onChange: () => void;
    elements: IToggleButton[];
}

const ToggleButtonGroup: FC<IToggleButtonGroup> = ({
    elements = [],
    className,
    value,
    onChange,
    ...props
}) => {
    return (
        <TBG
            {...props}
            className={classNames(styles.toggleButtonGroup, className)}
            value={value}
            onChange={onChange}
        >
            {elements.map((e, i) => (
                <ToggleButton key={i} value={e?.value}>
                    {e?.label}
                </ToggleButton>
            ))}
        </TBG>
    );
};

export default ToggleButtonGroup;
