import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorApi } from '../../apiFeature/types';
import { reduxStoreName } from './types';
import {
    fetchQuestionnaireDetailed,
    fetchQuestionnairesList,
} from '../../apiFeature/questionnaires';
import {
    QuestionnairesDetailedResponse,
    QuestionnairesListRequest,
} from '../../apiFeature/questionnaires/type';
import { getProjectOutlet } from '../../apiFeature/projectOutlets';
import { createRestoredVisit, getRestoredVisitAvailableDates } from '../../apiFeature/visits/restoredVisit';
import FakeVisitAction from './actions';
import { fileLinksStatus, filesUpload, filesUploadList } from '../../apiFeature/fileService';
import { AvailableDatesRequest } from '../../apiFeature/visits/restoredVisit/types';
import { getAnswersAndPhotos } from './utils';
import { RootState } from '../store';
import { utcOffsetInHours } from '../../tools/utils/utcOffsetConvert';

export module FakeVisitThunks {
    export const fetchQuestionnaires = createAsyncThunk<
        any,
        QuestionnairesListRequest,
        { rejectValue: ErrorApi }
    >(`${reduxStoreName}/fetchQuestionnairesList`, async (arg, { rejectWithValue }) => {
        try {
            return await fetchQuestionnairesList(arg);
        } catch (e) {
            return rejectWithValue(e);
        }
    });

    export const fetchQuestionnairesDetailed = createAsyncThunk<
        QuestionnairesDetailedResponse,
        { id: string },
        {
            rejectValue: ErrorApi | string;
        }
    >(`${reduxStoreName}/fetchQuestionnairesDetailed`, async ({ id }, { rejectWithValue }) => {
        try {
            return await fetchQuestionnaireDetailed({
                ids: [id],
            });
        } catch (e) {
            return rejectWithValue(e);
        }
    });

    export const fetchProjectOutlet = createAsyncThunk<
        any,
        string[] | string,
        { rejectValue: ErrorApi }
    >(`${reduxStoreName}/fetchProjectOutlet`, async (id, { rejectWithValue }) => {
        try {
            return await getProjectOutlet(id);
        } catch (e) {
            return rejectWithValue(e);
        }
    });

    export const fetchAvailableDates = createAsyncThunk<
        any,
        AvailableDatesRequest,
        { rejectValue: ErrorApi }
    >(`${reduxStoreName}/fetchAvailableDates`, async (arg, { rejectWithValue }) => {
        try {
            return await getRestoredVisitAvailableDates(arg);
        } catch (e) {
            return rejectWithValue(e);
        }
    });

    export const createFakeVisitThunk = createAsyncThunk<any, any, { rejectValue: ErrorApi }>(
        `${reduxStoreName}/createFakeVisitThunk`,
        async (filter, { rejectWithValue, getState, dispatch }) => {
            const {
                userId,
                start_point_lat,
                start_point_lon,
                startTime,
                end_point_lat,
                end_point_lon,
                endTime,
                comment,
                projectOutletId,
                questionResults,
                projectId,
                photos,
            } = filter;

            const state = getState() as RootState;

            const utc_offset = utcOffsetInHours();

            const { questionAnswers, photoShopSign, addedQuestionnaires } = state.fakeVisit;
            const { profile } = state.user;

            const { photos: questionnairePhotos, answers: _answers } = getAnswersAndPhotos(
                questionAnswers ?? {}
            );
            const countPhotos = photoShopSign ? photos.length + 1 : photos.length
            const request = {
                visit: {
                    user_id: userId?.value,
                    // user_id: profile?.id,
                    start_point_lon,
                    start_point_lat,
                    start_time: startTime,
                    start_distance: 0,
                    end_point_lon,
                    end_point_lat,
                    end_time: endTime,
                    end_distance: 0,
                    result: {
                        count_photo_visit: countPhotos,
                        questionnaire_ids: addedQuestionnaires,
                    },
                    comment,
                    utc_offset,
                },
                route_point: {
                    project_outlet_id: projectOutletId?.value,
                },
                question_results: _answers,
            };
            try {
                if (
                    !userId?.value ||
                    !projectOutletId?.value ||
                    !startTime ||
                    !endTime ||
                    !projectId?.value
                ) {
                    dispatch(FakeVisitAction.setErrorNotFullData());
                    throw new Error('Not all fields are filled');
                } else {
                    const fakeVisit = await createRestoredVisit(request);
                    const ids: string[] = [];
                    if (fakeVisit.restored_visit?.id) {
                        const startPhotoReq = {
                            file: photoShopSign,
                            object_id: fakeVisit.restored_visit?.id,
                            type: 'sfa_visit',
                            user_id: userId?.value,
                        };
                        const respStartPhoto = await filesUpload(startPhotoReq);
                        if (respStartPhoto?.file_link_id?.length > 0) {
                            ids.push(respStartPhoto.file_link_id);
                        }

                        for (let file of photos) {
                            const { photo } = file;
                            if (file.isCopy) {
                                const requestForUploadFiles = {
                                    file: photo,
                                    object_id: fakeVisit.restored_visit?.id,
                                    type: 'sfa_visit',
                                    user_id: userId?.value,
                                };
                                const responseUploadFile = await filesUpload(requestForUploadFiles);
                            }
                            if (photo && file.question_id.length > 0 && !file?.isCopy) {
                                const requestForUploadFiles = file.isPhotoAnswer
                                    ? {
                                        file: photo,
                                        objects: [
                                            {
                                                generic_type: 'SFA_VISIT',
                                                object_id: file?.question_id,
                                                type: 'sfa_question_result',
                                                visit_id: fakeVisit?.restored_visit?.id,
                                            },
                                        ],
                                    }
                                    : {
                                        file: photo,
                                        objects: [
                                            {
                                                object_id: file?.question_id,
                                                type: 'sfa_question_result',
                                                visit_id: fakeVisit?.restored_visit?.id,
                                            },
                                        ],
                                    };
                                const responseUploadFile = await filesUploadList(
                                    requestForUploadFiles
                                );
                                if (responseUploadFile[0]?.file_link_id.length > 0) {
                                    ids.push(responseUploadFile[0]?.file_link_id);
                                }
                            }
                        }

                        if (ids.length > 0) {
                            const status = await fileLinksStatus({ ids });
                            setTimeout(async () => {
                                await fileLinksStatus({ ids });
                            }, 0);
                            setTimeout(async () => {
                                await fileLinksStatus({ ids });
                            }, 1000);
                            setTimeout(async () => {
                                await fileLinksStatus({ ids });
                            }, 2000);
                            setTimeout(async () => {
                                await fileLinksStatus({ ids });
                            }, 3500);
                        }
                    }
                    return fakeVisit;
                }
            } catch (e) {
                return rejectWithValue(e);
            }
        }
    );
}
