type TLinkedList = { prev_id: string | null; next_id: string | null; id: string };

export const getSortedList = <T extends TLinkedList>(steps: T[]): T[] => {
    const arr: T[] = [];
    let currentId: string | null | undefined = '';
    if (arr.length === 0) {
        const first = steps.find((item) => item.prev_id === null);
        if (first) {
            arr.push(first);
            currentId = first.next_id ?? null;
        } else {
            currentId = null;
        }
    }
    let while_break = false;
    while (currentId !== null && !while_break) {
        const next = steps.find((item) => item.id === currentId);
        if (next) {
            arr.push(next);
            currentId = next.next_id ?? null;
        } else {
            currentId = null;
        }
        if (arr.length > steps.length) {
            while_break = true;
        }
    }
    if (while_break) {
        return steps;
    }
    return arr.length === steps.length ? arr : steps;
};

export const sortQuestionnaires = (data) => {
    const questionnaires = structuredClone(data);
    return questionnaires.map((questionnairesItem) => {
        questionnairesItem.questionnaire_steps = getSortedList(
            questionnairesItem.questionnaire_steps
        );
        questionnairesItem.questionnaire_steps.forEach((s) => {
            s.questions = getSortedList(s.questions);
            s.questions.forEach((q) => {
                q.question_answers = getSortedList(q.question_answers);
            });
        });
        return questionnairesItem;
    });
};
