import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Filters, DatePicker } from '@components';
import ProjectsSelectors from '@redux/projects/selectors';
import { FiltersThunks } from '@redux/filters/thunk';
import FiltersSelectors from '@redux/filters/selectors';
import { getOptions } from '@tools/utils/functions';
import { useOutlets } from '@tools/hooks/useOutlets';
import styles from './CreateForm.module.scss';
import { visitTypeOptions as vTOptions } from '../../../helpers/helpers';

const CreateForm = ({ defaultValues, form }) => {
    const dispatch = useDispatch();
    const { userProjects, userProjectsLoading } = useSelector(FiltersSelectors.filtersState);

    const { t } = useTranslation('translation');

    const { projectsList, loading: loadingProjects } = useSelector(
        ProjectsSelectors.projectsSelectors
    );

    const {
        setValue,
        watch,
        formState: { errors },
    } = form;
    const currValues = watch();

    const { project_id_create: dpID } = defaultValues || {};
    const {
        project_id_create: pID,
        user_ids,
        outlet_ids,
        ir_type_visit,
        visit_date,
        type: visit_type,
    } = currValues;

    const project_id_create = pID || dpID;

    const getFiltersProjects = useCallback(() => {
        dispatch(
            FiltersThunks.getUserProjectExecutors({ project_id: project_id_create, visit_type })
        ).then((result) => {
            if (FiltersThunks.getUserProjectExecutors.fulfilled.match(result)) {
                const users = result?.payload?.[project_id_create] || [];
                const hasValue = users?.some((e) => e.id === user_ids);
                if (!hasValue) setValue('user_ids', '');
            }
        });
    }, [project_id_create, visit_type]);

    const [projectOutlets, outletsLoading, onInputChangeOutlets, moreOutlets] =
        useOutlets(project_id_create);

    useEffect(() => {
        if (project_id_create) getFiltersProjects();
    }, [project_id_create]);

    useEffect(() => {
        if (projectOutlets && outlet_ids) {
            const hasValue = projectOutlets?.some((e) => e.id === outlet_ids);
            if (!hasValue) setValue('outlet_ids', '');
        }
    }, [projectOutlets]);

    const onChange = (name, e) => {
        let value = e?.value || e;
        if (e && Array.isArray(e)) {
            value = e.length > 0 ? e.map((item) => item.value) : null;
        }
        setValue(name, value);
    };

    const visitTypeOptions = [
        ...vTOptions,
        {
            label: t('feed.jointTour'),
            value: 'joint_tour',
        },
    ];
    const projectOptions = getOptions(projectsList, 'full_name');
    const usersOptions = getOptions(userProjects?.[project_id_create], [
        'last_name',
        'first_name',
        'patronymic_name',
        'phone',
    ]);
    const outletsOptions = getOptions(
        projectOutlets,
        ['external_code', 'name', 'address'],
        'id',
        false
    );

    const filters = [
        {
            type: Select,
            name: 'ir_type_visit',
            label: t('feed.visitType'),
            onChange,
            value: ir_type_visit ? visitTypeOptions?.find((e) => e.value === ir_type_visit) : null,
            options: visitTypeOptions,
        },
        {
            type: Select,
            name: 'project_id_create',
            label: t('common.project'),
            onChange,
            value: project_id_create
                ? projectOptions?.find((e) => e.value === project_id_create)
                : projectOptions?.[0],
            options: projectOptions,
            isLoading: loadingProjects,
        },
        {
            type: Select,
            name: 'user_ids',
            label: t('common.user'),
            placeholder: t('common.all'),
            onChange,
            value: user_ids ? usersOptions?.filter((e) => user_ids.includes(e.value)) : null,
            options: usersOptions,
            isLoading: userProjectsLoading,
            ...(errors?.user_ids?.message && { error: { message: errors?.user_ids?.message } }),
        },
        {
            type: Select,
            name: 'outlet_ids',
            label: t('common.project_outlet'),
            placeholder: t('common.all'),
            onChange: (name, e) => {
                setValue(name, e?.value, {
                    shouldDirty: true,
                    shouldValidate: true,
                });
                if (!e?.value) onInputChangeOutlets('', { action: 'clear' });
            },
            value: outlet_ids ? outletsOptions?.filter((e) => outlet_ids.includes(e.value)) : null,
            options: outletsOptions,
            isLoading: outletsLoading,
            isClearable: true,
            onInputChange: onInputChangeOutlets,
            onMenuScrollToBottom: moreOutlets,
            ...(errors?.outlet_ids?.message && { error: { message: errors?.outlet_ids?.message } }),
        },
        {
            type: DatePicker,
            name: 'visit_date',
            label: t('feed.visitDate'),
            placeholder: t('feed.visitDate'),
            dateFormat: 'dd.MM.yyyy',
            value: visit_date,
            onChange,
            ...(errors?.visit_date?.message && { error: { message: errors?.visit_date?.message } }),
        },
    ];

    return (
        <form>
            <Filters className={styles.filters} defaultValues={defaultValues} filters={filters} />
        </form>
    );
};

export default CreateForm;
