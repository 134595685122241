import {
    CreateRoutesRequest,
    CreateRoutesResponse,
    RoutesListRequest,
    RoutesListResponse,
} from './types';
import { clientPassword } from '../ClientPassword';

export const createRoutes = (request: CreateRoutesRequest) =>
    clientPassword.post<CreateRoutesResponse, CreateRoutesRequest>('/routes/', request);

export const getRoutes = (request: RoutesListRequest) =>
    clientPassword.post<RoutesListResponse, RoutesListRequest>('/routes/list/', request);
