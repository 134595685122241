import { createSlice } from '@reduxjs/toolkit';
import { SettingsState } from './types';
import { reduxStoreName } from './types';
import { SettingsThunks } from './thunk';
import { t } from 'i18next';

const initialState: SettingsState = {
    loading: false,
    errMessage: '',
    settingsAll: null,
    settingsAllUser: null,
    settingsAllLoaded: false,
    settingsAllUserLoaded: false,
};

const slice = createSlice({
    name: reduxStoreName,
    initialState: { ...initialState },
    reducers: {
        reset() {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(SettingsThunks.getAllSettings.pending, (state) => {
                state.loading = true;
                state.settingsAll = null;
                state.errMessage = '';
            })
            .addCase(SettingsThunks.getAllSettings.rejected, (state, { payload }) => {
                state.loading = false;
                const errorMessage =
                    payload?.message || payload?.data?.detail || t('messages.unknownError');
                state.settingsAll = null;
                state.errMessage = errorMessage;
                state.settingsAllLoaded = true;
            })
            .addCase(SettingsThunks.getAllSettings.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.errMessage = '';
                state.settingsAll = payload;
                state.settingsAllLoaded = true;
            });
        builder
            .addCase(SettingsThunks.getAllUserSettings.pending, (state) => {
                state.loading = true;
                state.settingsAllUser = null;
                state.errMessage = '';
            })
            .addCase(SettingsThunks.getAllUserSettings.rejected, (state, { payload }) => {
                state.loading = false;
                const errorMessage =
                    payload?.message || payload?.data?.detail || t('messages.unknownError');
                state.settingsAllUser = null;
                state.errMessage = errorMessage;
                state.settingsAllUserLoaded = true;
            })
            .addCase(SettingsThunks.getAllUserSettings.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.errMessage = '';
                state.settingsAllUser = payload?.user_settings?.items || [];
                state.settingsAllUserLoaded = true;
            });
        builder
            .addCase(SettingsThunks.getSettingsOptions.pending, (state) => {
                state.loading = true;
                state.errMessage = '';
            })
            .addCase(SettingsThunks.getSettingsOptions.rejected, (state, { payload }) => {
                state.loading = false;
                const errorMessage = payload?.message || payload?.data?.detail || t('messages.unknownError');
                state.errMessage = errorMessage;
            })
            .addCase(SettingsThunks.getSettingsOptions.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.errMessage = '';
                const [setting] = payload?.setting_options || [];
                const { setting_id } = setting || {};
                state.settingsOptions = {
                    ...state.settingsOptions,
                    [setting_id]: payload?.setting_options
                };
            })
    },
});

export const reducer = slice.reducer;
