import { t } from 'i18next';

export type BaseTypeEntity = {
    id: string;
    created_at: string;
    updated_at: string;
    utc_offset: number;
};

export type SettingValueType = BaseTypeEntity & {
    project_id: string;
    val: string;
};

export type SettingType = BaseTypeEntity & {
    name: string;
    code: string;
    group: string;
    setting_values?: SettingValueType[];
};

export type GlobalSettingType = BaseTypeEntity & {
    key: string;
    value: string;
};

export type SettingsAllRequestType = {
    name: string;
    code: string;
    group: string;
    project_ids: string[];
    val: string;
    codes: string[];
};

export type SettingsAllResponseType = {
    settings: SettingType[];
    global_settings: GlobalSettingType[];
};

export type SettingsListRequestType = {
    name: string;
    code: string;
    group: string;
};

export type SettingsListResponseType = {
    settings: SettingType;
};

type SettingValuesItems = {
    id: string;
    val: string;
};

export type SettingValuesUpdateManyRequestType = {
    items: SettingValuesItems[];
};

export type SettingValuesUpdateManyResponseType = {
    setting_values?: SettingValueType[];
};

export type SettingsAllUserRequestType = {
    project_id: string;
    role_id: string;
    user_id: string;
    pagination: { limit: number; offset: number };
};

export type SettingsAllUserItem = {
    user_id: string;
    setting_id: string;
    code: string;
    setting_kind: string;
    setting_option_id: string;
    project_id: string;
    role_id: string;
    setting_value: string;
};

export type SettingsAllUserResponseType = {
    user_settings: { items: SettingsAllUserItem[] };
    total: number;
    limit: number;
    offset: number;
};

export type ProjectSettingsRequest = {
    project_id?: string;
    role_id?: string;
    pagination: {
        limit: number;
        offset?: number;
    };
};

export type UserSettingsRequestType = {
    project_id?: string;
    role_id?: string;
    user_id?: string;
    pagination: {
        limit: number;
        offset?: number;
    };
};

export type UserSettingItem = {
    user_id?: string;
    setting_id?: string;
    code?: string;
    setting_kind?: string;
    setting_option_id?: string;
    project_id?: string;
    role_id?: string;
    setting_value?: string;
};

export type UserSettingsResponseType = {
    user_settings: {
        items: UserSettingItem[];
        total: number;
        limit: number;
        offset: number;
    };
};

export type SettingsOptionsRequest = {
    setting_id?: string;
    value?: string;
    weight?: number;
};

export type SettingsOption = {
    id: string;
    created_at: string;
    updated_at: string;
    setting_id: string;
    value: string;
    weight: number;
  };

export type SettingsOptionsResponse = {
    setting_options: SettingsOption[];
};

export const settingOptionsNames = {
    CREATE: t('settings.create'),
    DELETE: t('settings.delete'),
    UPDATE: t('settings.update'),
    READ: t('settings.read'),
    NOT_ALLOWED: t('settings.notAllowed'),
} as const;
