import React, { useCallback, useEffect } from 'react';
import { Select, TextField } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import FiltersSelectors from '@redux/filters/selectors';
import { FiltersThunks } from '@redux/filters/thunk';
import { t } from 'i18next';
import { statusOptions } from '@constants';

const UsersFilters = ({ defaultValues, form }) => {
    const dispatch = useDispatch();
    const { projectsList } = useSelector(ProjectsSelectors.projectsSelectors);
    const { roles } = useSelector(FiltersSelectors.filtersState);

    const { reset, watch, setValue } = form;
    const projectValue = watch('project');
    const status = watch('status');
    const roleValues = watch('roles');

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    const setActualValue = (curData, data, name) => {
        const value = data?.filter((e) => curData?.includes(e.id)).map((e) => e.id);
        if (curData && curData?.join() !== value?.join()) {
            setValue(name, value.length > 0 ? value : null);
        }
    };

    const getRoles = useCallback(() => {
        dispatch(FiltersThunks.getRolesThunk({})).then((result) => {
            if (FiltersThunks.getRolesThunk.fulfilled.match(result)) {
                const roles = result?.payload || [];
                if (roles?.length > 0) setActualValue(roleValues, roles, 'roles');
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getRoles();
    }, [getRoles]);

    useEffect(() => {
        getProjects();
    }, [getProjects]);

    const onChange = (name, e) => {
        let value = e?.value;
        if (e && Array.isArray(e)) {
            value = e.length > 0 ? e.map((item) => item.value) : null;
        }
        setValue(name, value);
    };

    const handleReset = () => {
        const [project] = projectsList || [];
        const { id } = project || {};
        reset({ ...defaultValues, project: id });
    };

    const projectOptions = projectsList?.map(({ full_name, id }) => ({
        label: full_name,
        value: id,
    }));

    const rolesOptions = roles?.map((role) => ({
        label: role.name,
        value: role.id,
    }));

    const filters = [
        {
            type: TextField,
            name: 'name',
            label: t('catalog.users.fullname'),
            placeholder: t('catalog.users.searchFullname'),
        },
        {
            type: TextField,
            name: 'phone',
            label: t('common.phone'),
            placeholder: t('catalog.team.enterPhone'),
        },
        {
            type: TextField,
            name: 'id',
            label: t('catalog.users.id'),
            placeholder: t('catalog.users.searchId'),
        },
        // {
        //     type: TextField,
        //     name: 'snils',
        //     label: t('reports.reports.snils'),
        //     placeholder: t('catalog.team.enterSnils')
        // },
        {
            type: Select,
            name: 'roles',
            label: t('catalog.team.roles'),
            onChange,
            value: roleValues ? rolesOptions?.filter((e) => roleValues.includes(e.value)) : null,
            options: rolesOptions,
            isMulti: true,
        },
        {
            type: Select,
            name: 'status',
            label: t('common.status'),
            placeholder: t('common.all'),
            onChange,
            value: statusOptions?.find((e) => e.value === status),
            options: statusOptions,
            isClearable: true,
        },
    ];

    return { handleReset, filters };
};

export default UsersFilters;
