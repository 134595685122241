import React from 'react';

function ScissorsIcon({ color = '#414152' }) {
    return (
        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.01445 6.83286L3.01449 6.83292L6.36697 11.6952L5.85795 12.4333C4.33342 11.9936 2.64003 12.5524 1.6856 13.9368L1.68554 13.9368C0.487181 15.6754 0.898171 18.0732 2.62012 19.298L2.62031 19.2982C4.35168 20.5291 6.73586 20.0971 7.94111 18.3492L7.94114 18.3492C8.64122 17.3338 8.78994 16.0978 8.45613 15.0036L9.00009 14.2146L9.54393 15.0034C9.21031 16.0976 9.35898 17.3334 10.0588 18.3488L10.0589 18.349C11.2641 20.0968 13.6481 20.5288 15.3795 19.298L15.3796 19.2979C17.1018 18.0731 17.5129 15.675 16.3144 13.9366L16.3144 13.9365C15.3599 12.5523 13.6666 11.9936 12.1421 12.4332L11.633 11.695L14.9853 6.833C14.9853 6.83298 14.9853 6.83296 14.9853 6.83295C16.0293 5.31898 15.6332 3.23556 14.1558 2.18506L13.3289 1.59708L12.753 2.43243L9.00004 7.87609L5.24673 2.43237L4.67081 1.59706L3.84394 2.18503C2.36649 3.23563 1.97086 5.31905 3.01445 6.83286Z" stroke={color} strokeWidth="2" />
        </svg>

    );
}

export default ScissorsIcon;