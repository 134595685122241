import { RootState } from '../store';
import { adapter, detailedAdapter } from './adapter';

module AssignmentSelectors {
    export const assignmentSelector = (state: RootState) => state.questionnaireAssignment;
    export const detailed = (state: RootState) => state.questionnaireAssignment?.detailedList;

    // questionnaire/list
    export const questionnaireListAdapter = adapter.getSelectors<RootState>(assignmentSelector);
    export const questionnaireList = questionnaireListAdapter.selectAll;
    export const paginatedQuestionnaireList = (state: RootState) =>
        assignmentSelector(state).questionnairesPaginatedData;
    // export const currentQuestionnaire = assignmentSelector.currentQuestionnaire;
    // detailed-list
    export const detailedQuestAdapter = detailedAdapter.getSelectors<RootState>(detailed);

    export const detailedQuestionnaireList = detailedQuestAdapter.selectAll;
}

export default AssignmentSelectors;
