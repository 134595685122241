import { clientPassword } from "../ClientPassword"
import { SupportRequestRequest, SupportRequestResponse, SupportRequestV2Request, SupportRequestV2Response } from "./types"

export const fetchSupportRequestsList = (request: SupportRequestRequest) => {
    return clientPassword.post<SupportRequestResponse, SupportRequestRequest>('/support_requests/list/', request);
};

export const fetchSupportRequestTypes = () => {
    return clientPassword.post('/support_request_types/list/', {});
};

export const fetchSupportRequestsListV2 = (request: SupportRequestV2Request) => {
    return clientPassword.post<SupportRequestV2Response, SupportRequestV2Request>('/v2/support_requests/list/', request);
};