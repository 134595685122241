import React, { FC, useEffect, useState } from "react";
import styles from './CheckHistoryItem.module.scss';
import { Visit } from '@types/visits';
import moment from "moment";
import { fetchCheckListResult } from "@apiFeature/checkers";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { extractErrorMessage } from '@tools/utils/functions';
import { CheckListResultItem } from "@apiFeature/checkers/types";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

interface ICheckHistoryItem {
    visit: Visit;
}

export const CheckHistoryItem: FC<ICheckHistoryItem> = ({ visit }) => {
    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [checkResult, setCheckResult] = useState<CheckListResultItem | null>(null);
    const { number, start_time, id } = visit || {};
    const startTimeFormatted = start_time ? moment(start_time).format('DD.MM.YYYY') : '';

    const handleOpenVisit = () => {
        navigate(`/visit-check/${id}`);
    }

    useEffect(() => {
        if (id) {
            fetchCheckListResult({
                object_id: id
            }).then((res) => {
                const { items } = res?.check_list_results || {};
                const [checkResult] = items || [];
                if (checkResult) {
                    setCheckResult(checkResult?.result)
                }
            }).catch((error) => {
                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
            })
        }
    }, [id]);
    
    return (
        <div className={styles.visitItem}>
            <div >{number && `${t('feed.visit')} №${number} ${t('common.from')} ${startTimeFormatted}`}</div>
            <div className={classNames(styles.checkResult, {
                [styles.accepted]: checkResult && checkResult.startsWith('Прин'),
                [styles.declined]: checkResult && checkResult.startsWith('Отклон'),
                [styles.notChecked]: !checkResult,
            })}>{checkResult || t('feed.notChecked')}</div>
            <OpenInNewIcon
                className={styles.openVisitIcon}
                onClick={handleOpenVisit}
            />
        </div>
    )
};