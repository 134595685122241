import { ProjectType } from 'src/api/projects/projects.type';
import { clientAuthPassword, clientPassword } from '../ClientPassword';

export interface CreateUserRequestDataType {
    first_name: string | null;
    last_name: string | null;
    patronymic_name: string | null;
    date_of_birth: string | undefined | null;
    phone: string | null;
    sex: 'male' | 'female';
    email: string | null;
    snils: string | null;
    projects: ProjectType[] | string;
    branches: Array<any> | string;
    roles: Array<any>;
    territories: Array<any>;
    status: string;
}

export interface UserListReq {
    name?: string;
    ids?: string[];
    phones?: string[];
    roles?: string[];
    status?: 'active';
    pagination?: {
        limit?: number;
        offset?: number;
    };
}

export const fetchCreateUser = (request: CreateUserRequestDataType) => {
    return clientAuthPassword.post('/api/v1/users/', request);
};

export const userAssignProject = (arg) => {
    return clientPassword.post(
        `/v2/user_project/assign_user_projects/?user_id=${arg?.user_id}&should_relink=true`,
        arg?.request
    );
};

export const userAssignBranch = (arg) => {
    return clientPassword.post(
        `/v2/user_branch/assign_user_branches/?user_id=${arg?.user_id}`,
        arg?.request
    );
};

export const getUsrProj = (request) => {
    return clientPassword.post(`/v2/user_project/get_user_projects/`, request);
};

export const fetchUserProjects = (request) => {
    return clientPassword.post(`/user_projects/executors_filter/`, request);
};

export const fetchManagerProjects = (request) => {
    return clientPassword.post(`/user_projects/managers_filter/`, request);
};

export const fetchSetPass = (arg) => {
    return clientAuthPassword.put(`/api/v1/users/${arg?.user_id}/set_password/`, arg.request);
};

export const getusrBranch = (request) => {
    return clientPassword.post(`/v2/user_branch/get_list/`, request);
};

export const fetchUpdateUser = (arg) => {
    return clientAuthPassword.patch(`/api/v1/users/${arg?.user_id}/`, arg?.request);
};

export const fetchUserList = (request: UserListReq) => {
    return clientAuthPassword.post(`/api/v1/users/list/`, request).then((res) => {
        return res?.users;
    });
};

export const getUserById = (user_id: string) => {
    if (user_id) {
        return clientAuthPassword.get(`/api/v1/users/${user_id}/`);
    }
};

export const getUsersList = async (request) => {
    const users = await clientPassword.post('/user_projects/executors/', request);
    return users.executors;
};

export const fetchUserProjectExecutors = async (request) => {
    const users = await clientPassword.post('/v2/user_project/executors/', request);
    return users.executors;
};

export const unlinkUser = (userId: string, projectsId: string[]) => {
    return clientPassword.post('/user_projects/unlink_list/', {
        items: projectsId.map((id) => ({
            user_id: userId,
            project_id: id,
        })),
    });
};

export const createUserV2 = async (request) => {
    return clientAuthPassword.post(`/api/v2/users/`, request);
};

export const updateUserV2 = async (userId, request) => {
    return clientAuthPassword.patch(`/api/v2/users/${userId}/`, request);
};

export const addRoles = async (userId, request) => {
    return clientAuthPassword.post(`/api/v2/users/add_roles/${userId}/`, request);
};

export const deleteRoles = async (userId, request) => {
    return clientAuthPassword.post(`/api/v2/users/del_roles/${userId}/`, request);
};

export const unlinkUserProject = (request) => {
    return clientPassword.post('/user_projects/unlink_list/', {
        items: request,
    });
};
