import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './ProjectDetails.module.scss';
import { Header, Title, BackLink, TextField, Select, Loader, Label, Button } from '@components';
import { Book } from '@/navigation/Book';
import { useNavigate, useParams } from 'react-router-dom';
import { getProjectById, updateProject, createProject } from '@apiFeature/projects';
import { fetchAllSettings, settingValuesUpdateMany } from '@apiFeature/settings';
import { useManagers } from './hooks/useManagers';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { statusOptions, merchandisingTypeOptions } from '@constants';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import FiltersSelectors from '@redux/filters/selectors';
import { FiltersThunks } from '@redux/filters/thunk';
import { useAppDispatch, useAppSelector } from '@tools/hooks/redux';
import { useRoles } from '@tools/hooks/useRoles';
import { extractErrorMessage, getOptions } from '@tools/utils/functions';
import classNames from 'classnames';
import ActionsBtns from './ActionsBtns/ActionsBtns';
import arrowSelectIcon from '@images/svg/arrow-for-select-red.svg';
import arrowOpenSelectIcon from '@images/svg/arrow-for-select-open-red.svg';

export const ProjectDetails: FC = () => {
    const { projectId } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('translation');
    const { isAdmin } = useRoles();
    const dispatch = useAppDispatch();
    const { projectsList, loading: projectsLoading } = useAppSelector(
        ProjectsSelectors.projectsSelectors
    );
    const { clients, clientsLoading } = useAppSelector(FiltersSelectors.filtersState);

    const [loading, setLoading] = useState<boolean>(false);
    const [isOpenedSettings, setOpenedSettings] = useState<boolean>(false);
    const [projectData, setProjectData] = useState({});
    const { full_name, status, manager_id } = projectData || {};

    const defaultErrorText = t('messages.fieldRequired');
    const validationSchema = object({
        short_name: string().required(defaultErrorText),
        external_code: string().required(defaultErrorText),
        client_id: string().when('projectId', (projectId, schema) =>
            !projectId?.[0] ? schema.required(defaultErrorText) : schema
        ),
        merchandising_type: string().when('projectId', (projectId, schema) =>
            !projectId?.[0] ? schema.required(defaultErrorText) : schema
        ),
    });
    const form = useForm({ mode: 'onChange', resolver: yupResolver(validationSchema) });
    const { control, setValue, handleSubmit, reset, formState, watch } = form;
    const currentValues = watch();
    const { settings } = currentValues || {};

    const getData = () => {
        setLoading(true);
        getProjectById(projectId)
            ?.then((result) => {
                const {
                    short_name,
                    external_code,
                    full_name,
                    contract,
                    client_id,
                    merchandising_type,
                    manager_id,
                    parent_id,
                    status,
                    max_radius_outlet_meters,
                } = result?.project || {};

                const defaultValues = {
                    short_name,
                    external_code,
                    full_name,
                    contract,
                    client_id,
                    merchandising_type,
                    manager_id,
                    parent_id,
                    status,
                    max_radius_outlet_meters,
                    projectId,
                };
                setProjectData(defaultValues);
            })
            .catch((error) => {
                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    const getFiltersData = useCallback(() => {
        dispatch(FiltersThunks.getClientsThunk({}));
    }, [dispatch]);

    const getSettingsProjectData = useCallback(() => {
        fetchAllSettings({ project_ids: [projectId] })
            ?.then((settingsAll) => {
                const { settings } = settingsAll || {};
                setValue('settings', settings);
            })
            .catch((error) => {
                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
            });
    }, [projectId]);

    const [managers, managersLoading, onInputChangeManagers, moreManagers] =
        useManagers(manager_id);

    useEffect(() => {
        getProjects();
        getFiltersData();

        if (!projectId) {
            const defaultValues = {
                short_name: '',
                external_code: '',
                full_name: '',
                contract: '',
                client_id: '',
                merchandising_type: '',
                manager_id: '',
                parent_id: '',
                status: 'active',
                max_radius_outlet_meters: 100,
            };
            reset(defaultValues);
        }
    }, []);

    useEffect(() => {
        if (projectId) {
            getData();
            getSettingsProjectData();
        }
    }, [projectId]);

    useEffect(() => {
        if (projectId && Object.keys(projectData)?.length > 0) {
            reset({ ...projectData, settings });
        }
    }, [projectId, projectData]);

    const onError = () => {
        if (!formState.isValid) {
            enqueueSnackbar(t('messages.invalidForm'), {
                variant: 'error',
            });
        }
    };

    const onSubmit = ({ settings, ...data }) => {
        const { isDirty, dirtyFields } = formState;
        if (isDirty || Object.keys(dirtyFields).length) {
            setLoading(true);

            const project = projectId ? updateProject(projectId, data) : createProject(data);
            const projectMessage = projectId
                ? t('catalog.projects.projectUpdated')
                : t('catalog.projects.projectCreated');

            project
                .then(async (result) => {
                    if (projectId) {
                        if (settings?.length > 0) {
                            const items = settings.map((e) => e.setting_values).flat();
                            await settingValuesUpdateMany({ items })
                                .then(() => getSettingsProjectData())
                                .catch((error) => {
                                    enqueueSnackbar(extractErrorMessage(error), {
                                        variant: 'error',
                                    });
                                });
                        }

                        getData();
                    } else {
                        navigate(`${Book.catalog.projects}/${result?.project?.id}`);
                    }

                    enqueueSnackbar(projectMessage, { variant: 'success' });
                })
                .catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            enqueueSnackbar(t('messages.notChanged'), { variant: 'info' });
        }
    };

    const projectOptions = getOptions(projectsList, 'short_name');
    const clientsOptions = getOptions(clients, 'full_name');
    const filteredStatusOptions = statusOptions.filter((option) => option?.value !== 'banned');
    const managersOptions = getOptions(managers, [
        'last_name',
        'first_name',
        'patronymic_name',
        'phone',
    ]);

    return (
        <div className={styles.main}>
            {loading && <Loader />}
            <Header type="inner">
                <Title>
                    <BackLink link={Book.catalog.projects} />
                    {projectId ? (
                        <div className={styles.head}>
                            <div className={styles.title}>
                                {t('common.project')} {full_name}
                            </div>
                            <div
                                className={
                                    status === 'active'
                                        ? styles.activeStatus
                                        : styles.nonActiveStatus
                                }
                            >
                                {status === 'active'
                                    ? t('statuses.active')
                                    : t('statuses.inactive')}
                            </div>
                        </div>
                    ) : (
                        t('catalog.projects.newProject')
                    )}
                </Title>
            </Header>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className={styles.formWrapper}>
                    <div className={styles.projectInfo}>
                        <div className={styles.formItems}>
                            <div className={styles.formItem}>
                                <Controller
                                    name="short_name"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            label={t('catalog.projects.shortName')}
                                            onChange={onChange}
                                            value={value || ''}
                                            variant="outlined"
                                            error={error}
                                            disabled={!isAdmin}
                                        />
                                    )}
                                />
                            </div>
                            <div className={styles.formItem}>
                                <Controller
                                    name="external_code"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            label={t('catalog.projects.externalCode')}
                                            onChange={onChange}
                                            value={value || ''}
                                            variant="outlined"
                                            error={error}
                                            disabled={!isAdmin}
                                        />
                                    )}
                                />
                            </div>
                            <div className={styles.formItem}>
                                <Controller
                                    name="full_name"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            label={t('common.name')}
                                            onChange={onChange}
                                            value={value || ''}
                                            variant="outlined"
                                            error={error}
                                            disabled={!isAdmin}
                                        />
                                    )}
                                />
                            </div>
                            <div className={styles.formItem}>
                                <Controller
                                    name="contract"
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            label={t('catalog.projects.contactInfo')}
                                            onChange={onChange}
                                            value={value || ''}
                                            variant="outlined"
                                            error={error}
                                            disabled={!isAdmin}
                                        />
                                    )}
                                />
                            </div>
                            <div className={styles.formItem}>
                                <Controller
                                    name="client_id"
                                    control={control}
                                    render={({
                                        field: { name, value, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Select
                                            id={name}
                                            name={name}
                                            inputRef={ref}
                                            label={t('catalog.projects.client')}
                                            onChange={(e) => {
                                                setValue(name, e?.value, {
                                                    shouldDirty: true,
                                                    shouldValidate: true,
                                                });
                                            }}
                                            value={clientsOptions?.find((e) => e.value === value)}
                                            options={clientsOptions}
                                            isLoading={clientsLoading}
                                            isClearable={true}
                                            error={error}
                                            disabled={!isAdmin}
                                        />
                                    )}
                                />
                            </div>
                            <div className={styles.formItem}>
                                <Controller
                                    name="merchandising_type"
                                    control={control}
                                    render={({
                                        field: { name, value, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Select
                                            id={name}
                                            name={name}
                                            inputRef={ref}
                                            label={t('catalog.team.merchType')}
                                            onChange={(e) => {
                                                setValue(name, e?.value, {
                                                    shouldDirty: true,
                                                    shouldValidate: true,
                                                });
                                            }}
                                            value={merchandisingTypeOptions?.find(
                                                (e) => e.value === value
                                            )}
                                            options={merchandisingTypeOptions}
                                            isClearable={true}
                                            error={error}
                                            disabled={!isAdmin}
                                        />
                                    )}
                                />
                            </div>
                            <div className={styles.formItem}>
                                <Controller
                                    name="manager_id"
                                    control={control}
                                    render={({
                                        field: { name, value, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Select
                                            id={name}
                                            name={name}
                                            inputRef={ref}
                                            label={t('catalog.projects.manager')}
                                            onChange={(e) => {
                                                setValue(name, e?.value, {
                                                    shouldDirty: true,
                                                    shouldValidate: true,
                                                });
                                            }}
                                            value={managersOptions?.find((e) => e.value === value)}
                                            options={managersOptions}
                                            isLoading={managersLoading}
                                            isClearable={true}
                                            onInputChange={onInputChangeManagers}
                                            onMenuScrollToBottom={moreManagers}
                                            error={error}
                                            disabled={!isAdmin}
                                        />
                                    )}
                                />
                            </div>
                            <div className={styles.formItem}>
                                <Controller
                                    name="parent_id"
                                    control={control}
                                    render={({
                                        field: { name, value, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Select
                                            id={name}
                                            name={name}
                                            inputRef={ref}
                                            label={t('catalog.projects.basicProject')}
                                            onChange={(e) => {
                                                setValue(name, e?.value, {
                                                    shouldDirty: true,
                                                    shouldValidate: true,
                                                });
                                            }}
                                            value={projectOptions?.find((e) => e.value === value)}
                                            options={projectOptions}
                                            isLoading={projectsLoading}
                                            isClearable={true}
                                            error={error}
                                            disabled={!isAdmin}
                                        />
                                    )}
                                />
                            </div>
                            <div className={styles.formItem}>
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({
                                        field: { name, value, ref },
                                        fieldState: { error },
                                    }) => (
                                        <Select
                                            id={name}
                                            name={name}
                                            className={classNames({
                                                [styles.active]: value === 'active',
                                                [styles.inactive]: value === 'inactive',
                                            })}
                                            inputRef={ref}
                                            label={t('common.status')}
                                            onChange={(e) => {
                                                setValue(name, e?.value, {
                                                    shouldDirty: true,
                                                    shouldValidate: true,
                                                });
                                            }}
                                            value={filteredStatusOptions?.find(
                                                (e) => e.value === value
                                            )}
                                            options={filteredStatusOptions}
                                            error={error}
                                            disabled={!isAdmin}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.actions}>
                        <Button
                            className={styles.textBtn}
                            onClick={() => setOpenedSettings(!isOpenedSettings)}
                            color="secondary"
                            endIcon={
                                <img
                                    className={styles.actionsImg}
                                    src={!isOpenedSettings ? arrowSelectIcon : arrowOpenSelectIcon}
                                    alt=""
                                />
                            }
                        >
                            {t('catalog.projects.projectSettings')}
                        </Button>
                    </div>
                    <div
                        className={classNames(styles.formItems, styles.formSettings, {
                            [styles.isOpenedSettings]: isOpenedSettings,
                        })}
                    >
                        <div className={styles.formItem}>
                            <Controller
                                name="max_radius_outlet_meters"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <TextField
                                        label={t('catalog.projects.perDistance')}
                                        placeholder={t('catalog.projects.perDistancePlaceholder')}
                                        onChange={onChange}
                                        value={value || ''}
                                        variant="outlined"
                                        error={error}
                                    />
                                )}
                            />
                        </div>
                        {projectId &&
                            settings?.length > 0 &&
                            settings.map((e, i) => (
                                <div className={styles.formItem} key={e.id}>
                                    <Label>{e.name}</Label>
                                    <div className={styles.formValues}>
                                        {e.setting_values?.length > 0 &&
                                            e.setting_values.map((ee, ii) => (
                                                <Controller
                                                    key={ee.id}
                                                    name={`settings[${i}].setting_values[${ii}].val`}
                                                    control={control}
                                                    render={({
                                                        field: { onChange, value },
                                                        fieldState: { error },
                                                    }) => (
                                                        <TextField
                                                            onChange={onChange}
                                                            value={value || ''}
                                                            variant="outlined"
                                                            error={error}
                                                        />
                                                    )}
                                                />
                                            ))}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                <ActionsBtns setValue={setValue} />
            </form>
        </div>
    );
};
