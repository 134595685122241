import React, { useRef, useEffect, FC, useState, Dispatch, SetStateAction } from 'react';
import { Fancybox } from '@fancyapps/ui';
import type { OptionsType } from '@fancyapps/ui/types/Fancybox/options';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { SliderSlick, Title, Button, Loader } from '@components';
import { FileLink } from '@types/files';
import { getPhotos } from '@tools/utils/functions';
import { deleteFeedPhoto, updateFeed } from '@apiFeature/feeds';
import styles from './PhotoSlider.module.scss';
import DeleteIcon from '@images/svg/delete.svg';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

const toDataURL = (url: string) =>
    fetch(url)
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .catch((error) => console.log('error', error));

const downloadImage = async (url: string) => {
    const a = document.createElement('a');
    a.href = await toDataURL(url);
    a.download = 'image.png';
    a.click();
};

interface IPhotoSlider {
    id?: string;
    photos: FileLink[] | null;
    className?: string | undefined;
    delegate?: string;
    options?: Partial<OptionsType>;
    description?: {};
    utc_offset?: number;
    type?: 'carousel' | 'list';
    countPhotoVisitObj?: { id: string };
    setCountPhotoVisitObj?: (arg: any) => void;
    result?: Record<string, unknown>;
    hasDeleteBtn?: boolean;
    isSmall?: boolean;
    isViewSettings?: boolean;
    isSelectable?: boolean;
    selectedPhotoIds?: string[];
    setSelectedPhotoIds?: Dispatch<SetStateAction<string[]>>
}

const PhotoSlider: FC<IPhotoSlider> = ({
    id,
    photos,
    className,
    delegate,
    options,
    description,
    utc_offset,
    type,
    countPhotoVisitObj,
    setCountPhotoVisitObj,
    result,
    hasDeleteBtn,
    isSmall,
    isViewSettings,
    isSelectable=false,
    selectedPhotoIds,
    setSelectedPhotoIds,
}) => {
    const containerRef = useRef(null);

    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();
    const [photosForSlider, setPhotosForSlider] = useState<FileLink[]>([]);
    const [deleteId, setOpenDeleteModal] = useState('');
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (photos && photos?.length > 0) {
            const photosForSlider = getPhotos(photos);
            setPhotosForSlider(photosForSlider);
        }
    }, [photos]);

    useEffect(() => {
        const container = containerRef.current;
        const delegateProps = delegate || '[data-fancybox]';
        const optionsProps: Partial<OptionsType> = {
            Thumbs: {
                type: 'classic',
            },
            Images: {
                Panzoom: {
                    maxScale: 10,
                },
            },
            Toolbar: {
                items: {
                    download: {
                        tpl: '<a class="f-button" title="{{DOWNLOAD}}" data-fancybox-download href="javasript:;"><svg><path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2M7 11l5 5 5-5M12 4v12"/></svg></a>',
                        click: (_, e) => e?.target?.download && downloadImage(e?.target?.download),
                    },
                    delete: {
                        tpl: `<button class="f-button" title="Delete"><img src="${DeleteIcon}" /></button>`,
                        click: ({ instance }) => handleOpenModal(instance),
                    },
                },
                display: {
                    left: ['infobar'],
                    middle: [
                        'zoomIn',
                        'zoomOut',
                        'toggle1to1',
                        'fitX',
                        'fitY',
                        'rotateCCW',
                        'rotateCW',
                        'download',
                        hasDeleteBtn ? 'delete' : '',
                    ],
                    right: ['thumbs', 'fullscreen', 'close'],
                },
            },
            caption: function (_, slide) {
                const caption = slide.triggerEl?.querySelector('.caption');
                return caption?.innerHTML || slide.caption || '';
            },
            on: {
                keydown: () => {
                    hasDeleteBtn && handleCloseModal();
                },
            },
            compact: true,
            ...options,
        };

        Fancybox.bind(container, delegateProps, optionsProps);

        return () => {
            Fancybox.unbind(container);
            Fancybox.close();
        };
    }, [id]);

    const handleOpenModal = (instance) => {
        const { fancyboxId: photoId } = instance?.getSlide() || {};
        setOpenDeleteModal(photoId);
    };
    const handleCloseModal = () => setOpenDeleteModal('');

    const handleErr = (e) => {
        setLoading(false);
        const errorMessage = e?.message || e?.data?.detail || t('messages.unknownError');
        enqueueSnackbar(errorMessage, { variant: 'error' });
    };
    const handleDelete = async () => {
        handleCloseModal();

        const { id_original } = photosForSlider.find((e) => e.id === deleteId) || {};

        setLoading(true);
        try {
            await deleteFeedPhoto(deleteId);
            if (id_original) await deleteFeedPhoto(id_original);

            const countPhotoVisit =
                id && countPhotoVisitObj?.[id] ? countPhotoVisitObj?.[id] - 1 : 0;

            if (id && result) {
                await updateFeed({
                    visit_id: id,
                    req: {
                        result: {
                            ...result,
                            count_photo_visit: countPhotoVisit,
                        },
                    },
                });
            }

            enqueueSnackbar(t('messages.deletePhotoSuccess'), {
                variant: 'success',
                autoHideDuration: 500,
            });
            setLoading(false);

            setPhotosForSlider(photosForSlider.filter((e) => e.id !== deleteId));
            if (setCountPhotoVisitObj && id) {
                setCountPhotoVisitObj((prevState) => ({
                    ...prevState,
                    [id]: countPhotoVisit,
                }));
            }

            const instance = Fancybox.getInstance();
            const index = instance?.carousel?.page;
            const countSlides = instance?.carousel?.pages.length;
            instance?.carousel?.removeSlide(index || 0);
            instance?.plugins.Thumbs.detach();
            instance?.plugins.Thumbs.attach();
            if (index === 0 && countSlides === 1) Fancybox.close();
        } catch (e) {
            handleErr(e);
        }
    };

    return (
        <>
            {isLoading && <Loader />}
            <div ref={containerRef} className={classNames(styles.photoSlider, className)}>
                <SliderSlick
                    photos={photosForSlider}
                    id={id}
                    description={description}
                    utc_offset={utc_offset}
                    type={type}
                    isSmall={isSmall}
                    isViewSettings={isViewSettings}
                    countPhotoVisitObj={countPhotoVisitObj}
                    isSelectable={isSelectable}
                    selectedPhotoIds={selectedPhotoIds}
                    setSelectedPhotoIds={setSelectedPhotoIds}
                />
            </div>
            {hasDeleteBtn && (
                <div hidden={!deleteId} className={styles.deleteModal}>
                    <div className={styles.backdropModal} onClick={handleCloseModal}></div>
                    <div className={styles.contentModal}>
                        <Title type="h2">{t('messages.deletePhotoConfirm')}</Title>
                        <div className={styles.btns}>
                            <Button variant="contained" color="secondary" onClick={handleDelete}>
                                {t('buttons.delete')}
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
                                {t('buttons.cancel')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PhotoSlider;
