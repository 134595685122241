import React, { useEffect, useState } from 'react';
import styles from './PhotoReportHeader.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, Loader, ToggleButtonGroup } from '@components';
import { exportPhotosReportNew } from '@apiFeature/reports';
import { exportPhotoCrowdTaskResult } from '@apiFeature/exportPhotoFiles';
import { useSnackbar } from 'notistack';
import {
    filtersDirHierarchy,
    filtersFilename,
} from '../PhotoReportBaseFilters/PhotoReportBaseFilters';
import { fixTimezoneOffset } from '@tools/utils/date.util';
import { useURLFilters } from '@tools/hooks/useURLFilters';
import { useRoles } from '@tools/hooks/useRoles';

const PhotoReportHeader = ({ form }) => {
    const { isCrowd, isClient, isPhotoReport, isAdmin } = useRoles();
    const [isLoading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { watch, setValue } = form;
    const currValues = watch();
    const {
        project_id,
        kind,
        date_range: { start, end } = {},
        visit_ids,
        project_outlet_additional_projects,
        project_outlet_retails,
        project_outlet_branches,
        questionnaire_names,
        question_names,
        questionnaire_step_titles,
        project_outlet_ir_outlet_code,
        project_outlet_external_code,
        ...otherValues
    } = currValues;

    const { t } = useTranslation('translation');

    const { searchParams, setSearchParams } = useURLFilters();

    useEffect(() => {
        const autostart = searchParams.get('autostart');
        if (autostart && autostart === '1' && start && end) {
            searchParams.delete('autostart');
            setSearchParams(searchParams);
            setTimeout(handleGetPhotoReportFile, 5000);
        }
    }, [start, end, searchParams, questionnaire_names]);

    const handleGetPhotoReportFileRes = (res) => {
        if (res) {
            setLoading(false);
            enqueueSnackbar(t('messages.takenToProcess'), { variant: 'success' });
            setValue('export_process_id', res.export_process?.id);
        }
    };

    const handleGetPhotoReportFileErr = (e) => {
        setLoading(false);
        enqueueSnackbar(e?.message, { variant: 'error' });
    };

    const getDataPhoto = () => {
        const newPayload = { ...otherValues };
        const dirHierachyFilterNames = filtersDirHierarchy.map((filter) => filter.name);
        const filenameFilterNames = filtersFilename.map((filter) => filter.name);
        const booleanFilterNames = [...dirHierachyFilterNames, ...filenameFilterNames];
        const dir_name_paths = dirHierachyFilterNames.filter(
            (filter) => filter in otherValues && otherValues[filter]
        );
        const file_name_paths = filenameFilterNames
            .filter((filter) => filter in otherValues && otherValues[filter])
            .map((filter) => filter.slice(0, -3));

        booleanFilterNames.forEach((propertyName) => {
            if (propertyName in newPayload) {
                delete newPayload[propertyName];
            }
        });
        delete newPayload.status;

        let data = {
            ...newPayload,
            project_id,
            ...(project_outlet_additional_projects && {
                project_outlet_additional_projects: [project_outlet_additional_projects],
            }),
            ...(project_outlet_retails && { project_outlet_retails: [project_outlet_retails] }),
            ...(project_outlet_branches && { project_outlet_branches: [project_outlet_branches] }),
            ...(project_outlet_ir_outlet_code && { project_outlet_ir_outlet_code }),
            ...(project_outlet_external_code && { project_outlet_external_code }),
            ...(questionnaire_names && { questionnaire_names: [questionnaire_names] }),
            ...(question_names && { question_names: [question_names] }),
            ...(questionnaire_step_titles && {
                questionnaire_step_titles: [questionnaire_step_titles],
            }),
            ...(dir_name_paths.length && { dir_name_paths }),
            ...(file_name_paths.length && { file_name_paths }),
        };

        if (kind === 'PHOTO_CROWD_TASK_RESULTS') {
            data = {
                ...data,
                include_photo_question_result: true,
                include_photo_task_result: true,
                task_result_start_time_lower: fixTimezoneOffset(start, 'start'),
                task_result_start_time_upper: fixTimezoneOffset(end, 'end'),
                ...(visit_ids && { task_result_ids: [visit_ids] }),
            };
        } else {
            data = {
                ...data,
                include_question_result: true,
                include_visit_photo: true,
                visit_date_start_lower: fixTimezoneOffset(start, 'start'),
                visit_date_start_upper: fixTimezoneOffset(end, 'end'),
                ...(visit_ids && { visit_ids: [visit_ids] }),
            };
        }

        return data;
    };

    const handleGetPhotoReportFile = () => {
        if (!(project_id && start && end)) {
            enqueueSnackbar(t('messages.projectAndDateRequired'), { variant: 'error' });
            return;
        }

        setLoading(true);

        const data = getDataPhoto();
        const reg =
            kind === 'PHOTO_CROWD_TASK_RESULTS'
                ? exportPhotoCrowdTaskResult
                : exportPhotosReportNew;
        reg(data)
            .then((res) => handleGetPhotoReportFileRes(res))
            .catch((e) => handleGetPhotoReportFileErr(e));
    };

    const handleChange = (_, value: string) => {
        if (value !== null) {
            setValue('kind', value);
        }
    };

    let elements = [
        { value: 'PHOTO_VISITS', label: t('reports.photoReport.sfaPhoto') },
        { value: 'PHOTO_CROWD_TASK_RESULTS', label: t('reports.photoReport.crowdPhoto') },
    ];

    if (!isAdmin) {
        if (!(isPhotoReport && isClient)) {
            elements = elements.filter((e) => e.value !== 'PHOTO_VISITS');
        }
        if (!(isPhotoReport && isCrowd && isClient)) {
            elements = elements.filter((e) => e.value !== 'PHOTO_CROWD_TASK_RESULTS');
        }
    }

    return (
        <div className={styles.renderHeader}>
            {isLoading && <Loader />}
            <ToggleButtonGroup
                color="secondary"
                value={kind}
                exclusive
                onChange={handleChange}
                elements={elements}
            />
            <div className={styles.btns}>
                {kind === 'PHOTO_VISITS' && (
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleGetPhotoReportFile}
                    >
                        {t('buttons.exportReport')}
                    </Button>
                )}
                {kind === 'PHOTO_CROWD_TASK_RESULTS' && (
                    <Button color="secondary" variant="outlined" onClick={handleGetPhotoReportFile}>
                        {t('feed.uploadPhoto')}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default PhotoReportHeader;
