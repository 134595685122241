import { clientPassword } from "../../ClientPassword";
import { AvailableDatesRequest, AvailableDatesResponse, RestoredVisitRequest, RestoredVisitResponse } from "./types";

export const createRestoredVisit = (request: RestoredVisitRequest) => {
    return clientPassword.post<RestoredVisitResponse, RestoredVisitRequest>('/fake_visits/', request)
}

export const getRestoredVisitAvailableDates = (request: AvailableDatesRequest) => {
    return clientPassword.post<AvailableDatesResponse, AvailableDatesRequest>('/fake_visits/available_dates/', request)
}

export const createRestoredVisitNew = (request: RestoredVisitRequest) => {
    return clientPassword.post<RestoredVisitResponse, RestoredVisitRequest>('/restored_visits/', request)
}