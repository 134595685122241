import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { object, string, array, mixed } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetchProject } from '@apiFeature/projects';
import { formatters } from '@tools/utils/date.util';
import { useAppDispatch, useAppSelector } from '@tools/hooks/redux';
import { ProjectsThunks } from '@redux/projects/thunk';
import ProjectsSelectors from '@redux/projects/selectors';
import { fetchCreateQuestionnaires, fetchUpdateQuestionnaires } from '@apiFeature/questionnaires';
import { useTranslation } from 'react-i18next';
import setHours from 'date-fns/setHours';
import { useSnackbar } from 'notistack';
import {
    TextField,
    Title,
    Select,
    TextareaAutosize,
    Loader,
    DatePicker,
    Checkbox,
} from '@components';
import Questions from './Questions/Questions';
import ActionsBtns from './ActionsBtns/ActionsBtns';
import styles from './QuestionnaireEditCommon.module.scss';
import moment from 'moment';

const validNumber = (value) => (value ? String(value)?.replace(/[^0-9+]/g, '') == value : true);

const defaultErrorText = 'Поле обязательно';
const invalidValueText = 'Некорректное значение';
const validationSchema = object({
    name: string().required(defaultErrorText),
    project: string().required(defaultErrorText),
    external_code: string().required(defaultErrorText),
    kind: string().required(defaultErrorText),
    period_lower: string().required(defaultErrorText),
    period_upper: mixed()
        .test('period_lower', '"Начало действия" больше чем "Окончание действия"', (_, context) => {
            const { period_lower, period_upper } = context?.parent;
            const res = setHours(new Date(period_lower), 0) <= setHours(new Date(period_upper), 0);
            return res;
        })
        .required(defaultErrorText),
    duration: string()
        .test('duration', invalidValueText, (value) => validNumber(value))
        .required(defaultErrorText),
    order: string()
        .test('duration', invalidValueText, (value) => validNumber(value))
        .required(defaultErrorText),
    questionnaire_steps: array().of(
        object().shape({
            title: string().required(defaultErrorText),
            description: string().required(defaultErrorText),
            questions: array().of(
                object().shape({
                    name: string().required(defaultErrorText),
                    kind: string().required(defaultErrorText),
                    parent_criteria: string().when('parent_id', {
                        is: (parent_id) => parent_id,
                        then: (schema) => schema.required(defaultErrorText),
                        otherwise: (schema) => schema.notRequired(),
                    }),
                    min_number: string()
                        .nullable()
                        .test('min_number', invalidValueText, (value) => validNumber(value)),
                    max_number: string()
                        .nullable()
                        .test('max_number', invalidValueText, (value) => validNumber(value)),
                    min_length: string()
                        .nullable()
                        .test('min_length', invalidValueText, (value) => validNumber(value)),
                    photo_quantity: string()
                        .nullable()
                        .test('photo_quantity', invalidValueText, (value) => validNumber(value)),
                })
            ),
        })
    ),
});

export const QuestionnaireEditCommon: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const { pollId } = params || {};
    const navigate = useNavigate();
    const form = useForm({ mode: 'onChange', resolver: yupResolver(validationSchema) });
    const { control, reset, setValue, handleSubmit, watch, trigger } = form;
    const currentValues = watch();

    const dispatch = useAppDispatch();
    const { projectsList, loading: loadingProjects } = useAppSelector(
        ProjectsSelectors.projectsSelectors
    );

    const { t } = useTranslation('translation');

    const [loading, setLoading] = useState<boolean>(false);

    const { detailed } = useOutletContext();
    const {
        id,
        name,
        external_code,
        is_active,
        project_id,
        kind,
        period_lower,
        period_upper,
        order,
        is_required,
        description,
        questionnaire_steps,
    } = detailed ?? {};

    const getProjectName = async (project_id) => {
        const data = await fetchProject(project_id);
        const { project } = data || {};
        return project?.short_name;
    };

    useEffect(() => {
        (async () => {
            if (Object.keys(detailed).length > 0) {
                const project_name = await getProjectName(project_id || '');
                const defaultValues = {
                    name,
                    project: project_id,
                    project_name,
                    external_code,
                    is_active,
                    kind,
                    period_lower: formatters.toISODateFormatString(period_lower, 'yyyy-MM-dd'),
                    period_upper: formatters.toISODateFormatString(period_upper, 'yyyy-MM-dd'),
                    order,
                    description,
                    is_required,
                    duration: '60',
                    questionnaire_steps,
                };
                reset({ ...defaultValues });
            }
        })();
    }, [detailed]);

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    useEffect(() => {
        if (!pollId) getProjects();
    }, [pollId, getProjects]);

    const optionsProject = pollId
        ? [
              {
                  label: currentValues?.project_name,
                  value: project_id,
              },
          ]
        : projectsList?.map(({ full_name, id }) => ({
              label: full_name,
              value: id,
          }));

    const optionsKind = [
        {
            label: t('questionnaires.questionnaire'),
            value: 'questionnaire',
        },
        {
            label: t('feed.audit'),
            value: 'audit',
        },
        {
            label: t('feed.auditManager'),
            value: 'audit_manager',
        },
    ];

    const optionsStatus = [
        {
            label: t('statuses.active'),
            value: true,
        },
        {
            label: t('statuses.inactive'),
            value: false,
        },
    ];

    const onSubmit = (data) => {
        const {
            name,
            project,
            external_code,
            is_active,
            kind,
            period_lower,
            period_upper,
            is_required,
            order,
            description,
            questionnaire_steps,
        } = data || {};

        const data_q_steps = questionnaire_steps.map((e, i) => ({
            ...e,
            id: e.id || null,
            prev_id: questionnaire_steps[i - 1]?.id || null,
            next_id: questionnaire_steps[i + 1]?.id || null,
            questions:
                e.questions?.map((ee, ii) => ({
                    ...ee,
                    id: ee.id || null,
                    questionnaire_step_id: e.id,
                    prev_id: e.questions[ii - 1]?.id || null,
                    next_id: e.questions[ii + 1]?.id || null,
                    is_photo_required: ee.is_photo_required || false,
                    photo_quantity: ee.photo_quantity || 0,
                    min_length: ee.min_length || null,
                    min_number: ee.min_number || null,
                    max_number: ee.max_number || null,
                    is_required: ee.is_required || false,
                    question_answers:
                        ee.question_answers?.map((it, iti) => ({
                            ...it,
                            id: it?.id || null,
                            question_id: ee.id,
                            is_default: it?.is_default || false,
                            prev_id: ee.question_answers[iti - 1]?.id || null,
                            next_id: ee.question_answers[iti + 1]?.id || null,
                        })) || [],
                })) || [],
        }));

        const startDate = moment(period_lower).utc().startOf('day').toISOString();
        const endDate = moment(period_upper).utc().endOf('day').toISOString();

        const request = {
            id,
            name,
            project_id: project,
            external_code,
            is_active: is_active || false,
            kind,
            period_lower: startDate,
            period_upper: endDate,
            is_required: is_required || false,
            order,
            description,
            questionnaire_steps: data_q_steps,
        };

        setLoading(true);
        const fetch = pollId ? fetchUpdateQuestionnaires : fetchCreateQuestionnaires;
        fetch({ request })
            .then((res) => {
                const { id, project_id, version } = res || {};
                if (id) {
                    const title = pollId
                        ? t('questionnaires.updatedSuccess')
                        : t('questionnaires.createdSuccess');
                    enqueueSnackbar(`${title} (v${version})`, {
                        variant: 'success',
                    });
                    navigate(`/questionnaires/edit/${id}/${project_id}`);
                }
            })
            .catch((e) => enqueueSnackbar(e?.message, { variant: 'error' }))
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className={styles.main}>
            {loading && <Loader />}
            <Title className={styles.title}>{t('questionnaires.details')}</Title>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.formItems}>
                    <div className={styles.formItem}>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    label={t('common.name')}
                                    onChange={onChange}
                                    value={value || ''}
                                    id={name}
                                    variant="outlined"
                                    error={error}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.formItem}>
                        <Controller
                            name="project"
                            control={control}
                            render={({ field: { name, value, ref }, fieldState: { error } }) => (
                                <Select
                                    id={name}
                                    name={name}
                                    inputRef={ref}
                                    label={t('common.project')}
                                    onChange={(e) => {
                                        setValue(name, e.value);
                                    }}
                                    disabled={pollId ? true : false}
                                    value={optionsProject?.find((e) => e.value === value)}
                                    options={optionsProject}
                                    error={error}
                                    isLoading={!pollId ? loadingProjects : false}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.formItem}>
                        <Controller
                            name="external_code"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    label={t('questionnaires.externalCode')}
                                    onChange={onChange}
                                    value={value || ''}
                                    id={name}
                                    variant="outlined"
                                    disabled={pollId ? true : false}
                                    error={error}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className={styles.formItems}>
                    <div className={styles.formItem}>
                        <Controller
                            name='is_active'
                            control={control}
                            render={({ field: { name, value, ref }, fieldState: { error } }) => (
                                <Select
                                    id={name}
                                    name={name}
                                    inputRef={ref}
                                    label={t('common.status')}
                                    onChange={(e) => {
                                        setValue(name, e.value);
                                    }}
                                    value={optionsStatus?.find((e) => e.value === value)}
                                    options={optionsStatus}
                                    error={error}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.formItem}>
                        <Controller
                            name='kind'
                            control={control}
                            render={({ field: { name, value, ref }, fieldState: { error } }) => (
                                <Select
                                    id={name}
                                    name={name}
                                    inputRef={ref}
                                    label={t('feed.type')}
                                    onChange={(e) => {
                                        setValue(name, e.value);
                                    }}
                                    value={optionsKind?.find((e) => e.value === value)}
                                    options={optionsKind}
                                    error={error}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.formItem}>
                        <Controller
                            name='period_lower'
                            control={control}
                            render={({
                                         field: { name, value, ref, onChange },
                                         fieldState: { error },
                                     }) => (
                                <DatePicker
                                    id={name}
                                    name={name}
                                    inputRef={ref}
                                    onChange={(e) => {
                                        onChange(e);
                                        trigger('period_upper');
                                    }}
                                    label={t('questionnaires.periodLower')}
                                    value={value}
                                    enable={[]}
                                    dateFormat='dd.MM.yyyy'
                                    error={error}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.formItem}>
                        <Controller
                            name='period_upper'
                            control={control}
                            render={({
                                         field: { name, value, ref, onChange },
                                         fieldState: { error },
                                     }) => (
                                <DatePicker
                                    id={name}
                                    name={name}
                                    inputRef={ref}
                                    onChange={onChange}
                                    label={t('questionnaires.periodUpper')}
                                    value={value}
                                    enable={[]}
                                    dateFormat='dd.MM.yyyy'
                                    error={error}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.formItem}>
                        <Controller
                            name='duration'
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    label={t('questionnaires.duration')}
                                    onChange={onChange}
                                    value={value || ''}
                                    id={name}
                                    variant='outlined'
                                    error={error}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.formItem}>
                        <Controller
                            name='order'
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                    label={t('questionnaires.order')}
                                    onChange={onChange}
                                    value={value || ''}
                                    id={name}
                                    variant='outlined'
                                    error={error}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className={styles.formSection}>
                    <Controller
                        name='is_required'
                        control={control}
                        render={({ field: { onChange, value = false }, fieldState: { error } }) => (
                            <Checkbox
                                value={value}
                                onChange={onChange}
                                label={t('questionnaires.required')}
                                labelPlacement='end'
                                id={name}
                                className={styles.isRequired}
                                error={error}
                            />
                        )}
                    />
                </div>
                <div className={styles.formSection}>
                    <Controller
                        name="description"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextareaAutosize
                                id={name}
                                minRows={4}
                                onChange={onChange}
                                value={value || ''}
                                label={t('common.description')}
                                error={error}
                            />
                        )}
                    />
                </div>
                <ActionsBtns />
            </form>
            <FormProvider {...form}>
                <Questions />
            </FormProvider>
        </div>
    );
};
