import React from 'react';
import styles from './UsersHeader.module.scss';
import { Button } from '@components';
import { useTranslation } from 'react-i18next';

const UsersHeader = () => {
    const { t } = useTranslation('translation');

    return (
        <div className={styles.renderHeader}>
            <Button
                variant="outlined"
                disabled={true}
            >
                {t('buttons.upload')}
            </Button>
        </div >

    );
};

export default UsersHeader;