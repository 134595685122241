import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '@components';
import UsersHeader from './UsersHeader/UsersHeader';
import UsersData from './UsersData/UsersData';
import UsersFilters from './UsersFilters/UsersFilters';

export const Users = () => {
    const { t } = useTranslation('translation');

    //waiting for backend
    // const project_id = sessionStorage.getItem('project_id');
    // const defaultValues = {
    //     project: project_id !== 'undefined' ? project_id : '',
    // };
    const defaultValues = {};

    const getFilters = (values) => {
        let res = {};
        // if (values.project) res = { ...res, project_id: values?.project };
        if (values.name) res = { ...res, name: values?.name };
        if (values.phone) res = { ...res, phones: [values.phone] };
        if (values.id) res = { ...res, ids: [values.id] };
        if (values.roles) res = { ...res, roles: values.roles };
        if (values.status) res = { ...res, status: values.status };
        return res;
    };

    return (
        <Layout
            title={t('catalog.users.title')}
            renderHeader={(props) => <UsersHeader {...props} />}
            renderData={(props) => <UsersData {...props} />}
            renderFilters={UsersFilters}
            defaultValues={defaultValues}
            getFilters={getFilters}
        />
    );
};