import React, { FC, useState } from 'react';
import { VisitStatus } from '@/types/visits';
import { useTranslation } from 'react-i18next';
import { Button, Menu, Dialog, Loader } from '@components';
import { useSelector } from 'react-redux';
import FiltersSelectors from '@redux/filters/selectors';
import { getOptions } from '@tools/utils/functions';
import { useSnackbar } from 'notistack';
import { getItemsNames } from '../utils/functions';
import { fixTimezoneOffset } from '@tools/utils/date.util';
import { SettingsAllUserItem } from '@apiFeature/settings/types';
import { getActionsHiddenActive } from '../../helpers/helpers';

interface MenuDialogProps {
    status?: VisitStatus;
    row: {
        project_id: string;
        project_outlet_id?: string;
        user_id?: string;
    };
    date?: string;
    setValue: (name: string, value: string) => void;
    statusType?: string;
    anchorEl: HTMLButtonElement | null;
    handleClose: () => void;
    selectedRoutes?: [];
    settings: SettingsAllUserItem[];
    isOnlyRows?: boolean;
}

const MenuDialog: FC<MenuDialogProps> = ({
    status,
    row,
    date,
    setValue,
    statusType,
    anchorEl,
    handleClose,
    selectedRoutes,
    settings,
    isOnlyRows = false,
}) => {
    const actionsHiddenActive = getActionsHiddenActive(settings) || [];

    const { user_id: userId, project_id, project_outlet_id } = row || {};

    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();

    const s = useSelector((s) => s?.auth);
    const { id: audit_user_id } = s?.user;

    const { userProjects } = useSelector(FiltersSelectors.filtersState);
    const usersOptions = getOptions(userProjects?.[project_id], [
        'last_name',
        'first_name',
        'patronymic_name',
        'phone',
    ]);

    const [isLoading, setLoading] = useState(false);
    const [user_id, setUserId] = useState(userId);
    const [to_date, setToDate] = useState(
        fixTimezoneOffset(date) || fixTimezoneOffset(new Date().setHours(0, 0, 0)).split('T')[0]
    );
    const [activeItem, setActiveItem] = useState('');
    const [isOpenDialog, setDialog] = useState(false);
    const [open, setOpen] = useState(Boolean(anchorEl));

    const handleCloseMenu = () => {
        setOpen(false);
        handleClose();
    };

    const setValueChange = (value) => {
        setActiveItem(value);
        setDialog(true);
    };

    const handleCloseDialog = () => {
        setActiveItem('');
        setDialog(false);
    };

    const onChange = (name, e) => {
        if (name === 'user_id') {
            setUserId(e?.value);
        } else if (name === 'to_date') {
            const to_date = fixTimezoneOffset(new Date(e).setHours(0, 0, 0));
            setToDate(to_date);
        }
    };

    const handleCloseAll = (id) => {
        handleCloseDialog();
        handleCloseMenu();
        setValue('update_id', id);
    };

    const itemsNames = getItemsNames({
        status,
        to_date,
        date,
        user_id,
        userId,
        usersOptions,
        project_outlet_id,
        audit_user_id,
        ...(selectedRoutes && { selectedRoutes }),
        enqueueSnackbar,
        setLoading,
        handleCloseAll,
        onChange,
    });

    let items: any = [];
    if (!selectedRoutes) {
        if (statusType === VisitStatus.InProgress) {
            items.push({
                label: t('visits.setStatusCompleted'),
                onClick: () => setValueChange('statusCompleted'),
            });
        }
        if (
            (statusType === VisitStatus.InProgress ||
                statusType === VisitStatus.Completed ||
                statusType === VisitStatus.ProblemReported) &&
            !actionsHiddenActive.includes('set_status_new')
        ) {
            items.push({
                label: t('visits.setStatusNew'),
                onClick: () => setValueChange('statusNew'),
            });
        }
        if (statusType === VisitStatus.Unperformed || statusType === VisitStatus.New) {
            if (!actionsHiddenActive.includes('change_date')) {
                items.push({
                    label: t('visits.changeDate'),
                    onClick: () => setValueChange('changeDate'),
                });
            }
            if (!actionsHiddenActive.includes('change_user')) {
                items.push({
                    label: t('visits.changeUser'),
                    onClick: () => setValueChange('changeUser'),
                });
            }
        }
        if (
            statusType === VisitStatus.New &&
            !actionsHiddenActive.includes('plan_controlled_visit')
        ) {
            items.push({
                label: t('visits.scheduleJointTour'),
                onClick: () => setValueChange('scheduleJointTour'),
            });
        }
        if (!actionsHiddenActive.includes('delete')) {
            items.push({ label: t('buttons.delete'), onClick: () => setValueChange('delete') });
        }
        if (!actionsHiddenActive.includes('questionnaires')) {
            items.push({
                label: t('questionnaires.openList'),
                onClick: () => setValueChange('questionnaireList'),
            });
        }
        if (
            (statusType === VisitStatus.InProgress || statusType === VisitStatus.Completed) &&
            status?.id &&
            !actionsHiddenActive.includes('go_to_visit')
        ) {
            items.push({
                label: t('buttons.viewVisit'),
                onClick: () => window.open(`/visit_feed/${status?.id}`),
            });
        }
        if (
            (status?.id || (project_id && project_outlet_id)) &&
            !actionsHiddenActive.includes('create_task')
        ) {
            items.push({
                label: t('tasks.assignNew'),
                onClick: () =>
                    window.open(
                        status?.id
                            ? `/feed-task/create/${status?.id}`
                            : `/tasks/create?project_id=${project_id}&project_outlet_id=${project_outlet_id}`
                    ),
            });
        }
    } else {
        if (selectedRoutes.some((e) => e.statusType === VisitStatus.InProgress) && !isOnlyRows) {
            items.push({
                label: t('visits.setStatusCompleted'),
                onClick: () => setValueChange('statusCompleted'),
            });
        }
        if (
            selectedRoutes.some(
                (e) =>
                    e.statusType === VisitStatus.InProgress ||
                    e.statusType === VisitStatus.Completed ||
                    e.statusType === VisitStatus.ProblemReported
            ) &&
            !actionsHiddenActive.includes('set_status_new') &&
            !isOnlyRows
        ) {
            items.push({
                label: t('visits.setStatusNew'),
                onClick: () => setValueChange('statusNew'),
            });
        }
        if (
            selectedRoutes.some(
                (e) => e.statusType === VisitStatus.Unperformed || e.statusType === VisitStatus.New
            )
        ) {
            if (!actionsHiddenActive.includes('change_date') && !isOnlyRows) {
                items.push({
                    label: t('visits.changeDate'),
                    onClick: () => setValueChange('changeDate'),
                });
            }
            if (!actionsHiddenActive.includes('change_user')) {
                items.push({
                    label: t('visits.changeUser'),
                    onClick: () => setValueChange('changeUser'),
                });
            }
        }
        if (!actionsHiddenActive.includes('delete')) {
            items.push({ label: t('buttons.delete'), onClick: () => setValueChange('delete') });
        }
    }

    return (
        <>
            <Menu
                id={open ? 'VisitScheduleMenu' : undefined}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                onContextMenu={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                items={items}
            />
            {isOpenDialog && (
                <Dialog
                    id="VisitScheduleDialog"
                    isOpenDialog={isOpenDialog}
                    setCloseDialog={handleCloseDialog}
                    title={itemsNames[activeItem]?.title || ''}
                    actions={
                        <>
                            {itemsNames[activeItem]?.confirmTitle && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={itemsNames[activeItem]?.action}
                                >
                                    {itemsNames[activeItem]?.confirmTitle || ''}
                                </Button>
                            )}
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleCloseDialog}
                            >
                                {t('buttons.cancel')}
                            </Button>
                        </>
                    }
                    actionsAlignCenter
                    type={2}
                >
                    {itemsNames[activeItem]?.content || null}
                </Dialog>
            )}
            {isLoading && <Loader />}
        </>
    );
};

export default MenuDialog;
