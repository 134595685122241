import { urls } from 'src/api/api.constant';
import { clientPassword } from 'src/apiFeature/ClientPassword';
import { ProjectsListRequest } from '@apiFeature/projects/types';

export interface ProjectListFilters {
    client_id?: string;
    external_code?: string;
    merchandising_type?: 'combined' | 'exclusive';
    full_name?: string;
}

export const fetchProjects = async (filters: ProjectsListRequest = {}) => {
    const data = await clientPassword.post(urls.projects.list, { ...filters });
    const { projects } = data || {};

    return projects;
};
export const updateProject = async (id: string, data: any) => {
    const project = await clientPassword.patch(urls.projects.patch(id), { ...data });

    return project;
};

export const createProject = async (data: any) =>
    await clientPassword.post(urls.projects.create, { ...data });

export const getProject = async (id: string) => {
    const data = await clientPassword.get(urls.projects.getProject(id));
    const { project } = data || {};
    return project;
};
