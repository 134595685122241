import React, { useCallback, useEffect } from 'react';
import styles from './OutletsFilters.module.scss';
import { useTranslation } from 'react-i18next';
import { Select, TextField } from '@components';
import { getOptions } from '@tools/utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import FiltersSelectors from '@redux/filters/selectors';
import { ProjectsThunks } from '@redux/projects/thunk';
import { FiltersThunks } from '@redux/filters/thunk';

const OutletsFilters = ({ defaultValues, form }) => {
    const dispatch = useDispatch();
    const { projectsList, loading: loadingProjects } = useSelector(
        ProjectsSelectors.projectsSelectors
    );
    const projectsListId = projectsList?.[0]?.id;

    const {
        retails,
        retailsLoading,
        branches,
        branchesLoading,
        territory,
        territoryLoading,
        cities,
        citiesLoading,
    } = useSelector(FiltersSelectors.filtersState);

    const { t } = useTranslation('translation');

    const { reset, watch, setValue } = form;
    const currValues = watch();
    const {
        project_id: pr_id,
        project_retail_ids,
        branch_ids,
        territory_ids,
        city_ids,
    } = currValues;
    const project_id = pr_id || projectsListId;

    const setActualValue = (curData, data, name) => {
        const value = data?.filter((e) => curData?.includes(e.id)).map((e) => e.id);
        if (curData && curData?.join() !== value?.join()) {
            setValue(name, value.length > 0 ? value : null);
        }
    };

    const getFiltersProjects = useCallback(() => {
        dispatch(FiltersThunks.getRetailsList({ project_id })).then((result) => {
            if (FiltersThunks.getRetailsList.fulfilled.match(result)) {
                const retails = result?.payload || [];
                if (retails?.length > 0)
                    setActualValue(project_retail_ids, retails, 'project_retail_ids');
            }
        });
        dispatch(FiltersThunks.getBranchesList({ project_id })).then((result) => {
            if (FiltersThunks.getBranchesList.fulfilled.match(result)) {
                const branches = result?.payload || [];
                if (branches?.length > 0) setActualValue(branch_ids, branches, 'branch_ids');
            }
        });
        dispatch(FiltersThunks.getTerritoryList({ project_id })).then((result) => {
            if (FiltersThunks.getTerritoryList.fulfilled.match(result)) {
                const territories = result?.payload || [];
                if (territories?.length > 0)
                    setActualValue(territory_ids, territories, 'territory_ids');
            }
        });
        dispatch(FiltersThunks.getCitiesList({ project_id })).then((result) => {
            if (FiltersThunks.getCitiesList.fulfilled.match(result)) {
                const cities = result?.payload || [];
                if (cities?.length > 0) setActualValue(city_ids, cities, 'city_ids');
            }
        });
    }, [dispatch, project_id]);

    const getProjects = useCallback(() => {
        if (!projectsList.length) {
            dispatch(ProjectsThunks.fetchProjectsListThunk({}));
        }
    }, [dispatch, projectsList]);

    useEffect(() => {
        getProjects();
    }, []);

    useEffect(() => {
        if (projectsList.length > 0 && !project_id) setValue('project_id', projectsListId);
    }, [projectsList]);

    useEffect(() => {
        if (project_id) getFiltersProjects();
    }, [project_id, getFiltersProjects]);

    const onChange = (name, e) => {
        let value = e?.value;
        if (e && Array.isArray(e)) {
            value = e.length > 0 ? e.map((item) => item.value) : null;
        }
        setValue(name, value);
    };

    const handleReset = () => {
        reset({
            ...defaultValues,
            project_id: projectsListId,
        });
    };

    const projectOptions = getOptions(projectsList, 'full_name');
    const retailsOptions = getOptions(retails);
    const branchesOptions = getOptions(branches);
    const territoryOptions = getOptions(territory);
    const citiesOptions = getOptions(cities);

    const filters = [
        {
            type: Select,
            name: 'project_id',
            label: t('common.project'),
            onChange,
            value: project_id
                ? projectOptions?.find((e) => e.value === project_id)
                : projectOptions?.[0],
            options: projectOptions,
            isLoading: loadingProjects,
        },
        {
            type: Select,
            name: 'project_retail_ids',
            label: t('common.retail'),
            placeholder: t('common.all'),
            onChange,
            value: project_retail_ids
                ? retailsOptions?.filter((e) => project_retail_ids.includes(e.value))
                : null,
            options: retailsOptions,
            isLoading: retailsLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'branch_ids',
            label: t('feed.branch'),
            placeholder: t('common.all'),
            onChange,
            value: branch_ids ? branchesOptions?.filter((e) => branch_ids.includes(e.value)) : null,
            options: branchesOptions,
            isLoading: branchesLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'territory_ids',
            label: t('common.territory'),
            placeholder: t('common.all'),
            onChange,
            value: territory_ids
                ? territoryOptions?.filter((e) => territory_ids.includes(e.value))
                : null,
            options: territoryOptions,
            isLoading: territoryLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'city_ids',
            label: t('common.city'),
            placeholder: t('common.all'),
            onChange,
            value: city_ids ? citiesOptions?.filter((e) => city_ids.includes(e.value)) : null,
            options: citiesOptions,
            isLoading: citiesLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: TextField,
            name: 'external_code',
            label: t('feed.id'),
            placeholder: t('feed.searchId'),
        },
    ];

    return { handleReset, filters };
};

export default OutletsFilters;
