import React, { FC } from 'react';
import { Switch as SwitchToggle } from '@mui/material';

const Switch: FC = ({
    ...props
}) => {
    return (
        <SwitchToggle {...props} />
    );
};

export default Switch;