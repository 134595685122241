import { PeriodItem } from '@apiFeature/addressProgram/type';
import { SupportRequestType } from '@apiFeature/supportRequests/types';
import { RolesType } from '@apiFeature/roles/types';

export const reduxStoreName = 'filters';

export interface IData {
    id: string;
    created_at: string;
    updated_at: string;
    utc_offset: number;
    name: string;
    project_id: string;
}

export interface IDataTag extends IData {
    entity_id: string;
    entity_type: string;
    tag_name: string;
}

export interface FiltersState {
    filters?: {};
    citiesLoading?: boolean;
    citiesErrMessage?: string;
    cities?: IData[];
    retailsLoading?: boolean;
    retailsErrMessage?: string;
    retails?: IData[];
    branchesLoading?: boolean;
    branchesErrMessage?: string;
    branches?: IData[];
    territoryLoading?: boolean;
    territoryErrMessage?: string;
    territory?: IData[];
    usersLoading?: boolean;
    usersErrMessage?: string;
    users?: IData[];
    managers?: IData[];
    managersLoading?: boolean;
    managersErrMessage?: string;
    visitsFailResultsLoading?: boolean;
    visitsFailResultsErrMessage?: string;
    visitsFailResults?: IData[];
    outletsLoading?: boolean;
    outletsErrMessage?: string;
    projectOutlets?: IData[];
    periodList?: PeriodItem[];
    periodsLoading?: boolean;
    periodsErrMessage?: string;
    outletsCrowdLoading: boolean;
    outletsCrowdErrMessage: string;
    projectOutletsCrowd: IData[];
    supportRequestTypes?: SupportRequestType[];
    srTypesLoading?: boolean;
    srTypesErrMessage?: string;
    roles?: RolesType[];
    rolesErrMessage?: string;
    rolesLoading?: boolean;
    clients?: RolesType[];
    clientsErrMessage?: string;
    clientsLoading?: boolean;
    userProjects?: {
        [key: string]: IData[];
    };
    userProjectErr?: string;
    userProjectsLoading?: boolean;
    managerProjects?: {
        [key: string]: IData[];
    };
    managerProjectErr?: string;
    managerProjectsLoading?: boolean;
    tagsProjects?: {
        [key: string]: {
            [key: string]: IDataTag[];
        };
    };
    tagsProjectErr?: string;
    tagsProjectsLoading?: boolean;
}
