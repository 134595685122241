import React from 'react';
import styles from './TasksHeader.module.scss';
import { Button } from '@components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Book } from '@navigation/Book';
import { useRoles } from '@tools/hooks/useRoles';

const TasksHeader = ({ isEnableTaskWebClient, isTaskCreateBlocked }) => {
    const { t } = useTranslation('translation');
    const navigation = useNavigate();
    const { isAdmin } = useRoles();

    return (
        <div className={styles.renderHeader}>
            {!isTaskCreateBlocked && (
                <Button
                    variant="outlined"
                    disabled={!isEnableTaskWebClient && !isAdmin}
                    onClick={() => {
                        navigation(Book.tasks_create);
                    }}
                >
                    {t('tasks.createNew')}
                </Button>
            )}
            {/*
            <Button
                variant="outlined"
                onClick={() => {
                    navigation(Book.tasks_export);
                }}
            >
                {t('tasks.exportTask')}
            </Button>
            */}
        </div>
    );
};

export default TasksHeader;
