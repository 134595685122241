import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { VisitFeedItem } from '@/types/visitFeed';
import { useRoles } from '@tools/hooks/useRoles';
import { permissionCheck } from '@tools/utils/permissionCheck';
import { convertToOriginalDate } from '@tools/utils/utcOffsetConvert';
import { getOptions } from '@tools/utils/functions';
import { fixTimezoneOffset } from '@tools/utils/date.util';
import { Controller, useForm } from 'react-hook-form';
import { object, string, mixed } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Select, TextField, DatePicker } from '@components';
import ListMapper from '@components/Lists/List/ListMapper/ListMapper';
import { useAppSelector } from '@tools/hooks/redux';
import { format, formatDistanceStrict } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import { FiltersThunks } from '@redux/filters/thunk';
import FiltersSelectors from '@redux/filters/selectors';
import { Book } from '@navigation/Book';
import ButtonDelete from './ButtonDelete/ButtonDelete';
import Map from './Map/Map';
import styles from './Info.module.scss';
import SettingsSelectors from '@redux/settings/selectors';
import moment from 'moment';

const defaultErrorText = t('messages.fieldRequired');
const validationSchema = object({
    start_time: string().required(defaultErrorText),
    end_time: mixed()
        .test(
            'start_time',
            `"${t('feed.startTime')}" ${t('common.more')} "${t('feed.endTime')}"`,
            (_, context) => {
                const { start_time, end_time } = context?.parent;
                const res = new Date(start_time) <= new Date(end_time);
                return res;
            }
        )
        .required(defaultErrorText),
});

interface IInfo {
    data: VisitFeedItem;
    isChecker?: boolean;
    handleSaveInfo: (VisitFeedItem) => void;
}

const Info: FC<IInfo> = ({ data, isChecker = false, handleSaveInfo }) => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { visitsFailResults, visitsFailResultsLoading, projectOutlets, outletsLoading } =
        useSelector(FiltersSelectors.filtersState);
    const { items: projectOutletsItems } = projectOutlets || {};

    const visitsFailResultsOptions = getOptions(visitsFailResults);
    const projectOutletsOptions = getOptions(projectOutletsItems, [
        'name',
        'address',
        'external_code',
    ]);
    const statuses = [
        { value: 'completed', label: t('statuses.completed') },
        { value: 'in_progress', label: t('statuses.inProgress') },
        { value: 'problem_reported', label: t('statuses.problemReported') },
    ];

    const authRoles = useAppSelector((state) => state?.auth?.jwtPayloadOpen?.roles);
    const { isClient, clientPermission, isAvailableDelete } = useRoles();

    const { settingsAllUser: userSettings } = useAppSelector(SettingsSelectors.settingsState);
    const isHideFeedsVisitDuration = userSettings?.find(
        (s) => s.code === 'feeds_visit_duration'
    )?.setting_value === 'FALSE';
    const isHideFeedsVisitEndTime = userSettings?.find(
        (s) => s.code === 'feeds_visit_end_time'
    )?.setting_value === 'FALSE';
    const isHideTimeZone = userSettings?.find(
        (s) => s.code === 'feeds_visit_timezone'
    )?.setting_value === 'FALSE';
    const isHideTimeZoneCheckers = userSettings?.find(
        (s) => s.code === 'visit_check_visit_timezone'
    )?.setting_value === 'FALSE';
    const isHideVisitNumber = userSettings?.find(
        (s) => s.code === 'feeds_visit_number'
    )?.setting_value === 'FALSE';
    const isHideVisitNumberCheckers = userSettings?.find(
        (s) => s.code === 'visit_check_visit_number'
    )?.setting_value === 'FALSE';

    const {
        id,
        user,
        number,
        project_outlets,
        start_time,
        end_time,
        utc_offset,
        visit_fail_result,
        comment,
        status,
        result,
        project_retail_name,
        is_ended_in_place,
    } = data || {};
    const { first_name, roles, phone } = user || {};
    const [project_outlet] = project_outlets || [];
    const {
        id: project_outlet_id,
        external_code,
        address,
        retails_format,
        longitude = 55.76,
        latitude = 37.64,
    } = project_outlet || {};
    const { id: visit_fail_result_id, name: visit_fail_result_name } = visit_fail_result || {};
    const { count_photo_visit_sent } = result || {};

    const startTimeOffset = convertToOriginalDate(start_time, utc_offset);
    const endTimeOffset = convertToOriginalDate(end_time, utc_offset);
    const startTime = start_time ? startTimeOffset : '-';
    const endTime = end_time ? endTimeOffset : '-';
    const signUTCOffset = utc_offset > 0 ? '+' : '-';
    const duration = end_time
        ? formatDistanceStrict(new Date(end_time), new Date(start_time), { locale: ru })
        : '';
    const visitEndWithoutTime = moment(end_time).format('D MMMM');
    const endTimeWithOffset = utc_offset && !((isHideTimeZone && !isChecker) || (isHideTimeZoneCheckers && isChecker)) ? (
        endTime ? (
            `${endTime} (GMT ${signUTCOffset}${utc_offset})`
        ) : null
    ) : (
        endTime
    );
    const resultEndTime = isHideFeedsVisitEndTime ? visitEndWithoutTime : endTimeWithOffset;

    const form = useForm({
        mode: 'onChange',
        defaultValues: {
            isEdit: false,
            project_outlet_id,
            visit_fail_result_id,
            status,
            comment,
            start_time: fixTimezoneOffset(start_time),
            end_time: fixTimezoneOffset(end_time),
        },
        resolver: yupResolver(validationSchema),
    });
    const { control, reset, setValue, handleSubmit, watch, trigger } = form;
    const currentValues = watch();
    const { isEdit } = currentValues || {};

    const fields = {
        [t('feed.visitNumber')]: { value: number ?? '-' },
        /* ждём правок бекенда по project_outlet_id
        [t('feed.id')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="project_outlet_id"
                        control={control}
                        render={({ field: { name, value, ref } }) => (
                            <Select
                                id={name}
                                name={name}
                                inputRef={ref}
                                onChange={(e) => {
                                    setValue(name, e?.value || null);
                                }}
                                value={projectOutletsOptions?.find((e) => e.value === value)}
                                options={projectOutletsOptions}
                                isLoading={outletsLoading}
                            />
                        )}
                    />
                </div>
            ) : (
                external_code ?? '-'
            ),
        },
        */
        [t('feed.id')]: { value: external_code ?? '-' },
        [t('feed.address')]: { value: address ?? '-' },
        [t('feed.signBoard')]: { value: project_retail_name ?? '-' },
        [t('feed.category')]: { value: retails_format ?? '-' },
        [t('feed.timeSpend')]: { value: duration ?? '-' },
        [t('feed.startTime')]: {
            value: isEdit ? (
                <div className={styles.formItem}>
                    <Controller
                        name="start_time"
                        control={control}
                        render={({
                            field: { name, value, ref, onChange },
                            fieldState: { error },
                        }) => (
                            <DatePicker
                                id={name}
                                name={name}
                                inputRef={ref}
                                onChange={(e) => {
                                    onChange(e);
                                    trigger('end_time');
                                }}
                                value={value}
                                enable={[]}
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                timeIntervals={1}
                                timeCaption="Time"
                                showTimeSelect
                                timeFormat="HH:mm:ss"
                                error={error}
                            />
                        )}
                    />
                </div>
            ) : utc_offset && !((isHideTimeZone && !isChecker) || (isHideTimeZoneCheckers && isChecker)) ? (
                `${startTime} (GMT ${signUTCOffset}${utc_offset})`
            ) : (
                startTime
            ),
        },
        [t('feed.endTime')]: {
            value: isEdit ? (
                <div className={styles.formItem}>
                    <Controller
                        name="end_time"
                        control={control}
                        render={({
                            field: { name, value, ref, onChange },
                            fieldState: { error },
                        }) => (
                            <DatePicker
                                id={name}
                                name={name}
                                inputRef={ref}
                                onChange={onChange}
                                value={value}
                                enable={[]}
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                timeIntervals={1}
                                timeCaption="Time"
                                showTimeSelect
                                timeFormat="HH:mm:ss"
                                error={error}
                            />
                        )}
                    />
                </div>
            ) : resultEndTime,
        },
        [t('feed.visitDate')]: {
            value: start_time ? format(new Date(start_time), 'd MMMM', { locale: ru }) : '-',
        },
        [t('feed.problem')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="visit_fail_result_id"
                        control={control}
                        render={({ field: { name, value, ref } }) => (
                            <Select
                                id={name}
                                name={name}
                                inputRef={ref}
                                onChange={(e) => {
                                    setValue(name, e?.value || null);
                                }}
                                value={visitsFailResultsOptions?.find((e) => e.value === value)}
                                options={visitsFailResultsOptions}
                                isLoading={visitsFailResultsLoading}
                                isClearable={true}
                            />
                        )}
                    />
                </div>
            ) : (
                visit_fail_result_name ?? '-'
            ),
        },
        [t('feed.comment')]: {
            value: isEdit ? (
                <div className={styles.formItemFull}>
                    <Controller
                        name="comment"
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                            />
                        )}
                    />
                </div>
            ) : (
                comment ?? '-'
            ),
        },
        [t('common.status')]: {
            value: isEdit ? (
                <div className={styles.formItem}>
                    <Controller
                        name="status"
                        control={control}
                        render={({ field: { name, value, ref } }) => (
                            <Select
                                id={name}
                                name={name}
                                inputRef={ref}
                                onChange={(e) => {
                                    setValue(name, e?.value || null);
                                }}
                                value={statuses?.find((e) => e.value === value)}
                                options={statuses}
                            />
                        )}
                    />
                </div>
            ) : (
                statuses.find((e) => e.value === status)?.label ?? '-'
            ),
        },
        [t('feed.countPhotoVisit')]: { value: count_photo_visit_sent ?? '0' },
    };

    if (is_ended_in_place !== null) {
        fields[t('visits.isEndedInPlace')] = is_ended_in_place
            ? { value: t('visits.endedIn') }
            : { value: t('visits.endedOut') };
    };

    if (isHideFeedsVisitDuration) {
        delete fields[t('feed.timeSpend')]
    };
    if ((!isChecker && isHideVisitNumber) || (isChecker && isHideVisitNumberCheckers)) {
        delete fields[t('feed.visitNumber')]
    }

    const handleCopy = () => {
        navigate(Book.restoredVisit.copy.replace(':visitId', id));
    };

    const handleEdit = () => {
        if (isEdit) {
            reset();
        } else {
            setValue('isEdit', !isEdit);

            if (visitsFailResults?.length === 0) {
                dispatch(FiltersThunks.getVisitsFailResultsOptions({}));
            }

            /*
            if (!projectOutletsItems || projectOutletsItems?.length === 0) {
                const ids = project_outlets?.map((e) => e.id) || [];
                dispatch(FiltersThunks.fetchProjectOutletsList({ ids }));
            }
            */
        }
    };

    const onSubmit = ({ isEdit, ...data }) => {
        setValue('isEdit', false);
        handleSaveInfo(data);
    };

    return (
        <div className={classNames(styles.info, { [styles.isChecker]: isChecker })}>
            <div className={styles.data}>
                <div className={styles.header}>
                    <div className={styles.first_name}>{!isClient && first_name}</div>
                    <div className={styles.name_phone}>
                        {!clientPermission && `${roles?.[0]?.name}, ${phone}`}
                    </div>
                </div>
                <div className={styles.fields}>
                    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                        <ListMapper
                            items={permissionCheck(authRoles, fields)}
                            className={styles.serializedFeedItem}
                        />
                        {!isChecker && isAvailableDelete && (
                            <div className={styles.btns}>
                                {isEdit ? (
                                    <>
                                        <Button variant="contained" color="secondary" type="submit">
                                            {t('buttons.save')}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={handleEdit}
                                        >
                                            {t('buttons.cancel')}
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={handleCopy}
                                        >
                                            {t('buttons.copy')}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={handleEdit}
                                        >
                                            {t('buttons.edit')}
                                        </Button>
                                    </>
                                )}
                                <ButtonDelete id={id} />
                            </div>
                        )}
                    </form>
                </div>
            </div>
            {!clientPermission && !isChecker && (
                <div className={styles.map}>
                    <Map visit_id={id} latitude={latitude} longitude={longitude} />
                </div>
            )}
        </div>
    );
};

export default Info;
