import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@components';
import styles from './ActionBtns.module.scss';

const ActionBtns: FC = ({handleCancel, setIsOpenDialog}) => {
    const { t } = useTranslation('translation');

    return (
        <div className={styles.btns}>
            <Button variant="contained" color="secondary" onClick={() => {setIsOpenDialog(true)}}>
                {t('buttons.save').toLocaleUpperCase()}
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleCancel}>
                {t('buttons.cancel').toLocaleUpperCase()}
            </Button>
        </div>
    );
};

export default ActionBtns;