import moment from 'moment';
import { formatters } from '@tools/utils/date.util';

const now = moment().format('YYYY-MM-DD');

const project_id = sessionStorage.getItem('project_id');
export const defaultValuesData = {
    project_id: project_id !== 'undefined' ? project_id : '',
    date_range: {
        start: now,
        end: now,
    },
    user_ids: null,
    project_retail_id: null,
    branch_id: null,
    territory_id: null,
    city_id: null,
    external_code: '',
    number: '',
    manager_ids: null,
    visit_type: 'visit',
    statuses: ['completed'],
};

export const getVisitFilters = (values, clientPermission) => {
    const { date_range, project_id, statuses, visit_type } = values || {};

    const date_from = date_range?.start || now;
    const date_to = date_range?.end || now;

    let res = {
        ...values,
        date_from: formatters.toISODateFormatString(date_from, 'yyyy-MM-dd'),
        date_to: formatters.toISODateFormatString(date_to, 'yyyy-MM-dd'),
    };

    if (project_id) res = { ...res, project_ids: [project_id] };
    if (statuses) {
        res = { ...res, statuses };
    } else if (!statuses && clientPermission) {
        res = { ...res, statuses: ['completed'] };
    }
    if (!visit_type) res = { ...res, visit_type: 'visit' };

    return res;
};

export const getDefaultValues = (filters, clientPermission) => {
    const project_id = sessionStorage.getItem('project_id');

    let defaultValues = {};
    if (Object.keys(filters)?.length > 0) {
        defaultValues = { ...filters };
        if (project_id) defaultValues = { ...defaultValues, project_id, project_ids: [project_id] };
    } else {
        defaultValues = getVisitFilters(defaultValuesData, clientPermission);
    }

    return defaultValues;
};

export const getFiltersHiddenActive = (settings = []) => {
    const filtersHidden = [
        'is_webfeedfilter_hidden_project_id',
        'is_webfeedfilter_hidden_date_range',
        'is_webfeedfilter_hidden_project_retail_ids',
        'is_webfeedfilter_hidden_branch_ids',
        'is_webfeedfilter_hidden_territory_ids',
        'is_webfeedfilter_hidden_city_ids',
        'is_webfeedfilter_hidden_external_code',
        'is_webfeedfilter_hidden_number',
        'is_webfeedfilter_hidden_visit_type',
        'is_webfeedfilter_hidden_statuses',
        'is_webfeedfilter_hidden_address',
    ];

    return settings
        ?.filter((s) => filtersHidden.includes(s.code) && s?.setting_values?.[0]?.val === 'true')
        .map((s) => s.code?.replace('is_webfeedfilter_hidden_', ''));
};
