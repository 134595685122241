import React, { FC, ReactNode } from 'react';

import closeIcon from 'src/assets/images/svg/exit-logo-for-popup-menu.svg';

import styles from './Modal.module.scss';

interface ModalProps {
    onClose: () => void;
    children: ReactNode;
    className?: string;
}

export const Modal: FC<ModalProps> = ({ children, onClose, className = '' }) => {
    return (
        <>
            <div className={`${styles.modal} ${className}`}>
                <div className={styles.modal__close} onClick={onClose}>
                    <img alt='close/icon' src={closeIcon} />
                </div>
                {children}
            </div>
            <div className={styles.popup} onClick={onClose} />
        </>
    );
};
