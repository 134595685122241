import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Loader } from '@components';
import styles from './ActionsBtns.module.scss';

interface IActionBtns {
    isSubmitting: boolean;
    isLoading: boolean;
}

const ActionsBtns: FC<IActionBtns> = ({ isSubmitting, isLoading }) => {
    const { t } = useTranslation('translation');
    const params = useParams();
    const { visitId } = params || {};


    const navigate = useNavigate();
    const handleCancel = () => {
        if (visitId) {
            navigate(`/visit_feed/${visitId}`)
        } else {
            navigate('/visit-feed');
        }
    };

    return (
        <div className={styles.btns}>
            <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isSubmitting}
            >
                {t('buttons.create')}
            </Button>
            <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
                disabled={isSubmitting}
            >
                {t('buttons.cancel')}
            </Button>
            {isLoading && <Loader/>}
        </div>
    );
};

export default ActionsBtns;