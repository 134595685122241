import React, { useEffect, useState } from 'react';
import styles from './ReportItemDetails.module.scss';
import classNames from 'classnames';
import { Header, Title, BackLink, TableGrid, Button } from '@components';
import { renderErrors, bytesToSize } from '@tools/utils/functions';
import { formatters } from '@tools/utils/date.util';
import { exportProcessById } from '@apiFeature/reports';
import { exportProcessStatusNames, exportProcessKindNamesForTable, ExportProcessesItem } from '@apiFeature/reports/type';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import downloadFileIcon from '@images/svg/download-file.svg';
import { useSnackbar } from 'notistack';
import { filterParamsList, yesNoOptions } from './filterParams';
import { getProjectById } from '@apiFeature/projects';
import { FilterValue } from '@apiFeature/types';
import { utcOffsetInHours, convertLocalToUtc } from '@tools/utils/utcOffsetConvert';
import moment from 'moment';

const LabelValueBlock = ({ label, value }) => {
    return (
        <div>
            <span className={styles.labels}>{label}</span>: {value}
        </div>
    );
};

const currentUtc = utcOffsetInHours();

const columnsForExportItem = [
    {
        field: 'id',
        headerName: t('reports.reports.exportId'),
        minWidth: 320,
        valueGetter: ({ value }) => value || '-',
        sortable: false,
    },
    {
        field: 'created_at',
        headerName: t('questionnaires.createDate'),
        minWidth: 180,
        renderCell: ({ value }) =>
            value
                ? <p>
                    {formatters.toISODateFormatString(value)}{' '}
                    {moment(convertLocalToUtc(value))
                        .local()
                        .format('HH:mm')}{' '}
                    UTC{currentUtc > 0 ? '+' : '-'}
                    {currentUtc}
                </p>
                : '-',
        sortable: false,
    },
    {
        field: 'status',
        headerName: t('common.status'),
        minWidth: 180,
        renderCell: ({ value }) =>
            value ? (
                <span
                    className={classNames(
                        styles.status,
                        styles[`status-${value.toLocaleLowerCase()}`]
                    )}
                >
                    {exportProcessStatusNames[value]}
                </span>
            ) : (
                '-'
            ),
        sortable: false,
    },
    {
        field: 'kind',
        headerName: t('feed.type'),
        minWidth: 130,
        valueGetter: ({ value }) => (value ? exportProcessKindNamesForTable[value] : '-'),
        sortable: false,
    },
    {
        field: 'error_data',
        headerName: t('messages.error'),
        minWidth: 130,
        valueGetter: ({ value }) => renderErrors(value),
        sortable: false,
    },
];

const columnsForFileLinks = [
    {
        field: 'name',
        headerName: t('common.name'),
        minWidth: 470,
        sortable: false,
    },
    {
        field: 'created_at',
        headerName: t('questionnaires.createDate'),
        minWidth: 180,
        renderCell: ({ value }) =>
            value
                ? <p>
                    {formatters.toISODateFormatString(value)}{' '}
                    {moment(convertLocalToUtc(value))
                        .local()
                        .format('HH:mm')}{' '}
                    UTC{currentUtc > 0 ? '+' : '-'}
                    {currentUtc}
                </p>
                : '-',
        sortable: false,
    },
    {
        field: 'size_bytes',
        headerName: t('common.size'),
        minWidth: 130,
        valueGetter: ({ value }) => value ? bytesToSize(value) : '-',
        sortable: false,
    },
    {
        field: 'id',
        headerName: t('common.fileId'),
        minWidth: 320,
        valueGetter: ({ value }) => value || '-',
        sortable: false,
    },
    {
        field: 'url',
        headerName: t('reports.reports.fileLinks'),
        minWidth: 160,
        sortable: false,
        renderCell: ({ value }) => (
            <div style={{ textAlign: 'center', width: '100%' }}>
                {value
                    ? <div
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Button href={value}>
                            <img src={downloadFileIcon} alt='downloadIcon' />
                        </Button>
                    </div>
                    : '-'}
            </div>
        ),
    },
];

export const ReportItemDetails = () => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [exportProcess, setExportProcess] = useState<ExportProcessesItem[]>([]);
    const [fileLinks, setFileLinks] = useState<any>([]);
    const [filterParams, setFilterParams] = useState<any>([]);
    const [projectId, setProjectId] = useState<string | null>(null);
    const { exportId } = useParams();
    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        if (exportId) {
            setLoading(true);
            exportProcessById(exportId)
                .then(({ export_process }) => {
                    if (export_process) {
                        setExportProcess([export_process]);
                        if (export_process?.file_links) {
                            setFileLinks(export_process?.file_links.map(fl => fl.file))
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    const errorMessage = error?.message || error?.data?.detail || t('messages.unknownError');
                    enqueueSnackbar(errorMessage, { variant: 'error' });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    useEffect(() => {
        if (exportProcess.length) {
            const [exportProcessItem] = exportProcess;
            const filter_params_data = exportProcessItem.filter_params_data || {};
            Object.keys(filter_params_data).forEach((key) => {
                const currentField = filterParamsList.find((field) => field.name === key);
                let filterValue;
                if (Array.isArray(filter_params_data[key])) {
                    [filterValue] = filter_params_data[key];
                } else {
                    filterValue = filter_params_data[key];
                }
                let newFilterObj: FilterValue = {
                    label: '',
                    value: ''
                };
                switch (currentField?.type) {
                    case 'date':
                        newFilterObj.value = formatters.toISODateFormatString(filterValue, 'dd.MM.yyyy');
                        break;
                    case 'yesNo':
                        newFilterObj.value = yesNoOptions[filterValue.toString()]
                        break;
                    case 'select':
                        newFilterObj.value = currentField.options[filterValue];
                        break;
                    default:
                        newFilterObj.value = filterValue;
                }

                if (key === 'project_id') {
                    setProjectId(filter_params_data[key]);
                }
                if (key !== 'utc_offset') {
                    if (
                        currentField &&
                        !filterParams?.some((param) => param.label === currentField.label)
                    ) {
                        setFilterParams((prevState) => {
                            return [
                                ...prevState,
                                {
                                    label: currentField.label,
                                    value: newFilterObj.value,
                                },
                            ];
                        });
                    }
                }
            });
        }
    }, [exportProcess]);

    useEffect(() => {
        if (projectId) {
            getProjectById(projectId).then((res) => {
                if (res?.project) {
                    const newFilters = filterParams.map((filter) => {
                        if (filter.value === projectId) {
                            return {
                                label: filter.label,
                                value: res.project.full_name,
                            };
                        } else return filter;
                    });
                    setFilterParams(newFilters);
                }
            });
        }
    }, [projectId]);


    return (
        <>
            <Header type='inner'>
                <Title>
                    <BackLink link='/reports/reports' />
                    {exportId}
                </Title>
            </Header>

            <TableGrid
                rows={exportProcess}
                columns={columnsForExportItem}
                loading={isLoading}
                disableRowSelectionOnClick
                hideFooterPagination
            />
            <div className={styles.filterParams}>

                <Title>
                    {t('reports.reports.filterParams')}
                </Title>
                {filterParams.map((filter, index) => {
                    return (
                        <LabelValueBlock
                            key={index}
                            label={filter.label}
                            value={filter.value}
                        />
                    )
                })}
            </div>
            <div className={styles.titleFileLinks}>
                <Title>
                    {t('reports.reports.fileLinks')}
                </Title>
            </div>

            <TableGrid
                rows={fileLinks}
                columns={columnsForFileLinks}
                loading={isLoading}
                getRowHeight={() => 'auto'}
                disableRowSelectionOnClick
                hideFooterPagination
            />
        </>
    )
} 