import { DatePickerProps } from '../ui/DatePicker/DatePicker';
import { CheckboxProps } from '../ui/form/Checkbox/Checkbox';
import { AsyncSelectProps } from '../ui/form/Select/AsyncSelect';
import { SelectProps } from '../ui/form/Select/Select';
import { TextInputProps } from '../ui/form/TextInput/TextInput';
import { FormFieldType } from '../ComplexForm/ComplexForm.type';
import { DateTimePicker } from '../ui/DateTimePicker/DateTimePicker';

export enum FieldTypes {
    Select = 'select',
    RequestSelect = 'request_select',
    Checkbox = 'checkbox',
    TextInput = 'TextInput',
    Date = 'date',
    DateRange = 8,
    DateTime,
}

interface BaseField {
    name: string;
    label: string;
    placeholder?: string;
    className?: string;
    columnStart?: number;
    columnEnd?: number;
    type: FormFieldType;
    wrapperClassName?: string;
}

export interface SelectField extends SelectProps {
    type: FieldTypes.Select;
}

export interface RequestSelectField<T> extends AsyncSelectProps<T> {
    type: FieldTypes.RequestSelect;
}

export interface CheckboxField extends CheckboxProps {
    type: FieldTypes.Checkbox;
}

export interface TextInputField extends TextInputProps {
    type: FieldTypes.TextInput;
}

export interface DatePickerField extends DateTimePicker {
    type: FieldTypes.Date;
}

export interface DateTimePickerField extends DatePickerProps {
    type: FieldTypes.DateTime;
}

export interface DateRangeField extends BaseField {
    type: FieldTypes.DateRange;
}

export type FieldsItem<T = unknown> =
    | SelectField
    | RequestSelectField<T>
    | CheckboxField
    | TextInputField
    | DateRangeField
    | DatePickerField
    | DateTimePickerField;
