import { clientPassword } from '../ClientPassword';

export const getProjectOutletsList = async (request) => {
    const res = await clientPassword.post('/project_outlets/list/', request);
    return res?.project_outlets;
};

export const getProjectOutletsListV2 = async (request) => {
    const res = await clientPassword.post('/v2/project_outlets/list/', request);
    return res?.project_outlets;
};

export const getProjectOutlet = async (id) => {
    return await clientPassword.get(`/project_outlets/${id}/`);
};

export const patchProjectOutlet = async (request) => {
    const res = await clientPassword.patch(`/project_outlets/${request?.id}/`, request);
    return res?.project_outlet;
};

export const fetchProjectOutletsTaskOutletStatus = async (request) => {
   return await clientPassword.post('/project_outlets/task_outlet_status/',request);
};
