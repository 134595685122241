import React, { useCallback, useEffect, useState } from 'react';
import styles from './ProjectsData.module.scss';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { TableGrid } from '@components';
import { useSnackbar } from 'notistack';
import { fetchProjectsListV2 } from '@apiFeature/projects';
import { merchandisingTypeOptions } from '@constants';
import { extractErrorMessage } from '@tools/utils/functions';
import { useSelector } from 'react-redux';
import FiltersSelectors from '@redux/filters/selectors';

const limit = 10;

const columns = [
    {
        field: 'external_code',
        headerName: t('catalog.projects.code'),
        flex: 1,
        sortable: false,
    },
    {
        field: 'full_name',
        headerName: t('common.name'),
        flex: 1,
        sortable: false,
    },
    {
        field: 'agency_full_name',
        headerName: t('catalog.projects.agency'),
        flex: 1,
        sortable: false,
    },
    {
        field: 'merchandising_type',
        headerName: t('catalog.projects.mearchType'),
        flex: 1,
        valueGetter: ({ value }) =>
            value ? merchandisingTypeOptions.find((i) => i.value === value)?.label : '',
        sortable: false,
    },
    {
        field: 'contract',
        headerName: t('catalog.projects.info'),
        flex: 2,
        sortable: false,
    },
];

const ProjectsData = ({ filters }) => {
    const { clients } = useSelector(FiltersSelectors.filtersState);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const paginationModelDefault = { page: 0, pageSize: limit };
    const [paginationModel, setPaginationModel] = useState(paginationModelDefault);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any | null>(null);
    const { items = [], total = 0, offset } = data || {};

    const getProjectsList = useCallback(
        async (offset = 0) => {
            setLoading(true);

            fetchProjectsListV2({
                ...filters,
                pagination: {
                    limit,
                    offset,
                },
            })
                .then((res) => {
                    const { items: projectsItems, total, offset } = res?.projects || {};
                    const items = projectsItems.map((e) => ({
                        ...e,
                        agency_full_name: clients?.find((client) => client.id === e.client_id)
                            ?.full_name,
                    }));

                    setData({ items, total, offset });
                })
                .catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                    setLoading(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [filters, clients]
    );

    useEffect(() => {
        if (!isLoading && clients?.length > 0) {
            getProjectsList(paginationModel?.page * limit);
        }
    }, [filters, paginationModel, clients]);

    useEffect(() => {
        if (filters && Object.keys(filters)?.length > 0) {
            setPaginationModel(paginationModel);
        }
    }, [filters]);

    return (
        <>
            <TableGrid
                rows={items}
                columns={columns}
                rowCount={total}
                loading={isLoading}
                pageSizeOptions={[limit]}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                paginationModel={offset === 0 ? paginationModelDefault : paginationModel}
                rowHeight={50}
                hasSun
                isRowSelectable={({ id }) => navigate(`/catalog/projects/${id}`)}
                className={styles.tableGrid}
            />
        </>
    );
};

export default ProjectsData;
