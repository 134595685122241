import React from "react";
import styles from './QuestionniaresHeader.module.scss';
import { Button } from '@components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const QuestionniaresHeader = () => {
    const { t } = useTranslation('translation');
    const navigation = useNavigate();

    return (
        <div
            className={styles.renderHeader}
            onClick={() => {
                navigation(`/questionnaires/create/`);
            }}
        >
            <Button variant="outlined">
                {t('questionnaires.create')}
            </Button>
        </div>
    )
};

export default QuestionniaresHeader;
