import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as AddImg } from 'src/assets/images/svg/addImg.svg';
import { ReactComponent as CrossIcon } from 'src/assets/images/svg/cross.svg';
import { FileArguments, getFile } from 'src/tools/utils/getFile';
import styles from './ImagesPicker.module.scss';
import { useTranslation } from 'react-i18next';
import { FileLink } from '@types/files';

export interface ImageLink {
    id: number;
    file: Blob;
}

interface ImagesPickerProps {
    files: ImageLink[];
    onChange: (files: ImageLink[]) => void;
    images?: string[];
    slider?: FileLink[] | null;
    setSlider?: (data: any) => void;
    setSelectedPhoto?: (data: any) => void;
    setImages?: (data: any) => void;
}

export const ImagesPicker: FC<ImagesPickerProps> = ({
    files,
    onChange,
    images,
    setSlider,
    setSelectedPhoto,
    setImages,
}) => {
    const { t } = useTranslation('translation');
    const [photos, setPhotos] = useState([]);
    const id = files.at(-1)?.id || 0;

    const arg: FileArguments = {
        callback: (e) =>
            onChange([
                ...files,
                {
                    id: id + 1,
                    file: e.target.files[0],
                },
            ]),
    };

    const deleteImage = (e, idImage: number) => {
        e.stopPropagation();
        const list = [...files].filter((el) => el.id !== idImage);
        if (images?.find((img) => img.id === idImage) && setImages) {
            const newImagesList = images.filter((img) => img.id !== idImage);
            setImages(newImagesList);
        }
        onChange(list);
    };

    useEffect(() => {
        setPhotos(images);
    }, [images]);

    const handleSelectPhoto = (e, el) => {
        e.stopPropagation();
        setSlider(photos);
        setSelectedPhoto(el);
    };

    if (images) {
        if (images.length <= 0 && files?.length === 0) {
            return <div />;
        }

        return (
            <div className={styles.wrapper}>
                <ul className={styles.images}>
                    {images.map((el, idx) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div
                            key={idx}
                            className={styles.image}
                            onClick={(e) => handleSelectPhoto(e, el)}
                        >
                            <CrossIcon onClick={(e) => deleteImage(e, el.id)} />
                            <img alt="planogram" src={el.file.url} />
                        </div>
                    ))}
                    {files.map((el: ImageLink, idx) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={idx} className={styles.image}>
                            <CrossIcon onClick={(e) => deleteImage(e, el.id)} />
                            <img alt="planogram" src={URL.createObjectURL(el.file)} />
                        </div>
                    ))}
                </ul>
                <div className={styles.btn} onClick={() => getFile(arg)}>
                    {t('catalog.planograms.uploadPhotos')} <AddImg />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.wrapper}>
            {files.length > 0 && (
                <ul className={styles.images}>
                    {files.map((el: ImageLink, idx) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={idx} className={styles.image}>
                            <CrossIcon onClick={(e) => deleteImage(e, el.id)} />
                            <img alt="planogram" src={URL.createObjectURL(el.file)} />
                        </div>
                    ))}
                </ul>
            )}
            <div className={styles.btn} onClick={() => getFile(arg)}>
                {t('catalog.planograms.uploadPhotos')} <AddImg />
            </div>
        </div>
    );
};
