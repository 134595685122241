import styles from './PhotoReportFilters.module.scss';
import { useTranslation } from 'react-i18next';
import { Select } from '@components';
import { exportProcessStatusNames } from '@apiFeature/reports/type';

const PhotoReportFilters = ({ _, form }) => {
    const { t } = useTranslation('translation');

    const { watch, setValue } = form;
    const statusValue = watch('status');

    const onChange = (name, e) => {
        setValue(name, e?.value);
    };

    const handleReset = () => {
        setValue('status', '');
    };

    const exportStatusNames = Object.keys(exportProcessStatusNames).map((key) => {
        return {
            label: exportProcessStatusNames[key],
            value: key,
        };
    });

    const filters = [
        {
            type: Select,
            name: 'status',
            label: t('common.status'),
            placeholder: t('common.all'),
            onChange,
            value: exportStatusNames?.find((e) => e.value === statusValue),
            options: exportStatusNames,
            isClearable: true,
        },
    ];

    return { handleReset, filters };
};

export default PhotoReportFilters;
