import { t } from 'i18next';
import React from 'react';
import { formatters } from '@tools/utils/date.util';
import styles from './ImportsJournalDetails.module.scss';

export const columnsForRowDetails = [
    {
        field: 'questionnaire_name',
        headerName: t('importJournal.questionnaire'),
        minWidth: 200,
        valueGetter: (params) => params.row?.parsed_data?.questionnaire?.name || '-',
        sortable: false,
    },
    {
        field: 'question_name',
        headerName: t('common.question'),
        minWidth: 200,
        valueGetter: (params) => params.row?.parsed_data?.question?.name || '-',
        sortable: false,
    },
    {
        field: 'answer',
        headerName: t('common.answers'),
        minWidth: 200,
        renderCell: (params) =>
            params.row?.parsed_data?.answers?.length ? (
                <div className={styles.cellWrap}>
                    {params.row?.parsed_data?.answers?.map((answer, index) => {
                        return <div key={`${answer.answer} ${index}`}>{answer?.answer}</div>;
                    })}
                </div>
            ) : (
                '-'
            ),
        sortable: false,
    },
    {
        field: 'step_title',
        headerName: t('importJournal.questionnaireStep'),
        minWidth: 200,
        valueGetter: (params) => params.row?.parsed_data?.questionnaire_step?.title || '-',
        sortable: false,
    },
    {
        field: 'phone',
        headerName: t('common.phone'),
        minWidth: 140,
        valueGetter: (params) => params.row?.parsed_data?.user?.phone || '-',
        sortable: false,
    },
    {
        field: 'first_name',
        headerName: t('catalog.team.firstname'),
        minWidth: 150,
        valueGetter: (params) => params.row?.parsed_data?.user?.first_name || '-',
        sortable: false,
    },
    {
        field: 'last_name',
        headerName: t('catalog.team.lastname'),
        minWidth: 150,
        valueGetter: (params) => params.row?.parsed_data?.user?.last_name || '-',
        sortable: false,
    },
    {
        field: 'patronymic_name',
        headerName: t('catalog.team.patronymic'),
        minWidth: 150,
        valueGetter: (params) => params.row?.parsed_data?.user?.patronymic_name || '-',
        sortable: false,
    },
    {
        field: 'inn',
        headerName: t('importJournal.inn'),
        minWidth: 150,
        valueGetter: (params) => params.row?.parsed_data?.user?.inn || '-',
        sortable: false,
    },
    {
        field: 'snils',
        headerName: t('reports.reports.snils'),
        minWidth: 150,
        valueGetter: (params) => params.row?.parsed_data?.user?.snils || '-',
        sortable: false,
    },
    {
        field: 'roles',
        headerName: t('catalog.team.roles'),
        minWidth: 150,
        valueGetter: (params) => {
            const { roles } = params.row?.parsed_data?.user || {};
            return roles && roles.length ? roles.map((role) => role) : '-';
        },
        sortable: false,
    },
    {
        field: 'user_phone',
        headerName: t('reports.reports.userPhone'),
        minWidth: 150,
        valueGetter: (params) => params.row?.parsed_data?.user?.phone || '-',
        sortable: false,
    },
    {
        field: 'manager_phone',
        headerName: t('reports.reports.managerPhone'),
        minWidth: 150,
        valueGetter: (params) => params.row?.parsed_data?.manager?.phone || '-',
        sortable: false,
    },
    {
        field: 'external_code',
        headerName: t('common.outletCode'),
        minWidth: 200,
        valueGetter: (params) => params.row?.parsed_data?.project_outlet?.external_code || '-',
        sortable: false,
    },
    {
        field: 'visit_date',
        headerName: t('feed.visitDate'),
        minWidth: 200,
        renderCell: (params) => {
            const { visit_dates } = params.row?.parsed_data?.route || {};
            return visit_dates && Object.keys(visit_dates).length ? (
                <div className={styles.cellWrap}>
                    {Object.keys(visit_dates).map((date, index) => {
                        return (
                            <p key={`${date}-${index}`}>
                                {date} : {visit_dates[date]}
                            </p>
                        );
                    })}
                </div>
            ) : (
                '-'
            );
        },
        sortable: false,
    },
    {
        field: 'travel_time',
        headerName: t('reports.reports.timeForMove'),
        minWidth: 180,
        valueGetter: (params) => params.row?.parsed_data?.route_point?.travel_time || '-',
        sortable: false,
    },
    {
        field: 'visit_time',
        headerName: t('importJournal.visitTime'),
        minWidth: 180,
        valueGetter: (params) => params.row?.parsed_data?.route_point?.visit_time || '-',
        sortable: false,
    },
    {
        field: 'questionnaire_external_code',
        headerName: t('importJournal.questionnaire'),
        minWidth: 180,
        valueGetter: (params) => params.row?.parsed_data?.questionnaire?.external_code || '-',
        sortable: false,
    },
    {
        field: 'outlet_id',
        headerName: t('feed.id'),
        minWidth: 180,
        valueGetter: (params) => params.row?.parsed_data?.project_outlet?.id || '-',
        sortable: false,
    },
    {
        field: 'outlet_name',
        headerName: t('common.name'),
        minWidth: 180,
        valueGetter: (params) => params.row?.parsed_data?.project_outlet?.name || '-',
        sortable: false,
    },
    {
        field: 'address',
        headerName: t('feed.address'),
        minWidth: 180,
        valueGetter: (params) => params.row?.parsed_data?.project_outlet?.address || '-',
        sortable: false,
    },
    {
        field: 'planogram_name',
        headerName: t('catalog.planograms.title'),
        minWidth: 180,
        valueGetter: (params) => params.row?.parsed_data?.planogram?.name || '-',
        sortable: false,
    },
    {
        field: 'planogram_preview',
        headerName: t('importJournal.preview'),
        minWidth: 180,
        valueGetter: (params) =>
            params.row?.parsed_data?.planogram_project_outlet?.is_preview || '-',
        sortable: false,
    },
    {
        field: 'price',
        headerName: t('crowd.price'),
        minWidth: 140,
        valueGetter: (params) => params.row?.parsed_data?.task?.price || '-',
        sortable: false,
    },
    {
        field: 'duration',
        headerName: t('crowd.duration'),
        minWidth: 150,
        valueGetter: (params) => params.row?.parsed_data?.task?.timing || '-',
        sortable: false,
    },
    {
        field: 'start_date',
        headerName: t('crowd.startDate'),
        minWidth: 180,
        valueGetter: (params) => {
            const { start_date } = params.row?.parsed_data?.task || {};
            return start_date
                ? formatters.toISODateFormatString(start_date, 'dd.MM.yyyy HH:mm')
                : '-';
        },
        sortable: false,
    },
    {
        field: 'end_date',
        headerName: t('crowd.endDate'),
        minWidth: 180,
        valueGetter: (params) => {
            const { end_date } = params.row?.parsed_data?.task || {};
            return end_date ? formatters.toISODateFormatString(end_date, 'dd.MM.yyyy HH:mm') : '-';
        },
        sortable: false,
    },
    {
        field: 'description',
        headerName: t('tasks.description'),
        minWidth: 180,
        valueGetter: (params) => params.row?.parsed_data?.task?.description || '-',
        sortable: false,
    },
    {
        field: 'is_photo_required',
        headerName: t('crowd.photoRequired'),
        minWidth: 200,
        valueGetter: (params) => params.row?.parsed_data?.task?.is_photo_required || '-',
        sortable: false,
    },
    {
        field: 'task_type',
        headerName: t('crowd.task_type'),
        minWidth: 180,
        valueGetter: (params) => params.row?.parsed_data?.task_type?.name || '-',
        sortable: false,
    },
    {
        field: 'questionnaire_code',
        headerName: t('crowd.questionnaireCode'),
        minWidth: 180,
        valueGetter: (params) => params.row?.parsed_data?.questionnaire?.external_code || '-',
        sortable: false,
    },
    {
        field: 'complexity',
        headerName: t('crowd.complexity'),
        minWidth: 180,
        valueGetter: (params) => params.row?.parsed_data?.task_complexity?.name || '-',
        sortable: false,
    },
];

export const columnsAndKindRelation = {
    QUESTIONNAIRES: ['questionnaire_name', 'question_name', 'answer', 'step_title'],
    USERS: ['phone', 'first_name', 'last_name', 'patronymic_name', 'inn', 'snils', 'roles'],
    USER_PROJECTS: ['user_phone', 'manager_phone'],
    ROUTES: ['external_code', 'visit_date', 'travel_time', 'visit_time', 'user_phone'],
    ROUTES_V2: ['external_code', 'visit_date', 'travel_time', 'visit_time', 'user_phone'],
    QAPO: ['external_code', 'questionnaire_external_code'],
    PROJECT_OUTLETS: ['outlet_id', 'external_code', 'outlet_name', 'address'],
    PLANOGRAMS: ['external_code', 'planogram_name', 'planogram_preview'],
    CROWD_TASKS: [
        'price',
        'duration',
        'start_date',
        'end_date',
        'description',
        'is_photo_required',
        'task_type',
        'questionnaire_code',
        'external_code',
        'complexity',
    ],
    AP: [],
    CHECKERS: [],
    CROWD_PAYMENTS: [],
    UPDATE_PROJECT_OUTLETS: [],
};
