import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '@components';
import ProjectsHeader from './ProjectsHeader/ProjectsHeader';
import ProjectsData from './ProjectsData/ProjectsData';
import ProjectsFilters from './ProjectsFilters/ProjectsFilters';

export const Projects = () => {
    const { t } = useTranslation('translation');

    const defaultValues = {
        client_id: '',
        merchandising_type: '',
        external_code: '',
        status: '',
    };

    const getFilters = (values) => {
        return values;
    };

    return (
        <Layout
            title={t('common.projects')}
            renderSearch={() => ({ name: 'full_name', placeholder: t('common.searchName') })}
            renderHeader={(props) => <ProjectsHeader {...props} />}
            renderData={(props) => <ProjectsData {...props} />}
            renderFilters={ProjectsFilters}
            defaultValues={defaultValues}
            getFilters={getFilters}
        />
    );
};
