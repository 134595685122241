import React from 'react';

function DispenserIcon({ color='#414152'}) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 11.5C6 10.9477 6.44772 10.5 7 10.5H17C17.5523 10.5 18 10.9477 18 11.5V21.5H6V11.5Z" stroke={color} strokeWidth="2" />
            <path d="M9 7.5C9 6.94772 9.44772 6.5 10 6.5H14C14.5523 6.5 15 6.94771 15 7.5V10.5H9V7.5Z" stroke={color} strokeWidth="2" />
            <path d="M5.5 5.5C5.5 4.5 5.59998 3 7.99998 3C10.4 3 11.6667 3 12.5 3V6" stroke={color} strokeWidth="2" strokeLinejoin="round" />
        </svg>
    );
}

export default DispenserIcon;
