import React, { useCallback, useEffect, useState } from 'react';
import styles from './QuestionnaireEditOutletsData.module.scss';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import { TableGrid, Checkbox } from '@components';
import { useSnackbar } from 'notistack';
import { getProjectOutletsListV2 } from '@apiFeature/projectOutlets';
import {
    listQuestionnaire,
    linkQuestionnaire,
    unlinkQuestionnaire,
} from '@apiFeature/questionnaires';
import { extractErrorMessage } from '@tools/utils/functions';

const limit = 10;

const QuestionnaireEditOutletsData = ({ filters, setTotalUnLink }) => {
    const params = useParams();
    const { projectId, pollId } = params || {};
    const { enqueueSnackbar } = useSnackbar();
    const paginationModelDefault = { page: 0, pageSize: limit };
    const [paginationModel, setPaginationModel] = useState(paginationModelDefault);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any | null>(null);
    const { items = [], total = 0, offset, securedCount } = data || {};

    const getProjectsList = useCallback(
        async (offset = 0) => {
            setLoading(true);

            getProjectOutletsListV2({
                ...filters,
                project_id: projectId,
                pagination: {
                    limit,
                    offset,
                },
            })
                .then(async (res) => {
                    const { items: data, total, offset } = res || {};

                    const { questionnaire_assignment_outlets } =
                        (await listQuestionnaire(pollId)) || [];

                    const items = data.map((e) => ({
                        ...e,
                        checked: questionnaire_assignment_outlets.some(
                            (ee) => ee.project_outlet_id === e.id
                        ),
                    }));
                    //.sort((a, b) => (a.checked === b.checked ? 0 : a.checked ? -1 : 1));

                    const securedCount = questionnaire_assignment_outlets?.length;

                    setData({
                        items,
                        total,
                        offset,
                        securedCount,
                    });

                    setTotalUnLink(securedCount);
                })
                .catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [filters]
    );

    useEffect(() => {
        if (!isLoading) {
            getProjectsList(paginationModel?.page * limit);
        }
    }, [filters, paginationModel]);

    useEffect(() => {
        if (filters && Object.keys(filters)?.length > 0) {
            setPaginationModel(paginationModel);
        }
    }, [filters]);

    const onChange = (id, value) => {
        setLoading(true);
        if (!value) {
            unlinkQuestionnaire(pollId, id)
                .then((res) => {
                    const itemsUp = items.map((e) => ({
                        ...e,
                        checked: e.id === id ? !e.checked : e.checked,
                    }));
                    //.sort((a, b) => (a.checked === b.checked ? 0 : a.checked ? -1 : 1));

                    setData({
                        items: itemsUp,
                        total,
                        offset,
                        securedCount: securedCount - 1,
                    });
                    setTotalUnLink(securedCount - 1);
                })
                .catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            linkQuestionnaire(pollId, id)
                .then((res) => {
                    const itemsUp = items.map((e) => ({
                        ...e,
                        checked: e.id === id ? !e.checked : e.checked,
                    }));
                    //.sort((a, b) => (a.checked === b.checked ? 0 : a.checked ? -1 : 1));

                    setData({
                        items: itemsUp,
                        total,
                        offset,
                        securedCount: securedCount + 1,
                    });
                    setTotalUnLink(securedCount + 1);
                })
                .catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const columns = [
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 40,
            getActions: ({ id, row }) => {
                return [
                    <Checkbox
                        value={row.checked}
                        onChange={(_, value) => onChange(id, value)}
                        id={id}
                        className={styles.checkbox}
                    />,
                ];
            },
        },
        {
            field: 'external_code',
            headerName: t('common.outletCode'),
            flex: 1,
            sortable: false,
        },
        {
            field: 'address',
            headerName: t('feed.address'),
            flex: 2,
            sortable: false,
        },
        {
            field: 'name',
            headerName: t('common.name'),
            flex: 1,
            sortable: false,
        },
        {
            field: 'project_retail_name',
            headerName: t('common.retail'),
            flex: 1,
            sortable: false,
        },
        {
            field: 'branch_name',
            headerName: t('feed.branch'),
            flex: 1,
            sortable: false,
        },
        {
            field: 'territory_name',
            headerName: t('common.territory'),
            flex: 1,
            sortable: false,
        },
        {
            field: 'city_name',
            headerName: t('common.city'),
            flex: 1,
            sortable: false,
        },
    ];

    return (
        <TableGrid
            rows={items}
            columns={columns}
            rowCount={total}
            securedCount={securedCount}
            loading={isLoading}
            pageSizeOptions={[limit]}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            paginationModel={offset === 0 ? paginationModelDefault : paginationModel}
            rowHeight={50}
            hasSun
            disableRowSelectionOnClick
            className={styles.tableGrid}
        />
    );
};

export default QuestionnaireEditOutletsData;
