import React, { Suspense, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { SettingsThunks } from '@redux/settings/thunk';
import { useDispatch } from 'react-redux';
import { useURLFilters } from '@tools/hooks/useURLFilters';
import { Book } from '@navigation/Book';
import { Loader } from '@components';
import { useAppSelector } from '@tools/hooks/redux';
import SettingsSelectors from '@redux/settings/selectors';
import ProjectsSelectors from '@redux/projects/selectors';
import { ProtectedRoute } from './components/ProtectedRoute';
import { useRoles } from '@tools/hooks/useRoles';
import { Home } from '@pages';
import { AddressProgram } from '@pages/AddressProgram/AddressProgram';
import { AddressProgramDetails } from '@pages/AddressProgram/AddressProgramDetails/AddressProgramDetails';
import { CrowdCheck } from '@pages/CrowdCheck/CrowdCheck';
import { CrowdFeed } from '@pages/CrowdFeed/CrowdFeed';
import { GetPhotosByQuestionResult } from '@pages/GetPhotosByQuestionResult/GetPhotosByQuestionResult';
import { GetPhotosByVisitId } from '@pages/GetPhotosByVisitId/GetPhotosByVisitId';
import { ImportJournal } from '@pages/ImportsJournal/ImportsJournal';
import { ImportsJournalDetails } from '@pages/ImportsJournal/ImportsJournalDetails/ImportsJournalDetails';
import { HealthCheck } from '@pages/HealthCheck/HeathCheck';
import { PrivacyPolicy } from '@pages/PrivacyPolicy/PrivacyPolicy';
import { QuestionnaireEdit } from '@pages/Questionnaires/QuestionnaireEdit/QuestionnaireEdit';
import { QuestionnaireEditCommon } from '@pages/Questionnaires/QuestionnaireEdit/QuestionnaireEditCommon/QuestionnaireEditCommon';
import { QuestionnaireEditOutlets } from '@pages/Questionnaires/QuestionnaireEdit/QuestionnaireEditOutlets/QuestionnaireEditOutlets';
import { Reports } from '@pages/Reports/Reports';
import { ReportItemDetails } from '@pages/Reports/ReportItemDetails/ReportItemDetails';
import { PhotoReport } from '@pages/Reports/PhotoReport/PhotoReport';
import { SupportRequests } from '@pages/SupportRequests/SupportRequest';
import { VisitDetails } from '@pages/VisitDetails/VisitDetails';
import { VisitFeed } from '@pages/VisitFeed/VisitFeed';
import { VisitCheck } from '@pages/VisitCheck/VisitCheck';
import { VisitSchedule } from '@pages/VisitSchedule/VisitSchedule';
import { Users } from '@pages/Users/Users';
import { Team } from '@pages/Team/Team';
import { TeamDetails } from '@pages/Team/TeamDetails/TeamDetails';
import { Outlets } from '@pages/Outlets/Outlets';
import { OutletDetails } from '@pages/Outlets/OutletDetails/OutletDetails';
import { Projects } from '@pages/Projects/Projects';
import { ProjectDetails } from '@pages/Projects/ProjectDetails/ProjectDetails';
import { Tasks } from '@pages/Tasks/Tasks';
import { TasksDetails } from '@pages/Tasks/TasksDetails/TasksDetails';
import { RestoredVisit } from '@pages/RestoredVisit/RestoredVisit';
import { Questionnaires } from '@pages/Questionnaires/Questionnaires';

///////////////////legacy///////////////////////
import { CrowdPage } from '@pages/legacy/CrowdPage';
import { EditCrowdPage } from '@pages/legacy/CrowdPage/EditCrowdPage';
import { Planograms } from '@pages/legacy/Planogram/Planogram';
import { Settings } from '@pages/Settings/Settings';
//import { Routes as RoutesPage } from '@pages/legacy/Routes/Routes';
//import { OutletInfoPopup } from '@pages/legacy/Routes/Components/OutletInfoPopup/OutletInfoPopup';
///////////////////legacy///////////////////////

const session_project_id = sessionStorage.getItem('project_id');

export const PrivateRoutes = () => {
    const { settingsAll } = useAppSelector(SettingsSelectors.settingsState);
    const { settings } = settingsAll || {};
    const isTaskCreateBlocked =
        settings?.find((s) => s.code === 'is_task_create_blocked')?.setting_values?.[0]?.val ===
        'true';

    const {
        isClient,
        isClientOnly,
        isClientCrowd,
        isCrowd,
        isAdmin,
        isChecker,
        isUserCatalogEnable,
        isCheckManager,
        isHeadCheckManager,
        isAnalyst,
    } = useRoles();

    const { projectsList } = useAppSelector(ProjectsSelectors.projectsSelectors);
    const projectsListId = projectsList?.[0]?.id;

    const { searchParams } = useURLFilters();
    const { pathname } = useLocation();
    const visitPaths = ['visit_feed', 'visit-check'];
    const isVisitDetails = visitPaths.some(path => pathname.includes(path));
    const dispatch = useDispatch();
    
    useEffect(() => {
        let search_project_id;
        if (searchParams.get('project_id')) {
            try {
                search_project_id = atob(searchParams.get('project_id'));
            } catch {
                search_project_id = searchParams.get('project_id');
            }
        }
        const project_id = search_project_id || session_project_id || projectsListId;

        if (project_id && !isVisitDetails) {
            dispatch(SettingsThunks.getAllSettings({ project_ids: [project_id] }));
            dispatch(SettingsThunks.getAllUserSettings({ project_id }));
        }
    }, []);

    useEffect(() => {
        let search_project_id;
        if (searchParams.get('project_id')) {
            try {
                search_project_id = atob(searchParams.get('project_id'));
            } catch {
                search_project_id = searchParams.get('project_id');
            }
        }
        const project_id = search_project_id || session_project_id;

        if (projectsListId && !settingsAll && !project_id && !isVisitDetails) {
            dispatch(SettingsThunks.getAllSettings({ project_ids: [projectsListId] }));
            dispatch(SettingsThunks.getAllUserSettings({ project_id: projectsListId }));
        }
    }, [projectsListId]);

    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route element={<Navigate to={Book.home} />} path="*" />
                <Route element={<Home />} path={Book.home} />
                <Route
                    element={
                        <ProtectedRoute condition={!isClientOnly}>
                            <AddressProgram />
                        </ProtectedRoute>
                    }
                    path={Book.address_program}
                />
                <Route
                    element={
                        <ProtectedRoute condition={!isClientOnly}>
                            <AddressProgramDetails />
                        </ProtectedRoute>
                    }
                    path={Book.address_program_details}
                />
                <Route
                    element={
                        <ProtectedRoute
                            condition={
                                isAdmin ||
                                (isCrowd && isCheckManager) ||
                                (isCrowd && isHeadCheckManager)
                            }
                        >
                            <CrowdCheck />
                        </ProtectedRoute>
                    }
                    path={Book.crowd_check}
                />
                <Route
                    element={
                        <ProtectedRoute condition={isClientCrowd || isAdmin}>
                            <CrowdFeed />
                        </ProtectedRoute>
                    }
                    path={Book.crowd_feed}
                />
                <Route
                    path={Book.crowd.crowd}
                    element={
                        <ProtectedRoute condition={isAdmin}>
                            <CrowdPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    element={
                        <ProtectedRoute condition={isCrowd || isAdmin}>
                            <EditCrowdPage />
                        </ProtectedRoute>
                    }
                    path={Book.crowd.edit}
                />
                <Route
                    element={
                        <ProtectedRoute condition={isCrowd || isAdmin}>
                            <EditCrowdPage />
                        </ProtectedRoute>
                    }
                    path={Book.crowd.create}
                />
                <Route
                    element={
                        <ProtectedRoute condition={isCrowd || isAdmin}>
                            <EditCrowdPage />
                        </ProtectedRoute>
                    }
                    path={Book.crowd.watch}
                />
                <Route
                    element={<GetPhotosByQuestionResult />}
                    path={Book.get_photos_by_question_result_id}
                />
                <Route element={<GetPhotosByVisitId />} path={Book.get_photos_by_visit_id} />
                <Route
                    element={
                        <ProtectedRoute condition={!isClientOnly}>
                            <ImportJournal />
                        </ProtectedRoute>
                    }
                    path={Book.import_journal}
                />
                <Route
                    element={
                        <ProtectedRoute condition={!isClientOnly}>
                            <ImportsJournalDetails />
                        </ProtectedRoute>
                    }
                    path={Book.import_journal_details}
                />
                <Route
                    element={
                        <ProtectedRoute condition={!isClientOnly}>
                            <Outlets />
                        </ProtectedRoute>
                    }
                    path={Book.catalog.outlets}
                />
                <Route element={<OutletDetails />} path={Book.catalog.outlet_details} />
                <Route
                    element={
                        <ProtectedRoute condition={!isClientOnly}>
                            <Planograms />
                        </ProtectedRoute>
                    }
                    path={Book.catalog.planograms}
                />
                <Route
                    element={
                        <ProtectedRoute condition={isAnalyst || isAdmin}>
                            <Projects />
                        </ProtectedRoute>
                    }
                    path={Book.catalog.projects}
                />
                <Route
                    element={
                        <ProtectedRoute condition={isAnalyst || isAdmin}>
                            <ProjectDetails />
                        </ProtectedRoute>
                    }
                    path={Book.catalog.project_details}
                />
                <Route
                    element={
                        <ProtectedRoute condition={isAdmin}>
                            <ProjectDetails />
                        </ProtectedRoute>
                    }
                    path={Book.catalog.project_create}
                />
                <Route
                    element={
                        <ProtectedRoute condition={!isClientOnly}>
                            <Questionnaires />
                        </ProtectedRoute>
                    }
                    path={Book.questionnaires.questionnaires}
                />
                <Route
                    element={
                        <ProtectedRoute condition={!isClientOnly}>
                            <QuestionnaireEdit />
                        </ProtectedRoute>
                    }
                    path={Book.questionnaires.edit}
                >
                    <Route index element={<QuestionnaireEditCommon />} />
                    <Route element={<QuestionnaireEditOutlets />} path="outlets" />
                </Route>
                <Route
                    element={
                        <ProtectedRoute condition={!isClientOnly}>
                            <QuestionnaireEdit />
                        </ProtectedRoute>
                    }
                    path={Book.questionnaires.create}
                >
                    <Route index element={<QuestionnaireEditCommon />} />
                </Route>
                <Route element={<Reports />} path={Book.reports.reports} />
                <Route element={<ReportItemDetails />} path={Book.reports.report_item} />
                <Route element={<PhotoReport />} path={Book.reports.photo_report} />
                <Route
                    element={
                        <ProtectedRoute condition={!isClientOnly}>
                            <SupportRequests />
                        </ProtectedRoute>
                    }
                    path={Book.support_requests}
                />
                <Route element={<VisitDetails />} path={Book.visit_feed_details} />
                <Route element={<VisitDetails isChecker />} path={Book.visit_check_details} />
                <Route element={<VisitDetails />} path={Book.crowd_feed_details} />
                <Route element={<VisitDetails isChecker />} path={Book.crowd_check_details} />
                <Route element={<VisitFeed />} path={Book.visit_feed} />
                <Route element={<RestoredVisit />} path={Book.restoredVisit.new} />
                <Route element={<RestoredVisit />} path={Book.restoredVisit.copy} />
                <Route element={<Tasks />} path={Book.tasks} />
                <Route
                    element={
                        <ProtectedRoute condition={isAdmin || !isTaskCreateBlocked}>
                            <TasksDetails />
                        </ProtectedRoute>
                    }
                    path={Book.feeds_task_create}
                />
                <Route
                    element={
                        <ProtectedRoute condition={isAdmin || !isTaskCreateBlocked}>
                            <TasksDetails />
                        </ProtectedRoute>
                    }
                    path={Book.tasks_create}
                />
                <Route
                    element={
                        <ProtectedRoute condition={isAdmin || !isTaskCreateBlocked}>
                            <TasksDetails />
                        </ProtectedRoute>
                    }
                    path={Book.tasks_restore}
                />
                <Route
                    element={
                        <ProtectedRoute condition={!isClientOnly}>
                            <Team />
                        </ProtectedRoute>
                    }
                    path={Book.catalog.team}
                />
                <Route
                    element={
                        <ProtectedRoute condition={!isClientOnly}>
                            <TeamDetails />
                        </ProtectedRoute>
                    }
                    path={Book.catalog.team_details}
                />
                <Route
                    element={
                        <ProtectedRoute condition={!isClientOnly}>
                            <TeamDetails />
                        </ProtectedRoute>
                    }
                    path={Book.catalog.team_create}
                />
                <Route
                    element={
                        <ProtectedRoute condition={isChecker || isAdmin}>
                            <VisitCheck />
                        </ProtectedRoute>
                    }
                    path={Book.visit_check}
                />
                <Route element={<VisitSchedule />} path={Book.visit_schedule} />
                {/* 
                <Route
                    element={
                        <ProtectedRoute condition={!isClientOnly}>
                            <RoutesPage />
                        </ProtectedRoute>
                    }
                    path={Book.routes}
                />
                <Route element={<OutletInfoPopup />} path={Book.routes_outlet} />
                */}
                <Route
                    element={
                        <ProtectedRoute condition={isUserCatalogEnable || isAdmin}>
                            <Users />
                        </ProtectedRoute>
                    }
                    path={Book.catalog.users}
                />
                <Route
                    element={
                        <ProtectedRoute condition={isUserCatalogEnable || isAdmin}>
                            <TeamDetails isUser />
                        </ProtectedRoute>
                    }
                    path={Book.catalog.user_details}
                />
                 <Route element={<Settings />} path={Book.settings} />
                <Route element={<HealthCheck />} path={Book.health} />
                <Route element={<PrivacyPolicy />} path={Book.policy} />
            </Routes>
        </Suspense>
    );
};
