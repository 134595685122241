import React from 'react';
import { Button } from 'src/view/components/ui/Button/Button';
import { PopupField } from 'src/view/components/ui/PopupField/PopupField';
import { DeleteConfirmationTypes } from './DeleteConfirmation.type';
import './DeleteConfirmation.style.scss';

export const DeleteConfirmation = ({
    onClose,
    onDelete,
    question,
    btnsText,
}: DeleteConfirmationTypes) => (
    <PopupField onClose={onClose} className="delete-wrap">
        <div className="delete-confirmation-block">
            <span className="delete-confirmation-block__question">{question}</span>

            <div className="delete-confirmation-block__btn-block">
                <Button onClick={onDelete}>{btnsText?.confirm ?? 'Да'}</Button>

                <Button onClick={onClose}>{btnsText?.cancel ?? 'Нет'}</Button>
            </div>
        </div>
    </PopupField>
);
