import React, { FC, useEffect, useState } from 'react';
import styles from './ImageUpload.module.scss';
import { FileUploader } from 'react-drag-drop-files';
import DeleteIcon from '@images/svg/delete.svg';
import { removeDuplicatesForImages } from '../utilsForRestoredVisit';

interface IImageUpload {
    multiple: boolean;
    existingImages?: { url: string | File; id: string | null }[];
    onImagesChange: (images: { url: string | File; id: string | null }[]) => void;
    onImagesDelete?: (deleteIds: string[]) => void;
    signboardPhoto?: File[];
    disabled?: boolean;
}

const ImageUpload: FC<IImageUpload> = ({
    multiple = false,
    existingImages = [],
    onImagesChange,
    onImagesDelete = () => {},
    signboardPhoto,
    disabled = false,
}) => {
    const [images, setImages] = useState<any>([]);

    useEffect(() => {
        if (existingImages.length > 0) {
            const uniqueImages = removeDuplicatesForImages(existingImages);
            setImages(uniqueImages);
        }
    }, [existingImages]);

    useEffect(() => {
        const [signPhoto] = signboardPhoto || [];
        if (signPhoto && !images?.length) {
            setImages([{ url: signPhoto, id: null }]);
        }
    }, [signboardPhoto]);

    const handleChange = (files) => {
        const newImages = multiple
            ? [...images, ...Array.from(files).map((e) => ({ url: e, id: null }))]
            : [{ url: files, id: null }];
        setImages(newImages);
        onImagesChange(newImages);
    };

    const handleDelete = (index, e) => {
        e.preventDefault();
        const newImages = images.filter((_, i) => i !== index);
        setImages(newImages);
        onImagesChange(newImages);

        const deleteIds = images.filter((_, i) => i === index).map((e) => e.id);
        if (deleteIds?.length > 0) onImagesDelete(deleteIds);
    };

    return (
        <div className={styles.imageUpload}>
            <div className={styles.previewWrapper}>
                {images &&
                    images?.length > 0 &&
                    images.map((item, index) => {
                        const { url: image } = item || {};
                        return (
                            <div key={index} className={styles.imagePreview}>
                                <img
                                    src={
                                        typeof image === 'string'
                                            ? image
                                            : URL.createObjectURL(image)
                                    }
                                    alt={`preview ${index}`}
                                    className={styles.image}
                                />
                                <button
                                    className={styles.deleteButton}
                                    onClick={handleDelete.bind(this, index)}
                                >
                                    <img src={DeleteIcon} alt="delete" />
                                </button>
                            </div>
                        );
                    })}
            </div>
            <FileUploader
                handleChange={handleChange}
                name="images"
                types={['JPG', 'PNG', 'JPEG', 'GIF', 'WEBP']}
                multiple={multiple}
                disabled={disabled}
            />
        </div>
    );
};

export default ImageUpload;
