import { createSlice } from '@reduxjs/toolkit';
import { name } from './types';
import action from './action';
interface SliceState {
    isFilterOpen: boolean;
    isMenuOpen: boolean;
}
const initialState: SliceState = {
    isFilterOpen: !localStorage.getItem('isFilter')
        ? true
        : localStorage.getItem('isFilter') === 'true',
    isMenuOpen: localStorage.getItem('isMenu') === 'true',
};

const slice = createSlice({
    name,
    initialState: {
        ...initialState,
    },
    reducers: {
        reset() {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(action.filterToggle, (state) => {
                state.isFilterOpen = !state.isFilterOpen;
            })
            .addCase(action.menuToggle, (state) => {
                state.isMenuOpen = !state.isMenuOpen;
            });
    },
});

export const reducer = slice.reducer;
