import React, { FC, useEffect, useState } from 'react';
import { getProjectOutletsList } from '@apiFeature/projectOutlets';
import { fetchProjects } from 'src/api/projects/projects.api';
import { Planogram } from 'src/types/planogram';
import { FieldTypes, RequestSelectField } from 'src/view/components/Fields/Fields.type';
import { ListingProps, Listing } from 'src/view/components/Listing/Listing';
import { Cart } from './Cart/Cart';
import { NewPlanogram } from './NewPlanogram/NewPlanogram';
import { PlanogramImport } from './PlanogramImport/PlanogramImport';
import { useTranslation } from 'react-i18next';
import { SelectField } from 'src/view/components/ComplexForm/ComplexForm.type';
import { PlanogramTypesName } from '@apiFeature/planograms/types';
import { DeleteConfirmation } from 'src/view/components/ui/DeleteConfirmation/DeleteConfirmation';
import { deletePlanogram, fetchPlanogramsList } from '@apiFeature/planograms';
import { FileLink } from '@types/files';
import './Planogram.style.scss';
import { sortFilterLabels } from '@tools/utils/functions';

export const Planograms: FC = () => {
    const [planograms, setPlanograms] = useState<Planogram[]>([]);
    const [modal, setModal] = useState<boolean>(false);
    const [planogram, setPlanogram] = useState<Planogram | null>(null);
    const [modalImport, setModalImport] = useState<boolean>(false);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [deletedPlanogram, setDeletedPlanogram] = useState(null);
    const [projectId, setProjectId] = useState(null);
    const [slider, setSlider] = useState<null | FileLink[]>(null);
    const { t } = useTranslation('translation');

    const listingProps: ListingProps<Planogram> = {
        title: t('catalog.planograms.planograms'),
        searchData: { key: 'name', placeholder: t('common.searchName') },
        defaultFilter: {
            project_id: null,
            project_outlet_ids: null,
            planogram_type: null,
        },
        emptyText: t('catalog.planograms.empty'),
        showTotal: false,
        setProjectId,
        fields: [
            {
                key: 'project_id',
                field: {
                    type: FieldTypes.RequestSelect,
                    label: t('common.project'),
                    name: 'Project',
                    placeholder: t('common.all'),
                    optionsLoaderConfig: {
                        mapper: (data) => ({ label: data.full_name, value: data.id }),
                        request: (request) => fetchProjects(request),
                    },
                } as RequestSelectField<any>,
            },
            {
                key: 'project_outlet_ids',
                field: {
                    label: t('common.project_outlet'),
                    type: FieldTypes.RequestSelect,
                    name: 'project_outlet_ids',
                    placeholder: t('common.all'),
                    isSearchable: true,
                    searchField: 'address',
                    optionsLoaderConfig: {
                        mapper: (data) => ({
                            label: data?.name + ' ' + data?.address,
                            value: data?.id,
                        }),
                        request: (request) => getProjectOutletsList(request),
                    },
                    projectId: projectId,
                } as RequestSelectField<any>,
            },
            {
                key: 'planogram_type',
                field: {
                    type: FieldTypes.Select,
                    placeholder: t('common.all'),
                    name: 'planogram_type',
                    prependPlaceholder: true,
                    options: sortFilterLabels(Object.keys(PlanogramTypesName).map((type) => {
                        return {
                            value: type,
                            label: PlanogramTypesName[type],
                        };
                    })),
                    label: t('catalog.planograms.type'),
                } as SelectField,
            },
        ],
        request(filter) {
            const { manager_ids, user_ids, ...filterToRequest } = filter;

            if (filter['project_outlet_ids']) {
                filterToRequest['project_outlet_ids'] = [filter['project_outlet_ids']];
            }
            return fetchPlanogramsList(filterToRequest).then((list) => {
                setPlanograms(list?.planograms);
                return list?.planograms;
            });
        },
        buttons: [
            {
                text: t('catalog.planograms.create'),
                onClick: () => setModal(true),
            },
            /*
            {
                text: t('importJournal.import'),
                onClick: () => setModalImport(true),
            },
            */
        ],
    };

    useEffect(() => {
        return () => {
            setPlanograms([]);
        };
    }, []);

    const deleteSelectedPlanogram = (id) => {
        deletePlanogram(id);
        setOpenDeleteConfirm(false);
        setPlanograms((prev) => {
            return prev.filter((planogram) => planogram.id !== id);
        });
    };

    return (
        <>
            <Listing {...listingProps}>
                <div className="planograms">
                    <div className="planograms__list">
                        {planograms?.map((plan) => (
                            <Cart
                                key={plan.id}
                                images={plan.file_links}
                                name={plan.name}
                                project={plan?.project?.full_name || ''}
                                onClick={() => setPlanogram(plan)}
                                id={plan.id}
                                setDeletedPlanogram={setDeletedPlanogram}
                                setOpenDeleteConfirm={setOpenDeleteConfirm}
                                type={plan.type}
                            />
                        ))}
                    </div>
                </div>
            </Listing>
            {(modal || planogram) && (
                <NewPlanogram
                    addPlanogram={(data) => setPlanograms([...planograms, data])}
                    setPlanograms={setPlanograms}
                    planogram={planogram}
                    onClose={() => {
                        setPlanogram(null);
                        setModal(false);
                    }}
                    setSlider={setSlider}
                    slider={slider}
                />
            )}
            {modalImport && <PlanogramImport onClose={() => setModalImport(false)} />}
            {openDeleteConfirm && (
                <DeleteConfirmation
                    onDelete={() => {
                        deleteSelectedPlanogram(deletedPlanogram);
                    }}
                    question={t('messages.planogramsDeleteConfirm')}
                    onClose={() => {
                        setOpenDeleteConfirm(false);
                    }}
                />
            )}
        </>
    );
};
