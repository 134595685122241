import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Questionnaires.module.scss';
import { Select, TableGrid, Checkbox } from '@components';
import {
    linkQuestionnaire,
    unlinkQuestionnaire,
    fetchQuestionnairesList,
} from '@apiFeature/questionnaires';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import Autocomplete from './Autocomplete/Autocomplete';

interface IQuestionnaires {
    outletId: string;
    project_id: string;
}

const limit = 10;

const Questionnaires: FC<IQuestionnaires> = ({ outletId, project_id }) => {
    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);
    const [questionnaireWithSearch, setQuestionnaireWithSearch] = useState([]);
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: limit });
    const [total, setQuestionnairesTotal] = useState(0);
    const [kind, setKind] = useState('questionnaire');

    const filterKind = (kind: string) => {
        setKind(kind);
        setPaginationModel({ page: 0, pageSize: limit });
    };

    const updateQuestionnaries = useCallback(
        async (outletId: string, kind: string = 'questionnaire', offset: number = 0) => {
            setLoading(true);

            const { items = [], total } = await fetchQuestionnairesList({
                project_id,
                project_outlet_id: outletId,
                is_active: true,
                kind,
                sorting: {
                    period_upper: 'desc',
                },
                pagination: {
                    limit,
                    offset,
                },
            });

            const data = items.map((e) => ({
                ...e,
                kind:
                    e.kind === 'questionnaire'
                        ? t('questionnaires.questionnaire')
                        : t('feed.audit'),
                is_active: e.is_active ? t('statuses.active') : t('statuses.inactive'),
                created_at: moment(e?.created_at).format('DD.MM.YYYY'),
                period_lower: moment(e?.period_lower).format('DD.MM.YYYY'),
                period_upper: moment(e?.period_upper).format('DD.MM.YYYY'),
            }));

            setQuestionnaireWithSearch(data);
            setQuestionnairesTotal(total);
            setLoading(false);
        },
        [outletId]
    );

    useEffect(() => {
        if (outletId) updateQuestionnaries(outletId, kind, paginationModel?.page * limit);
    }, [outletId, kind, paginationModel, updateQuestionnaries]);

    const handleUnlinkQuestionnaire = async (id) => {
        if (!project_id || !outletId) return;
        await unlinkQuestionnaire(id, outletId);
        updateQuestionnaries(outletId, kind);
    };

    const handleLinkQuestionnaire = async (questionnaire: any) => {
        const res = await linkQuestionnaire(questionnaire.id, outletId)
            .then((data) => {
                return data;
            })
            .catch((err) => {
                return err;
            });
        if (res && 'questionnaire_assignment_outlets' in res) {
            updateQuestionnaries(outletId, kind);
        } else {
            enqueueSnackbar(t('outlets.link_questionnaire_error'), { variant: 'error' });
        }
    };

    const columns = [
        {
            field: 'assignment',
            headerName: t('catalog.outlets.assignment'),
            minWidth: 50,
            sortable: false,
            renderCell: ({ id }) => (
                <Checkbox
                    label=""
                    onChange={() => {
                        handleUnlinkQuestionnaire(id);
                    }}
                    value={true}
                />
            ),
        },
        { field: 'name', headerName: t('common.name'), minWidth: 300, flex: 1, sortable: false },
        {
            field: 'external_code',
            headerName: t('catalog.outlets.code'),
            minWidth: 300,
            flex: 1,
            sortable: false,
        },
        { field: 'kind', headerName: t('feed.type'), minWidth: 150, sortable: false },
        { field: 'is_active', headerName: t('common.status'), minWidth: 120, sortable: false },
        {
            field: 'created_at',
            headerName: t('questionnaires.createDate'),
            minWidth: 120,
            sortable: false,
        },
        {
            field: 'version',
            headerName: t('questionnaires.version'),
            minWidth: 100,
            sortable: false,
        },
        {
            field: 'period_lower',
            headerName: t('questionnaires.periodLower'),
            minWidth: 120,
            sortable: false,
        },
        {
            field: 'period_upper',
            headerName: t('questionnaires.periodUpper'),
            minWidth: 120,
            sortable: false,
        },
    ];

    const kindList = [
        {
            label: t('questionnaires.questionnaire'),
            value: 'questionnaire',
        },
        {
            label: t('feed.audit'),
            value: 'audit',
        },
    ];

    return (
        <div className={styles.tableWrapper}>
            <div className={styles.filters}>
                <div className={styles.add}>
                    <Autocomplete
                        name="add"
                        onChange={handleLinkQuestionnaire}
                        project_id={project_id}
                        kind={kind}
                        label={t('buttons.add')}
                    />
                </div>
                <div className={styles.kind}>
                    <Select
                        name={'kind'}
                        options={kindList}
                        value={kindList.find((e) => e.value === kind)}
                        onChange={({ value }) => filterKind(value)}
                        placeholder={t('common.choose')}
                    />
                </div>
            </div>
            <TableGrid
                rows={questionnaireWithSearch}
                columns={columns}
                rowCount={total}
                loading={isLoading}
                pageSizeOptions={[limit]}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                paginationModel={paginationModel}
                disableRowSelectionOnClick
                hasSun
            />
        </div>
    );
};

export default Questionnaires;
