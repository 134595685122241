import React, { FC } from 'react';
import { useNavigate, useNavigationType } from 'react-router-dom';
import classNames from 'classnames';
import backArrowSvg from '@images/svg/arrow-back.svg';
import styles from './BackLink.module.scss';

interface IBackLink {
    link: string;
    className?: string | undefined;
}

const BackLink: FC<IBackLink> = ({ className, link, ...props }) => {
    const navigate = useNavigate();
    const navigationType = useNavigationType();

    const handleNavigateBack = () => {
        if (navigationType === 'POP') {
            navigate(link);
        } else {
            navigate(-1);
        }
    };

    return (
        <img
            {...props}
            src={backArrowSvg}
            alt="back"
            className={classNames(styles.backLink, className)}
            onClick={handleNavigateBack}
        />
    );
};

export default BackLink;
