import { createSlice } from '@reduxjs/toolkit';
import { FakeVisitState, reduxStoreName as name } from './types';
import actions, { deletePhoto, loadingStatus, showConfirmPopup } from './actions';
import { FakeVisitThunks } from './thunk';
import FakeVisitAction from './actions';
import { TQuestion } from '../../apiFeature/questionnaires/type';

const initialState: FakeVisitState = {
    loading: false,
    loadingDetailed: false,
    loadingOutlet: false,
    loadingCreate: false,
    loadingDates: false,
    init: false,
    error: false,
    errorMessage: '',
    errorNotFullData: false,
    errorDates: false,
    errorPolls: false,
    projectId: null,
    projectOutletId: null,
    projectOutlets: [],
    userList: [],
    userId: null,
    date: null,
    availableDates: [],
    startTime: null,
    endTime: null,
    comment: null,
    is_ended_in_place: null,
    visit_fail_result: null,
    questionnaires: [],
    questionnaireDetailed: [],
    addedQuestionnaires: [],
    openedQuestionnaires: [],
    questionResults: [],
    start_point_lon: null,
    start_point_lat: null,
    end_point_lon: null,
    end_point_lat: null,
    photos: [],
    photoShopSign: null,
    isSucceed: false,
    curQuestionnaireDetailed: null,
    questionAnswers: {},
    fetchLoading: false,
    showConfirmPopup: false,
    disable: false,
    isValidTime: true,
};

const slice = createSlice({
    name,
    initialState: {
        ...initialState,
    },
    reducers: {
        reset() {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(actions.setProjectId, (state, { payload }) => {
                state.projectId = payload;
            })
            .addCase(actions.setUserId, (state, { payload }) => {
                state.userId = payload;
            })
            .addCase(actions.setOutletId, (state, { payload }) => {
                state.projectOutletId = payload;
            })
            .addCase(actions.setDate, (state, { payload }) => {
                state.date = payload;
            })
            .addCase(actions.setStartTime, (state, { payload }) => {
                state.startTime = payload;
            })
            .addCase(actions.setEndTime, (state, { payload }) => {
                state.endTime = payload;
            })
            .addCase(actions.setComment, (state, { payload }) => {
                state.comment = payload;
            })
            .addCase(actions.setEndedInPlace, (state, { payload }) => {
                state.is_ended_in_place = payload;
            })
            .addCase(actions.setFailResult, (state, { payload }) => {
                state.visit_fail_result = payload;
            })
            .addCase(actions.setErrorNotFullData, (state) => {
                state.errorNotFullData = true;
            })
            .addCase(actions.setProjectOutlets, (state, { payload }) => {
                state.projectOutlets = payload;
            })
            .addCase(actions.setUserList, (state, { payload }) => {
                state.userList = payload;
            })
            .addCase(actions.setPhotoShopSign, (state, { payload }) => {
                state.photoShopSign = payload;
            })
            .addCase(actions.resetError, (state) => {
                state.error = false;
                state.errorMessage = '';
                state.errorPolls = false;
                state.errorDates = false;
                state.errorPolls = false;
                state.errorNotFullData = false;
                state.isSucceed = false;
            })
            .addCase(actions.setQuestionAnswer, (state, { payload }) => {
                state.questionAnswers = { ...state.questionAnswers, ...payload };
            })
            .addCase(actions.clearForm, (state, { payload }) => {
                return { ...initialState };
            });

        builder
            .addCase(FakeVisitThunks.fetchQuestionnaires.pending, (state) => {
                state.loading = true;
                state.errorPolls = false;
            })
            .addCase(FakeVisitThunks.fetchQuestionnaires.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.errorPolls = false;
                state.questionnaires = payload.questionnaires;
            })
            .addCase(FakeVisitThunks.fetchQuestionnaires.rejected, (state, { payload }) => {
                state.errorPolls = true;
                state.errorMessage = payload?.message ?? 'Неизвестная ошибка';
                state.loading = false;
            })
            .addCase(FakeVisitThunks.fetchQuestionnairesDetailed.pending, (state) => {
                state.loadingDetailed = true;
            })
            .addCase(
                FakeVisitThunks.fetchQuestionnairesDetailed.fulfilled,
                (state, { payload }) => {
                    state.loadingDetailed = false;
                    state.errorPolls = false;
                    state.questionnaireDetailed?.push(payload.questionnaires[0]);
                    if (payload.questionnaires.length > 0) {
                        const questionnaireItem = payload.questionnaires[0];
                        state.curQuestionnaireDetailed = questionnaireItem;
                        const questions = questionnaireItem.questionnaire_steps.reduce<TQuestion[]>(
                            (a, { questions: _q }) => (_q && _q.length ? a.concat(_q) : a),
                            []
                        );
                        // state.questionAnswers = utils.initQuestionAnswers(questions, state.questionAnswers ?? {});
                    }
                }
            )
            .addCase(FakeVisitThunks.fetchQuestionnairesDetailed.rejected, (state, { payload }) => {
                state.errorPolls = true;
                state.errorMessage = payload?.message ?? 'Неизвестная ошибка';
                state.loadingDetailed = false;
            })
            .addCase(actions.addQuestionnaireInVisit, (state, { payload }) => {
                if (state.addedQuestionnaires?.includes(payload)) {
                    state.addedQuestionnaires = state.addedQuestionnaires.filter(
                        (pollId) => pollId !== payload
                    );
                } else {
                    state.addedQuestionnaires?.push(payload);
                }
            })
            .addCase(actions.openQuestionnaire, (state, { payload }) => {
                if (state.openedQuestionnaires?.includes(payload)) {
                    state.openedQuestionnaires = state.openedQuestionnaires.filter(
                        (pollId) => pollId !== payload
                    );
                } else {
                    state.openedQuestionnaires?.push(payload);
                }
            })
            .addCase(actions.setQuestionResults, (state, { payload }) => {
                if (
                    !state.questionResults?.find(
                        (result) => result?.question_id === payload?.question_id
                    )
                ) {
                    state.questionResults.push({
                        question_id: payload.question_id,
                        answer: payload.answer,
                    });
                } else {
                    state.questionResults = state.questionResults.map((result) => {
                        if (result.question_id === payload?.question_id) {
                            return {
                                question_id: payload.question_id,
                                answer: payload.answer,
                            };
                        } else return result;
                    });
                }
            })
            .addCase(FakeVisitAction.addPhotos, (state, { payload }) => {
                state.photos?.push(payload);
                state.questionResults?.push({
                    question_id: payload.question_id,
                    answer: [],
                });
            })
            .addCase(FakeVisitAction.isValidTime, (state, { payload }) => {
                state.isValidTime = payload;
            })
            .addCase(FakeVisitAction.deletePhoto, (state, { payload }) => {
                state.photos = state.photos?.filter((i) => i?.blobUri !== payload);
            })
            .addCase(FakeVisitAction.cancelPhoto, (state) => {
                state.photos = [];
            })
            .addCase(FakeVisitAction.loadingStatus, (state, { payload }) => {
                state.fetchLoading = payload;
            })
            .addCase(FakeVisitAction.showConfirmPopup, (state, { payload }) => {
                state.showConfirmPopup = payload;
            })
            .addCase(FakeVisitThunks.fetchProjectOutlet.pending, (state) => {
                state.loadingOutlet = true;
            })
            .addCase(FakeVisitThunks.fetchProjectOutlet.rejected, (state, { payload }) => {
                state.loadingOutlet = false;
                state.error = true;
                state.errorMessage = payload?.message ?? 'Неизвестная ошибка';
            })
            .addCase(FakeVisitThunks.fetchProjectOutlet.fulfilled, (state, { payload }) => {
                state.loadingOutlet = false;
                state.error = false;
                state.start_point_lon = payload.project_outlet.longitude;
                state.start_point_lat = payload.project_outlet.latitude;
                state.end_point_lon = payload.project_outlet.longitude;
                state.end_point_lat = payload.project_outlet.latitude;
            })
            .addCase(FakeVisitThunks.fetchAvailableDates.pending, (state) => {
                state.loadingDates = true;
            })
            .addCase(FakeVisitThunks.fetchAvailableDates.rejected, (state, { payload }) => {
                state.loadingDates = false;
                state.errorDates = true;
                state.errorMessage = payload?.message ?? 'Неизвестная ошибка';
            })
            .addCase(FakeVisitThunks.fetchAvailableDates.fulfilled, (state, { payload }) => {
                state.loadingDates = false;
                state.errorDates = false;
                state.availableDates = payload?.dates;
            })
            .addCase(FakeVisitAction.setLongitudeLatitude, (state, { payload }) => {
                state.start_point_lon = payload.longitude;
                state.start_point_lat = payload.latitude;
                state.end_point_lon = payload.longitude;
                state.end_point_lat = payload.latitude;
            });

        builder
            .addCase(FakeVisitThunks.createFakeVisitThunk.pending, (state) => {
                state.loadingCreate = true;
                state.isSucceed = false;
                state.fetchLoading = true;
                state.disable = true;
            })
            .addCase(FakeVisitThunks.createFakeVisitThunk.rejected, (state, { payload }) => {
                state.loadingCreate = false;
                state.error = true;
                state.isSucceed = false;
                state.errorMessage = payload?.message ?? 'Неизвестная ошибка';
                state.fetchLoading = false;
                state.disable = false;
            })
            .addCase(FakeVisitThunks.createFakeVisitThunk.fulfilled, (state, { payload }) => {
                state.loadingCreate = false;
                state.error = false;
                state.errorMessage = '';
                state.errorNotFullData = false;
                state.isSucceed = true;
                state.fetchLoading = false;
            });
    },
});

export const reducer = slice.reducer;
