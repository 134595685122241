export const declOfNum = (number, words) => {
    return words[
        number % 100 > 4 && number % 100 < 20
            ? 2
            : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
    ];
};

export const isValidUrl = (url) => {
    const objRE = /(^https?:\/\/)?[a-z0-9~_\-\.]+\.[a-z]{2,9}(\/|:|\?[!-~]*)?$/i;
    return objRE.test(url);
};

export const removeCharacters = (val) => {
    if (!val) return val;
    return String(val)?.replace(/[\.\-/\\\s]/g, '');
};

export const getIntegerValue = (value) => {
    try {
        let res = value ? String(parseInt(value)) : '';
        res = res === 'NaN' ? '' : res;
        return res;
    } catch {
        return '';
    }
};
