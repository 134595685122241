import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import ProjectsSelectors from '@redux/projects/selectors';
import SettingsSelectors from '@redux/settings/selectors';
import { fetchVisitFeeds } from '@api/visitFeed/visitFeed.api';
import { Lists } from '@components';
import VisitFeedList from './VisitFeedList/VisitFeedList';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FiltersThunks } from '@redux/filters/thunk';
import { getUsrProj, fetchUserList } from '@apiFeature/user';
import { User } from '@apiFeature/types';
import { FilterValue } from '@apiFeature/types';
import { getFullName } from '@tools/utils/users';
import { useURLFilters } from '@tools/hooks/useURLFilters';
import { fixTimezoneOffset } from '@tools/utils/date.util';
import { getFiltersHiddenActive } from '../utils/functions';

type UserAndManager = Array<{
    manager: User;
    user: string;
}>;

const VisitFeedData = ({
    defaultValues,
    filters,
    clientPermission,
    nameFilters,
    isCheckers = false,
}) => {
    const dispatch = useDispatch();

    const { t } = useTranslation('translation');

    const { searchParams, setSearchParams } = useURLFilters();

    let limitFromURL;
    let pageNumber;
    try {
        limitFromURL = Number(atob(searchParams.get('limit')));
        pageNumber = Number(atob(searchParams.get('page')));
    } catch {
        limitFromURL = Number(searchParams.get('limit'));
        pageNumber = Number(searchParams.get('page'));
    }

    const defaultItemsPerPage = {
        label: limitFromURL > 0 ? limitFromURL : '10',
        value: limitFromURL > 0 ? limitFromURL : 10,
    };
    const [itemsPerPage, setItemsPerPage] = useState<FilterValue>(defaultItemsPerPage);
    const limit = Number(itemsPerPage.value);
    const offsetFromPageNumber = (pageNumber - 1) * limit;
    const paginationModelDefault = {
        offset: offsetFromPageNumber > 0 ? offsetFromPageNumber : 0,
        limit,
    };
    const { projectsList } = useSelector(ProjectsSelectors.projectsSelectors);
    const { settingsAll } = useSelector(SettingsSelectors.settingsState);
    const { settings } = settingsAll || {};
    const [isLoading, setLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState(paginationModelDefault);
    const [sortModel, setSortModel] = useState({ start_time: 'desc' });
    const [data, setData] = useState({ items: [], total: 0, offset: 0 });
    const { items = [], total = 0, offset } = data || {};
    const [userAndManagerList, setUserAndManagerList] = useState<UserAndManager>([]);

    const getProgramsList = useCallback(
        (offset = 0, limit, sortModel) => {
            setLoading(true);
            const [project] = projectsList || [];
            const { id } = project || {};
            const { project: defaultProjectId } = defaultValues || {};
            const project_id = projectsList.find((p) => p.id === defaultProjectId)?.id ?? id;
            const project_ids = [project_id];

            let filtersData = {
                project_id,
                project_ids,
                ...defaultValues,
                ...filters,
            };

            const {
                date_range: dr,
                page,
                limit: lim,
                date_from: dateFrom,
                date_to: dateTo,
                statuses: statusesFilter,
                visit_type: visitTypeFilter,
                ...filtersRes
            } = filtersData || {};

            let filtersHiddenActive = getFiltersHiddenActive(settings);
            if (filtersHiddenActive.includes('date_range'))
                filtersHiddenActive = [...filtersHiddenActive, 'date_from', 'date_to'];

            const date_from = fixTimezoneOffset(new Date(dateFrom).setHours(0, 0, 0));
            let date_to = fixTimezoneOffset(new Date(dateTo).setHours(23, 59, 59));
            if (moment(new Date(dateTo)).isSame(new Date(), 'day') && clientPermission) {
                date_to = fixTimezoneOffset(new Date()).split('.')[0].concat('.000Z');
            }

            let statuses = statusesFilter;
            if (
                (clientPermission && !statusesFilter) ||
                (clientPermission && filtersHiddenActive.includes('statuses') && statusesFilter)
            ) {
                statuses = ['completed'];
            }

            let visit_type = visitTypeFilter;
            if (
                (clientPermission && !visitTypeFilter) ||
                (clientPermission && filtersHiddenActive.includes('visit_type') && visitTypeFilter)
            ) {
                visit_type = 'visit';
            }

            const res = {
                ...filtersRes,
                date_from,
                date_to,
                statuses,
                visit_type,
                sorting: sortModel,
                pagination: {
                    limit,
                    offset,
                },
            };

            let dataRes = {};
            if (clientPermission) {
                Object.keys(res).forEach((e) => {
                    if (
                        !filtersHiddenActive
                            .filter((s) => !['visit_type', 'statuses'].includes(s))
                            .includes(e) &&
                        res[e]
                    ) {
                        dataRes = { ...dataRes, [e]: res[e] };
                    }
                });
            } else {
                dataRes = { ...res };
            }

            fetchVisitFeeds(dataRes).then((res) => {
                setLoading(false);
                const userIdList = res.items.map((feed) => feed.user.id);
                const uniqueUserIds = new Set(userIdList);
                const usersPromise = Promise.all(
                    Array.from(uniqueUserIds).map((userId) => {
                        const existingManager = userAndManagerList.find(
                            (item) => item?.user === userId
                        );

                        if (!existingManager) {
                            return getUsrProj({
                                user_id: userId,
                            }).then((userProject) => {
                                const { user_projects } = userProject || {};

                                const manager_ids = user_projects
                                    .filter(
                                        (e) =>
                                            e.manager_id && e.project_id === filtersData?.project_id
                                    )
                                    .map((e) => e.manager_id);

                                if (manager_ids && manager_ids?.length) {
                                    return fetchUserList({ ids: manager_ids }).then((res) => {
                                        const { items } = res || {};
                                        if (items && items.length) {
                                            const userAndManager = {
                                                user: userId,
                                                manager: items,
                                            };
                                            setUserAndManagerList((prevManagerList) => [
                                                ...prevManagerList,
                                                userAndManager,
                                            ]);
                                            return userAndManager;
                                        }
                                    });
                                }
                            });
                        }

                        return Promise.resolve(existingManager);
                    })
                );
                usersPromise.then((userAndManagerList) => {
                    const resultItems = res.items.map((feed) => {
                        const currentManager = userAndManagerList.find(
                            (item) => item?.user === feed?.user?.id
                        );
                        const manager = currentManager?.manager
                            ?.map((e) => getFullName(e))
                            ?.join(', ');
                        return {
                            ...feed,
                            manager: manager || '',
                        };
                    });
                    setData(() => {
                        const data = { ...res, items: [...resultItems] };
                        return data;
                    });
                });
            });

            if (Object.keys(filtersData)?.length > 0 && nameFilters) {
                const { page, limit, ...filtersRes } = filtersData;
                dispatch(FiltersThunks.setFilters({ [nameFilters]: filtersRes }));
            }
        },
        [projectsList, filters, settingsAll]
    );

    useEffect(() => {
        const { offset, limit } = paginationModel;
        if (projectsList?.length > 0) getProgramsList(offset, limit, sortModel);
    }, [paginationModel, projectsList]);

    useEffect(() => {
        if (Object.keys(filters)?.length > 0) {
            searchParams.set('page', btoa('1'));
            searchParams.set('limit', btoa('10'));
            setSearchParams(searchParams);
            setItemsPerPage({
                label: '10',
                value: 10,
            });
            setPaginationModel({
                offset: 0,
                limit: 10,
            });
        }
    }, [filters]);

    useEffect(() => {
        setPaginationModel(paginationModelDefault);
    }, [sortModel]);

    const onSortModelChange = () => {
        setSortModel((prevState) => ({
            ...prevState,
            start_time: prevState?.start_time === 'asc' ? 'desc' : 'asc',
        }));
    };

    const sortName = `${t('feed.visitStart')}: ${
        sortModel?.start_time === 'asc' ? t('common.sortAsc') : t('common.sortDesc')
    }`;

    return (
        <Lists
            items={items}
            loading={isLoading}
            hasSun={!clientPermission}
            hasNumbersPagination={!clientPermission}
            total={total}
            offset={offset}
            limit={limit}
            renderList={(props) => (
                <VisitFeedList {...props} isCheckers={isCheckers} filters={filters} />
            )}
            onPaginationModelChange={setPaginationModel}
            onSortModelChange={onSortModelChange}
            sort={sortModel?.start_time}
            sortName={sortName}
            pageNumber={pageNumber}
            setItemsPerPage={setItemsPerPage}
        />
    );
};

export default VisitFeedData;
