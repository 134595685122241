import React, { FC, Fragment, ReactChild, ReactFragment, ReactPortal } from 'react';

import { FieldsItem, FieldTypes } from './Fields.type';

import { DatePicker } from '../ui/form/date/DatePicker';
import { Checkbox } from '../ui/form/Checkbox/Checkbox';
import { AsyncSelect } from '../ui/form/Select/AsyncSelect';
import { Select } from '../ui/form/Select/Select';
import { TextInput } from '../ui/form/TextInput/TextInput';
import { RangePicker } from '../ui/form/date/RangePicker';
import { DateTimePicker } from '../ui/DateTimePicker/DateTimePicker';

export type FieldComponent = ReactChild | ReactFragment | ReactPortal | boolean | null | undefined;

interface FieldsProps {
    fields: FieldsItem[];
}

export const Fields: FC<FieldsProps> = ({ fields }) => {
    const getBody = (el: FieldsItem): FieldComponent => {
        switch (el?.type) {
            case FieldTypes.Select:
                return <Select {...el} />;
            case FieldTypes.RequestSelect:
                return <AsyncSelect {...el} />;
            case FieldTypes.Checkbox:
                return <Checkbox {...el} />;
            case FieldTypes.TextInput:
                return <TextInput {...el} />;
            case FieldTypes.Date:
                return <DatePicker {...el} />;
            case FieldTypes.DateRange:
                return <RangePicker {...el} />;
            case FieldTypes.DateTime:
                return <DateTimePicker {...el} />;
            default:
                return null;
        }
    };

    return (
        <>
            {fields?.map((el: FieldsItem, idx: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={idx}>{getBody(el)}</Fragment>
            ))}
        </>
    );
};
