import { createSlice } from "@reduxjs/toolkit"
import { reduxStoreName } from "./types"
import CrowdActions from "./actions";
import { CrowdThunks } from "./thunk";
import { isErrorApi } from "../../apiFeature/types";

const initialState = {
    loading: false,
    error: false,
    errorMessage: '',
    taskResultsList: [],
    taskList: [],
    currentTask: null,
    currentUserId: '',
    taskComplexities: [],
    taskTypes: [],
}

const slice = createSlice({
    name: reduxStoreName,
    initialState: {
        ...initialState
    },
    reducers: {
        reset() {
            return { ...initialState };
        },
    },
    extraReducers: builder => {
        builder
        .addCase(CrowdActions.resetAction, state => {
            state.loading = false;
            state.error = false;
            state.taskList = [];
            state.taskResultsList = [];
        })
        .addCase(CrowdThunks.fetchTaskResultsList.pending, state => {
            state.loading = true;
            state.error = false;
        })
        .addCase(CrowdThunks.fetchTaskResultsList.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.errorMessage = payload?.message ?? 'Неизвестная ошибка';
        })
        .addCase(CrowdThunks.fetchTaskResultsList.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.taskResultsList = payload;
        })
        .addCase(CrowdThunks.fetchTaskById.pending, state => {
            state.loading = true;
            state.error = false;
        })
        .addCase(CrowdThunks.fetchTaskById.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.errorMessage = payload?.message ?? 'Неизвестная ошибка';
        })
        .addCase(CrowdThunks.fetchTaskById.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.currentTask = payload;
        })
        .addCase(CrowdActions.setCurrentUserId, (state, { payload }) => {
            state.currentUserId = payload;
        })
        .addCase(CrowdThunks.fetchTaskComplexities.pending, (state) => {
            state.error = false;
            state.loading = true;
        })
        .addCase(CrowdThunks.fetchTaskComplexities.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.errorMessage = payload?.message ?? 'Неизвестная ошибка';
        })
        .addCase(CrowdThunks.fetchTaskComplexities.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.taskComplexities = payload;
        })
        .addCase(CrowdThunks.fetchTaskTypes.pending, (state) => {
            state.error = false;
            state.loading = true;
        })
        .addCase(CrowdThunks.fetchTaskTypes.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = true;
            state.errorMessage = (isErrorApi(payload) ? payload?.message : payload) ?? 'Неизвестная ошибка';
        })
        .addCase(CrowdThunks.fetchTaskTypes.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = false;
            state.taskTypes = payload;
        })
    }
});

export const reducer = slice.reducer;