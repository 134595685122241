import React, { useCallback, useEffect, useState } from 'react';
import { FormLabel } from '@mui/material';
import { TextareaAutosize } from '@components';
import { useFormContext, Controller } from 'react-hook-form';
import { useAppDispatch } from '@tools/hooks/redux';
import CheckerAction from '@redux/checker/action';
import { useDebounce } from '@tools/hooks/useDebounce';
import CheckerSelectors from '@redux/checker/selector';
import { useSelector } from 'react-redux';
import { checkFields } from './CheckFields';

const PlainAnswer = ({ item, parentId }) => {
    const resultSelect = useSelector(CheckerSelectors.answerSelector(parentId, item?.id));
    const { parent_criteria, parent_id } = item;
    const { setValue, watch, control } = useFormContext();
    const linkedItem = useSelector(CheckerSelectors.getLinkedChecker(parent_id));
    const [isShow, setIsShow] = useState(parent_criteria?.length > 0);
    const linkedVal = linkedItem?.[0]?.answers;
    const dispatch = useAppDispatch();
    const debounceValue = useDebounce(watch(item?.id), 100);

    useEffect(() => {
        if (linkedVal) {
            if (linkedVal === parent_criteria) {
                setIsShow(false);
            } else {
                setIsShow(true);
                dispatch(CheckerAction.deleteFormField(item));
            }
        }
    }, [parent_id, parent_criteria, linkedVal, dispatch, item]);

    const fieldOnChange = useCallback(
        (e) => {
            setValue(item?.id, e?.target?.value);
        },
        [setValue, item?.id]
    );

    useEffect(() => {
        if (debounceValue) {
            dispatch(CheckerAction.updateFormField({ ...item, answers: debounceValue }));
        } else {
            dispatch(CheckerAction.deleteFormField(item));
        }
    }, [debounceValue, dispatch, item]);

    useEffect(() => {
        if (!!resultSelect?.length) {
            setIsShow(false);
        }
    }, [resultSelect?.length]);

    if (isShow) {
        return null;
    }

    return (
        <div className={!!parent_id ? 'py-1.5 ml-5' : 'py-1.5'}>
            <div className="mb-1">
                <FormLabel>
                    {item?.name} {item?.is_required && <span className="text-red">*</span>}
                </FormLabel>
            </div>
            <Controller
                control={control}
                name={item?.id}
                defaultValue={''}
                rules={{
                    required: item?.is_required && !resultSelect?.length && 'Поле обязательно !',
                }}
                render={({ field, fieldState }) => (
                    <>
                        <TextareaAutosize
                            {...field}
                            onChange={(e) => fieldOnChange(e)}
                            placeholder={!!resultSelect?.[0]?.id ? resultSelect?.[0]?.answers : ''}
                            variant="outlined"
                            disabled={!!resultSelect?.[0]?.id}
                            error={!!fieldState.error}
                            minRows={1}
                        />
                        {item?.is_required && (
                            <div className="text-red">{fieldState?.error?.message}</div>
                        )}
                    </>
                )}
            />
            {!!item?.children?.length && (
                <div className="ml-3">
                    {item?.children?.map((item) => checkFields(item, item?.check_list_id))}
                </div>
            )}
        </div>
    );
};

export default PlainAnswer;
