import { crowdServicePassword } from '../ClientPassword';
import { ResponseWithIds } from '../types';
import { CrowdFeedFilterRequest, CrowdFeedRequest, CrowdFeedResponse, TaskResultItem } from './types';

export const getCrowdTaskList = async (filter) => {
    const { tasks } = await crowdServicePassword.post('/api/v1/tasks/list/', filter);

    return tasks;
};

export const getCrowdTaskComplexityList = async (request) => {
    const { task_complexities } = await crowdServicePassword.post(
        '/api/v1/task_complexities/list/',
        request
    );
    return task_complexities;
};

export const getTaskTypeList = async (request) => {
    const { task_types } = await crowdServicePassword.post('/api/v1/task_types/list/', request);
    return task_types;
};

export const createTask = async (task) => {
    const data = await crowdServicePassword.post('/api/v1/tasks/', task);
    return data;
};

export const updateTask = async (id: string, task) => {
    const data = await crowdServicePassword.patch(`/api/v1/tasks/${id}/`, task);
    return data;
};

export const deleteTask = async (id: string) => {
    const data = await crowdServicePassword.delete(`/api/v1/tasks/${id}/`);
    return data;
};

export const getDetailedTaskList = async (id) => {
    const { tasks } = await crowdServicePassword.post('/api/v1/tasks/detailed-list/', [id]);
    return tasks[0];
};

export const getTaskReserveList = async (request) => {
    const { task_reserves } = await crowdServicePassword.post('/api/v1/task_reserves/list/', request);
    return task_reserves;
};

export const getTaskResultsList = async (request) => {
    const { task_results } = await crowdServicePassword.post('/api/v1/task_results/list/', request);
    return task_results;
}

export const getTaskById = async (task_id: string) => {
    const { task } = await crowdServicePassword.get(`/api/v1/tasks/${task_id}/`);
    return task;
};

export const updateTaskResult = async (id: string, taskResult: TaskResultItem) => {
    const data = await crowdServicePassword.patch(`/api/v1/task_results/${id}/`, taskResult);
    return data;
};

export const getCrowdFeedsList = async (request: CrowdFeedRequest) => {
    return await crowdServicePassword.post<CrowdFeedResponse, CrowdFeedRequest>('/api/v1/feeds/list/', request)
};

export const getExecutorsForCrowdFeed = async (request: CrowdFeedFilterRequest) => {
    return await crowdServicePassword.post<ResponseWithIds, CrowdFeedFilterRequest>('/api/v1/tasks/executors_filter/', request)
};

export const getOutletsForCrowdFeed = async (request: CrowdFeedFilterRequest) => {
    return await crowdServicePassword.post<ResponseWithIds, CrowdFeedFilterRequest>('/api/v1/tasks/project_outlet_filter/', request)
}
