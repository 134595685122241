import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './VisitScheduleBaseFilters.module.scss';
import { useTranslation } from 'react-i18next';
import { Select, TextField, RangePicker, Filters, Button } from '@components';
import arrowSelectIcon from '@images/svg/arrow-for-select-red.svg';
import arrowOpenSelectIcon from '@images/svg/arrow-for-select-open-red.svg';
import VisitScheduleBaseFiltersData from './VisitScheduleBaseFiltersData/VisitScheduleBaseFiltersData';
import { getFiltersHiddenActive } from '../helpers/helpers';
import { useReset } from '@tools/hooks/useReset';

const VisitScheduleBaseFilters = ({ defaultValues, form, settings }) => {
    const filtersHiddenActive = getFiltersHiddenActive(settings) || [];

    const [isOpenedFilters, setOpenedFilters] = useState(true);

    const { t } = useTranslation('translation');

    const { reset, watch, setValue } = form;
    const currValues = watch();
    const {
        project_id,
        type,
        date,
        user_ids,
        manager_ids,
        kind,
        status,
        project_retail_ids,
        branch_ids,
        city_ids,
    } = currValues;

    const {
        projectOptions,
        usersOptions,
        managerOptions,
        retailsOptions,
        branchesOptions,
        citiesOptions,
        loadingProjects,
        usersLoading,
        managersLoading,
        retailsLoading,
        branchesLoading,
        citiesLoading,
        projectsListId,
    } = VisitScheduleBaseFiltersData(form);

    const onChange = (name, e) => {
        let value = e?.value;
        if (e && Array.isArray(e)) {
            value = e.length > 0 ? e.map((item) => item.value) : null;
        }
        setValue(name, value);
    };

    const { setReset } = useReset();
    const handleReset = () => {
        reset({
            ...defaultValues,
            project_id: projectsListId,
            type,
            kind,
            status,
        });
        setReset(projectsListId);
    };

    let filtersBaseData = [
        {
            type: Select,
            name: 'project_id',
            label: t('common.project'),
            onChange,
            value: project_id
                ? projectOptions?.find((e) => e.value === project_id)
                : projectOptions?.[0],
            options: projectOptions,
            isLoading: loadingProjects,
        },
        {
            type: RangePicker,
            name: 'date',
            label: t('visits.dateRange'),
            placeholder: '',
            dateFormat: 'dd.MM.yyyy',
            valueStart: date?.start,
            valueEnd: date?.end,
        },
    ];

    let filters = [
        {
            type: Select,
            name: 'user_ids',
            label: t('common.user'),
            placeholder: t('common.all'),
            onChange,
            value: user_ids ? usersOptions?.filter((e) => user_ids.includes(e.value)) : null,
            options: usersOptions,
            isLoading: usersLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: Select,
            name: 'manager_ids',
            label: t('common.manager'),
            placeholder: t('common.all'),
            onChange,
            value: manager_ids
                ? managerOptions?.filter((e) => manager_ids.includes(e.value))
                : null,
            options: managerOptions,
            isLoading: managersLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: TextField,
            name: 'address',
            label: t('feed.address'),
            placeholder: t('feed.searchAddress'),
        },
        {
            type: Select,
            name: 'branch_ids',
            label: t('feed.branch'),
            placeholder: t('common.searchBranch'),
            onChange,
            value: branch_ids ? branchesOptions?.filter((e) => branch_ids.includes(e.value)) : null,
            options: branchesOptions,
            isClearable: true,
            isMulti: true,
            isLoading: branchesLoading,
        },
        {
            type: Select,
            name: 'city_ids',
            label: t('common.city'),
            placeholder: t('common.searchCity'),
            onChange,
            value: city_ids ? citiesOptions?.filter((e) => city_ids.includes(e.value)) : null,
            options: citiesOptions,
            isClearable: true,
            isMulti: true,
            isLoading: citiesLoading,
        },
        {
            type: Select,
            name: 'project_retail_ids',
            label: t('common.retail'),
            placeholder: t('common.all'),
            onChange,
            value: project_retail_ids
                ? retailsOptions?.filter((e) => project_retail_ids.includes(e.value))
                : null,
            options: retailsOptions,
            isLoading: retailsLoading,
            isClearable: true,
            isMulti: true,
        },
        {
            type: TextField,
            name: 'external_code',
            label: t('common.outletCode'),
            placeholder: t('common.searchOutletCode'),
        },
        {
            type: TextField,
            name: 'name',
            label: t('common.outletName'),
            placeholder: t('common.searchName'),
        },
        {
            type: TextField,
            name: 'ir_outlet_code',
            label: t('feed.code'),
            placeholder: t('common.searchOutletCode'),
        },
        {
            type: TextField,
            name: 'ir_type_visit',
            label: t('common.irTypeVisit'),
            placeholder: t('common.searchType'),
        },
    ];

    filtersBaseData = filtersBaseData.filter((e) => !filtersHiddenActive.includes(e.name));
    filters = filters.filter((e) => !filtersHiddenActive.includes(e.name));

    return (
        <div className={styles.baseFilters}>
            <Filters
                className={classNames(styles.filters, styles.filtersBase)}
                defaultValues={defaultValues}
                filters={filtersBaseData}
            />
            <div className={styles.actions}>
                <Button
                    className={styles.textBtn}
                    onClick={() => setOpenedFilters(!isOpenedFilters)}
                    color="secondary"
                    endIcon={
                        <img
                            className={styles.actionsImg}
                            src={!isOpenedFilters ? arrowSelectIcon : arrowOpenSelectIcon}
                            alt=""
                        />
                    }
                >
                    {t('common.allFilters')}
                </Button>
            </div>
            <Filters
                className={classNames(styles.filters, {
                    [styles.isOpenedFilters]: isOpenedFilters,
                })}
                defaultValues={defaultValues}
                filters={filters}
            />
            <div className={styles.reset}>
                <Button className={styles.textBtn} onClick={handleReset} color="secondary">
                    {t('common.reset').toLocaleUpperCase()}
                </Button>
            </div>
        </div>
    );
};

export default VisitScheduleBaseFilters;
