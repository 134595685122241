/* eslint no-underscore-dangle: 0 */

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const FILE_SERVICE_URL = process.env.REACT_APP_FILE_SERVICE_URL;

export const IMPORT_SERVICE_URL = process.env.REACT_APP_IMPORT_SERVICE_URL;

export const EXPORT_SERVICE_URL = process.env.REACT_APP_EXPORT_SERVICE_URL;

export const AUTH_URL = process.env.REACT_APP_AUTH_URL;

export const CROWD_SERVICE_URL = process.env.REACT_APP_CROWD_URL;

export const REACT_APP_CHECKER = process.env.REACT_APP_CHECKER;

export const AP_SERVICE_URL = process.env.REACT_APP_AP_URL;

export const ACCESS_TOKEN = 'access_token';
export const ACCESS_EXPIRES = 'access_expires';
export const REFRESH_TOKEN = 'refresh_token';
export const REFRESH_EXPIRES = 'refresh_expires';

export const urls = {
    auth: {
        login: '/api/v1/auth/login/',
        refresh: '/api/v1/auth/refresh/',
        loginBySms: '/api/v1/sms_auth/login_by_otp_code/',
    },
    project_outlets: {
        list: '/project_outlets/list/',
        patch: (id: string) => `/project_outlets/${id}/`,
    },
    projects: {
        list: '/projects/list/',
        create: '/projects/',
        patch: (id: string) => `/projects/${id}/`,
        getProject: (id: string) => `/projects/${id}/`,
    },
    clients: {
        list: '/clients/',
    },
    userProjects: {
        team: (id?: string) => (id ? `/user_projects/team/${id}/` : '/user_projects/team/'),
    },
    routes: {
        list: '/routes/list/',
        create: '/routes/',
        getRoute: (id: string) => `/routes/${id}/`,
    },
    routePoints: {
        list: '/route_points/list/',
        create: '/route_points/',
        getRoutePoint: (id: string) => `/route_points/${id}/`,
        delete: (id: string) => `/route_points/${id}/`,
    },
    user: {
        create: '/api/v1/users/',
    },
    users: {
        list: '/api/v1/users/list/',
    },
    branches: {
        list: '/branches/',
        create: '/branches/',
    },
    retails: {
        list: '/project_retails/list/',
        get: (id: string) => `/project_retails/${id}/`,
        create: '/retails/',
    },
    planograms: {
        list: '/planograms/list/',
        create: '/planograms/',
    },
    planogram_files: {
        create: (id: string | number) => `/planogram_files/${id}/`,
    },
    feeds: {
        list: '/feeds/list/',
        photos: '/file_links/list/',
    },
    visitsSchedule: {
        list: '/visiting_schedule/list/',
    },
    teems: {
        list: '/user_projects/team/',
        roles: '/user_projects/roles/',
    },
    tasks: {
        list: '/tasks/list/',
        create: '/tasks/',
    },
    visits: {
        update: (id: string) => `/visits/${id}`,
        delete: (id: string) => `/visits/${id}`,
    },
    questionnaires: {
        list: '/questionnaires/list/',
        detailed: '/questionnaires/detailed-list/',
    },
    reports: {
        visits: '/visits/export/visits/',
        questionnaires: '/visits/export/visits_with_questionnaires/',
        photos: '/visits/export/photo_files/',
    },
};