import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "./types";
import { reduxStoreName } from "./types";
import { AuthThunks } from "./thunk";
import { t } from "i18next";
import jwtDecode from "jwt-decode";
import AuthActions from "./actions";

export const emptyTokenState = {
    token: '',
    expires_at: '',
};

const initAccessTokenState = {
    token: localStorage.getItem('access_token') ?? '',
    expires_at: localStorage.getItem('access_expires') ?? '',
};

const initRefreshTokenState = {
    token: localStorage.getItem('refresh_token') ?? '',
    expires_at: localStorage.getItem('refresh_expires') ?? '',
};

const initialState: AuthState = {
    loading: false,
    errMessage: '',
    user: undefined,
    refreshToken: initRefreshTokenState,
    accessToken: initAccessTokenState,
};

const slice = createSlice({
    name: reduxStoreName,
    initialState: { ...initialState },
    reducers: {
        reset() {
            return { ...initialState }
        },
    },
    extraReducers: builder => {
        builder
            .addCase(AuthThunks.loginRequest.pending, (state) => {
                state.loading = true;
                state.user = null;
                state.errMessage = '';
            })
            .addCase(AuthThunks.loginRequest.rejected, (state, { payload }) => {
                state.loading = false;
                const { detail } = payload || {};
                if (detail === 'Too many tries. Repeat in 5 min') {
                    state.errMessage = t('messages.limitOfTries');
                } else if (detail === 'Unauthorized') {
                    state.errMessage = t('messages.invalidLoginOrPassword');
                } else {
                    const errorMessage = payload?.message || payload?.data?.detail || t('messages.unknownError');
                    state.errMessage = errorMessage;
                }
                state.user = null;
            })
            .addCase(AuthThunks.loginRequest.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.errMessage = '';
                const { tokens } = payload || {};
                const { access, refresh } = tokens || {};
                try {
                    const decoded = jwtDecode(access?.token);
                    state.jwtPayloadOpen = decoded;
                } catch (e) {
                    state.errMessage = e;
                }
                state.tokens = tokens;
                state.user = payload.user;
                state.refreshToken = refresh;
                state.accessToken = access;
            })
        builder
            .addCase(AuthThunks.loginBySMS.pending, (state) => {
                state.loading = true;
                state.user = null;
                state.errMessage = '';
            })
            .addCase(AuthThunks.loginBySMS.rejected, (state, { payload }) => {
                state.loading = false;
                const { detail } = payload || {};
                if (detail === 'Too many tries. Repeat in 5 min') {
                    state.errMessage = t('messages.limitOfTries');
                } else if (detail === 'Unauthorized') {
                    state.errMessage = t('messages.invalidLoginOrPassword');
                } else {
                    const errorMessage = payload?.message || payload?.data?.detail || t('messages.unknownError');
                    state.errMessage = errorMessage;
                }
                state.user = null;
            })
            .addCase(AuthThunks.loginBySMS.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.errMessage = '';
                const { tokens } = payload || {};
                const { access, refresh } = tokens || {};
                try {
                    const decoded = jwtDecode(access?.token);
                    state.jwtPayloadOpen = decoded;
                } catch (e) {
                    state.errMessage = e;
                }
                state.tokens = tokens;
                state.user = payload.user;
                state.refreshToken = refresh;
                state.accessToken = access;
            })
        builder
            .addCase(AuthActions.logout, (state) => {
                state.refreshToken = emptyTokenState;
                state.accessToken = emptyTokenState;
                localStorage.clear();
            })
            .addCase(AuthActions.saveAuth, (state, { payload }) => {
                const { tokens } = payload || {};
                const { access, refresh } = tokens || {};
                state.tokens = tokens;
                state.user = payload.user;
                state.refreshToken = refresh;
                state.accessToken = access;
                state.errMessage = '';
            })
            .addCase(AuthActions.resetError, (state) => {
                state.errMessage = '';
            })
        builder
            .addCase(AuthThunks.fetchUserInfo.pending, (state) => {
                state.loading = true;
                state.user = null;
                state.errMessage = '';
            })
            .addCase(AuthThunks.fetchUserInfo.rejected, (state, { payload }) => {
                state.loading = false;
                const errorMessage = payload?.message || payload?.data?.detail || t('messages.unknownError');
                state.user = null;
                state.errMessage = errorMessage;
            })
            .addCase(AuthThunks.fetchUserInfo.fulfilled, (state, { payload } ) => {
                state.loading = false;
                state.errMessage = '';
                state.user = payload?.user;
            })
    }
});

export const reducer = slice.reducer;