import React from 'react';
import styles from './Placeholder.module.scss';

export const Placeholder = ({ depth }) => (
    <div
        className={styles.placeholder}
        style={{ left: depth * 24 }}
        data-testid="placeholder"
    ></div>
);
