import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './ReportsBaseFilters.module.scss';
import { useTranslation } from 'react-i18next';
import { Select, TextField, RangePicker, Filters, Button } from '@components';
import arrowSelectIcon from '@images/svg/arrow-for-select-red.svg';
import arrowOpenSelectIcon from '@images/svg/arrow-for-select-open-red.svg';
import ReportsBaseFiltersData from './ReportsBaseFiltersData/ReportsBaseFiltersData';
import SettingsSelectors from '@redux/settings/selectors';
import { useSelector } from 'react-redux';
import { getMinDateBasedOnSettings } from '@tools/utils/date.util';
import { useURLFilters } from '@tools/hooks/useURLFilters';
import { useReset } from '@tools/hooks/useReset';

const ReportsBaseFilters = ({ defaultValues, form, clientPermission }) => {
    const [isOpenedTTFilters, setOpenedTTFilters] = useState(true);
    const [isOpenedVisitFilters, setOpenedVisitFilters] = useState(true);
    const [isOpenedQuestionnaireFilters, setOpenedQuestionnaireFilters] = useState(false);
    const { setURLFilters } = useURLFilters();
    const { settingsAll } = useSelector(SettingsSelectors.settingsState);
    const [minDate, setMinDate] = useState<Date | null>(null);

    const { t } = useTranslation('translation');

    const { reset, watch, setValue } = form;
    const currValues = watch();
    const {
        project_id,
        visit_date,
        is_visit_ended_out_outlet,
        route_point_is_scheduled,
        visit_is_start_fake_gps,
        visit_is_fake_photo,
        visit_status,
        task_result_status,
        visit_type,
        visit_fail_result_name,
        user_id,
        manager_id,
        questionnaire_name,
        questionnaire_kind,
        visit_is_restored,
        kind: curKind,
        status,
        city_id,
        project_retail_ids,
        branch_ids,
    } = currValues;

    const kind = [
        'VISITS',
        'VISITS_WITH_QUESTIONNAIRES',
        'CROWD_TASKS',
        'CROWD_QUESTIONNAIRES',
    ].some((ee) => ee === curKind)
        ? curKind
        : 'VISITS';

    const {
        projectOptions,
        questionnairesProjectsOptions,
        citiesOptions,
        retailsOptions,
        branchesOptions,
        visitsFailResultsOptions,
        yesNoOptions,
        visitStatusOptions,
        taskResultStatusOptions,
        visitTypeOptions,
        questionnaireKindOptions,
        loadingProjects,
        loadingQuestionnairesProjects,
        citiesLoading,
        retailsLoading,
        branchesLoading,
        visitsFailResultsLoading,
        projectsListId,
    } = ReportsBaseFiltersData(form);

    useEffect(() => {
        const { settings } = settingsAll || {};
        if (settings) {
            const minDate = getMinDateBasedOnSettings(settings, project_id, clientPermission);
            if (minDate) setMinDate(minDate);
        }
    }, [settingsAll]);

    const onChange = (name, e) => {
        setValue(name, e?.value);
    };

    const { setReset } = useReset();
    const handleReset = () => {
        reset({
            ...defaultValues,
            project_id: projectsListId,
            kind: 'VISITS',
            status,
            visit_date: { start: null, end: null },
        });

        setURLFilters({ value: 'VISITS' }, 'kind');
        setReset(projectsListId);
    };

    const filtersBaseData = [
        {
            type: Select,
            name: 'project_id',
            label: t('common.project'),
            onChange,
            value: project_id
                ? projectOptions?.find((e) => e.value === project_id)
                : projectOptions?.[0],
            options: projectOptions,
            isLoading: loadingProjects,
        },
        {
            type: RangePicker,
            name: 'visit_date',
            label: t('visits.dateRange'),
            placeholder: '',
            dateFormat: 'dd.MM.yyyy',
            valueStart: visit_date?.start,
            valueEnd: visit_date?.end,
            minDate,
            maxDate: clientPermission ? new Date() : null,
        },
    ];

    const filtersTTData = [
        {
            type: Select,
            name: 'branch_ids',
            label: t('feed.branch'),
            placeholder: t('common.searchBranch'),
            onChange,
            value: branchesOptions?.find((e) => e.value === branch_ids),
            options: branchesOptions,
            isClearable: true,
            isLoading: branchesLoading,
        },
        {
            type: Select,
            name: 'city_id',
            label: t('common.city'),
            placeholder: t('common.searchCity'),
            onChange,
            value: citiesOptions?.find((e) => e.value === city_id),
            options: citiesOptions,
            isClearable: true,
            isLoading: citiesLoading,
            visible: ['CROWD_TASKS', 'CROWD_QUESTIONNAIRES'],
        },
        {
            type: Select,
            name: 'project_retail_ids',
            label: t('common.retail'),
            placeholder: t('common.searchRetail'),
            onChange,
            value: retailsOptions?.find((e) => e.value === project_retail_ids),
            options: retailsOptions,
            isClearable: true,
            isLoading: retailsLoading,
            visible: ['CROWD_TASKS', 'CROWD_QUESTIONNAIRES'],
        },
        {
            type: TextField,
            name: 'project_outlet_external_code',
            label: t('common.outletCode'),
            placeholder: t('common.searchOutletCode'),
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES', 'CROWD_QUESTIONNAIRES'],
        },
        {
            type: TextField,
            name: 'project_outlet_alert_magnit_code',
            label: t('reports.reports.additionalCode'),
            placeholder: t('common.searchOutletCode'),
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES', 'CROWD_QUESTIONNAIRES'],
        },
        {
            type: TextField,
            name: 'project_outlet_name',
            label: t('common.outletNameTT'),
            placeholder: t('common.searchName'),
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES', 'CROWD_QUESTIONNAIRES'],
        },
        {
            type: TextField,
            name: 'project_outlet_address',
            label: t('feed.address'),
            placeholder: t('feed.searchAddress'),
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES', 'CROWD_QUESTIONNAIRES'],
        },
        {
            type: TextField,
            name: 'project_outlet_additional_projects',
            label: t('common.additionalProjectTT'),
            placeholder: t('common.searchAdditionalProject'),
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES', 'CROWD_QUESTIONNAIRES'],
        },
        {
            type: TextField,
            name: 'project_outlet_outlet_types',
            label: t('common.outletTypesTT'),
            placeholder: t('common.searchOutletTypes'),
            visible: ['VISITS'],
        },
        {
            type: TextField,
            name: 'project_outlet_ir_outlet_code',
            label: t('reports.reports.additionalCodeIr'),
            placeholder: t('common.searchOutletCode'),
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES', 'CROWD_QUESTIONNAIRES'],
        },
        {
            type: TextField,
            name: 'project_outlet_ir_type_visit',
            label: t('reports.reports.outletIrTypeVisitTT'),
            placeholder: t('common.searchOutletIrTypeVisit'),
            visible: ['VISITS'],
        },
    ];

    const filtersVisitData = [
        /* Временно скрыть до доработки на бэке
        {
            type: Select,
            name: 'manager_id',
            label: t('common.manager'),
            placeholder: t('common.all'),
            onChange,
            value: userProjectsOptions?.find((e) => e.value === manager_id),
            options: userProjectsOptions,
            isClearable: true,
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES'],
            isLoading: userProjLoading,
        },
        {
            type: Select,
            name: 'user_id',
            label: t('common.user'),
            placeholder: t('common.all'),
            onChange,
            value: userProjectsOptions?.find((e) => e.value === user_id),
            options: userProjectsOptions,
            isClearable: true,
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES'],
            isLoading: userProjLoading,
        },
        */
        {
            type: TextField,
            name: 'visit_end_distance_lower',
            label: t('reports.reports.endDistance'),
            placeholder: '',
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES'],
        },
        {
            type: TextField,
            name: 'visit_end_distance_upper',
            label: '',
            placeholder: '',
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES'],
        },
        {
            type: Select,
            name: 'visit_is_start_fake_gps',
            label: t('reports.reports.fakeGps'),
            placeholder: t('common.all'),
            onChange,
            value: yesNoOptions?.find((e) => e.value === visit_is_start_fake_gps),
            options: yesNoOptions,
            isClearable: true,
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES'],
        },
        {
            type: Select,
            name: 'visit_is_fake_photo',
            label: t('reports.reports.fakePhoto'),
            placeholder: t('common.all'),
            onChange,
            value: yesNoOptions?.find((e) => e.value === visit_is_fake_photo),
            options: yesNoOptions,
            isClearable: true,
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES'],
        },
        {
            type: Select,
            name: 'visit_status',
            label: t('feed.visitStatus'),
            placeholder: t('common.all'),
            onChange,
            value: visitStatusOptions?.find((e) => e.value === visit_status),
            options: visitStatusOptions,
            isClearable: true,
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES'],
        },
        {
            type: Select,
            name: 'task_result_status',
            label: t('feed.taskStatus'),
            placeholder: t('common.all'),
            onChange,
            value: taskResultStatusOptions?.find((e) => e.value === task_result_status),
            options: taskResultStatusOptions,
            isClearable: true,
            visible: ['CROWD_TASKS', 'CROWD_QUESTIONNAIRES'],
        },
        {
            type: Select,
            name: 'visit_type',
            label: t('feed.visitType'),
            placeholder: t('common.all'),
            onChange,
            value: visitTypeOptions?.find((e) => e.value === visit_type),
            options: visitTypeOptions,
            isClearable: !clientPermission ? true : false,
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES'],
        },
        {
            type: Select,
            name: 'is_visit_ended_out_outlet',
            label: t('reports.reports.endedOut'),
            placeholder: t('common.all'),
            onChange,
            value: yesNoOptions?.find((e) => e.value === is_visit_ended_out_outlet),
            options: yesNoOptions,
            isClearable: true,
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES'],
        },
        {
            type: Select,
            name: 'visit_fail_result_name',
            label: t('filledVisit.problemInShop'),
            placeholder: t('common.all'),
            onChange,
            value: visitsFailResultsOptions?.find((e) => e.value === visit_fail_result_name),
            options: visitsFailResultsOptions,
            isClearable: true,
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES'],
            isLoading: visitsFailResultsLoading,
        },
        {
            type: Select,
            name: 'route_point_is_scheduled',
            label: t('reports.reports.planVisit'),
            placeholder: t('common.all'),
            onChange,
            value: yesNoOptions?.find((e) => e.value === route_point_is_scheduled),
            options: yesNoOptions,
            isClearable: true,
            visible: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES'],
        },
    ];

    const filtersQuestionnaireData = [
        {
            type: Select,
            name: 'questionnaire_name',
            label: t('reports.reports.questionnaireName'),
            placeholder: t('common.all'),
            onChange,
            value: questionnairesProjectsOptions?.find((e) => e.value === questionnaire_name),
            options: questionnairesProjectsOptions,
            isClearable: true,
            visible: ['VISITS_WITH_QUESTIONNAIRES', 'CROWD_QUESTIONNAIRES'],
            isLoading: loadingQuestionnairesProjects,
        },
        {
            type: Select,
            name: 'questionnaire_kind',
            label: t('reports.reports.questionnaireType'),
            placeholder: t('common.all'),
            onChange,
            value: questionnaireKindOptions?.find((e) => e.value === questionnaire_kind),
            options: questionnaireKindOptions,
            isClearable: true,
            visible: ['VISITS_WITH_QUESTIONNAIRES', 'CROWD_QUESTIONNAIRES'],
        },
        {
            type: TextField,
            name: 'questionnaire_step_title',
            label: t('reports.reports.stepTitle'),
            placeholder: t('common.search'),
            visible: ['VISITS_WITH_QUESTIONNAIRES', 'CROWD_QUESTIONNAIRES'],
        },
        {
            type: TextField,
            name: 'questionnaire_step_description',
            label: t('reports.reports.stepDescription'),
            placeholder: t('common.search'),
            visible: ['VISITS_WITH_QUESTIONNAIRES', 'CROWD_QUESTIONNAIRES'],
        },
        {
            type: TextField,
            name: 'question_name',
            label: t('reports.reports.question'),
            placeholder: t('common.search'),
            visible: ['VISITS_WITH_QUESTIONNAIRES', 'CROWD_QUESTIONNAIRES'],
        },
        {
            type: TextField,
            name: 'question_result_answer',
            label: t('common.answer'),
            placeholder: t('common.search'),
            visible: ['VISITS_WITH_QUESTIONNAIRES', 'CROWD_QUESTIONNAIRES'],
        },
        {
            type: Select,
            name: 'visit_is_restored',
            label: t('reports.reports.restoredVisit'),
            placeholder: t('common.all'),
            onChange,
            value: yesNoOptions?.find((e) => e.value === visit_is_restored),
            options: yesNoOptions,
            isClearable: true,
            visible: ['VISITS_WITH_QUESTIONNAIRES'],
        },
    ];

    const filtersTT = filtersTTData.filter((e) => !e.visible || e.visible.includes(kind));
    const filtersVisit = filtersVisitData.filter((e) => !e.visible || e.visible.includes(kind));
    const filtersQuestionnaires = filtersQuestionnaireData.filter(
        (e) => !e.visible || e.visible.includes(kind)
    );

    return (
        <div className={styles.reportsBaseFilters}>
            <Filters
                className={classNames(styles.filters, styles.filtersBase)}
                defaultValues={defaultValues}
                filters={filtersBaseData}
            />
            <div className={styles.actions}>
                <Button
                    className={styles.textBtn}
                    onClick={() => setOpenedTTFilters(!isOpenedTTFilters)}
                    color="secondary"
                    endIcon={
                        <img
                            className={styles.actionsImg}
                            src={!isOpenedTTFilters ? arrowSelectIcon : arrowOpenSelectIcon}
                            alt=""
                        />
                    }
                >
                    {t('common.project_outlet')}
                </Button>
            </div>
            <Filters
                className={classNames(styles.filters, {
                    [styles.isOpenedFilters]: isOpenedTTFilters,
                })}
                defaultValues={defaultValues}
                filters={filtersTT}
            />
            <div className={styles.actions}>
                <Button
                    className={styles.textBtn}
                    onClick={() => setOpenedVisitFilters(!isOpenedVisitFilters)}
                    color="secondary"
                    endIcon={
                        <img
                            className={styles.actionsImg}
                            src={!isOpenedVisitFilters ? arrowSelectIcon : arrowOpenSelectIcon}
                            alt=""
                        />
                    }
                >
                    {t('feed.visit')}
                </Button>
            </div>
            <Filters
                className={classNames(styles.filters, {
                    [styles.filtersVisit]: ['VISITS', 'VISITS_WITH_QUESTIONNAIRES'].includes(kind),
                    [styles.isOpenedFilters]: isOpenedVisitFilters,
                })}
                defaultValues={defaultValues}
                filters={filtersVisit}
            />
            {['CROWD_QUESTIONNAIRES', 'VISITS_WITH_QUESTIONNAIRES'].includes(kind) && (
                <>
                    <div className={styles.actions}>
                        <Button
                            className={styles.textBtn}
                            onClick={() =>
                                setOpenedQuestionnaireFilters(!isOpenedQuestionnaireFilters)
                            }
                            color="secondary"
                            endIcon={
                                <img
                                    className={styles.actionsImg}
                                    src={
                                        !isOpenedQuestionnaireFilters
                                            ? arrowSelectIcon
                                            : arrowOpenSelectIcon
                                    }
                                    alt=""
                                />
                            }
                        >
                            {t('questionnaires.questionnaire')}
                        </Button>
                    </div>
                    <Filters
                        className={classNames(styles.filters, {
                            [styles.isOpenedFilters]: isOpenedQuestionnaireFilters,
                        })}
                        defaultValues={defaultValues}
                        filters={filtersQuestionnaires}
                    />
                </>
            )}
            <div className={styles.reset}>
                <Button className={styles.textBtn} onClick={handleReset} color="secondary">
                    {t('common.reset').toLocaleUpperCase()}
                </Button>
            </div>
        </div>
    );
};

export default ReportsBaseFilters;
