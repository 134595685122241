import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { useAppDispatch } from '@tools/hooks/redux';
import CheckerAction from '@redux/checker/action';
import CheckerSelectors from '@redux/checker/selector';
import { checkFields } from './CheckFields';

const OneAnswer = ({ item, parentId }) => {
    const resultSelect = useSelector(CheckerSelectors.answerSelector(parentId, item?.id));
    const { parent_criteria, parent_id } = item;
    const { setValue, control } = useFormContext();
    const [isShow, setIsShow] = useState(!!parent_criteria?.length);
    const dispatch = useAppDispatch();
    const linkedItem = useSelector(CheckerSelectors.getLinkedChecker(parent_id));
    const linkedVal = linkedItem?.[0]?.answers;

    useEffect(() => {
        if (linkedVal) {
            if (linkedVal === parent_criteria) {
                setIsShow(false);
            } else {
                setIsShow(true);
                dispatch(CheckerAction.deleteFormField(item));
            }
        }
    }, [parent_id, parent_criteria, linkedVal, dispatch, item]);

    const handleChange = useCallback((value) => {
        setValue(item?.id, value);
        dispatch(CheckerAction.updateFormField({ ...item, answers: value }));
    }, []);

    useEffect(() => {
        if (!!resultSelect?.length) {
            setIsShow(false);
        }
    }, [resultSelect?.length]);

    if (isShow) {
        return null;
    }
    return (
        <div className={!!parent_id ? 'py-1.5 ml-5' : 'py-1.5'}>
            <FormLabel>{item?.name}</FormLabel>
            {item?.is_required && <span className="text-red">*</span>}
            <Controller
                name={item?.id}
                control={control}
                defaultValue=""
                rules={{
                    required: item?.is_required && !resultSelect?.length && 'Поле обязательно !',
                }}
                render={({ field, fieldState }) => (
                    <>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            {!!resultSelect?.length
                                ? item?.answers?.map((i) => (
                                      <FormControlLabel
                                          {...field}
                                          key={i}
                                          onChange={() => handleChange(i)}
                                          value={i}
                                          control={
                                              <Radio
                                                  checked={resultSelect?.[0]?.answers === i}
                                                  size="small"
                                              />
                                          }
                                          label={i}
                                          disabled={!!resultSelect?.length}
                                      />
                                  ))
                                : item?.answers?.map((i) => (
                                      <FormControlLabel
                                          {...field}
                                          key={i}
                                          onChange={() => handleChange(i)}
                                          value={i}
                                          control={<Radio size="small" />}
                                          label={i}
                                          disabled={!!resultSelect?.length}
                                      />
                                  ))}
                        </RadioGroup>
                        {item?.is_required && (
                            <div className="text-red">{fieldState?.error?.message}</div>
                        )}
                    </>
                )}
            />

            {!!item?.children?.length && (
                <div className="ml-3">
                    {item?.children?.map((item) => checkFields(item, item?.check_list_id))}
                </div>
            )}
        </div>
    );
};

export default OneAnswer;
