import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './PhotoReportBaseFilters.module.scss';
import { useTranslation } from 'react-i18next';
import { Select, TextField, RangePicker, Filters, Button, Checkbox } from '@components';
import arrowSelectIcon from '@images/svg/arrow-for-select-red.svg';
import arrowOpenSelectIcon from '@images/svg/arrow-for-select-open-red.svg';
import PhotoReportBaseFiltersData from './PhotoReportBaseFiltersData/PhotoReportBaseFiltersData';
import { useRoles } from '@tools/hooks/useRoles';
import { useReset } from '@tools/hooks/useReset';
import { t } from 'i18next';

export const filtersDirHierarchy = [
    {
        type: Checkbox,
        name: 'BRANCH',
        label: t('feed.branch'),
        labelPlacement: 'end',
    },
    {
        type: Checkbox,
        name: 'RETAIL',
        label: t('common.retail'),
        labelPlacement: 'end',
    },
    {
        type: Checkbox,
        name: 'ADDRESS',
        label: t('feed.address'),
        labelPlacement: 'end',
    },
    {
        type: Checkbox,
        name: 'EXTERNAL_CODE',
        label: t('common.outletCode'),
        labelPlacement: 'end',
    },
    {
        type: Checkbox,
        name: 'DATE',
        label: t('common.date'),
        labelPlacement: 'end',
    },
];

export const filtersFilename = filtersDirHierarchy.map((filter) => ({
    ...filter,
    name: `${filter.name}_FN`,
}));

const PhotoReportBaseFilters = ({ defaultValues, form }) => {
    const [isOpenedTTFilters, setOpenedTTFilters] = useState(true);
    const [isOpenedHierarchyFilters, setOpenedHierarchyFilters] = useState(true);
    const [isOpenedQuestionnaireFilters, setOpenedQuestionnaireFilters] = useState(false);
    const [isOpenedFilenameFilters, setOpenedFilenameFilters] = useState(true);
    const [isInitialRequest, setIsInitilalRequest] = useState(true);

    const { clientPermission } = useRoles();

    const { t } = useTranslation('translation');

    const { reset, watch, setValue } = form;
    const currValues = watch();
    const {
        project_id,
        date_range,
        user_id,
        questionnaire_names,
        question_names,
        project_outlet_retails,
        project_outlet_branches,
    } = currValues;

    const {
        projectOptions,
        questionnairesProjectsOptions,
        retailsOptions,
        branchesOptions,
        loadingProjects,
        loadingQuestionnairesProjects,
        retailsLoading,
        branchesLoading,
        projectsListId,
    } = PhotoReportBaseFiltersData(form);

    useEffect(() => {
        Object.keys(defaultValues).forEach((key) => {
            if (key !== 'questionnaire_names') {
                setValue(key, defaultValues[key]);
            }
        });
    }, [defaultValues]);

    useEffect(() => {
        if (questionnairesProjectsOptions?.length && isInitialRequest) {
            Object.keys(defaultValues).forEach((key) => {
                if (key === 'questionnaire_names') {
                    setValue(key, defaultValues[key]);
                }
                setIsInitilalRequest(false);
            });
        }
    }, [questionnairesProjectsOptions]);

    const onChange = (name, e) => {
        setValue(name, e?.value);
    };

    const { setReset } = useReset();
    const handleReset = () => {
        reset({
            ...defaultValues,
            project_id: projectsListId,
            date_range: { start: null, end: null },
        });
        setReset(projectsListId);
    };

    const filtersBaseData = [
        {
            type: Select,
            name: 'project_id',
            label: t('common.project'),
            onChange,
            value: project_id
                ? projectOptions?.find((e) => e.value === project_id)
                : projectOptions?.[0],
            options: projectOptions,
            isLoading: loadingProjects,
        },
        {
            type: RangePicker,
            name: 'date_range',
            label: t('visits.dateRange'),
            placeholder: '',
            dateFormat: 'dd.MM.yyyy',
            valueStart: date_range?.start,
            valueEnd: date_range?.end,
            maxDate: clientPermission ? new Date() : null,
        },
        {
            type: TextField,
            name: 'visit_ids',
            label: t('visits.visitId'),
            placeholder: t('visits.enterVisitId'),
        },
        /* Временно скрыть до доработки на бэке
       {
           type: Select,
           name: 'manager_id',
           label: t('common.manager'),
           placeholder: t('common.all'),
           onChange,
           value: userProjectsOptions?.find((e) => e.value === manager_id),
           options: userProjectsOptions,
           isClearable: true,
           isLoading: userProjLoading,
       },
       {
           type: Select,
           name: 'user_id',
           label: t('common.user'),
           placeholder: t('common.all'),
           onChange,
           value: userProjectsOptions?.find((e) => e.value === user_id),
           options: userProjectsOptions,
           isClearable: true,
           isLoading: userProjLoading,
       },
       */
    ];

    const filtersTTData = [
        {
            type: TextField,
            name: 'project_outlet_external_code',
            label: t('common.outletCode'),
            placeholder: t('common.searchOutletCode'),
        },
        {
            type: Select,
            name: 'project_outlet_retails',
            label: t('common.retail'),
            placeholder: t('common.searchRetail'),
            onChange,
            value: project_outlet_retails
                ? retailsOptions?.filter((e) => project_outlet_retails.includes(e.value))
                : null,
            options: retailsOptions,
            isClearable: true,
            isLoading: retailsLoading,
        },
        {
            type: Select,
            name: 'project_outlet_branches',
            label: t('feed.branch'),
            placeholder: t('common.searchBranch'),
            onChange,
            value: project_outlet_branches
                ? branchesOptions?.filter((e) => project_outlet_branches.includes(e.value))
                : null,
            options: branchesOptions,
            isClearable: true,
            isLoading: branchesLoading,
        },
        {
            type: TextField,
            name: 'project_outlet_additional_projects',
            label: t('common.additionalProjectTT'),
            placeholder: t('common.searchAdditionalProject'),
        },
        {
            type: TextField,
            name: 'project_outlet_ir_outlet_code',
            label: t('reports.reports.additionalCodeIr'),
            placeholder: t('common.searchOutletCode'),
        },
    ];

    const filtersQuestionnaireData = [
        {
            type: Select,
            name: 'questionnaire_names',
            label: t('reports.reports.questionnaireName'),
            placeholder: t('common.all'),
            onChange,
            value: questionnaire_names
                ? questionnairesProjectsOptions?.filter((e) =>
                      questionnaire_names.includes(e.value)
                  )
                : null,
            options: questionnairesProjectsOptions,
            isClearable: true,
            isLoading: loadingQuestionnairesProjects,
        },
        {
            type: TextField,
            name: 'questionnaire_step_titles',
            label: t('reports.reports.stepTitle'),
            placeholder: t('common.search'),
        },
        {
            type: TextField,
            name: 'question_names',
            label: t('reports.reports.question'),
            placeholder: t('common.search'),
        },
    ];

    return (
        <div className={styles.reportsBaseFilters}>
            <Filters
                className={classNames(styles.filters, styles.filtersBase)}
                defaultValues={defaultValues}
                filters={filtersBaseData}
            />
            <div className={styles.actions}>
                <Button
                    className={styles.textBtn}
                    onClick={() => setOpenedTTFilters(!isOpenedTTFilters)}
                    color="secondary"
                    endIcon={
                        <img
                            className={styles.actionsImg}
                            src={!isOpenedTTFilters ? arrowSelectIcon : arrowOpenSelectIcon}
                            alt=""
                        />
                    }
                >
                    {t('common.project_outlet')}
                </Button>
            </div>
            <Filters
                className={classNames(styles.filters, {
                    [styles.isOpenedFilters]: isOpenedTTFilters,
                })}
                defaultValues={defaultValues}
                filters={filtersTTData}
            />
            <div className={styles.actions}>
                <Button
                    className={styles.textBtn}
                    onClick={() => setOpenedQuestionnaireFilters(!isOpenedQuestionnaireFilters)}
                    color="secondary"
                    endIcon={
                        <img
                            className={styles.actionsImg}
                            src={
                                !isOpenedQuestionnaireFilters
                                    ? arrowSelectIcon
                                    : arrowOpenSelectIcon
                            }
                            alt=""
                        />
                    }
                >
                    {t('questionnaires.questionnaire')}
                </Button>
            </div>
            <Filters
                className={classNames(styles.filters, {
                    [styles.isOpenedFilters]: isOpenedQuestionnaireFilters,
                })}
                defaultValues={defaultValues}
                filters={filtersQuestionnaireData}
            />
            <div className={styles.actions}>
                <Button
                    className={styles.textBtn}
                    onClick={() => setOpenedHierarchyFilters(!isOpenedHierarchyFilters)}
                    color="secondary"
                    endIcon={
                        <img
                            className={styles.actionsImg}
                            src={!isOpenedHierarchyFilters ? arrowSelectIcon : arrowOpenSelectIcon}
                            alt=""
                        />
                    }
                >
                    {t('reports.photoReport.foldersHierarchy')}
                </Button>
            </div>
            <Filters
                className={classNames(styles.filters, styles.filtersHierarchy, {
                    [styles.isOpenedFilters]: isOpenedHierarchyFilters,
                })}
                defaultValues={defaultValues}
                filters={filtersDirHierarchy}
            />
            <div className={styles.actions}>
                <Button
                    className={styles.textBtn}
                    onClick={() => setOpenedFilenameFilters(!isOpenedFilenameFilters)}
                    color="secondary"
                    endIcon={
                        <img
                            className={styles.actionsImg}
                            src={!isOpenedFilenameFilters ? arrowSelectIcon : arrowOpenSelectIcon}
                            alt=""
                        />
                    }
                >
                    {t('reports.photoReport.filename')}
                </Button>
            </div>
            <Filters
                className={classNames(styles.filters, styles.filtersHierarchy, {
                    [styles.isOpenedFilters]: isOpenedFilenameFilters,
                })}
                defaultValues={defaultValues}
                filters={filtersFilename}
            />
            <div className={styles.reset}>
                <Button className={styles.textBtn} onClick={handleReset} color="secondary">
                    {t('common.reset').toLocaleUpperCase()}
                </Button>
            </div>
        </div>
    );
};

export default PhotoReportBaseFilters;
