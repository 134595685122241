import React, { useEffect, useMemo, useState } from 'react';
import FlatpickrTypes from 'flatpickr';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import ruLocale from 'date-fns/locale/ru';
import * as moment from 'moment';
import { t } from 'i18next';

export interface DateTimePicker {
    value: string | string[];
    enable?: string[];
    label?: string;
    disabled?: boolean;
    placeholder?: string;
    name?: string;
    className?: string;
    onBlur: () => void;
    onChange: (value: string) => void;
    options?: FlatpickrTypes.Options.Options;
}

export const DateTimePicker: React.FC<DateTimePicker> = ({
    value,
    enable,
    label,
    disabled,
    placeholder = t('messages.selectDateTime'),
    name,
    className,
    onBlur,
    onChange,
    options,
}) => {
    const [valueString, setValueString] = useState<string | undefined | null>(value);
    const { valueDate } = useMemo(
        () => ({
            valueDate: (valueString && new Date(valueString)) || null,
        }),
        [valueString],
    );

    useEffect(() => {
        if (value && value !== valueString) {
            setValueString(value);
        } else if (value === null) {
            setValueString(value);
        }
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    const [showYearPicker, setShowYearPicker] = useState(false);
    const [open, setOpen] = useState(false);

    const openCalendar = () => {
        setOpen(true);
    };

    const handleClickOutside = () => {
        setOpen(false);
        if (value && value !== valueString) {
            setValueString(value);
        } else if (value === null) {
            setValueString(value);
        }
        handleSubmit();
    };

    const handleYearClick = () => {
        setShowYearPicker(true);
    };

    const fixTimezoneOffset = (date: Date): Date | string => {
        if (!date) return '';

        return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    };

    const handleChange = (date: Date | null) => {
        if (showYearPicker) {
            setShowYearPicker(false);
        }
        const momentObj = moment.utc(date, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
        const isoString = momentObj.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        setValueString(date.toISOString());
    };

    const handleSubmit = () => {
        onChange(valueString);
        setOpen(false);
    };
    return (
        <div
            ref={el => {
                const monthLabel = el?.querySelector('.react-datepicker__current-month');
                if (monthLabel) {
                    monthLabel.addEventListener('click', () => {
                        setShowYearPicker(true);
                    });
                }
            }}
            className={cn('date-picker-wrapper', { 'date-picker--open': open }, className)}
        >
            {label}
            <div className={'timePickerWrap'}>
                <DatePicker
                    disabled={disabled}
                    timeIntervals={1}
                    timeCaption="Time"
                    locale={ruLocale}
                    open={open}
                    placeholderText={placeholder}
                    selected={valueDate === null ? valueDate : valueDate}
                    showYearPicker={showYearPicker}
                    onBlur={onBlur}
                    showTimeSelect
                    onCalendarOpen={openCalendar}
                    onChange={handleChange}
                    onClickOutside={handleClickOutside}
                    timeFormat={'HH:mm:ss'}
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    menuPlacement="auto"
                />
            </div>
        </div>
    );
};
