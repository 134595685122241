import React from 'react';

import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { formatters } from 'src/tools/utils/date.util';
import { ReactComponent as ArrowCircleIcon } from '@images/svg/arrow-circle-right.svg';

interface DatePickerHeaderProps extends ReactDatePickerCustomHeaderProps {
    yearPicker?: boolean;
    onYearClick: () => void;
}

export const DatePickerHeader: React.FC<DatePickerHeaderProps> = ({
    date,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    yearPicker,
    onYearClick,
}) => {
    return (
        <div className="date-picker__header">
            <button
                disabled={prevMonthButtonDisabled}
                type="button"
                onClick={() => {
                    if (yearPicker) {
                        decreaseYear();
                    } else {
                        decreaseMonth();
                    }
                }}
            >
                <ArrowCircleIcon className="rotate-180 text-black hover:text-red transition-colors" />
            </button>

            <div className="date-picker__header-text">
                {formatters.toMonthName(date)}
                &nbsp;
                <span className="date-picker__header-text__year" onClick={onYearClick}>
                    {formatters.toYear(date)}
                </span>
            </div>

            <button
                disabled={nextMonthButtonDisabled}
                type="button"
                onClick={() => {
                    if (yearPicker) {
                        increaseYear();
                    } else {
                        increaseMonth();
                    }
                }}
            >
                <ArrowCircleIcon className="text-black hover:text-red transition-colors" />
            </button>
        </div>
    );
};
