import React, { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { TextField, Select, Button, Dialog } from '@components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import QuestionEditDialog from './QuestionEditDialog/QuestionEditDialog';
import QuestionEditDialogActions from './QuestionEditDialog/QuestionEditDialogActions/QuestionEditDialogActions';
import sortIcon from '@images/svg/drag.svg';
import styles from './QuestionsChildren.module.scss';

const QuestionsChildren: FC = ({
    questions,
    registerStr,
    remove,
    idx,
    isMoreOneField,
    defaultValuesStepQuestion,
    questionId,
}) => {
    const [isOpenDialog, setDialog] = useState(false);

    const { control, setValue, clearErrors, formState, getValues } = useFormContext();
    const parent_id = getValues(`${registerStr}.parent_id`);

    const keysArr = registerStr.replaceAll('[', '.').replaceAll(']', '').split('.');
    const errors = keysArr.reduce((acc = {}, item) => acc[item], formState?.errors || {});

    const { t } = useTranslation('translation');

    const optionsKind = [
        {
            label: t('questionnaires.photo'),
            value: 'photo_answer',
        },
        {
            label: t('questionnaires.number'),
            value: 'number_answer',
        },
        {
            label: t('questionnaires.integerAnswer'),
            value: 'integer_answer',
        },
        {
            label: t('questionnaires.text'),
            value: 'your_answer',
        },
        {
            label: t('questionnaires.manyAnswers'),
            value: 'many_answers',
        },
        {
            label: t('questionnaires.oneAnswer'),
            value: 'one_answer',
        },
    ];

    const { listeners, setNodeRef, transform, transition } = useSortable({ id: questionId });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const setCancelChange = () => {
        setValue(registerStr, defaultValuesStepQuestion);
        clearErrors(registerStr);
        setDialog(false);
    };

    return (
        <div className={styles.block} ref={setNodeRef} style={style}>
            <div
                className={classNames(styles.items, styles.itemBorder, {
                    [styles.isSubItem]: parent_id,
                })}
            >
                <div
                    className={classNames(styles.item, styles.order, {
                        [styles.isSortable]: isMoreOneField,
                    })}
                >
                    <Button className={styles.sortBtn} {...listeners}>
                        <img src={sortIcon} alt="" />
                    </Button>
                    <Controller
                        name={`${registerStr}.order`}
                        control={control}
                        render={({ field: { name } }) => (
                            <TextField
                                value={idx + 1}
                                id={name}
                                variant="outlined"
                                disabled={true}
                            />
                        )}
                    />
                </div>
                <div className={classNames(styles.item, styles.content)}>
                    <Controller
                        name={`${registerStr}.name`}
                        control={control}
                        render={({ field: { onChange, name, value }, fieldState: { error } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                                error={error}
                            />
                        )}
                    />
                    {parent_id && (
                        <Controller
                            name={`${registerStr}.parent_criteria`}
                            control={control}
                            render={({
                                field: { onChange, name, value },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    className={styles.parentCriteria}
                                    onChange={onChange}
                                    value={value || ''}
                                    id={name}
                                    variant="outlined"
                                    error={error}
                                />
                            )}
                        />
                    )}
                </div>
                <div className={classNames(styles.item, styles.questions)}>
                    <Controller
                        name={`${registerStr}.kind`}
                        control={control}
                        render={({ field: { name, value, ref }, fieldState: { error } }) => (
                            <Select
                                id={name}
                                name={name}
                                inputRef={ref}
                                onChange={(e) => {
                                    setValue(name, e.value, {
                                        shouldValidate: true,
                                    });
                                }}
                                value={optionsKind?.find((e) => e.value === value)}
                                options={optionsKind}
                                className={styles.select}
                                error={error}
                            />
                        )}
                    />
                </div>
                <div className={classNames(styles.item, styles.actions)}>
                    <Button
                        className={styles.textBtn}
                        onClick={() => setDialog(true)}
                        disabled={!questionId}
                        color="secondary"
                    >
                        {t('buttons.edit').toLocaleUpperCase()}
                    </Button>
                    <Button
                        className={styles.textBtn}
                        onClick={() => remove(idx)}
                        color="secondary"
                    >
                        {t('buttons.delete').toLocaleUpperCase()}
                    </Button>
                </div>
            </div>
            {isOpenDialog && (
                <Dialog
                    id="questionEditDialog"
                    isOpenDialog={isOpenDialog}
                    setCloseDialog={setCancelChange}
                    title={t('questionnaires.editQuestion')}
                    actions={
                        <QuestionEditDialogActions
                            setDialog={setDialog}
                            setCancelChange={setCancelChange}
                            errors={errors}
                        />
                    }
                >
                    <QuestionEditDialog
                        registerStr={registerStr}
                        questions={questions}
                        question={defaultValuesStepQuestion}
                        optionsKind={optionsKind}
                    />
                </Dialog>
            )}
        </div>
    );
};

export default QuestionsChildren;
