import React, { FC } from 'react';
import { TextareaAutosize as TA } from '@mui/material';
import classNames from 'classnames';
import Label from '../Label/Label';
import styles from './TextareaAutosize.module.scss';
import { FormHelperText } from '@mui/material';

export interface ITextareaAutosize {
    id: string;
    label?: string;
    className?: string;
    error?: {
        message: string;
    };
    onChange?: () => void;
    minRows?: number;
}

const TextareaAutosize: FC<ITextareaAutosize> = ({
    id,
    label,
    className,
    onChange,
    error,
    minRows = 1,
    ...props
}) => {
    return (
        <>
            {label && <Label htmlFor={id}>{label}</Label>}
            <TA
                {...props}
                id={id}
                onChange={onChange}
                className={classNames(styles.textareaAutosize, className)}
                minRows={minRows}
            />
             {error && <FormHelperText error>{error.message}</FormHelperText>}
        </>
    );
};

export default TextareaAutosize;
