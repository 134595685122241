import React, { FC, useEffect, useState } from 'react';
import styles from './QuestionnairesInVisit.module.scss';
import { useTranslation } from 'react-i18next';
import { TableGrid, DatePicker } from '@components';
import { fetchProjectOutletsTaskOutletStatus, getProjectOutlet } from '@apiFeature/projectOutlets';
import { useSnackbar } from 'notistack';
import { fetchQuestionnairesList } from '@apiFeature/questionnaires';
import { useAppSelector } from '@tools/hooks/redux';
import ListMapper from '@components/Lists/List/ListMapper/ListMapper';
import { permissionCheck } from '@tools/utils/permissionCheck';
import { ProjectOutlet } from '@types/projectOutlets';
import { TQuestionnaire } from '@apiFeature/questionnaires/type';
import { extractErrorMessage } from '@tools/utils/functions';
import moment from 'moment';
import { TProjectOutletTask } from '@apiFeature/tasks/types';
import { fetchTasksListV2 } from '@apiFeature/tasks/tasks';

interface IQuestionnairesInVisit {
    visit_id?: string;
    route_point_id?: string;
    date?: string;
    project_outlet_id?: string;
}

const QuestionnairesInVisit: FC<IQuestionnairesInVisit> = ({
    visit_id,
    route_point_id,
    date,
    project_outlet_id,
}) => {
    const { t } = useTranslation('translation');

    const [questionnaireList, setQuestionnaireList] = useState<TQuestionnaire[]>([]);
    const [projectOutlet, setProjectOutlet] = useState<ProjectOutlet | null>(null);
    const [dateValue, setDateValue] = useState<string | undefined>(date);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tasks, setTasks] = useState<TProjectOutletTask[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const authRoles = useAppSelector((state) => state?.auth?.jwtPayloadOpen?.roles);
    const { name: outlet_name, external_code, address } = projectOutlet || {};
    const serializedItem = {
        [t('common.outletNameTT')]: {
            value: outlet_name ?? '-',
        },
        [t('common.outletCode')]: {
            value: external_code ?? '-',
        },
        [t('feed.address')]: {
            value: address ?? '-',
        },
    };

    useEffect(() => {
        if (project_outlet_id) {
            setIsLoading(true);
            fetchProjectOutletsTaskOutletStatus({
                kind: 'questionnaire',
                visit_id,
                project_outlet_id,
                date: dateValue,
            })
                .then((res) => {
                    const { tasks } = res || {};
                    if (tasks?.length) {
                        const ids = tasks.map((i) => i.id);
                        fetchQuestionnairesList({
                            ids,
                            is_active: true,
                            kind: 'questionnaire',
                        })
                            .then((res) => {
                                const { questionnaires } = res || {};
                                setQuestionnaireList(questionnaires);
                            })
                            .catch((error) => {
                                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                            });
                    }
                })
                .catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [visit_id, dateValue]);

    useEffect(() => {
        if (project_outlet_id) {
            getProjectOutlet(project_outlet_id)
                .then((res) => {
                    const { project_outlet } = res || {};
                    setProjectOutlet(project_outlet);
                })
                .catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                });
        }
    }, [project_outlet_id]);

    useEffect(() => {
        if (project_outlet_id) {
            fetchTasksListV2({
                project_outlet_id,
                visit_id,
            }).then((res) => {
                const { items } = res || {};
                setTasks(items);
            });
        }
    }, [project_outlet_id, dateValue]);

    const columns = [
        { field: 'name', headerName: t('common.name'), minWidth: 280, flex: 1, sortable: false },
        {
            field: 'external_code',
            headerName: t('catalog.outlets.code'),
            minWidth: 300,
            flex: 1,
            sortable: false,
        },
        {
            field: 'kind',
            headerName: t('feed.type'),
            minWidth: 150,
            sortable: false,
            valueGetter: ({ value }) =>
                value === 'questionnaire' ? t('questionnaires.questionnaire') : t('feed.audit'),
        },
        {
            field: 'version',
            headerName: t('questionnaires.version'),
            minWidth: 60,
            sortable: false,
        },
    ];

    return (
        <div>
            <div className={styles.outletInfo}>
                <div className={styles.item}>
                    <ListMapper
                        items={permissionCheck(authRoles, serializedItem)}
                        className={styles.serializedFeedItem}
                    />
                </div>
                <div className={styles.dateValue}>
                    <DatePicker
                        name="date"
                        onChange={(e) => {
                            setDateValue(moment(e).format('YYYY-MM-DD'));
                        }}
                        label={t('common.date')}
                        value={date}
                        dateFormat="dd.MM.yyyy"
                        disabled={isLoading}
                    />
                </div>
            </div>
            <TableGrid
                rows={questionnaireList}
                columns={columns}
                loading={isLoading}
                disableRowSelectionOnClick
            />
            <div className={styles.tasks}>
                {tasks.map((task, index) => {
                    return (
                        <div key={index} className={styles.taskItem}>
                            {`${t('tasks.taskItem')} ${index + 1}`}: {task?.description}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default QuestionnairesInVisit;
