import React from 'react';
import { getYear } from 'date-fns';
import { formatters } from 'src/tools/utils/date.util';
import { Select, SelectProps } from 'src/view/components/ui/form/Select/Select';

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const getMonthsList = (year = new Date()): Date[] => {
    return Array.from({ length: 12 }).map((_, index) => {
        const month = (index + 1).toString().padStart(2, '0');
        return new Date(`${getYear(year)}-${month}-01`);
    });
};

const MONTHS_OPTIONS = getMonthsList().map((date) => ({
    value: formatters.toISODateString(date),
    label: capitalizeFirstLetter(formatters.toMonthName(date)),
}));

const currentMonth = capitalizeFirstLetter(formatters.toMonthName(new Date()));
export const INITIAL_MONTH_OPTION = MONTHS_OPTIONS.find(({ label }) => label === currentMonth);

type MonthSelectProps = Omit<SelectProps, 'options'>;

export const MonthSelect: React.FC<MonthSelectProps> = ({ ...props }) => {
    return <Select {...props} options={MONTHS_OPTIONS} />;
};
