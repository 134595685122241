import { createSlice } from '@reduxjs/toolkit';
import { reduxStoreName } from './types';
import { FeedsThunks } from './thunk';
import FeedsActions from './actions';

const initialState = {
    loading: false,
    error: false,
    errorMessage: '',
    currentFeed: {},
    deleteLoading: false,
    deleteErr: false,
    deleteErrMsg: '',
    isEdit: false,
    editFields: null,
    photos: [],
    updateLoading: false,
    updateErr: false,
    updateErrMsg: '',
    successUpdate: false,
    branchList: [],
    branchLoading: false,
    branchError: false,
};

const slice = createSlice({
    name: reduxStoreName,
    initialState: {
        ...initialState,
    },
    reducers: {
        reset() {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(FeedsThunks.fetchCurrentFeed.pending, (state) => {
                state.loading = true;
                state.error = false;
                state.errorMessage = '';
            })
            .addCase(FeedsThunks.fetchCurrentFeed.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload?.message ?? 'Неизвестная ошибка';
            })
            .addCase(FeedsThunks.fetchCurrentFeed.fulfilled, (state, { payload }) => {
                state.currentFeed = payload?.feed || {};
                state.loading = false;
                state.error = false;
                state.errorMessage = '';
            })
            .addCase(FeedsThunks.fetchDeleteFeed.pending, (state) => {
                state.deleteLoading = true;
                state.deleteErr = false;
                state.errorMessage = '';
            })
            .addCase(FeedsThunks.fetchDeleteFeed.fulfilled, (state) => {
                state.deleteLoading = false;
                state.deleteErr = false;
                state.errorMessage = '';
            })
            .addCase(FeedsThunks.fetchDeleteFeed.rejected, (state, { payload }) => {
                state.deleteLoading = false;
                state.deleteErr = true;
                state.errorMessage = payload?.message ?? 'Неизвестная ошибка';
            })
            .addCase(FeedsThunks.fetchUpdateFeed.pending, (state) => {
                state.updateLoading = true;
            })
            .addCase(FeedsThunks.fetchUpdateFeed.fulfilled, (state, { payload }) => {
                state.updateLoading = false;
                state.updateErr = false;
                state.updateErrMsg = '';
                state.successUpdate = true;
                state.currentFeed.comment = payload?.visit?.comment;
                state.currentFeed.status = payload?.visit?.status;
            })
            .addCase(FeedsThunks.fetchUpdateFeed.rejected, (state, { payload }) => {
                state.updateLoading = false;
                state.updateErr = true;
                state.successUpdate = false;
                state.updateErrMsg = payload?.message ?? 'Неизвестная ошибка';
            });
        builder
            .addCase(FeedsActions.editMode, (state, { payload }) => {
                state.isEdit = payload;
            })
            .addCase(FeedsActions.saveEditFields, (state, { payload }) => {
                state.editFields = payload;
            })
            .addCase(FeedsActions.savePhotos, (state, { payload }) => {
                state.photos?.push(payload);
            })
            .addCase(FeedsActions.cancelEdit, (state) => {
                state.photos = [];
                state.isEdit = false;
                state.editFields = null;
            });
        builder
            .addCase(FeedsThunks.fetchBranches.pending, (state) => {
                state.loading = true;
            })
            .addCase(FeedsThunks.fetchBranches.rejected, (state, { payload }) => {
                state.branchError = true;
                state.errorMessage = payload?.message ?? 'Неизвестная ошибка';
                state.loading = false;
            })
            .addCase(FeedsThunks.fetchBranches.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.branchError = false;
                state.branchList = payload?.branches;
            });
    },
});

export const reducer = slice.reducer;
