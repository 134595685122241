import { BaseTypeEntity, TFileLink } from '../types';
import { t } from 'i18next';

export type VisitCreateRequest = {
    route_point_id?: string;
    start_point_lon?: number | null;
    start_point_lat?: number | null;
    start_time: string;
    start_distance: number;
    is_start_fake_gps: boolean;
    is_end_fake_gps?: boolean;
    is_fake_photo?: boolean;
    is_restored?: boolean;
    is_ended_in_place?: boolean;
    comment?: string;
    user_id: string;
};
export enum VisitStatus {
    in_progress = 'in_progress',
    completed = 'completed',
    problem_reported = 'problem_reported',
    unperformed = 'unperformed',
};

export const visitStatusNames = {
    in_progress: t('statuses.inProgress'),
    completed: t('statuses.done'),
    problem_reported: t('reports.reports.new'),
    unperformed:  t('reports.reports.unperformed'),
} as const;

export const visitTypeNames = {
    visit: t('feed.visit'),
    audit: t('feed.audit'),
    audit_manager: t('feed.auditManager'),
} as const;

export type TVisit = BaseTypeEntity & {
    user_id: string;
    created_by: string;
    route_point_id?: string;
    updated_by?: string | null;
    is_end_fake_gps: boolean;
    is_ended_in_place: boolean;
    is_fake_photo: boolean;
    is_restored: boolean;
    is_start_fake_gps: boolean;
    end_distance: number | null;
    end_point_lat: number | null;
    end_point_lon: number | null;
    end_time?: string | null;
    result?: string | null;
    file_links?: TFileLink[] | null;
    start_distance?: number | null;
    start_point_lat?: number | null;
    start_point_lon?: number | null;
    start_time?: string | null;
    status: VisitStatus | null;
    comment?: string | null;
    deleted_at?: string | null;
};

export type VisitCreateResponse = { visit: TVisit };

export type VisitsHistoryRequest = {
    project_outlet_id?: string | null;
    status?: VisitStatus | null;
    pagination?: {
        limit?: number;
        offset?: number;
    }
};

export type VisitsHistoryItem = {
    id?: string;
    created_at?: string;
    updated_at?: string;
    utc_offset?: string;
    user?: {
        id?: string;
        created_at?: string;
        updated_at?: string;
        utc_offset: string;
        first_name: string;
        last_name: string;
        patronymic_name: string;
        email: string;
        phone: string;
        snils: string;
        date_of_birth: string;
    },
    user_id?: string;
    visitDate?: string;
}

export type VisitsHistoryResponse = {
    visits: {
        items: VisitsHistoryItem[];
    };
    total: number;
    limit: number;
    offset: number;
}
