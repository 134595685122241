import React, { FC, useEffect, useState } from 'react';

import dispenser from 'src/assets/images/svg/dispenser.svg';
import drop from 'src/assets/images/svg/drop.svg';
import person from 'src/assets/images/svg/person.svg';
import pram from 'src/assets/images/svg/pram.svg';
import razor from 'src/assets/images/svg/razor.svg';
import scissors from 'src/assets/images/svg/scissors.svg';
import tooth from 'src/assets/images/svg/tooth.svg';
import tshirt from 'src/assets/images/svg/tshirt.svg';
import bookIcon from 'src/assets/images/svg/book.svg';
import { Button } from 'src/view/components/ui/Button/Button';
import { useRoles } from '@tools/hooks/useRoles';
import styles from './Cart.module.scss';
import { useTranslation } from 'react-i18next';
import { PhotoSlider } from '@components';

interface CartProps {
    name: string;
    project: string;
    images: string[];
    onClick: () => void;
    id?: string;
    setDeletedPlanogram: (id: string) => void;
    setOpenDeleteConfirm: () => void;
    type?: string;
}

const iconsObj = {
    book: bookIcon,
    fem_hygiene: dispenser,
    fabric_care: tshirt,
    child_hygiene: pram,
    body_care: person,
    teeth_care: tooth,
    shaving: razor,
    cleaning: drop,
    hair_care: scissors,
};

export const Cart: FC<CartProps> = ({
    name,
    project,
    images,
    onClick,
    id,
    setDeletedPlanogram,
    setOpenDeleteConfirm,
    type,
}) => {
    const { clientPermission } = useRoles();
    const { t } = useTranslation('translation');
    const [photos, setPhotos] = useState([]);

    const selectDeleted = (id) => {
        setDeletedPlanogram(id);
        setOpenDeleteConfirm(true);
    };

    useEffect(() => {
        setPhotos(images);
    }, [images]);

    return (
        <div className={styles.cart}>
            <img alt="icon" className={styles.cart__icon} src={type ? iconsObj[type] : ''} />
            <div className={styles.cart__content}>
                <p className={styles.cart__title}>{name}</p>
                <div className={styles.cart__projectType}>
                    <p>{t('common.project')}</p>
                    <p>{project}</p>
                </div>
                {photos?.length > 0 && (
                    <div className={styles.slider}>
                        <PhotoSlider
                            id={id}
                            photos={photos}
                            type="carousel"
                            hasDeleteBtn={!clientPermission}
                        />
                    </div>
                )}
                <hr />
                <div className={styles.buttons}>
                    <Button onClick={onClick}>{t('buttons.view')}</Button>
                    <Button
                        onClick={() => {
                            selectDeleted(id);
                        }}
                    >
                        {t('buttons.delete')}
                    </Button>
                </div>
            </div>
        </div>
    );
};
