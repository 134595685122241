import React, { FC, useMemo, useState } from 'react';

import classNames from 'classnames';
import arrowIcon from 'src/assets/images/svg/red-arrow-for-selectors.svg';

import styles from './Sort.module.scss';

export interface TitleSort {
    id: string | number;
    name: string;
}

export interface SortProps {
    value: string | number;
    setValue: (arg: number | string) => void;
    list: TitleSort[];
    className?: string;
}

export const Sort: FC<SortProps> = ({
    value, list, className, setValue,
}) => {
    const [isSort, setIsSort] = useState<boolean>(false);
    const current = useMemo(() => list.find((el) => el.id === value), [value]);

    const newList: TitleSort[] = list.filter((el) => el.id !== value);

    return (
        <div
            className={classNames(styles.sort, className || '')}
            onClick={() => setIsSort(!isSort)}
        >
            <p>{current?.name}</p>
            <img alt='arrow/icon' src={arrowIcon} />
            {isSort && (
                <ul>
                    {newList.map((el) => <li key={el.id} onClick={() => setValue(el.id)}>{el.name}</li>)}
                </ul>
            )}
        </div>
    );
};
