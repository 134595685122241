import React, { useEffect, useState } from "react";
import styles from './InstantMerch.module.scss';
import InstantMerchItem from "./InstantMerchItem/InstantMerchItem";
import { TextField, Checkbox, Filters, Loader } from '@components';
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from 'react-hook-form';
import classNames from "classnames";
import moment from "moment";
import { fetchEventsList } from "@apiFeature/stockInsights";
import { useSnackbar } from "notistack";
import { extractErrorMessage } from '@tools/utils/functions';
import { stockInsightsList } from '@apiFeature/stockInsights';
import { SkuWithEvents, StockInsightItem } from "@apiFeature/stockInsights/types";

const InstantMerch = ({ feedItem, visit_id }) => {
    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(false);
    const [stockInsights, setStockInsights] = useState<StockInsightItem>([]);
    const [skus, setSkus] = useState<SkuWithEvents[]>([])
    const [filteredSkus, setFilteredSkus] = useState<SkuWithEvents[]>([])
    let defaultValues: Record<string, any> = {
        price_change: false,
        not_on_shelf: false,
        virtual_stock: false,
        promo: false,
        product_name: '',
        code: ''
    };
    const form = useForm({ defaultValues });
    const { watch, reset } = form;

    const handleReset = () => {
        reset({
            ...defaultValues,
        });
    };

    const {
        price_change,
        product_name,
        code,
        not_on_shelf,
        virtual_stock,
        promo,
    } = watch();
    const { start_time, project_outlets } = feedItem || {};
    const [projectOutlet] = project_outlets || [];
    const date_lower = moment(start_time).utc().startOf('day').toISOString();
    const date_upper = moment(start_time).utc().endOf('day').toISOString();
    const { id: projectOutletId } = projectOutlet || {};

    const baseFilters = [
        {
            type: TextField,
            name: 'product_name',
            label: t('instantMerch.productName'),
            placeholder: t('common.enterName'),
        },
        {
            type: TextField,
            name: 'code',
            label: t('instantMerch.code'),
            placeholder: t('instantMerch.enterCode'),
        },
    ];

    const checkBoxFilters = [
        {
            type: Checkbox,
            name: 'price_change',
            label: t('instantMerch.priceChanged'),
            labelPlacement: 'end',
        },
        {
            type: Checkbox,
            name: 'promo',
            label: t('instantMerch.promoEngaged'),
            labelPlacement: 'end',
        },
        {
            type: Checkbox,
            name: 'virtual_stock',
            label: t('alert.virtualStock'),
            labelPlacement: 'end',
        },
        {
            type: Checkbox,
            name: 'not_on_shelf',
            label: t('instantMerch.notOnShelf'),
            labelPlacement: 'end',
        },
    ];

    const addStockInsightToSkus = (stockInsights, skus) => {
        if (stockInsights?.length > 0) {
            const skusWithStockInsights = skus.map((sku) => {
                const currentStockInsight = stockInsights.find((stockInsight) => stockInsight.sku_id === sku.id);
                if (currentStockInsight) {
                    return {
                        ...sku,
                        stockInsight: currentStockInsight
                    }
                } else {
                    return sku
                }
            });
            return skusWithStockInsights
        } else {
            return skus
        }
    }

    useEffect(() => {
        if (visit_id && date_lower && date_upper && projectOutletId) {
            setLoading(true);
            fetchEventsList({
                date_lower,
                date_upper,
                visit_id,
                project_outlet_ids: [projectOutletId],
            }).then((eventsListResult) => {
                const { project_outlets } = eventsListResult || {};
                const [projectOutlet] = project_outlets || [];
                const { skus } = projectOutlet || {};
                stockInsightsList({
                    visit_ids: [visit_id],
                }).then((res) => {
                    const { stock_insights } = res || {};
                    const { items } = stock_insights || {};
                    if (items) {
                        setStockInsights(items);
                    };
                    if (skus) {
                        const skusWithStockInsights = addStockInsightToSkus(items, skus)
                        setSkus(skusWithStockInsights);
                    };
                }).catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                });
            }).catch((error) => {
                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
            }).finally(() => {
                setLoading(false);
            });
        };

    }, [visit_id]);

    useEffect(() => {
            let filtered = addStockInsightToSkus(stockInsights, skus);
            if (price_change) {
                filtered = filtered.filter((sku) =>
                    sku.events.some((e) => e.event_type_name === 'price_check')
                );
            }
            if (promo) {
                filtered = filtered.filter((sku) => sku.sku_info.promo === 'TRUE');
            }
            if (product_name) {
                filtered = filtered.filter((sku) =>
                    sku.name.toLowerCase().includes(product_name.toLowerCase())
                );
            }
            if (code) {
                filtered = filtered.filter((sku) => sku.article.includes(code));
            }
            if (not_on_shelf) {
                filtered = filtered.filter((sku) => sku?.stockInsight?.error_type === t('instantMerch.notOnShelf'));
            }
            if (virtual_stock) {
                filtered = filtered.filter((sku) => sku?.stockInsight?.error_type === t('alert.virtualStock'));
            }
            setFilteredSkus(filtered);

    }, [
        price_change,
        promo,
        product_name,
        code,
        not_on_shelf,
        virtual_stock,
        skus,
    ]);

    return (
        <div>
            <FormProvider  {...form}>
                <Filters
                    className={styles.baseFilters}
                    defaultValues={defaultValues}
                    filters={baseFilters}
                    isHeader
                    handleReset={handleReset}
                    form={form}
                />
                <Filters
                    className={classNames(styles.baseFilters, styles.checkboxFilters)}
                    defaultValues={defaultValues}
                    filters={checkBoxFilters}
                    handleReset={handleReset}
                    form={form}
                />
                {loading && <Loader />}
                {filteredSkus?.length > 0 &&
                    filteredSkus.map((sku) => {
                        return (
                            <InstantMerchItem
                                key={sku.id}
                                stockInsight={sku?.stockInsight}
                                sku={sku}
                            />
                        )
                    })}
            </FormProvider>
        </div>
    )
};

export default InstantMerch;