import React, { useCallback, useEffect, useState } from 'react';
import styles from './ImportsJournalData.module.scss';
import { t } from 'i18next';
import { TableGrid, Button } from '@components';
import { useSelector } from 'react-redux';
import { formatters } from '@tools/utils/date.util';
import { utcOffsetInHours, convertLocalToUtc } from '@tools/utils/utcOffsetConvert';
import moment from 'moment';
import { importFileKindNames, importFileStatusNames } from '@apiFeature/importProcesses/types';
import { importFileProcessesList } from '@apiFeature/importProcesses';
import { useSnackbar } from 'notistack';
import ProjectsSelectors from '@redux/projects/selectors';
import { bytesToSize } from '@tools/utils/functions';
import downloadFileIcon from '@images/svg/download-file.svg';
import { useNavigate } from 'react-router-dom';
import { User } from '@apiFeature/types';
import { getUserById } from '@apiFeature/user';
import { Book } from '@navigation/Book';
import BarChart from '../BarChart/BarChart';

const limit = 10;
const currentUtc = utcOffsetInHours();

const columns = [
    {
        field: 'kind',
        headerName: t('feed.type'),
        minWidth: 150,
        valueGetter: ({ value }) => importFileKindNames[value],
        sortable: false,
    },
    {
        field: 'name',
        headerName: t('common.name'),
        minWidth: 280,
        valueGetter: (params) => params?.row?.file_links?.[0]?.file?.name || '',
        sortable: false,
    },
    {
        field: 'created_at',
        headerName: t('importJournal.dateAndTime'),
        minWidth: 135,
        headerClassName: styles.wrapHeader,
        renderCell: (params) =>
            params.row?.file_links &&
            params.row?.file_links.length && (
                <p>
                    {formatters.toISODateFormatString(
                        params?.row?.file_links?.[0]?.file?.created_at
                    )}{' '}
                    {moment(convertLocalToUtc(params?.row?.file_links?.[0]?.file?.created_at))
                        .local()
                        .format('HH:mm')}{' '}
                    UTC{currentUtc > 0 ? '+' : '-'}
                    {currentUtc}
                </p>
            ),
        sortable: false,
    },
    {
        field: 'progress',
        headerName: t('importJournal.progress'),
        width: 180,
        renderCell: (params) => (
            <div className={styles.barWrap}>
                <BarChart importProcessId={params.row.id} />
            </div>
        ),
        sortable: false,
    },
    {
        field: 'status',
        headerName: t('common.status'),
        width: 120,
        valueGetter: ({ value }) => importFileStatusNames[value],
        sortable: false,
    },
    {
        field: 'file_links',
        headerName: t('importJournal.fileToDownload'),
        minWidth: 100,
        headerClassName: styles.wrapHeader,
        sortable: false,
        renderCell: ({ value: file_links }) => (
            <div style={{ textAlign: 'center', width: '100%' }}>
                {file_links && file_links?.length > 0
                    ? file_links.map((item) => (
                        <div
                            key={item?.file?.id}
                            onClick={(e) => e.stopPropagation()}
                            title={item?.file?.name}
                        >
                            <Button href={item?.file?.url}>
                                <img src={downloadFileIcon} alt="" />
                            </Button>
                        </div>
                    ))
                    : '-'}
            </div>
        ),
    },
    {
        field: 'user',
        headerName: t('common.initiator'),
        width: 270,
        renderCell: (params) => {
            const { phone, user } = params.row || {};
            return (
                (user || phone) && (
                    <div>
                        {user && <p>{user}</p>}
                        {phone && <p>{phone}</p>}
                    </div>
                )
            );
        },
        sortable: false,
    },
    {
        field: 'error',
        headerName: t('messages.error'),
        width: 300,
        renderCell: (params) =>
            params?.row?.error_data?.common?.length && <div>{params.row.error_data.common[0]}</div>,
        sortable: false,
    },
    {
        field: 'size',
        headerName: t('common.size'),
        minWidth: 90,
        valueGetter: (params) =>
            params?.row?.file_links && params?.row?.file_links?.length
                ? bytesToSize(params.row?.file_links[0]?.file.size_bytes)
                : '',
        sortable: false,
    },
];

const ImportsJournalData = ({ defaultValues, filters, importedFile }) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const paginationModelDefault = { page: 0, pageSize: limit };
    const { projectsList } = useSelector(ProjectsSelectors.projectsSelectors);
    const [paginationModel, setPaginationModel] = useState(paginationModelDefault);
    const [sortModel, setSortModel] = useState({ created_at: 'desc' });
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any | null>(null);
    const { items = [], total = 0, offset } = data || {};
    const [userList, setUserList] = useState<User[]>([]);
    const { project_id: pId, kind, status } = filters || {};

    const getImportFileProcessesList = useCallback(
        async (offset = 0, sortModel) => {
            setLoading(true);
            const [project] = projectsList || [];
            const { id } = project || {};
            const { project: projectId } = defaultValues || {};
            const project_id = projectsList.find((p) => p.id === projectId)?.id ?? id;
            importFileProcessesList({
                project_id,
                ...filters,
                sorting: sortModel,
                pagination: {
                    limit,
                    offset,
                },
            })
                .then((res) => {
                    const fileItems = res?.import_file_processes?.items || [];
                    const receivedUsers = fileItems
                        .filter((file) => file?.file_links.length)
                        .map((file) => file?.file_links?.[0]?.created_by);
                    const uniqueUserIds = new Set(receivedUsers);
                    const usersPromise = Promise.all(
                        Array.from(uniqueUserIds).map((userId) => {
                            const existingUser = userList.find((user) => user?.user?.id === userId);

                            if (!existingUser) {
                                return getUserById(userId).then((newUser) => {
                                    setUserList((prevUserList) => [...prevUserList, newUser]);
                                    return newUser;
                                });
                            }

                            return Promise.resolve(existingUser);
                        })
                    );
                    usersPromise.then((users) => {
                        const resultItems = fileItems.map((file) => {
                            const currentUser = users.find(
                                (user) => user?.user?.id === file?.file_links?.[0]?.created_by
                            );
                            const { last_name, first_name, patronymic_name, phone } =
                                currentUser?.user || {};
                            return {
                                ...file,
                                user: `${last_name} ${first_name} ${patronymic_name}`,
                                phone,
                            };
                        });
                        setData({
                            ...res.import_file_processes,
                            items: resultItems,
                        });
                    });
                    setLoading(false);
                })
                .catch((error) => {
                    const errorMessage =
                        error?.message || error?.data?.detail || t('messages.unknownError');
                    enqueueSnackbar(errorMessage, { variant: 'error' });
                    setLoading(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [filters, projectsList, importedFile]
    );

    useEffect(() => {
        if (!isLoading && projectsList?.length > 0) {
            getImportFileProcessesList(paginationModel?.page * limit, sortModel);
        }
    }, [pId, kind, status, paginationModel, projectsList, importedFile]);

    useEffect(() => {
        if (filters && Object.keys(filters)?.length > 0) {
            setPaginationModel(paginationModel);
        }
    }, [pId, kind, status]);

    useEffect(() => {
        setPaginationModel(paginationModelDefault);
    }, [sortModel]);

    const onSortModelChange = () => {
        setSortModel((prevState) => ({
            ...prevState,
            created_at: prevState?.created_at === 'asc' ? 'desc' : 'asc',
        }));
    };

    const sortName = `${t('importJournal.uploadDate')}: ${sortModel?.created_at === 'asc' ? t('common.sortAsc') : t('common.sortDesc')
        }`;

    const openRowsDetail = (id, item) => {
        navigate(`${Book.import_journal}/${id}`, {
            state: {
                fileName: item?.file_links?.[0]?.file?.name,
                importItem: item,
            },
        });
    };

    return (
        <>
            <TableGrid
                rows={items}
                columns={columns}
                rowCount={total}
                loading={isLoading}
                pageSizeOptions={[limit]}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                paginationModel={offset === 0 ? paginationModelDefault : paginationModel}
                rowHeight={80}
                hasSun
                getRowHeight={() => 'auto'}
                isRowSelectable={({ id, row }) => openRowsDetail(id, row)}
                onSortModelChange={onSortModelChange}
                sort={sortModel?.created_at}
                sortName={sortName}
                className={styles.tableGrid}
            />
        </>
    );
};

export default ImportsJournalData;
