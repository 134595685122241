import { t } from "i18next";
import { ProjectOutletType } from "../projectOutlets/types"; 
import { User } from "../user/types";

export const crowdTaskStatusNames = {
    PENDING: t('statuses.pending'),
    IN_PROGRESS: t('statuses.inProgress'),
    DONE: t('statuses.done'),
    CANT_DO: t('statuses.cantDo'),
} as const;

export type crowdTaskStatuses = keyof typeof crowdTaskStatusNames;

export type TaskComplexityItem = {
    id: string;
    created_at: string;
    updated_at: string;
    created_by: string;
    updated_by: string;
    name: string;
    min_rating: number;
    max_rating: number;
    reserve_time: string;
};

export interface TaskItem {
    id: string;
    created_at: string;
    updated_at: string;
    created_by: string;
    updated_by: string;
    task_type_id: string;
    task_complexity_id: string;
    questionnaire_id: string;
    project_outlet_long: number;
    project_outlet_lat: number;
    start_date: string;
    end_date: string;
    timing: number;
    description: string;
    price: number;
    order: number;
    is_photo_required: boolean;
    deadline?: string;
    is_required?: boolean;
    utc_offset?: number;
    project_outlet: {
        id: string;
        longitude: number;
        latitude: number;
        name: string;
        address: string;
        square: number;
        outlet_type: string;
    };
};

export interface FileLink {
    id: string;
    created_at: string;
    updated_at: string;
    created_by: string;
    file_id: string;
    generic_id: string;
    generic_type: string;
    is_preview: boolean;
    file: {
        id: string;
        created_at: string;
        updated_at: string;
        bucket: string;
        key: string;
        name: string;
        content_type: string;
        size_bytes: number;
        checksum_md5: string;
        url: string;
    };
};

export interface TaskResultItem {
    file_links: FileLink[];
    id: string;
    created_at: string;
    updated_at: string;
    created_by: string;
    updated_by: string;
    deleted_at: string;
    task_reserve_id: string;
    user_id: string;
    task_fail_id: string;
    start_point_lon: number;
    start_point_lat: number;
    start_time: string;
    start_distance: number;
    end_point_lon: number;
    end_point_lat: number;
    end_time: string;
    end_distance: number;
    is_ended_in_place: boolean;
    is_start_fake_gps: boolean;
    status: string;
    verification_time_type: string;
    result: Record<string, any>;
};

export interface CrowdFeedRequest {
    utc_offset: number;
    ids: string[];
    task_reserve_ids: string[];
    user_id: string;
    task_fail_id: string;
    verification_time_type: string;
    is_ended_in_place: boolean;
    status: string;
    start_time_lower: string;
    start_time_upper: string;
    end_time_lower: string;
    end_time_upper: string;
    project_id: string;
    city_id: string;
    branch_id: string;
    task_type_id: string;
    task_complexity_id: string;
    pagination: {
        limit: number;
        offset: number;
    };
};

export interface CrowdFeedItem {
    id: string;
    created_at: string;
    updated_at: string;
    created_by: string;
    updated_by: string;
    deleted_at: string;
    project_outlet_id: string;
    task_reserve_id: string;
    user_id: string;
    task_fail_id: string;
    start_point_lon: number;
    start_point_lat: number;
    start_time: string;
    start_distance: number;
    end_point_lon: number;
    end_point_lat: number;
    end_time: string;
    end_distance: number;
    is_ended_in_place: boolean;
    is_start_fake_gps: boolean;
    status: string;
    verification_time_type: string;
    result: Record<string, any>;
    user: User;
    project_outlet: ProjectOutletType;
    task_type_id: string;
    task_complexity_id: string;
    timing: number;
    price: number;
};

export interface CrowdFeedResponse {
    feeds: {
        items: CrowdFeedItem[];
        total: number;
        limit: number;
        offset: number;
    };
};

export interface CrowdFeedFilterRequest {
    project_id: string;
    task_type_id: string;
    start_date: string;
    end_date: string;
    pagination: {
        limit: number;
        offset: number
    };
  }