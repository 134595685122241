import React from 'react';

import { FormField } from './ComplexForm.type';

interface FieldWrapperProps {
    children: Exclude<React.ReactNode, undefined>;
    field: FormField;
}

export const FieldWrapper: React.FC<FieldWrapperProps> = ({ field, children }) => {
    return <div className={field.wrapperClassName}>{children}</div>;
};
