import React from 'react';
import styles from './TeamHeader.module.scss';
import { Button } from '@components';
import { useTranslation } from 'react-i18next';
import { Book } from '@navigation/Book';
import { useNavigate } from 'react-router-dom';

const TeamHeader = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();

    return (
        <div className={styles.renderHeader}>
            <Button
                variant="outlined"
                onClick={() => {
                    navigate(Book.catalog.team_create);
                }}
            >
                {t('catalog.team.createUser')}
            </Button>
        </div>
    );
};

export default TeamHeader;
