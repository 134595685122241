import React, { FC, ReactNode, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DndProvider } from 'react-dnd';
import {
    Tree,
    MultiBackend,
    getBackendOptions,
    NodeModel,
    TreeMethods,
} from '@minoru/react-dnd-treeview';
import { NavLink } from 'react-router-dom';
import { Button } from '@components';
import { Tooltip, useMediaQuery } from '@mui/material';
import { Book } from '@navigation/Book';
import { useAppDispatch, useAppSelector } from '@tools/hooks/redux';
import { useTranslation } from 'react-i18next';
import { useRoles } from '@tools/hooks/useRoles';
import AuthActions from '@redux/auth/actions';
import { AuthThunks } from '@redux/auth/thunk';
import AppSelector from '@redux/app/selector';
import SettingsSelectors from '@redux/settings/selectors';
import AppAction from '@redux/app/action';
import Logo from '@images/logo512.png';
import { ReactComponent as ArrowIcon } from '@images/svg/chevron-down.svg';
import {
    MenuOpen,
    CollectionsRounded,
    AssignmentTurnedInRounded,
    CalendarMonthRounded,
    AssignmentRounded,
    CollectionsOutlined,
    AssignmentTurnedInOutlined,
    AssignmentOutlined,
    BusinessCenterRounded,
    HomeRounded,
    AutoAwesomeMotionRounded,
    SupervisorAccountRounded,
    AccountBoxRounded,
    AutoStoriesRounded,
    LiveHelpRounded,
    AssessmentRounded,
    CameraAltRounded,
    GetAppRounded,
    ContactSupportRounded,
    ExitToAppRounded,
    SettingsApplicationsRounded,
} from '@mui/icons-material';
import styles from './MenuBlock.module.scss';

type treeData = { link?: string; hidden?: boolean; icon?: ReactNode } & NodeModel;

const MenuBlock: FC = () => {
    const ref = useRef<TreeMethods>(null);
    const dispatch = useAppDispatch();
    const { t } = useTranslation('translation');
    const isMenu = useAppSelector(AppSelector.menuSelector);
    const s = useAppSelector((s) => s?.auth);
    const { last_name, first_name, patronymic_name, phone } = s?.user || {};

    const { settingsAll, settingsAllUser } = useAppSelector(SettingsSelectors.settingsState);
    const { settings } = settingsAll || {};
    const isEnableTaskWebClient =
        settings?.find((s) => s.code === 'is_enable_task_web_client')?.setting_values?.[0]?.val ===
        'true';
    const visitingScheduleWeb = settingsAllUser?.find(
        (s) => s.code === 'web_visiting_schedule_permission'
    )?.setting_value;
    const isVisitingScheduleWeb = ['READ', 'CREATE'].includes(visitingScheduleWeb);

    const notMobile = useMediaQuery('(min-width:768px)');

    useEffect(() => {
        if (!notMobile && isMenu) {
            handleToggleMenu();
        }
    }, [notMobile]);

    useEffect(() => {
        if (!isMenu) {
            ref.current?.openAll();
        }
    }, [isMenu]);

    useEffect(() => {
        if (!s?.user) {
            const id = s?.jwtPayloadOpen?.sub;
            dispatch(AuthThunks.fetchUserInfo(id));
        }
    }, [s?.user]);

    const {
        isAdmin,
        isVisitReport,
        isQuestionnaireReport,
        isClient,
        isCrowd,
        isPhotoReport,
        isChecker,
        isCheckManager,
        isHeadCheckManager,
        isUserCatalogEnable,
        isAnalyst,
    } = useRoles();

    const isCrowdFeedHidden = !(isClient && isCrowd) && !isAdmin;
    const isCheckerCrowd = !(
        isAdmin ||
        (isCrowd && isCheckManager) ||
        (isCrowd && isHeadCheckManager)
    );
    const isCrowdHidden = !isAdmin;
    const isCrowdGroupHidden = isCrowdFeedHidden && isCheckerCrowd && isCrowdHidden;

    const treeData: treeData[] = [
        {
            id: 1,
            parent: 0,
            text: t('feed.title'),
            link: Book.visit_feed,
            icon: <CollectionsRounded />,
        },
        {
            id: 2,
            parent: 0,
            text: t('feed.checkerTitle'),
            link: Book.visit_check,
            hidden: !isChecker && !isAdmin,
            icon: <AssignmentTurnedInRounded />,
        },
        {
            id: 3,
            parent: 0,
            text: t('visits.title'),
            link: Book.visit_schedule,
            hidden: isClient && !isVisitingScheduleWeb,
            icon: <CalendarMonthRounded />,
        },
        {
            id: 4,
            parent: 0,
            text: t('tasks.title'),
            link: Book.tasks,
            hidden: isClient && !isEnableTaskWebClient,
            icon: <AssignmentRounded />,
        },
        {
            id: 5,
            parent: 0,
            text: t('common.crowd'),
            hidden: isCrowdGroupHidden,
            droppable: true,
        },
        {
            id: 6,
            parent: 5,
            text: t('crowd.crowdFeed'),
            link: Book.crowd_feed,
            hidden: isCrowdFeedHidden,
            icon: <CollectionsOutlined />,
        },
        {
            id: 7,
            parent: 5,
            text: t('crowd.checkerCrowd'),
            link: Book.crowd_check,
            hidden: isCheckerCrowd,
            icon: <AssignmentTurnedInOutlined />,
        },
        {
            id: 8,
            parent: 5,
            text: t('crowd.title'),
            link: Book.crowd.crowd,
            hidden: isCrowdHidden,
            icon: <AssignmentOutlined />,
        },
        {
            id: 9,
            parent: 0,
            text: t('common.catalog'),
            hidden: isClient && !(isAnalyst || isAdmin) && !isUserCatalogEnable && !isAdmin,
            droppable: true,
        },
        {
            id: 10,
            parent: 9,
            text: t('common.projects'),
            link: Book.catalog.projects,
            hidden: !(isAnalyst || isAdmin),
            icon: <BusinessCenterRounded />,
        },
        {
            id: 11,
            parent: 9,
            text: t('common.project_outlets'),
            link: Book.catalog.outlets,
            hidden: isClient,
            icon: <HomeRounded />,
        },
        {
            id: 12,
            parent: 9,
            text: t('address_program.title'),
            link: Book.address_program,
            hidden: isClient,
            icon: <AutoAwesomeMotionRounded />,
        },
        {
            id: 13,
            parent: 9,
            text: t('catalog.team.title'),
            link: Book.catalog.team,
            hidden: isClient,
            icon: <SupervisorAccountRounded />,
        },
        {
            id: 14,
            parent: 9,
            text: t('catalog.users.users'),
            link: Book.catalog.users,
            hidden: !isUserCatalogEnable && !isAdmin,
            icon: <AccountBoxRounded />,
        },
        {
            id: 15,
            parent: 9,
            text: t('catalog.planograms.planograms'),
            link: Book.catalog.planograms,
            hidden: isClient,
            icon: <AutoStoriesRounded />,
        },
        {
            id: 16,
            parent: 9,
            text: t('questionnaires.title'),
            link: Book.questionnaires.questionnaires,
            hidden: isClient,
            icon: <LiveHelpRounded />,
        },
        {
            id: 17,
            parent: 0,
            text: t('reports.reports.title'),
            hidden: isClient && !isVisitReport && !isQuestionnaireReport && !isPhotoReport,
            droppable: true,
        },
        {
            id: 18,
            parent: 17,
            text: t('reports.reports.title'),
            link: Book.reports.reports,
            hidden: isClient && !isVisitReport && !isQuestionnaireReport,
            icon: <AssessmentRounded />,
        },
        {
            id: 19,
            parent: 17,
            text: t('reports.photoReport.shortTitle'),
            link: Book.reports.photo_report,
            hidden: isClient && !isPhotoReport,
            icon: <CameraAltRounded />,
        },
        {
            id: 20,
            parent: 0,
            text: t('routes.title'),
            link: Book.routes,
            hidden: true,
        },
        {
            id: 21,
            parent: 0,
            text: t('importJournal.title'),
            link: Book.import_journal,
            hidden: isClient,
            icon: <GetAppRounded />,
        },
        {
            id: 22,
            parent: 0,
            text: t('common.settings'),
            link: Book.settings,
            hidden: !isAdmin,
            icon: <SettingsApplicationsRounded />,
        },
        {
            id: 23,
            parent: 0,
            text: t('supportRequests.title'),
            link: Book.support_requests,
            hidden: !isAdmin,
            icon: <ContactSupportRounded />,
        },
    ];

    const handleLink = () => {
        if (!notMobile && isMenu) handleToggleMenu();
    };

    const handleToggleMenu = () => {
        dispatch(AppAction.menuToggle());
        localStorage.setItem('isMenu', String(!isMenu));
    };

    const titleMenu = `${isMenu ? t('common.close') : t('common.open')} ${t(
        'common.menu'
    ).toLowerCase()}`;

    const titleExit = t('buttons.exit');

    return (
        <div
            className={classNames(styles.menuBlock, {
                [styles.isMenu]: isMenu,
                isMenu: isMenu,
            })}
        >
            <div className={styles.user}>
                <div className={styles.logo}>
                    <img src={Logo} alt="logo" />
                </div>
                <div className={styles.userInfo}>
                    <div>
                        {last_name} {first_name} {patronymic_name}
                    </div>
                    <div>{phone}</div>
                </div>
                <Tooltip title={titleMenu || ''} placement="right" arrow>
                    <div className={styles.toggleMenu}>
                        <Button
                            onClick={handleToggleMenu}
                            className={styles.toggleMenuBtn}
                            variant="outlined"
                        >
                            <MenuOpen />
                        </Button>
                    </div>
                </Tooltip>
            </div>
            <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                <Tree
                    ref={ref}
                    tree={treeData}
                    rootId={0}
                    render={(node, { depth, isOpen, onToggle }) => {
                        const { droppable, text, link, icon, hidden } = node as treeData;
                        return !hidden ? (
                            <div
                                className={classNames(styles.treeItem, {
                                    [styles.droppable]: droppable,
                                    [styles.isOpen]: isOpen,
                                })}
                                style={{ marginInlineStart: !isMenu ? 0 : depth * 20 }}
                                onClick={droppable ? onToggle : () => {}}
                            >
                                {link ? (
                                    <Tooltip title={!isMenu ? text : ''} placement="right" arrow>
                                        <NavLink
                                            className={styles.item}
                                            to={link}
                                            onClick={handleLink}
                                        >
                                            <span className={styles.text}>{text}</span>
                                            <span className={styles.icon}>{icon}</span>
                                        </NavLink>
                                    </Tooltip>
                                ) : (
                                    isMenu && (
                                        <div className={styles.item}>
                                            {text}
                                            <span className={styles.expandIconWrapper}>
                                                <ArrowIcon />
                                            </span>
                                        </div>
                                    )
                                )}
                            </div>
                        ) : (
                            <></>
                        );
                    }}
                    onDrop={() => {}}
                    canDrag={() => false}
                    canDrop={() => false}
                    sort={false}
                    insertDroppableFirst={false}
                    enableAnimateExpand={true}
                    classes={{
                        root: styles.treeRoot,
                    }}
                />
            </DndProvider>
            <div className={styles.footer}>
                {!isClient && <div className={styles.support}>Техподдержка +7 (800) 551-11-51</div>}
                <Tooltip title={!isMenu ? titleExit : ''} placement="right" arrow>
                    <div className={styles.exit}>
                        <Button
                            className={styles.exitBtn}
                            variant="outlined"
                            onClick={() => dispatch(AuthActions.logout())}
                        >
                            <div className={styles.text}>{titleExit}</div>
                            <div className={styles.icon}>
                                <ExitToAppRounded />
                            </div>
                        </Button>
                    </div>
                </Tooltip>
                <div className={styles.version}>v0.0.53</div>
            </div>
        </div>
    );
};

export default MenuBlock;
