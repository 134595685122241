import React from 'react';

function PramIcon({ color = '#414152' }) {
    return (
        <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5553 6.95833C16.5553 9.525 14.5997 11.5417 12.1108 11.5417V2.375C14.5997 2.375 16.5553 4.39167 16.5553 6.95833Z" stroke={color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.1108 2.37516V1.4585" stroke={color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.44466 22.542C10.181 22.542 10.778 21.9264 10.778 21.167C10.778 20.4076 10.181 19.792 9.44466 19.792C8.70828 19.792 8.11133 20.4076 8.11133 21.167C8.11133 21.9264 8.70828 22.542 9.44466 22.542Z" stroke={color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3.33333 22.542C4.06971 22.542 4.66667 21.9264 4.66667 21.167C4.66667 20.4076 4.06971 19.792 3.33333 19.792C2.59695 19.792 2 20.4076 2 21.167C2 21.9264 2.59695 22.542 3.33333 22.542Z" stroke={color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.77767 17.042C9.711 17.042 12.111 14.567 12.111 11.542H1.44434C1.44434 14.567 3.84434 17.042 6.77767 17.042Z" stroke={color} stroke-width="2" stroke-miterlimit="10" stroke-linejoin="round" />
        </svg>

    );
}

export default PramIcon;