import { clientFileServicePassword, clientPassword } from "../ClientPassword";

export const uploadPlanogramImage = (request) => {
    const fd = new FormData();
    const { object_id, file,} = request;
    fd.append('file', file);
    fd.append('object_id', object_id);
    fd.append('type', "sfa_planogram");
    return clientFileServicePassword.post(
        '/api/v1/files/upload/',
        fd,
        {
            headers: {
                'Content-Type': `multipart/form-data`,
            },
        }
    );
};

export const deletePlanogram = (id: string) => {
    return clientPassword.delete(`/planograms/${id}/`)
}

export const createPlanogramRequest = (request) => {
    return clientPassword.post('/planograms/', request)
}

export const fetchPlanogramsList = (request) => {
    return clientPassword.post('/planograms/list/', request)
}

export const editPlanogramRequest = (planogram_id, request) => {
    return clientPassword.patch(`/planograms/${planogram_id}/`, request)
}