import { ErrorApi } from '../../apiFeature/types';
import { t } from 'i18next';

export const importFileKind = {
    QAPO: 'QAPO',
    QAPO_V2: 'QAPO_v2',
    QUESTIONNAIRES: 'QUESTIONNAIRES',
    ROUTES: 'ROUTES',
    PLANOGRAMS: 'PLANOGRAMS',
    PROJECT_OUTLETS: 'PROJECT_OUTLETS',
    USER_PROJECTS: 'USER_PROJECTS',
    UPDATE_PROJECT_OUTLETS: 'UPDATE_PROJECT_OUTLETS',
    USERS: 'USERS',
    CROWD_TASKS: 'CROWD_TASKS',
    CHECKERS: 'CHECKERS',
    AP: 'AP',
    CROWD_PAYMENTS: 'CROWD_PAYMENTS',
} as const;

export const importFileKindNames = {
    QAPO: t('importJournal.importTypes.QAPO'),
    QAPO_V2: t('importJournal.importTypes.QAPO_V2'),
    QUESTIONNAIRES: t('importJournal.importTypes.QUESTIONNAIRES'),
    ROUTES: t('importJournal.importTypes.ROUTES'),
    ROUTES_V2: t('importJournal.importTypes.ROUTES_V2'),
    PLANOGRAMS: t('importJournal.importTypes.PLANOGRAMS'),
    PROJECT_OUTLETS: t('importJournal.importTypes.PROJECT_OUTLETS'),
    USER_PROJECTS: t('importJournal.importTypes.USER_PROJECTS'),
    USERS: t('importJournal.importTypes.USERS'),
    UPDATE_PROJECT_OUTLETS: t('importJournal.importTypes.UPDATE_PROJECT_OUTLETS'),
    CROWD_TASKS: t('importJournal.importTypes.CROWD_TASKS'),
    CHECKERS: t('importJournal.importTypes.CHECKERS'),
    AP: t('importJournal.importTypes.AP'),
    CROWD_PAYMENTS: t('importJournal.importTypes.CROWD_PAYMENTS'),
} as const;

export const importFileStatus = {
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    ERROR: 'ERROR',
    DONE_PARTIALLY: 'DONE_PARTIALLY',
} as const;

export const importFileStatusNames = {
    PENDING: t('statuses.pending'),
    IN_PROGRESS: t('statuses.inProgress'),
    DONE: t('statuses.done'),
    ERROR: t('messages.error'),
    DONE_PARTIALLY: t('statuses.problemReported'),
} as const;

export const importRowStatusNames = {
    PENDING: t('statuses.pending'),
    IN_PROGRESS: t('statuses.inProgress'),
    DONE: t('statuses.done'),
    ERROR: t('messages.error'),
} as const;

export const importRowStatus = {
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    ERROR: 'ERROR',
} as const;

export type ImportFileStatusType = keyof typeof importFileStatus;
export type ImportFileKindType = keyof typeof importFileKind;
export type ImportRowStatusType = keyof typeof importRowStatus;

export type ParseErrors = {
    reason?: string | null;
    field?: string | null;
};

export type ErrorDataDetail = {
    id?: string | null;
    detail?: string | null;
};

export type ErrorData = {
    parse_errors?: ParseErrors[] | null;
    errors?: ErrorDataDetail[] | null;
};

export type ImportFileProcessesRequest = {
    status?: ImportFileStatusType | null;
    kind?: ImportFileKindType | null;
    project_id?: string | null;
    pagination?: {
        limit?: number | null;
        offset?: number | null;
    };
    sorting?: {};
};

export type ImportRowProcessesRequest = {
    import_file_process_id?: string | null;
    status?: ImportRowStatusType | null;
    line_numbers?: number[] | string[] | null;
    line_number_lower?: number | null;
    line_number_upper?: number | null;
    pagination?: {
        limit?: number | null;
        offset?: number | null;
    };
    sorting?: {
        line_number?: string;
    };
};

export type ImportRowProcessesItem = {
    id?: string;
    created_at?: string;
    updated_at?: string;
    import_file_process_id?: string;
    line_number?: number | null;
    status?: ImportRowStatusType;
    parsed_data: {};
    parsed_data_schema_ver: number | null;
    error_data?: ErrorData | null;
    error_data_schema_ver: number | null;
    result_data: {};
    result_data_schema_ver: number | null;
};

export type ImportRowProcessesResponse = {
    import_row_processes: {
        items: ImportRowProcessesItem[];
    };
};

export type ImportProcessesFile = {
    id: string;
    created_at: string;
    updated_at: string;
    bucket: string;
    key: string;
    name: string;
    content_type: string;
    size_bytes: number;
    checksum_md5: string;
    url: string;
};

export type FileLinksItem = {
    id?: string;
    created_at?: string;
    updated_at?: string;
    created_by?: string;
    file_id?: string;
    generic_id?: string;
    generic_type?: string;
    file?: ImportProcessesFile;
};

export type ImportFileProcessesItem = {
    file_links: FileLinksItem[];
    id?: string;
    created_at?: string;
    updated_at?: string;
    kind?: ImportFileKindType;
    status?: ImportFileStatusType;
    error_data?: ErrorData;
    extra_data?: {};
};

export type ImportFileProcessesResponse = {
    import_file_processes: {
        items: ImportFileProcessesItem[];
    };
};

export type ImportFileRequest = {
    project_id: string | null;
    kind: ImportFileKindType | null;
    file: File | null;
};

export const ImportFileStatusNamesConst = {
    PENDING: 'Ожидает обработки',
    IN_PROGRESS: 'В процессе',
    DONE: 'Успешно завершено',
    ERROR: 'Ошибка',
    DONE_PARTIALLY: 'Завершено с ошибками',
} as const;

export type ImportProgressResponse = {
    total_row_count: number;
    successful_row_count: number;
    error_row_count: number;
    in_progress_row_count: number;
};
