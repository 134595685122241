export const Book = Object.freeze({
    home: '/',
    login: '/login',
    visit_feed: '/visit-feed',
    visit_feed_details: '/visit_feed/:visitID',
    visit_schedule: '/visit-schedule',
    visit_check: '/visit-check',
    visit_check_details: '/visit-check/:visitID',
    feeds_task_create: '/feed-task/create/:visitId',
    tasks: '/tasks',
    tasks_create: '/tasks/create',
    tasks_export: '/task/export',
    tasks_restore: '/task/restore/:taskId',
    questionnaires: {
        questionnaires: '/questionnaires',
        edit: '/questionnaires/edit/:pollId/:projectId/*',
        create: '/questionnaires/create/',
    },
    routes: '/routes',
    routes_outlet: '/route/:outletId',
    address_program: '/address-program',
    address_program_details: '/address-program/:id/*',
    catalog: {
        outlets: '/catalog/outlets',
        outlet_details: '/catalog/outlets/:outletId',
        projects: '/catalog/projects',
        project_details: '/catalog/projects/:projectId',
        project_create: '/catalog/projects/create',
        team: '/catalog/team',
        team_details: '/catalog/team/:userId',
        team_create: '/catalog/team/create',
        planograms: '/catalog/planograms',
        users: '/users',
        user_details: '/users/:userId',
    },
    reports: {
        reports: '/reports/reports',
        photo_report: '/reports/photo-report',
        report_item: '/reports/:exportId',
    },
    import_journal: '/import-journal',
    import_journal_details: '/import-journal/:importId',
    policy: '/policies',
    crowd: {
        crowd: '/crowd',
        edit: '/crowd/edit/:crowdId/*',
        create: '/crowd/create/*',
        watch: '/crowd/watch/:crowdId/*',
    },
    crowd_feed: '/crowd-feed',
    crowd_feed_details: '/crowd-feed/:taskId',
    crowd_check: '/crowd-check',
    crowd_check_details: '/crowd-check/:taskId',
    get_photos_by_question_result_id: '/get_photos_by_question_result_id/:questionResultId',
    get_photos_by_visit_id: '/get_photos_by_visit_id/:visitId',
    restoredVisit: {
        new: '/restored-visit',
        copy: '/restored-visit/copy/:visitId'
    },
    banned: '/access-denied',
    health: '/health',
    support_requests: '/support-requests',
    settings: '/settings',
});
