import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import Qs from 'qs';
import { ErrorApi } from './types';
import { API_TIMEOUT, BASE_URL } from '../constants/api';
import {
    AUTH_URL,
    ACCESS_EXPIRES,
    ACCESS_TOKEN,
    REFRESH_EXPIRES,
    REFRESH_TOKEN,
    urls,
} from '../api/api.constant';

const configUrl = BASE_URL;

const configAuthUrl = AUTH_URL;

export default abstract class BaseClient {
    protected readonly instance: AxiosInstance;

    protected constructor(
        baseURL: string,
        arrayFormat?: 'indices' | 'brackets' | 'repeat' | 'comma'
    ) {
        this.instance = axios.create({
            baseURL: baseURL,
            timeout: API_TIMEOUT,
            paramsSerializer: (params) =>
                Qs.stringify(params, { arrayFormat: arrayFormat ?? 'repeat' }),
        });
    }

    public get<T = any, B = {}>(url: string, params?: B, config?: {}): Promise<T> {
        return this.instance.get<T>(url, { params, ...config }).then((r: AxiosResponse<T>) => {
            return r?.data;
        });
    }

    public post<T = any, B = {}>(url: string, data?: B, config?: {}): Promise<T> {
        if (data && Object.keys(data)?.length) {
            Object.keys(data)?.forEach((key) => {
                if (key === 'is_preview') return;
                if (data[key] === null || data[key] === '' || data[key] === 'null') {
                    delete data[key];
                }
            });
        }
        return this.instance.post<T>(url, data, config).then((r: AxiosResponse<T>) => {
            return r?.data;
        });
    }

    public patch<T = any, B = {}>(url: string, data?: B, config?: {}): Promise<T> {
        return this.instance.patch<T>(url, data, config).then((r: AxiosResponse<T>) => {
            return r?.data;
        });
    }

    public put<T = any, B = {}>(url: string, data?: B, config?: {}): Promise<T> {
        return this.instance.put<T>(url, data, config).then((r: AxiosResponse<T>) => {
            return r?.data;
        });
    }

    public delete<T = any, B = {}>(url: string, params?: B): Promise<T> {
        return this.instance.delete<T>(url, params).then((r: AxiosResponse<T>) => {
            return r?.data;
        });
    }

    protected getAnswerString = (data: any): string => {
        const message = data?.message;
        const errors = data?.errors;
        if (data?.detail) {
            return data.detail;
        }

        if (data?.error) {
            return data.error;
        }

        if (errors && Array.isArray(errors)) {
            return errors.map((e) => JSON.stringify(e)).join(', ');
        }

        return message
            ? Object.keys(message)
                .map((key) => {
                    const value = message[key];
                    return Array.isArray(value) ? value.join(', ') : value;
                })
                .join(', ')
            : 'Неизвестная ошибка';
    };

    protected errorAdapter = (error: any): ErrorApi => {
        if (
            typeof error === 'object' &&
            error !== null &&
            !Array.isArray(error) &&
            error.response
        ) {
            const status = error.response?.status ?? 0;
            if (error.response.data) {
                return {
                    error: 1,
                    data: error.response.data,
                    message: this.getAnswerString(error.response.data),
                    error_description: error.response.data?.error_description,
                    status,
                };
            } else {
                return {
                    error: 2,
                    data: null,
                    message: `Error status ${error.response.status}`,
                    status,
                };
            }
        } else {
            return {
                error: 100,
                data: null,
                status: 500,
                message: 'Network error',
            };
        }
    };
}
class ClientOpen extends BaseClient {
    constructor(baseURL: string) {
        super(baseURL, 'brackets');
        this._initializeInterceptors();
    }

    private _initializeInterceptors = () => {
        this.instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
            return config;
        });

        this.instance.interceptors.response.use(
            (response: AxiosResponse) => {
                const isAuthUrl = [urls.auth.login, urls.auth.refresh, urls.auth.loginBySms].includes(response.config.url ?? '/');
                if (isAuthUrl && response.status === 200) {
                    localStorage.setItem(ACCESS_TOKEN, response.data.tokens.access.token);
                    localStorage.setItem(ACCESS_EXPIRES, response.data.tokens.access.expires_at);
                    localStorage.setItem(REFRESH_TOKEN, response.data.tokens.refresh.token);
                    localStorage.setItem(REFRESH_EXPIRES, response.data.tokens.refresh.expires_at);
                }
                return response;
            },
            (error) => {
                return Promise.reject(this.errorAdapter(error));
            }
        );
    };
}
export const clientOpen = new ClientOpen(configUrl);

export const clientAuth2Open = new ClientOpen(configAuthUrl);
