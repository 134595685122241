import { FileLink } from 'src/types/files';
import { BaseUserData } from 'src/types/user';

export enum VisitStatus {
    New = 'new',
    InProgress = 'in_progress',
    Completed = 'completed',
    ProblemReported = 'problem_reported',
    Unperformed = 'unperformed',
}

export interface Visit {
    file_links: FileLink[];
    id: string;
    created_at: string;
    updated_at: string;
    created_by: string;
    updated_by: string;
    deleted_at: string;
    user_id: string;
    route_point_id: string;
    start_point_lon: number;
    start_point_lat: number;
    start_time: string;
    start_distance: number;
    end_point_lon: number;
    end_point_lat: number;
    end_time: string;
    end_distance: number;
    is_start_fake_gps: true;
    is_end_fake_gps: true;
    is_fake_photo: true;
    is_restored: true;
    is_ended_in_place: true;
    status: VisitStatus;
    result: Record<string, unknown>;
    comment: string;
    user: BaseUserData;
    visit_fail_result: string | null;
}
