import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loader } from '@components';
import { CheckerThunk } from '@redux/checker/thunk';
import { useSelector } from 'react-redux';
import CheckerSelectors from '@redux/checker/selector';
import { useAppDispatch } from '@tools/hooks/redux';
import { Visit } from '@types/visits';
import { TaskItem, TaskResultItem } from '@apiFeature/crowd/types';
import CheckersForms from './checkersForm';
import './checkers.style.scss';
import { fetchVisitFeeds } from '@api/visitFeed/visitFeed.api';
import { useSnackbar } from 'notistack';
import { extractErrorMessage } from '@tools/utils/functions';
import moment from 'moment';
import { VisitFeedItem } from '@types/visitFeed';
import { CheckHistoryItem } from './CheckHistoryItem/CheckHistoryItem';
import SettingsSelectors from '@redux/settings/selectors';

interface CheckListProps {
    visit?: Visit;
    task?: TaskItem;
    taskResult?: TaskResultItem;
}

const CheckList: FC<CheckListProps> = ({ visit, task, taskResult }) => {
    const { loading, orderedData } = useSelector(CheckerSelectors.checkerSelectors);

    const dispatch = useAppDispatch();
    const { projID, visitID, taskId } = useParams();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [visitsInOutlet, setVisitsInOutlet] = useState<VisitFeedItem[]>([]);
    const [projectOutlet] = visit?.project_outlets || [];
    const { start_time } = visit || {};
    const { external_code, project_id: prIdFromVisit } = projectOutlet || {};
    const { settingsAll, settingsAllUser: userSettings, loading: settingsLoading, settingsAllLoaded, settingsAllUserLoaded } = useSelector(SettingsSelectors.settingsState);
    const { settings } = settingsAll || {};
    const visit_check_history = userSettings?.find((s) => s.code === 'visit_check_history')
        ?.setting_value === 'TRUE';
    const visit_check_history_days_past = settings?.find((s) => s.code === 'visit_check_history_days_past')?.setting_values?.[0]
        ?.val || 1;
    const date_to = moment(start_time).format('YYYY-MM-DD');
    const date_from = moment(start_time).subtract(visit_check_history_days_past, 'days').format('YYYY-MM-DD');

    const session_project_id = sessionStorage.getItem('project_id');
    const resultProjectId = prIdFromVisit || session_project_id || projID || null;

    useEffect(() => {
        (async () => {
            if (!!visitID?.length) {
                dispatch(
                    CheckerThunk.fetchResult({
                        object_id: visitID,
                    })
                );
            }
            if (!!taskId?.length) {
                dispatch(
                    CheckerThunk.fetchResult({
                        object_id: taskId,
                    })
                );
            }
        })();
    }, [dispatch, visitID]);

    useEffect(() => {
        if (resultProjectId && !taskId) {
            dispatch(CheckerThunk.fetchCheckerList({ project_id: resultProjectId }));
        } else if (taskId) {
            dispatch(
                CheckerThunk.fetchCheckerList({
                    source: 'crowd',
                })
            );
        }
    }, [dispatch, resultProjectId]);

    useEffect(() => {
        if (external_code && !settingsLoading && settingsAllLoaded && settingsAllUserLoaded && resultProjectId) {
            fetchVisitFeeds({
                date_from,
                date_to,
                external_code,
                project_ids: [resultProjectId],
                visit_type: "visit",
                statuses: ["completed"],
                sorting: {
                    start_time: "desc"
                }
            }).then((res) => {
                const { items } = res || {};
                if (items) {
                    setVisitsInOutlet(items);
                }
            }).catch((error) => {
                enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
            })

        }
    }, [external_code, settings, settingsLoading, settingsAllUserLoaded, settingsAllLoaded, resultProjectId]);

    return (
        <div
            className={
                orderedData?.length > 0 ? 'checkList' : 'checkList flex items-center justify-center'
            }
        >
            {loading ? (
                <Loader />
            ) : (
                <>
                    {orderedData?.length > 0 ? (
                        <>
                            <h2>{t('feed.checkerTitle')}</h2>
                            <div className="last:border-b-0">
                                {orderedData?.map((i) => (
                                    <div
                                        key={i?.id}
                                        className="mb-3 border-solid border-b border-slate-300"
                                    >
                                        <CheckersForms
                                            visit={visit}
                                            fieldItem={i}
                                            task={task}
                                            taskResult={taskResult}
                                        />
                                    </div>
                                ))}
                            </div>
                            {!visit_check_history && visitsInOutlet?.length > 0 &&
                                <>
                                    <h3 className='mb-2'>{t('feed.checkHistory')}</h3>
                                    {visitsInOutlet.sort((a, b) => a.start_time - b.start_time).map(visit => {
                                        return (
                                            <CheckHistoryItem key={visit?.id} visit={visit} />
                                        )
                                    })}
                                </>
                            }
                        </>
                    ) : (
                        <h2>{t('messages.checkListNotFound')}</h2>
                    )}
                </>
            )}
        </div>
    );
};

export default CheckList;
