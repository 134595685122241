import { clientImportServicePassword } from '../ClientPassword';
import {
    ImportFileProcessesRequest,
    ImportFileProcessesResponse,
    ImportFileRequest,
    ImportRowProcessesRequest,
    ImportRowProcessesResponse,
} from './types';

export const importFileProcessesList = (request: ImportFileProcessesRequest) => {
    return clientImportServicePassword.post<
        ImportFileProcessesResponse,
        ImportFileProcessesRequest
    >('/v1/import_file_processes/list/', request);
};

export const importFile = (request: ImportFileRequest) => {
    const { project_id, kind, file } = request;
    const formData = new FormData();
    formData.append('project_id', project_id);
    formData.append('kind', kind);
    formData.append('file', file);
    return clientImportServicePassword.post<ImportFileProcessesResponse, FormData>(
        '/v1/import_file_processes/import/',
        formData,
        {
            headers: {
                'Content-Type': `multipart/form-data`,
            },
        }
    );
};

export const importRowProcessesList = (request: ImportRowProcessesRequest) => {
    return clientImportServicePassword.post<ImportRowProcessesResponse, ImportRowProcessesRequest>(
        '/v1/import_row_processes/list/',
        request
    );
};

export const downloadImportTemplate = (kind) => {
    return clientImportServicePassword.get(
        `/v1/import_file_processes/templates/`,
        { kind },
        {
            responseType: 'blob'
        }
    );
}

export const getImportProcessesProgress = (id: string) => {
    return clientImportServicePassword.get(`/v1/import_file_processes/${id}/stats/`)
}

export const getImportProcessItemById = (id: string) => {
    return clientImportServicePassword.get(`/v1/import_file_processes/${id}/`)
}
