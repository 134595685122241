import React, { useCallback } from 'react';

import cn from 'classnames';
import BaseSelect, { SingleValue } from 'react-select';
import { InputOption } from 'src/types/common';

import s from './Select.module.scss';
import { useTranslation } from 'react-i18next';

export interface SelectProps {
    label?: string;
    name: string;
    className?: string;
    placeholder?: string;
    value?: InputOption;
    options?: InputOption[];
    disabled?: boolean;
    onChange?: (option?: InputOption | null) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    error?: boolean;
}

export const Select: React.FC<SelectProps> = ({
    label,
    name,
    placeholder,
    className,
    value,
    options,
    disabled,
    onChange,
    onBlur,
    error,
}) => {
    const handleChange = useCallback(
        (v: SingleValue<InputOption>) => {
            onChange?.(v);
        },
        [onChange],
    );
    const { t } = useTranslation('translation');

    return (
        <label className={cn(s.label, className)}>
            {label}
            <BaseSelect
                className={`${s.select} table-select`}
                classNamePrefix="r-select"
                isDisabled={disabled}
                name={name}
                options={options}
                placeholder={placeholder}
                value={value}
                onBlur={onBlur}
                menuPlacement="auto"
                onChange={handleChange}
            />
             {error && <div className={s.errorMessage}>{t('messages.fieldRequired')}</div>}
        </label>
    );
};
