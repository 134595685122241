import React from 'react';

interface MapValues {
    [k: string]: any;
}

type FuncIsSame = (values: MapValues) => boolean;
export type TUseCompareValues = () => { isSame: FuncIsSame };

export const useCompareValues: TUseCompareValues = () => {
    const prevValues = React.useRef<MapValues>();

    const isSame = React.useCallback<FuncIsSame>((values) => {
        const prev = prevValues.current;
        const compare = (() => {
            if (prev) {
                for (const [k, v] of Object.entries(values)) {
                    if (prev[k] !== undefined && JSON.stringify(prev[k]) !== JSON.stringify(v)) {
                        return false;
                    }
                }
                return true;
            }
            return false;
        })();
        prevValues.current = values;
        return compare;
    }, []);

    return { isSame };
};
