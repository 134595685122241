import React, { FC, Component, ChangeEvent } from 'react';
import classNames from 'classnames';
import { Title, Button } from '@components';
import styles from './Filters.module.scss';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { SettingsThunks } from '@redux/settings/thunk';
import { useURLFilters } from '@tools/hooks/useURLFilters';
import { useReset } from '@tools/hooks/useReset';

export interface IFilter {
    className?: string | undefined;
    type: typeof Component;
    name: string;
    label?: string;
    onChange?: (name: string, e: ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    options?: [];
    isLoading?: boolean;
}

interface IFilters {
    className?: string | undefined;
    defaultValues?: { project?: string; project_id?: string };
    filters?: IFilter[];
    form?: {};
    handleReset?: () => void;
    isHeader?: boolean | undefined;
}

const Filters: FC<IFilters> = ({
    className,
    defaultValues = {},
    filters,
    form,
    handleReset,
    isHeader,
}) => {
    const { t } = useTranslation('translation');

    const { control, reset } = useFormContext();
    const dispatch = useDispatch();
    const { setURLFilters, resetURLFilters } = useURLFilters();

    const handleChangeForm = (name, e, onChangeItem, onChange) => {
        onChangeItem ? onChangeItem(name, e) : onChange(e);

        if ((name === 'project' || name === 'project_id') && e?.value) {
            const project_id = e?.value;
            sessionStorage.setItem('project_id', project_id);
            sessionStorage.setItem('project_label', e?.label);
            dispatch(SettingsThunks.getAllSettings({ project_ids: [project_id] }));
            dispatch(SettingsThunks.getAllUserSettings({ project_id }));
        }
        if (name === 'date_range' && e?.start && e?.end) {
            sessionStorage.setItem('start_date', e?.start);
            sessionStorage.setItem('end_date', e?.end);
        }
        setURLFilters(e, name);
    };

    const { setReset } = useReset();
    const handleResetForm = () => {
        handleReset ? handleReset() : reset();

        const { watch } = form || {};
        const { project, project_id } = watch();
        if (
            ('project' in defaultValues || 'project_id' in defaultValues) &&
            (project || project_id)
        )
            setReset(project || project_id);

        resetURLFilters();
    };

    return (
        <div className={classNames(styles.filters, className)}>
            <div className={styles.form}>
                {isHeader && (
                    <div className={styles.header}>
                        <Title type="h2">{t('common.filters')}</Title>
                        <Button
                            className={styles.textBtn}
                            onClick={handleResetForm}
                            color="secondary"
                        >
                            {t('common.reset').toLocaleUpperCase()}
                        </Button>
                    </div>
                )}
                <div className={classNames(styles.items, 'items')}>
                    {filters?.map(
                        (
                            {
                                type,
                                name,
                                value: valueProps,
                                className,
                                onChange: onChangeProps,
                                ...item
                            },
                            i
                        ) => {
                            const Component = type;
                            return (
                                <div
                                    key={name || i}
                                    className={classNames(styles.item, className, 'item')}
                                >
                                    {Component && (
                                        <Controller
                                            name={name}
                                            control={control}
                                            render={({ field: { name, value, ref, onChange } }) => (
                                                <Component
                                                    {...item}
                                                    id={name}
                                                    name={name}
                                                    inputRef={ref}
                                                    onChange={(e) =>
                                                        handleChangeForm(
                                                            name,
                                                            e,
                                                            onChangeProps,
                                                            onChange
                                                        )
                                                    }
                                                    value={valueProps ?? value}
                                                />
                                            )}
                                        />
                                    )}
                                </div>
                            );
                        }
                    )}
                </div>
            </div>
        </div>
    );
};

export default Filters;
