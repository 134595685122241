import { clientExportServicePassword, clientPassword } from '../ClientPassword';
import {
    TaskReportUpdateRequest,
    TaskReportsRequest,
    TaskReportsResponse,
    TaskCreateRequest,
    TaskCreateResponse,
} from './types';

export const fetchTasksList = async (filters = {}) => {
    const taskList = await clientPassword.post('/tasks/list/', filters);
    return taskList?.tasks;
};

export const fetchExportTask = async (args) => {
    return await clientExportServicePassword.post('/v1/exports/tasks/', args);
};

export const fetchGetTask = async (task_id) => {
    return await clientPassword.get(`/tasks/${task_id}/`);
};

export const fetchTasksListV2 = async (arg) => {
    const taskList = await clientPassword.post('/v2/tasks/list/', arg);
    return taskList?.tasks;
};

export const fetchTasksListV3 = async (arg) => {
    const taskList = await clientPassword.post('/v3/tasks/list/', arg);
    return taskList?.tasks;
};

export const taskReportsList = async (request: TaskReportsRequest) => {
    return await clientPassword.post<TaskReportsResponse, TaskReportsRequest>(
        '/task_reports/list/',
        request
    );
};

export const updateTaskReport = async (
    task_report_id: string,
    request: TaskReportUpdateRequest
) => {
    return await clientPassword.patch<TaskReportsResponse, TaskReportUpdateRequest>(
        `/task_reports/${task_report_id}/`,
        request
    );
};

export const taskCreate = (request: TaskCreateRequest) =>
    clientPassword.post<TaskCreateResponse, TaskCreateRequest>('/tasks/', request);
