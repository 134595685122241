import React from 'react';
import classNames from 'classnames';
import { useDragOver } from '@minoru/react-dnd-treeview';
import { ReactComponent as ArrowIcon } from '@images/svg/chevron-down.svg';
import { ReactComponent as SortIcon } from '@images/svg/drag.svg';
import styles from './CustomNode.module.scss';

export const CustomNode = ({ node, isOpen, onToggle, depth, handleRef }) => {
    const { id, droppable, text } = node;
    const indent = depth * 24;

    const handleToggle = (e) => {
        e.stopPropagation();
        onToggle(id);
    };

    const dragOverProps = useDragOver(id, isOpen, onToggle);

    return (
        <div
            className={styles.root}
            style={{ marginLeft: indent }}
            data-testid={`custom-node-${id}`}
            {...dragOverProps}
        >
            <div className={styles.handle} ref={handleRef} data-testid={`drag-handle-${id}`}>
                <SortIcon />
            </div>
            {droppable && (
                <div
                    className={classNames(styles.expandIconWrapper, { [styles.isOpen]: isOpen })}
                    onClick={handleToggle}
                >
                    <ArrowIcon />
                </div>
            )}
            <div className={styles.labelGridItem}>{text}</div>
        </div>
    );
};
