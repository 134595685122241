import { clientPassword } from '../ClientPassword';

export const fetchBranchListByProjectId = (project_id: string) => {
    return clientPassword.get(`branches/?project_id=${project_id}`)
}

export const fetchBranchList = async (request) => {
    const { branches } = await clientPassword.post('/branches/list/', request);
    return branches;
}
