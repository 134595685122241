import { clientPassword } from '../ClientPassword';

export const fetchFeed = (feed_id: string) => {
    return clientPassword.get(`/feeds/${feed_id}/`);
};

export const deleteFeed = (feed_id: string) => {
    return clientPassword.delete(`/visits/${feed_id}/`);
};

export const updateFeed = (arg) => {
    return clientPassword.patch(`/visits/${arg?.visit_id}/`, arg.req);
};

export const deleteFeedPhoto = async (id: string) => {
    const response = await clientPassword.delete(`/file_links/${id}/`);
    return response;
};
