import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '@components';
import { useRoles } from '@tools/hooks/useRoles';
import CrowdFeedData from '../CrowdFeed/CrowdFeedData/CrowdFeedData';
import CrowdFeedFilters from '../CrowdFeed/CrowdFeedFilters/CrowdFeedFilters';
import FiltersSelectors from '@redux/filters/selectors';
import { useSelector } from 'react-redux';
import { fixTimezoneOffset } from '@tools/utils/date.util';
import styles from './CrowdCheck.module.scss';
import { useURLFilters } from '@tools/hooks/useURLFilters';

export const CrowdCheck = () => {
    const { clientPermission } = useRoles();
    const { setURLFiltersFromDefaultValues } = useURLFilters();

    const { t } = useTranslation('translation');

    const formatDateForPayload = (date, startOrEnd) => {
        const result = fixTimezoneOffset(date, startOrEnd);
        return new Date(result).getTime();
    };

    const now = formatDateForPayload(new Date(), 'start');

    const project_id = sessionStorage.getItem('project_id');
    const defaultValuesData = {
        project_id: project_id !== 'undefined' ? project_id : '',
        date_range: {
            start: now,
            end: now,
        },
        user_ids: null,
        task_complexity_ids: null,
        task_type_ids: null,
        project_outlet_ids: null,
        retail_ids: null,
        branch_id: null,
        territory_id: null,
        city_id: null,
    };

    const getFilters = (values) => {
        const { date_range, project_id } = values || {};
        const date_from = date_range?.start || now;
        const date_to = date_range?.end || now;
        let res = {
            ...values,
            start_time_lower: formatDateForPayload(date_from, 'start'),
            start_time_upper: formatDateForPayload(date_to, 'end'),
        };
        if (project_id) res = { ...res, project_ids: [project_id] };
        return res;
    };

    const [defaultValues, setDefaultValues] = useState({});

    const { filters: { crowdCheck: crowdCheckFilters = {} } = {} } = useSelector(
        FiltersSelectors.filtersState
    );

    useEffect(() => {
        let defaultValues = {};
        if (Object.keys(crowdCheckFilters)?.length > 0) {
            defaultValues = { ...crowdCheckFilters };
            if (project_id) defaultValues = { ...defaultValues, project_id };
        } else {
            defaultValues = getFilters(defaultValuesData);
        }
        const resultFilterValues = setURLFiltersFromDefaultValues(defaultValues);
        setDefaultValues(resultFilterValues);
    }, []);

    return Object.keys(defaultValues)?.length > 0 ? (
        <Layout
            title={t('crowd.checkerCrowd')}
            className={styles.crowdCheck}
            renderSearch={() => ({})}
            renderData={(props) => (
                <CrowdFeedData
                    {...props}
                    defaultValues={defaultValues}
                    clientPermission={clientPermission}
                    isCheckers
                    nameFilters="crowdCheck"
                />
            )}
            renderFilters={(props) =>
                CrowdFeedFilters({ ...props, defaultValues: defaultValuesData })
            }
            defaultValues={defaultValues}
            getFilters={getFilters}
        />
    ) : (
        <></>
    );
};
