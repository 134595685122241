import { QuestionTypeKind, TQuestion, TQuestionnairesAnswer } from "../../apiFeature/questionnaires/type";
import { QuestionnaireTaskPhoto, TStateQuestionAnswers } from "./types";

  export const getAnswersAndPhotos: (
    questionAnswers: TStateQuestionAnswers,
  ) => {
    answers: TQuestionnairesAnswer[];
    photos: QuestionnaireTaskPhoto[];
  } = questionnaireAnswers => {
    const entries = Object.entries(questionnaireAnswers);

    const answers = entries
      .filter(([, v]) => v && (v.answers || v.yourAnswer))
      .map<TQuestionnairesAnswer>(([question_id, v]) => ({
        question_id,
        answer: v.answers ?? v.yourAnswer,
      }));

    const photos = entries
      .filter(([, v]) => v && v.photos && v.photos.length > 0)
      .reduce<QuestionnaireTaskPhoto[]>((a, [, v]) => (v.photos ? a.concat(v.photos) : a), []);

    return { answers, photos };
  };

  export const initQuestionAnswers: (
    questions: TQuestion[],
    questionAnswersState: TStateQuestionAnswers,
  ) => TStateQuestionAnswers = (questions, questionAnswersState) =>
    questions.reduce<TStateQuestionAnswers>((questionAnswers, question) => {
      const { id: questionId, kind, question_result, question_answers } = question;
      if (question_result) {
        const answer = question_result.answer;
        switch (kind) {
          case QuestionTypeKind.your_answer:
          case QuestionTypeKind.number_answer:
          case QuestionTypeKind.photo_answer:
            questionAnswers[questionId] = {
              yourAnswer: typeof answer !== 'object' ? answer : '',
            };
            break;
          case QuestionTypeKind.many_answers:
          case QuestionTypeKind.one_answer:
            // дополнительная проверка на случай если с бэка будет более одного ответа для one_answer
            questionAnswers[questionId] = {
              answers:
                answer && Array.isArray(answer) && answer.length > 0
                  ? kind === QuestionTypeKind.many_answers
                    ? answer
                    : [answer[0]]
                  : [],
            };
            break;
        }
      } else {
        // ставим значения по умолчанию в many_answers и one_answer
        if (
          [QuestionTypeKind.many_answers, QuestionTypeKind.one_answer].includes(kind) &&
          question_answers &&
          question_answers.length > 0 &&
          question_answers.find(({ is_default }) => is_default) !== undefined
        ) {
          const default_answers = question_answers
            .filter(({ is_default }) => is_default)
            .map(({ id }) => id);
          // дополнительная проверка на случай если с бэка будет более одного is_default = true
          // + проверка, если было выбрано пользователем тоже не скидываем
          questionAnswers[questionId] =
            questionAnswersState && questionAnswersState[questionId]
              ? questionAnswersState[questionId]
              : {
                  answers:
                    default_answers.length > 0
                      ? kind === QuestionTypeKind.many_answers
                        ? default_answers
                        : [default_answers[0]]
                      : [],
                };
        } else if (questionAnswersState && questionAnswersState[questionId]) {
          questionAnswers[questionId] = questionAnswersState[questionId];
        }
      }
      return questionAnswers;
    }, {});