import React, { useCallback, useEffect, useState } from 'react';
import styles from './UsersData.module.scss';
import { t } from 'i18next';
import { TableGrid } from '@components';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import ProjectsSelectors from '@redux/projects/selectors';
import { useNavigate } from 'react-router-dom';
import { getUserById, fetchUserList, getUsrProj } from '@apiFeature/user';
import { statusOptions } from '@constants';
import { extractErrorMessage } from '@tools/utils/functions';
import { ReactComponent as Visibility } from '@images/svg/visibility.svg';
import { Book } from '@/navigation/Book';

const limit = 10;

const columns = [
    {
        field: 'last_name',
        headerName: t('catalog.team.lastname'),
        minWidth: 130,
        sortable: false,
    },
    {
        field: 'first_name',
        headerName: t('catalog.team.firstname'),
        minWidth: 130,
        sortable: false,
    },
    {
        field: 'patronymic_name',
        headerName: t('catalog.team.patronymic'),
        minWidth: 130,
        sortable: false,
    },
    {
        field: 'phone',
        headerName: t('common.phone'),
        minWidth: 150,
        sortable: false,
    },
    {
        field: 'snils',
        headerName: t('reports.reports.snils'),
        minWidth: 150,
        sortable: false,
    },
    {
        field: 'userProjects',
        headerName: t('common.project'),
        minWidth: 150,
        renderCell: ({ value }) => {
            return value?.length ? (
                <div className={styles.column}>
                    {value.map((item, index) => (
                        <div key={index}>{item}</div>
                    ))}
                </div>
            ) : (
                ''
            );
        },
        sortable: false,
    },
    {
        field: 'manager',
        headerName: t('common.manager'),
        minWidth: 150,
        valueGetter: ({ value }) => {
            const { first_name, last_name, patronymic_name } = value || {};
            return value ? `${last_name} ${first_name} ${patronymic_name}` : '';
        },
        sortable: false,
    },
    {
        field: 'roles',
        headerName: t('catalog.team.roles'),
        minWidth: 220,
        renderCell: ({ value }) => (value?.length ? value.map((e) => e.name).join(', ') : ''),
        sortable: false,
    },
    {
        field: 'status',
        headerName: t('common.status'),
        minWidth: 150,
        valueGetter: ({ value }) =>
            value ? statusOptions.find((i) => i.value === value)?.label : '',
        sortable: false,
    },
    {
        field: 'id',
        headerName: t('catalog.users.id'),
        minWidth: 250,
        sortable: false,
    },
];

const UsersData = ({ defaultValues, filters }) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const paginationModelDefault = { page: 0, pageSize: limit };
    const { projectsList } = useSelector(ProjectsSelectors.projectsSelectors);
    const [paginationModel, setPaginationModel] = useState(paginationModelDefault);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any | null>(null);
    const { items = [], total = 0, offset } = data || {};

    const getUserList = useCallback(
        async (offset = 0) => {
            setLoading(true);
            //project waiting for backend
            // const [project] = projectsList || [];
            // const { id } = project || {};
            // const { project: projectId } = defaultValues || {};
            //const project_id = projectsList.find((p) => p.id === projectId)?.id ?? id;
            fetchUserList({
                ...filters,
                pagination: {
                    limit,
                    offset,
                },
            })
                .then((res) => {
                    const users = res?.items || [];
                    const usersPromise = Promise.all(
                        users.map((user) => {
                            return getUsrProj({
                                user_id: user.id,
                            }).then(({ user_projects }) => {
                                if (user_projects?.length) {
                                    const projectNames = projectsList
                                        .map((project) => {
                                            if (
                                                user_projects.find(
                                                    (userProject) =>
                                                        userProject.project_id === project.id
                                                )
                                            ) {
                                                return project.full_name;
                                            }
                                        })
                                        .filter((item) => item !== undefined);
                                    const [currentUserProject] = user_projects;
                                    const { manager_id } = currentUserProject || {};
                                    if (manager_id) {
                                        return getUserById(manager_id).then((res) => {
                                            const { user: manager } = res || {};
                                            return {
                                                ...user,
                                                manager,
                                                userProjects: projectNames,
                                            };
                                        });
                                    } else
                                        return {
                                            ...user,
                                            manager: null,
                                            userProjects: projectNames,
                                        };
                                } else return user;
                            });
                        })
                    );
                    usersPromise.then((result) => {
                        setData({
                            ...res,
                            items: result,
                        });
                    });
                })
                .catch((error) => {
                    enqueueSnackbar(extractErrorMessage(error), { variant: 'error' });
                    setLoading(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [filters, projectsList]
    );

    useEffect(() => {
        if (!isLoading && projectsList?.length > 0) {
            getUserList(paginationModel?.page * limit);
        }
    }, [filters, paginationModel, projectsList]);

    useEffect(() => {
        if (filters && Object.keys(filters)?.length > 0) {
            setPaginationModel(paginationModel);
        }
    }, [filters]);

    const openRowsDetail = (id) => {
        navigate(`${Book.catalog.users}/${id}`);
    };

    const resultColumns = [
        {
            field: 'actions',
            type: 'actions',
            headerName: t('common.actions'),
            width: 90,
            cellClassName: 'actions',
            renderCell: (params) => (
                <div className={styles.openRow} onClick={() => openRowsDetail(params.row.id)}>
                    <Visibility />
                </div>
            ),
        },
        ...columns,
    ];

    return (
        <>
            <TableGrid
                rows={items}
                columns={resultColumns}
                rowCount={total}
                loading={isLoading}
                pageSizeOptions={[limit]}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                paginationModel={offset === 0 ? paginationModelDefault : paginationModel}
                hasSun
                getRowHeight={() => 'auto'}
                className={styles.tableGrid}
                disableRowSelectionOnClick
            />
        </>
    );
};

export default UsersData;
