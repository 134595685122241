import React, { useEffect, useState } from 'react';
import styles from './PhotoReport.module.scss';
import { useTranslation } from 'react-i18next';
import { Layout } from '@components';
import PhotoReportHeader from './PhotoReportHeader/PhotoReportHeader';
import PhotoReportBaseFilters from './PhotoReportBaseFilters/PhotoReportBaseFilters';
import PhotoReportFilters from './PhotoReportFilters/PhotoReportFilters';
import PhotoReportData from './PhotoReportData/PhotoReportData';
import { useURLFilters } from '@tools/hooks/useURLFilters';

export const PhotoReport = () => {
    const { t } = useTranslation('translation');
    const { setURLFiltersFromDefaultValues } = useURLFilters();
    const project_id = sessionStorage.getItem('project_id');
    const [defaultValues, setDefaultValues] = useState({
        project_id: project_id !== 'undefined' ? project_id : '',
        kind: 'PHOTO_VISITS',
        BRANCH: false,
        RETAIL: false,
        ADDRESS: false,
        EXTERNAL_CODE: false,
        DATE: false,
        BRANCH_FN: false,
        RETAIL_FN: false,
        ADDRESS_FN: false,
        EXTERNAL_CODE_FN: false,
        DATE_FN: false,
    });

    useEffect(() => {
        const resultFilterValues = setURLFiltersFromDefaultValues(defaultValues);
        setDefaultValues(resultFilterValues);
    }, []);

    const getFilters = (values) => {
        const { project_id, kind, status, export_process_id } = values || {};
        let res = {};
        if (project_id) res = { ...res, project_id };
        if (kind) res = { ...res, kind };
        if (status) res = { ...res, status };
        if (export_process_id) res = { ...res, export_process_id };
        return res;
    };

    return Object.keys(defaultValues)?.length > 0 ? (
        <Layout
            className={styles.layoutReports}
            title={t('reports.photoReport.title')}
            renderHeader={(props) => <PhotoReportHeader {...props} />}
            renderBaseFilters={(props) => <PhotoReportBaseFilters {...props} />}
            renderData={(props) => <PhotoReportData {...props} />}
            renderFilters={PhotoReportFilters}
            defaultValues={defaultValues}
            getFilters={getFilters}
            type={2}
        />
    ) : (
        <></>
    );
};
