import { createTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

const fontSize = 14;

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#414152',
            dark: '#414152',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#cc1c17',
            dark: '#A8100B',
        },
        text: {
            primary: '#414152',
            secondary: '#414152',
            disabled: '#414152',
        },
        divider: alpha('#414152', 0.12),
    },
    typography: {
        fontFamily: 'Geometria, Arial',
        button: {
            fontSize: fontSize,
        },
        fontSize: fontSize,
        htmlFontSize: fontSize,
        subtitle1: {
            fontSize: fontSize,
        },
        subtitle2: {
            fontSize: fontSize,
        },
        body1: {
            fontSize: fontSize,
        },
        body2: {
            fontSize: fontSize,
        },
    },
});

export default theme;
