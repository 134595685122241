import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchUserList } from '@apiFeature/user';
import { debounce } from '@tools/utils/functions';
import { useSnackbar } from 'notistack';

const managersLimit = 10;

export function useManagers(manager_id) {
    const { t } = useTranslation('translation');

    const { enqueueSnackbar } = useSnackbar();

    const [managerCurrent, setManagerCurrent] = useState([]);
    const [managersData, setManagers] = useState({});
    const [managersLoading, setManagersLoading] = useState(false);
    const {
        items: managers = [],
        total: managersTotal,
        offset: managersOffset,
    } = managersData || {};

    const moreManagers = () => {
        const offset = managersLimit + managersOffset;
        if (offset < managersTotal) {
            getManagersListData({
                status: 'active',
                pagination: { limit: managersLimit, offset },
            });
        }
    };

    const findManagers = debounce((name) => {
        getManagersListData({
            name,
            status: 'active',
            pagination: { limit: managersLimit, offset: 0 },
        });
    }, 1000);

    const onInputChangeManagers = (name) => {
        if (name) {
            findManagers(name);
        }
    };

    const getManagersListDataCurrent = useCallback(
        async (manager_id) => {
            try {
                const managers_data = (await fetchUserList({ ids: [manager_id] })) || {};
                const managers_items = managers_data?.items || [];

                if (managers_items?.length > 0) {
                    setManagerCurrent(managers_items);
                }
            } catch (e) {
                enqueueSnackbar(`${t('messages.error')}: ${e?.message}`, {
                    variant: 'error',
                });
            }
        },
        [manager_id]
    );

    const getManagersListData = useCallback(
        async (data) => {
            setManagersLoading(true);
            try {
                const managers_data = (await fetchUserList(data)) || {};
                const managers_items = managers_data?.items || [];

                if (managers_data?.offset > 0) {
                    const items = [...managers, ...managers_items];
                    setManagers({
                        ...managers_data,
                        items,
                    });
                } else {
                    setManagers(managers_data);
                }
            } catch (e) {
                enqueueSnackbar(`${t('messages.error')}: ${e?.message}`, {
                    variant: 'error',
                });
            }
            setManagersLoading(false);
        },
        [managers]
    );

    const getFiltersProjects = useCallback(() => {
        getManagersListData({
            status: 'active',
            pagination: { limit: managersLimit, offset: 0 },
        });
    }, [getManagersListData]);

    useEffect(() => {
        if (manager_id) getManagersListDataCurrent(manager_id);
    }, [manager_id]);

    useEffect(() => {
        getFiltersProjects();
    }, []);

    useEffect(() => {
        if (managerCurrent?.length > 0 && managers?.length > 0) {
            const items = [...managerCurrent, ...managers.filter((e) => e.id !== manager_id)];

            setManagers({
                ...managersData,
                items,
            });
        }
    }, [managerCurrent?.length, managers?.length]);

    return [managers, managersLoading, onInputChangeManagers, moreManagers];
}
