import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { exportPhotoFiles } from '@apiFeature/exportPhotoFiles';
import { Book } from '@navigation/Book';

export const GetPhotosByQuestionResult = () => {
    const { questionResultId } = useParams();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('project_id');
    const navigate = useNavigate();

    useEffect(() => {
        if (!questionResultId || !projectId) return;

        (async () => {
            const data = await exportPhotoFiles({
                include_question_result: true,
                include_visit_photo: false,
                question_result_ids: [questionResultId],
                project_id: projectId,
            });

            const href = URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `photo_files-${questionResultId}.zip`);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            navigate(Book.visit_feed);
        })();
    }, [navigate, questionResultId, projectId]);

    return <></>;
};
