import React from 'react';
import styles from './QuestionnaireEditOutletsHeader.module.scss';
import UnlinkAll from './UnlinkAll/UnlinkAll';

const QuestionnaireEditOutletsHeader = ({ form, totalUnLink }) => {
    const { setValue } = form;

    return (
        <div className={styles.renderHeader}>
            <div className={styles.renderHeaderBtns}>
                {totalUnLink ? <UnlinkAll setValue={setValue} totalUnLink={totalUnLink} /> : ''}
            </div>
        </div>
    );
};

export default QuestionnaireEditOutletsHeader;
