import React, { useCallback, useMemo, useRef } from 'react';

import cn from 'classnames';
import { SingleValue } from 'react-select';
import BaseAsyncSelect from 'react-select/async';
import { InputOption } from 'src/types/common';
import { AsyncPaginate } from 'react-select-async-paginate';
import s from './Select.module.scss';
import { useTranslation } from 'react-i18next';
import { sortFilterLabels } from '@tools/utils/functions';

interface OptionsLoaderConfig<T> {
    request: (inputValue: object) => Promise<T[]>;
    mapper: (data: T) => InputOption;
    prependPlaceholder?: boolean;
}

export interface AsyncSelectProps<T> {
    label?: string;
    name: string;
    className?: string;
    placeholder?: string;
    value?: InputOption;
    disabled?: boolean;
    onChange?: (option?: InputOption | null) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    optionsLoaderConfig: OptionsLoaderConfig<T>;
    isSearchable?: boolean;
    request?: (request: any) => void;
    searchField?: string;
    triggerRequest?: number[] | string[];
    isMulty?: boolean;
    projectId?: string | null;
    isFirstDefault?: boolean;
    error?: boolean;
    questionnaireKind?: string | null;
}

export function AsyncSelect<T>({
    label,
    name,
    placeholder,
    className,
    value,
    optionsLoaderConfig,
    disabled,
    onChange,
    onBlur,
    isSearchable = false,
    searchField,
    isMulty,
    projectId,
    isFirstDefault = false,
    error = false,
    questionnaireKind = 'questionnaire',
}: AsyncSelectProps<T>): React.ReactElement {
    const [total, setTotal] = React.useState<number>(0);
    const offsetRef = useRef(0);
    const searchRef = useRef('');
    const projectRef = useRef('');
    const questionnaireKindRef = useRef('');
    const { t } = useTranslation('translation');
    async function getOptionsLoader<T>(inputValue: string, config: any) {
        if (
            total < offsetRef.current &&
            searchRef.current === inputValue &&
            projectRef.current === projectId &&
            questionnaireKindRef.current === questionnaireKind
        ) {
            return {
                options: [],
                hasMore: false,
            };
        }
        const requestPayload = {
            pagination: { offset: offsetRef.current, limit: 50 },
        };
        if (searchRef.current !== inputValue) {
            searchRef.current = inputValue;
            // setOffset(0);
            offsetRef.current = 0;
            setTotal(0);
        }
        if (projectRef.current !== projectId && !Array.isArray(projectId)) {
            projectRef.current = projectId;
            offsetRef.current = 0;
            setTotal(0);
        }

        if (questionnaireKindRef.current !== questionnaireKind) {
            questionnaireKindRef.current = questionnaireKind;
            offsetRef.current = 0;
            setTotal(0);
        }

        if (projectRef.current) {
            requestPayload.project_id = projectId;
        }

        if (questionnaireKindRef.current && name === 'questionnaire_kind') {
            requestPayload.kind = questionnaireKind;
        }

        const data = searchField
            ? await optionsLoaderConfig.request({
                  ...requestPayload,
                  [searchField]: inputValue,
              })
            : await optionsLoaderConfig.request(requestPayload);
        // setOffset(data.offset + data.limit);
        offsetRef.current = data.offset || 0 + data.limit || 0;
        setTotal(+data.total);
        let options;
        if (data?.items && data?.items?.length !== 0) {
            options = data.items.map(optionsLoaderConfig.mapper);
            if (isFirstDefault && onChange) {
                onChange(data?.items?.map(optionsLoaderConfig.mapper)[0] ?? null);
            }
        } else {
            options = data?.map(optionsLoaderConfig.mapper);
            if (isFirstDefault && onChange) {
                onChange(data?.map(optionsLoaderConfig.mapper)[0] ?? null);
            }
        }

        if (
            placeholder !== undefined &&
            optionsLoaderConfig.prependPlaceholder &&
            offsetRef.current === 0
        ) {
            options.unshift({ value: '', label: placeholder });
        }

        return {
            options: sortFilterLabels(options),
            hasMore: data.total > data.offset,
        };
    }

    const handleChange = useCallback(
        (v: SingleValue<InputOption>) => {
            onChange?.(v);
        },
        [onChange]
    );

    return (
        <label className={cn(s.label, className)}>
            {label}
            <AsyncPaginate
                key={`${projectId} ${questionnaireKind}`}
                isMulti={isMulty}
                defaultOptions
                className={cn(s.select, {
                    [s.error]: Boolean(error),
                })}
                classNamePrefix="r-select"
                menuPlacement="auto"
                isDisabled={disabled}
                loadOptions={getOptionsLoader}
                name={name}
                placeholder={placeholder ?? label}
                value={value}
                onBlur={onBlur}
                isSearchable={isSearchable}
                onChange={handleChange}
                debounceTimeout={1000}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            {error && <div className={s.errorMessage}>{t('messages.fieldRequired')}</div>}
        </label>
    );
}
