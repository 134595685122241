import React, { FC, forwardRef, useState } from 'react';
import { StandardTextFieldProps, TextField as TF, IconButton, InputAdornment } from '@mui/material';
import InputMask from 'react-text-mask';
import { MaskedInputProps, Mask } from '@types/react-text-mask';
import classNames from 'classnames';
import Label from '../Label/Label';
import styles from './TextField.module.scss';
import { ReactComponent as Visibility } from '@images/svg/visibility.svg';
import { ReactComponent as VisibilityOff } from '@images/svg/visibility-off.svg';

export interface ITextField extends Omit<StandardTextFieldProps, 'error'> {
    id: string;
    label?: string;
    className?: string;
    value?: string;
    error?: {
        message: string;
    };
    mask?: Mask;
    isPassword?: boolean;
}

interface CustomProps {
    name: string;
}

const TextMaskCustom = forwardRef<CustomProps, MaskedInputProps>(function TextMaskCustom(
    props,
    inputRef
) {
    const { mask, ...other } = props;
    return (
        <InputMask
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask}
            guide={false}
        />
    );
});

const TextField: FC<ITextField> = ({
    id,
    value = '',
    label,
    className,
    onChange,
    error,
    mask,
    InputProps,
    inputProps,
    isPassword = false,
    ...props
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    let dataInputProps = { InputProps: {} };
    if (InputProps) dataInputProps = { InputProps: { ...InputProps } };
    if (mask)
        dataInputProps = {
            InputProps: { ...dataInputProps.InputProps, inputComponent: TextMaskCustom },
        };
    if (isPassword) {
        dataInputProps = {
            InputProps: {
                ...dataInputProps.InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }
        }
    };


    return (
        <>
            {label && <Label htmlFor={id}>{label}</Label>}
            <TF
                {...props}
                {...dataInputProps}
                value={value}
                type={isPassword ?
                    showPassword
                        ? "text"
                        : "password"
                    : 'text'}
                id={id}
                onChange={onChange}
                className={classNames(styles.textField, className, {
                    [styles.error]: error,
                })}
                helperText={error ? error.message : null}
                inputProps={{ ...inputProps, mask }}
            />
        </>
    );
};

export default TextField;
