import React, { useEffect, useState } from "react";
import styles from './InstantMerchItem.module.scss';
import { useTranslation } from "react-i18next";
import { PhotoSlider } from '@components';
import moment from "moment";
import { stockInsightsActions, stockInsightsComments } from "@apiFeature/stockInsights/types";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import { fetchFeedsPhotos } from '@api/visitFeed/visitFeed.api';
import { FileLink } from '@types/files';

interface QuestionField {
    question: string;
    answer: string;
    isPhoto?: boolean;
    photos?:  FileLink[];
    eventResultId?: string;
}

const InstantMerchItem = ({ stockInsight, sku }) => {
    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();
    const [photos, setPhotos] = useState([]);
    const [questionFieldsForPriceCheckEvents, setQuestionFieldsForPriceCheckEvents] = useState<QuestionField[]>([])
    const [questionFieldsForProductSituationEvents, setQuestionFieldsForProductSituationEvents] = useState<QuestionField[]>([])
    const [questionFieldsForPriceCheckEventsWithPhotos, setQuestionFieldsForPriceCheckEventsWithPhotos] = useState<QuestionField[]>([])
    const [questionFieldsForProductSituationEventsWithPhotos, setQuestionFieldsForProductSituationEventsWithPhotos] = useState<QuestionField[]>([])
    const {
        id: stockInsightId,
        action,
        virtual_stock_quantity,
        comment,
        error_type,
        current_stock,
        last_sale_at,
        explanation
    } = stockInsight || {};
    const {
        name: productName,
        article,
        events,
        sku_info,
    } = sku || {};
    const {
        price_regular,
        price_promo,
        price_discount,
        stock_date,
        stock_amount,
        delivery_amount,
        delivery_date,
        promo,
    } = sku_info || {};

    const isPriceCheckBadge = events?.length > 0 && events.some((e) => e.event_type_name === 'price_check');
    const stockDateFormatted = stock_date ? moment(stock_date).format('DD.MM') : null;
    const deliveryDateFormatted = delivery_date ? moment(delivery_date).format('DD.MM') : null;
    const lastSaleAtFormatted = last_sale_at ? moment(last_sale_at).format('DD.MM.yyyy') : null;


    const getQuestionsAndAnswerFromEventResult = (eventResults) => {
        let questionFields: QuestionField[] = [];
        eventResults.forEach(async (eventResult) => {
            const { result, id: eventResultId } = eventResult || {};
            const { question_answers } = result || {};
            if (question_answers?.length > 0) {
                question_answers.forEach((qa) => {
                    const { question_display, answer } = qa || {};
                    if (question_display) {
                        questionFields.push({
                            question: question_display,
                            answer,
                            eventResultId
                        })
                    }
                })
            }
        });
        return questionFields;
    };

    const fetchPhotosForEventResult = async (questions) => {
        if (questions?.length > 0) {
            const newQuestionFields = await Promise.all(questions.map(async (q) => {
                if (q.answer === 'photo') {
                    const photos = await fetchFeedsPhotos({
                        generic_id: q.eventResultId,
                        is_preview: null,
                    });
                    return {
                        ...q,
                        photos,
                        isPhoto: true,
                    }
                } else {
                    return q
                }
            }));
            return newQuestionFields
        } else {
            return questions;
        }
    }

    useEffect(() => {
        if (events?.length > 0) {
            const priceCheckEvents = events
                ?.filter(e => e.event_type_name === 'price_check')
                ?.map((e) => e.event_results).flat();

            const productSituationEvents = events
                ?.filter(e => e.event_type_name === 'product_situation')
                ?.map((e) => e.event_results).flat() || [];
            const newQuestionFieldsForPriceCheckEvents = getQuestionsAndAnswerFromEventResult(priceCheckEvents);
            const newQuestionFieldsForProductSituationEvents = getQuestionsAndAnswerFromEventResult(productSituationEvents);
            setQuestionFieldsForPriceCheckEvents(newQuestionFieldsForPriceCheckEvents);
            setQuestionFieldsForProductSituationEvents(newQuestionFieldsForProductSituationEvents)
        }
    }, [events]);

    useEffect(() => {
        (async () => {
            const resultQuestions = await fetchPhotosForEventResult(questionFieldsForPriceCheckEvents);
            setQuestionFieldsForPriceCheckEventsWithPhotos(resultQuestions)
        })();
    }, [questionFieldsForPriceCheckEvents]);

    useEffect(() => {
        (async () => {
            const resultQuestions = await fetchPhotosForEventResult(questionFieldsForProductSituationEvents);
            setQuestionFieldsForProductSituationEventsWithPhotos(resultQuestions);
        })();
    }, [questionFieldsForProductSituationEvents]);

    const stockInsightFields = {
        [t('alert.stockStatus')]: action ? stockInsightsActions[action] : '-',
        [t('alert.virtualStockQty')]: virtual_stock_quantity ?? '-',
        [t('alert.comment')]: comment ? stockInsightsComments[comment] : '-',
        [t('feed.comment')]: explanation ?? '-',
    };

    useEffect(() => {
        if (stockInsightId) {
            fetchFeedsPhotos({
                generic_id: stockInsightId,
                is_preview: null,
            }).then((photos) => {
                setPhotos(photos);
            })
                .catch((e) => {
                    const errorMessage = e?.message || e?.data?.detail || t('messages.unknownError');
                    enqueueSnackbar(errorMessage, { variant: 'error' })
                });
        }
    }, [stockInsightId]);

    return (
        <div className={styles.productItem}>
            <div className={styles.header}>
                <div className={styles.title}>
                    {article} {productName && ' | '} {productName}
                </div>
            </div>
            <div className={styles.pins}>
                {error_type &&
                    <div className={classNames(styles.pinItem, styles.redPin)}>
                        {error_type}
                    </div>}
                {isPriceCheckBadge &&
                    <div className={classNames(styles.pinItem, styles.redPin)}>
                        {t('instantMerch.priceChanging')}
                    </div>}
                {price_regular &&
                    <div className={styles.pinItem}>
                        {t('instantMerch.priceRegular')}: {price_regular}
                    </div>
                }
                {price_promo &&
                    <div className={styles.pinItem}>
                        {t('instantMerch.pricePromo')}: {price_promo}
                    </div>
                }
                {price_discount &&
                    <div className={styles.pinItem}>
                        {t('instantMerch.priceDiscount')}: {price_discount}
                    </div>
                }
                {stock_amount &&
                    <div className={styles.pinItem}>
                        {t('instantMerch.stockLeft')} {stockDateFormatted} - {stock_amount} {t('common.qty')}
                    </div>
                }
                {delivery_amount &&
                    <div className={styles.pinItem}>
                        {t('instantMerch.delivery')} {deliveryDateFormatted} - {delivery_amount} {t('common.qty')}
                    </div>
                }
                {promo === 'TRUE' &&
                    <div className={styles.pinItem}>
                        {t('instantMerch.promoEngagement')}
                    </div>}
                {current_stock &&
                    <div className={styles.pinItem}>
                        {t('alert.currentStock')}: {current_stock}
                    </div>}
                {last_sale_at &&
                    <div className={styles.pinItem}>
                        {t('alert.lastSale')}: {lastSaleAtFormatted}
                    </div>}
            </div>
            {stockInsight && <div className={styles.stockInsights}>
                <div className={styles.subtitle}>
                    {t('instantMerch.signalCheck')}
                </div>
                <div className={styles.questionBlock}>
                    {Object.keys(stockInsightFields).map((key, index) => {
                        return (
                            <div key={index} className={styles.questions}>
                                <div>{key}</div>
                                <div>{stockInsightFields[key]}</div>
                            </div>
                        )
                    })}

                </div>
                {photos?.length > 0 && (
                    <PhotoSlider
                        photos={photos}
                        type="list"
                        isSmall
                    />
                )}
            </div>}
            {questionFieldsForPriceCheckEventsWithPhotos?.length > 0 &&
                <div className={styles.events}>
                    <div className={styles.subtitle}>
                        {t('instantMerch.priceChanging')}
                    </div>
                    <div className={styles.questionBlock}>
                        {questionFieldsForPriceCheckEventsWithPhotos.map((i, index) => {
                            return (
                                <div key={index} className={styles.questions}>
                                    <div>{i.question}</div>
                                    {i?.isPhoto
                                        ? <PhotoSlider
                                            photos={i.photos}
                                            type="list"
                                            isSmall
                                        />
                                        : <div>{i.answer}</div>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>}
            {questionFieldsForProductSituationEventsWithPhotos?.length > 0 &&
                <div className={styles.events}>
                    <div className={styles.subtitle}>
                        {t('alert.stockStatus')}
                    </div>
                    <div className={styles.questionBlock}>
                        {questionFieldsForProductSituationEventsWithPhotos.map((i, index) => {
                            return (
                                <div key={index} className={styles.questions}>
                                    <div>{i.question}</div>
                                    {i?.isPhoto
                                        ? <PhotoSlider
                                            photos={i.photos}
                                            type="list"
                                            isSmall
                                        />
                                        : <div>{i.answer}</div>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>}
        </div>
    )
};

export default InstantMerchItem;