import styles from './ReportsFilters.module.scss';
import { useTranslation } from 'react-i18next';
import { Select } from '@components';
import { exportProcessStatusNames } from '@apiFeature/reports/type';
import { useRoles } from '@tools/hooks/useRoles';
import { useURLFilters } from '@tools/hooks/useURLFilters';

const ReportsFilters = ({ defaultValues, filters, form }) => {
    const { t } = useTranslation('translation');
    const { setURLFilters } = useURLFilters();

    const { setValue } = form;
    const kindValue = filters?.kind || defaultValues?.kind;
    const statusValue = filters?.status || defaultValues?.status;
    const { isCrowd, isAdmin, isClient, isQuestionnaireReport, isVisitReport, isCrowdReport, isVisitReportAndCrowd } =
        useRoles();

    const onChange = (name, e) => {
        setValue(name, e?.value);

        if (
            name === 'kind' &&
            ['VISITS', 'VISITS_WITH_QUESTIONNAIRES', 'CROWD_TASKS', 'CROWD_QUESTIONNAIRES'].some(
                (ee) => ee === e?.value
            )
        ) {
            setURLFilters(e, 'kind');
        }
    };

    const handleReset = () => {
        setValue('kind', 'VISITS');
        setValue('status', '');

        setURLFilters({ value: 'VISITS' }, 'kind');
    };

    let resultKindOptions = [
        { value: 'VISITS', label: t('reports.reports.visit') },
        { value: 'VISITS_WITH_QUESTIONNAIRES', label: t('reports.reports.questionnaire') },
        { value: 'CROWD_TASKS', label: t('reports.reports.crowd') },
        { value: 'CROWD_QUESTIONNAIRES', label: t('reports.reports.crowdQuestionnaires') },
    ];

    if (!isVisitReport && !isAdmin && isClient) {
        resultKindOptions = resultKindOptions.filter((e) => e.value !== 'VISITS');
    }
    if (!isQuestionnaireReport && !isAdmin && isClient) {
        resultKindOptions = resultKindOptions.filter(
            (e) => e.value !== 'VISITS_WITH_QUESTIONNAIRES'
        );
    }
    if ((!isCrowdReport && !isVisitReportAndCrowd) || (!isCrowd && !isAdmin)) {
        resultKindOptions = resultKindOptions.filter((e) => e.value !== 'CROWD_TASKS');
    }
    if (!(isAdmin || (isCrowdReport && isCrowd))) {
        resultKindOptions = resultKindOptions.filter((e) => e.value !== 'CROWD_QUESTIONNAIRES');
    }

    const exportStatusNames = Object.keys(exportProcessStatusNames).map((key) => {
        return {
            label: exportProcessStatusNames[key],
            value: key,
        };
    });

    const data = [
        {
            type: Select,
            name: 'kind',
            label: t('reports.reports.type'),
            placeholder: t('common.all'),
            onChange,
            value: resultKindOptions?.find((e) => e.value === kindValue),
            options: resultKindOptions,
        },
        {
            type: Select,
            name: 'status',
            label: t('common.status'),
            placeholder: t('common.all'),
            onChange,
            value: exportStatusNames?.find((e) => e.value === statusValue),
            options: exportStatusNames,
            isClearable: true,
        },
    ];

    return { handleReset, filters: data };
};

export default ReportsFilters;
