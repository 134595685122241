import React, { FC, RefObject } from 'react';
import Slider from '@mui/material/Slider';
import Label from '@components/Label/Label';
import classNames from 'classnames';
import styles from './RangeSlider.module.scss'

export interface IRangeSlider {
    id: string;
    label?: string;
    className?: string;
    value?: number[];
    error?: {
        message: string;
    };
    onChange?: () => void;
    inputRef?: RefObject<HTMLInputElement>
}

const RangeSlider: FC<IRangeSlider> = ({
    id,
    label,
    value,
    className,
    onChange,
    error,
    inputRef,
    ...props
}) => {

    return (
        <>
            {label && <Label htmlFor={id}>{label}</Label>}
            <Slider
                {...props}
                ref={inputRef}
                className={classNames(styles.sliderWrap, className)}
                id={id}
                value={value}
                onChange={onChange}
            />
        </>
    );
}

export default RangeSlider;