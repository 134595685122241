import React, { FC, useEffect, useState } from 'react';
import styles from './QuestionnaireDetailedItem.module.scss';
import { TQuestionnaire } from '@apiFeature/questionnaires/type';
import { Button, Collapse } from '@mui/material';
import StepList from './StepList/StepList';
import { ReactComponent as ArrowIcon } from '@images/svg/chevron-down.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import DeleteIcon from '@images/svg/delete.svg';
import AddIcon from '@images/svg/add.svg';

interface IQuestionnaireDetailedItem {
    questionnaire?: TQuestionnaire;
    registerStr: string;
    expandedAll: boolean;
}

const QuestionnaireDetailedItem: FC<IQuestionnaireDetailedItem> = ({
    questionnaire,
    registerStr,
    expandedAll,
}) => {
    const { t } = useTranslation('translation');
    const [expanded, setExpanded] = useState(expandedAll);
    const { setValue, watch } = useFormContext();
    const added = watch(`${registerStr}.added`);
    const { name, external_code, questionnaire_steps } = questionnaire || {};

    useEffect(() => {
        setExpanded(expandedAll);
    }, [expandedAll]);

    const handleExpandClick = (e) => {
        setExpanded(!expanded);
        e.stopPropagation();
    };

    const handleAddQuestionnaireToVisit = (e) => {
        e.stopPropagation();
        setValue(`${registerStr}.added`, !added);
        setExpanded(!added);
    };

    return (
        <div className={styles.questionnaireItem}>
            <div className={styles.line} onClick={(e) => handleExpandClick(e)}>
                <div className={styles.titleWrapper}>
                    <div
                        className={classNames(styles.expandIconWrapper, {
                            [styles.isOpen]: expanded,
                        })}
                    >
                        <ArrowIcon />
                    </div>
                    <div className={styles.title}>
                        <div className={styles.name}>{name}</div>
                        <div className={styles.externalCode}>{external_code}</div>
                    </div>
                </div>
                <div className={styles.btnWrapper}>
                    <Button
                        className={classNames(styles.textBtn, {
                            [styles.addBtn]: !added,
                        })}
                        onClick={(e) => {
                            handleAddQuestionnaireToVisit(e);
                        }}
                        color="secondary"
                        startIcon={
                            added ? (
                                <img src={DeleteIcon} alt="delete" />
                            ) : (
                                <img src={AddIcon} alt="add" />
                            )
                        }
                    >
                        {added ? t('buttons.deleteFromVisit') : t('buttons.addToVisit')}
                    </Button>
                </div>
            </div>
            <Collapse in={expanded} timeout={200}>
                <StepList
                    steps={questionnaire_steps}
                    registerStr={registerStr}
                    expandedAll={expandedAll}
                />
            </Collapse>
        </div>
    );
};

export default QuestionnaireDetailedItem;
