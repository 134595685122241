import React, { FC } from 'react';
import { Radio as RD, FormControlLabel, SvgIcon } from '@mui/material';
import classNames from 'classnames';
import styles from './Radio.module.scss';

interface IRadio {
    className?: string | undefined;
    value: boolean;
    label?: string;
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom' | undefined;
}

const Radio: FC<IRadio> = ({ value, className, label, labelPlacement, ...props }) => {
    return (
        <FormControlLabel
            {...props}
            className={classNames(styles.radio, className)}
            checked={value}
            control={
                <RD
                    icon={
                        <SvgIcon>
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="0.5"
                                    y="0.5"
                                    width="15"
                                    height="15"
                                    rx="7.5"
                                    stroke="#414152"
                                />
                            </svg>
                        </SvgIcon>
                    }
                    checkedIcon={
                        <SvgIcon>
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="0.5"
                                    y="0.5"
                                    width="15"
                                    height="15"
                                    rx="7.5"
                                    stroke="#CC1C17"
                                />
                                <rect x="3" y="3" width="10" height="10" rx="5" fill="#CC1C17" />
                            </svg>
                        </SvgIcon>
                    }
                />
            }
            label={label}
            labelPlacement={labelPlacement}
        />
    );
};

export default Radio;
