import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styles from './RestoredVisit.module.scss';
import { useTranslation } from 'react-i18next';
import GeneralStep from './GeneralStep/GeneralStep';
import QuestionnaireStep from './QuestionnaireStep/QuestionnaireStep';
import { Title, Header, Items } from '@components';
import { useForm, FormProvider } from 'react-hook-form';
import ActionsBtns from './ActionsBtns/ActionsBtns';
import { fetchQuestionnaireDetailed } from '@apiFeature/questionnaires';
import { useSnackbar } from 'notistack';
import { TQuestionnaire } from '@apiFeature/questionnaires/type';
import { extractErrorMessage } from '@tools/utils/functions';
import { object, string, mixed } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { utcOffsetInHours } from '@tools/utils/utcOffsetConvert';
import { fixTimezoneOffset } from '@tools/utils/date.util';
import { createRestoredVisitNew } from '@apiFeature/visits/restoredVisit';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchFeed } from '@apiFeature/feeds';
import { useSelector } from 'react-redux';
import ProjectsSelectors from '@redux/projects/selectors';
import {
    getAnswersAndPhotosFromState,
    getDefaultValuesFromFeedItem,
    setAnswersFromFilledQuestionnaires,
} from './utilsForRestoredVisit';
import { filesUpload, filesUploadList, fileLinks } from '@apiFeature/fileService';
import { fetchFeedsPhotos } from '@api/visitFeed/visitFeed.api';
import { useOutlets } from '@tools/hooks/useOutlets';
import { fetchProjectOutletsTaskOutletStatus } from '@apiFeature/projectOutlets';

export const RestoredVisit: FC = () => {
    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const params = useParams();
    const { visitId } = params || {};
    const [section, setSection] = useState('stepOne');
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [questionnairesList, setQuestionnairesList] = useState<TQuestionnaire[]>([]);
    const [loadingQuestionnaires, setLoadingQuestionnaires] = useState<boolean>(false);

    const defaultErrorText = t('messages.fieldRequired');
    const invalidTime = t('filledVisit.errorDate');
    const selectDateMessage = t('common.selectDate');
    const selectProjectMessage = t('filledVisit.selectProject');
    const validationSchema = object().shape({
        project_id: string().required(defaultErrorText),
        project_outlet_id: string().when('project_id', {
            is: (project_id) => !project_id,
            then: (schema) => schema.required(selectProjectMessage),
            otherwise: (schema) => schema.required(defaultErrorText),
        }),
        user_id: string().when('project_id', {
            is: (project_id) => !project_id,
            then: (schema) => schema.required(selectProjectMessage),
            otherwise: (schema) => schema.required(defaultErrorText),
        }),
        date: string().required(defaultErrorText),
        startTime: string()
            .test('date-required', selectDateMessage, (_, context) => {
                const { date } = context?.parent || {};
                return !!date;
            })
            .required(defaultErrorText),
        endTime: mixed()
            .test('date-required', selectDateMessage, (_, context) => {
                const { date } = context?.parent || {};
                return !!date;
            })
            .test('startTime', invalidTime, (_, context) => {
                const { startTime, endTime } = context?.parent;
                return moment(endTime).isAfter(moment(startTime));
            })
            .required(defaultErrorText),
        comment: string().nullable(),
    });
    const formMethods = useForm({ mode: 'onChange', resolver: yupResolver(validationSchema) });
    const { watch, handleSubmit, formState, reset, setValue } = formMethods;
    const { project_id, project_outlet_id, date, address } = watch();
    const { isSubmitting } = formState || {};

    const { projectsList } = useSelector(ProjectsSelectors.projectsSelectors);

    const projectIdFromStorage = sessionStorage.getItem('project_id');
    const projectsListId = projectsList?.[0]?.id;
    const pId = projectIdFromStorage || projectsListId;
    const prevProjectIdForOutlets = useRef(project_id);

    const [projectOutlets, outletsLoading, onInputChangeOutlets, moreOutlets] = useOutlets(
        project_id,
        2,
        prevProjectIdForOutlets,
        address
    );

    const onSubmit = (data) => {
        setLoadingCreate(true);
        const utc_offset = utcOffsetInHours();
        const {
            user_id,
            start_point_lat,
            start_point_lon,
            end_point_lat,
            end_point_lon,
            startTime,
            endTime,
            project_outlet_id,
            comment,
            questionnaires,
            questions_file_links_ids,
            signboardPhoto,
            photosDelete = [],
            photosCopy,
        } = data || {};

        const questionnairesIds =
            questionnaires &&
            Object.keys(questionnaires).filter((qId) => {
                return questionnaires[qId]?.added === true;
            });

        const { question_results, photo_results } = getAnswersAndPhotosFromState(
            data,
            questionnairesList
        );

        const request = {
            visit: {
                user_id,
                start_point_lon,
                start_point_lat,
                start_time: startTime,
                start_distance: 0,
                end_point_lon,
                end_point_lat,
                end_time: endTime,
                end_distance: 0,
                result: {
                    questionnaire_ids: questionnairesIds,
                },
                comment,
                utc_offset,
            },
            route_point: {
                project_outlet_id,
            },
            question_results,
        };

        createRestoredVisitNew(request)
            .then(async (res) => {
                const { restored_visit } = res || {};
                const { id, result } = restored_visit || {};
                const { questionnaire_ids } = result || {};
                if (id) {
                    const [signPhoto] = signboardPhoto || [];
                    if (signPhoto && typeof signPhoto !== 'string') {
                        const requestForSignboardPhoto = {
                            file: signPhoto,
                            object_id: id,
                            type: 'sfa_visit',
                            user_id: user_id,
                        };
                        filesUpload(requestForSignboardPhoto).catch((e) => {
                            enqueueSnackbar(extractErrorMessage(e), { variant: 'error' });
                        });
                    }

                    if (photo_results?.length > 0) {
                        for (let photoResult of photo_results) {
                            const { question_id, answer: file } = photoResult;
                            const requestForUploadFiles = {
                                file,
                                objects: [
                                    {
                                        generic_type: 'SFA_VISIT',
                                        object_id: question_id,
                                        type: 'sfa_question_result',
                                        visit_id: id,
                                    },
                                ],
                            };
                            filesUploadList(requestForUploadFiles).catch((e) => {
                                enqueueSnackbar(extractErrorMessage(e), { variant: 'error' });
                            });
                        }
                    }

                    let photo_results_copy = photosCopy
                        ?.filter((e) => !photosDelete.includes(e.id))
                        ?.map(
                            ({
                                file_id,
                                generic_id,
                                generic_type,
                                file,
                                extend_data,
                                created_at,
                                updated_at,
                                created_by,
                            }) => ({
                                file_id,
                                generic_id: generic_type === 'visits' ? id : generic_id,
                                generic_type,
                                visit_id: id,
                                file_created_at: file?.created_at,
                                is_preview: file?.name === 'preview.webp',
                                original_file_link_id:
                                    file?.name === 'preview.webp' &&
                                    extend_data?.original_file_link_id
                                        ? extend_data.original_file_link_id
                                        : undefined,
                                name: file?.name,
                                created_at,
                                updated_at,
                                created_by,
                            })
                        )
                        .filter((e) => e.name !== 'preview.webp');

                    if (photo_results_copy?.length > 0) {
                        if (
                            questionnaire_ids?.length > 0 &&
                            Object.keys(questions_file_links_ids)?.length > 0
                        ) {
                            await fetchQuestionnaireDetailed({
                                ids: questionnaire_ids,
                                visit_id: id,
                            })
                                .then((res) => {
                                    const { questionnaires } = res || {};
                                    if (questionnaires && questionnaires?.length > 0) {
                                        questionnaires.forEach((q) => {
                                            const { questionnaire_steps } = q || {};
                                            if (
                                                questionnaire_steps &&
                                                questionnaire_steps?.length > 0
                                            ) {
                                                questionnaire_steps.forEach((step) => {
                                                    const { questions } = step || {};
                                                    if (questions && questions?.length > 0) {
                                                        questions.forEach((question) => {
                                                            const {
                                                                id: questionId,
                                                                question_result,
                                                            } = question || {};
                                                            const { id: question_result_id } =
                                                                question_result || {};

                                                            const question_result_id_old =
                                                                questions_file_links_ids[
                                                                    questionId
                                                                ];

                                                            if (
                                                                question_result_id_old &&
                                                                question_result_id
                                                            ) {
                                                                photo_results_copy =
                                                                    photo_results_copy.map(
                                                                        (item) => ({
                                                                            ...item,
                                                                            generic_id:
                                                                                item.generic_id &&
                                                                                question_result_id_old &&
                                                                                item.generic_id ===
                                                                                    question_result_id_old
                                                                                    ? question_result_id
                                                                                    : item.generic_id,
                                                                        })
                                                                    );
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                })
                                .catch((e) => {
                                    enqueueSnackbar(extractErrorMessage(e), { variant: 'error' });
                                });
                        }

                        for (let photoResult of photo_results_copy) {
                            fileLinks(photoResult)
                                .then((res) => {
                                    const { id, extend_data, ...file_link } = res?.file_link || {};
                                    const file = {
                                        ...file_link,
                                        ...extend_data,
                                        original_file_link_id: id,
                                        is_preview: true,
                                        file_id: photoResult?.file_id,
                                    };

                                    fileLinks(file).catch((e) => {
                                        enqueueSnackbar(extractErrorMessage(e), {
                                            variant: 'error',
                                        });
                                    });
                                })
                                .catch((e) => {
                                    enqueueSnackbar(extractErrorMessage(e), { variant: 'error' });
                                });
                        }
                    }

                    navigate(`/visit_feed/${id}`, { replace: true });
                }
            })
            .catch((e) => {
                enqueueSnackbar(extractErrorMessage(e), { variant: 'error' });
            })
            .finally(() => {
                setLoadingCreate(false);
            });
    };

    useEffect(() => {
        if (visitId) {
            fetchFeed(visitId)
                .then((res) => {
                    const { feed } = res || {};
                    const { start_time, end_time, result } = feed || {};
                    const feedData = {
                        ...feed,
                        start_time: fixTimezoneOffset(start_time),
                        end_time: fixTimezoneOffset(end_time),
                    };

                    const { questionnaire_ids = [] } = result || {};
                    const { defaultValues, project_outlet_id } = getDefaultValuesFromFeedItem(
                        feedData,
                        pId
                    );
                    reset(defaultValues);
                    fetchProjectOutletsTaskOutletStatus({
                        kind: 'questionnaire',
                        project_outlet_id,
                    })
                        .then((result) => {
                            const { tasks } = result || {};
                            if (tasks?.length > 0) {
                                const tasks_ids = tasks.map((q) => q?.id);
                                const ids = [...new Set([...questionnaire_ids, ...tasks_ids])];
                                if (ids?.length > 0) {
                                    fetchQuestionnaireDetailed({
                                        ids,
                                        date_in: fixTimezoneOffset(start_time),
                                        visit_id: visitId,
                                    }).then((res) => {
                                        const { questionnaires: q } = res || {};
                                        if (q && q?.length > 0) {
                                            setAnswersFromFilledQuestionnaires(q, setValue);
                                            setQuestionnairesList(q);
                                        }
                                    });

                                    questionnaire_ids?.forEach((qItem) => {
                                        const registerStr = `questionnaires[${qItem}].added`;
                                        setValue(registerStr, true);
                                    });
                                }
                            }
                        })
                        .catch((e) => {
                            enqueueSnackbar(extractErrorMessage(e), { variant: 'error' });
                        });
                })
                .catch((e) => {
                    enqueueSnackbar(extractErrorMessage(e), { variant: 'error' });
                });
            fetchFeedsPhotos({ visit_id: visitId })
                .then((photos) => {
                    if (photos.length) {
                        setValue('photosCopy', photos);

                        let fileLink = photos.find(
                            (photo) => photo.generic_type === 'visits' && !photo.is_preview
                        );
                        if (fileLink && Object.keys(fileLink)?.length === 0) {
                            fileLink = photos.find(
                                (photo) => photo.generic_type === 'visits' && photo.is_preview
                            );
                        }
                        const { file, id } = fileLink || {};
                        const { url } = file || {};
                        if (url) {
                            setValue('signboardPhoto', [url]);
                            setValue('signboardPhotoId', id);
                        }
                    }
                })
                .catch((e) => {
                    enqueueSnackbar(extractErrorMessage(e), { variant: 'error' });
                });
        }
    }, [visitId]);

    const items = [
        {
            label: t('filledVisit.stepOne'),
            onClick: () => setSection('stepOne'),
            id: 'stepOne',
            isActive: section === 'stepOne',
        },
        {
            label: t('filledVisit.stepTwo'),
            onClick: () => setSection('stepTwo'),
            id: 'stepTwo',
            isActive: section === 'stepTwo',
        },
    ];

    const getSection = {
        stepOne: () => (
            <GeneralStep
                {...formMethods}
                projectOutlets={projectOutlets}
                onInputChangeOutlets={onInputChangeOutlets}
                moreOutlets={moreOutlets}
                outletsLoading={outletsLoading}
            />
        ),
        stepTwo: () => (
            <QuestionnaireStep
                {...formMethods}
                questionnaires={questionnairesList}
                loading={loadingQuestionnaires}
            />
        ),
    };

    const fetchQuestionnairesCallback = useCallback(() => {
        if (project_id && project_outlet_id && date) {
            setLoadingQuestionnaires(true);
            fetchQuestionnaireDetailed({
                project_id,
                project_outlet_id,
                date_in: date,
                is_active: true,
                kind: 'questionnaire',
            })
                .then((res) => {
                    const { questionnaires } = res || {};
                    if (questionnaires) {
                        setQuestionnairesList(questionnaires);
                    }
                })
                .catch((e) => {
                    enqueueSnackbar(extractErrorMessage(e), { variant: 'error' });
                })
                .finally(() => {
                    setLoadingQuestionnaires(false);
                });
        }
    }, [project_id, project_outlet_id, date]);

    useEffect(() => {
        if (!visitId) {
            fetchQuestionnairesCallback();
        }
    }, [project_id, project_outlet_id, date]);

    return (
        <div className={styles.restoredVisit}>
            <div className={styles.nav}>
                <Header className={styles.header}>
                    <Title>{t('filledVisit.title')}</Title>
                </Header>
                <Items data={items} />
            </div>
            <div className={styles.content}>
                <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {getSection[section]()}
                        <ActionsBtns isSubmitting={isSubmitting} isLoading={loadingCreate} />
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};
