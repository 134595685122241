import React from 'react';
import { PopupFieldPropsType } from './PopupField.type';
import './PopupField.style.scss';

export const PopupField = ({ children, onClose }: PopupFieldPropsType) => (
    <div className="popupfield__wrap" onClick={onClose}>
        <div className="popupfield__wrap__content" onClick={(e) => e.stopPropagation()}>
            <button className="popupfield__wrap__close-button" type="button" onClick={onClose} />
            {children}
        </div>
    </div>
);
