import { clientPassword } from '../ClientPassword';

export const fetchRetailsList = async (args) => {
    const res = await clientPassword.post('/project_retails/list/', args);

    return res?.project_retails;
};

export const fetchRetail = async (id) => {
    const res = await clientPassword.get(`/project_retails/${id}/`);

    return res?.project_retail;
};
