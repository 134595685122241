import React from 'react';
import styles from './VisitFeedHeader.module.scss';
import { Button } from '@components';
import { useRoles } from '@tools/hooks/useRoles';
import { useNavigate } from 'react-router-dom';
import { Book } from '@navigation/Book';
import { useTranslation } from 'react-i18next';

const VisitFeedHeader = () => {
    const { isAvailableFakeVisit } = useRoles();

    const navigate = useNavigate();

    const { t } = useTranslation('translation');

    return (
        isAvailableFakeVisit && (
            <div className={styles.renderHeader}>
                <Button variant="outlined" onClick={() => navigate(Book.restoredVisit.new)}>
                    {t('feed.createVisit')}
                </Button>
            </div>
        )
    );
};

export default VisitFeedHeader;
