import React, { forwardRef, InputHTMLAttributes, PropsWithChildren } from 'react';
import TextareaAutosize, { TextareaAutosizeProps } from '@mui/material/TextareaAutosize';
import cn from 'classnames';
import { ReactComponent as CloseIcon } from '@images/svg/close-circle.svg';
import s from './TextInput.module.scss';

interface ComponentLayoutProps {
    className?: string;
    label?: string;
    error?: string;
    clearable?: boolean;
    onClear?: () => void;
}

const ComponentLayout: React.FC<PropsWithChildren<ComponentLayoutProps>> = ({
    label,
    error,
    clearable,
    className,
    children,
    onClear,
}) => {
    return (
        <label className={cn(className, s.label)}>
            {label}
            <div className={s.inputWrapper}>
                {children}
                {clearable && <CloseIcon className={s.clearIcon} onClick={onClear} />}
            </div>
            {error && <div className={s.errorMessage}>{error}</div>}
        </label>
    );
};

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    error?: string;
    clearable?: boolean;
}

const TextInputComponent = forwardRef<HTMLInputElement, TextInputProps>(
    ({ label, className, error, clearable, ...props }, ref) => {
        return (
            <ComponentLayout
                className={className}
                clearable={clearable}
                error={error}
                label={label}
            >
                <input
                    aria-label={label}
                    value={props.value || ''}
                    {...props}
                    ref={ref}
                    className={cn(s.input, {
                        [s.disabled]: props.disabled,
                        [s.noLabel]: !label,
                        [s.error]: Boolean(error),
                        [s.clearable]: clearable,
                    })}
                />
            </ComponentLayout>
        );
    }
);

TextInputComponent.displayName = 'TextInput';

interface ResizableInputProps extends TextareaAutosizeProps {
    label?: string;
    error?: string;
    clearable?: boolean;
}

const Resizable = forwardRef<HTMLTextAreaElement, ResizableInputProps>(
    ({ label, className, error, clearable, ...props }, ref) => {
        return (
            <ComponentLayout
                className={className}
                clearable={clearable}
                error={error}
                label={label}
            >
                <TextareaAutosize
                    aria-label={label}
                    {...props}
                    ref={ref}
                    className={cn(s.input, s.textarea, {
                        [s.disabled]: props.disabled,
                        [s.noLabel]: !label,
                        [s.error]: Boolean(error),
                        [s.clearable]: clearable,
                    })}
                />
            </ComponentLayout>
        );
    }
);

Resizable.displayName = 'ResizableTextInput';

export const TextInput = Object.assign(TextInputComponent, { Resizable });
