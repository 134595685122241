import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from './SettingsHeader.module.scss';
import { ToggleButtonGroup } from '@components';
import { useURLFilters } from '@tools/hooks/useURLFilters';

export const SettingsHeader: FC = ({ form }) => {
    const { t } = useTranslation('translation');
    const { watch, setValue } = form;
    const { switchType } = watch();
    const { setURLFilters } = useURLFilters();

    const handleChange = (_, value: string) => {
        if (value !== null) {
            setValue('switchType', value);
            setURLFilters({ value }, 'switchType');
        }
    };
    let elements = [
        { value: 'ROLES', label: t('catalog.team.roles') },
        { value: 'USERS', label: t('catalog.users.users') },
    ];
    return (
        <div className={styles.renderHeader}>
            <ToggleButtonGroup
                color="secondary"
                value={switchType}
                exclusive
                onChange={handleChange}
                elements={elements}
            />
        </div>
    )
}