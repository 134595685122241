import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import { AnyAction, Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducer';
import { persistStore } from 'redux-persist';

const isDev = process.env.NODE_ENV === 'development';

const sagaMiddleware = createSagaMiddleware();
const middlewares: Middleware[] = [sagaMiddleware];

if (isDev) {
    middlewares.push(
        createLogger({
            duration: true,
            collapsed: true,
            colors: {
                title: () => '#139BFE',
                prevState: () => '#1C5FAF',
                action: () => '#149945',
                nextState: () => '#A47104',
                error: () => '#ff0005',
            },
        })
    );
}

export const reduxStore = configureStore({
    reducer: rootReducer,
    devTools: isDev,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
});

export const reduxPersistor = persistStore(reduxStore);

export type RootState = ReturnType<typeof reduxStore.getState>;

export type AppDispatch = typeof reduxStore.dispatch;

export type AppStore = typeof reduxStore;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
