import React from 'react';

function TshirtIcon({ color = '#414152' }) {
    return (
        <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0002 17.5525H17.0418V6.81569L19.7918 9.05253L22.5418 6.36832L18.8752 2.78937C18.0502 1.98411 16.8585 1.44727 15.6668 1.44727H12.0002H8.42516C7.14183 1.44727 6.04183 1.89463 5.12516 2.78937L1.4585 6.36832L4.2085 9.05253L6.9585 6.81569V17.5525H12.0002Z" stroke={color} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    );
}

export default TshirtIcon;