import { clientPassword } from '../ClientPassword';
import { VisitCreateRequest, VisitCreateResponse, VisitsHistoryRequest, VisitsHistoryResponse } from './types';

export const getVisitsFailResultsOptions = async (filter) => {
    const failResults = await clientPassword.post('/visit_fail_results/list/', filter);
    return failResults.visit_fail_results;
};

export const createVisit = (request: VisitCreateRequest) =>
    clientPassword.post<VisitCreateResponse, VisitCreateRequest>('/visits/', request);

export const getVisitsHistory = (request: VisitsHistoryRequest) => 
    clientPassword.post<VisitsHistoryResponse, VisitsHistoryRequest>('/visits/history/', request);

export const getVisitById = (visitId: string) => 
    clientPassword.get(`/visits/${visitId}/`);

