import React, { FC, useEffect, useState } from 'react';

import { importFile } from '@apiFeature/importProcesses';
import { fetchProjects } from 'src/api/projects/projects.api';
import { InputOption } from 'src/types/common';
import { Button } from 'src/view/components/ui/Button/Button';
import { AsyncSelect } from 'src/view/components/ui/form/Select/AsyncSelect';
import { Loader } from 'src/view/components/ui/Loader/Loader';
import { Modal } from 'src/view/components/ui/Modal/Modal';
import { ImportFiles } from './ImportFiles/ImportFiles';
import { extractErrorMessage } from '@tools/utils/functions';

import './PlanogramImport.style.scss';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

enum BodyKeys {
    ProjectId = 'project_id',
    File = 'file',
}

interface PlanogramImportProps {
    onClose: () => void;
}

interface FileRequest {
    file: File | null;
    project_id: InputOption | null;
}

const required: BodyKeys[] = Object.values(BodyKeys);

const defaultBody: FileRequest = {
    file: null,
    project_id: null,
};

export const PlanogramImport: FC<PlanogramImportProps> = ({ onClose }) => {
    const [warning, setWarning] = useState<BodyKeys[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [body, setBody] = useState<FileRequest>(defaultBody);
    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();

    const changeBody = (key: BodyKeys, data: any) => setBody({ ...body, [key]: data });

    const check = (key: BodyKeys) => warning.includes(key);

    const reset = () => {
        setBody(defaultBody);
        setLoading(false);
        onClose();
    };

    const sendRequest = () => {
        const valid: BodyKeys[] = [];

        required.forEach((key) => {
            if (body[key] === null) {
                valid.push(key);
            }
        });

        if (valid.length > 0) {
            setWarning(valid);

            return;
        }

        setLoading(true);

        if (body.file !== null && body.project_id !== null) {
            const formData = new FormData();
            formData.append('file', body.file);

            importFile({
                project_id: body.project_id.value,
                kind: 'PLANOGRAMS',
                file: formData,
            }).catch((e) => {
                enqueueSnackbar(extractErrorMessage(e), { variant: 'error' });
            }).finally(onClose);
        }
    };

    useEffect(() => {
        setWarning([]);
    }, [body]);

    useEffect(() => {
        return () => reset();
    }, []);

    return (
        <Modal className="planogramsImport" onClose={onClose}>
            <p className="planogramsImport__title">{t('catalog.planograms.import')}</p>
            <AsyncSelect
                className="planogramsImport__select"
                label={t('common.project')}
                name="project"
                optionsLoaderConfig={{
                    mapper: (data) => ({ label: data.full_name, value: data.id }),
                    request: () => fetchProjects({}),
                }}
                value={body.project_id || undefined}
                onChange={(option) => changeBody(BodyKeys.ProjectId, option)}
            />
            {loading ? (
                <Loader className="planogramsImport__loader" />
            ) : (
                <ImportFiles
                    error={check(BodyKeys.File)}
                    file={body.file}
                    onChange={(arg) => changeBody(BodyKeys.File, arg)}
                />
            )}
            <div className="planogramsImport__btns">
                <Button disabled={loading} onClick={sendRequest}>
                    {t('importJournal.import')}
                </Button>
                <Button disabled={loading} onClick={reset}>
                    {t('buttons.cancel')}
                </Button>
            </div>
        </Modal>
    );
};
