import React from 'react';

export const HealthCheck = () => {
    return (
        <div>
            <h1>Healt Check</h1>
            <p>Status: OK</p>
        </div>
    );
};
