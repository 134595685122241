import React, { useCallback, useEffect, useState } from 'react';
import styles from './ImportsJournalDetails.module.scss';
import { Header, Title, BackLink, TableGrid, Select, TextField, Filters } from '@components';
import { useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
    getImportProcessItemById,
    importRowProcessesList,
    ImportFileProcessesItem,
} from '@apiFeature/importProcesses';
import { useTranslation } from 'react-i18next';
import {
    importRowStatusNames,
    ImportRowProcessesItem,
    importFileKindNames,
} from '@apiFeature/importProcesses/types';
import { FormProvider, useForm } from 'react-hook-form';
import { renderErrors } from '@tools/utils/functions';
import { columnsAndKindRelation, columnsForRowDetails } from './columnsForImportDetails';
import { debounce } from '@tools/utils/functions';
import BarChart from '../BarChart/BarChart';
import { Book } from '@navigation/Book';

const rowsPerPageOptions = [10, 20, 30, 50, 100];

export const ImportsJournalDetails = () => {
    const { importId } = useParams();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation('translation');
    const [data, setData] = useState<ImportRowProcessesItem | null>(null);
    const [importItemInfo, setImportItemInfo] = useState<ImportFileProcessesItem | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [limit, setLimit] = useState(10);
    const paginationModelDefault = { page: 0, pageSize: limit };
    const [paginationModel, setPaginationModel] = useState(paginationModelDefault);
    const { items = [], total = 0 } = data || {};
    const filename = importItemInfo?.file_links.length
        ? importItemInfo?.file_links?.[0]?.file?.name
        : undefined;
    const title = `${importFileKindNames[importItemInfo?.kind]} - ${filename ?? importId}`;

    const form = useForm();
    const { watch, setValue } = form;
    const { line_number, status } = watch();

    const onChange = (name, e) => {
        setValue(name, e?.value);
    };

    const rowStatusOptions = Object.keys(importRowStatusNames).map((key) => {
        return {
            label: importRowStatusNames[key],
            value: key,
        };
    });

    const filters = [
        {
            type: Select,
            name: 'status',
            label: t('common.status'),
            placeholder: t('common.all'),
            onChange,
            value: rowStatusOptions?.find((e) => e.value === status),
            options: rowStatusOptions,
            isClearable: true,
        },
        {
            type: TextField,
            name: 'line_number',
            label: t('importJournal.lineNumber'),
            value: line_number,
        },
    ];

    useEffect(() => {
        if (location.state) {
            setImportItemInfo(location.state.importItem);
        } else if (importId) {
            getImportProcessItemById(importId)
                .then((res) => {
                    setImportItemInfo(res.import_file_process);
                })
                .catch((error) => {
                    const errorMessage =
                        error?.message || error?.data?.detail || t('messages.unknownError');
                    enqueueSnackbar(errorMessage, { variant: 'error' });
                });
        }
    }, [importId]);

    const baseColumns = [
        {
            field: 'line_number',
            headerName: t('importJournal.lineNumber'),
            minWidth: 80,
            sortable: false,
        },
        {
            field: 'status',
            headerName: t('common.status'),
            minWidth: 180,
            valueGetter: ({ value }) => importRowStatusNames[value],
            sortable: false,
        },
        {
            field: 'error_data',
            headerName: t('messages.error'),
            minWidth: 200,
            valueGetter: ({ value }) => renderErrors(value),
            sortable: false,
        },
    ];

    const filteredColumns = columnsForRowDetails.filter((column) => {
        if (importItemInfo?.kind) {
            return columnsAndKindRelation[importItemInfo?.kind].includes(column.field);
        } else return false;
    });

    const getImportRowList = useCallback(
        async (offset = 0) => {
            setLoading(true);
            importRowProcessesList({
                import_file_process_id: importId,
                ...(line_number && { line_numbers: [line_number] }),
                status,
                pagination: {
                    limit,
                    offset,
                },
                sorting: {
                    line_number: 'asc',
                },
            })
                .then((res) => {
                    setData(res.import_row_processes);
                    setLoading(false);
                })
                .catch((error) => {
                    const errorMessage =
                        error?.message || error?.data?.detail || t('messages.unknownError');
                    enqueueSnackbar(errorMessage, { variant: 'error' });
                    setLoading(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [filters, importId]
    );

    useEffect(() => {
        if (!isLoading) {
            const getImportRowListDebounce = debounce(
                () => getImportRowList(paginationModel?.page * limit),
                1000
            );
            getImportRowListDebounce();
        }
    }, [line_number, status, paginationModel]);

    useEffect(() => {
        if (line_number || status) {
            setPaginationModel(paginationModel);
        }
    }, [line_number, status]);

    const handlePageSizeChange = (e) => {
        const { pageSize } = e || {};
        if (pageSize) {
            setLimit(pageSize);
        }
        setPaginationModel({ ...paginationModel, ...e });
    };

    return (
        <div className={styles.importsDetailWrap}>
            <Header type="inner">
                <Title>
                    <BackLink link={Book.import_journal} />
                    {title}
                </Title>
            </Header>
            <FormProvider {...form}>
                <div className={styles.filtersWrap}>
                    <Filters filters={filters} className={styles.filters} />
                    <div className={styles.progressBar}>
                        <BarChart importProcessId={importId} />
                    </div>
                </div>
            </FormProvider>
            <TableGrid
                className={styles.table}
                rows={items}
                columns={[...baseColumns, ...filteredColumns]}
                rowCount={total}
                loading={isLoading}
                pageSize={limit}
                pageSizeOptions={rowsPerPageOptions}
                paginationMode="server"
                onPaginationModelChange={(e) => handlePageSizeChange(e)}
                paginationModel={paginationModel}
                rowHeight={80}
                hasSun
                getRowHeight={() => 'auto'}
                disableRowSelectionOnClick
            />
        </div>
    );
};
