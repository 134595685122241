import React, { FC } from 'react';
import classNames from 'classnames';
import { TextField } from '@components';
import InputAdornment from '@mui/material/InputAdornment';
import styles from './Search.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import searchIcon from '@images/svg/search-icon.svg';

interface ISearch {
    className?: string | undefined;
    name?: string | undefined;
    placeholder?: string | undefined;
}

const Search: FC<ISearch> = ({ name = 'address', className = '', placeholder }) => {
    const { t } = useTranslation('translation');
    const { control } = useFormContext();

    return (
        <div className={classNames(styles.search, className)}>
            <Controller
                name={name}
                control={control}
                render={({ field: { name, value, ref, onChange } }) => (
                    <TextField
                        id={name}
                        name={name}
                        inputRef={ref}
                        onChange={onChange}
                        value={value}
                        placeholder={placeholder || t('feed.search')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img alt="search" src={searchIcon} />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />
        </div>
    );
};

export default Search;
