import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Autocomplete as AC, TextField, CircularProgress, Tooltip } from '@mui/material';
import { fetchQuestionnairesList } from '@apiFeature/questionnaires';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from './Autocomplete.module.scss';

export default function Autocomplete({ name, onChange, project_id, kind, label }) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options && options.length === 0;

    const { t } = useTranslation('translation');

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const { questionnaires } = await fetchQuestionnairesList({
                project_id: project_id,
                is_active: true,
                kind,
                sorting: {
                    period_upper: 'desc',
                },
            });

            if (active) {
                setOptions(questionnaires);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Tooltip title={t('outlets.tooltip')} placement="top">
            <AC
                id={name}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={(_, option) => {
                    onChange(option);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.external_code}
                renderOption={(props, option) => (
                    <li {...props} className={classNames(props.className, styles.renderOption)}>
                        <span className={styles.renderOptionItem}>
                            <span className={styles.item}>
                                <b>{t('catalog.outlets.code')}:</b>
                                <span>{option.external_code}</span>
                            </span>
                            <span className={styles.item}>
                                <b>{t('feed.type')}:</b>
                                <span>
                                    {option?.kind === 'questionnaire'
                                        ? t('questionnaires.questionnaire')
                                        : t('feed.audit')}
                                </span>
                            </span>
                            <span className={styles.item}>
                                <b>{t('questionnaires.periodLower')}:</b>
                                <span>{moment(option.period_lower).format('DD.MM.YYYY')}</span>
                            </span>
                            <span className={styles.item}>
                                <b>{t('questionnaires.periodUpper')}:</b>
                                <span>{moment(option.period_upper).format('DD.MM.YYYY')}</span>
                            </span>
                        </span>
                    </li>
                )}
                disableClearable={true}
                blurOnSelect={true}
                value={null}
                options={options || []}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        color="secondary"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        </Tooltip>
    );
}
