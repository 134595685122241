import { useDispatch } from 'react-redux';
import { SettingsThunks } from '@redux/settings/thunk';

export const useReset = () => {
    const dispatch = useDispatch();

    const setReset = (project_id) => {
        sessionStorage.setItem('project_id', project_id || '');
        sessionStorage.setItem('project_label', '');
        if (project_id) {
            dispatch(SettingsThunks.getAllSettings({ project_ids: [project_id] }));
            dispatch(SettingsThunks.getAllUserSettings({ project_id }));
        }
    };

    return { setReset };
};
