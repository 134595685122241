import React from 'react';
import { useAppDispatch } from '@tools/hooks/redux';
import { Button } from '@components';
import styles from './banned.module.scss';
import AuthActions from '@redux/auth/actions';

export const BannedRoles = () => {
    const dispatch = useAppDispatch();
    return (
        <div className={styles.banned}>
            <div className={styles.banned__img}>
                <img src={'warning.gif'} alt="no access img" />
            </div>
            <div className={styles.banned__description}>
                Данный пользователь не имеет доступ к sfa Сработаемся
            </div>
            <div>
                <Button color="secondary" variant="outlined" onClick={() => dispatch(AuthActions.logout())}>
                    Выйти
                </Button>
            </div>
        </div>
    );
};
