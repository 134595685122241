import { createAction } from '@reduxjs/toolkit';
import { reduxStoreName } from './types';

module CheckerAction {
  export const reset = createAction(`${reduxStoreName}/reset`);

  export const updateFormField = createAction<any | undefined>(`${reduxStoreName}/updateFormField`);

  export const deleteFormField = createAction<any | undefined>(`${reduxStoreName}/deleteFormField`);

  export const finishChecker = createAction(`${reduxStoreName}/finishChecker`);
  export const descriptionChecker = createAction(`${reduxStoreName}/descriptionChecker`);

  export const reorderCheckersList = createAction(`${reduxStoreName}/reorderCheckersList`);
}
export default CheckerAction;
