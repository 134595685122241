import React, { useCallback, useEffect, useState } from 'react';
import ProjectsSelectors from '@redux/projects/selectors';
import { fetchTasksListV3 } from '@apiFeature/tasks/tasks';
import { fetchUserList } from '@apiFeature/user';
import { Lists } from '@components';
import TasksList from './TasksList/TasksList';
import { useSelector } from 'react-redux';
import { FilterValue } from '@apiFeature/types';
import { useURLFilters } from '@tools/hooks/useURLFilters';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { fixTimezoneOffset } from '@tools/utils/date.util';
import { getFullName } from '@tools/utils/users';

const TasksData = ({
    defaultValues,
    filters,
    isTaskCreateBlocked,
    showMyTasksOnly,
    isTasksAuthor,
}) => {
    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();
    const { searchParams, setSearchParams } = useURLFilters();

    let limitFromURL;
    let pageNumber;
    try {
        limitFromURL = Number(atob(searchParams.get('limit')));
        pageNumber = Number(atob(searchParams.get('page')));
    } catch {
        limitFromURL = Number(searchParams.get('limit'));
        pageNumber = Number(searchParams.get('page'));
    }

    const defaultItemsPerPage = {
        label: limitFromURL > 0 ? limitFromURL : '10',
        value: limitFromURL > 0 ? limitFromURL : 10,
    };
    const [itemsPerPage, setItemsPerPage] = useState<FilterValue>(defaultItemsPerPage);
    const limit = Number(itemsPerPage.value);
    const offsetFromPageNumber = (pageNumber - 1) * limit;
    const paginationModelDefault = {
        offset: offsetFromPageNumber > 0 ? offsetFromPageNumber : 0,
        limit,
    };
    const { projectsList } = useSelector(ProjectsSelectors.projectsSelectors);
    const auth = useSelector((s) => s?.auth);
    const [isLoading, setLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState(paginationModelDefault);
    const [sortModel, setSortModel] = useState({ deadline: 'desc' });
    const [data, setData] = useState({ items: [], total: 0, offset: 0 });
    const { items = [], total = 0, offset } = data || {};

    const getProjectTasks = useCallback(
        (offset = 0, limit, sortModel) => {
            setLoading(true);
            const [project] = projectsList || [];
            const { id } = project || {};
            const {
                project_id: defaultProjectId,
                deadline: deadline_default,
                created: created_default,
                fact_date: fact_date_default,
                ...otherDefaultValues
            } = defaultValues || {};
            const project_id = projectsList.find((p) => p.id === defaultProjectId)?.id ?? id;

            const {
                project_id: cur_project_id,
                deadline,
                created,
                fact_date,
                is_photo_required,
                is_required,
                ...otherFilters
            } = filters;

            const deadline_lower = deadline?.start
                ? fixTimezoneOffset(deadline.start, 'start').replace('Z', '')
                : null;
            const deadline_upper = deadline?.end
                ? fixTimezoneOffset(deadline.end, 'end').replace('Z', '')
                : null;

            const created_lower = created?.start
                ? fixTimezoneOffset(created.start, 'start').replace('Z', '')
                : null;
            const created_upper = created?.end
                ? fixTimezoneOffset(created.end, 'end').replace('Z', '')
                : null;

            const start_time = fact_date?.start
                ? fixTimezoneOffset(fact_date.start, 'start').replace('Z', '')
                : null;
            const end_time = fact_date?.end
                ? fixTimezoneOffset(fact_date.end, 'end').replace('Z', '')
                : null;

            let res = {
                ...otherDefaultValues,
                ...otherFilters,
                project_id: cur_project_id || project_id,
                deadline_lower,
                deadline_upper,
                created_lower,
                created_upper,
                start_time,
                end_time,
                is_photo_required: is_photo_required || undefined,
                is_required: is_required || undefined,
                sorting: sortModel,
                pagination: {
                    limit,
                    offset,
                },
            };

            if (showMyTasksOnly) {
                res = {
                    ...res,
                    user_ids: [auth?.user?.id],
                };
            }

            fetchTasksListV3(res)
                .then(async (res) => {
                    let result = { ...res };
                    const { items } = result || {};

                    result = {
                        ...result,
                        items: items.map((e) => ({
                            ...e,
                            project_short_name: projectsList?.find(
                                (p) => p.id === e?.project_outlet?.project_id
                            )?.short_name,
                        })),
                    };

                    const uIds = items.map((e) => e.created_by);
                    const created_user_ids = [...new Set(uIds)].filter((e) => e);

                    let created_users: any = [];
                    if (created_user_ids.length > 0) {
                        created_users = await fetchUserList({ ids: created_user_ids })
                            .then(({ items }) => items)
                            .catch((e) => enqueueSnackbar(e?.message, { variant: 'error' }));
                    }
                    if (created_users.length > 0) {
                        result = {
                            ...result,
                            items: result.items.map((e) => ({
                                ...e,
                                created_by_name: getFullName(
                                    created_users.find((ee) => ee.id === e.created_by)
                                ),
                            })),
                        };
                    }

                    setData(result);
                })
                .catch((e) => enqueueSnackbar(e?.message, { variant: 'error' }))
                .finally(() => {
                    setLoading(false);
                });
        },
        [projectsList, filters]
    );

    useEffect(() => {
        const { offset, limit } = paginationModel;
        if (projectsList?.length > 0) getProjectTasks(offset, limit, sortModel);
    }, [paginationModel, projectsList]);

    useEffect(() => {
        if (Object.keys(filters)?.length > 0) {
            searchParams.set('page', btoa('1'));
            searchParams.set('limit', btoa('10'));
            setSearchParams(searchParams);
            setItemsPerPage({
                label: '10',
                value: 10,
            });
            setPaginationModel({
                offset: 0,
                limit: 10,
            });
        }
    }, [filters]);

    useEffect(() => {
        setPaginationModel(paginationModelDefault);
    }, [sortModel]);

    const onSortModelChange = () => {
        setSortModel((prevState) => ({
            ...prevState,
            deadline: prevState?.deadline === 'asc' ? 'desc' : 'asc',
        }));
    };

    const sortName = `${t('tasks.deadline')}: ${
        sortModel?.deadline === 'asc' ? t('common.sortAsc') : t('common.sortDesc')
    }`;

    return (
        <Lists
            items={items}
            loading={isLoading}
            hasSun={true}
            total={total}
            offset={offset}
            limit={limit}
            renderList={(props) => (
                <TasksList
                    {...props}
                    isTaskCreateBlocked={isTaskCreateBlocked}
                    isTasksAuthor={isTasksAuthor}
                />
            )}
            onPaginationModelChange={setPaginationModel}
            onSortModelChange={onSortModelChange}
            sort={sortModel?.deadline}
            sortName={sortName}
            pageNumber={pageNumber}
            setItemsPerPage={setItemsPerPage}
            emptyText={t('tasks.empty')}
        />
    );
};

export default TasksData;
