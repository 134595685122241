import { compareAsc, format, differenceInDays, add } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import moment from 'moment';
import 'moment/locale/ru';
import i18n from '../../i18n';
import { convertToOriginalDate } from './utcOffsetConvert';
import { SettingType } from '@apiFeature/settings/types';

type DateFnsFormatOptions = {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
};

const RU_LOCALE_OPTIONS: DateFnsFormatOptions = {
    locale: ruLocale,
    weekStartsOn: 1,
};

const currentLocale = i18n.language === 'en' ? 'en-us' : i18n.language;

export const formatters = {
    toYear: (date: Date): string => {
        return format(date, 'yyyy', RU_LOCALE_OPTIONS);
    },
    toMonthName: (date: Date): string => {
        moment.locale(currentLocale);
        return moment(date).format('MMMM');
    },
    toISODateString: (date: Date): string => {
        return format(date, 'yyyy-MM-dd');
    },
    toMonthDay: (date: Date): string => {
        return format(date, 'dd', RU_LOCALE_OPTIONS);
    },
    toWeekDay: (date: Date): string => {
        moment.locale(currentLocale);
        return moment(date).format('dd');
    },
    toISODateFormatString: (date: string, mask: string = 'dd.MM.yyyy'): string => {
        return date ? format(new Date(date), mask, RU_LOCALE_OPTIONS) : '';
    },
    toDatePlusDay: (date: string): string => {
        moment.locale(currentLocale);
        const now = moment().format('YYYY-MM-DD');
        return moment(new Date(new Date(date || now).getTime() + 60 * 60 * 24 * 1000)).format(
            'YYYY-MM-DD'
        );
    },
};

const getExpireDateLocal = (dateString: string): Date => {
    const expireDateLocal =
        dateString[dateString.length - 1] === 'Z'
            ? new Date(dateString)
            : new Date(`${dateString}Z`);

    return expireDateLocal;
};

export const isOutdated = (dateString: string): boolean => {
    if (!dateString) {
        return true;
    }
    const expireDateLocal = getExpireDateLocal(dateString);
    return compareAsc(new Date(), expireDateLocal) >= 0;
};

export const isOutdatedDays = (dateString: string): boolean => {
    if (!dateString) {
        return true;
    }
    const expireDateLocal = getExpireDateLocal(dateString);
    return differenceInDays(new Date(), expireDateLocal) > 0;
};

export const fixTimezoneOffset = (date: Date, name: string): string => {
    if (!date) return '';
    const newDate = new Date(date);
    let res = new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000);
    if (name === 'end') res = new Date(res.setDate(res.getDate() + 1));
    return res.toISOString();
};

export const getDateGMT = (date, utc_offset, format?: string) => {
    const signUTCOffset = utc_offset > 0 ? '+' : '-';
    const timeOffset = convertToOriginalDate(date, utc_offset, format);
    const endTime = date
        ? utc_offset
            ? `${timeOffset} (GMT ${signUTCOffset}${utc_offset})`
            : timeOffset
        : '';
    return endTime;
};

export const getDaysList = (start: Date, end: Date): Date[] => {
    const daysCount = differenceInDays(end, start) + 1;

    const days: Date[] = [start];
    for (let i = 1; i < daysCount; i += 1) {
        days.push(add(start, { days: i }));
    }
    return days;
};

export const getMinDateBasedOnSettings = (
    settings: SettingType[],
    projectId: string,
    clientPermission: boolean
): Date | undefined => {
    const settingAvailableDates = settings.find((s) => s.code === 'days_past_available_client')
        ?.setting_values?.[0];
    if (settingAvailableDates) {
        const projectInSettings = settingAvailableDates.project_id;
        const valueAvailableDates = settingAvailableDates.val;
        if (!valueAvailableDates) return;
        const dateLimit = moment().subtract(valueAvailableDates, 'days').toDate();
        if (clientPermission && projectInSettings === projectId) {
            return dateLimit;
        } else if (clientPermission) {
            return new Date();
        }
    }
};
