import React from 'react';

function RazorIcon({ color = '#414152' }) {
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4396 10.7907L17.4413 10.7902C17.8034 10.7015 18.186 10.7852 18.4779 11.017L18.4782 11.0172C18.8115 11.2816 19.004 11.6852 18.9997 12.1107H18.9997V12.1207V12.1337V12.142L18.9998 12.1504C19.0093 12.7161 18.6657 13.2275 18.1392 13.4329C16.4753 14.027 13.5377 14.4013 10.0363 14.4013C7.12711 14.4013 4.58858 14.1423 2.84623 13.7038L2.29621 13.5654L2.06229 13.7992L1.43748 13.0992C1.20137 12.8346 1.07152 12.4921 1.07292 12.1376L1.07296 12.1287L1.07283 12.1197C1.06684 11.6911 1.2596 11.2839 1.59486 11.0168C1.88699 10.7854 2.26958 10.7018 2.63162 10.7903L2.63284 10.7906C3.1132 10.9074 3.636 11.0094 4.19195 11.097L4.68633 11.1748L4.63087 11.2303L6.81431 11.387C7.82971 11.4599 8.91147 11.4988 10.0363 11.4988C12.8982 11.4988 15.5306 11.255 17.4396 10.7907ZM8.65715 7.21366L14.744 1.12678L15.4434 1.82621L11.0931 6.17654L11.0031 6.18367L10.9898 6.18472L10.9766 6.18613C10.1127 6.27782 9.302 6.63848 8.65715 7.21366ZM10.9188 6.35079C10.919 6.35057 10.9193 6.35034 10.9195 6.35012L10.9191 6.35054L10.9188 6.35079Z" stroke={color} stroke-width="2" />
        </svg>

    );
}

export default RazorIcon;