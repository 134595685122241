import React from 'react';
import styles from './ProjectsHeader.module.scss';
import { Button } from '@components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Book } from '@navigation/Book';
import { useRoles } from '@tools/hooks/useRoles';

const ProjectsHeader = () => {
    const { t } = useTranslation('translation');
    const navigation = useNavigate();
    const { isAdmin } = useRoles();

    return (
        <div
            className={styles.renderHeader}
            onClick={() => {
                navigation(Book.catalog.project_create);
            }}
        >
            <Button variant="outlined" disabled={!isAdmin}>
                {t('catalog.projects.create')}
            </Button>
        </div>
    );
};

export default ProjectsHeader;
