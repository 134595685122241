import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '@components';
import ImportsJournalData from './ImportsJournalData/ImportsJournalData';
import ImportsJournalFilters from './ImportsJournalFilters/ImportsJournalFilters';
import ImportsJournalHeader from './ImportsJournalHeader/ImportsJournalHeader';

export const ImportJournal = () => {
    const { t } = useTranslation('translation');

    const project_id = sessionStorage.getItem('project_id');
    const defaultValues = {
        project: project_id !== 'undefined' ? project_id : '',
    };
    const [importedFile, setImportedFile] = useState<string | null>(null);

    const getFilters = (values) => {
        let res = {};
        if (values.project) res = { ...res, project_id: values?.project };
        if (values.kind) res = { ...res, kind: values.kind };
        if (values.status) res = { ...res, status: values.status };
        return res;
    };

    return (
        <Layout
            title={t('importJournal.title')}
            renderHeader={(props) => (
                <ImportsJournalHeader setImportedFile={setImportedFile} {...props} />
            )}
            renderData={(props) => <ImportsJournalData importedFile={importedFile} {...props} />}
            renderFilters={ImportsJournalFilters}
            defaultValues={defaultValues}
            getFilters={getFilters}
        />
    );
};
