import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Loader, Dialog } from '@components';
import { useSnackbar } from 'notistack';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import styles from './Create.module.scss';
import CreateForm from './CreateForm/CreateForm';
import { getRoutes, createRoutes } from '@apiFeature/routes';
import { routePointsList, createRoutePoints } from '@apiFeature/routePoints';
import { fixTimezoneOffset } from '@tools/utils/date.util';
import moment from 'moment';

const defaultErrorText = 'Поле обязательно';
const validationSchema = object({
    user_ids: string().required(defaultErrorText),
    outlet_ids: string().required(defaultErrorText),
    visit_date: string().required(defaultErrorText),
});

const Create = ({ project_id, type, setValue }) => {
    const [isOpenDialog, setDialog] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const { t } = useTranslation('translation');

    const defaultValues = {
        project_id_create: project_id,
        type,
        visit_date: new Date(),
    };

    const form = useForm({ defaultValues, resolver: yupResolver(validationSchema) });
    const { handleSubmit } = form;

    const setCancelChange = () => {
        setDialog(false);
    };

    const onSubmit = async (values) => {
        const { user_ids, outlet_ids, visit_date } = values;
        const startDate = moment(visit_date).utc().startOf('day').toISOString();
        const endDate = moment(visit_date).utc().endOf('day').toISOString();
        const date = fixTimezoneOffset(visit_date);

        setLoading(true);
        try {
            const { routes } =
                (await getRoutes({
                    visit_date_lower: startDate,
                    visit_date_upper: endDate,
                    user_id: user_ids,
                })) || {};

            let route_id = routes?.[0]?.id;
            if (!route_id) {
                const { route } =
                    (await createRoutes({
                        user_id: user_ids,
                        name: t('visits.newRoute'),
                        visit_date: date,
                    })) || {};

                route_id = route?.id;
            }
            const { route_points } =
                (await routePointsList({
                    project_outlet_id: outlet_ids,
                    route_id,
                })) || {};

            let route_point_id = route_points?.[0]?.id;
            if (!route_point_id) {
                const { route_point } =
                    (await createRoutePoints({
                        route_id,
                        project_outlet_id: outlet_ids,
                        is_scheduled: false,
                    })) || {};

                route_point_id = route_point?.id;
            }
            if (route_point_id) {
                setValue('route_point_id', route_point_id);
                setCancelChange();
            }
        } catch (e) {
            enqueueSnackbar(`${t('messages.error')}: ${e?.message}`, {
                variant: 'error',
            });
        }
        setLoading(false);
    };

    return (
        <>
            {isLoading && <Loader />}
            <Button color="secondary" variant="outlined" onClick={() => setDialog(true)}>
                {t('buttons.create')}
            </Button>
            {isOpenDialog && (
                <Dialog
                    id="createVisitSchedule"
                    isOpenDialog={isOpenDialog}
                    setCloseDialog={setCancelChange}
                    title={t('buttons.create')}
                    className={styles.dialog}
                    actions={
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit(onSubmit)}
                        >
                            {t('buttons.create')}
                        </Button>
                    }
                >
                    <CreateForm defaultValues={defaultValues} form={form} />
                </Dialog>
            )}
        </>
    );
};

export default Create;
