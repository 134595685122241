import React, { FC, useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField, Select, Checkbox, Radio, Button } from '@components';
import { v4 as uniqid } from 'uuid';
import { getSku } from '@apiFeature/sku';
import { isValidUrl } from '@tools/utils/string.utils';
import removeIcon from '@images/svg/close-card-button-inactive.svg';
import styles from './QuestionEditDialog.module.scss';

const QuestionEditDialog: FC = ({ registerStr, questions, question, optionsKind }) => {
    const {
        sku_photo: defaultSkuPhoto,
        question_answers: defaultQuestionAnswers,
        min_number: defaultMinNumber,
        max_number: defaultMaxNumber,
        min_length: defaultMinLength,
    } = question || {};

    const [optionsSKU, setOptionsSKU] = useState([]);

    const { control, watch, setValue, getValues, clearErrors, trigger } = useFormContext();
    const { project: project_id, external_code } = watch();
    const curQuestion = getValues(`${registerStr}`);
    const parent_id = getValues(`${registerStr}.parent_id`);
    const kind = getValues(`${registerStr}.kind`);
    const is_photo_required = getValues(`${registerStr}.is_photo_required`);
    const question_answers = getValues(`${registerStr}.question_answers`);

    const { t } = useTranslation('translation');

    useEffect(() => {
        (async () => {
            if (project_id && external_code) {
                const data = await getSku({ project_id, external_code });
                const { skus } = data || {};
                if (skus && skus.length > 0) {
                    const optionsSKU = skus.map((e) => ({
                        value: e.id,
                        label: `${e.article} ${e.name}`,
                    }));
                    setOptionsSKU(optionsSKU);
                }
            }
        })();
    }, [project_id, external_code]);

    useEffect(() => {
        if (kind === 'photo_answer') setValue(`${registerStr}.is_photo_required`, true);
    }, [kind]);

    const optionsParentQuestion = questions?.map((e) => ({ value: e.id, label: e.name }));

    const { fields, remove, append, replace } = useFieldArray({
        control,
        name: `${registerStr}.question_answers`,
    });

    return (
        <div className={styles.form}>
            <div className={styles.items}>
                <div className={styles.item}>
                    <Controller
                        name={`${registerStr}.sku_id`}
                        control={control}
                        render={({ field: { name, value, ref }, fieldState: { error } }) => (
                            <Select
                                id={name}
                                name={name}
                                inputRef={ref}
                                label="SKU"
                                placeholder={t('questionnaires.selectCommodity')}
                                onChange={(e) => {
                                    setValue(name, e?.value || null);
                                }}
                                value={optionsSKU?.find((e) => e?.value === value)}
                                options={optionsSKU}
                                isClearable
                                error={error}
                            />
                        )}
                    />
                </div>
                <div className={styles.item}>
                    <Controller
                        name={`${registerStr}.name`}
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                                label={t('common.question')}
                                placeholder={t('questionnaires.typeQuestion')}
                                error={error}
                            />
                        )}
                    />
                </div>
                <div className={styles.item}>
                    <Controller
                        name={`${registerStr}.parent_id`}
                        control={control}
                        render={({ field: { name, value, ref }, fieldState: { error } }) => (
                            <Select
                                id={name}
                                name={name}
                                inputRef={ref}
                                label={t('questionnaires.parentQuestion')}
                                placeholder={t('questionnaires.selectParentQuestion')}
                                onChange={(e, { action }) => {
                                    setValue(name, e?.value || null);

                                    if (action === 'clear') {
                                        setValue(`${registerStr}.parent_criteria`, null);
                                        clearErrors(`${registerStr}.parent_criteria`);
                                    } else {
                                        trigger(`${registerStr}.parent_criteria`);
                                    }
                                }}
                                value={optionsParentQuestion?.find((e) => e?.value === value)}
                                options={optionsParentQuestion}
                                isClearable
                                error={error}
                            />
                        )}
                    />
                </div>
                {parent_id && (
                    <div className={styles.item}>
                        <Controller
                            name={`${registerStr}.parent_criteria`}
                            control={control}
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    onChange={onChange}
                                    value={value || ''}
                                    id={name}
                                    variant="outlined"
                                    label={t('questionnaires.parentCriteria')}
                                    placeholder={t('questionnaires.selectParentCriteria')}
                                    error={error}
                                />
                            )}
                        />
                    </div>
                )}
                <div className={styles.item}>
                    <Controller
                        name={`${registerStr}.description`}
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                                label={t('questionnaires.typeDescription')}
                                placeholder={t('questionnaires.selectTypeDescription')}
                                error={error}
                            />
                        )}
                    />
                </div>
                <div className={styles.item}>
                    <Controller
                        name={`${registerStr}.barcode`}
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <TextField
                                onChange={onChange}
                                value={value || ''}
                                id={name}
                                variant="outlined"
                                label={t('questionnaires.barcode')}
                                placeholder={t('questionnaires.selectBarcode')}
                                error={error}
                            />
                        )}
                    />
                </div>
                <div className={styles.item}>
                    <Controller
                        name={`${registerStr}.is_required`}
                        control={control}
                        render={({
                            field: { name, onChange, value = false },
                            fieldState: { error },
                        }) => (
                            <Checkbox
                                value={value}
                                onChange={onChange}
                                label={t('questionnaires.answerRequired')}
                                labelPlacement="end"
                                id={name}
                                error={error}
                            />
                        )}
                    />
                </div>
            </div>
            <div className={styles.items}>
                <div className={styles.item}>
                    <Controller
                        name={`${registerStr}.kind`}
                        control={control}
                        render={({ field: { name, value, ref }, fieldState: { error } }) => (
                            <Select
                                id={name}
                                name={name}
                                inputRef={ref}
                                label={t('questionnaires.answerType')}
                                onChange={(e) => {
                                    setValue(name, e.value, {
                                        shouldValidate: true,
                                    });
                                    setValue(`${registerStr}`, {
                                        ...curQuestion,
                                        min_number: defaultMinNumber,
                                        max_number: defaultMaxNumber,
                                        min_length: defaultMinLength,
                                    });
                                    clearErrors([
                                        `${registerStr}.min_number`,
                                        `${registerStr}.max_number`,
                                        `${registerStr}.min_length`,
                                    ]);
                                    if (defaultQuestionAnswers) replace(defaultQuestionAnswers);
                                }}
                                value={optionsKind?.find((e) => e?.value === value)}
                                options={optionsKind}
                                error={error}
                            />
                        )}
                    />
                </div>
                {(kind === 'number_answer' || kind === 'integer_answer') && (
                    <>
                        <div className={styles.item}>
                            <Controller
                                name={`${registerStr}.min_number`}
                                control={control}
                                render={({
                                    field: { name, onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value || ''}
                                        id={name}
                                        variant="outlined"
                                        label={t('questionnaires.minNumber')}
                                        error={error}
                                    />
                                )}
                            />
                        </div>
                        <div className={styles.item}>
                            <Controller
                                name={`${registerStr}.max_number`}
                                control={control}
                                render={({
                                    field: { name, onChange, value },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        onChange={onChange}
                                        value={value || ''}
                                        id={name}
                                        variant="outlined"
                                        label={t('questionnaires.maxNumber')}
                                        error={error}
                                    />
                                )}
                            />
                        </div>
                    </>
                )}
                {kind === 'your_answer' && (
                    <div className={styles.item}>
                        <Controller
                            name={`${registerStr}.min_length`}
                            control={control}
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    onChange={onChange}
                                    value={value || ''}
                                    id={name}
                                    variant="outlined"
                                    label={t('questionnaires.minLength')}
                                    error={error}
                                />
                            )}
                        />
                    </div>
                )}
                {(kind === 'one_answer' || kind === 'many_answers') && (
                    <div className={styles.itemAnswers}>
                        {fields?.length > 0 &&
                            fields.map((item, i) => (
                                <div className={styles.item} key={item.id}>
                                    <Controller
                                        name={`${registerStr}.question_answers[${i}]`}
                                        control={control}
                                        render={({
                                            field: { name, value },
                                            fieldState: { error },
                                        }) => (
                                            <>
                                                {kind === 'many_answers' ? (
                                                    <Checkbox
                                                        className={styles.itemAnswersEl}
                                                        value={value?.is_default || false}
                                                        onChange={(e) =>
                                                            setValue(
                                                                `${name}.is_default`,
                                                                e.target.checked
                                                            )
                                                        }
                                                        id={`${name}.is_default`}
                                                    />
                                                ) : (
                                                    <Radio
                                                        className={styles.itemAnswersEl}
                                                        value={value?.is_default || false}
                                                        onChange={(e) =>
                                                            setValue(
                                                                `${registerStr}.question_answers`,
                                                                question_answers.map(
                                                                    (item, ii) => ({
                                                                        ...item,
                                                                        is_default:
                                                                            ii === i ? true : false,
                                                                    })
                                                                )
                                                            )
                                                        }
                                                        id={`${name}.is_default`}
                                                    />
                                                )}
                                                <TextField
                                                    onChange={(e) =>
                                                        setValue(`${name}.answer`, e.target.value)
                                                    }
                                                    value={value?.answer}
                                                    id={`${name}.answer`}
                                                    variant="outlined"
                                                    error={error}
                                                />
                                            </>
                                        )}
                                    />
                                    <Button className={styles.removeBtn} onClick={() => remove(i)}>
                                        <img src={removeIcon} alt="" />
                                    </Button>
                                </div>
                            ))}
                        <Button
                            className={styles.textBtn}
                            onClick={() => append({ id: uniqid(), answer: '' })}
                            color="secondary"
                        >
                            {t('questionnaires.addVariant').toLocaleUpperCase()}
                        </Button>
                    </div>
                )}
                <div className={styles.item}>
                    <Controller
                        name={`${registerStr}.is_photo_required`}
                        control={control}
                        render={({
                            field: { name, onChange, value = false },
                            fieldState: { error },
                        }) => (
                            <Checkbox
                                value={value}
                                onChange={onChange}
                                label={t('questionnaires.photo')}
                                labelPlacement="end"
                                id={name}
                                error={error}
                            />
                        )}
                    />
                </div>
                {(kind === 'photo_answer' || is_photo_required) && (
                    <div className={styles.item}>
                        <Controller
                            name={`${registerStr}.photo_quantity`}
                            control={control}
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    onChange={onChange}
                                    value={value || ''}
                                    id={name}
                                    variant="outlined"
                                    label={t('questionnaires.photoQuantity')}
                                    error={error}
                                />
                            )}
                        />
                    </div>
                )}
                {defaultSkuPhoto && (
                    <div className={styles.item}>
                        <Controller
                            name={`${registerStr}.sku_photo`}
                            control={control}
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    onChange={onChange}
                                    value={value || ''}
                                    id={name}
                                    variant="outlined"
                                    label={t('questionnaires.photoLink')}
                                    error={error}
                                />
                            )}
                        />
                        {isValidUrl(defaultSkuPhoto) && (
                            <Button variant="text" href={defaultSkuPhoto} target="_blank">
                                <img className={styles.img} src={defaultSkuPhoto} alt="" />
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default QuestionEditDialog;
