import React, { PropsWithChildren } from 'react';

import cn from 'classnames';
import { Link, Location } from 'react-router-dom';

import s from './Button.module.scss';

export type ButtonType = 'primary' | 'default' | 'button' | 'submit' | 'flat';

export interface ButtonProps {
    type?: ButtonType;
    uppercase?: boolean;
    to?: string;
    className?: string;
    disabled?: boolean;
    title?: string;
    htmlType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
    icon?: React.ReactNode;
    onClick?: () => void;
    target?: string;
    state?: Location['state'];
}

export const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
    type = 'default',
    children,
    className,
    uppercase = true,
    to,
    disabled,
    title,
    icon,
    htmlType = 'button',
    onClick,
    target,
    state,
    ...rest
}) => {
    const buttonClassNames = cn(
        s.button,
        {
            [s.link]: Boolean(to),
            [s.primary]: type === 'primary',
            [s.default]: type === 'default',
            [s.flat]: type === 'flat',
            [s.disabled]: disabled,
            uppercase,
        },
        className,
    );

    const handleClick = () => {
        if (!disabled && onClick) {
            onClick();
        }
    };

    if (to && !disabled) {
        return (
            <Link target={target} className={buttonClassNames} to={to} onClick={handleClick} state={state} >
                {children}
                {icon && <span className={s.icon}>{icon}</span>}
            </Link>
        );
    }

    return (
        <button
            {...rest}
            className={buttonClassNames}
            title={title}
            type={htmlType} // eslint-disable-line react/button-has-type
            onClick={handleClick}
        >
            {children}
            {icon && <span className={s.icon}>{icon}</span>}
        </button>
    );
};
