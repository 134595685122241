import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Book } from '@navigation/Book';
import { Loader } from '@components';
import { Login } from '@pages';
import { PrivacyPolicy } from '@pages/PrivacyPolicy/PrivacyPolicy';

export const PublicRoutes = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route element={<Navigate to={Book.login} />} path="*" />
                <Route element={<Login />} path={Book.login} />
                <Route element={<PrivacyPolicy />} path={Book.policy} />
            </Routes>
        </Suspense>
    );
};
