import React, { useCallback, useEffect, useState } from 'react';
import styles from './SupportRequestsData.module.scss';
import { t } from 'i18next';
import { TableGrid, PhotoSlider } from '@components';
import { SupportRequestResponse } from '@apiFeature/supportRequests/types';
import { formatters } from '@tools/utils/date.util';
import { getUserById } from '@apiFeature/user';
import { User } from '@apiFeature/types';
import { fetchSupportRequestsListV2 } from '@apiFeature/supportRequests';
import { fetchFeedsPhotos } from '@api/visitFeed/visitFeed.api';
import { GridToolbarContainer, GridToolbarColumnsButton } from '@mui/x-data-grid';
import FiltersSelectors from '@redux/filters/selectors';
import { useSelector } from 'react-redux';

const limit = 10;

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
        </GridToolbarContainer>
    );
}

const renderColumns = () => {
    return [
        { field: 'content', headerName: t('common.description'), minWidth: 280 },
        { field: 'type_name', headerName: t('feed.type'), minWidth: 280 },
        { field: 'user_name', headerName: t('common.user'), minWidth: 250, flex: 1 },
        {
            field: 'created_at',
            headerName: t('questionnaires.createDate'),
            minWidth: 180,
            valueGetter: ({ value }) => (value ? formatters.toISODateFormatString(value) : value),
        },
        {
            field: 'file_links',
            headerName: 'Image',
            width: 750,
            renderCell: ({ id, value }) => {
                if (value) {
                    return (
                        <div className={styles.photos}>
                            {value?.length > 0 && (
                                <PhotoSlider
                                    id={id}
                                    photos={value}
                                    type="carousel"
                                    hasDeleteBtn={false}
                                />
                            )}
                        </div>
                    );
                } else return '';
            },
        },
    ];
};

const SupportRequestsData = ({ filters }) => {
    const { supportRequestTypes } = useSelector(FiltersSelectors.filtersState);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState<SupportRequestResponse | null>(null);
    const [userList, setUserList] = useState<User[]>([]);
    const paginationModelDefault = { page: 0, pageSize: limit };
    const [paginationModel, setPaginationModel] = useState(paginationModelDefault);
    const { items = [], total = 0, offset } = data || {};

    const getSupportRequestsList = useCallback(
        async (offset = 0) => {
            setLoading(true);

            const {
                support_requests: { items: support_requests, total, offset: offsetFromResponse },
            } =
                (await fetchSupportRequestsListV2({
                    ...filters,
                    pagination: {
                        limit,
                        offset,
                    },
                })) || {};
            const uniqueUserIds = new Set(support_requests.map((sr) => sr.user_id));
            const users = await Promise.all(
                Array.from(uniqueUserIds).map(async (userId) => {
                    const existingUser = userList.find((user) => user?.user?.id === userId);
                    if (!existingUser) {
                        const newUser = await getUserById(userId);
                        setUserList((prevUserList) => [...prevUserList, newUser]);
                        return newUser;
                    }
                    return existingUser;
                })
            );
            const items = await Promise.all(
                support_requests?.map(async (sr) => {
                    const currentUser = users.find((user) => user?.user?.id === sr.user_id);
                    const { last_name, first_name, patronymic_name } = currentUser?.user || {};
                    const fileLinks = await fetchFeedsPhotos({
                        generic_id: sr.id,
                    });
                    return {
                        ...sr,
                        type_name: supportRequestTypes.find(
                            (type) => type.id === sr.support_request_type_id
                        )?.name,
                        user_name: `${last_name} ${first_name} ${patronymic_name}`,
                        file_links: fileLinks,
                    };
                })
            );
            setLoading(false);
            setData({ items, total, offset: offsetFromResponse });
        },
        [filters, userList]
    );

    useEffect(() => {
        if (!isLoading && Object.keys(filters)?.length > 0) {
            getSupportRequestsList(paginationModel?.page * limit);
        }
    }, [filters, paginationModel]);

    useEffect(() => {
        if (Object.keys(filters)?.length > 0) setPaginationModel(paginationModelDefault);
    }, [filters]);

    return (
        <TableGrid
            rows={items}
            columns={renderColumns()}
            rowCount={total}
            loading={isLoading}
            pageSizeOptions={[limit]}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            paginationModel={offset === 0 ? paginationModelDefault : paginationModel}
            rowHeight={50}
            hasSun
            getRowHeight={() => 'auto'}
            slots={{
                toolbar: CustomToolbar,
            }}
            className={styles.tableGrid}
            disableRowSelectionOnClick
        />
    );
};

export default SupportRequestsData;
