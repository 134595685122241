import { t } from 'i18next';

export const apikey = '29294198-6cdc-4996-a870-01e89b830f3e';

export const statusOptions = [
    {
        label: t('statuses.active'),
        value: 'active',
    },
    {
        label: t('statuses.inactive'),
        value: 'inactive',
    },
    {
        label: t('statuses.blocked'),
        value: 'banned',
    },
];

export const genderOptions = [
    {
        label: t('catalog.team.male'),
        value: 'male',
    },
    {
        label: t('catalog.team.female'),
        value: 'female',
    },
];

export const taskStatusOptions = [
    {
        label: t('tasks.completed'),
        value: 'DONE',
    },
    {
        label: t('tasks.notCompleted'),
        value: 'CANT_DO',
    },
];

export const taskStatusesOptions = [
    {
        label: t('tasks.completed'),
        value: 'DONE',
    },
    {
        label: t('tasks.pending'),
        value: 'PENDING',
    },
    {
        label: t('tasks.overdue'),
        value: 'OVERDUE',
    },
];

export const merchandisingTypeOptions = [
    {
        label: t('catalog.projects.combined'),
        value: 'combined',
    },
    {
        label: t('catalog.projects.exclusive'),
        value: 'exclusive',
    },
];

export const importsKindOptions = [
    {
        label: t('common.project_outlets'),
        value: 'OUTLETS_G',
        subOptions: [
            {
                label: t('importJournal.importTypes.PROJECT_OUTLETS'),
                value: 'PROJECT_OUTLETS',
            },
            {
                label: t('importJournal.importTypes.UPDATE_PROJECT_OUTLETS'),
                value: 'UPDATE_PROJECT_OUTLETS',
            },
        ],
    },
    {
        label: t('catalog.users.users'),
        value: 'USERS_G',
        subOptions: [
            {
                label: t('importJournal.importTypes.USERS'),
                value: 'USERS',
            },
            {
                label: t('importJournal.importTypes.USER_PROJECTS'),
                value: 'USER_PROJECTS',
            },
        ],
    },
    {
        label: t('routes.title'),
        value: 'ROUTES_G',
        subOptions: [
            {
                label: t('importJournal.importTypes.ROUTES'),
                value: 'ROUTES',
            },
            {
                label: t('importJournal.importTypes.ROUTES_V2'),
                value: 'ROUTES_V2',
            },
        ],
    },
    {
        label: t('questionnaires.title'),
        value: 'QUESTIONNAIRES_G',
        subOptions: [
            {
                label: t('importJournal.importTypes.QUESTIONNAIRES'),
                value: 'QUESTIONNAIRES',
            },
            {
                label: t('importJournal.importTypes.QAPO'),
                value: 'QAPO',
            },
            {
                label: t('importJournal.importTypes.QAPO_V2'),
                value: 'QAPO_V2',
            },
        ],
    },
    {
        label: t('catalog.planograms.planograms'),
        value: 'PLANOGRAMS_G',
        subOptions: [
            {
                label: t('importJournal.importTypes.PLANOGRAMS'),
                value: 'PLANOGRAMS',
            },
        ],
    },
    {
        label: t('address_program.title'),
        value: 'AP_G',
        subOptions: [
            {
                label: t('importJournal.importTypes.AP'),
                value: 'AP',
            },
        ],
    },
    {
        label: t('feed.checkerTitle'),
        value: 'CHECKERS_G',
        subOptions: [
            {
                label: t('importJournal.importTypes.CHECKERS'),
                value: 'CHECKERS',
            },
        ],
    },
    {
        label: 'CROWD',
        value: 'CROWD_G',
        subOptions: [
            {
                label: t('importJournal.importTypes.CROWD_TASKS'),
                value: 'CROWD_TASKS',
            },
            {
                label: t('importJournal.importTypes.CROWD_PAYMENTS'),
                value: 'CROWD_PAYMENTS',
            },
        ],
    },
];

export const statusOptionsForQuestionnaires = [
    {
        value: true,
        label: t('statuses.active')
    },
    {
        value: false,
        label: t('statuses.inactive')
    },
];

export const kindOptionsForQuestionnaires = [
    {
        value: 'questionnaire',
        label: t('questionnaires.questionnaire')
    },
    {
        value: 'audit',
        label: t('feed.audit')
    },
    {
        value: 'audit_manager',
        label: t('feed.auditManager')
    },
];
